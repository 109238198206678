import { TFunction } from "i18next";
import { CommonTranslation } from "./Common.Translation";

export class CustomPageTranslation extends CommonTranslation {
  constructor(translation: TFunction) {
    super(translation, "customPage");
  }

  public get pageNotFound(): string {
    return super.getTextByMessageKey("pageNotFound");
  }

  public get errorUpdatingContent(): string {
    return super.getTextByMessageKey("errorUpdatingContent");
  }

  public get successUpdatingContent(): string {
    return super.getTextByMessageKey("successUpdatingContent");
  }

  public get editPage(): string {
    return super.getTextByMessageKey("editPage");
  }
}
