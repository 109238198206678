import * as React from "react";

import "bootstrap/dist/css/bootstrap.css";

import { Utility } from "../../../Utility/Utility";
import { ResourcesTranslation } from "../../../Translations/Resources.Translation";

// import "./ManageResourcesComponent.scss";
import styles from "./ManageResourcesComponent.module.scss";

import { PersonaStyle, User, UserInfo } from "../../../Models/User";

import { PersonaComponent } from "../PersonaComponent/PersonaComponent";

import {
  DefaultButton,
  DetailsList,
  DetailsListLayoutMode,
  FontIcon,
  FontWeights,
  getTheme,
  IColumn,
  IconButton,
  mergeStyleSets,
  Modal,
  nullRender,
  PersonaSize,
  PrimaryButton,
  ScrollablePane,
  ScrollbarVisibility,
  SelectionMode,
  TextField,
  TooltipHost,
  DirectionalHint,
  ICalloutProps,
  SearchBox,
} from "@fluentui/react";
import { IBaseProperties } from "../../../Models/IBaseProperties";
import { UserClient } from "../../../Clients/UserClient";
import { ToastNotificationType } from "../../../Models/ToastNote";
import { ConcurrentTask } from "../../../Utility/ConcurrentTasks";
import { LoaderComponent } from "../LoaderComponent/LoaderComponent";
import { SpinnerComponent } from "../SpinnerComponent/SpinnerComponent";

const calloutProps: ICalloutProps = { gapSpace: 0 };

export interface IManageResourcesComponentProps extends IBaseProperties {
  // allResources: IPersonaComponentItem[];
  listResources: User[];
  saveFunction: any;
  getUsers?: (text: string) => Promise<User[]>;
  onRenderModalHeader: any;
  maxLength: number;
  personaSize: PersonaSize;
  personaStyle?: PersonaStyle;
  userType?: string;
  edit: boolean;
  labelSelectedResources?: string;
  calendar: number;
}

export interface IManageResourcesComponentState {
  isLoading: boolean;
  isListLoading: boolean;
  allResources: User[];
  listResources: User[];
  viewPanel: boolean;
  searchText: string;
  newAssignmentsText: string;
  removedAssignmentsText: string;
  viewMode: boolean;
  noUsersFound?: string;
}

const WAIT_INTERVAL = 1000;
const ENTER_KEY = 13;

export class ManageResourcesComponent extends React.Component<
  IManageResourcesComponentProps,
  IManageResourcesComponentState
> {
  _columns: IColumn[];
  private readonly _translation: ResourcesTranslation;
  private readonly _reference = React.createRef<HTMLElement>();
  private _timer?: NodeJS.Timeout;

  constructor(props: IManageResourcesComponentProps) {
    super(props);  
    this._translation = new ResourcesTranslation(
      this.props.commonProps.translation
    );

    this._editPanel = this._editPanel.bind(this);
    this._viewPanel = this._viewPanel.bind(this);
    this._handleChange = this._handleChange.bind(this);
    this._handleKeyDown = this._handleKeyDown.bind(this);
    this._triggerChange = this._triggerChange.bind(this);
    this._onChangeSearchText = this._onChangeSearchText.bind(this);

    this._timer = undefined;
    this._columns = [
      {
        key: "PersonaComponent",
        name: (<div></div>) as any,
        minWidth: 50,
        maxWidth: 50,
        isResizable: false,
        onRender: (item: User) => {
          return (
            <PersonaComponent
              commonProps={this.props.commonProps}
              persona={[item]}
              maxLength={1}
              personaSize={PersonaSize.size40}
              personaStyle={this.props.personaStyle}
            />
          );
        },
        // columnActionsMode: ColumnActionsMode.,
      },
      {
        key: "PersonaName",
        name: (<div></div>) as any,
        minWidth: window.innerWidth > 767 ? 200 : 100,
        isResizable: false,
        onRender: (item: User) => {
          return <div className={styles.ResourcesCell}>{item.displayName}</div>;
        },
        // columnActionsMode: ColumnActionsMode.,
      },
      {
        key: "PersonaAction",
        name: (<div></div>) as any,
        minWidth: 50,
        maxWidth: 50,
        isResizable: false,
        onRender: (item: User) => {
          if (this.state.viewMode) return <div></div>;
          return (
            <div className={styles.add}>
              {
                <FontIcon
                  aria-label={
                    Utility.CheckuserInList(
                      item.userPrincipalName,
                      this.state.listResources
                    )
                      ? "Save"
                      : Utility.CheckuserInList(
                          item.userPrincipalName,
                          this.props.listResources
                        )
                      ? "Remove"
                      : "Add"
                  }
                  iconName={
                    Utility.CheckuserInList(
                      item.userPrincipalName,
                      this.state.listResources
                    )
                      ? "Accept"
                      : Utility.CheckuserInList(
                          item.userPrincipalName,
                          this.props.listResources
                        )
                      ? "Cancel"
                      : "Add"
                  }
                  className={
                    Utility.CheckuserInList(
                      item.userPrincipalName,
                      this.state.listResources
                    )
                      ? styles.Accept
                      : Utility.CheckuserInList(
                          item.userPrincipalName,
                          this.props.listResources
                        )
                      ? styles.Cancel
                      : styles.Add
                  }
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      var assignedResources: User[];

                      if (
                        Utility.CheckuserInList(
                          item.userPrincipalName,
                          this.state.listResources
                        )
                      ) {
                        assignedResources = this.state.listResources.filter(
                          (x) => x.userPrincipalName !== item.userPrincipalName
                        );
                      } else {
                        assignedResources = this.state.listResources
                          ? this.state.listResources
                          : [];
                        if (
                          this.state.allResources.filter(
                            (el) =>
                              el.userPrincipalName === item.userPrincipalName
                          ).length > 0
                        ) {
                          assignedResources.push(
                            this.state.allResources.filter(
                              (el) =>
                                el.userPrincipalName === item.userPrincipalName
                            )[0]
                          );
                        } else {
                          assignedResources.push(
                            this.props.listResources.filter(
                              (el) =>
                                el.userPrincipalName === item.userPrincipalName
                            )[0]
                          );
                        }
                      }

                      var newAssignments = assignedResources?.filter(
                        (x) =>
                          !Utility.CheckuserInList(
                            x.userPrincipalName,
                            this.props.listResources
                          )
                      ).length;
                      var removedAssignments = this.props.listResources?.filter(
                        (x) =>
                          !Utility.CheckuserInList(
                            x.userPrincipalName,
                            assignedResources
                          )
                      ).length;

                      var newText = "";
                      var removedText = "";
                      if (newAssignments > 0) {
                        newText = this._translation.addedResources(
                          newAssignments
                        );
                      }

                      if (removedAssignments > 0) {
                        removedText = this._translation.removedResources(
                          removedAssignments
                        );
                      }

                      this.setState({
                        listResources: assignedResources,
                        newAssignmentsText: newText,
                        removedAssignmentsText: removedText,
                      });
                    }
                  }}
                  onClick={(event) => {
                    // event.stopPropagation();
                    var assignedResources: User[];

                    if (
                      Utility.CheckuserInList(
                        item.userPrincipalName,
                        this.state.listResources
                      )
                    ) {
                      assignedResources = this.state.listResources.filter(
                        (x) => x.userPrincipalName !== item.userPrincipalName
                      );
                    } else {
                      assignedResources = this.state.listResources
                        ? this.state.listResources
                        : [];
                      if (
                        this.state.allResources.filter(
                          (el) =>
                            el.userPrincipalName === item.userPrincipalName
                        ).length > 0
                      ) {
                        assignedResources.push(
                          this.state.allResources.filter(
                            (el) =>
                              el.userPrincipalName === item.userPrincipalName
                          )[0]
                        );
                      } else {
                        assignedResources.push(
                          this.props.listResources.filter(
                            (el) =>
                              el.userPrincipalName === item.userPrincipalName
                          )[0]
                        );
                      }
                    }

                    var newAssignments = assignedResources?.filter(
                      (x) =>
                        !Utility.CheckuserInList(
                          x.userPrincipalName,
                          this.props.listResources
                        )
                    ).length;
                    var removedAssignments = this.props.listResources?.filter(
                      (x) =>
                        !Utility.CheckuserInList(
                          x.userPrincipalName,
                          assignedResources
                        )
                    ).length;

                    var newText = "";
                    var removedText = "";
                    if (newAssignments > 0) {
                      newText = this._translation.addedResources(
                        newAssignments
                      );
                    }

                    if (removedAssignments > 0) {
                      removedText = this._translation.removedResources(
                        removedAssignments
                      );
                    }

                    this.setState({
                      listResources: assignedResources,
                      newAssignmentsText: newText,
                      removedAssignmentsText: removedText,
                    });
                  }}
                />
              }
            </div>
          );
        },
        // columnActionsMode: ColumnActionsMode.,
      },
    ];

    this.state = {
      allResources: [],
      listResources: Object.assign([], this.props.listResources),
      viewPanel: false,
      viewMode: false,
      searchText: "",
      newAssignmentsText: "",
      removedAssignmentsText: "",
      isLoading: false,
      isListLoading: false,
    };
  }

  async componentDidMount() {
    try {
      if (this.props.getUsers) {
        // this.setState({
        //   isLoading: true,
        // });
        this._getAllUsers("").then((users) => {
          this.setState({
            isLoading: false,
            allResources: users,
            searchText: "",
          });
        });
      }
    } catch (err) {
      this.props.commonProps.toastComponent?.showMessage(
        this._translation.error,
        this._translation.error,
        ToastNotificationType.ERROR
      );
    }
  }

  private _getResourcesToAdd(): User[] {
    return this.state.allResources.filter(
      (item) =>
        !Utility.CheckuserInList(
          item.userPrincipalName,
          this.props.listResources
        ) &&
        !Utility.CheckuserInList(
          item.userPrincipalName,
          this.state.listResources
        )
    );
  }

  public _editPanel(ev?: any): void {
    ev.preventDefault();
    this.setState({
      isLoading: true,
    });
    this._getAllUsers("").then((users) => {
      this.setState({
        isLoading: false,
        allResources: users,
        searchText: "",
        viewPanel: true,
        viewMode: false,
        listResources: this.props.listResources
          ? Object.assign([], this.props.listResources)
          : [],
        removedAssignmentsText: "",
        newAssignmentsText: "",
      });
    });
  }

  public _viewPanel(ev?: any): void {
    ev.preventDefault();
    this.setState({
      searchText: "",
      viewPanel: true,
      viewMode: true,
      listResources: this.props.listResources
        ? Object.assign([], this.props.listResources)
        : [],
      removedAssignmentsText: "",
      newAssignmentsText: "",
    });
  }

  private _handleChange(e: any, value: string | undefined) {
    if (this._timer) clearTimeout(this._timer);
    if (value) {
      this.setState({ searchText: value });
      this._timer = setTimeout(this._triggerChange, WAIT_INTERVAL);
    } else if (value == "") {
      this.setState({ searchText: value });
      this._onChangeSearchText("");
    }
  }

  private _handleKeyDown(e: any) {
    if (e.keyCode === ENTER_KEY) {
      this._triggerChange();
    }
  }

  private _triggerChange() {
    const { searchText } = this.state;

    this._onChangeSearchText(searchText);
  }

  private _onChangeSearchText(
    // event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) {
    const sFilter = newValue ? newValue : "";
    this.setState({
      isListLoading: true,
    });
    this._getAllUsers(sFilter).then((users) => {
      let noUsersFound = "";

      if (users.length === 0) {
        noUsersFound = this._translation.noUsersFound;
      }

      this.setState({
        isListLoading: false,
        allResources: users,
        searchText: sFilter,
        noUsersFound: noUsersFound,
      });
    });
  }

  public render() {
    const { listResources, viewPanel } = this.state;

    return (
      <div className={styles.divManageResourcesComponent}>
        <LoaderComponent
          commonProps={this.props.commonProps}
          isLoading={this.state.isLoading}
        />
        {this.props.listResources?.length > 0 && (
          <div
            aria-label={this.props.edit ? "EDIT" : "VIEW"}
            className={styles.listResources}
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                this.props.edit ? this._editPanel() : this._viewPanel();
              }
            }}
            onClick={this.props.edit ? this._editPanel : this._viewPanel}
          >
            <PersonaComponent
              commonProps={this.props.commonProps}
              persona={this.props.listResources}
              maxLength={this.props.maxLength}
              personaSize={this.props.personaSize}
              personaStyle={this.props.personaStyle}
            />
          </div>
        )}
        {this.props.edit && (
          <div
            className={styles.add}
            aria-label="Edit users"
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                this._editPanel();
              }
            }}
            onClick={this._editPanel}
          >
            +
          </div>
        )}
        <Modal
          titleAriaId=""
          isOpen={viewPanel}
          onDismiss={(ev) => {
            ev?.preventDefault();
            this.setState({ viewPanel: false });
          }}
          isBlocking={false}
          containerClassName={styles.modalContainer}
          className={styles.ModalAssignedResources}
          closeButtonAriaLabel="Close"
        >
          <div className={styles.modalHeader}>
            {this.props.onRenderModalHeader()}
            <IconButton
              className={styles.ModalButton}
              iconProps={{ iconName: "Cancel" }}
              aria-label="Close"
              ariaLabel={this._translation.close}
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  this.setState({ viewPanel: false });
                }
              }}
              onClick={(ev: { preventDefault: () => void }) => {
                ev?.preventDefault();
                this.setState({ viewPanel: false });
              }}
            />
          </div>
          <hr />
          <div className={styles.modalBody}>
            {!this.state.viewMode && (
              <div className={styles.addText}>
                <label className={styles.Label + " ms-Grid-col ms-md11"}>
                  {this._translation.addResources}
                </label>
                <span style={{ textAlign: "right" }}>
                  <TooltipHost
                    content="Resource"
                    id="infoIcon"
                    calloutProps={calloutProps}
                    directionalHint={DirectionalHint.bottomRightEdge}
                  >
                    <FontIcon
                      aria-describedby="infoIcon"
                      id="infoIcon"
                      iconName={"Info"}
                      className={styles.Info + " ms-Grid-col ms-md1"}
                    />
                  </TooltipHost>
                </span>
                <div className={styles.searchBoxDiv}>
                  <SearchBox
                    iconProps={{ iconName: "Search" }}
                    // errorMessage={this.state.noUsersFound}
                    onSearch={this._onChangeSearchText}
                    onChange={this._handleChange}
                    onKeyDown={this._handleKeyDown}
                    className="ms-Grid-col ms-sm11"
                  />
                  {this.state.isListLoading && (
                    <div className="ms-Grid-col ms-sm1">
                      <SpinnerComponent
                        commonProps={this.props.commonProps}
                        relative={true}
                      ></SpinnerComponent>
                    </div>
                  )}
                </div>
              </div>
            )}
            {!this.state.viewMode &&
            this.state.searchText.length >= 0 &&
            this._getResourcesToAdd().length > 0 ? (
              <div>
                <div className={styles.addResources + " ms-Grid-col ms-sm12"}>
                  <ScrollablePane
                    scrollbarVisibility={ScrollbarVisibility.auto}
                  >
                    <DetailsList
                      className={"ms-listClear"}
                      compact={true}
                      items={
                        this.state.searchText.length < 0
                          ? []
                          : this._getResourcesToAdd()
                      }
                      columns={this._columns}
                      selectionMode={SelectionMode.none}
                      layoutMode={DetailsListLayoutMode.justified}
                      isHeaderVisible={false}
                    />
                  </ScrollablePane>
                </div>
                <hr />
              </div>
            ) : !this.state.viewMode ? (
              <hr />
            ) : (
              <div></div>
            )}

            <label className={styles.Label + " ms-Grid-col ms-md11"}>
              {this.props.labelSelectedResources
                ? this.props.labelSelectedResources
                : this._translation.selectedResources}
            </label>
            <div
              className={
                this.state.searchText.length >= 0 &&
                this._getResourcesToAdd().length
                  ? styles.resources + " ms-Grid-col ms-md12 "
                  : styles.resources +
                    " ms-Grid-col ms-md12 " +
                    styles.maximized
              }
            >
              <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                <DetailsList
                  className={"ms-listClear"}
                  compact={true}
                  items={(!this.props.listResources
                    ? []
                    : this.props.listResources
                  ).concat(
                    this.state.listResources.filter(
                      (x) =>
                        !Utility.CheckuserInList(
                          x.userPrincipalName,
                          this.props.listResources
                        )
                    )
                  )}
                  columns={this._columns}
                  selectionMode={SelectionMode.none}
                  layoutMode={DetailsListLayoutMode.justified}
                  isHeaderVisible={false}
                />
              </ScrollablePane>
            </div>
          </div>
          <hr />
          <div className={styles.modalFooter}>
            <div className={styles.ModalFooterRow + " row"}>
              <div className={styles.divAssignments + " hidden-mobile"}>
                <span className={styles.labelNewAssignments}>
                  {this.state.newAssignmentsText}
                </span>
                <span className={styles.labelremovedAssignemnts}>
                  {this.state.removedAssignmentsText}
                </span>
              </div>
              <div>
                {this.state.newAssignmentsText ||
                this.state.removedAssignmentsText ? (
                  <DefaultButton
                    aria-label={this._translation.cancel}
                    text={this._translation.cancel}
                    className={"btn btn-dismiss"}
                    tabIndex={0}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        this.setState({ viewPanel: false });
                      }
                    }}
                    onClick={(ev: { preventDefault: () => void }) => {
                      ev?.preventDefault();
                      this.setState({ viewPanel: false });
                    }}
                  />
                ) : (
                  <div></div>
                )}
              </div>
              <div className={styles.divBtnSendInvitation}>
                <PrimaryButton
                  text={
                    this.state.newAssignmentsText &&
                    this.state.removedAssignmentsText
                      ? this._translation.saveUpdates
                      : this.state.newAssignmentsText
                      ? this._translation.sendInvitation
                      : this.state.removedAssignmentsText
                      ? this._translation.remove
                      : this._translation.close
                  }
                  aria-label={
                    this.state.newAssignmentsText &&
                    this.state.removedAssignmentsText
                      ? this._translation.saveUpdates
                      : this.state.newAssignmentsText
                      ? this._translation.sendInvitation
                      : this.state.removedAssignmentsText
                      ? this._translation.remove
                      : this._translation.close
                  }
                  className={"btn btn-primary"}
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      this.props.saveFunction(
                        this.state.listResources,
                        this.state.newAssignmentsText ||
                          this.state.removedAssignmentsText
                      );
                      this.setState({ viewPanel: false });
                    }
                  }}
                  onClick={(ev: { preventDefault: () => void }) => {
                    ev?.preventDefault();
                    this.props.saveFunction(
                      this.state.listResources,
                      this.state.newAssignmentsText ||
                        this.state.removedAssignmentsText
                    );
                    this.setState({ viewPanel: false });
                  }}
                />
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }

  private readonly _concurrentTask: ConcurrentTask<
    UserInfo[] | undefined
  > = new ConcurrentTask<UserInfo[] | undefined>();

  private async _getAllUsers(text: string): Promise<User[]> {
    let userClient = new UserClient();
    var users: User[];
    var usersInfo: UserInfo[] | undefined;

    if (this.props.getUsers) {
      users = await this.props.getUsers(text.toUpperCase());

      return users
        ? users?.map((element) => {
            return {
              id: element.id,
              userPrincipalName: element.userPrincipalName,
              displayName: element.displayName,
              initials: element.initials,
            };
          })
        : [];
    } else {
      if (this.props.userType) {
        const userType = this.props.userType;
        // usersInfo = await userClient.getUsersByFilterAsyc(
        //   this.props.userType,
        //   text.toUpperCase()
        // );

        if (!text || !text.trim()) {
          text = "%20";
        }

        const promise = () =>
          userClient.getUsersByFilterAsync(userType, {term: text.toUpperCase(), calendar: this.props.calendar } );

        usersInfo = await this._concurrentTask.executePromise(promise, []);

        if (usersInfo === undefined) {
          this.props.commonProps.toastComponent?.showMessage(
            this._translation.error,
            this._translation.getUsersError,
            ToastNotificationType.ERROR
          );
          usersInfo = [];
        }
      } else {
        usersInfo = [];
      }

      if (usersInfo) {
        return usersInfo?.map((element) => {
          return {
            id: element.id,
            userPrincipalName: element.username,
            displayName: element.displayName,
            initials: element.initials,
          };
        });
      } else {
        return [];
      }
    }
  }
}
