import * as React from "react";
import styles from "./SupportFooterComponent.module.scss";
import {
  ContextualMenu,
  DefaultButton,
  Dropdown,
  FontIcon,
  IconButton,
  IDragOptions,
  MessageBar,
  MessageBarType,
  Modal,
  TextField,
} from "@fluentui/react";
import { Text } from "@fluentui/react/lib/Text";
import "url-search-params-polyfill";
import { IBaseProperties } from "../../../../../Models/IBaseProperties";
import { Footer } from "../../../../../Models/SupportPage";
import { Incident } from "../../../../../Models/Incident";
import { LoaderComponent } from "../../../../Shared/LoaderComponent/LoaderComponent";

const dragOptions: IDragOptions = {
  moveMenuItemText: "Move",
  closeMenuItemText: "Close",
  menu: ContextualMenu,
  dragHandleSelector: ".panelHeader",
};

export interface ISupportFooterComponentProps extends IBaseProperties {
  footer: Footer;
}

export interface ISupportFooterComponentState {
  // persona: IPersonaComponentItem[];
  incident: Incident;
  isLoading: boolean;
  showReportModal: boolean;
  showMessageBar: boolean;
  showResultMessage: boolean;
  messageBarText: string;
  title: string;
  description: string;
  showIncidentError: boolean;
  resultIncident: boolean;
}

export class SupportFooterComponent extends React.Component<
  ISupportFooterComponentProps,
  ISupportFooterComponentState
> {
  constructor(props: ISupportFooterComponentProps) {
    super(props);

    this.state = {
      showReportModal: false,
      showMessageBar: false,
      showResultMessage: false,
      messageBarText: "",
      resultIncident: false,
      incident: {
        userid: "",
        issue: "Business Application_Other_Generic Issue",
        apmcode: "AP32353",
        source: "Press Releases",
        impacted_users: "low",
        degradation_type: "NOT DEGRADED",
        description: "",
      },
      title: "",
      description: "",
      showIncidentError: false,
      isLoading: false,
    };
  }

  public componentDidMount(): void {
      this.setState({
        title: "",
        description: "",
        incident: {
          userid: this.props.footer.userId,
          issue: "Business Application_Other_Generic Issue",
          apmcode: "AP32353",
          source: "Press Releases",
          impacted_users: "low",
          degradation_type: "NOT DEGRADED",
          description: "",
        },
      });
  }

  public render() {
    var mailSupportTeam = this.props.footer.links[1].href;

    const ErrorMessage = (
      <div>
        <div>Oops, something went wrong!</div>
        <div>Try to do it again.</div>
        <button
          // disabled={this.state.incident.description.length == 0}
          onClick={async () => {
            this.setState({ showResultMessage: false });
          }}
          className={styles.ButtonField + " " + styles.ButtonPrimaryField}
        >
          Retry
        </button>
        <div className={styles.SecondaryMessage}>
          If the problem persists, contact
        </div>
        <div className={styles.SecondaryMessage}>
          our Support Team{" "}
          <a href={"mailto:" + mailSupportTeam}>
            {mailSupportTeam.toUpperCase()}
          </a>
        </div>
      </div>
    );

    const OkMessage = (
      <div>
        <div className={styles.TitleMessage}>
          Your request has been created correctly
        </div>
        <div>
          You will soon receive a confirmation email from ME - OneClick with the
          summary.
        </div>
        <div>
          The support request will be taken care of as soon as possible.
        </div>
        <button
          // disabled={this.state.incident.description.length == 0}
          onClick={async () => {
            this.setState({ showReportModal: false });
          }}
          className={styles.ButtonField + " " + styles.ButtonPrimaryField}
        >
          Close
        </button>
      </div>
    );

    return (
      <div>
        <hr />
        <div className={styles.ContactSection}>
          {this.props.footer.links.map((x) => {
            if (this.props.footer.links.indexOf(x) == 0) {
              return (
                <div className={styles.ContactPart}>
                  <div className={styles.ContactHeading}>{x.header}</div>
                  <div className={styles.ContactText}>
                    {x.text}{" "}
                    <a href={"mailto:" + x.href}>
                      {x.hrefText ? x.hrefText.toUpperCase() : ""}
                    </a>
                  </div>
                </div>
              );
            } else {
              return (
                <div className={styles.ContactPart}>
                  <div className={styles.ContactHeading}>{x.header}</div>
                  <div className={styles.ContactText}>
                    {x.text}{" "}
                    <a
                      href="#"
                      onClick={() => {
                        this.setState({ showReportModal: true });
                      }}
                    >
                      {x.hrefText}
                    </a>
                  </div>
                </div>
              );
            }
          })}
        </div>
        <div className={styles.ContactSection}>
          <div className={styles.ReportBugSection}>
            {/* <DefaultButton
              id="btnReportBug"
              onClick={() => {
                this.setState({ showReportModal: true });
              }}
              text="Report a bug"
              // hidden={true}
            /> */}
            <Modal
              isOpen={this.state.showReportModal}
              closeButtonAriaLabel="Close"
              onDismiss={() => {
                this.setState({
                  showReportModal: false,
                  showMessageBar: false,
                  showResultMessage: false,
                });
              }}
              containerClassName={styles.ModalContainer}
              dragOptions={dragOptions}
            >
              <div className={styles.modalHeader}>
                <div className={styles.HeaderSection}>
                  Open an incident on ME - OneClick
                </div>
                <IconButton
                  className={styles.ModalButton}
                  iconProps={{ iconName: "Cancel" }}
                  aria-label="Close"
                  ariaLabel={"Close"}
                  onClick={(ev: { preventDefault: () => void }) => {
                    ev.preventDefault();
                    this.setState({
                      showReportModal: false,
                      showMessageBar: false,
                      showResultMessage: false,
                    });
                  }}
                />
              </div>
              {/* <hr /> */}

              {this.state.incident && (
                <div
                  className={styles.modalBody}
                  // className={}
                  // style={divStyles}
                >
                  {this.state.isLoading && (
                    <LoaderComponent
                      commonProps={this.props.commonProps}
                      isLoading={this.state.isLoading}
                    />
                  )}
                  {!this.state.isLoading &&
                    this.state.showMessageBar && [
                      <MessageBar
                        // delayedRender={false}
                        className={styles.MessageBar}
                        messageBarType={MessageBarType.error}
                      >
                        {this.state.messageBarText}
                      </MessageBar>,
                      <br />,
                    ]}

                  {!this.state.isLoading && this.state.showResultMessage && (
                    <div className={styles.divResult}>
                      <FontIcon
                        iconName={
                          this.state.resultIncident ? "CheckMark" : "Warning"
                        }
                        className={
                          this.state.resultIncident
                            ? styles.IconResultOk
                            : styles.IconResultError
                        }
                      />
                      <div className={styles.MessageResult}>
                        {this.state.resultIncident ? OkMessage : ErrorMessage}
                      </div>
                    </div>
                  )}

                  {!this.state.showResultMessage && (
                    <div>
                      <div className={styles.TabField}>
                        <div className={styles.LabelIncident}>Title *</div>
                        <TextField
                          aria-label="Title of the issue"
                          description={
                            this.state.showIncidentError && !this.state.title
                              ? "campo obbligatorio"
                              : " "
                          }
                          onRenderDescription={(props) => {
                            return (
                              <Text
                                variant="small"
                                className={styles.LabelErrorMessage}
                              >
                                {props?.description}
                              </Text>
                            );
                          }}
                          placeholder="Insert a text for the issue"
                          inputClassName={styles.TextInputIncidentText}
                          className={
                            this.state.showIncidentError && !this.state.title
                              ? styles.TextInputIncident + " " + styles.Error
                              : styles.TextInputIncident
                          }
                          onChange={(ev, text) => {
                            var incident = this.state.incident;
                            incident.description =
                              text + " - " + this.state.description;
                            this.setState({
                              title: text ? text : "",
                              incident: incident,
                            });
                          }}
                          value={this.state.title}
                        />
                      </div>
                      <br />
                      <div className={styles.TabField}>
                        <div className={styles.LabelIncident}>
                          Description *
                        </div>
                        <TextField
                          aria-label="Description of the issue"
                          multiline
                          resizable={false}
                          rows={3}
                          description={
                            this.state.showIncidentError &&
                            !this.state.description
                              ? "campo obbligatorio"
                              : " "
                          }
                          onRenderDescription={(props) => {
                            return (
                              <Text
                                variant="small"
                                className={styles.LabelErrorMessage}
                              >
                                {props?.description}
                              </Text>
                            );
                          }}
                          placeholder="Insert a short description of the issue"
                          inputClassName={styles.TextInputIncidentText}
                          className={
                            this.state.showIncidentError &&
                            !this.state.description
                              ? styles.TextInputIncident + " " + styles.Error
                              : styles.TextInputIncident
                          }
                          onChange={(ev, text) => {
                            var incident = this.state.incident;
                            incident.description =
                              text + " - " + this.state.description;
                            this.setState({
                              description: text ? text : "",
                              incident: incident,
                            });
                          }}
                          value={this.state.description}
                        />
                      </div>
                      <br />
                      <div
                        className={
                          styles.TabField + " " + styles.TabFieldButton
                        }
                      >
                        <button
                          // disabled={this.state.incident.description.length == 0}
                          onClick={async () => {
                            if (!this.state.title || !this.state.description) {
                              this.setState({ showIncidentError: true });
                            } else {
                              this.setState({
                                showIncidentError: false,
                                isLoading: true,
                              });
                              // await SupportUtility.CreateIncident(
                              //   this.state.incident,
                              //   this.props.footer.webFunctionUrl,
                              //   this.props.footer.functionName,
                              //   this.props.footer.clientId,
                              //   this.props.context
                              // ).then((x) => {
                              //   this.setState({
                              //     showResultMessage: true,
                              //     resultIncident: x,
                              //     isLoading: false,
                              //   });
                              // });
                            }
                          }}
                          className={
                            styles.ButtonField + " " + styles.ButtonPrimaryField
                          }
                        >
                          Send
                        </button>

                        <button
                          // disabled={this.state.incident.description.length == 0}
                          onClick={async () => {
                            this.setState({ showReportModal: false });
                          }}
                          className={
                            styles.ButtonField +
                            " " +
                            styles.ButtonSecondaryField
                          }
                        >
                          Cancel
                        </button>
                      </div>

                      <br />
                    </div>
                  )}
                </div>
              )}
            </Modal>
          </div>
        </div>
      </div>
    );
  }
}
