import * as React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { DeliverablesTranslation } from "../../../../Translations/Deliverables.Translation";
import { Utility } from "../../../../Utility/Utility";
import { LoaderComponent } from "../../../Shared/LoaderComponent/LoaderComponent";
import { RangeCalendar } from "../../../Shared/RangeCalendar/RangeCalendar";
import { VersionHistoryComponent } from "../../../Shared/VersionHistoryComponent/VersionHistoryComponent";
import styles from "./ListAttachments.module.scss";

import {
  ColumnActionsMode,
  ContextualMenu,
  DefaultButton,
  DetailsRow,
  DirectionalHint,
  FontIcon,
  IContextualMenuProps,
  IDetailsListProps,
  IDetailsRowStyles,
  SearchBox,
  SelectionMode,
  Dropdown,
  IDropdownOption,
  ISelectableOption,
  Modal,
  IconButton,
  CommandBarButton,
} from "@fluentui/react";

import {
  DetailsHeader,
  DetailsList,
  IColumn,
  IDetailsHeaderProps,
  IDetailsList,
  IRenderFunction,
  IContextualMenuItem,
  Icon,
} from "office-ui-fabric-react";

import { IOrderBy } from "../../../../Models/Activity";
import { IDateRangeValue } from "../../../Shared/RangeCalendar/IDateRangeValue";
import { IBaseProperties } from "../../../../Models/IBaseProperties";

import {
  IDeliverable,
  IDeliverableFilter,
  IListDeliverables,
  DeliverableFileTypes,
  DeliverableType,
  IDocumentPermission,
  IDeliverableDetails,
} from "../../../../Models/Deliverable";
import { IDeliverableClient } from "../../../../Clients/IDeliverableClient";
import { ToastNotificationType } from "../../../../Models/ToastNote";
import { FormEvent } from "react";
import { ResponsiveEmbed } from "react-bootstrap";
import { PanelActivityHeaderComponent } from "../../../Shared/PanelActivityHeaderComponent/PanelActivityHeaderComponent";
import { SpinnerComponent } from "../../../Shared/SpinnerComponent/SpinnerComponent";

const onRenderOption: IRenderFunction<ISelectableOption> = (option) =>
  option ? (
    <div className={styles.fileTypeOption}>
      {option.data && option.data.icon && (
        <Icon
          iconName={option.data.icon}
          aria-hidden="true"
          title={option.data.icon}
          className={styles.iconFileExtension}
        />
      )}
      <span>{option.text}</span>
    </div>
  ) : null;

export interface IListAttachmentsProps extends IBaseProperties {
  briefAttachments: boolean;
}

export interface IListAttachmentsState {
  attachmentContextualMenuProps?: IContextualMenuProps;
  filteredItems: IDeliverable[];
  permission?: IDocumentPermission;
  // filteredBrief: string[];
  showItemIndexInView: boolean;
  isCompactMode: boolean;
  columns: IColumn[];
  contextualMenuProps?: IContextualMenuProps;
  preserveContextualMenuProps: boolean;
  fileNameSearch: string;
  fileTypeSearch: string[];
  activitySearch: string;
  lastModifyStartSearch?: Date;
  lastModifyEndSearch?: Date;
  lastModifyRangeSearch?: IDateRangeValue;
  lastModifyStringSearch: string;
  lastCount: number;
  isLoading: boolean;
  openHistoryAttachmentVersion: boolean;
  activityId?: number;
  deliverableId: number;
  deliverableFileName: string;

  showPreview: boolean;
  previewLoading: boolean;
  attachmentItemSelectedForPreview?: IDeliverableDetails;
}

const mock: boolean = false;

export class ListAttachments extends React.Component<
  IListAttachmentsProps,
  IListAttachmentsState
> {
  private _root = React.createRef<IDetailsList>();
  private _columns: IColumn[];
  private _observer?: IntersectionObserver;
  private readonly _translation: DeliverablesTranslation;
  private readonly _deliverableClient: IDeliverableClient;

  private readonly ACTIVITY_TYPES = ["brief", "activity"];
  private filteredBrief = this.ACTIVITY_TYPES;
  private readonly _allFileTypes = DeliverableFileTypes.map((t) => t.key);
  private readonly _briefItems: IDropdownOption[] = [
    {
      key: "brief",
      text: "Brief",
    },
    {
      key: "activity",
      text: "Activity",
    },
  ];

  constructor(props: IListAttachmentsProps) {
    super(props);

    this._translation = new DeliverablesTranslation(
      this.props.commonProps.translation
    );

    this._deliverableClient = this.props.commonProps.clientCreator.createDeliverableClient();

    this.bindEvents();

    this._observer = undefined;

    this._columns = this.getColumnsList();

    this.state = {
      // activitySearch: this.ACTIVITY_TYPES,
      filteredItems: [],
      showItemIndexInView: false,
      isCompactMode: false,
      columns: this._columns,
      fileNameSearch: "",
      activitySearch: "",
      fileTypeSearch: this._allFileTypes,
      lastModifyStringSearch: "",
      isLoading: true,
      lastCount: 0,
      deliverableId: 0,
      deliverableFileName: "",
      openHistoryAttachmentVersion: false,
      preserveContextualMenuProps: false,
      showPreview: false,
      previewLoading: true,
      attachmentItemSelectedForPreview: undefined,
    };
  }

  private bindEvents() {
    this.onSearchFileName = this.onSearchFileName.bind(this);
    this.onSearchBriefOrActivity = this.onSearchBriefOrActivity.bind(this);
    this.onChangeFileType = this.onChangeFileType.bind(this);
    // this.onChangeActivityType = this.onChangeActivityType.bind(this);
    this._onRenderDetailsHeader = this._onRenderDetailsHeader.bind(this);
    this._onRenderModalHeader = this._onRenderModalHeader.bind(this);
    this._resetFilter = this._resetFilter.bind(this);
    this._getItems = this._getItems.bind(this);
    this._getCount = this._getCount.bind(this);
    this._handleObserver = this._handleObserver.bind(this);
    this._rangeCalendarChange = this._rangeCalendarChange.bind(this);
    this._onAttachmentContextualMenuDismissed = this._onAttachmentContextualMenuDismissed.bind(
      this
    );
    this._getAttachmentContextualMenuProps = this._getAttachmentContextualMenuProps.bind(
      this
    );
    this._deleteAttachment = this._deleteAttachment.bind(this);
  }

  // private onChangeActivityType(
  //   event: FormEvent<HTMLDivElement>,
  //   option?: IDropdownOption | undefined,
  //   index?: number | undefined
  // ): void {
  //   if (option) {
  //     this.setState(
  //       {
  //         activitySearch: option.selected
  //           ? [...this.state.activitySearch, option.key as string]
  //           : this.state.activitySearch.filter((key) => key !== option.key),
  //         isLoading: true,
  //       },
  //       async () => {
  //         await this.loadAttachmentsAsync();
  //       }
  //     );
  //   }
  // }

  private onChangeFileType(
    event: FormEvent<HTMLDivElement>,
    option?: IDropdownOption | undefined,
    index?: number | undefined
  ): void {
    if (option) {
      this.setState(
        {
          fileTypeSearch: option.selected
            ? [...this.state.fileTypeSearch, option.key as string]
            : this.state.fileTypeSearch.filter((key) => key !== option.key),
          isLoading: true,
          preserveContextualMenuProps: true,
        },
        async () => {
          await this.loadAttachmentsAsync();
        }
      );
    }
  }

  private getColumnsList(): IColumn[] {
    return [
      this.toColumnFileName(),
      this.toColumnFileType(),
      this.toColumnLastUpdate(),
      this.toColumnBrief(),
      this.toColumnMenu(),
    ];
  }

  private get canDownloadDocument(): boolean {
    const { permission } = this.state;
    const canDownload =
      permission && permission.canDownloadDeliverables ? true : false;
    return canDownload;
  }

  private toColumnMenu(): IColumn {
    return {
      key: "prop",
      name: "",
      fieldName: "prop",
      minWidth: 100,
      isResizable: true,
      onRender: (item: IDeliverable) => {
        return this.renderMenuContext(item);
      },
    };
  }

  private renderMenuContext(item: IDeliverable): any {
    return (
      <div className={styles.menuContext}>
        <CommandBarButton
          role="menuitem"
          aria-label="More items"
          className={styles.MenuProps}
          // styles={onRenderOverflowButtonStyles}
          menuIconProps={{ iconName: "MoreVertical" }}
          menuProps={this.state.attachmentContextualMenuProps}
          onClick={(ev: React.MouseEvent<HTMLElement>) => {
            if (item.id) {
              this.setState({
                attachmentContextualMenuProps: this._getAttachmentContextualMenuProps(
                  ev,
                  // item.filePath ? item.filePath : "",
                  // item.fileDownload ? item.fileDownload : "",
                  item.fileName ? item.fileName : "",
                  this.getIconNameByFileExtension(
                    item.fileExtension ? item.fileExtension : ""
                  ),
                  item
                ),
              });
            }
          }}
        />
      </div>
    );
  }

  private toColumnBrief(): IColumn {
    return {
      key: "activityBrief",
      name: this.props.briefAttachments
        ? this._translation.brief
        : this._translation.activity,
      fieldName: "activityBrief",
      minWidth: 200,
      isResizable: true,
      onColumnClick: this._onColumnClick,
      onRender: (item: IDeliverable) => {
        return (
          <div className={styles["DetailsCell--brief"]}>{item.entityName}</div>
        );
      },
    };
  }

  private toColumnLastUpdate(): IColumn {
    return {
      key: "lastUpdate",
      name: this._translation.lastUpdate,
      fieldName: "lastUpdate",
      minWidth: 100,
      isResizable: true,
      onColumnClick: this._onColumnClick,
      onRender: (item: IDeliverable) => {
        return (
          <div className={styles.DetailsCell}>
            {item.lastUpdate &&
              Utility.formatDate(item.lastUpdate, "DD/MM/YYYY")}
          </div>
        );
      },
    };
  }

  private toColumnFileType(): IColumn {
    return {
      key: "fileType",
      name: this._translation.fileType,
      fieldName: "fileType",
      minWidth: 100,
      isResizable: true,
      onColumnClick: this._onColumnClick,
      onRender: (item: IDeliverable) => {
        return (
          <div className={styles["DetailsCell--filetype"]}>
            {/* {this.renderFileIcon(item)}            */}
            {this.toFileExtensionFormatted(item.fileExtension)}
          </div>
        );
      },
    };
  }

  private toFileExtensionFormatted(fileType?: string): string {
    let sFileExtensions = "...";
    if (fileType) {
      sFileExtensions = fileType.startsWith(".", 0) ? fileType : "." + fileType;
    }

    return sFileExtensions;
  }

  private getIconNameByFileExtension(ext: string): string {
    const DEFAULT_ICONNAME = "FileTemplate";
    const sExt = ext.startsWith(".") ? ext.substring(1) : ext;
    const mappedFileTypes = DeliverableFileTypes.filter((t) =>
      t.associatedExtensions.some((t) => t.toLowerCase() === sExt.toLowerCase())
    );
    if (mappedFileTypes && mappedFileTypes.length > 0) {
      return mappedFileTypes[0].iconName;
    } else {
      return DEFAULT_ICONNAME;
    }
  }

  private renderFileIcon(item: IDeliverable): JSX.Element {
    if (item && item.fileExtension) {
      const iconName = this.getIconNameByFileExtension(item.fileExtension);
      const icon = (
        <Icon
          iconName={iconName}
          aria-label={item.fileExtension}
          className="iconFileExtension"
        ></Icon>
      );

      return icon;
    } else {
      return <></>;
    }
  }

  private toColumnFileName(): IColumn {
    return {
      key: "fileName",
      name: (<div>{this._translation.file}</div>) as any,
      fieldName: "fileName",
      minWidth: 100,
      isResizable: true,
      onColumnClick: this._onColumnClick,
      onRender: (item: IDeliverable) => {
        return (
          <div
            className={styles["DetailsCell--filename"]}
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                this.setState({
                  showPreview: true,
                  previewLoading: true,
                  attachmentItemSelectedForPreview: undefined,
                });
                this._deliverableClient
                  .getAttachmentPaths(item.id)
                  .then((x) => {
                    if (x)
                      this.setState({
                        attachmentItemSelectedForPreview: x,
                        previewLoading: false,
                      });
                    else {
                      this.props.commonProps.toastComponent?.showMessage(
                        this._translation.error,
                        this._translation.getDeliverableError,
                        ToastNotificationType.ERROR
                      );
                    }
                  });
              }
            }}
            onClick={() => {
              this.setState({
                showPreview: true,
                previewLoading: true,
                attachmentItemSelectedForPreview: undefined,
              });
              this._deliverableClient.getAttachmentPaths(item.id).then((x) => {
                if (x)
                  this.setState({
                    attachmentItemSelectedForPreview: x,
                    previewLoading: false,
                  });
                else {
                  this.props.commonProps.toastComponent?.showMessage(
                    this._translation.error,
                    this._translation.getDeliverableError,
                    ToastNotificationType.ERROR
                  );
                }
              });
            }}
          >
            {this.renderFileIcon(item)}
            {item.fileName}
          </div>
        );
      },
    };
  }

  private get activityBriefSearch() {
    return this.state.activitySearch;
    // return this.state.activitySearch.length === 1
    //   ? this.state.activitySearch[0]
    //   : "";
  }

  public componentDidMount() {
    this._getItems({
      lastModifyStartDate: this.state.lastModifyStartSearch,
      lastModifyEndDate: this.state.lastModifyEndSearch,
      fileName: this.state.fileNameSearch,
      fileType: [],
      activityBrief: this.activityBriefSearch,
      startIndex: this.state.filteredItems.length,
    }).then((result) => {
      this.setState({
        filteredItems: result.deliverables,
        permission: result.permission,
        showItemIndexInView: false,
        isCompactMode: false,
        columns: this._columns,
        fileNameSearch: "",
        fileTypeSearch: this._allFileTypes,
        lastModifyStringSearch: "",
        activitySearch: "", //this.ACTIVITY_TYPES,
        isLoading: false,
        lastCount: result.deliverables?.length,
      });

      let options = {
        root: null,
        rootMargin: "0px",
        threshold: 1.0,
      };

      this._observer = new IntersectionObserver(
        this._handleObserver.bind(this),
        options
      );
      let obs = document.querySelector(".divInfiniteLoader");
      if (obs) this._observer.observe(obs);
    });
  }

  public componentWillUnmount() {
    if (this.state.showItemIndexInView) {
      const itemIndexInView = this._root.current!.getStartItemIndexInView();
    }
  }

  private _handleObserver(entities: any, observer: any) {
    const y = entities[0];

    if (y.isIntersecting) {
      this.loadAttachmentsAsync(true);

      // this._getItems({
      //   fileName: this.state.fileNameSearch,
      //   activityBrief: this.activityBriefSearch,
      //   lastModifyStartDate: this.state.lastModifyStartSearch,
      //   lastModifyEndDate: this.state.lastModifyEndSearch,
      //   fileType: this.state.fileTypeSearch,
      //   startIndex: this.state.filteredItems.length,
      // }).then((newItems) => {
      //   this.setState({
      //     filteredItems: this.state.filteredItems
      //       ? this.state.filteredItems.concat(newItems.deliverables)
      //       : newItems.deliverables,
      //     lastCount: this.state.filteredItems
      //       ? this.state.filteredItems.length + newItems.deliverables.length
      //       : newItems.deliverables.length,
      //     contextualMenuProps: undefined,
      //   });
      // });
    }
  }

  private _onRenderDetailsHeader(
    props?: IDetailsHeaderProps,
    _defaultRender?: IRenderFunction<IDetailsHeaderProps>
  ) {
    const { columns } = this.state;
    let isSorted: boolean = false;
    columns.forEach((newCol: IColumn) => {
      if (newCol.isSorted) isSorted = true;
    });

    return (
      <div>
        {this.state.activitySearch || //.length !== 2 ||
        this.state.fileNameSearch ||
        this.state.lastModifyStartSearch ||
        isSorted ||
        this.state.fileTypeSearch.length !== this._allFileTypes.length ? (
          <div className={styles.divResetFilter}>
            <DefaultButton
              text={this._translation.resetFilters}
              iconProps={{ iconName: "ClearFilter" }}
              className="btn btn-dismiss"
              onClick={this._resetFilter}
            />
          </div>
        ) : (
          <div></div>
        )}
        <DetailsHeader {...props} layoutMode={0} />
      </div>
    );
  }

  private _onRenderModalHeader() {
    return (
      <PanelActivityHeaderComponent
        commonProps={this.props.commonProps}
        text={this.state.attachmentItemSelectedForPreview?.fileName}
        showContextMenu={false}
        showStatus={false}
        showType={true}
        showManage={false}
      ></PanelActivityHeaderComponent>
    );
  }

  private async _deleteAttachment(
    itemId: number,
    activityId: number
  ): Promise<boolean> {
    this.setState({ isLoading: true });

    await this._deliverableClient.deleteDeliverable(
      activityId.toString(),
      itemId
    );

    let items = await this._getItems({
      fileName: this.state.fileNameSearch,
      activityBrief: this.activityBriefSearch,
      lastModifyStartDate: this.state.lastModifyStartSearch,
      lastModifyEndDate: this.state.lastModifyEndSearch,
      fileType: this.fileTypesFilter,
      startIndex: 0,
    });

    this.setState({ filteredItems: [] }, () => {
      this.setState({
        filteredItems: items.deliverables,
        isLoading: false,
      });
    });

    return true;
  }

  public render() {
    const {
      filteredItems,
      isCompactMode,
      contextualMenuProps,
      attachmentContextualMenuProps,
    } = this.state;

    this._columns = this._getColumns();
    const { permission } = this.state;
    const hasViewPermission: boolean =
      permission && permission.visibility ? true : false;

    return (
      <div>
        <LoaderComponent
          commonProps={this.props.commonProps}
          isLoading={this.state.isLoading}
          label={this._translation.loadingAttachment}
        />

        <Modal
          titleAriaId="File Preview"
          isOpen={this.state.showPreview}
          onDismiss={(ev) => {
            ev?.preventDefault();
            this.setState({ showPreview: false });
          }}
          isBlocking={false}
          containerClassName={
            styles.modalContainer + " " + styles.modalContainerPreview
          }
          className={styles.ModalAssignedResources}
        >
          <div className={styles.modalHeader}>
            {this._onRenderModalHeader()}
            <IconButton
              className={styles.ModalButton}
              iconProps={{ iconName: "Cancel" }}
              aria-label="Close"
              ariaLabel={this._translation.close}
              onClick={(ev: { preventDefault: () => void }) => {
                ev?.preventDefault();
                this.setState({ showPreview: false });
              }}
            />
          </div>
          <hr />
          <div className={styles.modalBody + " " + styles.modalBodyPreview}>
            {!this.state.attachmentItemSelectedForPreview ? (
              // <ResponsiveEmbed aspectRatio="16by9">
              //   <embed
              //     src={
              //       "https://m365x178943.sharepoint.com/sites/MediaPlanningNew/_layouts/15/Doc.aspx?sourcedoc=%7BA7BFB076-DCA1-43C8-822D-CF59ED628014%7D&file=Test.docx&action=interactivepreview&mobileredirect=true"
              //     }
              //   />
              // </ResponsiveEmbed>
              // <img
              //   src={
              //     "https://m365x178943.sharepoint.com/sites/MediaPlanningNew/SiteAssets/No_image_available.png"
              //   }
              //   className={styles.imgPreview}
              // ></img>
              <SpinnerComponent
                commonProps={this.props.commonProps}
                relative={true}
              ></SpinnerComponent>
            ) : this.state.attachmentItemSelectedForPreview?.fileType ==
                DeliverableType.JPG ||
              this.state.attachmentItemSelectedForPreview?.fileType ==
                DeliverableType.PNG ||
              this.state.attachmentItemSelectedForPreview?.fileType ==
                DeliverableType.GIF ? (
              <img
                alt={this._translation.preview}
                className={styles.imgPreview}
                src={this.state.attachmentItemSelectedForPreview.embedUrl}
              ></img>
            ) : this.state.attachmentItemSelectedForPreview?.fileType ==
                DeliverableType.TEXT ||
              this.state.attachmentItemSelectedForPreview?.fileType ==
                DeliverableType.ONENOTE ? (
              <img
                alt={this._translation.preview}
                src={
                  // "https://m365x178943.sharepoint.com/sites/MediaPlanningNew/SiteAssets/No_image_available.png"
                  this.state.attachmentItemSelectedForPreview.embedUrl
                }
                className={styles.imgPreview}
              ></img>
            ) : (
              <ResponsiveEmbed
                aspectRatio="16by9"
                className={styles.embedPreview}
              >
                <embed
                  src={this.state.attachmentItemSelectedForPreview?.embedUrl}
                />
              </ResponsiveEmbed>
              // <img src={"https://m365x178943.sharepoint.com/sites/MediaPlanningNew/SiteAssets/No_image_available.png"} width="100%"></img>
            )}
          </div>
        </Modal>

        {hasViewPermission && (
          <div role="AttachmentsList">
            {!!filteredItems && filteredItems.length > 0 && (
              <DetailsList
                onItemInvoked={(item) => {
                  this.setState({
                    showPreview: true,
                    previewLoading: true,
                    attachmentItemSelectedForPreview: undefined,
                  });
                  this._deliverableClient
                    .getAttachmentPaths(item.id)
                    .then((x) => {
                      if (x)
                        this.setState({
                          attachmentItemSelectedForPreview: x,
                          previewLoading: false,
                        });
                      else {
                        this.props.commonProps.toastComponent?.showMessage(
                          this._translation.error,
                          this._translation.getDeliverableError,
                          ToastNotificationType.ERROR
                        );
                      }
                    });
                }}
                componentRef={this._root}
                items={filteredItems}
                selectionMode={SelectionMode.none}
                //groups={groups}
                className={styles.listActivities /*"listActivities"*/}
                columns={this._columns}
                onRenderDetailsHeader={this._onRenderDetailsHeader}
                groupProps={{
                  showEmptyGroups: true,
                }}
                onRenderItemColumn={this._onRenderColumn}
                compact={isCompactMode}
                onRenderRow={this._onRenderRow}
              />
            )}
            {(!filteredItems || filteredItems.length === 0) && (
              <div
                className={
                  "ms-Grid-col ms-xl3 ms-lg6 ms-md12 ms-sm12 paddingTop10 " +
                  styles.noActivitiedFound
                }
              >
                {this._translation.noAttachmentFound}
              </div>
            )}
          </div>
        )}
        {contextualMenuProps && <ContextualMenu {...contextualMenuProps} />}

        {attachmentContextualMenuProps && (
          <ContextualMenu {...attachmentContextualMenuProps} />
        )}

        <div className={"divInfiniteLoader"}></div>

        {this.state.openHistoryAttachmentVersion && (
          <VersionHistoryComponent
            hideTitle={true}
            commonProps={this.props.commonProps}
            // item={this.props.item}
            activityId={this.state.activityId}
            deliverableId={this.state.deliverableId}
            fileName={this.state.deliverableFileName}
            canUpdateVersion={true}
            // onRenderModalHeader={this.props.onRenderModalHeader}
            onRenderModalHeader={() =>
              this.renderHistoryPanelHeader(this._translation.getVersionHistory)
            }
            hideHistory={() => {
              this.setState({ openHistoryAttachmentVersion: false });
            }}
            calendar={-1}            
          ></VersionHistoryComponent>
        )}
      </div>
    );
  }

  private renderHistoryPanelHeader(headerText: string): JSX.Element {
    return <div>{headerText}</div>;
  }

  private _resetFilter() {
    const { columns } = this.state;
    const newColumns: IColumn[] = columns.slice();
    newColumns.forEach((newCol: IColumn) => {
      newCol.isSorted = false;
      newCol.isSortedDescending = true;
    });

    this.setState(
      {
        fileNameSearch: "",
        activitySearch: "", //this.ACTIVITY_TYPES,
        fileTypeSearch: this._allFileTypes,
        lastModifyStringSearch: "",
        lastModifyStartSearch: undefined,
        lastModifyEndSearch: undefined,
        lastModifyRangeSearch: undefined,
        isLoading: true,
      },
      async () => await this.loadAttachmentsAsync()
    );
  }

  private _onRenderColumn(
    item?: IDeliverable,
    index?: number,
    column?: IColumn
  ) {
    const value =
      item && column && column.fieldName
        ? item[column.fieldName as keyof IDeliverable] || ""
        : "";

    return <div data-is-focusable={true}>{value}</div>;
  }

  private _onRenderRow: IDetailsListProps["onRenderRow"] = (props) => {
    let result: any = null;
    if (props) {
      result = (
        <div className={styles.divRow}>
          <div className={styles.divSelection + " hidden-tablet"} style={{marginLeft:"50px"}}>
            {/* <label className={""}></label> */}
          </div>
          <div className={styles.DetailsRowNoMicro}>
            <DetailsRow
              {...props}
              key={"Attachment" + props.item.id}
              className={styles.DeliverablesRow + " ms-MainRow"}
            />
          </div>
        </div>
      );
    }
    return result;
  };

  private _onColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn
  ): void => {
    if (column.columnActionsMode !== ColumnActionsMode.disabled) {
      this._getContextualMenuProps(ev, column).then((contextMenuProps) => {
        this.setState({
          contextualMenuProps: contextMenuProps,
        });
      });
    }
  };

  private _onContextualMenuDismissed = (ev: any, dp: any): void => {
    this.setState({
      contextualMenuProps: undefined,
    });
  };

  private _getColumns(): IColumn[] {
    let fileName = this.state.fileNameSearch
      ? ((
          <div>
            {this._translation.fileName}{" "}
            <FontIcon
              iconName="Filter"
              className={styles.iconFilter}
            ></FontIcon>
          </div>
        ) as any)
      : this._translation.fileName;
    let fileType =
      this.state.fileTypeSearch.length !== this._allFileTypes.length
        ? ((
            <div>
              {this._translation.fileType}{" "}
              <FontIcon
                iconName="Filter"
                className={styles.iconFilter}
              ></FontIcon>
            </div>
          ) as any)
        : this._translation.fileType;
    let lastModify =
      this.state.lastModifyEndSearch && this.state.lastModifyStartSearch
        ? ((
            <div>
              {this._translation.createdOn}{" "}
              <FontIcon
                iconName="Filter"
                className={styles.iconFilter}
              ></FontIcon>
            </div>
          ) as any)
        : this._translation.createdOn;
    let activityBrief = this.state.activitySearch
      ? ((
          <div>
            {this.props.briefAttachments
              ? this._translation.brief
              : this._translation.activity}{" "}
            <FontIcon
              iconName="Filter"
              className={styles.iconFilter}
            ></FontIcon>
          </div>
        ) as any)
      : this.props.briefAttachments
      ? this._translation.brief
      : this._translation.activity;

    let columns: IColumn[] = Object.assign([], this.state.columns);
    columns[0].name = fileName;
    columns[1].name = fileType;
    columns[2].name = lastModify;
    columns[3].name = activityBrief;
    columns[0].ariaLabel = fileName;
    columns[1].ariaLabel = fileType;
    columns[2].ariaLabel = lastModify;
    columns[3].ariaLabel = activityBrief;

    return columns;
  }

  private async loadAttachmentsAsync(isObserver?: boolean): Promise<void> {
    try {
      const items = await this._getItems(this.toIDeliverableFilter(isObserver));
      const { preserveContextualMenuProps, contextualMenuProps } = this.state;
      this.setState({
        permission: items.permission,
        filteredItems: isObserver
          ? this.state.filteredItems
            ? this.state.filteredItems.concat(items.deliverables)
            : items.deliverables
          : items.deliverables,
        lastCount: isObserver
          ? this.state.filteredItems
            ? this.state.filteredItems.length + items.deliverables.length
            : items.deliverables.length
          : this.state.lastCount,
        contextualMenuProps: preserveContextualMenuProps
          ? contextualMenuProps
          : undefined,
      });
    } catch (err) {
      console.error(err);
      this.props.commonProps.toastComponent?.showMessage(
        this._translation.error,
        this._translation.loadingAttachmentError,
        ToastNotificationType.ERROR
      );
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  private toIDeliverableFilter(isObserver?: boolean): IDeliverableFilter {
    return {
      fileName: this.state.fileNameSearch,
      fileType: this.fileTypesFilter,
      activityBrief: this.state.activitySearch,
      lastModifyStartDate: this.state.lastModifyStartSearch,
      lastModifyEndDate: this.state.lastModifyEndSearch,
      startIndex: isObserver ? this.state.filteredItems.length : 0,
    };
  }

  private get fileTypesFilter(): string[] {
    const keys: string[] =
      this.state.fileTypeSearch.length === this._allFileTypes.length
        ? []
        : this.state.fileTypeSearch;
    if (keys.length > 0) {
      const values = keys.flatMap((fkey) => {
        return DeliverableFileTypes.filter((t) => t.key === fkey)[0]
          .associatedExtensions;
      });

      return values;
    } else {
      return [];
    }
  }

  private async _getItems(
    filter: IDeliverableFilter
  ): Promise<IListDeliverables> {
    const emptyResult = {
      count: 0,
      deliverables: [],
      permission: {
        visibility: false,
        canAdd: false,
        canDelete: false,
        canDownloadDeliverables: false,
      },
    };
    const columns = filter.orderBy ? filter.orderBy : this.state.columns;
    const orderBy: (IOrderBy | undefined)[] = this.toOrderByClause(columns);

    if (!mock) {
      const items = await this._deliverableClient.getAttachments(
        this.props.briefAttachments,
        filter,
        orderBy
      );
      if (items) {
        const retItems = items.deliverables ? items : emptyResult;
        if (retItems.deliverables.length === 0) {
          // this.props.commonProps.toastComponent?.showMessage(
          //   this._translation.info,
          //   this._translation.noAttachmentFound,
          //   ToastNotificationType.INFO
          // );
        }
        return retItems;
      } else {
        this.props.commonProps.toastComponent?.showMessage(
          this._translation.error,
          this._translation.loadingAttachmentError,
          ToastNotificationType.ERROR
        );
        return emptyResult;
      }
    } else {
      return emptyResult;
    }
  }

  private toOrderByClause(columns: IColumn[]): (IOrderBy | undefined)[] {
    return columns.map((column: IColumn) => {
      if (column.isSorted) {
        switch (column.fieldName) {
          case "fileName":
            return {
              field: "f",
              type: column.isSortedDescending ? "DESC" : "ASC",
            };
          case "fileType":
            return {
              field: "ft",
              type: column.isSortedDescending ? "DESC" : "ASC",
            };
          case "activityBrief":
            return {
              field: "ba",
              type: column.isSortedDescending ? "DESC" : "ASC",
            };
          case "lastUpdate":
            return {
              field: "co",
              type: column.isSortedDescending ? "DESC" : "ASC",
            };
          default:
            return undefined;
        }
      }
    });
  }

  private async _getCount(filter: IDeliverableFilter): Promise<number> {
    const items = this._getItems(filter);

    return (await items).count;
  }

  private onSearchFileName(newValue: any): void {
    if (newValue && newValue.length >= 3) {
      this.setState(
        {
          isLoading: true,
          fileNameSearch: newValue,
        },
        async () => {
          await this.loadAttachmentsAsync();
        }
      );
    } else if (!newValue) {
      this.setState(
        {
          isLoading: true,
          fileNameSearch: "",
        },
        async () => {
          await this.loadAttachmentsAsync();
        }
      );
    }
  }

  private onSearchBriefOrActivity(newValue: any): void {
    if (newValue && newValue.length >= 3) {
      this.setState(
        {
          isLoading: true,
          activitySearch: newValue,
        },
        async () => {
          await this.loadAttachmentsAsync();
        }
      );
    } else if (!newValue) {
      this.setState(
        {
          isLoading: true,
          activitySearch: "",
        },
        async () => {
          await this.loadAttachmentsAsync();
        }
      );
    }
  }

  private _renderContextMenuFileName(item: IDeliverable): any {
    return (
      <div className={styles.DetailsCell}>
        <SearchBox
          className={"SearchBox"}
          value={this.state.fileNameSearch}
          placeholder={
            this.state.fileNameSearch
              ? this.state.fileNameSearch +
                " (" +
                this.state.filteredItems.length +
                ")"
              : this._translation.searchFile
          }
          onSearch={this.onSearchFileName}
        />
      </div>
    );
  }

  private _renderContextMenuBriefActivity(item: IDeliverable): any {
    return (
      <div className={styles.DetailsCell}>
        <SearchBox
          className={"SearchBox"}
          value={this.state.activitySearch}
          placeholder={
            this.state.activitySearch
              ? this.state.activitySearch +
                " (" +
                this.state.filteredItems.length +
                ")"
              : this.props.briefAttachments
              ? this._translation.searchBrief
              : this._translation.searchActivity
          }
          onSearch={this.onSearchBriefOrActivity}
        />
      </div>
      // <div className={styles.activityTypesSearch}>
      //   <Dropdown
      //     className={"dropDownSearch"}
      //     // styles = {this.dropdownStyles}
      //     multiSelect
      //     defaultSelectedKeys={this.state.activitySearch}
      //     options={this._briefItems}
      //     onChange={this.onChangeActivityType}
      //   />
      // </div>
    );
  }

  private _renderContextMenuLastModify(item: IDeliverable): any {
    return (
      <div className={styles.DetailsCell}>
        <RangeCalendar
          commonProps={this.props.commonProps}
          showTime={false}
          disableTime={false}
          onChange={this._rangeCalendarChange}
          value={this.state.lastModifyRangeSearch}
        ></RangeCalendar>
      </div>
    );
  }

  private _rangeCalendarChange(newValue?: IDateRangeValue | undefined) {
    if (newValue) {
      this.setState(
        {
          lastModifyStringSearch: newValue.fromDate
            ? Utility.FormatDate(newValue.fromDate) +
              " to " +
              Utility.FormatDate(newValue.toDate)
            : "",
          lastModifyStartSearch: newValue.fromDate,
          lastModifyEndSearch: newValue.toDate,
          lastModifyRangeSearch: newValue,
          contextualMenuProps: undefined,
          isLoading: true,
        },
        async () => {
          await this.loadAttachmentsAsync();
        }
      );
    } else {
      this.setState(
        {
          isLoading: true,
          lastModifyStringSearch: "",
          lastModifyStartSearch: undefined,
          lastModifyEndSearch: undefined,
          lastModifyRangeSearch: undefined,
          contextualMenuProps: undefined,
        },
        async () => {
          await this.loadAttachmentsAsync();
        }
      );
    }
  }

  private async _getContextualMenuProps(
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn
  ): Promise<IContextualMenuProps> {
    let target = ev.currentTarget;

    let items: IContextualMenuItem[] =
      column.key === "type"
        ? []
        : [
            {
              key: "aToZ",
              name: "A to Z",
              iconProps: { iconName: "SortUp" },
              canCheck: true,
              checked: column.isSorted && !column.isSortedDescending,
              onClick: () => this._onSortColumn(column.key, false),
            },
            {
              key: "zToA",
              name: "Z to A",
              iconProps: { iconName: "SortDown" },
              canCheck: true,
              checked: column.isSorted && column.isSortedDescending,
              onClick: () => this._onSortColumn(column.key, true),
            },
            {
              key: "Divider",
              name: "Divider",
              canCheck: false,
              onRender: (item: IDeliverable) => {
                return (
                  <div className={styles.DetailsCell}>
                    <hr />
                  </div>
                );
              },
            },
          ];

    if (column.key === "fileName")
      items.push({
        key: "searchFileName",
        name: this._translation.searchFile,
        onRender: (item: IDeliverable) => this._renderContextMenuFileName(item),
      });
    else if (column.key === "fileType") {
      items.push({
        key: "fileTypes",
        name: "File types",
        onRender: (item: IDeliverable) => this._renderContextMenuFileType(item),
      });
    } else if (column.key === "lastUpdate") {
      items.push({
        key: "searchDates",
        name: this._translation.selectDate,
        canCheck: false,
        target: "DPO",
        onRender: (item: IDeliverable) =>
          this._renderContextMenuLastModify(item),
      });
    } else if (column.key === "activityBrief")
      items.push({
        key: "searchActivity",
        name: "Search Activity",
        onRender: (item: IDeliverable) =>
          this._renderContextMenuBriefActivity(item),
      });

    return {
      items: items,
      target: target as HTMLElement,
      directionalHint: DirectionalHint.bottomLeftEdge,
      gapSpace: 10,
      isBeakVisible: false,
      onDismiss:
        column.key === "NO_DISMISS_SUPPORTED"
          ? undefined
          : this._onContextualMenuDismissed,
    };
  }

  private getFileTypesOptions(): IDropdownOption[] {
    return DeliverableFileTypes.map((t) => {
      return { key: t.key, text: t.name, data: { icon: t.iconName } };
    });
  }

  onRenderOption2(option: IDropdownOption): JSX.Element {
    return (
      <div>
        {option.data && option.data.icon && (
          <Icon
            iconName={option.data.icon}
            aria-hidden="true"
            title={option.data.icon}
          />
        )}
        <span>{option.text}</span>
      </div>
    );
  }

  _renderContextMenuFileType(item: IDeliverable): React.ReactNode {
    return (
      <div className={styles.fileTypesSearch}>
        <Dropdown
          className={"dropDownSearch"}
          key={item.id}
          multiSelect
          defaultSelectedKeys={this.state.fileTypeSearch}
          options={this.getFileTypesOptions()}
          onRenderOption={onRenderOption}
          onChange={this.onChangeFileType}
        ></Dropdown>
      </div>
    );
  }

  private _onAttachmentContextualMenuDismissed = (ev: any, dp: any): void => {
    this.setState({
      attachmentContextualMenuProps: undefined,
    });
  };

  private _getAttachmentContextualMenuProps(
    ev: React.MouseEvent<HTMLElement>,
    filePath: string,
    iconName: string,
    deliverable: IDeliverable
  ): IContextualMenuProps {
    if (this.canDownloadDocument) {
      let items: IContextualMenuItem[] = [
        {
          key: "openAttachment",
          name: this._translation.openInBrowser,
          iconProps: { iconName: iconName },
          canCheck: false,
          // checked: column.isSorted && !column.isSortedDescending,
          onClick: () => {
            if (filePath) {
              // this.openFile(filePath, fileName);
              this.openFile(deliverable.id);
            }
          },
          // href: filePath
        },
        {
          key: "downloadAttachment",
          name: this._translation.download,
          iconProps: { iconName: "Download" },
          canCheck: false,
          // checked: column.isSorted && !column.isSortedDescending,
          onClick: () => {
            // this.downloadFile(fileDownload, fileName);
            this.downloadFile(deliverable.id);
            //todo
            // if (deliverable.idActivity && deliverable.fileName && deliverable.fileType)
            // {
            //   this.downloadFileByAPI(deliverable.idActivity, deliverable.id, deliverable.fileName,deliverable.fileType);
            // }
          },
          // href: filePath
        },
        {
          key: "historyMenu",
          // subMenuProps: {
          //   items: this.toHistoryMenuItems(deliverable),
          // },
          text: this._translation.getVersionHistory,
          iconProps: { iconName: "FullHistory" },
          onClick: () => {
            this.openHistoryPanel(deliverable);
            //todo
            // if (deliverable.idActivity && deliverable.fileName && deliverable.fileType)
            // {
            //   this.downloadFileByAPI(deliverable.idActivity, deliverable.id, deliverable.fileName,deliverable.fileType);
            // }
          },
        },
        //todo, currently, it is not possible to print
        // {
        //   key: "printAttachment",
        //   name: "Print",
        //   iconProps: { iconName: "Print" },
        //   canCheck: false,
        //   // checked: column.isSorted && !column.isSortedDescending,
        //   // onClick: () => this._deleteAttachment(itemId, activityId),
        // },
      ];

      return {
        items: items,
        target: ev.currentTarget as HTMLElement,
        directionalHint: DirectionalHint.bottomLeftEdge,
        gapSpace: 10,
        isBeakVisible: false,
        onDismiss: this._onAttachmentContextualMenuDismissed,
      };
    } else {
      return {
        items: [],
        target: ev.currentTarget as HTMLElement,
        directionalHint: DirectionalHint.bottomLeftEdge,
        gapSpace: 10,
        isBeakVisible: false,
        onDismiss: this._onAttachmentContextualMenuDismissed,
      };
    }
  }

  openHistoryPanel(deliverable: IDeliverable) {
    this.setState({
      openHistoryAttachmentVersion: true,
      deliverableId: deliverable.id,
      deliverableFileName: deliverable.fileName
        ? deliverable.fileName
        : "Deliverable",
      activityId: deliverable.entityId
        ? deliverable.entityId
        : deliverable.idBrief,
    });
  }

  // private toHistoryMenuItems(deliverable: IDeliverable): IContextualMenuItem[]
  // {
  //   const values:IContextualMenuItem[] = deliverable.attachmentHistory
  //   .map((t)=> this.toHistoryMenuItem(t));
  //   return values;

  // }

  // private toHistoryMenuItem(attachment: IAttachment): IContextualMenuItem
  // {
  //   const value: IContextualMenuItem =
  //     { key: attachment.fileName, text: attachment.fileName, title: 'Download', onClick: ()=>{this.downloadFile(attachment.fileDownload, attachment.fileName);} };
  //     return value
  // }

  private _onSortColumn = (
    columnKey: string,
    isSortedDescending: boolean
  ): void => {
    const { columns, filteredItems } = this.state;
    const newColumns: IColumn[] = columns.slice();
    const currColumn: IColumn = newColumns.filter(
      (currCol) => columnKey === currCol.key
    )[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    this.setState(
      {
        columns: newColumns,
        lastCount: 0,
        contextualMenuProps: undefined,
        isLoading: true,
      },
      async () => await this.loadAttachmentsAsync()
    );

    // this._copyAndSort(
    //   filteredItems,
    //   currColumn.fieldName!,
    //   currColumn.isSortedDescending
    // );
  };

  private openFile(idAttachment: number) {
    //(filePath: string, fileName: string) {
    this._deliverableClient.getAttachmentPaths(idAttachment).then((x) => {
      if (x && x.filePath) this.createTemporaryHREF(x.filePath, "_blank");
      else {
        this.props.commonProps.toastComponent?.showMessage(
          this._translation.error,
          this._translation.getDeliverableError,
          ToastNotificationType.ERROR
        );
      }
    });
  }

  private downloadFile(idAttachment: number) {
    //(fileDownload: string, fileName: string) {
    this._deliverableClient.getAttachmentPaths(idAttachment).then((x) => {
      if (x && x.fileDownload)
        this.createTemporaryHREF(x.fileDownload, "_blank");
      else {
        this.props.commonProps.toastComponent?.showMessage(
          this._translation.error,
          this._translation.getDeliverableError,
          ToastNotificationType.ERROR
        );
      }
    });
    // this.createTemporaryHREF(fileDownload, "_self");
  }

  private createTemporaryHREF(href: string, target: string) {
    const link = document.createElement("a");
    link.target = target;
    link.href = href;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  private downloadFileByAPI(
    idActivity: number,
    idDeliverable: number,
    fileName: string,
    fileType: DeliverableType
  ) {
    this._deliverableClient
      .downloadAttachment(idActivity, idDeliverable)
      .then((file) => {
        if (file == undefined) {
          this.props.commonProps.toastComponent?.showMessage(
            this._translation.error,
            this._translation.getDeliverableError,
            ToastNotificationType.ERROR
          );
        } else {
          //var blob = new Blob([file], { type: "application/pdf" });
          var binary_string = window.atob(file);
          var len = binary_string.length;
          var bytes = new Uint8Array(len);
          for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
          }
          var link = document.createElement("a");
          var type = "";
          switch (fileType) {
            case DeliverableType.EXCEL: {
              type = "application/excel";
              break;
            }
            case DeliverableType.GIF: {
              type = "image/gif";
              break;
            }
            case DeliverableType.JPG: {
              type = "image/jpeg";
              break;
            }
            case DeliverableType.ONENOTE: {
              type = "application/octet-stream";
              break;
            }
            case DeliverableType.PDF: {
              type = "application/pdf";
              break;
            }
            case DeliverableType.PNG: {
              type = "image/png";
              break;
            }
            case DeliverableType.POWERPOINT: {
              type = "application/mspowerpoint";
              break;
            }
            case DeliverableType.TEXT: {
              type = "text/plain";
              break;
            }
            case DeliverableType.WORD: {
              type = "application/msword";
              break;
            }
            default: {
              type = "application/octet-stream";
              break;
            }
          }
          var blob = new Blob([bytes], { type: type });
          link.href = window.URL.createObjectURL(blob);
          link.download = fileName ? fileName : "doc";
          link.click();
        }
      });
  }

  private downloadFile2(filePath: string, fileName: string) {
    // const link = document.createElement("a");
    // // If you don't know the name or want to use
    // // the webserver default set name = ''
    // link.setAttribute('download', "name.pdf");
    // link.href = filePath;
    // document.body.appendChild(link);
    // link.click();
    // link.remove();

    const cors = "https://cors-anywhere.herokuapp.com/";
    const headers: Headers = new Headers();
    headers.append("Access-Control-Allow-Origin", "http://localhost:3000");
    headers.append("Access-Control-Allow-Credentials", "true");
    const uri = cors + filePath;

    fetch(uri, {
      method: "GET",
      mode: "cors",
      headers: headers,
    })
      .then((response) => response.blob())
      .then((blob) => {
        const blobURL = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = blobURL;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch(() => {
        const downloaderror = `Can’t access ${filePath} response. Blocked by browser`;
        this.props.commonProps.toastComponent?.showMessage(
          "Download error",
          downloaderror,
          ToastNotificationType.ERROR
        );
        console.log(downloaderror);
      });
  }
}
