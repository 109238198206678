import "react-app-polyfill/ie11";
import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { App } from "./App";
import { AppNew } from "./AppNew";
// import registerServiceWorker from "./registerServiceWorker";
import { initReactI18next, I18nextProvider } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import i18next from "i18next";

import common_it from "./Translations/it/common.json";
import common_en from "./Translations/en/common.json";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");

const resources = {
  en: {
    common: common_en,
    messages: common_en
  },
  it: {
    common: common_it,
    messages: common_it
  },
};

const i18n = i18next.use(LanguageDetector).use(initReactI18next);
i18n.init({
  react: {
    wait: true,
  },
  resources: resources,
  lng: "en",
  fallbackLng: "en",
  keySeparator: ".",
  interpolation: {
    escapeValue: false,
  },
  ns: ["messages"],
  defaultNS: "messages",
  fallbackNS: [],
});
i18n.loadNamespaces("common");

// i18next.init({
//   interpolation: { escapeValue: false }, // React already does escaping
//   lng: "en", // language to use
//   resources: {},
// });

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <I18nextProvider i18n={i18n}>
      {/* <App /> */}
      <AppNew></AppNew>
    </I18nextProvider>
  </BrowserRouter>,
  rootElement
);

// registerServiceWorker();
