import { TFunction } from "i18next";
import { CommonTranslation } from "../../../Translations/Common.Translation";

export class RangeCalendarTranslation extends CommonTranslation
{    
    constructor(translation: TFunction)
    {
        super(translation, "dateRangeComponent");        
    }   

    public get select(): string
    {
        return super.getTextByMessageKey("select");
    }

    public get reset(): string
    {
        return super.getTextByMessageKey("reset");
    }

    public get apply(): string
    {
        return super.getTextByMessageKey("apply");
    }

    public get from(): string
    {
        return super.getTextByMessageKey("from");
    }

    public get to(): string
    {
        return super.getTextByMessageKey("to");
    }
}