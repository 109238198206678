import {
  IBasePickerStyles,
  IButtonStyles,
  ICheckboxStyles,
  IDropdownStyles,
  IIconProps,
  ILabelStyles,
  IRawStyle,
  IStyle,
  ITextFieldStyles,
  mergeStyleSets,
  getTheme,
  FontWeights,
  ITextStyles,
  IComboBoxStyles,
  IDatePickerStyles,
  IToggleStyles,
} from "@fluentui/react";
import { Colors } from "./Colors";

const theme = getTheme();

export class Constants {
  static PageDashboard: string = "Dashboard";
  static PageMyBrief: string = "MyBrief";
  static PageActivities: string = "Activities";
  static PageFAQ: string = "FAQ";
  static PageContacts: string = "Contacts";
  static PageWhoWeAre: string = "WhoWeAre";
  static PagePlanner: string = "Planner";
  static PageReport: string = "Report";
  static PageRouting: string = "Routing";
  static PageLegal: string = "Legal";
  static PagePrivacy: string = "Privacy";
  static PageCookie: string = "Cookie";
  static PageSupport: string = "Support";
  static PageAttachments: string = "Attachments";
  static ActivityStatus = [
    "Planned",
    // "Draft",
    "In progress",
    // "Approved",
    // "Rework",
    // "Ready for Distribution",
    // "Distributed",
    "Closed",
  ];
  static views: string[] = ["Day", "Week", "Month", "Year"];

  //#region fluentui styles
  public static dropdownCalendar: IStyle = {
    width: 150,
    marginLeft: 16,
  };
  public static dropdownCalendar1: IStyle = {
    width: 150,
    display: "inline-block",
    position: "absolute",
    left: 380,
    top: 22,
  };
  public static dropdownCalendar2: IStyle = {
    width: 150,
    display: "inline-block",
    position: "absolute",
    left: 540,
    top: 22,
  };
  public static dropdownTitle: IStyle = {
    background: "#EFF2F7 0% 0% no-repeat padding-box",
    borderRadius: 4,
    border: "none",
    textAlign: "left",
    font: "normal normal bold 14px/14px Arial",
    letterSpacing: "0.14px",
    color: Colors.GreyDark,
    padding: "8px 28px 8px 16px",
    ":hover": {
      color: "#0152E8 !important",
      outline: "0",
    },
    ":focus": {
      color: "#0152E8 !important",
      outline: "0",
    },
  };
  public static dropdownItemsWrapper: IStyle = {
    marginTop: 4,
  };
  public static dropdowncaretDown: IStyle = {
    color: "#0152E8",
    fontWeight: "bold",
    ":hover:focus": {
      color: "#0152E8",
      fontWeight: "bold",
    },
  };
  public static dropdowncaretDownWrapper: IStyle = {
    color: "#0152E8",
    fontWeight: "bold",
    ":hover:focus": {
      color: "#0152E8",
      fontWeight: "bold",
    },
  };
  public static dropdownItem: IStyle = {
    color: Colors.GreyDark,
    paddingLeft: 16,
    margin: 4,
    ":hover:focus": {
      color: "#0152E8",
      background: "#F7F8FB 0% 0% no-repeat padding-box",
      width: "95%",
      borderRadius: 4,
      border: "none",
      outline: "none",
    },
  };
  public static dropdownItemSelected: IStyle = {
    color: "#0152E8",
    background: "#F7F8FB 0% 0% no-repeat padding-box",
    margin: 4,
    paddingLeft: 16,
    width: "95%",
    borderRadius: 4,
    ":hover:focus": {
      color: "#0152E8",
      background: "#F7F8FB 0% 0% no-repeat padding-box",
      border: "none",
      outline: "none",
    },
  };

  public static dropdownViewStyles: Partial<IDropdownStyles> = {
    dropdown: { minWidth: 190, marginLeft: 16, backgroundColor: "#ffffff" },
    title: Constants.dropdownTitle,
    caretDown: Constants.dropdowncaretDown,
    caretDownWrapper: Constants.dropdowncaretDownWrapper,
    dropdownItemsWrapper: Constants.dropdownItemsWrapper,
    dropdownItem: {
      color: Colors.GreyDark,
      paddingLeft: 16,
      margin: 4,
      selectors: {
        "&:hover": {
          background: "#ffffff",
          color: "#0152E8",
        },
      },
      //     ':hover:focus':{
      //     color: "#0152E8",
      //     background: "#F7F8FB 0% 0% no-repeat padding-box",
      //     width: '95%',
      //     borderRadius: 4,
      //     border: "none",
      //     outline: "none"
      // }
    },
    dropdownItemSelected: {
      color: "#0152E8",
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      margin: 4,
      paddingLeft: 16,
      width: "95%",
      borderRadius: 4,
      selectors: {
        "&:active": {
          background: "#ffffff",
          color: "#0152E8",
        },
        "&:focus": {
          background: "#ffffff",
          color: "#0152E8",
        },
      },
    },
  };

  public static dropdownFilterStyles: Partial<IDropdownStyles> = {
    dropdown: { minWidth: 190, marginLeft: 16, backgroundColor: "#ffffff" },
    title: Constants.dropdownTitle,
    caretDown: Constants.dropdowncaretDown,
    caretDownWrapper: Constants.dropdowncaretDownWrapper,
    dropdownItemsWrapper: Constants.dropdownItemsWrapper,
    dropdownItem: {
      color: Colors.GreyDark,
      paddingLeft: 16,
      margin: 4,
      //     ':hover:focus':{
      //     color: "#0152E8",
      //     background: "#F7F8FB 0% 0% no-repeat padding-box",
      //     width: '95%',
      //     borderRadius: 4,
      //     border: "none",
      //     outline: "none"
      // }
    },
    dropdownItemSelected: {
      color: "#0152E8",
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      margin: 4,
      paddingLeft: 16,
      width: "95%",
      borderRadius: 4,
    },
  };

  public static dropdownCalendarStyles: Partial<IDropdownStyles> = {
    dropdown: Constants.dropdownCalendar,
    title: Constants.dropdownTitle,
    caretDown: Constants.dropdowncaretDown,
    caretDownWrapper: Constants.dropdowncaretDownWrapper,
    dropdownItemsWrapper: Constants.dropdownItemsWrapper,
    dropdownItem: Constants.dropdownItem,
    dropdownItemSelected: Constants.dropdownItemSelected,
  };
  public static dropdownCalendarStyles1: Partial<IDropdownStyles> = {
    dropdown: Constants.dropdownCalendar1,
    title: Constants.dropdownTitle,
    caretDown: Constants.dropdowncaretDown,
    caretDownWrapper: Constants.dropdowncaretDownWrapper,
    dropdownItemsWrapper: Constants.dropdownItemsWrapper,
    dropdownItem: Constants.dropdownItem,
    dropdownItemSelected: Constants.dropdownItemSelected,
  };
  public static dropdownCalendarStyles2: Partial<IDropdownStyles> = {
    dropdown: Constants.dropdownCalendar2,
    title: Constants.dropdownTitle,
    caretDown: Constants.dropdowncaretDown,
    caretDownWrapper: Constants.dropdowncaretDownWrapper,
    dropdownItemsWrapper: Constants.dropdownItemsWrapper,
    dropdownItem: Constants.dropdownItem,
    dropdownItemSelected: Constants.dropdownItemSelected,
  };

  public static iconHoverFocus: IStyle = {
    background: "#0152E8 0% 0% no-repeat padding-box;",
    borderRadius: 4,
    color: "white",
    outline: "none",
  };
  public static iconCalendarStyle: Partial<IButtonStyles> = {
    root: {
      marginRight: 8,
      background: "#EFF2F7 0% 0% no-repeat padding-box",
      borderRadius: 4,
      color: "#0152E8",
    },
    icon: {
      fontSize: 20,
      lineHeight: 20,
    },
    rootHovered: Constants.iconHoverFocus,
    rootFocused: Constants.iconHoverFocus,
  };
  public static iconCalendarSelStyle: Partial<IButtonStyles> = {
    root: {
      marginRight: 8,
      background: "#0152E8 0% 0% no-repeat padding-box",
      borderRadius: 4,
      color: Colors.White,
    },
    icon: {
      fontSize: 20,
      lineHeight: 20,
    },
    rootHovered: Constants.iconHoverFocus,
    rootFocused: Constants.iconHoverFocus,
  };

  private static panelControStyle: IRawStyle = {
    borderColor: Colors.Grey,
    // color: "#0152E8",
    // font: "normal normal normal 12px/12px Arial",
  };

  public static textFieldStyle: Partial<ITextFieldStyles> = {
    fieldGroup: [
      {
        borderColor: Colors.Grey,
        height: "48px",
        borderRadius: "4px",
      },
    ],
    field: {
      color: Colors.Black,
      fontFamily: "Arial",
      fontSize: "18px",
      letterSpacing: "0.36px",
    },
  };

  public static datePickerFieldStyle: Partial<IDatePickerStyles> = {
    wrapper: {
      height: "48px",
      // fieldGroup:
      // {
      //     borderColor: Colors.Grey,
      //     height: "48px",
      //     borderRadius: "4px",
      // },

      // field:
      // {
      //     color: Colors.Black,
      //     fontFamily: "Arial",
      //     fontSize: "18px",
      //     letterSpacing: "0.36px",

      // },
    },
  };

  public static textFieldLargeStyle: Partial<ITextFieldStyles> = {
    fieldGroup: [
      {
        borderColor: Colors.Grey,
        height: "72px",
        borderRadius: "4px",
      },
    ],
    field: {
      color: Colors.Black,
      font: "normal normal bold 28px/40px Arial",
      // fontSize: "28px",
      letterSpacing: "0.28px",
    },
  };

  public static timelineTextFieldSearchStyle: Partial<ITextFieldStyles> = {
    fieldGroup: [
      {
        borderColor: Colors.Grey,
        height: "48px",
        borderRadius: "4px",
        margin: "auto",
      },
    ],
    field: {
      color: Colors.Black,
      fontFamily: "Arial",
      fontSize: "18px",
      letterSpacing: "0.36px",
    },
    icon: {
      top: "14px",
    },
  };

  public static get textFieldDateRangeStyle(): Partial<ITextFieldStyles> {
    let textfieldArea = { ...Constants.textFieldStyle };
    textfieldArea.icon = { top: "14px" };
    return textfieldArea;
  }

  public static get textFieldAreaStyle(): Partial<ITextFieldStyles> {
    let textfieldArea = { ...Constants.textFieldStyle };
    textfieldArea.fieldGroup = [
      {
        borderColor: Colors.Grey,
        height: "80px",
        borderRadius: "4px",
      },
    ];
    return textfieldArea;
  }

  public static dropdownStyle: Partial<IDropdownStyles> = {
    title: {
      borderColor: Colors.Grey,
      height: "48px",
      color: Colors.Black,
      fontFamily: "Arial",
      fontSize: "18px",
      letterSpacing: "0.36px",
      paddingTop: "10px",
      borderRadius: "4px",
    },
    dropdownOptionText: Constants.panelControStyle,
    caretDownWrapper: {
      top: "10px",
    },
    caretDown: {
      color: Colors.Blue,
    },
    dropdownItem: {
      ":hover": {
        color: `${Colors.Blue} !important`,
      },
    },
    root: {},
  };

  public static comboboxStyle: Partial<IComboBoxStyles> = {
    container: {
      borderColor: Colors.Grey,
      borderRadius: "4px",
    },
    root: {
      borderColor: Colors.Grey,
      height: "48px",
      color: Colors.Black,
      fontFamily: "Arial",
      fontSize: "18px",
      letterSpacing: "0.36px",
      paddingTop: "10px",
    },
    input: {
      paddingBottom: "10px",
    },
    // dropdownOptionText: Constants.panelControStyle,
    // caretDownWrapper:
    // {
    //     top: "10px",
    // },
    // caretDown: {
    //     color: Colors.Blue,
    // },
    // dropdownItem:
    // {
    //     ':hover':
    //     {
    //         color: `${Colors.Blue} !important`,
    //     }
    // },
    // root:
    // {

    // }
  };

  public static pickerStyle: Partial<IBasePickerStyles> = {
    text: {
      borderColor: Colors.GreyLight,
      backgroundColor: Colors.White,
      borderRadius: "4px",
      " input": {
        height: "48px",
      },
    },
    itemsWrapper: {
      height: "48px",
      paddingTop: "10px",
      " div": {
        borderRadius: "20px",
        color: Colors.Blue,
        backgroundColor: Colors.GreyLight,
        letterspacing: "0.14px",
        fontWeight: FontWeights.bold,
      },
    },
  };

  public static checkBoxStyle: Partial<ICheckboxStyles> = {
    checkbox: {
      borderColor: Colors.Grey,
      backgroundColor: `${Colors.White} !important`,
      marginTop: "3px",
    },
    checkmark: {
      color: Colors.Blue,
      fontWeight: FontWeights.bold,
    },
  };

  public static labelStyle: Partial<ILabelStyles> = {
    root: {
      font: "normal normal bold 14px/14px Arial",
      textTransform: "uppercase",
      letterSpacing: "1.12px",
      color: Colors.GreyDark,
      position: "relative",
      display: "inline-block",
      marginRight: 12,
      lineHeight: 20,
      verticalAlign: "bottom",
    },
  };

  public static fileNameStyles: Partial<ITextStyles> = {
    root: {
      font: "normal normal bold 14px/14px Arial",
      letterSpacing: "1.12px",
      color: Colors.Blue,
      position: "relative",
      display: "inline-block",
      marginRight: 12,
      lineHeight: 20,
      verticalAlign: "bottom",
      marginBottom: "5px",
      pointerEvents: "auto",
    },
  };

  public static labelButtonStyle: Partial<ILabelStyles> = {
    root: {
      font: "normal normal normal 12px/12px Arial",
      borderRadius: 4,
      letterSpacing: "0.14px",
      width: "136px",
      backgroundColor: Colors.White,
      color: Colors.Blue,
      textAlign: "center",
      borderStyle: "solid",
      borderWidth: "thin",
      borderColor: Colors.Blue,
    },
  };

  public static labelFileStyle: Partial<ILabelStyles> = {
    root: {
      borderRadius: "4px",
      letterSpacing: "0.36px",
      width: "164px",
      height: "48px",
      border: "1px solid #0152E8",
      backgroundColor: Colors.White,
      color: Colors.Blue,
      paddingTop: "10px",
      textAlign: "center",
      ":hover": {
        color: Colors.White,
        backgroundColor: Colors.Blue,
      },
    },
  };

  public static calendarlabelStyle: Partial<ILabelStyles> = {
    root: {
      font: "normal normal 8px/8px Arial",
      fontSize: "10px !important",
      letterSpacing: "0.14px",
      color: Colors.GreyDark,
      position: "relative",
      display: "inline-block",
      marginRight: 12,
      lineHeight: 20,
      verticalAlign: "bottom",
      textAlign: "center",
      padding: 0,
    },
  };

  public static iconChevronHoverFocus: IStyle = {
    background: "#EFF2F7 0% 0% no-repeat padding-box",
    borderRadius: 4,
    outline: "none",
  };
  public static iconChevronStyle: Partial<IButtonStyles> = {
    root: {
      marginRight: 12,
      //background: "#EFF2F7 0% 0% no-repeat padding-box",
      borderRadius: 4,
      color: Colors.Blue,
    },
    icon: {
      fontSize: 13,
    },

    rootHovered: Constants.iconChevronHoverFocus,
    rootFocused: Constants.iconChevronHoverFocus,
  };
  //#endregion

  //#region fluentui icons
  public static iDelete: IIconProps = { iconName: "Delete" };
  public static iDeleteCalendar: IIconProps = { iconName: "DeleteTable" };
  public static iAccept: IIconProps = { iconName: "Accept" };
  public static iAdd: IIconProps = { iconName: "Add" };
  public static iUp: IIconProps = { iconName: "Up" };
  public static iCalendar: IIconProps = { iconName: "Calendar" };

  public static iUser: IIconProps = { iconName: "Contact" };
  public static iGroups: IIconProps = { iconName: "Group" };

  public static iRangeCalendar: IIconProps = {
    iconName: "Calendar",
    styles: { root: { color: Colors.Blue } },
  };
  public static iSearch: IIconProps = {
    iconName: "Search",
    styles: { root: { color: Colors.Blue } },
  };
  public static iFilters: IIconProps = { iconName: "Equalizer" };
  public static iWaffle: IIconProps = { iconName: "WaffleOffice365" };
  public static iTimeline: IIconProps = { iconName: "Timeline" };
  public static iCancel: IIconProps = { iconName: "Cancel" };
  public static iRinger: IIconProps = { iconName: "Ringer" };
  public static iInfo: IIconProps = {
    iconName: "Info",
    styles: {
      root: {
        color: Colors.Grey,
        height: "14px",
        width: "14px",
        marginBottom: "5px",
      },
    },
  };
  public static iChevronLeft: IIconProps = { iconName: "ChevronLeft" };
  public static iChevronRight: IIconProps = { iconName: "ChevronRight" };
  public static iChevronRightSmall: IIconProps = {
    iconName: "ChevronRightSmall",
  };
  public static iChevronDownSmall: IIconProps = {
    iconName: "ChevronDownSmall",
  };
  public static iChevronUpSmall: IIconProps = { iconName: "ChevronUpSmall" };

  public static iChevronDownSmallCalendar: IIconProps = {
    iconName: "ChevronDownSmall",
    styles: { root: { height: "16px" } },
  };
  public static iChevronUpSmallCalendar: IIconProps = {
    iconName: "ChevronUpSmall",
    styles: { root: { height: "16px" } },
  };

  public static iPrincipalButton: IButtonStyles = {
    root: {
      backgroundColor: Colors.Blue,
      borderRadius: "4px",
      color: Colors.White,
      textAlign: "center",
      // font : "normal normal normal 18px Arial",
      letterSpacing: "0.36px",
      width: "164px",
      height: "48px",
    },
  };

  public static iBriefButton: IButtonStyles = {
    root: {
      // font: "normal normal normal 18px Arial",
      backgroundColor: Colors.Blue,
      borderRadius: "4px",
      color: Colors.White,
      textAlign: "left",
      borderColor: Colors.White,
      letterSpacing: "0.36px",
      minWidth: "126px",
      minHeight: "48px",
    },
    rootHovered: {
      color: Colors.White,
      backgroundColor: Colors.Blue,
    },
  };

  public static iToggle: Partial<IToggleStyles> = {
    text: {
      font: "bold 14px / 20px Arial !important",
      textTransform: "uppercase",
      letterSpacing: "1.12px",
      color: Colors.GreyDark,
      position: "relative",
      display: "inline-block",
      marginRight: 12,
      lineHeight: 20,
      verticalAlign: "bottom",
    },
  };

  public static iPrincipalButtonLarge: IButtonStyles = {
    root: {
      backgroundColor: Colors.Blue,
      borderRadius: "4px",
      color: Colors.White,
      textAlign: "center",
      // font : "normal normal normal 18px Arial",
      letterSpacing: "0.36px",
      width: "254px",
      height: "48px",
    },
    label: {
      font: "normal normal normal 18px/32px Arial",
    },
  };

  public static iStandardButtonLarge: IButtonStyles = {
    root: {
      // font: "normal normal normal 18px Arial",
      backgroundColor: Colors.White,
      color: Colors.Blue,
      borderRadius: "4px",
      textAlign: "center",
      borderColor: Colors.Blue,
      letterSpacing: "0.36px",
      width: "254px",
      height: "48px",
      ":hover": {
        color: `${Colors.White} !important`,
        backgroundColor: Colors.Blue,
      },
    },
    label: {
      font: "normal normal normal 18px/32px Arial",
    },
    rootHovered: {},
  };

  public static iStandardButton: IButtonStyles = {
    root: {
      backgroundColor: Colors.White,
      color: Colors.Blue,
      textAlign: "center",
      // font : "normal normal normal 18px Arial",
      letterSpacing: "0.36px",
      width: "100px",
      height: "48px",
    },
  };

  public static iIconButtonCalendarStyle: Partial<IButtonStyles> = {
    root: {
      height: "26px",
    },
  };

  public static iTextCalendardStyle: Partial<ITextFieldStyles> = {
    fieldGroup: {
      borderRadius: "4px 4px 4px 4px",
      backgroundColor: "#0078d4",
      width: "35px",
      height: "20px",
      margin: "auto",
    },
    field: {
      color: "white",
      marginBottom: "1px",
      marginLeft: "1px",
    },
  };

  public static activityModalPanelStyle = mergeStyleSets({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      width: "30%", //'30%',
      minWidth: "826px",
      //   minHeight: "910px",
      borderRadius: "16px 16px 16px 16px",
    },
    header: [
      {
        flex: "1 1 auto",
        borderTop: `4px solid ${theme.palette.themePrimary}`,
        color: theme.palette.neutralPrimary,
        display: "flex",
        alignItems: "center",
        fontWeight: FontWeights.semibold,
        padding: "12px 12px 14px 24px",
      },
    ],
    body: {
      flex: "4 4 auto",
      padding: "18px 48px 18px 48px",
      overflowY: "hidden",

      selectors: {
        p: { margin: "14px 0" },
        "p:first-child": { marginTop: 0 },
        "p:last-child": { marginBottom: 0 },
      },
    },
  });

  public static toolbarRichTextEditor: any = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: [
      "INLINE_STYLE_BUTTONS",
      "BLOCK_TYPE_BUTTONS",
      "LINK_BUTTONS",
      /*'BLOCK_TYPE_DROPDOWN',*/ "HISTORY_BUTTONS",
    ],
    INLINE_STYLE_BUTTONS: [
      { label: "Bold", style: "BOLD", className: "custom-css-class" },
      { label: "Italic", style: "ITALIC" },
      { label: "Underline", style: "UNDERLINE" },
    ],
    BLOCK_TYPE_DROPDOWN: [
      { label: "Normal", style: "unstyled" },
      { label: "Heading Large", style: "header-one" },
      { label: "Heading Medium", style: "header-two" },
      { label: "Heading Small", style: "header-three" },
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: "UL", style: "unordered-list-item" },
      { label: "OL", style: "ordered-list-item" },
    ],
  };

  public static toolbarRichTextEditorCustomPage: any = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: [
      "INLINE_STYLE_BUTTONS",
      "BLOCK_ALIGNMENT_BUTTONS",
      "BLOCK_TYPE_BUTTONS",
      "LINK_BUTTONS",
      "IMAGE_BUTTON",
      "BLOCK_TYPE_DROPDOWN",
      "HISTORY_BUTTONS",
    ],
    INLINE_STYLE_BUTTONS: [
      { label: "Bold", style: "BOLD", className: "custom-css-class" },
      { label: "Italic", style: "ITALIC" },
      { label: "Underline", style: "UNDERLINE" },
    ],
    BLOCK_ALIGNMENT_BUTTONS: [
      { label: "Align Left", style: "ALIGN_LEFT" },
      { label: "Align Center", style: "ALIGN_CENTER" },
      { label: "Align Right", style: "ALIGN_RIGHT" },
      { label: "Align Justify", style: "ALIGN_JUSTIFY" },
    ],
    BLOCK_TYPE_DROPDOWN: [
      { label: "Normal", style: "unstyled" },
      { label: "Heading Large", style: "header-one" },
      { label: "Heading Medium", style: "header-two" },
      { label: "Heading Small", style: "header-three" },
      { label: "Code Block", style: "code-block" },
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: "UL", style: "unordered-list-item" },
      { label: "OL", style: "ordered-list-item" },
      { label: "Blockquote", style: "blockquote" },
    ],
  };

  //#endregion
}
