import React, {Component, Fragment} from 'react';
import {withAITracking} from '@microsoft/applicationinsights-react-js';
import {ai} from './TelemetryService';
import { apiConfig } from '../msal/MsalConfig';

/**
 * This Component provides telemetry with Azure App Insights
 *
 * NOTE: the package '@microsoft/applicationinsights-react-js' has a HOC withAITracking that requires this to be a Class Component rather than a Functional Component
 */

 export interface ITelemetryProviderProps {
    history: boolean;
    after: () => void;
  }

  export interface ITelemetryProviderState {
    initialized?: boolean;
  }

class TelemetryProvider extends React.Component<ITelemetryProviderProps,ITelemetryProviderState> {
    state = {
        initialized: false
    };

    componentDidMount() {
        const {history} = this.props as any;
        const {initialized} = this.state;
        const appInsightsConnectionString = apiConfig.applicationInsightsConnectionString;
        if (!Boolean(initialized) && Boolean(appInsightsConnectionString) && Boolean(history)) {
            ai.initialize(appInsightsConnectionString, history);
            this.setState({initialized: true});
        }

        (this.props as any).after();
    }

    render() {
        const {children} = this.props;
        return (
            <Fragment>
                {children}
            </Fragment>
        );
    }
}

export default withAITracking(ai.reactPlugin, TelemetryProvider);