import { StringMap, TFunction, TOptions } from "i18next";

class Translation {
  private readonly _translation: TFunction;

  constructor(translation: TFunction) {
    this._translation = translation;
  }

  public getTextByKey(
    namespace: string,
    key: string,
    options?: string | TOptions<StringMap> | undefined
  ) {
    return this.getTextByNamespaceAndMessageKey(namespace, key, options);
  }

  protected getTextByNamespaceAndMessageKey(
    namespace: string,
    key: string,
    options?: string | TOptions<StringMap> | undefined
  ): string {
    const fullKey = `${namespace}.${key}`;
    return this._translation(fullKey, options);
  }

  protected this(): Translation {
    return this;
  }
}

export class DateTranslation {
  private readonly _translation: Translation;
  private readonly _namespace = "date";
  constructor(translation: Translation) {
    this._translation = translation;
  }

  public get today(): string {
    return this._translation.getTextByKey(this._namespace, "today");
  }

  public get day(): string {
    return this._translation.getTextByKey(this._namespace, "day");
  }

  public get week(): string {
    return this._translation.getTextByKey(this._namespace, "week");
  }

  public get month(): string {
    return this._translation.getTextByKey(this._namespace, "month");
  }

  public get year(): string {
    return this._translation.getTextByKey(this._namespace, "year");
  }
}

export class CommonTranslation extends Translation {
  // private readonly _translation: TFunction;
  private readonly _namespace: string;
  private readonly _commonNamespace = "common";
  private readonly _dateTranslation: DateTranslation;

  constructor(translation: TFunction, namespace: string) {
    super(translation);
    this._dateTranslation = new DateTranslation(super.this());
    this._namespace = namespace;
  }

  public loadingMessageByKey(key: string): string {
    return this.getTextByNamespaceAndMessageKey("loading", key);
  }

  public minDigitBeforeSearch(minDigitBeforeSearch: number): string {
    return this.getCommomByMessageKey("minDigitBeforeSearch", {
      minDigitBeforeSearch: minDigitBeforeSearch,
    });
  }

  public get date(): DateTranslation {
    return this._dateTranslation;
  }

  public get status(): string {
    return this.getCommomByMessageKey("status");
  }

  public get history(): string {
    return this.getCommomByMessageKey("history");
  }

  public get apply(): string {
    return this.getCommomByMessageKey("apply");
  }

  public get accept(): string {
    return this.getCommomByMessageKey("accept");
  }

  public get reject(): string {
    return this.getCommomByMessageKey("reject");
  }

  public get waiting(): string {
    return this.getCommomByMessageKey("waiting");
  }

  public get back(): string {
    return this.getCommomByMessageKey("back");
  }


  public get or(): string {
    return this.getCommomByMessageKey("or");
  }

  public get error(): string {
    return this.getCommomByMessageKey("error");
  }

  public get info(): string {
    return this.getCommomByMessageKey("info");
  }

  public get warning(): string {
    return this.getCommomByMessageKey("warning");
  }

  public get success(): string {
    return this.getCommomByMessageKey("success");
  }

  public get chooseFile(): string {
    return this.getCommomByMessageKey("chooseFile");
  }

  public get savingData(): string {
    return this.getCommomByMessageKey("savingData");
  }

  public get save(): string {
    return this.getCommomByMessageKey("save");
  }

  public get suggestedUser(): string {
    return this.getCommomByMessageKey("suggestedUser");
  }

  public get suggestedMacro(): string {
    return this.getCommomByMessageKey("suggestedMacro");
  }

  public get noUsersFound(): string {
    return this.getCommomByMessageKey("noUsersFound");
  }

  public get noMacroFound(): string {
    return this.getCommomByMessageKey("noMacroFound");
  }

  public get confirm(): string {
    return this.getCommomByMessageKey("confirm");
  }

  public get cancel(): string {
    return this.getCommomByMessageKey("cancel");
  }

  public get typeHere(): string {
    return this.getCommomByMessageKey("typeHere");
  }

  public get loadingCalendar(): string {
    return this.getCommomByMessageKey("loadingCalendar");
  }
  public get selectCalendar(): string {
    return this.getCommomByMessageKey("selectCalendar");
  }
  public get activityCalendar(): string {
    return this.getCommomByMessageKey("activityCalendar");
  }
  public get activityCalendarTooltip(): string {
    return this.getCommomByMessageKey("activityCalendarTooltip");
  }

  public get loadingCategories(): string {
    return this.getCommomByMessageKey("loadingCategories");
  }
  public get selectCategory(): string {
    return this.getCommomByMessageKey("selectCategory");
  }
  public get activityCategory(): string {
    return this.getCommomByMessageKey("activityCategory");
  }
  public get activityCategoryTooltip(): string {
    return this.getCommomByMessageKey("activityCategoryTooltip");
  }

  public get loadingSensibilities(): string {
    return this.getCommomByMessageKey("loadingSensibilities");
  }
  public get selectSensibility(): string {
    return this.getCommomByMessageKey("selectSensibility");
  }
  public get activitySensibility(): string {
    return this.getCommomByMessageKey("activitySensibility");
  }
  public get activitySensibilityTooltip(): string {
    return this.getCommomByMessageKey("activitySensibilityTooltip");
  }

  public get loadingResponsibilities(): string {
    return this.getCommomByMessageKey("loadingResponsibilities");
  }
  public get selectResponsibility(): string {
    return this.getCommomByMessageKey("selectResponsibility");
  }
  public get activityResponsibility(): string {
    return this.getCommomByMessageKey("activityResponsibility");
  }
  public get activityResponsibilityTooltip(): string {
    return this.getCommomByMessageKey("activityResponsibilityTooltip");
  }

  public get errorInLoadCalendar(): string {
    return this.getCommomByMessageKey("errorInLoadCalendar");
  }
  public get activityAlarm(): string {
    return this.getCommomByMessageKey("activityAlarm");
  }
  public get activityAlarmTooltip(): string {
    return this.getCommomByMessageKey("activityAlarmTooltip");
  }
  public get alarmResourcesActivity(): string {
    return this.getCommomByMessageKey("alarmResourcesActivity");
  }

  public get close(): string {
    return this.getCommomByMessageKey("close");
  }

  public get remove(): string {
    return this.getCommomByMessageKey("remove");
  }

  public get yes(): string {
    return this.getCommomByMessageKey("yes");
  }

  public get no(): string {
    return this.getCommomByMessageKey("no");
  }

  public get loading(): string {
    return this.getCommomByMessageKey("loading");
  }

  public focus(counter?: number): string {
    return this.getCommomByMessageKey("focus", { counter: counter });
  }

  public all(male: boolean, counter?: number): string {
    if (counter) {
      return male
        ? this.getCommomByMessageKey("allCounter_M", {
          counter: counter,
        })
        : this.getCommomByMessageKey("allCounter_F", {
          counter: counter,
        });
    } else {
      return male
        ? this.getCommomByMessageKey("all_M")
        : this.getCommomByMessageKey("all_F");
    }
  }

  public get selectDate(): string {
    return this.getCommomByMessageKey("selectDate");
  }

  public get share(): string {
    return this.getCommomByMessageKey("share");
  }

  public get ok(): string {
    return this.getCommomByMessageKey("ok");
  }

  public get selectFile(): string {
    return this.getCommomByMessageKey("selectFile");
  }

  public get download(): string {
    return this.getCommomByMessageKey("downwload");
  }

  public fileSelected(numFiles: number): string {
    if (numFiles == 0) {
      return this.getCommomByMessageKey("noFilesSelected");
    } else if (numFiles == 1) {
      return this.getCommomByMessageKey("oneFileSelected");
    } else {
      return this.getCommomByMessageKey("multipleFileSelected");
    }
  }

  public selectAll(male: boolean): string {
    return male
      ? this.getCommomByMessageKey("selectAll_M")
      : this.getCommomByMessageKey("selectAll_F");
  }

  public get uploadFile(): string {
    return this.getCommomByMessageKey("uploadFile");
  }

  public get uploadFolder(): string {
    return this.getCommomByMessageKey("uploadFolder");
  }

  public get openInBrowser(): string {
    return this.getCommomByMessageKey("openInBrowser");
  }

  public get getActionHistory(): string {
    return this.getCommomByMessageKey("actionHistory");
  }

  public get getVersionHistory(): string {
    return this.getCommomByMessageKey("versionHistory");
  }

  public get print(): string {
    return this.getCommomByMessageKey("print");
  }

  public get delete(): string {
    return this.getCommomByMessageKey("delete");
  }

  public get rename(): string {
    return this.getCommomByMessageKey("rename");
  }

  public viewAll(male: boolean, counter?: number): string {
    if (counter) {
      return male
        ? this.getCommomByMessageKey("viewAllCounter_M", {
          counter: counter,
        })
        : this.getCommomByMessageKey("viewAllCounter_F", {
          counter: counter,
        });
    } else {
      return male
        ? this.getCommomByMessageKey("viewAll_M")
        : this.getCommomByMessageKey("viewAll_F");
    }
  }

  public get collapse(): string {
    return this.getCommomByMessageKey("collapse");
  }

  public get selectOption(): string {
    return this.getCommomByMessageKey("selectOption");
  }

  public get resetFilters(): string {
    return this.getCommomByMessageKey("resetFilters");
  }

  public get showFilters(): string {
    return this.getCommomByMessageKey("showFilters");
  }

  public get hideFilters(): string {
    return this.getCommomByMessageKey("hideFilters");
  }

  public get warningDeleteTitle(): string {
    return this.getCommomByMessageKey("warningDeleteTitle");
  }

  public get warningDeleteSubTitle(): string {
    return this.getCommomByMessageKey("warningDeleteSubTitle");
  }

  public get requiredFieldError(): string {
    return this.getCommomByMessageKey("requiredFieldError");
  }

  public get fileNotSupported(): string {
    return this.getCommomByMessageKey("fileNotSupported");
  }

  public get fileAlreadyExists(): string {
    return this.getCommomByMessageKey("fileAlreadyExists");
  }

  public get fileNameAlreadyExists(): string {
    return this.getCommomByMessageKey("fileNameAlreadyExists");
  }

  public get fileAlreadyExistsWarning(): string {
    return this.getCommomByMessageKey("fileAlreadyExistsWarning");
  }

  public get newActivityButton(): string {
    return this.getCommomByMessageKey("newActivityButton")
  }

  public get newBriefButton(): string {
    return this.getCommomByMessageKey("newBriefButton")
  }

  public get proceed(): string {
    return this.getCommomByMessageKey("proceed");
  }

  public get noAlarm(): string {
    return this.getCommomByMessageKey("noAlarm");
  }
  public get alarmOnEndDate(): string {
    return this.getCommomByMessageKey("alarmOnEndDate");
  }
  public get alarm1DayBefore(): string {
    return this.getCommomByMessageKey("alarm1DayBefore");
  }
  public get alarm2DaysBefore(): string {
    return this.getCommomByMessageKey("alarm2DaysBefore");
  }
  public get alarm3DaysBefore(): string {
    return this.getCommomByMessageKey("alarm3DaysBefore");
  }
  public get alarm4DaysBefore(): string {
    return this.getCommomByMessageKey("alarm4DaysBefore");
  }
  public get alarm5DaysBefore(): string {
    return this.getCommomByMessageKey("alarm5DaysBefore");
  }
  public get alarm1WeekBefore(): string {
    return this.getCommomByMessageKey("alarm1WeekBefore");
  }
  public get alarm2WeeksBefore(): string {
    return this.getCommomByMessageKey("alarm2WeeksBefore");
  }

  private getCommomByMessageKey(
    key: string,
    options?: string | TOptions<StringMap> | undefined
  ) {
    return this.getTextByNamespaceAndMessageKey(
      this._commonNamespace,
      key,
      options
    );
  }

  public getTextByMessageKey(
    key: string,
    options?: string | TOptions<StringMap> | undefined
  ) {
    return super.getTextByKey(this._namespace, key, options);
  }
}
