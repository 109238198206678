import { User } from "./User";
import { ICalendarPermission, IPlannerPermission } from "./Planner";

export interface CalendarResult {
    data: Calendar[];
    permissions: IPlannerPermission;
    calendarsPermissions: ICalendarPermission[];
}

export class Calendar {
    id!: number;
    title!: string;
    start!: Date;
    end!: Date;
    desc!: null | string;
    state!: string;
    isMacro!: boolean;
    Nmicro!: number;
    linkedActivities?: LinkedActivity[];
    allDay!: boolean;
    calendar!: string;
    users!: User[];
}

export class LinkedActivity {
    id!: number;
    title!: string;
    start!: Date;
    end!: Date;
    desc!: string;
    state!: string;
    calendar!: string;
    macroName!: string;
    users!: User[];
}

export interface InternalEvent {
    readonly isMacro: boolean;
    readonly state: string
    readonly start: Date;
    readonly end: Date;
    readonly title: string;
    readonly calendar: string;
    readonly macroName: string;
    readonly users: User[];
    readonly microsState: MicroState[]
}

export interface MicroState {
    readonly state: string;
}