import { HistoryDTO } from "./HistoryDTO";
import { BriefDTO, BriefLookupDTO } from "./BriefDTO";
import { TaskDTO } from "./TaskDTO";
// import { DeliverableDTO } from "./DeliverableDTO";
import { UserDTO } from "./UserDTO";
import { NoteDTO } from "./NoteDTO";

export enum ActivityTypeFilter {
  all,
  macro,
  micro,
}

export interface ListActivities {
  count: number;
  activities: Activity[];
  permissions: ActivityPermissions;
}

export interface Activity {
  isMacro: boolean;
  id: number;
  name: string;
  calendarId: number;
  categoryIds: number[];
  responsibilityIds: number[];
  sensibility?: number;
  dateFrom: Date;
  dateTo: Date;
  status?: string;
  microActivities: Activity[] | null;
  externalResources?: UserDTO[];
  description?: string;
  // tasks: TaskDTO[] | null;
  documentBrief: BriefDTO;
  brief: BriefLookupDTO;
  file?: File;
  createdBy: UserDTO;
  createdOn: Date;
  editedBy: UserDTO;
  lastUpdate: Date;
  parentMacroId?: number;
  parentMacro?: Activity;
  spoc?: UserDTO;
  headOfActivity?: UserDTO;
  mediaPlan?: Activity;
  // deliverables?: DeliverableDTO[];
  resources?: UserDTO[];
  reminderResources?: UserDTO[];
  // notes: NoteDTO[];
  reminder?: number;
  lastAction: HistoryDTO;
  actions: HistoryDTO[];
  availableStatus?: AvailableStatusDTO;
}

export interface AvailableStatusDTO {
  planned: boolean;
  // draft: boolean;
  inProgress: boolean;
  // approved: boolean;
  // rework: boolean;
  // readyForDistribution: boolean;
  // distributed: boolean;
  closed: boolean;
}

export interface ActivityPermissions {
  canAddNote: boolean;
  canAddTask: boolean;
  canDelete: boolean;
  canDeleteDeliverables: boolean;
  canDownloadDeliverables: boolean;
  canUpdate: boolean;
  canUpdateResources: boolean;
  canUpdateStatus: boolean;
  canUploadDeliverables: boolean;
  deletableNotes: number[];
  deletableTasks: number[];
  updatableNotes: number[];
  updatableTasks: number[];
}

export interface ActivityResponse {
  activity: Activity;
  permissions: ActivityPermissions;
}

// export interface ActivityDTO_update {
//   dateFrom?: string;
//   dateTo?: string;
//   description?: string;
//   spocUPN?: string;
//   headOfActivityUPN?: string;
// }
export interface ActivityDTO_update {
  key: string;
  value: string | null;
}

export interface ActivitiesCountDTO {
  focus: number;
  planned: number;
  // draft: number;
  inProgress: number;
  // rework: number;
  // approved: number;
  // readyForDistribution: number;
  // distributed: number;
  closed: number;
}
