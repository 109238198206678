import React from "react";
import {MonthCalendar} from './MonthCalendar';
import styles from "./YearPlanner.module.scss";
import {Calendar} from '../../../../Models/Calendar';

export interface IYearPlannerProps {
  t: any;
  year: number;  
  activities: Calendar[];
  selectedDay: Date;
}
export interface IYearPlannerState {
  activities: any[];
  showAddbtn: boolean;
  isLoading: boolean;
  showTimeline: boolean;
  showCalendear: boolean;
  newActivityVisible: boolean;
  newActivityType: string;
  activityDetailsVisible: boolean;
  activityDetailsOpen: any;  
  timelineZoom: any;
  timelineOpen: boolean;
  tracks: any;
}

export class YearPlanner extends React.Component<IYearPlannerProps, IYearPlannerState> {
   
    constructor(props: IYearPlannerProps) {
        super(props);
        this.state = {
          activities: [],
          showAddbtn: false,
          isLoading: true,
          showTimeline: false,
          showCalendear: true,
          newActivityVisible: false,
          newActivityType: "",
          activityDetailsVisible: false,
          activityDetailsOpen: {},                    
          timelineZoom: null,
          timelineOpen: true,
          tracks: null,
        };

    }
    get activities(): Calendar[] {
      return this.props.activities;
    }
    
    render() {

      const renderMonth =(month: number)=> {
        return <MonthCalendar activities={this.activities} t={this.props.t} month={month} year={this.props.year} selectedDay={this.props.selectedDay}></MonthCalendar>
      };
      
      const january = renderMonth(1);
      const february = renderMonth(2);
      const march = renderMonth(3);
      const april = renderMonth(4);
      const may = renderMonth(5);
      const june = renderMonth(6);
      const july = renderMonth(7);
      const august = renderMonth(8);
      const september = renderMonth(9);
      const october = renderMonth(10);
      const november = renderMonth(11);
      const december = renderMonth(12);

      return (
        <React.Fragment>
          <div className={styles["calendar-container"]}>
            <div className={styles["calendar-row"]}>
                {january}
                {february}
                {march}
                {april}
              </div>
            <div className={styles["calendar-row"]}>
                {may}
                {june}
                {july}
                {august}
            </div>
            <div className={styles["calendar-row"]}>
                {september}
                {october}
                {november}
                {december}
            </div>
          </div>
        </React.Fragment>              
        );
    }  
};
