import { APIUtility } from "../Utility/APIUtility";
import "../Utility/DateExtensions";
import { IMicro } from "../Models/Activity";

const HTTPStatusOk = 200;

export class MicroClient {

    private readonly http: APIUtility = new APIUtility();

    public async saveMicroAsync(Micro: IMicro): Promise<boolean> {
        let sMicro = Micro;

        const { dateFrom, dateTo } = sMicro;

        //convert local date to utc
        sMicro.dateFrom = dateFrom.utc();
        sMicro.dateTo = dateTo.utc();    

        const formData = this.buildFormData(sMicro);    

        const result = await this.http.saveMicro(formData);

        return result === HTTPStatusOk ? true : false; //todo, return more complex object
    }

    private str2ByteArr(str: string) {
        var bytes = [];

        for (var i = 0; i < str.length; ++i) {
            bytes.push(str.charCodeAt(i));
            bytes.push(0);
        }
        return bytes;
    }

    private arrayBufferToBase64(buffer: number[]): string {
        var binary = '';
        var bytes = new Uint8Array(buffer);
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }

    private buildFormData(micro: IMicro): FormData {
        const description = this.getStringFormValue(micro.description ? micro.description : "");
        const bytes = this.str2ByteArr(description);
        const base64 = this.arrayBufferToBase64(bytes);

        const form = new FormData();
        form.append("Name", micro.name);
        form.append("CalendarId", micro.calendar.toString());
        form.append("Description", base64);
        form.append("DateFrom", micro.dateFrom.toISOString());
        form.append("DateTo", micro.dateTo.toISOString());
        if (micro.categories) {
            form.append("Categories", JSON.stringify(micro.categories));
        }
        if (micro.responsibilities) {
            form.append("Responsibilities", JSON.stringify(micro.responsibilities));
        }
        if (micro.sensibility) {
            form.append("Sensibility", micro.sensibility.toString());
        }
        if (micro.alarm !== undefined) {
            form.append("Reminder", micro.alarm.toString());
        }
        if (micro.alarmResourcesUPNs) {
            for (var item of micro.alarmResourcesUPNs) {
                form.append("ReminderResourcesUPNs", item);
            }
        }

        if (micro.parentMacroId) {
            form.append("ParentMacroId", micro.parentMacroId.toString());
        }
        if (micro.spocUPN) {
            form.append("SpocUPN", micro.spocUPN);
        }
        if (micro.resourcesUPNs) {
            for (var item of micro.resourcesUPNs) {
                form.append("ResourcesUPNs", item);
            }
        }
        if (micro.externalResourcesUPNs) {
            for (var item of micro.externalResourcesUPNs) {
                form.append("ExternalResourcesUPNs", item);
            }
        }
        if (!!micro.deliverables) {
            for (var file of micro.deliverables) {
                form.append("Deliverables", file);
            }
        }


        return form;
    }

    private getStringFormValue(value: string | undefined): string {
        return value ? value : '';
    }
}