import * as React from "react";

import "./Notification.css";
import {
  Callout,
  DirectionalHint,
  ICalloutContentStyles,
  IconButton,
  IIconProps,
  Image,
  Panel,
  PanelType,
} from "@fluentui/react";
import { Constants } from "../../../Utility/Constants";
import { IBaseProperties, IRoute, Page } from "../../../Models/IBaseProperties";
import {
  INotification,
  NotificationSubType,
  NotificationType,
  NotificationUtilities,
} from "../../../Models/Notification";
import { NotificationTranslation } from "../../../Translations/Notification.Translation";
import info from "../../../Style/Images/ICO-24-toast-info.svg";
import msgin from "../../../Style/Images/ICO-MsgIN.svg";
import success from "../../../Style/Images/ICO-24-toast-success.svg";

import { HistoryCardComponent } from "./HistoryCardComponent/HistoryCardComponent";
import { filter } from "rxjs/operators";
import { Subscription } from "rxjs";

export interface INotificationProps extends IBaseProperties {}
export interface INotificationState {
  notifications: ITimerNotification[];
  notificationYetToBeRead: boolean;
  autoDelete: boolean;
  showNotificationsHistory: boolean;
}

export class Notification extends React.Component<
  INotificationProps,
  INotificationState
> {
  private readonly _translation: NotificationTranslation;
  private readonly _notifyIconRef = React.createRef<HTMLDivElement>();
  private readonly iconNotificationRef = React.createRef<IconButton>();
  private readonly panelStyle = { main: { backgroundColor: "#f7f8fb" } };
  private readonly cancelIcon: IIconProps = {
    iconName: "Cancel",
    color: "#667790",
  };
  private readonly calloutStyle: ICalloutContentStyles = {
    container: {},
    root: {},
    beak: {},
    beakCurtain: {},
    calloutMain: { borderRadius: "14px" },
  };
  private readonly _intervalCheck: number;
  private readonly _dismissTime: number;

  private intervalId?: NodeJS.Timeout;
  private _notificationSubject?: Subscription;

  constructor(props: INotificationProps) {
    super(props);

    this._translation = new NotificationTranslation(
      props.commonProps.translation
    );

    this.state = {
      showNotificationsHistory: false,
      notificationYetToBeRead: false,
      notifications: [],
      autoDelete: true,
    };

    this._dismissTime = 5000;
    this._intervalCheck = 3000;

    this.clickNotificationHistory = this.clickNotificationHistory.bind(this);
    this.removeNotification = this.removeNotification.bind(this);
    this.enterNotificationArea = this.enterNotificationArea.bind(this);
    this.leaveNotificationArea = this.leaveNotificationArea.bind(this);
    this.notificationClick = this.notificationClick.bind(this);
    this.removeOldNotification = this.removeOldNotification.bind(this);
  }

  componentDidUpdate(prevProps: INotificationProps) {
    if (
      prevProps.commonProps.onNotification !==
      this.props.commonProps.onNotification
    ) {
      this.buildNotificationSubject();
    }
  }

  componentDidMount() {
    this.renewInterval();

    this.buildNotificationSubject();
  }

  private buildNotificationSubject() {
    const filtererSubject = this.props.commonProps.onNotification?.pipe(
      filter((notification) => notification.author !== undefined)
    );

    this._notificationSubject = filtererSubject?.subscribe(
      (value: INotification) => {
        console.debug(value);
        const cNotification: ITimerNotification = {
          ...value,
          notificationDate: new Date(),
        };
        const { notifications } = this.state;
        const newNotifications = [...notifications, cNotification];

        // const newNotifications = notifications.concat(value);
        this.setState({
          notifications: newNotifications,
          notificationYetToBeRead: true,
        });
      }
    );
  }

  private renewInterval() {
    this.intervalId = setInterval(
      this.removeOldNotification,
      this._dismissTime
    );
  }

  componentWillUnmount() {
    // use intervalId from the state to clear the interval
    this.disableInterval();

    if (this._notificationSubject) {
      this._notificationSubject.unsubscribe();
    }
  }

  private disableInterval() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  private removeOldNotification() {
    const now = new Date().getTime();

    const { notifications, notificationYetToBeRead } = this.state;

    if (notifications.length > 0) {
      const aa = now - notifications[0].notificationDate.getTime();
      console.log(aa);
    }

    const newNotifications = notifications.filter(
      (t) => now - t.notificationDate.getTime() < this._dismissTime
    );

    const hasElements = notificationYetToBeRead || newNotifications.length > 0;

    this.setState({
      notifications: newNotifications,
      notificationYetToBeRead: hasElements,
    });
  }

  render() {
    return (
      <React.Fragment>
        <div id="notifyContainer" ref={this._notifyIconRef} className="notify">
          <div>
            <IconButton
              ref={this.iconNotificationRef}
              className="notify-button marginTop10"
              iconProps={Constants.iRinger}
              allowDisabledFocus
              aria-label="Notification"
              ariaLabel="Notification"
              onClick={this.clickNotificationHistory}
              disabled={false}
              checked={true}
            />
            {this.state.notificationYetToBeRead && (
              <div className="notify-to-read"></div>
            )}
          </div>
        </div>
        {this.state.notifications.length > 0 && (
          <Callout
            target={this._notifyIconRef}
            role="dialog"
            directionalHint={DirectionalHint.bottomAutoEdge}
            styles={this.calloutStyle}
          >
            <div
              className="notification-group"
              onMouseEnter={() => this.enterNotificationArea()}
              onMouseLeave={() => this.leaveNotificationArea()}
            >
              {this.state.notifications.map(
                (notification: INotification, i) => (
                  <div
                    key={i}
                    className="notificationnew-container"
                    onClick={() => this.notificationClick(notification)}
                  >
                    <div className="notificationnew-iconstatus">
                      <Image
                        src={this.getNotificationIconName(notification)}
                        alt="Notification"
                        className={this.getClassNameStatus(notification)}
                      />

                      {/* <FontIcon
                            iconName={this.getIconNameStatus(notification)}
                            className={this.getClassNameStatus(notification)}
                          ></FontIcon> */}
                    </div>
                    <div className="notificationew-content">
                      <div className="notificationnew-title">
                        {notification.name}
                      </div>
                      <div>
                        <span className="notificationnew-user">
                          {notification.author &&
                            notification.author.displayName}
                        </span>
                        <span className="notificationnew-message">
                          {this.getMessageByType(notification)}
                        </span>
                      </div>
                    </div>
                    <div className="notificationnew-iconclose">
                      <IconButton
                        aria-label="Cancel"
                        iconProps={this.cancelIcon}
                        onClick={(event) => {
                          this.removeNotification(notification.identifier);
                          event.preventDefault();
                          event.stopPropagation();
                        }}
                      ></IconButton>
                    </div>
                  </div>
                )
              )}
            </div>
          </Callout>
        )}
        <Panel
          headerText="Notifications"
          type={PanelType.custom}
          customWidth="500px"
          className="panel"
          isOpen={this.state.showNotificationsHistory}
          onDismiss={() => {
            this.setState({ showNotificationsHistory: false });
          }}
          styles={this.panelStyle}
          closeButtonAriaLabel="Close"
        >
          <HistoryCardComponent
            commonProps={this.props.commonProps}
          ></HistoryCardComponent>
        </Panel>
      </React.Fragment>
    );
  }

  private enterNotificationArea(): void {
    //disable timer interval in order to allow user to read notifications
    this.disableInterval();
  }

  private leaveNotificationArea(): void {
    //enable timer interval in order to hide old notification

    this.setState(
      {
        notifications: [],
        notificationYetToBeRead: false,
      },
      () => this.renewInterval()
    );
  }

  private getMessageByType(notification: INotification): string {
    return NotificationUtilities.getNotificationStringType(
      this._translation,
      notification.subType
    );
  }

  private getNotificationIconName(notification: INotification) {
    let value = info;

    switch (notification.subType) {
      case NotificationSubType.briefaddition:
        value = info;
        break;
      case NotificationSubType.briefapprovation:
        value = success;
        break;
      default:
        value = msgin;
        break;
    }

    return value;
  }

  private getClassNameStatus(notification: INotification): string | undefined {
    // const rotateClassName = "rotate180";
    const rotateClassName = "";
    return ["notificationnew-status-info", rotateClassName].join(" ");
  }

  private getIconNameStatus(notification: INotification): string | undefined {
    return "Info";
  }

  private removeNotification(identifier: string): void {
    const newNotifications = this.state.notifications.filter(
      (x) => x.identifier != identifier
    );
    const hasNotifications = newNotifications.length > 0;
    this.setState({
      notifications: newNotifications,
      notificationYetToBeRead: hasNotifications,
    });
  }

  private notificationClick(notification: INotification): void {
    const page = this.notificationToPage(notification);
    const route: IRoute = {
      page: page,
      additionalInfo: {
        keysValues: {
          id: notification.id.toString(),
          type: this.toType(notification),
          subtype: undefined,
          title: notification.name,
          showMyActivities: undefined,
        },
      },
    };
    this.props.commonProps.onRoute?.next(route);
  }

  private toType(notification: INotification): string | undefined {
    let retValue: string | undefined = undefined;
    switch (notification.subType) {
      case NotificationSubType.macroactivityheadassignment:
      case NotificationSubType.macroactivityheadremoval:
      case NotificationSubType.macroactivityspocassignment:
      case NotificationSubType.macroactivityspocremoval:
        retValue = "1";
        break;
      case NotificationSubType.microactivityresourceassignment:
      case NotificationSubType.microactivityresourceremoval:
      case NotificationSubType.microactivityspocremoval:
      case NotificationSubType.microactivitystatuschange:
      case NotificationSubType.microactivitytaskcompletion:
      case NotificationSubType.microactivitytaskcreation:
      case NotificationSubType.microactivitytaskreopening:
        retValue = "2";
        break;
    }

    return retValue;
  }

  private notificationToPage(notification: INotification): Page {
    let page: Page = Page.None;

    switch (notification.type) {
      case NotificationType.Brief:
        page = Page.MyBrief;
        break;
      case NotificationType.Micro:
      case NotificationType.Macro:
        page = Page.Activities;
        break;
    }

    return page;
  }

  private clickNotificationHistory() {
    this.setState({
      showNotificationsHistory: true,
      notifications: [],
      notificationYetToBeRead: false,
    });
  }
}

interface ITimerNotification extends INotification {
  notificationDate: Date;
}
