import { TFunction } from "i18next";
import { CommonTranslation } from "../../../Translations/Common.Translation";

export class PlannerTranslation extends CommonTranslation
{    
    constructor(translation: TFunction)
    {
        super(translation, "planner");                
    }    

    public get getTimelineNoData(): string
    {
        return super.getTextByMessageKey("timelineNoData");
    }

    public get getLoadingMessage(): string
    {
        return super.getTextByMessageKey("loadingMessage");
    }

    public get getTimelineError(): string
    {
        return super.getTextByMessageKey("getTimelineError");
    }

    public get getCalendarError(): string
    {
        return super.getTextByMessageKey("getCalendarError");
    }

    public saveSuccess(activityType: string): string
    {
        return super.getTextByMessageKey("saveSuccess", {
            activityType: activityType
        });
    }

    public get activityLoadingError(): string
    {
        return super.getTextByMessageKey("activityLoadingError");
    }
   
    public get genericErrorInSave(): string
    {
        return super.getTextByMessageKey("genericErrorOnSave");
    }
    
    public activityTitle(activityType: string): string
    {
        return super.getTextByMessageKey("activityTitle", {
            activityType: activityType
        });
    }

    public get addMacro(): string
    {
        return super.getTextByMessageKey("addMacro");
    }

    public get addMicro(): string
    {
        return super.getTextByMessageKey("addMicro");
    }
    
}