import React from 'react'
import PropTypes from 'prop-types'

function isDateEquals (date1, date2) 
{
  return (
  date1.getFullYear() === date2.getFullYear() &&
  date1.getMonth() === date2.getMonth() &&
  date1.getDate() === date2.getDate()
  );
}

const Cell = ({ time, title, subtitle, start, end, style, onClickDate, selectedDay }) => 
{
  const diffTime = Math.abs(end - start);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
  const view = diffDays > 1 ? 'month' : 'day';
  const click = time.view === 'day' ? undefined : ()=> onClickDate(start, view);
  const classPointer = time.view === 'day' ? undefined : 'rt-timebar__cell-pointer';
  const classSelected = time.view !== 'day' && isDateEquals(start,selectedDay) ? 'rt-timebar__cell-selected' : undefined;
  return (      
    <div className="rt-timebar__cell" style={{...style, ...time.toStyleLeftAndWidth(start, end)}}>
      <div id={subtitle && "week"} >
        <div id={`${time.view.toLowerCase()}title`} className={classPointer} onClick={click}> 
          <span className={classSelected} >
            {title}
          </span>
        </div>
        <div id="subtitle" >
          <span>
            {subtitle}
          </span>
        </div> 
      </div>      
    </div>
  )
}

Cell.propTypes = {
  time: PropTypes.shape({
    toStyleLeftAndWidth: PropTypes.func,
  }),
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  start: PropTypes.instanceOf(Date).isRequired,
  end: PropTypes.instanceOf(Date).isRequired,
  style: PropTypes.shape({}),
  onClickDate:  PropTypes.func.isRequired,
  selectedDay: PropTypes.instanceOf(Date),
}

export default Cell
