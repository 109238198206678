import { TFunction } from "i18next";
import { CommonTranslation } from "./Common.Translation";

export class NotificationTranslation extends CommonTranslation {
  constructor(translation: TFunction) {
    super(translation, "notifications");
  }

  /*       
   
    "": "A task has been reopened",
    "": "You have been assigned as a HEAD in an activity",
    "": "You have been removed as a HEAD in an activity",
    "": "You have been assigned as a SPOC in an activity",
    "" : "You have been removed as a SPOC in an activity"
  */

  public notificationByName(name: string): string {
      return super.getTextByMessageKey(name);
  }

  public get microctivityTaskReopening(): string {
    return super.getTextByMessageKey("microActivityTaskReopening");
  }
  public get macroActivityHeadAssignment(): string {
    return super.getTextByMessageKey("macroActivityHeadAssignment");
  }

  public get macroActivityHeadRemoval(): string {
    return super.getTextByMessageKey("macroActivityHeadRemoval");
  }

  public get macroActivitySpocAssignment(): string {
    return super.getTextByMessageKey("macroActivitySpocAssignment");
  }

  public get macroActivitySpocRemoval(): string {
    return super.getTextByMessageKey("macroActivitySpocRemoval");
  }

  public get microActivityTaskCompletion(): string {
    return super.getTextByMessageKey("microActivityTaskCompletion");
  }

  public get microActivityTaskCreation(): string {
    return super.getTextByMessageKey("microActivityTaskCreation");
  }

  public get microActivitySpocRemoval(): string {
    return super.getTextByMessageKey("microActivitySpocRemoval");
  }

  public get microActivitySpocAssignment(): string {
    return super.getTextByMessageKey("microActivitySpocAssignment");
  }

  public get briefsetrework(): string {
    return super.getTextByMessageKey("briefsetrework");
  }
  public get briefactivitydelegation(): string {
    return super.getTextByMessageKey("briefactivitydelegation");
  }

  public get briefapprovationdelegation(): string {
    return super.getTextByMessageKey("briefapprovationdelegation");
  }

  public get briefreferentsassignment(): string {
    return super.getTextByMessageKey("briefreferentsassignment");
  }

  public get briefreferentsremoval(): string {
    return super.getTextByMessageKey("briefreferentsremoval");
  }

  public get briefaddition(): string {
    return super.getTextByMessageKey("briefaddition");
  }
  public get briefinforequest(): string {
    return super.getTextByMessageKey("briefinforequest");
  }

  public get briefrejection(): string {
    return super.getTextByMessageKey("briefrejection");
  }
  public get microactivityresourceassignment(): string {
    return super.getTextByMessageKey("microactivityresourceassignment");
  }
  public get microactivityresourcetemoval(): string {
    return super.getTextByMessageKey("microactivityresourcetemoval");
  }
  public get microactivitystatuschange(): string {
    return super.getTextByMessageKey("microactivitystatuschange");
  }

  public get briefapprovation(): string {
    return super.getTextByMessageKey("briefapprovation");
  }

  public get briefsetpending(): string {
    return super.getTextByMessageKey("briefsetpending");
  }
}
