import * as React from "react";
import styles from "./VideoTutorialCardComponent.module.scss";
import { FontIcon, PersonaSize } from "@fluentui/react";
import { IBaseProperties } from "../../../../../../../Models/IBaseProperties";
import { VideoTutorial } from "../../../../../../../Models/VideoTutorial";
import { SupportTranslation } from "../../../../../../../Translations/Support.Translation";
import { ISupportClient } from "../../../../../../../Clients/ISupportClient";

export interface IVideoTutorialCardComponentProps extends IBaseProperties {
  videoTutorial: VideoTutorial;
  selectVideoTutorial: (userGuide: VideoTutorial) => void;
  refreshList: () => void;
}
export interface IVideoTutorialCardComponentState {
  isFav: boolean;
}

export class VideoTutorialCardComponent extends React.Component<
  IVideoTutorialCardComponentProps,
  IVideoTutorialCardComponentState
> {
  private readonly _translation: SupportTranslation;
  private readonly _supportClient: ISupportClient;

  constructor(props: IVideoTutorialCardComponentProps) {
    super(props);

    this._translation = new SupportTranslation(
      this.props.commonProps.translation
    );
    this._supportClient = this.props.commonProps.clientCreator.createSupportClient();

    this.state = {
      isFav: this.props.videoTutorial.favourite,
    };
  }

  private _getStyle(role: string): string {
    switch (role) {
      case "APPROVER":
        return styles.APPROVER;
      case "WORKING GROUP":
        return styles.WORKING_GROUP;
      case "EDITOR":
        return styles.EDITOR;
    }
    return "";
  }

  public render() {
    return (
      <div className={styles.activityCard}>
        <div
          className={styles.card_body}
          onClick={() => {
            this.props.selectVideoTutorial(this.props.videoTutorial);
          }}
        >
          <div className={styles.card_body_header}>
            <h6
              className={
                styles.cardState +
                " " +
                " ms-Grid-col ms-xl8 ms-lg8 ms-md8 ms-sm8"
              }
            >
              <div
                className={
                  styles.divRole +
                  " " +
                  (this.props.videoTutorial.Role
                    ? this._getStyle(this.props.videoTutorial.Role)
                    : "")
                }
              >
                {this.props.videoTutorial.Role
                  ? this.props.videoTutorial.Role
                  : ""}
              </div>
              {/* <FontIcon
                iconName={
                  this.props.contact.favourite
                    ? "FavoriteStarFill"
                    : "FavoriteStar"
                }
                className={styles.iconFav}
              ></FontIcon> */}
            </h6>
            <h6
              className={
                styles.cardDate +
                " " +
                styles.dateLabel +
                " ms-Grid-col ms-xl4 ms-lg4 ms-md4 ms-sm4"
              }
            >
              <FontIcon
                iconName={
                  this.state.isFav ? "FavoriteStarFill" : "FavoriteStar"
                }
                className={styles.iconFav}
                onClick={(e) => {
                  if (this.state.isFav) {
                    this._supportClient.RemoveFavourites([
                      this.props.videoTutorial.Id,
                    ]);

                    this.setState({ isFav: false });
                  } else {
                    this._supportClient.AddFavourites([
                      this.props.videoTutorial.Id,
                    ]);

                    this.setState({ isFav: true });
                  }

                  this.props.refreshList();
                }}
              ></FontIcon>
              {/* <span className="">
                {Utility.FormatDate(this.props.contact.lastUpdate)}
              </span> */}
            </h6>
          </div>
          <div className={styles.card_body_content}>
            <div className={"ms-Grid-col ms-xl2 ms-lg2 ms-md2 ms-sm2"}>
              <FontIcon
                iconName={"MSNVideos"}
                className={styles.iconPdf}
              ></FontIcon>
            </div>
            <div className={"ms-Grid-col ms-xl10 ms-lg10 ms-md10 ms-sm10"}>
              <h5
                className={
                  styles.card_title + " " + styles.truncate_overflow_parent
                }
                onClick={() => {
                  window.open(this.props.videoTutorial.URL);
                }}
              >
                {this.props.videoTutorial.title}
              </h5>
              <div className={styles.Language}>
                {this.props.videoTutorial.language
                  ? this.props.videoTutorial.language.toString().toUpperCase()
                  : ""}
              </div>
            </div>
            <div className={"ms-Grid-col ms-xl12 ms-lg12 ms-md12 ms-sm12"}>
              <FontIcon
                iconName={"TVMonitor"}
                className={
                  styles.iconPlatform +
                  (this.props.videoTutorial.isForDesktop
                    ? " " + styles.iconPlatformSelected
                    : "")
                }
              ></FontIcon>
              <FontIcon
                iconName={"CellPhone"}
                className={
                  styles.iconPlatform +
                  (this.props.videoTutorial.isForMobile
                    ? " " + styles.iconPlatformSelected
                    : "")
                }
              ></FontIcon>
            </div>
          </div>
          <div className={styles.card_body_footer}>
            {/* {this.props.contact.lastAction && (
              <p className={styles.card_text + " " + styles.card_greytext}>
                <span className={styles.date}>
                  {Utility.FormatDate(
                    this.props.contact.lastAction
                      ? this.props.contact.lastAction.actionDate
                      : undefined
                  ) + " "}
                </span>
                <span className={styles.author}>
                  {this.props.contact.lastAction &&
                  this.props.contact.lastAction.user &&
                  this.props.contact.lastAction.user.displayName
                    ? this.props.contact.lastAction.user.displayName
                    : "User"}
                </span>
                {this.props.contact.lastAction
                  ? " " + this._getActionText(this.props.contact.lastAction)
                  : " "}
                <span className={styles.date}>
                  {Utility.FormatTime(
                    this.props.contact.lastAction
                      ? this.props.contact.lastAction.actionDate
                      : undefined
                  )}
                </span>
              </p>
            )} */}
          </div>
        </div>
      </div>
    );
  }
}
