import React from "react";
import { apiConfig } from "./msal/MsalConfig";
import { Subject } from "rxjs";
import { withAuth } from "./msal/MsalAuthProvider";
import { Header } from "./Components/Header/Header";
import { Footer } from "./Components/Footer/Footer";
import { Dashboard } from "./Components/Body/Dashboard/Dashboard";
import { MyBrief } from "./Components/Body/MyBrief/MyBrief";
import { Planner, PlannerView } from "./Components/Body/Planner/Planner";
import { Report } from "./Components/Body/Report/Report";
import { Constants } from "./Utility/Constants";
import { Security } from "./Utility/Security";
import { WithTranslation, withTranslation } from "react-i18next";
import { initializeIcons } from "office-ui-fabric-react/lib/Icons";
import { ToastComponent } from "./Components/Shared/ToastComponent/ToastComponent";

import { RoutingComponent } from "./Components/Shared/RoutingComponent/RoutingComponent";
import "./App.css";
import "./Style/global.scss";
import {
  AdditionalRoutingInfo,
  ICommonProperties,
  IRoute,
  Page,
} from "./Models/IBaseProperties";
import { HttpClientsCreator } from "./Clients/ClientCreator";
import { ActivityHelper } from "./Models/ActivityStatus";
import { DateHelper } from "./Models/DateHelper";
import i18next from "i18next";
import "url-search-params-polyfill";
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IModalProps,
} from "@fluentui/react";
import { NotificationClient } from "./Clients/NotificationClient";
import { INotification } from "./Models/Notification";
import { IUserClient } from "./Clients/IUserClient";
import { CustomPageComponent } from "./Components/Shared/CustomPageComponent/CustomPageComponent";
import { ListActivities } from "./Components/Body/Activities/ListActivities/ListActivities";
import { ListAttachments } from "./Components/Body/Activities/ListAttachments/ListAttachments";
import { IHealthClient } from "./Clients/IHealthClient";
import { SupportComponent } from "./Components/Body/Support/SupportComponent/SupportComponent";
import { getAppInsights } from "./AppInsights/TelemetryService";
import TelemetryProvider from "./AppInsights/telemetry-provider";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";

export interface IAppProps extends WithTranslation {}

export interface IAppState {
  clientCreator: HttpClientsCreator;
  language?: string;
  page?: string;
  toastRefState?: React.RefObject<ToastComponent>;
  newBriefOpen: boolean;
  enableBriefDetail: boolean;
  sharepointUrl?: string;
  additionalInfo?: AdditionalRoutingInfo;
  routing?: Subject<IRoute>;
  notificationSubject?: Subject<INotification>;
  user?: string;
  userError: boolean;
  healthError: boolean;
  username: string;
  scrollY: number;
}

class AppInternal extends React.Component<IAppProps, IAppState> {
  private readonly _userClient: IUserClient;
  private readonly _healthClient: IHealthClient;
  private appInsights: ApplicationInsights | null = null;

  constructor(props: IAppProps) {
    super(props);

    var clientCreator = new HttpClientsCreator();
    this._userClient = clientCreator.createUserClient();
    this._healthClient = clientCreator.createHealthClient();

    var languageStoredInLocalStorage = localStorage.getItem("language");

    var urlParams = new URLSearchParams(window.location.search);
    var pageFromUrl = urlParams.get("page");
    var pageInLocalStorage = localStorage.getItem("page");
    var pageInSessionStorage = sessionStorage.getItem("page");
    var userInLocalStorage = localStorage.getItem("user");

    var ref: React.RefObject<ToastComponent> = React.createRef();

    this.state = {
      clientCreator: clientCreator,
      language: languageStoredInLocalStorage
        ? languageStoredInLocalStorage
        : "en",
      page: pageFromUrl
        ? pageFromUrl
        : pageInSessionStorage
        ? pageInSessionStorage
        : pageInLocalStorage
        ? pageInLocalStorage
        : "Dashboard",
      toastRefState: ref,
      newBriefOpen: false,
      enableBriefDetail: true,
      sharepointUrl: undefined,
      user: userInLocalStorage ? userInLocalStorage : undefined,
      userError: false,
      healthError: false,
      username: "",
      scrollY: 0,
    };

    initializeIcons();
    this._bindEvents();
  }

  componentDidMount() {
    this._loadApp();
    this.checkHealth();
    window.addEventListener("scroll", this.handleScroll, true);
    window.document.title = "Media Planning - Dashboard";
  }

  private changeLang(lang: string): any {
    i18next.changeLanguage(lang).then(() => {
      i18next.options.lng = lang;
      i18next.t("key");
    });
  }

  private _bindEvents() {
    this._loadApp = this._loadApp.bind(this);
  }

  handleScroll = () => {
    this.setState({ scrollY: window.scrollY });
  };

  private async checkHealth() {
    try {
      const resp = await this._healthClient.checkClientVersion();
      if (!!resp && resp.httpStatus === 400) {
        this.setState({ healthError: true });
      }
    } catch (err) {
      console.error(err);
      this.setState({ healthError: true });
    }
  }

  private async _loadApp() {
    var userInfo = Security.getCurrentUserInfo();
    var url: string | undefined = this.state.sharepointUrl;

    if (!this.state.user || userInfo === undefined) {
      userInfo = await this._userClient.getCurrentUserInfoAsync();
      if (!userInfo) {
        var username = await this._userClient.getCurrentUsername();
        this.setState({ userError: true, username: username ? username : "" });
      }
    }

    if (!url) {
      // url = await this._userClient.getSharepointLoginPageUrl();
      url = "http://sharepointUrl";
    }

    let routing = new Subject<IRoute>();
    routing.subscribe((route: IRoute) => {
      const newPage = this.pageEnumToString(route.page);
      window.document.title = newPage ? newPage : window.document.title;
      if (newPage) {
        if (newPage == this.state.page) {
          if (!!this.appInsights) {
            this.appInsights.trackPageView({
              name: "MP - " + (newPage || this.state.page),
              uri: apiConfig.redirectUri,
              isLoggedIn: true,
              properties: {
                UserLoginName: this.state.username || this.state.user,
              },
            });
          }
          this.setState({ page: this.pageEnumToString(Page.Dashboard) }, () => {
            this.setState({
              additionalInfo: route.additionalInfo,
              newBriefOpen: false,
              page: newPage || this.state.page,
            });
          });
        } else {
          if (!!this.appInsights) {
            this.appInsights.trackPageView({
              name: "MP - " + (newPage || this.state.page),
              uri: apiConfig.redirectUri,
              isLoggedIn: true,
              properties: {
                UserLoginName: this.state.username || this.state.user,
              },
            });
          }
          this.setState({
            additionalInfo: route.additionalInfo,
            newBriefOpen: false,
            page: newPage ? newPage : this.state.page,
          });
        }
      } else {
        this.setState({
          additionalInfo: route.additionalInfo,
        });
      }
    });

    const notificationSubject = new Subject<INotification>();
    const notification = new NotificationClient(
      `${apiConfig.signalResource}/notifications`
    );

    notification.onNewNotification = (value: INotification) => {
      notificationSubject.next(value);
      // subscriber.next(value);
    };

    if (url && userInfo && this.state.language) {
      new Security(userInfo);
      new DateHelper(this.state.language);
      new ActivityHelper(this.props.t);
      this.setState({
        sharepointUrl: url,
        user: userInfo.displayName,
        username: userInfo.username,
        routing: routing,
        notificationSubject: notificationSubject,
      });
    } else {
      // this.state.toastRefState?.current?.showMessage(
      //   "Error",
      //   this.props.t("users.userInfoError"),
      //   ToastNotificationType.ERROR
      // );
      this.setState({
        sharepointUrl: undefined,
        user: undefined,
        routing: routing,
        notificationSubject: notificationSubject,
        userError: true,
      });
    }
  }

  private pageEnumToString(page: Page): string | undefined {
    let sPage: string | undefined;
    switch (page) {
      case Page.Dashboard:
        sPage = Constants.PageDashboard;
        break;
      case Page.Activities:
        sPage = Constants.PageActivities;
        break;
      case Page.MyBrief:
        sPage = Constants.PageMyBrief;
        break;
      case Page.Planner:
        sPage = Constants.PagePlanner;
        break;
      case Page.Report:
        sPage = Constants.PageReport;
        break;
      case Page.FAQ:
        sPage = Constants.PageFAQ;
        break;
      case Page.Contacts:
        sPage = Constants.PageContacts;
        break;
      case Page.WhoWeAre:
        sPage = Constants.PageWhoWeAre;
        break;
      case Page.Legal:
        sPage = Constants.PageLegal;
        break;
      case Page.Privacy:
        sPage = Constants.PagePrivacy;
        break;
      case Page.Cookie:
        sPage = Constants.PageCookie;
        break;
      default:
        sPage = undefined;
        break;
    }

    return sPage;
  }

  private RootApp() {
    var commonProps: ICommonProperties = {
      translation: this.props.t,
      toastComponent: this.state.toastRefState?.current,
      clientCreator: this.state.clientCreator,
      onNotification: this.state.notificationSubject,
      onRoute: this.state.routing,
      additionalInfo: this.state.additionalInfo,
    };

    return (
      <TelemetryProvider
        history={true}
        after={() => {
          this.appInsights = getAppInsights();
        }}
      >
        <div className="App">
          <div hidden={true}>
            {/* {this.state.sharepointUrl && (
              <iframe
                className="frameSharepoint"
                src={this.state.sharepointUrl}
              ></iframe>
            )} */}
          </div>
          <div className="ms-Grid" dir="ltr">
            <div className="ms-Grid-row sticky">
              <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                <Header
                  commonProps={commonProps}
                  onClickNewBrief={() => {
                    this.setState({
                      newBriefOpen: true,
                      page: Constants.PageDashboard,
                    });
                  }}
                  page={this.state.page == null ? "" : this.state.page}
                  handleSetPage={(page) => {
                    //if (window.location.search) {
                    //   window.sessionStorage.setItem("page", page);
                    //   //window.location.href = "";
                    // } else {
                    window.sessionStorage.removeItem("page");
                    window.document.title = page;
                    if (!!this.appInsights) {
                      this.appInsights.trackPageView({
                        name: "MP - " + page,
                        uri: apiConfig.redirectUri,
                        isLoggedIn: true,
                        properties: {
                          UserLoginName: this.state.username || this.state.user,
                        },
                      });
                    }
                    this.setState({ page: page });
                    //}
                  }}
                  handleSetUser={(user) => {
                    this.setState({ user: user });
                  }}
                />
              </div>{" "}
            </div>
            <div className="ms-Grid-row content">
              <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                {this.state.user && (
                  <div id="body" className="bodyApp">
                    {this.state.page === Constants.PageRouting &&
                      commonProps.onRoute && (
                        <RoutingComponent
                          commonProps={commonProps}
                        ></RoutingComponent>
                      )}
                    {this.state.page === Constants.PageDashboard && (
                      <Dashboard
                        commonProps={commonProps}
                        showNewBrief={this.state.newBriefOpen}
                        onDismissBrief={() =>
                          this.setState({ newBriefOpen: false })
                        }
                        onNavigate={(page: string) => {
                          this.setState({ page: page });
                        }}
                      />
                    )}
                    {this.state.page === Constants.PageMyBrief && (
                      <MyBrief commonProps={commonProps} />
                    )}
                    {this.state.page === Constants.PageActivities && (
                      <ListActivities commonProps={commonProps} />
                    )}
                    {this.state.page === Constants.PageAttachments && (
                      <ListAttachments
                        briefAttachments={false}
                        commonProps={commonProps}
                      />
                    )}
                    {this.state.page === Constants.PageFAQ && (
                      <CustomPageComponent
                        commonProps={commonProps}
                        title="faqs"
                      />
                    )}
                    {this.state.page === Constants.PageContacts && (
                      <CustomPageComponent
                        commonProps={commonProps}
                        title="contacts"
                      />
                    )}
                    {this.state.page === Constants.PageWhoWeAre && (
                      <CustomPageComponent
                        commonProps={commonProps}
                        title="whoweare"
                      />
                    )}
                    {this.state.page === Constants.PageLegal && (
                      <CustomPageComponent
                        commonProps={commonProps}
                        title="legal"
                      />
                    )}
                    {this.state.page === Constants.PagePrivacy && (
                      <CustomPageComponent
                        commonProps={commonProps}
                        title="privacy"
                      />
                    )}
                    {this.state.page === Constants.PageCookie && (
                      <CustomPageComponent
                        commonProps={commonProps}
                        title="cookie"
                      />
                    )}
                    {this.isGMUser() &&
                      this.state.page === Constants.PagePlanner && (
                        <Planner
                          commonProps={commonProps}
                          views={PlannerView.All} 
                          user={this.state.user}
                          username={this.state.username}
                          redirectUri={apiConfig.redirectUri}
                          isLoggedIn={true}                          
                        />
                      )}
                    {this.isGMUser() &&
                      this.state.page === Constants.PageReport && (
                        <Report commonProps={commonProps} />
                      )}
                    {this.state.page === Constants.PageSupport && (
                      <SupportComponent commonProps={commonProps} />
                    )}
                    <DefaultButton
                      alt="Scroll Top"
                      className={["btn-scrollTop"].join(" ")}
                      hidden={this.state.scrollY <= 50}
                      text=""
                      title={"Scroll up"}
                      ariaLabel="Scroll up"
                      iconProps={Constants.iUp}
                      allowDisabledFocus
                      onClick={() => {
                        window.scroll({ top: 0, left: 0, behavior: "smooth" });
                      }}
                      checked={true}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="ms-Grid-row footer-row">
              <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                <Footer
                  commonProps={commonProps}
                  language={this.state.language ? this.state.language : "en"}
                  handleSetLanguage={(language) => {
                    this.setState({ language: language });
                    i18next.changeLanguage(language).then((t) => {
                      this.changeLang(language);
                    });
                    this.storeVariableInLocalStorage("language", language);
                  }}
                />
              </div>
            </div>
            <ToastComponent
              commonProps={commonProps}
              ref={this.state.toastRefState}
            ></ToastComponent>
          </div>
        </div>
      </TelemetryProvider>
    );
  }

  private isGMUser(): boolean {
    return (
      this.state.user != undefined &&
      (Security.isGlobalMediaAdvanced ||
        Security.isGlobalMediaSpecialist ||
        Security.isAuditor ||
        Security.isAdmin)
    );
  }

  private storeVariableInLocalStorage(variableName: string, page: string) {
    localStorage.setItem(variableName, page);
  }

  render() {
    var isIE11 =
      !!(window as any).MSInputMethodContext &&
      !!(document as any).documentMode;
    if (isIE11) {
      var confirmDialogProps: IModalProps = { isBlocking: true };
      return (
        <div className="App">
          <Dialog
            hidden={false}
            onDismiss={() => {
              window.location.href = "";
            }}
            modalProps={confirmDialogProps}
            dialogContentProps={{
              type: DialogType.normal,
              title: "BROWSER NOT SUPPORTED",
              subText:
                "The website you are opening is not supported on Internet Explorer",
            }}
          >
            <DialogFooter>
              {/* <DefaultButton
                onClick={() => {
                  window.location.href = "";
                }}
                text="Chiudi"
              /> */}
            </DialogFooter>
          </Dialog>
        </div>
      );
    } else if (this.state.userError) {
      // this._userClient.logoutCurrentUser();
      var confirmDialogProps: IModalProps = { isBlocking: true };
      return (
        <div className="App">
          <Dialog
            hidden={false}
            onDismiss={() => {
              window.location.href = "";
            }}
            modalProps={confirmDialogProps}
            dialogContentProps={{
              type: DialogType.normal,
              title: "USER NOT RECOGNIZED",
              subText:
                "Your profile (" +
                this.state.username +
                ") is not recognized as a Mediaplan User. Please click on the button below to use a different a account",
            }}
          >
            <DialogFooter>
              <DefaultButton
                alt="Sign in as different user"
                onClick={() => {
                  this._userClient.logoutCurrentUser();
                }}
                text="Sign in as different user"
              />
            </DialogFooter>
          </Dialog>
        </div>
      );
    } else if (this.state.healthError) {
      var confirmDialogProps: IModalProps = { isBlocking: true };
      return (
        <div className="App">
          <Dialog
            hidden={false}
            onDismiss={() => {
              window.location.href = "";
            }}
            modalProps={confirmDialogProps}
            dialogContentProps={{
              type: DialogType.normal,
              title: "APP VERSION OUT OF DATE",
              subText:
                "Your app is obsolete. Please empty the browser cache and reload the page.",
            }}
          ></Dialog>
        </div>
      );
    } else {
      return this.RootApp();
    }
  }
}

export const AppNew = withAuth(withTranslation("common")(AppInternal));
