import { IColumn } from "@fluentui/react";
import { ICalendarPermission } from "./Planner";
import { User } from "./User";

export interface Applicant {
  referents: Referent[];
  applicantUnits: string[];
}

export interface Referent {
  userPrincipalName: string;
  displayName: string;
}

export interface IBriefHistoryItem {
  id: number;
  user: User;
  actionDate: Date;
  type: number;
}

export interface IBriefLookup {
  id: number;
  title: string;
}

export interface IBrief extends IBriefLookup {
  applicantUnit: string;
  otherReferents: string[];
  timingFrom?: Date;
  timingTo?: Date;
  expectedDelivery?: Date;
  description?: string;
  goalAndKeyMessage?: string;
  targets: string[];
  highlightsAndCriticalIssues?: string;
  embargoed: boolean;
  relevantStakeholders: string[];
  mediaCoverage: boolean;
  documentations: File[];
  files: IFile[];
  links: string[];
  filesToRemove?: number[];
  skipApprovation: boolean;
  referent?: string;
  onBehalf: boolean;
  send: boolean;
}

export interface IFile {
  id: number,
  name: string;
  openPath: string;
  downloadPath: string;
  size: number;
}

export interface IBriefDetail {
  id: number;
  createdOn: Date;
  lastUpdate: Date;
  title: string;
  applicantUnit: string;
  referent?: User;
  otherReferents: User[];
  timingFrom?: Date;
  timingTo?: Date;
  expectedDelivery?: Date;
  description?: string;
  goalsAndKeyMessages?: string;
  targets: string[];
  highlightsAndCriticalIssues?: string;
  embargoed: boolean;
  relevantStakeholders: string[];
  mediaCoverage: boolean;
  // documentations: IFile[] | undefined;
  files?: IFile[];
  links: string[];
  status: string;
  skipApprovation: boolean;
  onBehalf: boolean;
  permissions: IBriefDetailPermission;
  macroActivityCount: number;
}

export interface IBriefDetailPermission {
  canEdit: boolean;
  canApprove: boolean;
  canDelete: boolean;
  canSendForApproval: boolean;
  canDelegateCreateActivity: boolean;
  canDelegateApproval: boolean;
  canCreateActivity: boolean;
}

export interface IBriefFilter {
  title: string;
  status: string;
  referent: string;
  applicationUnit: string;
  fromDate?: Date;
  toDate?: Date;
  startIndex: number;
  orderBy?: IColumn[];
  id?: number;
  maxLength?: number;
}

export interface IListBrief {
  briefs: IListBriefDetails[];
  count: number;
  permission: IListBriefPermission;
}

export enum BriefStatusEnum {
  Draft = 0,
  Pending = 1,
  Rework = 2,
  Accepted = 3,
  Rejected = 4
}

export interface IBriefsCount {
  state: BriefStatusEnum;
  count: number;
}

export interface IListBriefPermission { }

export interface IListBriefDetails {
  id: number;
  title: string;
  status: string;
  referent: string;
  applicantUnit: string;
  date?: Date;
  lastAction?: IBriefHistoryItem;
  createdOn?: Date;
  lastUpdate?: Date;
  attachmentsCount: number;
}

export interface IBriefPermission {
  visibility: boolean;
  canAdd: boolean;
  canDelete: boolean;
  canDownloadBriefs: boolean;
}

export enum BriefType {
  EXCEL = "excel",
  WORD = "word",
  ONENOTE = "onenote",
  POWERPOINT = "powerpoint",
  PNG = "png",
  JPG = "jpg",
  GIF = "gif",
  TEXT = "text",
  PDF = "pdf",
}


