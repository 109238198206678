import { SupportClient } from './SupportClient';
import { ISupportClient } from './ISupportClient';
import { CustomPageClient } from './CustomPageClient';
import { ICustomPageClient } from './ICustomPageClient';
import { BriefClient } from './BriefClient';
import { NoteClient } from './NoteClient';
import { INoteClient } from './INoteClient';
import { DeliverableClient } from './DeliverableClient';
import { IDeliverableClient } from './IDeliverableClient';
import { ActivityClient } from "./ActivityClient";
import { IActivityClient } from "./IActivityClient";
import { IBriefClient} from "./IBriefClient";
import { IUserClient} from "./IUserClient";
import { UserClient } from "./UserClient";
import { TaskClient } from './TaskClient';
import { ITaskClient } from './ITaskClient';
import { IMacroClient} from "./IMacroClient";
import { MacroClient } from "./MacroClient";
import { INotificationHistoryClient } from "./INotificationHistoryClient";
import { NotificationHistoryClient } from "./NotificationHistoryClient";
import { HealthClient } from './HealthClient';
import { IHealthClient } from './IHealthClient';

export abstract class ClientCreator {

    public abstract createActivityClient(): IActivityClient;    
    public abstract createBriefClient(): IBriefClient;    
    public abstract createMacroClient(): IMacroClient;
    public abstract createUserClient(): IUserClient;    
    public abstract createHealthClient(): IHealthClient;    
    public abstract createDeliverableClient(): IDeliverableClient;    
    public abstract createTaskClient(): ITaskClient;    
    public abstract createNoteClient(): INoteClient;    
    public abstract createNotificationHistoryClient(): INotificationHistoryClient;    
    public abstract createCustomPageClient(): ICustomPageClient; 
    public abstract createSupportClient(): ISupportClient;    
}

export class HttpClientsCreator extends ClientCreator {

    private static instance: IUserClient;  //to implements singleton    

    public createNotificationHistoryClient(): INotificationHistoryClient
    {
        return new NotificationHistoryClient();
    }

    public createActivityClient(): IActivityClient
    {
        return new ActivityClient();
    }

    public createBriefClient(): IBriefClient
    {
        return new BriefClient();
    }

    public createUserClient(): IUserClient
    {
        return new UserClient();
    }

    public createHealthClient(): IHealthClient
    {
        return new HealthClient();
    }

    public createMacroClient(): IMacroClient
    {
        return new MacroClient();
    }    

    public createDeliverableClient(): IDeliverableClient
    {
        return new DeliverableClient();
    }

    public createTaskClient(): ITaskClient
    {
        return new TaskClient();
    }

    public createNoteClient(): INoteClient
    {
        return new NoteClient();
    }

    public createCustomPageClient(): ICustomPageClient
    {
        return new CustomPageClient();
    }

    public createSupportClient(): ISupportClient
    {
        return new SupportClient();
    }
}