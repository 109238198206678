import * as React from "react";
import "../../../Utility/DateExtensions";
import { Macro } from "../Activities/Macro";
import { Micro } from "../Activities/Micro";
import "./Planner.css";
import { PlannerTranslation } from "./Planner.Translation";
import "./BigCalendar/sass/agenda.scss";
import "./BigCalendar/sass/reset.scss";
import "./BigCalendar/sass/styles.scss";
import "./BigCalendar/sass/time-column.scss";
import "./BigCalendar/sass/time-grid.scss";
import "./BigCalendar/sass/toolbar.scss";
import "./BigCalendar/sass/variables.scss";
import { PlannerActivityDetails } from "./PlannerActivityDetails/PlannerActivityDetails";
import { PlannerNavigation } from "./PlannerNavigation/PlannerNavigation";
import { TimelineWrap } from "./Timeline/TimelineWrap";
import Calendar from "./BigCalendar/Calendar";
import VIEWS from "./BigCalendar/Views";
import * as dates from "./BigCalendar/utils/dates";
import {
  DefaultButton,
  IconButton,
  IDropdownOption,
  Modal,
  IDragOptions,
  ContextualMenu,
  FontIcon,
  DropdownMenuItemType,
} from "@fluentui/react";
import * as loc from "./BigCalendar/localizers/moment";
import moment from "moment";
import { IBaseProperties, IRoute, Page } from "../../../Models/IBaseProperties";
import { ActivityHelper, View } from "../../../Models/ActivityStatus";
import { Constants } from "../../../Utility/Constants";
import {
  IActivityAllAttributes,
  IMacro,
  IMicro,
} from "../../../Models/Activity";
import { EnumHelpers } from "../../../Models/EnumHelper";
import { ActivityStatusEnum } from "../../../Models/Activity";
import { DateLocalizer } from "./BigCalendar";
import { ToastNotificationType } from "../../../Models/ToastNote";
import { FormEvent } from "react";
import {
  ActivityTypeFilter,
  IActivityClient,
  IFilterPlannerActivity,
} from "../../../Clients/IActivityClient";
import { ToastComponent } from "../../Shared/ToastComponent/ToastComponent";
import { LoaderComponent } from "../../Shared/LoaderComponent/LoaderComponent";
import { IPlannerPermission } from "../../../Models/Planner";
import { ActivityTranslation } from "../../../Translations/Activity.Translation";
import { addListener, removeListener } from "./Timeline/utils/events";
import raf from "./Timeline/utils/raf";
import { ICalendar } from "../../../Models/ICalendar";
import { ICategory } from "../../../Models/ICategory";
import { ISensibility } from "../../../Models/ISensibility";
import { IResponsibility } from "../../../Models/IResponsibility";

const dragOptions: IDragOptions = {
  moveMenuItemText: "Move",
  closeMenuItemText: "Close",
  menu: ContextualMenu,
  dragHandleSelector: ".panelHeader",
};

export enum PlannerView {
  All,
  Calendar,
  Timeline,
}

export interface IPlannerProps extends IBaseProperties {
  views: PlannerView;
  isLoggedIn?: boolean;
  username?: string;
  user?: string;
  redirectUri: string;
}

export interface IPlannerState {
  activities: any[];
  calendars: ICalendar[];
  categories: IDropdownOption[];
  responsibilities: IDropdownOption[];
  sensibilities: IDropdownOption[];
  showAddbtn: boolean;
  isLoading: boolean;
  loadingText: string;
  showTimeline: boolean;
  showCalendar: boolean;
  newActivityVisible: boolean;
  newActivityType: string;
  activityDetailsVisible: boolean;
  activityDetailsOpen: any;
  selectedFilter: string;
  selectedFilterStatus: string[];
  selectedFilterCalendar: string[];
  selectedFilterSensibility: string[];
  selectedFilterCategory: string[];
  selectedFilterResponsibility: string[];
  selectedView: string;
  timelineZoom: any;
  timelineOpen: boolean;
  lastTimelineRequest?: number;
  tracks: any;
  dataRange: Date[];
  startDate: Date;
  endDate: Date;
  currentDate: Date;
  lastMonth: number;
  lastViewComponent: string;
  tracksById: any[];
  permissionCalendar?: IPlannerPermission;
  permissionTimeline?: IPlannerPermission;
  slotinfo: any;
  isSticky: boolean;
  showFilters: boolean;
  isLoggedIn?: boolean;
  username?: string;
  user?: string;
  redirectUri: string;
}

const filterStatus = EnumHelpers.getNames(ActivityStatusEnum);

export class Planner extends React.Component<IPlannerProps, IPlannerState> {
  private readonly activityClient: IActivityClient;
  private readonly _translation: PlannerTranslation;
  private readonly _activityTranslation: ActivityTranslation;

  private readonly toastComponent?: ToastComponent | null;
  private readonly localizer: DateLocalizer = loc.default(moment);
  private _isMounted = false;

  private readonly _today = (): Date => new Date();

  constructor(props: IPlannerProps) {
    super(props);
    this.toastComponent = props.commonProps.toastComponent;
    this.activityClient = this.props.commonProps.clientCreator.createActivityClient();
    this._translation = new PlannerTranslation(
      this.props.commonProps.translation
    );

    this._activityTranslation = new ActivityTranslation(
      this.props.commonProps.translation
    );

    const additionalInfo = props.commonProps.additionalInfo;
    let startupDate = this._today();
    if (
      additionalInfo &&
      additionalInfo.optionalValues &&
      additionalInfo.optionalValues.date
    ) {
      startupDate = additionalInfo.optionalValues.date;
      this.clearRoute();
    }

    const startWeek = moment(startupDate)
      .startOf("week")
      .toDate();
    const endWeek = this.toEndOfDay(
      moment(startupDate)
        .endOf("week")
        .toDate()
    );

    const showTimeline =
      props.views === PlannerView.All || props.views === PlannerView.Timeline
        ? true
        : false;
    const showCalendar = props.views === PlannerView.Calendar ? true : false;

    this.state = {
      loadingText: this._translation.getLoadingMessage,
      dataRange: this.weekRange(moment().toDate(), this.localizer),
      lastViewComponent: "week",
      activities: [],
      calendars: [],
      categories: [],
      responsibilities: [],
      sensibilities: [],
      showAddbtn: false,
      isLoading: true,
      showTimeline: showTimeline,
      showCalendar: showCalendar,
      newActivityVisible: false,
      newActivityType: "macro",
      activityDetailsVisible: false,
      activityDetailsOpen: {},
      selectedFilter: "all",
      selectedFilterStatus: filterStatus,
      selectedFilterCalendar: [],
      selectedFilterSensibility: [],
      selectedFilterCategory: [],
      selectedFilterResponsibility: [],
      selectedView: "week",
      timelineZoom: null,
      timelineOpen: true,
      tracks: null,
      tracksById: [],
      startDate: startWeek,
      endDate: endWeek,
      currentDate: startupDate,
      lastMonth: new Date().getMonth(),
      slotinfo: undefined,
      isSticky: false,
      showFilters: false,
      user: this.props.user,
      username: props.username,
      isLoggedIn: props.isLoggedIn,
      redirectUri: props.redirectUri
    };

    this.handleRangeChange = this.handleRangeChange.bind(this);
    this.onTimelineClick = this.onTimelineClick.bind(this);
    this.showBtns = this.showBtns.bind(this);
    this.hideModalNewActivity = this.hideModalNewActivity.bind(this);
    this.macroSaved = this.macroSaved.bind(this);
    this.microSaved = this.microSaved.bind(this);
    this.onChangeTimeLineSearch = this.onChangeTimeLineSearch.bind(this);
    this.onChangeCalendarFilter = this.onChangeCalendarFilter.bind(this);
    this.changeFilterStatusCalendar = this.changeFilterStatusCalendar.bind(
      this
    );
    this.reloadPlannerDataAsync = this.reloadPlannerDataAsync.bind(this);
    this.onToggleFilter = this.onToggleFilter.bind(this);
  }

  private clearRoute() {
    const route: IRoute = {
      page: Page.None,
      additionalInfo: {
        keysValues: {
          id: undefined,
          type: undefined,
          subtype: undefined,
          title: undefined,
          showMyActivities: undefined,
        },
        optionalValues: {
          date: undefined,
        },
      },
    };

    this.props.commonProps.onRoute?.next(route);
  }

  private toEndOfDay(date: Date): Date {
    const nDate = new Date(date.getTime());
    nDate.setHours(23, 59, 59, 999);
    return nDate;
  }

  async componentDidMount() {
    let calendars = this.state.calendars;
    if (this.state.calendars.length === 0) {
      calendars = (await this.activityClient.getCalendars()) || [];
    }

    let categories = this.state.categories;
    if (this.state.categories.length === 0) {
      const categoriesResponse =
        (await this.activityClient.getCategories()) || [];
      categories = categoriesResponse.map(
        (o: ICategory): IDropdownOption => ({
          key: o.key,
          text: o.value,
          itemType: DropdownMenuItemType.Normal,
        })
      );
      categories.unshift({
        key: 0,
        text: this._translation.selectCategory,
        itemType: DropdownMenuItemType.Normal,
      });
    }

    let sensibilities = this.state.sensibilities;
    if (this.state.sensibilities.length === 0) {
      const sensibilitiesResponse =
        (await this.activityClient.getSensibilities()) || [];
      sensibilities = sensibilitiesResponse.map(
        (o: ISensibility): IDropdownOption => ({
          key: o.key,
          text: o.value,
          itemType: DropdownMenuItemType.Normal,
        })
      );
      sensibilities.unshift({
        key: 0,
        text: this._translation.selectSensibility,
        itemType: DropdownMenuItemType.Normal,
      });
    }

    let responsibilities = this.state.responsibilities;
    if (this.state.responsibilities.length === 0) {
      const respobilitiesResponse =
        (await this.activityClient.getResponsibilities()) || [];
      responsibilities = respobilitiesResponse.map(
        (o: IResponsibility): IDropdownOption => ({
          key: o.key,
          text: o.value,
          itemType: DropdownMenuItemType.Normal,
        })
      );
      responsibilities.unshift({
        key: 0,
        text: this._translation.selectResponsibility,
        itemType: DropdownMenuItemType.Normal,
      });
    }

    this.setState({
      calendars: calendars || [],
      categories: categories,
      sensibilities: sensibilities,
      responsibilities: responsibilities,
      selectedFilterCalendar: (calendars || []).map((c) => c.key.toString()),
    });

    this._isMounted = true;
    await this.loadPlannerDataAsync();

    addListener("scroll", this.handleScrollY);
  }

  componentWillUnmount() {
    this._isMounted = false;
    removeListener("scroll", this.handleScrollY);
  }

  handleScrollY = (e: any) => {
    raf(() => {
      if (this.state.showCalendar) {
        this.setState(() => ({ isSticky: false }));
        return;
      }
      const headerHeight = 108;
      const markerHeight = 0;
      const { top, bottom } = e.target.scrollingElement.getBoundingClientRect();
      const isSticky = top <= -headerHeight;
      this.setState(() => ({ isSticky }));
    });
  };

  private async loadPlannerDataAsync(): Promise<void> {
    if (this.state.showCalendar) {
      await this.getActivitiesAsync();
    }

    if (this.state.showTimeline) {
      await this.getTimelineActivitiesAsync();
    }
  }

  private async reloadPlannerDataAsync(hideActivity?: boolean): Promise<void> {
    if (this.state.showCalendar) {
      await this.getActivitiesAsync();
    }

    if (this.state.showTimeline) {
      await this.getTimelineActivitiesAsync();
    }

    this.setState({
      activityDetailsVisible: false,
      activityDetailsOpen: {},
    });

    // if (!hideActivity) {
    //   let activity = undefined;
    //   if (this.state.activityDetailsOpen?.id) {
    //     if (this.state.activityDetailsOpen?.isMacro) {
    //       activity = await this.tryGetMacro(this.state.activityDetailsOpen?.id);
    //     } else {
    //       activity = await this.tryGetMicro(this.state.activityDetailsOpen?.id);
    //     }
    //   }
    //   if (activity) {
    //     this.setState({
    //       isLoading: false,
    //       activityDetailsOpen: activity,
    //       activityDetailsVisible: true,
    //       showAddbtn: false,
    //     });
    //   } else {
    //     this.setState({
    //       activityDetailsVisible: false,
    //       activityDetailsOpen: {},
    //     });
    //   }
    // } else {
    //   this.setState({
    //     activityDetailsVisible: false,
    //     activityDetailsOpen: {},
    //   });
    // }
  }

  private async getTimelineActivitiesAsync() {
    try {
      const {
        startDate,
        endDate,
        selectedFilter,
        selectedFilterStatus,
        selectedFilterCalendar,
        selectedFilterSensibility,
        selectedFilterCategory,
        selectedFilterResponsibility,
      } = this.state;
      const filter: IFilterPlannerActivity = {
        activityType: (ActivityTypeFilter as any)[
          selectedFilter.toLowerCase()
        ] as ActivityTypeFilter,
        activityStatus: selectedFilterStatus,
        activityCalendar: selectedFilterCalendar,
        activitySensibility: selectedFilterSensibility,
        activityCategory: selectedFilterCategory,
        activityResponsibility: selectedFilterResponsibility,
        fromDate: startDate,
        toDate: endDate,
      };

      const timelineResult = await this.activityClient.getTimelineByFilters(
        filter
      );
      if (
        timelineResult &&
        timelineResult.data &&
        timelineResult.permissions.visibility
      ) {
        // map calendarId to the relative Name
        for (var item of timelineResult.data) {
          for (let elem of item.elements) {
            elem.calendar =
              this.state.calendars.find((c) => c.key === +elem.calendar)
                ?.value || "---";
          }
          for (let t of item.tracks) {
            t.calendar =
              this.state.calendars.find((c) => c.key === +t.calendar)?.value ||
              "---";
            for (let tElem of t.elements) {
              tElem.calendar =
                this.state.calendars.find((c) => c.key === +tElem.calendar)
                  ?.value || "---";
            }
          }
        }

        const tracksById = timelineResult.data.reduce((acc: any, i: any) => {
          acc[i.id] = i;
          return acc;
        }, {});
        const tracks = Object.values(tracksById);

        if (this._isMounted) {
          this.setState({
            tracks: tracks,
            tracksById: tracksById,
            lastTimelineRequest: Date.now(),
            permissionTimeline: timelineResult.permissions,
          });
        }
      } else {
        this.toastComponent?.showMessage(
          this._translation.error,
          this._translation.getTimelineError,
          ToastNotificationType.ERROR
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  componentDidCatch(error: any, info: any) {
    //this.setState({ error: error, info: info });
    console.error(error);
  }

  private onChangeTimeLineSearch(
    event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined
  ) {}

  private async getActivitiesAsync() {
    try {
      const { startDate, endDate } = this.state;

      const filter: IFilterPlannerActivity = this.toCalendarFilterOption(
        startDate,
        endDate
      );

      const response = await this.activityClient.getCalendarActivities(filter);
      if (response) {
        if (this._isMounted) {
          // map calendaId to calendar name
          for (var act of response.data) {
            act.calendar =
              this.state.calendars.find((c) => c.key === +act.calendar)
                ?.value || "---";
            if (act.linkedActivities) {
              for (var l of act.linkedActivities) {
                l.calendar =
                  this.state.calendars.find((c) => c.key === +l.calendar)
                    ?.value || "---";
              }
            }
          }

          this.setState({
            activities: response.data,
            permissionCalendar: response.permissions,
          });
        }
      } else {
        this.toastComponent?.showMessage(
          this._translation.error,
          this._translation.getCalendarError,
          ToastNotificationType.ERROR
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  private toCalendarFilterOption(
    minDate: Date,
    maxDate: Date
  ): IFilterPlannerActivity {
    return {
      activityType: (ActivityTypeFilter as any)[
        this.state.selectedFilter.toLowerCase()
      ],
      activityStatus: this.state.selectedFilterStatus,
      activityCalendar: this.state.selectedFilterCalendar,
      activitySensibility: this.state.selectedFilterSensibility,
      activityCategory: this.state.selectedFilterCategory,
      activityResponsibility: this.state.selectedFilterResponsibility,
      fromDate: minDate,
      toDate: maxDate,
    };
  }

  private async macroSaved(newMacro: IMacro) {
    await this.afterActivitySaveAsync("Macro", newMacro);
  }

  private async microSaved(newMacro: IMicro) {
    await this.afterActivitySaveAsync("Micro", newMacro);
  }

  private async afterActivitySaveAsync(
    activityType: string,
    newActivity: IMacro | IMicro
  ) {
    this.hideModalNewActivity();
    this.setState(
      { isLoading: true, currentDate: newActivity.dateFrom },
      async () => {
        this.changeCurrentDate(newActivity.dateFrom);

        await this.loadPlannerDataAsync();

        this.toastComponent?.showMessage(
          this._translation.success,
          this._translation.saveSuccess(activityType),
          ToastNotificationType.SUCCESS
        );
      }
    );
  }

  private weekRange(date: Date, localizer: any): Date[] {
    const firstOfWeek = localizer.startOfWeek();
    const start = dates.startOf(date, "week", firstOfWeek);
    const end = dates.endOf(date, "week", firstOfWeek);

    return dates.range(start, end);
  }

  handleOnNavigate(date: any, view: any, action: any) {}
  handleDrillDown(date: any, view: any, drilldownView: any) {}

  private lastView?: string;

  private getCalendarView(
    viewComponent: string,
    dates: any
  ): string | undefined {
    if (viewComponent) {
      const view = viewComponent ? viewComponent : this.state.lastViewComponent;

      return view;
    } else if (
      this.lastView &&
      this.lastView !== this.state.lastViewComponent
    ) {
      return undefined;
    } else {
      return this.state.lastViewComponent;
    }
  }

  private onTimelineClick(date: Date, view: string): void {
    const start = date;
    let end;
    if (view === "day") {
      end = start.toEndOfDay();
    } else {
      end = moment(start)
        .endOf("month")
        .toDate();
      end = this.toEndOfDay(end);
    }

    this.setState(
      {
        currentDate: date,
        lastViewComponent: view,
        selectedView: view,
        startDate: start,
        endDate: end,
      },
      () => {
        this.getTimelineActivitiesAsync();
      }
    );
  }

  handleRangeChange(dates: any, viewComponent: any, view: any) {
    if (viewComponent) {
      // let sView = typeof viewComponent === "string" ? viewComponent : viewComponent.name.replace("View", "");
      const sView = view;
      const currentView = this.getCalendarView(sView, dates);
      this.lastView = currentView;
      let { currentDate } = this.state;
      if (currentView) {
        let ranges: Date[] = [];
        switch (currentView.toLowerCase()) {
          case "year":
            ranges = this.getYearRange(dates);
            break;
          case "month":
            ranges = this.getMonthRange(dates);
            currentDate = this.getFirstMonthDay(ranges[0]);
            break;
          case "week":
            ranges = this.getWeekRange(dates);
            break;
          case "day":
            currentDate = dates[0]; //change current/selected date
            ranges = this.getDayRange(dates);
            break;
        }

        this.setState(
          {
            dataRange: ranges,
            lastViewComponent: currentView,
            selectedView: currentView,
            startDate: ranges[0],
            endDate: ranges[1],
            currentDate: currentDate,
          },
          () => {
            this.getActivitiesAsync();
          }
        );
      }
    }
  }

  private getFirstMonthDay(date: Date): Date {
    const month = date.getMonth();
    const day = date.getDate();
    const year = date.getFullYear();

    let firstMonthDate: Date;
    if (day === 1) {
      firstMonthDate = new Date(year, month, day);
    } else {
      if (month === 11) {
        firstMonthDate = new Date(year + 1, 0, 1);
      } else {
        firstMonthDate = new Date(year, month + 1, 1);
      }
    }

    return firstMonthDate;
  }

  private getYearRange(dates: Date[]): [Date, Date] {
    const anyDate = dates[0];
    const year = anyDate.getFullYear();
    const minDate = new Date(year, 0, 1);
    const maxDate = new Date(year, 11, 31, 23, 59, 59);

    return [minDate, maxDate];
  }

  private getDayRange(dates: any): [Date, Date] {
    const minDate = dates[0];
    const maxDate = minDate.toEndOfDay();

    return [minDate, maxDate];
  }

  private getMonthRange(dates: any): [Date, Date] {
    const minDate = dates.start;
    const maxDate = dates.end;

    return [minDate, maxDate];
  }

  private getWeekRange(dates: any): [Date, Date] {
    return dates;
  }

  render() {
    const showMacro =
      this.state.newActivityType.toLowerCase() === "macro" ? true : false;
    const showAddbtn = this.state.showAddbtn ? "" : "display-none";

    const localizer = loc.default(moment);

    const views: View[] = this.getViews();

    const macro = (
      <Macro
        slotinfo={this.state.slotinfo}
        commonProps={this.props.commonProps}
        className={Constants.activityModalPanelStyle.body}
        hideModalCallback={this.hideModalNewActivity}
        onSave={this.macroSaved}
      ></Macro>
    );
    const micro = (
      <Micro
        slotinfo={this.state.slotinfo}
        commonProps={this.props.commonProps}
        className={Constants.activityModalPanelStyle.body}
        hideModalCallback={this.hideModalNewActivity}
        onSave={this.microSaved}
      ></Micro>
    );

    const renderActivity = showMacro ? macro : micro;
    const activityTitle = this.getActivityTitle(showMacro);

    const canAdd = this.state.permissionCalendar
      ? this.state.permissionCalendar?.canAdd
      : false;
    const canShowTimeline =
      this.state.showTimeline && this.state.permissionTimeline
        ? this.state.permissionTimeline.visibility
        : false;
    const canShowCalendar =
      this.state.showCalendar && this.state.permissionCalendar
        ? this.state.permissionCalendar.visibility
        : false;
    const canViewPlanner = canShowCalendar || canShowTimeline;

    return (
      <React.Fragment>
        <LoaderComponent
          commonProps={this.props.commonProps}
          label={this.state.loadingText}
          isLoading={this.state.isLoading}
        />
        {canViewPlanner && (
          <div role="filterView" style={{ marginTop: 16 }}>
            {/* {this.state.showTimeline && ( */}
            <PlannerNavigation
              views={this.props.views}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              commonProps={this.props.commonProps}
              selectedFilter={this.state.selectedFilter}
              selectedFilterStatus={this.state.selectedFilterStatus}
              selectedFilterCalendar={this.state.selectedFilterCalendar}
              selectedFilterSensibility={this.state.selectedFilterSensibility}
              selectedFilterCategory={this.state.selectedFilterCategory}
              selectedFilterResponsibility={
                this.state.selectedFilterResponsibility
              }
              // viewNames={Constants.views}
              viewNames={views}
              showFilters={this.state.showFilters}
              onChangeShowFilter={this.onToggleFilter}
              isSticky={this.state.showTimeline} //this.state.isSticky
              selectedView={this.state.selectedView}
              showCalendar={this.state.showCalendar}
              showTimeline={this.state.showTimeline}
              onClickToday={this.clickToday.bind(this)}
              onChangeSelectedView={this.changeView.bind(this)}
              onChangeFilter={this.changeFilter.bind(this)}
              onChangeFilterStatus={this.changeFilterStatus.bind(this)}
              onClickPrev={this.clickPrev.bind(this)}
              onClickNext={this.clickNext.bind(this)}
              onChangePlannerType={this.changePlannerType.bind(this)}
              calendars={this.state.calendars}
              onChangeFilterCalendar={this.changeFilterCalendar.bind(this)}
              sensibilities={this.state.sensibilities}
              onChangeFilterSensibility={this.changeFilterSensibility.bind(
                this
              )}
              categories={this.state.categories}
              responsibilities={this.state.responsibilities}
              onChangeFilterCategory={this.changeFilterCategory.bind(this)}
              onChangeFilterResponsibility={this.changeFilterResponsibility.bind(
                this
              )}
              user={this.state.user}
              username={this.state.username}
              redirectUri={this.state.redirectUri}
              isLoggedIn={this.state.isLoggedIn}
            />
            {/* <PlannerNavigationNew
              views={this.props.views}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              commonProps={this.props.commonProps}
              selectedFilter={this.state.selectedFilter}
              selectedFilterStatus={this.state.selectedFilterStatus}
              selectedFilterCalendar={this.state.selectedFilterCalendar}
              selectedFilterSensibility={this.state.selectedFilterSensibility}
              selectedFilterCategory={this.state.selectedFilterCategory}
              selectedFilterResponsibility={this.state.selectedFilterResponsibility}
              // viewNames={Constants.views}
              viewNames={views}
              showFilters={this.state.showFilters}
              onChangeShowFilter={this.onToggleFilter}
              isSticky={this.state.showTimeline} //this.state.isSticky
              selectedView={this.state.selectedView}
              showCalendar={this.state.showCalendar}
              showTimeline={this.state.showTimeline}
              onClickToday={this.clickToday.bind(this)}
              onChangeSelectedView={this.changeView.bind(this)}
              onChangeFilter={this.changeFilter.bind(this)}
              onChangeFilterStatus={this.changeFilterStatus.bind(this)}
              onClickPrev={this.clickPrev.bind(this)}
              onClickNext={this.clickNext.bind(this)}
              onChangePlannerType={this.changePlannerType.bind(this)}
              calendars={this.state.calendars}
              onChangeFilterCalendar={this.changeFilterCalendar.bind(this)}
              sensibilities={this.state.sensibilities}
              onChangeFilterSensibility={this.changeFilterSensibility.bind(
                this
              )}
              categories={this.state.categories}
              responsibilities={this.state.responsibilities}
              onChangeFilterCategory={this.changeFilterCategory.bind(this)}
              onChangeFilterResponsibility={this.changeFilterResponsibility.bind(this)}
            /> */}
            {/* <PlannerNavigation
              views={this.props.views}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              commonProps={this.props.commonProps}
              selectedFilter={this.state.selectedFilter}
              selectedFilterStatus={this.state.selectedFilterStatus}
              selectedFilterCalendar={this.state.selectedFilterCalendar}
              selectedFilterSensibility={this.state.selectedFilterSensibility}
              selectedFilterCategory={this.state.selectedFilterCategory}
              selectedFilterResponsibility={this.state.selectedFilterResponsibility}
              // viewNames={Constants.views}
              viewNames={views}
              showFilters={this.state.showFilters}
              onChangeShowFilter={this.onToggleFilter}
              isSticky={this.state.showTimeline} //this.state.isSticky
              selectedView={this.state.selectedView}
              showCalendar={this.state.showCalendar}
              showTimeline={this.state.showTimeline}
              onClickToday={this.clickToday.bind(this)}
              onChangeSelectedView={this.changeView.bind(this)}
              onChangeFilter={this.changeFilter.bind(this)}
              onChangeFilterStatus={this.changeFilterStatus.bind(this)}
              onClickPrev={this.clickPrev.bind(this)}
              onClickNext={this.clickNext.bind(this)}
              onChangePlannerType={this.changePlannerType.bind(this)}
              calendars={this.state.calendars}
              onChangeFilterCalendar={this.changeFilterCalendar.bind(this)}
              sensibilities={this.state.sensibilities}
              onChangeFilterSensibility={this.changeFilterSensibility.bind(
                this
              )}
              categories={this.state.categories}
              responsibilities={this.state.responsibilities}
              onChangeFilterCategory={this.changeFilterCategory.bind(this)}
              onChangeFilterResponsibility={this.changeFilterResponsibility.bind(this)}
            /> */}
            {/* )} */}
            {this.state.showCalendar && (
              <>
                <div role="calender">
                  <Calendar
                    toolbar={false}
                    selectable
                    localizer={localizer}
                    date={this.state.startDate}
                    onNavigate={() => {}}
                    events={this.state.activities}
                    // showFilters={this.state.showFilters}
                    // defaultView={this.getSelView(this.state.selectedView)}
                    view={this.getSelView(this.state.selectedView)}
                    onView={this.getSelView(this.state.selectedView)}
                    scrollToTime={new Date(2000, 1, 1, 8)}
                    defaultDate={new Date()}
                    // onDrillDown={this.handleDrillDown}
                    onRangeChange={this.handleRangeChange}
                    onSelectSlot={this.showBtns}
                    //onSelectEvent={() => alert("onSelectEvent click")}
                    onKeyPressEvent={(event: {
                      id: string;
                      title: string;
                      start: Date;
                      end: Date;
                      desc: string;
                      state: string;
                      isMacro: boolean;
                      Nmicro: number;
                      linkedActivities: any[];
                    }) => this.openActivityDetails(event) }
                    onSelectEvent={(event: {
                      id: string;
                      title: string;
                      start: Date;
                      end: Date;
                      desc: string;
                      state: string;
                      isMacro: boolean;
                      Nmicro: number;
                      linkedActivities: any[];
                    }) => this.openActivityDetails(event)}
                    translation={this.props.commonProps.translation}
                    selectedDay={this.state.currentDate}
                  />
                  <Modal
                    isOpen={this.state.newActivityVisible}
                    closeButtonAriaLabel="Close"
                    onDismiss={this.hideModalNewActivity}
                    containerClassName={
                      Constants.activityModalPanelStyle.container
                    }
                    dragOptions={dragOptions}
                  >
                    <div className="panelHeader">
                      <span>{activityTitle}</span>
                      <div>
                        <IconButton
                          style={{ float: "right" }}
                          iconProps={Constants.iCancel}
                          aria-label="Close"
                          ariaLabel="Close popup modal"
                          onClick={() => this.hideModalNewActivity()}
                        />
                      </div>
                    </div>

                    <div>{renderActivity}</div>
                  </Modal>
                  <div
                    className={["panel-add-activity-buttons", showAddbtn].join(
                      " "
                    )}
                    style={{
                      top: this.state.slotinfo?.box?.y || "none",
                      left: this.state.slotinfo?.box?.x || "none",
                    }}
                  >
                    <IconButton
                      className={"panel-add-activity-buttons-close"}
                      iconProps={Constants.iCancel}
                      aria-label="Close"
                      ariaLabel="Close"
                      onClick={() =>
                        this.setState({
                          showAddbtn: false,
                          slotinfo: undefined,
                        })
                      }
                    />
                    <DefaultButton
                      className={["btn-add-activity", "macro"].join(" ")}
                      text={this._translation.addMacro}
                      allowDisabledFocus
                      onClick={() => this.openModalNewActivity("macro")}
                      disabled={false}
                      checked={true}
                    />
                    <br />
                    <DefaultButton
                      className={["btn-add-activity", "micro"].join(" ")}
                      text={this._translation.addMicro}
                      allowDisabledFocus
                      onClick={() => this.openModalNewActivity("micro")}
                      disabled={false}
                      checked={true}
                    />
                  </div>
                  {/* <DefaultButton
                    className={["btn-add"].join(" ")}
                    text=""
                    iconProps={Constants.iAdd}
                    allowDisabledFocus
                    onClick={this.showBtns}
                    disabled={!canAdd}
                    hidden={!canAdd}
                    checked={true}
                  /> */}
                </div>
              </>
            )}
            {canShowTimeline && this.state.permissionTimeline && (
              <TimelineWrap
                onClickDate={this.onTimelineClick}
                lastUpdate={this.state.lastTimelineRequest}
                tracks={this.state.tracks}
                tracksById={this.state.tracksById}
                commonProps={this.props.commonProps}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                selectedView={this.state.selectedView}
                selectedFilter={this.state.selectedFilter}
                selectedFilterStatus={this.state.selectedFilterStatus}
                onSelectActivity={(activity: any) =>
                  this.openActivityDetails(activity)
                }
                onChange={this.onChangeTimeLineSearch}
                enableSticky
                isSticky={true} //this.state.isSticky}
                showFilters={this.state.showFilters}
                selectedDay={this.state.currentDate}
                permission={this.state.permissionTimeline}
              />
            )}
            {this.state.activityDetailsOpen && (
              <PlannerActivityDetails
                commonProps={this.props.commonProps}
                calendars={this.state.calendars}
                sensibilities={this.state.sensibilities}
                categories={this.state.categories}
                responsibilities={this.state.responsibilities}
                idActivity={this.state.activityDetailsOpen.id}
                activityDetailsVisible={this.state.activityDetailsVisible}
                activityDetailsOpen={this.state.activityDetailsOpen}
                hideActivityDetails={() => this.hideActivityDetails()}
                reloadActivities={this.reloadPlannerDataAsync}
              />
            )}
          </div>
        )}
      </React.Fragment>
    );
  }

  private getViews(): View[] {
    return ActivityHelper.views;
  }

  private getActivityTitle(isMacro: boolean) {
    return this._translation.activityTitle(isMacro ? "Macro" : "Micro");
  }

  private showBtns(slotinfo: any) {
    if (!!slotinfo?.box?.y) {
      slotinfo.box.y = slotinfo.box.y - 80;
    }
    this.setState({
      showAddbtn: true,
      slotinfo: slotinfo,
    });
  }
  private openModalNewActivity(msg: string) {
    this.setState({
      newActivityType: msg,
      newActivityVisible: true,
      showAddbtn: false,
    });
  }
  private hideModalNewActivity() {
    this.setState({
      newActivityType: "",
      newActivityVisible: false,
      showAddbtn: false,
      slotinfo: undefined,
    });
  }
  private openActivityDetails(activity: any): void {
    try {
      this.setState({
        showAddbtn: false,
        slotinfo: undefined,
      });
      if (activity) {
        if (activity.isTask) {
          return;
        }

        let id: number = -1;

        if (activity && activity.id && !isNaN(activity.id)) {
          id = activity.id;
        } else if (activity && activity.identifier) {
          id = activity.identifier;
        }

        this.setState(
          {
            isLoading: true,
          },
          async () => {
            try {
              await this.loadActivityById(activity, id);
            } catch (err) {
              console.error(err);
              this.props.commonProps.toastComponent?.showMessage(
                this._translation.error,
                this._translation.activityLoadingError,
                ToastNotificationType.ERROR
              );
            } finally {
              this.setState({
                isLoading: false,
              });
            }
          }
        );
      }
    } catch (error) {
      console.error(error);
    }
  }

  private async getMacro(
    id: number
  ): Promise<IActivityAllAttributes | undefined> {
    const result = await this.activityClient.getMacroActivity(id);
    if (result) {
    } else {
      this.props.commonProps.toastComponent?.showMessage(
        this._activityTranslation.error,
        this._activityTranslation.getActivityError,
        ToastNotificationType.ERROR
      );
    }
    return result;
  }

  private async getMicro(
    id: number
  ): Promise<IActivityAllAttributes | undefined> {
    // const result = await this.activityClient.getMicroActivity(id);
    // return result;
    const result = await this.activityClient.getMicroActivity(id);
    if (result) {
    } else {
      this.props.commonProps.toastComponent?.showMessage(
        this._activityTranslation.error,
        this._activityTranslation.getActivityError,
        ToastNotificationType.ERROR
      );
    }
    return result;
  }

  private async tryGetMacro(
    id: number
  ): Promise<IActivityAllAttributes | undefined> {
    const result = await this.activityClient.getMacroActivity(id);
    return result;
  }

  private async tryGetMicro(
    id: number
  ): Promise<IActivityAllAttributes | undefined> {
    // const result = await this.activityClient.getMicroActivity(id);
    // return result;
    const result = await this.activityClient.getMicroActivity(id);
    return result;
  }

  private async loadActivityById(activity: any, id: number): Promise<void> {
    if (activity?.id) {
      if (activity?.isMacro) {
        let macro = await this.getMacro(id);

        this.setState({
          isLoading: false,
          activityDetailsOpen: macro,
          activityDetailsVisible: true,
          showAddbtn: false,
          slotinfo: undefined,
        });
      } else {
        let micro = await this.getMicro(id);

        this.setState({
          isLoading: false,
          activityDetailsOpen: micro,
          activityDetailsVisible: true,
          showAddbtn: false,
          slotinfo: undefined,
        });
      }
    }
  }

  private hideActivityDetails() {
    this.setState({
      activityDetailsVisible: false,
      showAddbtn: false,
      slotinfo: undefined,
    });
  }

  private changeFilter(
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption,
    index?: number
  ) {
    if (option) {
      this.setState(
        {
          isLoading: true,
          selectedFilter: option.key.toString(),
        },
        async () => {
          await this.loadPlannerDataAsync();
        }
      );
    }
  }

  private onChangeCalendarFilter(
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption,
    index?: number
  ) {
    if (option) {
      this.setState(
        {
          isLoading: true,
          selectedFilter: option.key.toString(),
        },
        async () => await this.getActivitiesAsync()
      );
    }
  }

  public clickToday() {
    let today = new Date();
    let start = this.state.startDate;
    let end = this.state.endDate;
    switch (this.state.selectedView.toLowerCase()) {
      case "day":
        start = end = today;
        break;
      case "week":
        start = moment(today)
          .startOf("week")
          .toDate();
        end = moment(today)
          .endOf("week")
          .toDate();
        break;
      case "month":
        start = moment(today)
          .startOf("month")
          .toDate();
        end = moment(today)
          .endOf("month")
          .toDate();
        break;
      case "year":
        start = moment(today)
          .startOf("year")
          .toDate();
        end = moment(today)
          .endOf("year")
          .toDate();
        break;
    }
    end = this.toEndOfDay(end);
    start.setHours(0, 0, 0);
    this.setState(
      {
        startDate: start,
        endDate: end,
        lastMonth: today.getMonth(),
        currentDate: this._today(),
      },
      async () => {
        await this.loadPlannerDataAsync();
      }
    );
  }

  private changeCurrentDate(currentDate: Date) {
    let start = this.state.startDate;
    let end = this.state.endDate;
    switch (this.state.selectedView.toLowerCase()) {
      case "day":
        start = end = currentDate;
        break;
      case "week":
        start = moment(currentDate)
          .startOf("week")
          .toDate();
        end = moment(currentDate)
          .endOf("week")
          .toDate();
        break;
      case "month":
        start = moment(currentDate)
          .startOf("month")
          .toDate();
        end = moment(currentDate)
          .endOf("month")
          .toDate();
        break;
      case "year":
        start = moment(currentDate)
          .startOf("year")
          .toDate();
        end = moment(currentDate)
          .endOf("year")
          .toDate();
        break;
    }
    end = this.toEndOfDay(end);
    start.setHours(0, 0, 0);
    this.setState({
      startDate: start,
      endDate: end,
      lastMonth: currentDate.getMonth(),
      currentDate: currentDate,
    });
  }

  public clickPrev() {
    this.navPrevNext("prev");
  }
  public clickNext() {
    this.navPrevNext("next");
  }

  private async navPrevNext(type: string) {
    let start = new Date(this.state.startDate.getTime());
    let end = new Date(this.state.endDate.getTime());

    switch (this.state.selectedView.toLowerCase()) {
      case "day":
        if (type === "prev") {
          start = start.addDays(-1);
          end = end.addDays(-1);
        }
        if (type === "next") {
          start = start.addDays(1);
          end = end.addDays(1);
        }
        break;
      case "week":
        if (type === "prev") {
          start = start.addDays(-7);
          end = end.addDays(-7);
        }
        if (type === "next") {
          start = start.addDays(7);
          end = end.addDays(7);
        }
        break;
      case "month":
        if (type === "prev") {
          start = new Date(
            start.getFullYear(),
            start.getMonth() - 1,
            start.getDate()
          );
          end = new Date(start.getFullYear(), start.getMonth() + 1, 0);
        }
        if (type === "next") {
          start = new Date(
            start.getFullYear(),
            start.getMonth() + 1,
            start.getDate()
          );
          end = new Date(start.getFullYear(), start.getMonth() + 1, 0);
        }
        break;
      case "year":
        if (type === "prev") {
          start = new Date(
            start.getFullYear() - 1,
            start.getMonth(),
            start.getDate()
          );
          end = new Date(end.getFullYear() - 1, end.getMonth(), end.getDate());
        }
        if (type === "next") {
          start = new Date(
            start.getFullYear() + 1,
            start.getMonth(),
            start.getDate()
          );
          end = new Date(end.getFullYear() + 1, end.getMonth(), end.getDate());
        }
        break;
    }

    end = this.toEndOfDay(end);
    this.setState(
      {
        startDate: start,
        endDate: end,
        lastMonth: start.getMonth(),
        //currentDate: start,
      },
      async () => {
        await this.loadPlannerDataAsync();
      }
    );
  }

  public getSelView(selectedView: string) {
    let viewToReturn = VIEWS.week;
    switch (selectedView.toLowerCase()) {
      case "day":
        viewToReturn = VIEWS.day;
        break;
      case "week":
        viewToReturn = VIEWS.week;
        break;
      case "month":
        viewToReturn = VIEWS.month;
        break;
      case "year":
        viewToReturn = VIEWS.year;
        break;
    }

    return viewToReturn;
  }

  public changeView(view: string) {
    // let start = this.state.startDate;
    // let end = this.state.endDate;
    const { currentDate } = this.state;
    let start = currentDate;
    let end = start;
    end.setHours(23, 59, 59, 999);

    switch (view.toLowerCase()) {
      case "day":
        end = start;
        break;
      case "week":
        start = moment(start)
          .startOf("week")
          .toDate();
        end = moment(start)
          .endOf("week")
          .toDate();
        break;
      case "month":
        // start.setMonth(this.state.lastMonth);

        start = moment(start)
          .startOf("month")
          .toDate();
        end = moment(start)
          .endOf("month")
          .toDate();
        break;
      case "year":
        start = moment(end)
          .startOf("year")
          .toDate();
        end = moment(end)
          .endOf("year")
          .toDate();
        break;
    }
    start.setHours(0, 0, 0);
    end = this.toEndOfDay(end);
    this.setState(
      {
        selectedView: view,
        startDate: start,
        endDate: end,
      },
      async () => {
        await this.loadPlannerDataAsync();
      }
    );
  }

  public changePlannerType(type: string) {
    this.setState(
      {
        showCalendar: type === "calendar",
        showTimeline: type === "timeline",
        isLoading: true,
      },
      async () => {
        await this.loadPlannerDataAsync();
      }
    );
  }

  public changeFilterCalendar(
    event: React.FormEvent<HTMLDivElement>,
    item?: IDropdownOption
  ) {
    if (item) {
      this.setState(
        {
          isLoading: true,
          selectedFilterCalendar: item.selected
            ? [...this.state.selectedFilterCalendar, item.key as string]
            : this.state.selectedFilterCalendar.filter(
                (key) => key !== item.key
              ),
        },
        async () => {
          await this.loadPlannerDataAsync();
        }
      );
    }
  }

  public changeFilterSensibility(
    event: React.FormEvent<HTMLDivElement>,
    item?: IDropdownOption
  ) {
    if (item) {
      this.setState(
        {
          isLoading: true,
          selectedFilterSensibility: item.selected
            ? [...this.state.selectedFilterSensibility, item.key as string]
            : this.state.selectedFilterSensibility.filter(
                (key) => key !== item.key
              ),
        },
        async () => {
          await this.loadPlannerDataAsync();
        }
      );
    }
  }

  public changeFilterCategory(
    event: React.FormEvent<HTMLDivElement>,
    item?: IDropdownOption
  ) {
    if (item) {
      this.setState(
        {
          isLoading: true,
          selectedFilterCategory: item.selected
            ? [...this.state.selectedFilterCategory, item.key as string]
            : this.state.selectedFilterCategory.filter(
                (key) => key !== item.key
              ),
        },
        async () => {
          await this.loadPlannerDataAsync();
        }
      );
    }
  }

  public changeFilterResponsibility(
    event: React.FormEvent<HTMLDivElement>,
    item?: IDropdownOption
  ) {
    if (item) {
      this.setState(
        {
          isLoading: true,
          selectedFilterResponsibility: item.selected
            ? [...this.state.selectedFilterResponsibility, item.key as string]
            : this.state.selectedFilterResponsibility.filter(
                (key) => key !== item.key
              ),
        },
        async () => {
          await this.loadPlannerDataAsync();
        }
      );
    }
  }

  public changeFilterStatus(
    event: React.FormEvent<HTMLDivElement>,
    item?: IDropdownOption
  ) {
    if (item) {
      this.setState(
        {
          isLoading: true,
          selectedFilterStatus: item.selected
            ? [...this.state.selectedFilterStatus, item.key as string]
            : this.state.selectedFilterStatus.filter((key) => key !== item.key),
        },
        async () => {
          await this.loadPlannerDataAsync();
        }
      );
    }
  }

  private changeFilterStatusCalendar(
    event: React.FormEvent<HTMLDivElement>,
    item?: IDropdownOption
  ) {
    if (item) {
      this.setState(
        {
          isLoading: true,
          selectedFilterStatus: item.selected
            ? [...this.state.selectedFilterStatus, item.key as string]
            : this.state.selectedFilterStatus.filter((key) => key !== item.key),
        },
        async () => {
          await this.getActivitiesAsync();
        }
      );
    }
  }

  private getShowFilters(event: React.FormEvent<HTMLDivElement>) {
    return this.state.showFilters;
  }

  private onToggleFilter(event: any) {
    this.setState({ showFilters: !this.state.showFilters });
  }
}
