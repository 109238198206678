import * as React from "react";
import $ from "jquery";

import "bootstrap/dist/css/bootstrap.css";

import "@fluentui/react/dist/css/fabric.min.css";

import { initializeIcons } from "@uifabric/icons";

import { DeliverablesTranslation } from "../../../../../../Translations/Deliverables.Translation";
import { Utility } from "../../../../../../Utility/Utility";
import "../../../../../../Utility/DateExtensions";

// import "./DeliverablesComponent.scss";
import styles from "./DeliverablesComponent.module.scss";

import {
  ISelection,
  Selection,
  SelectionZone,
} from "office-ui-fabric-react/lib/Selection";

import {
  ContextualMenu,
  DefaultButton,
  DetailsList,
  DetailsListLayoutMode,
  DetailsRow,
  Dialog,
  DialogFooter,
  DialogType,
  DirectionalHint,
  Dropdown,
  FontIcon,
  IColumn,
  IconButton,
  IContextualMenuItem,
  IContextualMenuProps,
  IDetailsListProps,
  IDialogContentProps,
  IDropdownOption,
  IModalProps,
  IObjectWithKey,
  MarqueeSelection,
  Modal,
  PersonaSize,
  PrimaryButton,
  ScrollablePane,
  ScrollbarVisibility,
  SelectionMode,
  ShimmeredDetailsList,
  TextField,
} from "@fluentui/react";

import {
  DeliverableType,
  IDeliverable,
  DeliverableTypeIcons,
  DeliverableTypeLabels,
  IDeliverableVersion,
  IDeliverableDetails,
  ITemplates,
} from "../../../../../../Models/Deliverable";
import { IBaseProperties } from "../../../../../../Models/IBaseProperties";
import { LoaderComponent } from "../../../../../Shared/LoaderComponent/LoaderComponent";
import { ManageResourcesComponent } from "../../../../../Shared/ManageResourcesComponent/ManageResourcesComponent";
import { PanelActivityHeaderComponent } from "../../../../../Shared/PanelActivityHeaderComponent/PanelActivityHeaderComponent";
import { VersionHistoryComponent } from "../../../../../Shared/VersionHistoryComponent/VersionHistoryComponent";
import { IDeliverableClient } from "../../../../../../Clients/IDeliverableClient";
import {
  ActivityPermissions,
  IActivityAllAttributes,
} from "../../../../../../Models/Activity";
import {
  ToastNotificationPosition,
  ToastNotificationType,
} from "../../../../../../Models/ToastNote";
import { EnumHelpers } from "../../../../../../Models/EnumHelper";
import { DeliverableVersionDTO } from "../../../../../../Models/DTO/DeliverableDTO";
import { IActivityClient } from "../../../../../../Clients/IActivityClient";
import { SpinnerComponent } from "../../../../../Shared/SpinnerComponent/SpinnerComponent";
import { Constants } from "../../../../../../Utility/Constants";
import { ResponsiveEmbed } from "react-bootstrap";
import { ActivityTranslation } from "../../../../../../Translations/Activity.Translation";
import { randomInt } from "crypto";

export interface IDeliverablesComponentProps extends IBaseProperties {
  activity?: IActivityAllAttributes;
  // deliverableItems: IDeliverable[];
  // saveDeliverable: (content: any) => Promise<boolean>;
  // deleteDeliverable: (itemId: number) => void;
  // addNewFile: (filename: string, type: DeliverableType) => Promise<boolean>;
  onRenderModalHeader: () => any;
  showMessage: (
    title: string,
    description: string,
    type: ToastNotificationType
  ) => void;
}

export interface IDeliverablesComponentState {
  isLoading: boolean;
  permissions: ActivityPermissions;
  derivableColumns: IColumn[];
  deliverableItems?: IDeliverableDetails[];
  addDerivableContextualMenuProps?: IContextualMenuProps;
  derivableContextualMenuProps?: IContextualMenuProps;
  selectionEnabled: boolean;
  itemsSelected: IDeliverable[];
  // selectAllDeliverables: boolean;

  allowedExtensions?: string[];
  templates?: ITemplates[];

  uploadDisabled: boolean;

  newItemFilename?: string;
  newTemplateName?: string;
  viewNewItem: boolean;
  errorFileInsert: boolean;

  deliverableItemSelected?: IDeliverableDetails;

  itemToDelete?: number;
  showDeleteDialog: boolean;

  showPreview: boolean;
  deliverableItemSelectedForPreview?: IDeliverableDetails;

  showFileOverwrite: boolean;
  fileSelected: any;
}

export class DeliverablesComponent extends React.Component<
  IDeliverablesComponentProps,
  IDeliverablesComponentState
> {
  _derivableColumns: IColumn[];
  private readonly _translation: DeliverablesTranslation;
  private readonly _activityTranslation: ActivityTranslation;
  private readonly _deliverableClient: IDeliverableClient;
  private readonly _activityClient: IActivityClient;
  private confirmDeleteDialogProps: IModalProps = { isBlocking: true };

  constructor(props: IDeliverablesComponentProps) {
    super(props);

    initializeIcons();
    this._translation = new DeliverablesTranslation(
      this.props.commonProps.translation
    );
    this._activityTranslation = new ActivityTranslation(
      this.props.commonProps.translation
    );
    this._deliverableClient = this.props.commonProps.clientCreator.createDeliverableClient();
    this._activityClient = this.props.commonProps.clientCreator.createActivityClient();

    this._getLabelSelection = this._getLabelSelection.bind(this);
    this._getLabelSizeSelection = this._getLabelSizeSelection.bind(this);

    this._fileChange = this._fileChange.bind(this);
    this._renderActivityModalHeader = this._renderActivityModalHeader.bind(
      this
    );
    this._downloadDeliverables = this._downloadDeliverables.bind(this);
    this._saveDeliverable = this._saveDeliverable.bind(this);
    this._deleteDeliverable = this._deleteDeliverable.bind(this);
    this._addNewDeliverable = this._addNewDeliverable.bind(this);

    this._derivableColumns = [
      {
        key: "select",
        name: (<div></div>) as any,
        fieldName: "select",
        minWidth: 20,
        maxWidth: 20,
        isResizable: false,
        // onColumnClick: this._onDerivableColumnClick,
        onRender: (item: IDeliverable) => {
          if (this.state.selectionEnabled) {
            return (
              <FontIcon
                iconName={
                  this.state.itemsSelected.filter((x) => x.id == item.id)
                    .length > 0
                    ? "CheckboxCompositeReversed"
                    : "CheckboxFill"
                }
                className="CheckboxMP iconType"
              ></FontIcon>
            );
          }
        },
        // columnActionsMode: ColumnActionsMode.,
      },
      {
        key: "file",
        ariaLabel: "Filter or Sort " + this._translation.file,
        name: (<div>{this._translation.file}</div>) as any,
        fieldName: "file",
        minWidth: 100,
        isResizable: true,
        onColumnClick: this._onDerivableColumnClick,
        onRender: (item: IDeliverable) => {
          return (
            <div
              className={styles.deliverablesDetailsCell}
              aria-label={"View file"}
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  this.setState({
                    showPreview: true,
                    deliverableItemSelectedForPreview: item,
                  });
                }
              }}
              onClick={() => {
                this.setState({
                  showPreview: true,
                  deliverableItemSelectedForPreview: item,
                });
              }}
            >
              <FontIcon
                iconName={Utility.GetIconType(
                  item.fileName ? item.fileName : ""
                )}
                className="iconType"
              ></FontIcon>
              <div className={styles.text}>{item.fileName}</div>
            </div>
          );
        },
        // columnActionsMode: ColumnActionsMode.,
      },
      {
        key: "editedBy",
        ariaLabel: "Filter or Sort " + this._translation.editBy,
        name: (<div>{this._translation.editBy}</div>) as any,
        fieldName: "editedBy",
        minWidth: 80,
        isResizable: true,
        onColumnClick: this._onDerivableColumnClick,
        onRender: (item: IDeliverableDetails) => {
          return (
            <div className={styles.deliverablesDetailsCell}>
              <div className={styles.TaskAssignedTo}>
                <ManageResourcesComponent
                  commonProps={this.props.commonProps}
                  // allResources={this.state.allResources}
                  listResources={item.editedBy ? [item.editedBy] : []}
                  
                  onRenderModalHeader={this._renderActivityModalHeader}
                  saveFunction={() => {}}
                  maxLength={2}
                  personaSize={PersonaSize.size32}
                  userType={"internalResource"}
                  edit={false}
                  calendar={this.props.activity?.calendar || -1}
                />
              </div>
              {/* {item.editedBy} */}
            </div>
          );
        },
        // columnActionsMode: ColumnActionsMode.,
      },
      {
        key: "data",
        ariaLabel: "Filter or Sort " + this._translation.data,
        name: (<div>{this._translation.data}</div>) as any,
        fieldName: "data",
        minWidth: 80,
        isResizable: true,
        onColumnClick: this._onDerivableColumnClick,
        onRender: (item: IDeliverable) => {
          return (
            <div className={styles.deliverablesDetailsCell}>
              {item.lastUpdate ? Utility.FormatDate(item.lastUpdate) : ""}
            </div>
          );
        },
        // columnActionsMode: ColumnActionsMode.,
      },
      {
        key: "size",
        ariaLabel: "Filter or Sort " + this._translation.size,
        name: (<div>{this._translation.size}</div>) as any,
        fieldName: "size",
        minWidth: 80,
        isResizable: true,
        onColumnClick: this._onDerivableColumnClick,
        onRender: (item: IDeliverableDetails) => {
          return (
            <div className={styles.deliverablesDetailsCell}>{item.size}</div>
          );
        },
        // columnActionsMode: ColumnActionsMode.,
      },
      {
        key: "prop",
        name: (
          <FontIcon iconName={"Download"} className="iconType"></FontIcon>
        ) as any,
        fieldName: "prop",
        minWidth: 50,
        isResizable: false,
        onColumnClick: () => {
          if (
            this.props.activity?.permissions.canDownloadDeliverables &&
            this.state.deliverableItems &&
            this.state.deliverableItems.length > 0
          ) {
            this.setState({
              selectionEnabled: !this.state.selectionEnabled,
              deliverableItems: Object.assign([], this.state.deliverableItems),
            });
          }
        },
        onRender: (item: IDeliverable) => {
          return (
            <div>
              <img
                src={
                  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACACAQAAABpN6lAAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADdcAAA3XAUIom3gAAAAHdElNRQfiAgEMLBkBZB4mAAACBklEQVR42u2bsXHCQBREH04gMDkqgYAmaICm6IEKKIAGHNMBBRCQYxMQyQFD4BmwheD0v7X7Lv4zdzvS6bR7f0AEQ+YsmFJRAQcO7NjwwTlkNh1TseJIfWMcWVFFT68sI5acbi7+Ok4sGUVPsxQTtr8u/jq2TKKnWoIZ+0bLr6nZM4ue7quZPLD8iwS9egpGDR/+ny9Cj/aC5cPLr6lZRk/7VVR/7Pz3vwg9+SiuWi2/pmYVPfVXMLxz7GkyjgxLT++tuABzxq1rx8z/vwCLwOoUAkwDq1MI8NxOXvw7MCguwCfvT1R/PbGDNKL8E5Cc8gIcAqstQAYBdoHVKQTYBFanQP4ofGbdunbdD59Y/ndY3hCxJYa8KQrytjjIByMgH41dSBaOlvcDbiEdjxtjjDHGGGOMMSYaGyKdk8wS6xZxU1TcFhcPRsSjMflwVDwel78g4X6B1gL04pKU+wUCq1MI4H6BwOoGuF+guADJ8XV5C1Aa9wsEVqdA/ijsfgH/DssbIrbEkDdFQd4WB/lgBOSjsQvJwlHH48YYY4wxxhhjjJHDhkjnJLPEukXcFBW3xcWDEfFoTD4cFY/H5S9IuF+gtQC9uCTlfoHA6hQCuF8gsLoB7hcoLkByfF3eApTG/QKB1SmQPwq7X8C/w/KGiC0x5E1RkLfFQT4YAflo7EKycFQ+Hv8GZi55Q1ESbvsAAAAldEVYdGRhdGU6Y3JlYXRlADIwMTgtMDItMDFUMTI6NDQ6MjUrMDE6MDD1JibVAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDE4LTAyLTAxVDEyOjQ0OjI1KzAxOjAwhHueaQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAAASUVORK5CYII="
                }
                alt={this._translation.actionMenu}
                className={styles.iconProps}
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    this.setState({
                      derivableContextualMenuProps: this._getDerivableContextualMenuProps(
                        undefined,
                        item.id
                      ),
                    });
                  }
                }}
                onClick={(ev) => {
                  this.setState({
                    derivableContextualMenuProps: this._getDerivableContextualMenuProps(
                      ev,
                      item.id
                    ),
                  });
                }}
                // alt={item.fileType + " file icon"}
              />
            </div>
          );
        },
      },
    ];

    this.state = {
      derivableColumns: this._derivableColumns,
      deliverableItems: [],
      itemsSelected: [],
      selectionEnabled: false,
      isLoading: false,
      // deliverablesSelection: Selection,
      viewNewItem: false,
      allowedExtensions: undefined,
      showDeleteDialog: false,
      permissions: Utility.getDefaultActivityPermissions(),
      showPreview: false,
      showFileOverwrite: false,
      fileSelected: undefined,
      errorFileInsert: false,
      uploadDisabled: false,
      templates: [],
    };
  }

  componentDidMount() {
    this._deliverableClient.getListTemplates().then((templates) => {
      if (templates == undefined) {
        this.props.showMessage(
          this._translation.error,
          this._translation.getTemplatesError,
          ToastNotificationType.ERROR
        );
      }
      if (this.props.activity && this.props.activity.id) {
        this._deliverableClient
          .getDeliverables(this.props.activity.id)
          .then((deliverables) => {
            if (deliverables == undefined) {
              this.props.showMessage(
                this._translation.error,
                this._translation.getDeliverableError,
                ToastNotificationType.ERROR
              );
            } else {
              this._deliverableClient
                .getAllowedExtension()
                .then((extensions) => {
                  if (extensions == undefined) {
                    this.props.showMessage(
                      this._translation.error,
                      this._translation.getDeliverableError,
                      ToastNotificationType.ERROR
                    );
                  } else {
                    this.setState({
                      derivableColumns: this._derivableColumns,
                      deliverableItems: Object.assign([], deliverables),
                      itemsSelected: [],
                      isLoading: false,
                      allowedExtensions: extensions,
                      templates: templates,
                      permissions: this.props.activity?.permissions
                        ? this.props.activity.permissions
                        : Utility.getDefaultActivityPermissions(),
                      // deliverablesSelection: Selection,
                    });
                  }
                });
            }
          });
      }
    });
  }

  private _reloadDeliverables() {
    if (this.props.activity && this.props.activity.id) {
      this._deliverableClient
        .getDeliverables(this.props.activity.id)
        .then((deliverables) => {
          if (deliverables == undefined) {
            this.props.showMessage(
              this._translation.error,
              this._translation.getDeliverableError,
              ToastNotificationType.ERROR
            );
            this.setState({
              isLoading: false,
              permissions: Utility.getDefaultActivityPermissions(),
            });
          } else {
            this._activityClient
              .getActivityPermissions(this.props.activity?.id, false)
              .then((permissions) => {
                if (permissions) {
                  this.setState({
                    derivableColumns: this._derivableColumns,
                    deliverableItems: Object.assign([], deliverables),
                    itemsSelected: [],
                    isLoading: false,
                    permissions: permissions,
                  });
                } else {
                  this.props.showMessage(
                    this._translation.error,
                    this._translation.getDeliverableError,
                    ToastNotificationType.ERROR
                  );
                  this.setState({
                    isLoading: false,
                    permissions: Utility.getDefaultActivityPermissions(),
                  });
                }
              });
          }
        });
    }
  }

  private _renderActivityModalHeader() {
    return (
      <PanelActivityHeaderComponent
        commonProps={this.props.commonProps}
        // item={this.props.item}
        text={this._translation.editBy}
        isMacro={false}
        showContextMenu={false}
        showStatus={false}
        showType={false}
        showManage={false}
      ></PanelActivityHeaderComponent>
    );
  }

  private _fileChange(
    event: React.ChangeEvent<HTMLInputElement>
  ): void | undefined {
    if (
      event.target.files &&
      event.target.files.length > 0 &&
      !!this.props.activity?.id
    ) {
      const filesToUploadRequests = [];
      var multiple = event.target.files.length > 1;
      if (multiple) {
        for (var i = 0; i < event.target.files.length; i++) {
          const sFile = event.target.files[i];
          this.setState({ isLoading: true });
          filesToUploadRequests.push(
            this._deliverableClient.addDeliverable(
              this.props.activity?.id.toString(),
              sFile,
              multiple
            )
          );
        }
        event.target.value = "";
        if (filesToUploadRequests.length > 0) {
          Promise.all(filesToUploadRequests).then(
            (results: number[]) => {
              console.log(results);
              if (results.some((r) => r < 0)) {
                // some file upload fails
                this.handleUploadDeliverableResult(
                  undefined,
                  results.sort()[results.length - 1]
                );
              } else {
                this.handleUploadDeliverableResult(undefined, 0);
              }
              this.setState({ isLoading: false });
            },
            (err) => {
              console.error(err);
            }
          );
        }
      } else {
        this._saveDeliverable(event.target.files[0], false);
      }
    }
  }

  private _onRenderOption(option?: IDropdownOption): JSX.Element {
    if (option)
      return (
        <div className={styles.fileTypeOption}>
          <FontIcon
            iconName={DeliverableTypeIcons.get(
              Utility.GetFileType(option.text)
            )}
            className={option.text}
          />
          <span>
            {
              option.text.split(".")[0]
              // DeliverableTypeLabels.get(option.text)
            }
          </span>
        </div>
      );
    else return <div></div>;
  }

  private _onRenderTitle(options?: IDropdownOption[]): JSX.Element {
    if (options) {
      const option = options[0];

      return (
        <div className={styles.fileTypeOption}>
          <FontIcon
            iconName={DeliverableTypeIcons.get(
              Utility.GetFileType(option.text)
            )}
            className={option.text}
          />
          <span>
            {
              option.text.split(".")[0]
              // DeliverableTypeLabels.get(option.text)
            }
          </span>
        </div>
      );
    } else return <div></div>;
  }

  private _onRenderRow: IDetailsListProps["onRenderRow"] = (props) => {
    var result = null;

    if (props) {
      result = (
        <div
          className={styles.DetailsRowNoMicro}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              if (this.state.selectionEnabled) {
                var list: IDeliverable[] = Object.assign(
                  [],
                  this.state.itemsSelected
                );
                var items: IDeliverable[] = Object.assign(
                  [],
                  this.state.deliverableItems
                );
                if (list.filter((x) => x.id == props.item.id).length > 0) {
                  list = list.filter((x) => x.id != props.item.id);
                  this.setState({ itemsSelected: list, deliverableItems: items });
                } else {
                  list.push(props.item);
                  this.setState({ itemsSelected: list, deliverableItems: items });
                }
              }
            }
          }}
          onClick={() => {
            if (this.state.selectionEnabled) {
              var list: IDeliverable[] = Object.assign(
                [],
                this.state.itemsSelected
              );
              var items: IDeliverable[] = Object.assign(
                [],
                this.state.deliverableItems
              );
              if (list.filter((x) => x.id == props.item.id).length > 0) {
                list = list.filter((x) => x.id != props.item.id);
                this.setState({ itemsSelected: list, deliverableItems: items });
              } else {
                list.push(props.item);
                this.setState({ itemsSelected: list, deliverableItems: items });
              }
            }
          }}
        >
          <DetailsRow
            {...props}
            key={"Deliverables" + props.item?.id}
            className={styles.DeliverablesRow + " ms-MainRow"}
          />
        </div>
      );
    }

    return result;
  };

  private _onDerivableColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn
  ): void => {
    const { derivableColumns, deliverableItems } = this.state;
    const newColumns: IColumn[] = derivableColumns.slice();
    const currColumn: IColumn = newColumns.filter(
      (currCol) => column.key === currCol.key
    )[0];

    if (deliverableItems) {
      newColumns.forEach((newCol: IColumn) => {
        if (newCol === currColumn) {
          currColumn.isSortedDescending = !currColumn.isSortedDescending;
          currColumn.isSorted = true;
        } else {
          newCol.isSorted = false;
          newCol.isSortedDescending = true;
        }
      });
      const newItems = Utility.CopyAndSort(
        deliverableItems,
        currColumn.fieldName!,
        currColumn.isSortedDescending
      );
      this.setState({
        derivableColumns: newColumns,
        deliverableItems: newItems,
      });
    }
  };

  private _getLabelSelection(): string {
    var labelItemsSelected = this._translation.fileSelected(
      this.state.itemsSelected.length
    );
    return labelItemsSelected;
  }

  private _getLabelSizeSelection(): string {
    var labelItemsSelected = "";
    var totSize: number = 0;

    this.state.itemsSelected.forEach((element: IDeliverableDetails) => {
      if (element && element.fileSize != undefined) {
        var size: number = element.fileSize;
        totSize += size;
      }
    });

    labelItemsSelected = Utility.GetFileSize(totSize);

    return labelItemsSelected;
  }

  private async _addNewDeliverable(
    fileName: string,
    // fileType: DeliverableType,
    templateName: string
  ): Promise<boolean> {
    var result: number = -2;
    if (this.props.activity?.id) {
      result = await this._deliverableClient.createNewDeliverable(
        this.props.activity.id.toString(),
        fileName,
        // fileType,
        templateName
      );
      if (result == -1) {
        this.props.showMessage(
          this._translation.error,
          this._translation.fileAlreadyExists,
          ToastNotificationType.ERROR
        );
      } else if (result < 0) {
        this.props.showMessage(
          this._translation.error,
          this._translation.insertDeliverableError,
          ToastNotificationType.ERROR
        );
      }
      this.setState({ deliverableItems: undefined }, this._reloadDeliverables);
    }

    return result >= 0;
  }

  private async _saveDeliverable(
    content: any,
    forced: boolean
  ): Promise<boolean> {
    if (this.props.activity?.id) {
      this.setState({ isLoading: true });
      var result = await this._deliverableClient.addDeliverable(
        this.props.activity?.id.toString(),
        content,
        forced
      );
      this.handleUploadDeliverableResult(content, result);
    }
    return true;
  }

  private handleUploadDeliverableResult(content: any, result: number): void {
    if (result == -1) {
      this.props.showMessage(
        this._translation.error,
        this._translation.fileNotSupported,
        ToastNotificationType.ERROR
      );
      this.setState(
        {
          deliverableItemSelected: undefined,
          fileSelected: undefined,
          showFileOverwrite: false,
          uploadDisabled: false,
        },
        this._reloadDeliverables
      );
    } else if (result == -2) {
      this.setState({
        showFileOverwrite: true,
        fileSelected: content,
        uploadDisabled: false,
      });
    } else if (result < 0) {
      this.props.showMessage(
        this._translation.error,
        this._translation.insertDeliverableError,
        ToastNotificationType.ERROR
      );
      this.setState(
        {
          deliverableItemSelected: undefined,
          fileSelected: undefined,
          showFileOverwrite: false,
          uploadDisabled: false,
        },
        this._reloadDeliverables
      );
    } else {
      this.setState(
        {
          deliverableItemSelected: undefined,
          fileSelected: undefined,
          showFileOverwrite: false,
          uploadDisabled: false,
        },
        this._reloadDeliverables
      );
    }
  }

  private async _deleteDeliverable(itemId: number): Promise<boolean> {
    if (this.props.activity?.id) {
      this.setState({ isLoading: true });
      var result = await this._deliverableClient.deleteDeliverable(
        this.props.activity?.id.toString(),
        itemId
      );
      if (!result) {
        this.props.showMessage(
          this._translation.error,
          this._translation.deleteDeliverableError,
          ToastNotificationType.ERROR
        );
      }

      this.setState(
        {
          deliverableItems: undefined,
          showDeleteDialog: false,
          itemToDelete: undefined,
        },
        this._reloadDeliverables
      );
    }

    return true;
  }

  private _downloadDeliverables(deliverables: IDeliverable[]) {
    if (this.props.activity?.id) {
      this._deliverableClient
        .downloadAttachments(this.props.activity.id, deliverables)
        .then((file) => {
          if (file == undefined) {
            this.props.showMessage(
              this._translation.error,
              this._translation.getDeliverableError,
              ToastNotificationType.ERROR
            );
          } else {
            //var blob = new Blob([file], { type: "application/pdf" });
            var binary_string = window.atob(file);
            var len = binary_string.length;
            var bytes = new Uint8Array(len);
            for (var i = 0; i < len; i++) {
              bytes[i] = binary_string.charCodeAt(i);
            }
            var link = document.createElement("a");
            var type = "application/octet-stream";
            var blob = new Blob([bytes], { type: type });
            link.href = window.URL.createObjectURL(blob);
            link.download = "Attachments.zip";
            link.click();
          }
        });
    }
  }

  private _confirmDeleteDialogContentProps(): IDialogContentProps {
    return {
      type: DialogType.normal,
      title: this._translation.warningDeleteTitle,
      subText: this._translation.warningDeleteSubTitle,
    };
  }

  public render() {
    const {
      addDerivableContextualMenuProps,
      derivableContextualMenuProps,
    } = this.state;

    var detailsList = this.state.deliverableItems ? (
      <DetailsList
        items={this.state.deliverableItems}
        columns={this.state.derivableColumns}
        groupProps={{
          showEmptyGroups: true,
        }}
        onRenderRow={this._onRenderRow}
        selectionMode={SelectionMode.none}
        // selection={this._selection}
        // selectionPreservedOnEmptyClick={true}
      />
    ) : (
      <ShimmeredDetailsList
        setKey="items"
        items={[this.state.deliverableItems]}
        columns={this.state.derivableColumns}
        selectionMode={SelectionMode.none}
        enableShimmer={false}
        // listProps={shimmeredDetailsListProps}
      />
    );

    const inputUploadFolderAtt = {
      directory: "",
      webkitdirectory: "",
      mozdirectory: "",
    };
    return (
      <div role="deliverables" className={styles.DeliverableComponent}>
        {/* <LoaderComponent
          commonProps={this.props.commonProps}
          isLoading={this.state.isLoading}
        /> */}
        {this.state.isLoading && (
          <SpinnerComponent
            commonProps={this.props.commonProps}
            relative={true}
          ></SpinnerComponent>
        )}

        <Dialog
          hidden={!this.state.showDeleteDialog}
          onDismiss={() => this.setState({ showDeleteDialog: false })}
          modalProps={this.confirmDeleteDialogProps}
          dialogContentProps={this._confirmDeleteDialogContentProps()}
        >
          <DialogFooter>
            {!this.props.activity?.isMacro && (
              <PrimaryButton
                tabIndex={0}
                onKeyDown={(e) => {
                  if (this.state.itemToDelete)
                    this._deleteDeliverable(this.state.itemToDelete);
                }}
                onClick={() => {
                  if (this.state.itemToDelete)
                    this._deleteDeliverable(this.state.itemToDelete);
                }}
                text={this._translation.confirm}
              />
            )}
            <DefaultButton
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  this.setState({ showDeleteDialog: false });
                }
              }}
              onClick={() => {
                this.setState({ showDeleteDialog: false });
              }}
              text={this._translation.cancel}
            />
          </DialogFooter>
        </Dialog>

        <Modal
          titleAriaId="File Overwrite Confirmation"
          isOpen={this.state.showFileOverwrite}
          onDismiss={(ev) => {
            ev?.preventDefault();
            this.setState({ showFileOverwrite: false });
          }}
          isBlocking={true}
          containerClassName={
            styles.modalContainer + " " + styles.modalContainerFileOverwrite
          }
          className={styles.ModalAssignedResources}
        >
          <div role="header" className={styles.modalHeader}>
            {this.props.onRenderModalHeader()}
            <IconButton
              className={styles.ModalButton}
              iconProps={{ iconName: "Cancel" }}
              aria-label="Close"
              ariaLabel={this._translation.close}
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  this.setState({ showFileOverwrite: false });
                }
              }}
              onClick={(ev: { preventDefault: () => void }) => {
                ev?.preventDefault();
                this.setState({ showFileOverwrite: false });
              }}
            />
          </div>
          <hr />
          <div
            className={styles.modalBody + " " + styles.modalBodyFileOverwrite}
          >
            <label className={styles.Label + " ms-Grid-col ms-sm12"}>
              {this._translation.fileAlreadyExistsWarning}
            </label>
          </div>
          <div
            role="commandbar"
            className={
              styles.modalFooter + " " + styles.modalFooterFileOverwrite
            }
          >
            <div className={styles.ModalFooterRow + " row"}>
              <div>
                <DefaultButton
                  text={this._translation.cancel}
                  className={"btn btn-dismiss"}
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                    this.setState({
                      showFileOverwrite: false,
                      isLoading: false,
                    });
                    }
                  }}
                  onClick={(ev: { preventDefault: () => void }) => {
                    ev?.preventDefault();
                    this.setState({
                      showFileOverwrite: false,
                      isLoading: false,
                    });
                  }}
                />
              </div>
              <div className={styles.divBtnNewFile}>
                <PrimaryButton
                  text={this._translation.proceed}
                  className={"btn btn-primary"}
                  disabled={this.state.uploadDisabled}
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      this.setState({
                        isLoading: true,
                        showFileOverwrite: false,
                        uploadDisabled: true,
                      });
                      this._saveDeliverable(this.state.fileSelected, true);
                    }
                  }}
                  onClick={(ev: { preventDefault: () => void }) => {
                    this.setState({
                      isLoading: true,
                      showFileOverwrite: false,
                      uploadDisabled: true,
                    });
                    this._saveDeliverable(this.state.fileSelected, true);
                  }}
                />
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          titleAriaId="Show Preview"
          isOpen={this.state.showPreview}
          onDismiss={(ev) => {
            ev?.preventDefault();
            this.setState({ showPreview: false });
          }}
          isBlocking={false}
          containerClassName={
            styles.modalContainer + " " + styles.modalContainerPreview
          }
          className={styles.ModalAssignedResources}
        >
          <div role="header" className={styles.modalHeader}>
            {this.props.onRenderModalHeader()}
            <IconButton
              className={styles.ModalButton}
              iconProps={{ iconName: "Cancel" }}
              ariaLabel={this._translation.close}
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                this.setState({ showPreview: false });
                }
              }}
              onClick={(ev: { preventDefault: () => void }) => {
                ev?.preventDefault();
                this.setState({ showPreview: false });
              }}
            />
          </div>
          <hr />
          <div className={styles.modalBody + " " + styles.modalBodyPreview}>
            {!this.state.deliverableItemSelectedForPreview ? (
              // <ResponsiveEmbed aspectRatio="16by9">
              //   <embed
              //     src={
              //       "https://m365x178943.sharepoint.com/sites/MediaPlanningNew/_layouts/15/Doc.aspx?sourcedoc=%7BA7BFB076-DCA1-43C8-822D-CF59ED628014%7D&file=Test.docx&action=interactivepreview&mobileredirect=true"
              //     }
              //   />
              // </ResponsiveEmbed>
              <img
                src={
                  "https://m365x178943.sharepoint.com/sites/MediaPlanningNew/SiteAssets/No_image_available.png"
                }
                // alt={this._translation.noImage}
                alt=""
                className={styles.imgPreview}
              ></img>
            ) : this.state.deliverableItemSelectedForPreview?.fileType ==
                DeliverableType.JPG ||
              this.state.deliverableItemSelectedForPreview?.fileType ==
                DeliverableType.PNG ||
              this.state.deliverableItemSelectedForPreview?.fileType ==
                DeliverableType.GIF ? (
              <img
                alt={this._translation.preview}
                className={styles.imgPreview}
                src={this.state.deliverableItemSelectedForPreview.embedUrl}
                alt=""
              ></img>
            ) : this.state.deliverableItemSelectedForPreview?.fileType ==
                DeliverableType.TEXT ||
              this.state.deliverableItemSelectedForPreview?.fileType ==
                DeliverableType.ONENOTE ? (
              <img
                alt={this._translation.preview}
                src={
                  // "https://m365x178943.sharepoint.com/sites/MediaPlanningNew/SiteAssets/No_image_available.png"
                  this.state.deliverableItemSelectedForPreview.embedUrl
                }
                className={styles.imgPreview}
              ></img>
            ) : (
              <ResponsiveEmbed
                aspectRatio="16by9"
                className={styles.embedPreview}
              >
                <embed
                  src={this.state.deliverableItemSelectedForPreview?.embedUrl}
                />
              </ResponsiveEmbed>
              // <img src={"https://m365x178943.sharepoint.com/sites/MediaPlanningNew/SiteAssets/No_image_available.png"} width="100%"></img>
            )}
          </div>
          {/*<hr />
           <div className={styles.modalFooter}>
            <div className={styles.ModalFooterRow + " row"}>
              <div>
                <DefaultButton
                  text={this._translation.cancel}
                  className={"btn btn-dismiss"}
                  onClick={(ev: { preventDefault: () => void }) => {
                    ev?.preventDefault();
                    this.setState({ showPreview: false });
                  }}
                />
              </div>
            </div>
          </div> */}
        </Modal>

        <iframe
          id="deliverable"
          style={{ display: "none" }}
          title={this._translation.deliverable}
        />
        <input
          id="fileBrief"
          type="file"
          title="Choose a file"
          multiple
          onChange={this._fileChange}
          hidden={true}
          accept={
            this.state.allowedExtensions
              ? this.state.allowedExtensions.join(", ")
              : ""
          }
        />
        <input
          id="folderBrief"
          type="file"
          title="Choose a folder"
          onChange={this._fileChange}
          hidden={true}
          {...inputUploadFolderAtt}
          accept={
            this.state.allowedExtensions
              ? this.state.allowedExtensions.join(", ")
              : ""
          }
        />
        <div id="printDiv"></div>

        {!this.state.isLoading && [
          <div
            role="header"
            className={styles.headerDeliverables}
            key="headerDeliverables"
          >
            <span>{this._translation.deliverables}</span>
            <div
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  this.setState({
                    addDerivableContextualMenuProps: this._getAddDerivableContextualMenuProps(
                      undefined
                    ),
                  });
                }
              }}
              onClick={(ev) => {
                this.setState({
                  addDerivableContextualMenuProps: this._getAddDerivableContextualMenuProps(
                    ev
                  ),
                });
              }}
            >
              +
            </div>

            {addDerivableContextualMenuProps && (
              <ContextualMenu {...addDerivableContextualMenuProps} />
            )}
          </div>,
          <div
            role="deliverables list"
            className={styles.divDeliverablesList + " ms-Grid-col ms-sm12"}
            key="divDeliverablesList"
          >
            {/* <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}> */}
            {detailsList}
            {derivableContextualMenuProps && (
              <ContextualMenu {...derivableContextualMenuProps} />
            )}
            {/* </ScrollablePane> */}
          </div>,
          this.state.selectionEnabled && (
            <div
              className={styles.deliverablesSelection + " row "}
              key="deliverablesSelection"
            >
              <div
                className={styles.SelectAll + " ms-Grid-col ms-lg3 ms-md12"}
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    if (this.state.selectionEnabled) {
                      var list: IDeliverable[] = Object.assign(
                        [],
                        this.state.itemsSelected
                      );
                      var items: IDeliverable[] = Object.assign(
                        [],
                        this.state.deliverableItems
                      );
                      if (
                        this.state.itemsSelected.length ==
                        this.state.deliverableItems?.length
                      ) {
                        this.setState({
                          itemsSelected: [],
                          deliverableItems: items,
                        });
                      } else {
                        this.setState({
                          itemsSelected: items,
                          deliverableItems: items,
                        });
                      }
                    }
                  }
                }}
                onClick={() => {
                  if (this.state.selectionEnabled) {
                    var list: IDeliverable[] = Object.assign(
                      [],
                      this.state.itemsSelected
                    );
                    var items: IDeliverable[] = Object.assign(
                      [],
                      this.state.deliverableItems
                    );
                    if (
                      this.state.itemsSelected.length ==
                      this.state.deliverableItems?.length
                    ) {
                      this.setState({
                        itemsSelected: [],
                        deliverableItems: items,
                      });
                    } else {
                      this.setState({
                        itemsSelected: items,
                        deliverableItems: items,
                      });
                    }
                  }
                }}
              >
                <FontIcon
                  iconName={
                    this.state.itemsSelected.length ==
                    this.state.deliverableItems?.length
                      ? "CheckboxCompositeReversed"
                      : "CheckboxFill"
                  }
                  className={styles.iconType + " CheckboxMP iconType"}
                ></FontIcon>
                <div className={styles.text}>
                  {this._translation.selectAll(true)}
                </div>
              </div>
              <div
                className={
                  styles.deliverablesSelectionLabels + " ms-Grid-col ms-md6"
                }
              >
                <span className={styles.deliverablesSelected}>
                  {this._getLabelSelection()}
                </span>
                <span className={styles.deliverablesSelectionSize}>
                  {this._getLabelSizeSelection()}
                </span>
              </div>
              <div className="ms-Grid-col ms-lg3 ms-md12">
                <DefaultButton
                  className={"action_button"}
                  text={this._translation.download}
                  allowDisabledFocus
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      this.props.showMessage(
                        this._translation.info,
                        this._activityTranslation.downloadAttachments,
                        ToastNotificationType.INFO
                      );
                      this._downloadDeliverables(this.state.itemsSelected);
                    }
                  }}
                  onClick={(event) => {
                    this.props.showMessage(
                      this._translation.info,
                      this._activityTranslation.downloadAttachments,
                      ToastNotificationType.INFO
                    );
                    this._downloadDeliverables(this.state.itemsSelected);
                  }}
                  disabled={this.state.itemsSelected.length == 0}
                  checked={true}
                  // hidden={this.state.validationOutOfPlatform}
                />
              </div>
            </div>
          ),
        ]}

        <Modal
          titleAriaId="New Item Section"
          isOpen={this.state.viewNewItem}
          onDismiss={(ev) => {
            ev?.preventDefault();
            this.setState({ viewNewItem: false });
          }}
          isBlocking={false}
          containerClassName={styles.modalContainer}
          className={styles.ModalAssignedResources}
        >
          <div role="header" className={styles.modalHeader}>
            {this.props.onRenderModalHeader()}
            <IconButton
              className={styles.ModalButton}
              iconProps={{ iconName: "Cancel" }}
              ariaLabel={this._translation.close}
              aria-label="Close"
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  this.setState({ viewNewItem: false });
                }
              }}
              onClick={(ev: { preventDefault: () => void }) => {
                ev?.preventDefault();
                this.setState({ viewNewItem: false });
              }}
            />
          </div>
          <hr />
          <div className={styles.modalBody}>
            <div className={styles.addText}>
              <label className={styles.Label + " ms-Grid-col ms-sm12"}>
                {this._translation.fileName}
              </label>
              <TextField
                onChange={(event, newValue) => {
                  this.setState({ newItemFilename: newValue });
                }}
                className="ms-Grid-col ms-sm12"
              />
              {this.state.errorFileInsert && (
                <label
                  className={
                    styles.Label +
                    " ms-Grid-col ms-sm12 " +
                    styles.errorFileInsert
                  }
                >
                  {this._translation.fileNameAlreadyExists}
                </label>
              )}
              <label className={styles.Label + " ms-Grid-col ms-sm12"}>
                {this._translation.fileType}
              </label>
              <Dropdown
                placeholder={this._translation.selectOption}
                // label=""
                ariaLabel="File type"
                aria-label="File type"
                className="ms-Grid-col ms-sm12"
                onRenderTitle={this._onRenderTitle}
                onRenderOption={this._onRenderOption}
                options={
                  this.state.templates
                    ? this.state.templates.map((val: ITemplates) => {
                        return {
                          key: val.fileName,
                          text: val.fileName,
                        };
                      })
                    : []
                }
                onChange={(ev, option) => {
                  this.setState({
                    newTemplateName: option?.key as string,
                  });
                }}
              />
            </div>
          </div>
          <hr />
          <div role="commandBar" className={styles.modalFooter}>
            <div className={styles.ModalFooterRow + " row"}>
              <div>
                <DefaultButton
                  text={this._translation.cancel}
                  className={"btn btn-dismiss"}
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                    this.setState({ viewNewItem: false });
                    }
                  }}
                  onClick={(ev: { preventDefault: () => void }) => {
                    ev?.preventDefault();
                    this.setState({ viewNewItem: false });
                  }}
                />
              </div>
              <div className={styles.divBtnNewFile}>
                <PrimaryButton
                  text={this._translation.createFile}
                  className={"btn btn-primary"}
                  disabled={
                    !this.state.newItemFilename ||
                    !this.state.newTemplateName ||
                    this.state.uploadDisabled
                  }
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      if (
                        this.state.newItemFilename &&
                        this.state.newTemplateName
                      ) {
                        this.setState({
                          // viewNewItem: false,
                          uploadDisabled: true,
                          isLoading: true,
                        });
                        this._addNewDeliverable(
                          this.state.newItemFilename,
                          // this.state.newItemType,
                          this.state.newTemplateName
                        ).then((result) => {
                          if (result) {
                            this.setState({
                              viewNewItem: false,
                              isLoading: false,
                              uploadDisabled: false,
                            });
                          } else {
                            this.setState({
                              viewNewItem: true,
                              isLoading: false,
                              errorFileInsert: true,
                              uploadDisabled: false,
                            });
                          }
                        });
                      }
                    }
                  }}
                  onClick={(ev: { preventDefault: () => void }) => {
                    ev?.preventDefault();
                    if (
                      this.state.newItemFilename &&
                      this.state.newTemplateName
                    ) {
                      this.setState({
                        // viewNewItem: false,
                        uploadDisabled: true,
                        isLoading: true,
                      });
                      this._addNewDeliverable(
                        this.state.newItemFilename,
                        // this.state.newItemType,
                        this.state.newTemplateName
                      ).then((result) => {
                        if (result) {
                          this.setState({
                            viewNewItem: false,
                            isLoading: false,
                            uploadDisabled: false,
                          });
                        } else {
                          this.setState({
                            viewNewItem: true,
                            isLoading: false,
                            errorFileInsert: true,
                            uploadDisabled: false,
                          });
                        }
                      });
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </Modal>

        {this.state.deliverableItemSelected && (
          <VersionHistoryComponent
            hideTitle={false}
            commonProps={this.props.commonProps}
            // item={this.props.item}
            activityId={this.props.activity?.id}
            deliverableId={this.state.deliverableItemSelected.id}
            fileName={
              this.state.deliverableItemSelected.fileName
                ? this.state.deliverableItemSelected.fileName
                : "Deliverable"
            }
            canUpdateVersion={
              this.state.permissions.canDeleteDeliverables ? true : false
            }
            onRenderModalHeader={this.props.onRenderModalHeader}
            hideHistory={() => {
              this.setState({ deliverableItemSelected: undefined });
            }}
            calendar={-1}
          ></VersionHistoryComponent>
        )}
      </div>
    );
  }

  private _onAddDerivableContextualMenuDismissed = (ev: any, dp: any): void => {
    this.setState({
      addDerivableContextualMenuProps: undefined,
    });
  };

  private _getAddDerivableContextualMenuProps(
    ev?: React.MouseEvent<HTMLElement>
  ): IContextualMenuProps {
    var items: IContextualMenuItem[] = [
      {
        key: "CreateNew",
        name: this._translation.createFile,
        // iconProps: { iconName: "SortUp" },
        disabled: !this.state.permissions.canUploadDeliverables,
        canCheck: false,
        // checked: column.isSorted && !column.isSortedDescending,
        onClick: () =>
          this.setState({ viewNewItem: true, errorFileInsert: false }),
      },
      {
        key: "UploadFile",
        name: this._translation.uploadFile,
        // iconProps: { iconName: "SortUp" },
        disabled: !this.state.permissions.canUploadDeliverables,
        canCheck: false,
        // checked: column.isSorted && !column.isSortedDescending,
        onClick: () => {
          $("#fileBrief").trigger("click");
        },
      },
      {
        key: "UploadFolder",
        name: this._translation.uploadFolder,
        // iconProps: { iconName: "SortUp" },
        disabled: !this.state.permissions.canUploadDeliverables,
        canCheck: false,
        // checked: column.isSorted && !column.isSortedDescending,
        onClick: () => {
          $("#folderBrief").trigger("click");
        },
      },
      // {
      //   key: "Preview",
      //   name: this._translation.cancel,
      //   // iconProps: { iconName: "SortUp" },
      //   disabled: !this.state.permissions.canUploadDeliverables,
      //   canCheck: false,
      //   // checked: column.isSorted && !column.isSortedDescending,
      //   onClick: () => {
      //     this.setState({ showPreview: true });
      //   },
      // },
    ];

    return {
      items: items,
      target: ev?.currentTarget as HTMLElement,
      directionalHint: DirectionalHint.bottomLeftEdge,
      gapSpace: 10,
      isBeakVisible: false,
      onDismiss: this._onAddDerivableContextualMenuDismissed,
    };
  }

  private _onDerivableContextualMenuDismissed = (ev: any, dp: any): void => {
    this.setState({
      derivableContextualMenuProps: undefined,
    });
  };

  private _getDerivableContextualMenuProps(
    ev?: React.MouseEvent<HTMLElement>,
    itemId?: number
  ): IContextualMenuProps {
    var items: IContextualMenuItem[] = [
      {
        key: "Download",
        name: this._translation.download,
        // iconProps: { iconName: "SortUp" },
        canCheck: false,
        disabled: !this.state.permissions.canDownloadDeliverables,
        // checked: column.isSorted && !column.isSortedDescending,
        onClick: () => {
          if (this.state.deliverableItems) {
            var item = this.state.deliverableItems.filter(
              (x) => x.id == itemId
            )[0];
            if (item.entityId && item.id) {
              this.props.showMessage(
                this._translation.info,
                this._activityTranslation.downloadAttachment,
                ToastNotificationType.INFO
              );
              this._deliverableClient
                .downloadAttachment(item.entityId, item.id)
                .then((file) => {
                  if (file == undefined) {
                    this.props.showMessage(
                      this._translation.error,
                      this._translation.getDeliverableError,
                      ToastNotificationType.ERROR
                    );
                  } else {
                    //var blob = new Blob([file], { type: "application/pdf" });
                    var binary_string = window.atob(file);
                    var len = binary_string.length;
                    var bytes = new Uint8Array(len);
                    for (var i = 0; i < len; i++) {
                      bytes[i] = binary_string.charCodeAt(i);
                    }
                    var link = document.createElement("a");
                    var type = "";
                    switch (item.fileType) {
                      case DeliverableType.EXCEL: {
                        type = "application/excel";
                        break;
                      }
                      case DeliverableType.GIF: {
                        type = "image/gif";
                        break;
                      }
                      case DeliverableType.JPG: {
                        type = "image/jpeg";
                        break;
                      }
                      case DeliverableType.ONENOTE: {
                        type = "application/octet-stream";
                        break;
                      }
                      case DeliverableType.PDF: {
                        type = "application/pdf";
                        break;
                      }
                      case DeliverableType.PNG: {
                        type = "image/png";
                        break;
                      }
                      case DeliverableType.POWERPOINT: {
                        type = "application/mspowerpoint";
                        break;
                      }
                      case DeliverableType.TEXT: {
                        type = "text/plain";
                        break;
                      }
                      case DeliverableType.WORD: {
                        type = "application/msword";
                        break;
                      }
                      default: {
                        type = "application/octet-stream";
                        break;
                      }
                    }
                    var blob = new Blob([bytes], { type: type });
                    link.href = window.URL.createObjectURL(blob);
                    link.download = item.fileName ? item.fileName : "doc";
                    link.click();
                  }
                });
            }
          }
        },
      },
      {
        key: "OpenInBrowser",
        name: this._translation.openInBrowser,
        // iconProps: { iconName: "SortUp" },
        canCheck: false,
        disabled: !this.state.permissions.canDownloadDeliverables,
        // checked: column.isSorted && !column.isSortedDescending,
        onClick: () => {
          if (this.state.deliverableItems) {
            var item = this.state.deliverableItems.filter(
              (x) => x.id == itemId
            )[0];
            const link = document.createElement("a");
            link.href = item.filePath + "?web=1";
            link.setAttribute("target", "_blank");
            document.body.appendChild(link);
            link.click();
          }
        },
      },
      {
        key: "History",
        name: this._translation.getVersionHistory,
        // iconProps: { iconName: "SortUp" },
        canCheck: false,
        disabled: !this.state.permissions.canDeleteDeliverables,
        // checked: column.isSorted && !column.isSortedDescending,
        onClick: () => {
          if (this.state.deliverableItems) {
            var item = this.state.deliverableItems.filter(
              (x) => x.id == itemId
            )[0];
            if (item.entityId && item.id) {
              this.setState({ deliverableItemSelected: item });
            }
          }
        },
      },
      {
        key: "DownloadAttachments",
        name: this._translation.downloadAllAttachments,
        // iconProps: { iconName: "SortUp" },
        canCheck: false,
        disabled: !this.state.permissions.canDownloadDeliverables,
        // checked: column.isSorted && !column.isSortedDescending,
        onClick: () => {
          if (this.state.deliverableItems) {
            this.props.showMessage(
              this._translation.info,
              this._activityTranslation.downloadAttachments,
              ToastNotificationType.INFO
            );
            this._downloadDeliverables(this.state.deliverableItems);
          }
        },
      },
      // {
      //   key: "Print",
      //   name: this._translation.print,
      //   disabled: true, //!this.props.activity?.permissions.canDownloadDeliverables,,
      //   // iconProps: { iconName: "SortUp" },
      //   canCheck: false,
      //   // checked: column.isSorted && !column.isSortedDescending,
      //   onClick: () => {
      //     if (this.state.deliverableItems) {
      //       var item = this.state.deliverableItems.filter(
      //         (x) => x.id == itemId
      //       )[0];
      //       if (item.entityId && item.id) {
      //         this._deliverableClient
      //           .downloadAttachment(item.entityId, item.id)
      //           .then((file) => {
      //             if (file == undefined) {
      //               this.props.showMessage(
      //                 this._translation.error,
      //                 this._translation.getDeliverableError,
      //                 ToastNotificationType.ERROR
      //               );
      //             } else {
      //               //var blob = new Blob([file], { type: "application/pdf" });
      //               var binary_string = window.atob(file);
      //               var len = binary_string.length;
      //               var bytes = new Uint8Array(len);
      //               var type = "";
      //               switch (item.fileType) {
      //                 case DeliverableType.EXCEL:
      //                   type = "application/excel";
      //                 case DeliverableType.GIF:
      //                   type = "image/gif";
      //                 case DeliverableType.JPG:
      //                   type = "image/jpeg";
      //                 case DeliverableType.ONENOTE:
      //                   type = "application/octet-stream";
      //                 case DeliverableType.PDF:
      //                   type = "application/pdf";
      //                 case DeliverableType.PNG:
      //                   type = "image/png";
      //                 case DeliverableType.POWERPOINT:
      //                   type = "application/mspowerpoint";
      //                 case DeliverableType.TEXT:
      //                   type = "text/plain";
      //                 case DeliverableType.WORD:
      //                   type = "application/msword";
      //                 default:
      //                   type = "application/octet-stream";
      //               }

      //               var w = window.open("data:application/pdf;base64," + file);
      //               w?.print();
      //             }
      //             // var element = document.createElement("a");
      //             // element.setAttribute(
      //             //   "href",
      //             //   "data:application/pdf;base64," +
      //             //     encodeURIComponent(file)
      //             // );
      //             // element.setAttribute("download", item.fileName ? item.fileName : "doc");
      //             // element.style.display = "none";
      //             // document.body.appendChild(element);
      //             // element.click();
      //             // document.body.removeChild(element);

      //             // and call print function like this
      //             //window.print();
      //           });
      //       }
      //     }

      //     // var item = this.state.deliverableItems.filter(
      //     //   (x) => x.id == itemId
      //     // )[0];
      //     // var iframe = document.createElement("iframe");
      //     // iframe.src = item.filePath ? item.filePath : "";
      //     // iframe.style.display = "none";
      //     // var iFrameLoaded = function() {
      //     //   iframe.contentWindow?.print();
      //     //   iframe.parentNode?.removeChild(iframe);
      //     // };
      //     // if (iframe.addEventListener)
      //     //   iframe.addEventListener("load", iFrameLoaded, false);
      //     // // for most other browsers
      //     // else iframe.onload = iFrameLoaded; // just in case there's a browser not covered by the first two

      //     // document.body.appendChild(iframe);
      //   },
      // },
      {
        key: "Delete",
        name: this._translation.delete,
        // iconProps: { iconName: "SortUp" },
        canCheck: false,
        disabled: !this.props.activity?.permissions.canDeleteDeliverables,
        // checked: column.isSorted && !column.isSortedDescending,
        onClick: () => {
          // this.setState({ isLoading: true });
          // this._deleteDeliverable(itemId);
          this.setState({ showDeleteDialog: true, itemToDelete: itemId });
        },
      },
    ];

    return {
      items: items,
      target: ev?.currentTarget as HTMLElement,
      directionalHint: DirectionalHint.bottomLeftEdge,
      gapSpace: 10,
      isBeakVisible: false,
      onDismiss: this._onDerivableContextualMenuDismissed,
    };
  }
}
