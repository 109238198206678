import { TFunction } from "i18next";
import { ToastComponent } from "../Components/Shared/ToastComponent/ToastComponent";
import { ClientCreator } from "../Clients/ClientCreator";
import { INotification } from "./Notification";
import { Observable, Subject } from "rxjs";
export interface IBaseProperties {
  commonProps: ICommonProperties;
}

export interface ICommonProperties {
  translation: TFunction;
  toastComponent?: ToastComponent | null;
  clientCreator: ClientCreator;
  onNotification?: Subject<INotification>;
  onRoute?: Subject<IRoute>;
  additionalInfo?: AdditionalRoutingInfo;
}

export interface IRoute {
  page: Page;
  additionalInfo: AdditionalRoutingInfo;
}

export type KeyName = "id" | "type" | "subtype" | "title" | "showMyActivities";

export interface AdditionalRoutingInfo {
  keysValues: Record<KeyName, string | undefined>;
  optionalValues?: Record<string, any | undefined>;
}

export enum Page {
  None,
  Dashboard,
  MyBrief,
  Activities,
  Planner,
  Report,
  FAQ,
  Contacts,
  WhoWeAre,
  Legal,
  Privacy,
  Cookie,
  Support
} 
