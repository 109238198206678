import * as React from "react";
import styles from "./Activities.module.scss";
import { FontIcon, Image, ImageFit } from "@fluentui/react";
import { Security } from "../../../Utility/Security";

import { ActivityTranslation } from "../../../Translations/Activity.Translation";
import { ListActivities } from "./ListActivities/ListActivities";
import { ToastComponent } from "../../Shared/ToastComponent/ToastComponent";
import { IBaseProperties } from "../../../Models/IBaseProperties";
import { Box, Tab, Tabs, Typography } from "@material-ui/core";
import { number } from "prop-types";
import { node } from "prop-types";
import { ListAttachments } from "./ListAttachments/ListAttachments";
import { Planner, PlannerView } from "../Planner/Planner";
import { apiConfig } from "../../../msal/MsalConfig";

export interface IActivitiesProps extends IBaseProperties {}
export interface IActivitiesState {
  value: number;
}

function TabPanel(props: {
  [x: string]: any;
  children: any;
  value: any;
  index: any;
}) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component="div">{children}</Typography>          
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: node,
  index: number,
  value: number,
};

export class Activities extends React.Component<
  IActivitiesProps,
  IActivitiesState
> {
  private readonly _translation: ActivityTranslation;

  constructor(props: IActivitiesProps) {
    super(props);
    this.state = { value: 0 };

    this._translation = new ActivityTranslation(
      this.props.commonProps.translation
    );

    this._handleChange = this._handleChange.bind(this);
  }

  private _a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  private _handleChange(event: any, newValue: any) {
    this.setState({ value: newValue });
  }

  private isGMUser() {
    return (      
      (Security.isGlobalMediaAdvanced || Security.isGlobalMediaSpecialist || Security.isAuditor || Security.isAdmin)
    );
  }

  render() {
    const isGMUser = this.isGMUser();
    const tabListActivities = 0;
    const tabTimeline = 1;
    const tabAttachment =  isGMUser ? 2 : 1;
    const tabMessages = isGMUser ? 3 : 2;
    return (
      <div>
        <Tabs
          value={this.state.value}
          onChange={this._handleChange}
          aria-label="Activities tab"
          className={styles.DivActivities}
        >
          <Tab
            label={
              <div className={"divMui"}>
                <FontIcon iconName={"BulletedList"} />
                <div>{this._translation.list}</div>
              </div>
            }
            {...this._a11yProps(tabListActivities)}
          />
          {isGMUser && 
            <Tab
              label={
                <div className={"divMui"}>
                  <FontIcon iconName={"Timeline"} />
                  <div>{this._translation.timeline}</div>
                </div>
              }
              {...this._a11yProps(tabTimeline)}
            />
          }
          <Tab
            label={
              <div className={"divMui"}>
                <FontIcon iconName={"Attach"} />
                <div>{this._translation.attachments}</div>
              </div>
            }
            {...this._a11yProps(tabAttachment)}
          />
          {/* <Tab
            label={
              <div className={"divMui"}>
                <FontIcon iconName={"Mail"} />
                <div>{this._translation.messages}</div>
              </div>
            }
            {...this._a11yProps(tabMessages)}
          /> */}
        </Tabs>

        <TabPanel value={this.state.value} index={tabListActivities}>
          <ListActivities commonProps={this.props.commonProps}></ListActivities>
        </TabPanel>
        { isGMUser  &&
          <TabPanel value={this.state.value} index={tabTimeline}>
          <Planner commonProps={this.props.commonProps} views={PlannerView.Timeline} redirectUri={apiConfig.redirectUri} />
        </TabPanel>
        }
        <TabPanel value={this.state.value} index={tabAttachment}>          
          <ListAttachments
            commonProps={this.props.commonProps}
            briefAttachments={false}
          ></ListAttachments>          
        </TabPanel>
        {/* <TabPanel value={this.state.value} index={tabMessages}>
          Messages
        </TabPanel> */}
      </div>
    );
  }
}
