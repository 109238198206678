import { bool } from 'prop-types';
import { ActivityStatus } from './ActivityStatus';
import { IDeliverable, IDeliverableDetails } from "./Deliverable";
// import { IMacro, IMicro, ActivityStatusEnum } from './Activity';
import { IColumn } from "@fluentui/react";
import { ITask } from "./Task";
import { User } from "./User";
import { INote } from "./Note";
import { ActivityHistory } from "./ActivityHistory";
import { IBriefLookup } from './Brief';

export interface IListActivitiesFilter {
  maxLength?: number;
  idActivity?: number;
  activity: string;
  showOnlyMyActivities: boolean;
  type: number;
  calendar?: number;
  categories?: number[];
  responsibilities?: number[];
  sensibilities?: number[];
  status?: string;
  lastModifyStartDate?: Date;
  lastModifyEndDate?: Date;
  activityDateStartDate?: Date;
  activityDateEndDate?: Date;
  dueToStartDate?: Date;
  dueToEndDate?: Date;
  startIndex: number;
  orderBy?: IColumn[];
}

export interface ActivityPermissions {
  canAddNote: boolean;
  canAddTask: boolean;
  canDelete: boolean;
  canDeleteDeliverables: boolean;
  canDownloadDeliverables: boolean;
  canUpdate: boolean;
  canUpdateResources: boolean;
  canUpdateStatus: boolean;
  canUploadDeliverables: boolean;
  deletableNotes: number[];
  deletableTasks: number[];
  updatableNotes: number[];
  updatableTasks: number[];
}

export interface IOrderBy {
  field: string;
  type: string;
}

export enum ActivityTypeFilter {
  all,
  macro,
  micro,
}

export interface IActivity {
  id?: number;
  name: string;
  calendar: number;
  description?: string | null;
  dateFrom: Date;
  dateTo: Date;
  // notes?: INote[];
  headOfActivity?: User;
  headOfActivityUPN?: string;
  spoc?: User;
  spocUPN?: string;
  permissions: ActivityPermissions;
  isMacro: boolean;
  createdBy?: User;
  createdOn?: Date;
  editedBy?: User;
  lastUpdate?: Date;
}

export interface IMacro extends IActivity {
  // briefId?: number;
  linkedFileBrief?: IDeliverableDetails;
  linkedItemBrief?: IBriefLookup;
  file?: File;
  mediaPlan?: IMediaPlan;
  microActivities?: IMicro[];
}

export interface IMediaPlan extends IActivity {
  resources?: User[];
  resourcesUPNs?: string[];
  externalResources?: User[];
  externalResourcesUPNs?: string[];
  lastAction?: ActivityHistory;
  actions?: ActivityHistory[];
}

export interface IMicro extends IActivity {
  parentMacroId?: number;
  parentMacro?: IMacro;
  status?: string;

  // deliverables?: IDeliverable[];
  // tasks?: ITask[];
  lastAction?: ActivityHistory;
  actions?: ActivityHistory[];
  resources?: User[];
  resourcesUPNs?: string[];
  categories: number[];
  responsibilities: number[];
  sensibility?: number;
  alarm?: number;
  alarmResources?: User[];
  alarmResourcesUPNs?: string[];
  externalResources?: User[];
  externalResourcesUPNs?: string[];
  availableStatus?: IAvailableStatus[];
  deliverables?: File[];
}

export interface IAvailableStatus {
  status: ActivityStatusEnum,
  available: boolean
}

export interface IActivityMinAttribute {
  id: number,
  isMacro: boolean
}

export interface IActivityAllAttributes
  extends IMacro,
  IMicro,
  IActivity,
  IMediaPlan {
  expanded: boolean;
}

export interface IListActivities {
  activities: IActivityAllAttributes[];
  count: number;
}

export enum ActivityStatusEnum {
  Planned = 0,
  // Draft = 1,
  "In progress" = 2,
  // Rework = 3,
  // Approved = 4,
  // "Ready for Distribution" = 5,
  // Distributed = 6,
  Closed = 7,
}

export interface IActivitiesCount {
  state: ActivityStatusEnum;
  count: number;
}
