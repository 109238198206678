import * as React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "@fluentui/react/dist/css/fabric.min.css";
import { initializeIcons } from "@uifabric/icons";
import { Utility } from "../../../Utility/Utility";
import { ActivityTranslation } from "../../../Translations/Activity.Translation";

import styles from "./ActivitySidebarComponent.module.scss";

import {
  Dropdown,
  DropdownMenuItemType,
  FontIcon,
  IColumn,
  IDropdownOption,
  ITag,
  PersonaSize,
} from "@fluentui/react";

import { ManageResourcesComponent } from "../ManageResourcesComponent/ManageResourcesComponent";
import { PanelActivityHeaderComponent } from "../PanelActivityHeaderComponent/PanelActivityHeaderComponent";
import {
  IActivityAllAttributes,
  IMacro,
  IMicro,
} from "../../../Models/Activity";

import { User } from "../../../Models/User";
import { IBaseProperties, IRoute, Page } from "../../../Models/IBaseProperties";
import { RangeCalendar } from "../RangeCalendar/RangeCalendar";
import { IDateRangeValue } from "../RangeCalendar/IDateRangeValue";
import { Spoc } from "../../Body/Activities/Spoc";
import { Constants } from "../../../Utility/Constants";
import { HeadOfActivity } from "../../Body/Activities/HeadOfActivity";
import { SpinnerComponent } from "../SpinnerComponent/SpinnerComponent";
import { ICalendar } from "../../../Models/ICalendar";

export interface IActivitySidebarComponentProps extends IBaseProperties {
  item?: IActivityAllAttributes;
  calendars: ICalendar[];
  editable: boolean;
  viewDetails: boolean;
  viewDescription: any;
  viewLinkedMicro: any;
  viewParent: any;
  updateAssignedResources?: (
    listResources: User[],
    update: boolean
  ) => Promise<User[] | undefined>;
  updateExternalResources?: (
    listResources: User[],
    update: boolean
  ) => Promise<User[] | undefined>;
  updateAlarmResources?: (
    listResources: User[],
    update: boolean
  ) => Promise<User[] | undefined>;
  updateDueToActivity?: (
    newValue: IDateRangeValue | undefined
  ) => Promise<boolean>;
  updateTimingActivity?: (
    newValue: IDateRangeValue | undefined
  ) => Promise<boolean>;
  updateSpoc?: (selected: ITag[]) => Promise<ITag | undefined>;
  updateAlarm?: (alarm: number) => Promise<number>;
  updateHeadOfActivity?: (selected: ITag[]) => Promise<ITag | undefined>;
  updateParent?: () => void;
}

export interface IActivitySidebarComponentState {
  // item?: IActivityAllAttributes;

  isMacro?: boolean;
  parentMacro?: IMacro;
  microActivities?: IMicro[];

  showTimingActivity: boolean;
  editDueTo: boolean;
  dueToActivity?: IDateRangeValue;

  editTiming: boolean;
  timingActivityRange?: IDateRangeValue;

  showSpoc: boolean;
  editSpoc: boolean;
  spoc?: ITag;

  showAlarm: boolean;
  editAlarm: boolean;
  alarm: number;

  showHeadOfActivity: boolean;
  editHeadOfActivity: boolean;
  headOfActivity?: ITag;

  showInternalResources: boolean;
  assignedResourcesItems: User[];
  showExternalResources: boolean;
  externalResourcesItems: User[];
  showAlarmResources: boolean;
  alarmResourcesItems: User[];
}

export class ActivitySidebarComponent extends React.Component<
  IActivitySidebarComponentProps,
  IActivitySidebarComponentState
> {
  _taskColumns: IColumn[] = [];
  private readonly _translation: ActivityTranslation;

  constructor(props: IActivitySidebarComponentProps) {
    super(props);

    initializeIcons();
    this._translation = new ActivityTranslation(
      this.props.commonProps.translation
    );

    this._setActivityState = this._setActivityState.bind(this);
    this.alarmOnChange = this.alarmOnChange.bind(this);

    this._renderActivityModalHeader = this._renderActivityModalHeader.bind(
      this
    );

    var assignedResourcesItems: User[] = [];
    var externalResourcesItems: User[] = [];
    var alarmResourcesItems: User[] = [];

    this.state = {
      // item: props.item,
      isMacro: this.props.item?.isMacro,
      assignedResourcesItems: assignedResourcesItems,
      externalResourcesItems: externalResourcesItems,
      alarmResourcesItems: alarmResourcesItems,
      alarm: -1,
      editDueTo: false,
      editTiming: false,
      editSpoc: false,
      editAlarm: false,
      editHeadOfActivity: false,

      showAlarm: false,
      showSpoc: false,
      showHeadOfActivity: false,
      showTimingActivity: false,
      showExternalResources: false,
      showInternalResources: false,
      showAlarmResources: false,
    };
  }

  componentDidMount() {
    if (this.props.item) {
      this._setActivityState();
    }
  }

  private get alarmOptions(): IDropdownOption[] {
    return [
      {
        key: -1,
        text: this._translation.noAlarm,
        itemType: DropdownMenuItemType.Normal,
      },
      {
        key: 0,
        text: this._translation.alarmOnEndDate,
        itemType: DropdownMenuItemType.Normal,
      },
      {
        key: 1,
        text: this._translation.alarm1DayBefore,
        itemType: DropdownMenuItemType.Normal,
      },
      {
        key: 2,
        text: this._translation.alarm2DaysBefore,
        itemType: DropdownMenuItemType.Normal,
      },
      {
        key: 3,
        text: this._translation.alarm3DaysBefore,
        itemType: DropdownMenuItemType.Normal,
      },
      {
        key: 4,
        text: this._translation.alarm4DaysBefore,
        itemType: DropdownMenuItemType.Normal,
      },
      {
        key: 5,
        text: this._translation.alarm5DaysBefore,
        itemType: DropdownMenuItemType.Normal,
      },
      {
        key: 7,
        text: this._translation.alarm1WeekBefore,
        itemType: DropdownMenuItemType.Normal,
      },
      {
        key: 14,
        text: this._translation.alarm2WeeksBefore,
        itemType: DropdownMenuItemType.Normal,
      },
    ];
  }

  private alarmOnChange(
    ev: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption
  ) {
    if (this.props.updateAlarm) {
      const value = option?.key !== undefined ? +option.key : -1;
      this.props.updateAlarm(value).then((result) => {
        this.setState({
          editAlarm: false,
          showAlarm: true,
          alarm: value,
        });
      });
    }
  }

  private _setActivityState() {
    if (this.props.item) {
      if (this.props.item?.isMacro == true || this.state.isMacro == true) {
        this.setState({
          // item: response,
          assignedResourcesItems: this.props.item?.resources
            ? this.props.item?.resources
            : this.state.assignedResourcesItems,
          externalResourcesItems: this.props.item?.externalResources
            ? this.props.item?.externalResources
            : this.state.externalResourcesItems,
          alarmResourcesItems: this.props.item?.alarmResources
            ? this.props.item?.alarmResources
            : this.state.alarmResourcesItems,

          isMacro: true,
          parentMacro: undefined,
          microActivities: this.props.item?.microActivities,
          timingActivityRange: {
            fromDate: new Date(this.props.item?.dateFrom),
            toDate: new Date(this.props.item?.dateTo),
          },
          spoc: this.props.item?.spoc
            ? {
                key: this.props.item?.spoc.userPrincipalName,
                name: this.props.item?.spoc.displayName,
              }
            : undefined,
          headOfActivity: this.props.item?.headOfActivity
            ? {
                key: this.props.item?.headOfActivity.userPrincipalName,
                name: this.props.item?.headOfActivity.displayName,
              }
            : undefined,
          alarm:
            this.props.item?.alarm !== undefined ? this.props.item.alarm : -1,

          showAlarm: true,
          showSpoc: true,
          showHeadOfActivity: true,
          showTimingActivity: true,
          showExternalResources: true,
          showInternalResources: true,
          showAlarmResources: true,
        });
      } else {
        if (this.props.item?.parentMacro) {
          var microActivities = (this.props.item.parentMacro
            .microActivities as IMicro[]).filter(
            (x) => x.id != this.props.item?.id
          );

          this.setState({
            // item: response,
            assignedResourcesItems: this.props.item.resources
              ? this.props.item.resources
              : this.state.assignedResourcesItems,
            externalResourcesItems: this.props.item.externalResources
              ? this.props.item.externalResources
              : this.state.externalResourcesItems,
            alarmResourcesItems: this.props.item?.alarmResources
              ? this.props.item?.alarmResources
              : this.state.alarmResourcesItems,

            isMacro: false,
            parentMacro: this.props.item.parentMacro,
            microActivities: microActivities,
            // new Date(this.props.item.dateTo),
            dueToActivity: {
              fromDate: new Date(this.props.item?.dateFrom),
              toDate: new Date(this.props.item?.dateTo),
            },
            spoc: this.props.item.spoc
              ? {
                  key: this.props.item.spoc.userPrincipalName,
                  name: this.props.item.spoc.displayName,
                }
              : undefined,
            headOfActivity: this.props.item.headOfActivity
              ? {
                  key: this.props.item.headOfActivity.userPrincipalName,
                  name: this.props.item.headOfActivity.displayName,
                }
              : undefined,

            alarm:
              this.props.item?.alarm !== undefined ? this.props.item.alarm : -1,

            showAlarm: true,
            showSpoc: true,
            showHeadOfActivity: true,
            showTimingActivity: true,
            showExternalResources: true,
            showInternalResources: true,
            showAlarmResources: true,
          });
        } else {
          this.setState({
            // item: response,
            assignedResourcesItems: this.props.item.resources
              ? this.props.item.resources
              : this.state.assignedResourcesItems,
            externalResourcesItems: this.props.item.externalResources
              ? this.props.item.externalResources
              : this.state.externalResourcesItems,
            alarmResourcesItems: this.props.item?.alarmResources
              ? this.props.item?.alarmResources
              : this.state.alarmResourcesItems,

            isMacro: false,
            parentMacro: undefined,
            microActivities: [],
            // new Date(this.props.item.dateTo),
            dueToActivity: {
              fromDate: new Date(this.props.item?.dateFrom),
              toDate: new Date(this.props.item?.dateTo),
            },
            spoc: this.props.item.spoc
              ? {
                  key: this.props.item.spoc.userPrincipalName,
                  name: this.props.item.spoc.displayName,
                }
              : undefined,
            headOfActivity: this.props.item.headOfActivity
              ? {
                  key: this.props.item.headOfActivity.userPrincipalName,
                  name: this.props.item.headOfActivity.displayName,
                }
              : undefined,

            alarm:
              this.props.item?.alarm !== undefined ? this.props.item.alarm : -1,

            showAlarm: true,
            showSpoc: true,
            showHeadOfActivity: true,
            showTimingActivity: true,
            showExternalResources: true,
            showInternalResources: true,
            showAlarmResources: true,
          });
        }
      }
    }
  }

  private _renderActivityModalHeader() {
    return (
      <PanelActivityHeaderComponent
        commonProps={this.props.commonProps}
        item={this.props.item}
        isMacro={this.props.item?.isMacro}
        showContextMenu={false}
        showStatus={false}
        showType={true}
        showManage={false}
      ></PanelActivityHeaderComponent>
    );
  }

  private _decodeHTMLEntities(text: string) {
    var span = document.createElement("span");
    span.innerHTML = text;
    return span.textContent || span.innerText;
  }

  public render() {
    if (this.props.item) {
      return (
        <div
          role="sidebarActivityDetails"
          className={styles.ActivitySidebarComponent}
        >
          {this.props.viewDetails && this.state.isMacro && (
            <div>
              <div className={styles.label_RightDiv}>
                {this._translation.activityCalendar.toLocaleUpperCase()}
              </div>
              <div className={styles.value_RightDiv}>
                {
                  this.props.calendars.find(
                    (c) => c.key === this.props.item?.calendar
                  )?.value
                }
              </div>
              <div className={styles.label_RightDiv}>
                {this._translation.createdBy}
              </div>
              <div className={styles.value_RightDiv}>
                {this.props.item?.createdBy?.displayName}
              </div>
              <div className={styles.label_RightDiv}>
                {this._translation.createdOn}
              </div>
              <div className={styles.value_RightDiv}>
                {this.props.item?.createdOn
                  ? Utility.FormatDate(new Date(this.props.item?.createdOn))
                  : "---"}
              </div>
              {false && (
                <div className={styles.label_RightDiv}>
                  {this._translation.timing}
                </div>
              )}
              {false && (
                <div className={styles.value_RightDiv}>
                  {!this.props.editable && !this.state.editTiming && (
                    <div
                      className={
                        this.props.item?.permissions.canUpdate
                          ? styles.editableField
                          : ""
                      }
                      tabIndex={0}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          if (this.props.item?.permissions.canUpdate)
                            this.setState({ editTiming: true });
                        }
                      }}
                      onClick={() => {
                        if (this.props.item?.permissions.canUpdate)
                          this.setState({ editTiming: true });
                      }}
                    >
                      <div className={styles.text}>
                        {this.state.timingActivityRange?.fromDate
                          ? Utility.FormatDate(
                              this.state.timingActivityRange?.fromDate
                            )
                          : "---"}{" "}
                        -{" "}
                        {this.state.timingActivityRange?.toDate
                          ? Utility.FormatDate(
                              this.state.timingActivityRange?.toDate
                            )
                          : "---"}
                      </div>
                      {this.props.editable &&
                        this.props.item?.permissions.canUpdate && (
                          <FontIcon
                            aria-label={"Edit " + this._translation.timing}
                            iconName="Edit"
                            className={"iconGet"}
                          ></FontIcon>
                        )}
                    </div>
                  )}
                  {this.state.editTiming && (
                    <RangeCalendar
                      commonProps={this.props.commonProps}
                      showTime={false}
                      disableTime={false}
                      onChange={this.props.updateTimingActivity}
                      value={this.state.timingActivityRange}
                      onDismiss={() => {
                        this.setState({ editTiming: false });
                      }}
                    ></RangeCalendar>
                  )}
                </div>
              )}
              <div className={styles.label_RightDiv}>
                {" "}
                {this._translation.spoc}
              </div>
              <div className={styles.value_RightDiv}>
                {!this.state.showSpoc && (
                  <SpinnerComponent
                    commonProps={this.props.commonProps}
                    relative={true}
                  ></SpinnerComponent>
                )}
                {(!this.props.editable || !this.state.editSpoc) &&
                  this.state.showSpoc && (
                    <div
                      className={
                        this.props.item?.permissions.canUpdate
                          ? styles.editableField
                          : ""
                      }
                      tabIndex={0}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          if (this.props.item?.permissions.canUpdate) {
                            this.setState({ editSpoc: true });
                          }
                        }
                      }}
                      onClick={() => {
                        if (this.props.item?.permissions.canUpdate) {
                          this.setState({ editSpoc: true });
                        }
                      }}
                    >
                      <div className={styles.text}>
                        {this.state?.spoc ? this.state.spoc.name : "---"}
                      </div>
                      {this.props.editable &&
                        this.props.item?.permissions.canUpdate && (
                          <FontIcon
                            aria-label={"Edit " + this._translation.spoc}
                            iconName="Edit"
                            className={"iconGet"}
                          ></FontIcon>
                        )}
                    </div>
                  )}
                {this.state.editSpoc && this.state.showSpoc && (
                  <Spoc
                    commonProps={this.props.commonProps}
                    styles={Constants.pickerStyle}
                    onChange={(selected: ITag[], spocId?: string) => {
                      if (this.props.updateSpoc && spocId) {
                        this.setState({ showSpoc: false });
                        this.props.updateSpoc(selected).then((result) => {
                          this.setState({
                            editSpoc: false,
                            showSpoc: true,
                            spoc: result,
                          });
                        });
                      }
                    }}
                    onBlur={(selected: ITag[], spocId?: string) => {
                      if (this.props.updateSpoc) {
                        this.setState({ showSpoc: false });
                        this.props.updateSpoc(selected).then((result) => {
                          this.setState({
                            editSpoc: false,
                            showSpoc: true,
                            spoc: result,
                          });
                        });
                      }
                    }}
                    values={this.state.spoc ? [this.state.spoc] : []}
                    calendar={this.props.item.calendar || -1}
                  ></Spoc>
                )}
              </div>
              <div className={styles.label_RightDiv}>
                {this._translation.headOfActivity}
              </div>
              <div className={styles.value_RightDiv}>
                {!this.state.showHeadOfActivity && (
                  <SpinnerComponent
                    commonProps={this.props.commonProps}
                    relative={true}
                  ></SpinnerComponent>
                )}
                {(!this.props.editable || !this.state.editHeadOfActivity) &&
                  this.state.showHeadOfActivity && (
                    <div
                      className={
                        this.props.item?.permissions.canUpdate
                          ? styles.editableField
                          : ""
                      }
                      tabIndex={0}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          if (this.props.item?.permissions.canUpdate)
                            this.setState({ editHeadOfActivity: true });
                        }
                      }}
                      onClick={() => {
                        if (this.props.item?.permissions.canUpdate)
                          this.setState({ editHeadOfActivity: true });
                      }}
                    >
                      <div className={styles.text}>
                        {this.state?.headOfActivity
                          ? this.state.headOfActivity.name
                          : "---"}
                      </div>
                      {this.props.editable &&
                        this.props.item?.permissions.canUpdate && (
                          <FontIcon
                            aria-label={
                              "Edit " + this._translation.headOfActivity
                            }
                            iconName="Edit"
                            className={"iconGet"}
                          ></FontIcon>
                        )}
                    </div>
                  )}
                {this.state.editHeadOfActivity &&
                  this.state.showHeadOfActivity && (
                    <HeadOfActivity
                      commonProps={this.props.commonProps}
                      styles={Constants.pickerStyle}
                      onChange={(
                        selected: ITag[],
                        headOfActivityId?: string
                      ) => {
                        if (
                          this.props.updateHeadOfActivity &&
                          headOfActivityId
                        ) {
                          this.setState({ showHeadOfActivity: false });
                          this.props
                            .updateHeadOfActivity(selected)
                            .then((result) => {
                              this.setState({
                                editHeadOfActivity: false,
                                showHeadOfActivity: true,
                                headOfActivity: result,
                              });
                            });
                        }
                      }}
                      onBlur={(selected: ITag[], spocId?: string) => {
                        if (this.props.updateHeadOfActivity) {
                          this.setState({ showHeadOfActivity: false });
                          this.props
                            .updateHeadOfActivity(selected)
                            .then((result) => {
                              this.setState({
                                editHeadOfActivity: false,
                                showHeadOfActivity: true,
                                headOfActivity: result,
                              });
                            });
                        }
                      }}
                      values={
                        this.state.headOfActivity
                          ? [this.state.headOfActivity]
                          : []
                      }
                      calendar={this.props.item.calendar || -1}
                    ></HeadOfActivity>
                  )}
              </div>
            </div>
          )}
          {this.props.viewDetails && !this.state.isMacro && (
            <div>
              <div className={styles.label_RightDiv}>
                {this._translation.activityCalendar.toLocaleUpperCase()}
              </div>
              <div className={styles.value_RightDiv}>
                {
                  this.props.calendars.find(
                    (c) => c.key === this.props.item?.calendar
                  )?.value
                }
              </div>
              <div className={styles.label_RightDiv}>
                {/* {this._translation.dueTo} */}
                {this._translation.start}
              </div>
              <div className={styles.value_RightDiv}>
                {!this.state.showTimingActivity && (
                  <SpinnerComponent
                    commonProps={this.props.commonProps}
                    relative={true}
                  ></SpinnerComponent>
                )}
                {(!this.props.editable || !this.state.editDueTo) &&
                  this.state.showTimingActivity && (
                    <div
                      className={
                        this.props.item?.permissions.canUpdate
                          ? styles.editableField
                          : ""
                      }
                      tabIndex={0}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          if (this.props.item?.permissions.canUpdate)
                            this.setState({ editDueTo: true });
                        }
                      }}
                      onClick={() => {
                        if (this.props.item?.permissions.canUpdate)
                          this.setState({ editDueTo: true });
                      }}
                    >
                      {/* <div className={styles.text}>
                        {this.state?.dueToActivity
                          ? Utility.FormatDate(
                              new Date(this.state.dueToActivity)
                            )
                          : "---"}
                      </div> */}
                      <div className={styles.text}>
                        {this.state.dueToActivity?.fromDate
                          ? Utility.FormatDateAndTime(
                              this.state.dueToActivity?.fromDate
                            )
                          : "---"}{" "}
                        -{" "}
                        {this.state.dueToActivity?.toDate
                          ? Utility.FormatDateAndTime(
                              this.state.dueToActivity?.toDate
                            )
                          : "---"}
                      </div>
                      {this.props.editable &&
                        this.props.item?.permissions.canUpdate && (
                          <FontIcon
                            aria-label={"Edit " + this._translation.dueTo}
                            iconName="Edit"
                            className={"iconGet"}
                          ></FontIcon>
                        )}
                    </div>
                  )}
                {this.state.editDueTo && this.state.showTimingActivity && (
                  <RangeCalendar
                    commonProps={this.props.commonProps}
                    showTime={true}
                    disableTime={false}
                    onChange={(newValue) => {
                      if (this.props.updateDueToActivity) {
                        this.setState({ showTimingActivity: false });
                        this.props
                          .updateDueToActivity(newValue)
                          .then((result) => {
                            if (result) {
                              this.setState({
                                dueToActivity: newValue,
                                editDueTo: false,
                                showTimingActivity: true,
                              });
                            } else {
                              this.setState({
                                editDueTo: false,
                                showTimingActivity: true,
                              });
                            }
                          });
                      }
                    }}
                    value={this.state.dueToActivity}
                    onDismiss={() => {
                      this.setState({ editDueTo: false });
                    }}
                  ></RangeCalendar>
                )}
              </div>
              <div className={styles.label_RightDiv}>
                {this._translation.spoc}
              </div>
              <div className={styles.value_RightDiv}>
                {!this.state.showSpoc && (
                  <SpinnerComponent
                    commonProps={this.props.commonProps}
                    relative={true}
                  ></SpinnerComponent>
                )}
                {(!this.props.editable || !this.state.editSpoc) &&
                  this.state.showSpoc && (
                    <div
                      className={
                        this.props.item?.permissions.canUpdate
                          ? styles.editableField
                          : ""
                      }
                      tabIndex={0}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          if (this.props.item?.permissions.canUpdate)
                            this.setState({ editSpoc: true });
                        }
                      }}
                      onClick={() => {
                        if (this.props.item?.permissions.canUpdate)
                          this.setState({ editSpoc: true });
                      }}
                    >
                      <div className={styles.text}>
                        {this.state?.spoc ? this.state.spoc.name : "---"}
                      </div>
                      {this.props.editable &&
                        this.props.item?.permissions.canUpdate && (
                          <FontIcon
                            aria-label={"Edit " + this._translation.spoc}
                            iconName="Edit"
                            className={"iconGet"}
                          ></FontIcon>
                        )}
                    </div>
                  )}
                {this.state.editSpoc && this.state.showSpoc && (
                  <Spoc
                    commonProps={this.props.commonProps}
                    styles={Constants.pickerStyle}
                    onChange={(selected: ITag[], spocId?: string) => {
                      if (this.props.updateSpoc && spocId) {
                        this.setState({ showSpoc: false });
                        this.props.updateSpoc(selected).then((result) => {
                          this.setState({
                            editSpoc: false,
                            showSpoc: true,
                            spoc: result,
                          });
                        });
                      }
                    }}
                    onBlur={(selected: ITag[]) => {
                      if (this.props.updateSpoc) {
                        this.setState({ showSpoc: false });
                        this.props.updateSpoc(selected).then((result) => {
                          this.setState({
                            editSpoc: false,
                            showSpoc: true,
                            spoc: result,
                          });
                        });
                      }
                    }}
                    values={this.state.spoc ? [this.state.spoc] : []}
                    calendar={this.props.item.calendar || -1}
                  ></Spoc>
                )}
              </div>
              <div className={styles.label_RightDiv}>
                {this._translation.assignedResources}
              </div>
              <div className={styles.value_RightDiv}>
                <div className={styles.assignedResources}>
                  {!this.state.showInternalResources ? (
                    <SpinnerComponent
                      commonProps={this.props.commonProps}
                      relative={true}
                    ></SpinnerComponent>
                  ) : (
                    <ManageResourcesComponent
                      commonProps={this.props.commonProps}
                      // allResources={this.state.allResources}
                      listResources={this.state.assignedResourcesItems}
                      onRenderModalHeader={this._renderActivityModalHeader}
                      saveFunction={(
                        listResources: User[],
                        update: boolean
                      ) => {
                        if (this.props.updateAssignedResources) {
                          this.setState({ showInternalResources: false });
                          this.props
                            .updateAssignedResources(listResources, update)
                            .then((result) => {
                              this.setState({
                                showInternalResources: true,
                                assignedResourcesItems: result ? result : [],
                              });
                            });
                        }
                      }}
                      maxLength={3}
                      personaSize={PersonaSize.size40}
                      userType={"internalResource"}
                      edit={
                        this.props.editable &&
                        this.props.item.permissions.canUpdateResources
                      }
                      calendar={this.props.item.calendar || -1}
                    />
                  )}
                </div>
              </div>
              <div className={styles.label_RightDiv}>
                {this._translation.externalResources}
              </div>
              <div className={styles.value_RightDiv}>
                <div className={styles.assignedResources}>
                  {!this.state.showExternalResources ? (
                    <SpinnerComponent
                      commonProps={this.props.commonProps}
                      relative={true}
                    ></SpinnerComponent>
                  ) : (
                    <ManageResourcesComponent
                      commonProps={this.props.commonProps}
                      // allResources={this.state.allResources}
                      listResources={this.state.externalResourcesItems}
                      onRenderModalHeader={this._renderActivityModalHeader}
                      saveFunction={(
                        listResources: User[],
                        update: boolean
                      ) => {
                        if (this.props.updateExternalResources) {
                          this.setState({ showExternalResources: false });
                          this.props
                            .updateExternalResources(listResources, update)
                            .then((result) => {
                              this.setState({
                                showExternalResources: true,
                                externalResourcesItems: result ? result : [],
                              });
                            });
                        }
                      }}
                      maxLength={3}
                      personaSize={PersonaSize.size40}
                      userType={"externalResource"}
                      edit={
                        this.props.editable &&
                        this.props.item.permissions.canUpdateResources
                      }
                      calendar={this.props.item.calendar || -1}
                    />
                  )}
                </div>
              </div>

              <div className={styles.label_RightDiv}>
                {this._translation.activityAlarm.toLocaleUpperCase()}
              </div>
              <div className={styles.value_RightDiv}>
                {!this.state.showAlarm && (
                  <SpinnerComponent
                    commonProps={this.props.commonProps}
                    relative={true}
                  ></SpinnerComponent>
                )}
                {(!this.props.editable || !this.state.editAlarm) && (
                  <div
                    className={
                      this.props.item?.permissions.canUpdate
                        ? styles.editableField
                        : ""
                    }
                    tabIndex={0}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        if (this.props.item?.permissions.canUpdate)
                          this.setState({ editAlarm: true });
                      }
                    }}
                    onClick={() => {
                      if (this.props.item?.permissions.canUpdate)
                        this.setState({ editAlarm: true });
                    }}
                  >
                    <div className={styles.text}>
                      {
                        this.alarmOptions.find(
                          (o) => o.key === this.state.alarm
                        )?.text
                      }
                    </div>
                    {this.props.editable &&
                      this.props.item?.permissions.canUpdate && (
                        <FontIcon
                          aria-label={"Edit " + this._translation.activityAlarm}
                          iconName="Edit"
                          className={"iconGet"}
                        ></FontIcon>
                      )}
                  </div>
                )}
                {this.state.editAlarm && this.state.showAlarm && (
                  <Dropdown
                    className="w-100"
                    id="activityAlarm"
                    options={this.alarmOptions}
                    selectedKey={this.state.alarm}
                    onChange={this.alarmOnChange}
                    styles={Constants.dropdownStyle}
                  />
                )}
              </div>
              {(this.props.item?.alarm !== undefined
                ? this.props.item.alarm
                : -1) >= 0 && (
                <>
                  <div className={styles.label_RightDiv}>
                    {this._translation.alarmResourcesActivity.toLocaleUpperCase()}
                  </div>
                  <div className={styles.value_RightDiv}>
                    <div className={styles.assignedResources}>
                      {!this.state.showAlarmResources ? (
                        <SpinnerComponent
                          commonProps={this.props.commonProps}
                          relative={true}
                        ></SpinnerComponent>
                      ) : (
                        <ManageResourcesComponent
                          commonProps={this.props.commonProps}
                          // allResources={this.state.allResources}
                          listResources={this.state.alarmResourcesItems}
                          onRenderModalHeader={this._renderActivityModalHeader}
                          saveFunction={(
                            listResources: User[],
                            update: boolean
                          ) => {
                            if (this.props.updateAlarmResources) {
                              this.setState({ showAlarmResources: false });
                              this.props
                                .updateAlarmResources(listResources, update)
                                .then((result) => {
                                  this.setState({
                                    showAlarmResources: true,
                                    alarmResourcesItems: result ? result : [],
                                  });
                                });
                            }
                          }}
                          maxLength={3}
                          personaSize={PersonaSize.size40}
                          userType={"reminderResource"}
                          edit={
                            this.props.editable &&
                            this.props.item.permissions.canUpdateResources
                          }
                          calendar={this.props.item.calendar || -1}
                        />
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
          {!this.state.isMacro &&
            (this.props.item?.permissions.canUpdate ||
              (this.props.item?.parentMacro &&
                this.props.item?.parentMacro?.id &&
                this.props.item?.parentMacro?.id > 0)) && (
              <div className={styles.label_RightDiv}>
                {this._translation.parent}
              </div>
            )}
          {!this.props.item.isMacro &&
            this.props.item?.parentMacro &&
            this.props.item?.parentMacro?.id &&
            this.props.item?.parentMacro?.id > 0 && (
              <div
                className={styles.value_RightDiv + " " + styles.parentActivity}
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    this.props.viewParent();
                  }
                }}
                onClick={this.props.viewParent}
              >
                <div className={styles.typeDetails + " typeDetails"}>
                  {"MACRO"}
                </div>
                <div className={styles.text}>
                  {this.props.item?.parentMacro?.name}
                </div>
                <FontIcon
                  aria-label={"Show Macro"}
                  iconName="ChevronRightSmall"
                  className={"iconGet"}
                ></FontIcon>
              </div>
            )}
          {!this.props.item.isMacro &&
            this.props.editable &&
            this.props.item?.permissions.canUpdate && (
              <div
                className={styles.editableField}
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && this.props.updateParent) {
                    this.props.updateParent();
                  }
                }}
                onClick={this.props.updateParent}
              >
                <div
                  className={
                    styles.value_RightDiv + " " + styles.parentActivity
                  }
                >
                  {this.props.item?.parentMacro &&
                  this.props.item?.parentMacro?.id &&
                  this.props.item?.parentMacro?.id > 0
                    ? this._translation.changeAssociatedMacro
                    : this._translation.associateMacro}
                </div>
                {this.props.editable &&
                  this.props.item?.permissions.canUpdate && (
                    <FontIcon
                      aria-label={"Edit Parent"}
                      iconName="Edit"
                      className={"iconGet"}
                    ></FontIcon>
                  )}
              </div>
            )}
          {this.state.microActivities &&
            this.state.microActivities.length > 0 && (
              <div className={styles.label_RightDiv}>
                {this._translation.associatedActivities}
              </div>
            )}
          {this.state.microActivities &&
            this.state.microActivities.length > 0 &&
            this.state.microActivities.map((x) => {
              return (
                <div
                  className={
                    styles.value_RightDiv + " " + styles.parentActivity
                  }
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      // this.props.viewLinkedMicro(ev, x);
                    }
                  }}
                  onClick={(ev) => {
                    this.props.viewLinkedMicro(ev, x);
                  }}
                >
                  <div className={styles.typeDetails + " typeDetails"}>
                    {"MICRO"}
                  </div>
                  <div className={styles.text}>{x.name}</div>
                  <FontIcon
                    aria-label={"Show Micro"}
                    iconName="ChevronRightSmall"
                    className={"iconGet"}
                  ></FontIcon>
                </div>
              );
            })}
          {this.state.isMacro &&
            (this.props.item?.linkedItemBrief ||
              this.props.item?.linkedFileBrief) && (
              <div className={styles.label_RightDiv}>
                {this._translation.macroDocumentation}
              </div>
            )}
          {this.state.isMacro && this.props.item?.linkedFileBrief?.id && (
            <div
              className={
                styles.value_RightDiv + " " + styles.MacroDocumentation
              }
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  var link = document.createElement("a");
                  link.target = "_blank";
                  link.href = this.props.item?.linkedFileBrief?.fileDownload
                    ? this.props.item?.linkedFileBrief?.fileDownload
                    : "";
                  link.click();
                  link.parentNode?.removeChild(link);
                }
              }}
              onClick={() => {
                var link = document.createElement("a");
                link.target = "_blank";
                link.href = this.props.item?.linkedFileBrief?.fileDownload
                  ? this.props.item?.linkedFileBrief?.fileDownload
                  : "";
                link.click();
                link.parentNode?.removeChild(link);
              }}
            >
              <FontIcon
                iconName="TextDocument"
                className={"iconGet"}
              ></FontIcon>
              <div>{this.props.item?.linkedFileBrief.fileName}</div>
              <FontIcon
                iconName="ChevronRightSmall"
                className={"iconGet"}
              ></FontIcon>
            </div>
          )}

          {this.state.isMacro && this.props.item?.linkedItemBrief?.id && (
            <div
              className={
                styles.value_RightDiv + " " + styles.MacroDocumentation
              }
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  window.scrollTo(0, 0);
                  const route: IRoute = {
                    page: Page.MyBrief,
                    additionalInfo: {
                      keysValues: {
                        id: this.props.item?.linkedItemBrief?.id.toString(),
                        type: undefined,
                        subtype: undefined,
                        title: undefined,
                        showMyActivities: undefined,
                      },
                    },
                  };
                  this.props.commonProps.onRoute?.next(route);
                }
              }}
              onClick={() => {
                window.scrollTo(0, 0);
                const route: IRoute = {
                  page: Page.MyBrief,
                  additionalInfo: {
                    keysValues: {
                      id: this.props.item?.linkedItemBrief?.id.toString(),
                      type: undefined,
                      subtype: undefined,
                      title: undefined,
                      showMyActivities: undefined,
                    },
                  },
                };
                this.props.commonProps.onRoute?.next(route);
              }}
            >
              <FontIcon
                iconName="TextDocument"
                className={"iconGet"}
              ></FontIcon>
              <div>{this.props.item?.linkedItemBrief.title}</div>
              <FontIcon
                iconName="ChevronRightSmall"
                className={"iconGet"}
              ></FontIcon>
            </div>
          )}
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}
