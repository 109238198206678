import * as React from "react";
import styles from "./SupportComponent.module.scss";
import { FontIcon } from "@fluentui/react";
import "url-search-params-polyfill";
import { SupportHeaderComponent } from "./SupportHeaderComponent/SupportHeaderComponent";
import { SupportHomeComponent } from "./SupportComponents/SupportHomeComponent/SupportHomeComponent";
import { SupportFooterComponent } from "./SupportFooterComponent/SupportFooterComponent";
import { UserGuidesComponent } from "./SupportComponents/UserGuideComponent/UserGuidesComponent";
import { VideoTutorialsComponent } from "./SupportComponents/VideoTutorialComponent/VideoTutorialsComponent";

import { FAQComponent } from "./SupportComponents/FAQComponent/FAQComponent";
import { IBaseProperties } from "../../../../Models/IBaseProperties";
import {
  SupportLanguage,
  SupportPagesEnum,
} from "../../../../Models/SupportPage";
import { SupportTranslation } from "../../../../Translations/Support.Translation";

export interface ISupportComponentProps extends IBaseProperties {}

export interface ISupportComponentState {
  // persona= IPersonaComponentItem[];
  content: SupportPagesEnum;
}

export class SupportComponent extends React.Component<
  ISupportComponentProps,
  ISupportComponentState
> {
  private readonly _translation: SupportTranslation;

  constructor(props: ISupportComponentProps) {
    super(props);

    this._translation = new SupportTranslation(
      this.props.commonProps.translation
    );

    this.changePage = this.changePage.bind(this);
    this.state = {
      content: SupportPagesEnum.Home,
    };
  }

  public changePage(p: SupportPagesEnum) {
    this.setState({
      content: p,
    });
  }

  public render() {
    return (
      <div className={styles.SupportCenter}>
        <div>
          <div hidden={true}>
            <input
              type={"text"}
              id={"txtSupportChangePage"}
              onClick={() => {
                var el: HTMLInputElement = document.getElementById(
                  "txtSupportChangePage"
                ) as HTMLInputElement;
                this.setState({ content: parseInt(el.value) });
              }}
            ></input>
          </div>
          <div className={styles.SupportTop}>
            <div className={styles.SupportContent}>
              <div hidden={true}>
                <input
                  type={"text"}
                  id={"txtHeaderSupportChangePage"}
                  onClick={() => {
                    var el: HTMLInputElement = document.getElementById(
                      "txtHeaderSupportChangePage"
                    ) as HTMLInputElement;
                    this.setState({ content: parseInt(el.value) });
                  }}
                ></input>
              </div>
              <SupportHeaderComponent
                commonProps={this.props.commonProps}
                page={this.state.content}
                changePage={this.changePage}
              ></SupportHeaderComponent>
            </div>
          </div>
          <div className={styles.SupportCenter}>
            <div className={styles.SupportContent}>
              {this.state.content == SupportPagesEnum.Home && (
                <SupportHomeComponent
                  commonProps={this.props.commonProps}
                  userGuidesVisibility={true}
                  videoTutorialsVisibility={false}
                  faqsVisibility={true}
                  changePage={this.changePage}
                ></SupportHomeComponent>
              )}
              {this.state.content == SupportPagesEnum.UserGuide && (
                <UserGuidesComponent
                  commonProps={this.props.commonProps}
                  component={{
                    visible: true,
                    languages: [SupportLanguage.English],
                  }}
                ></UserGuidesComponent>
              )}
              {this.state.content == SupportPagesEnum.VideoTutorials && (
                <VideoTutorialsComponent
                  commonProps={this.props.commonProps}
                  component={{
                    visible: false,
                    languages: [SupportLanguage.English],
                  }}
                ></VideoTutorialsComponent>
              )}
              {this.state.content == SupportPagesEnum.Faq && (
                <FAQComponent
                  commonProps={this.props.commonProps}
                  languages={[SupportLanguage.English]}
                  languageSelected={SupportLanguage.English}
                ></FAQComponent>
              )}
            </div>
          </div>
          <div className={styles.SupportBottom}>
            <div className={styles.SupportContent}>
              <SupportFooterComponent
                commonProps={this.props.commonProps}
                footer={{
                  links: [
                    {
                      header: this._translation.footerHeaderQuestions,
                      text: this._translation.footerTextQuestions,
                      href: "dl-gmg@enel.com",
                      hrefText: this._translation.footerHrefTextQuestions,
                    },
                    {
                      header: this._translation.footerHeaderTechSupport,
                      text: this._translation.footerTextTechSupport,
                      href: "SUPPORTMEDIAPLANNING@ENEL.COM",
                      hrefText: this._translation.footerHrefTextTechSupport,
                    },
                  ],
                  webFunctionUrl: "", // "https://enelpr-servicenow-functionapp.azurewebsites.net",
                  functionName: "", // "api/HttpTrigger1",
                  clientId: "", // "c284d66e-1f0f-4892-9e82-33c6c8ab9f76"
                  userId: "",
                }}
              ></SupportFooterComponent>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
