import React from "react";
import {DayActivityToolTip} from './DayActivityTooltip';
import {Calendar} from '../../../../Models/Calendar';
import {DayCell, DisplayType} from './DayCell';

export interface IMonthDayProps {
  content: string,
  activities: Calendar[] | null; 
  isCurrentDay: boolean;
  isSelectedDay: boolean;
  date: Date | null;
  onDrillDown: any;

}
export interface IMonthDayState {  
}

export class MonthDay extends React.Component<IMonthDayProps, IMonthDayState> {
   
    constructor(props: IMonthDayProps) {
        super(props);
    }

    renderDay(content: string, activities: Calendar[] | null, date: Date | null, isCurrentDay: boolean = false, isSelectedDay: boolean = false)
    {

        const hasActivities:boolean = activities !=null && activities.some(() => true);
        
        //calendar day not to be printed        
        const dayDoNotPrint =<DayCell date={date} currentDay={isCurrentDay} selectedDay={isSelectedDay} onDrillDown={this.props.onDrillDown} content="" cellType={DisplayType.Empty}></DayCell>

        //day to print that has at least one activity
        const dayWithActivities = <DayActivityToolTip key={date?.toUTCString()} content={content} onDrillDown={this.props.onDrillDown} activities={activities} currentDay={isCurrentDay} selectedDay={isSelectedDay} date={this.props.date}></DayActivityToolTip>;
        
        //day to print, hasn't activities
        const dayWithoutActivities = <DayCell date={date} currentDay={isCurrentDay} selectedDay={isSelectedDay} onDrillDown={this.props.onDrillDown} content={content} cellType={DisplayType.WithoutActivities}></DayCell>

        if (content)
        {               
            return hasActivities ? dayWithActivities : dayWithoutActivities;
        }
        else
        {
            return dayDoNotPrint
        }
    } 
        
    render() {
        return (
            <React.Fragment>
                {this.renderDay(this.props.content, this.props.activities, this.props.date, this.props.isCurrentDay, this.props.isSelectedDay)}
            </React.Fragment>
            
        );
    }  
    
};