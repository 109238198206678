import { INotificationHistoryDTO } from "../Models/DTO/NotificationHistoryDTO";
import { APIUtility } from "../Utility/APIUtility";
import {INotificationHistoryClient} from './INotificationHistoryClient';
import { HttpError } from "../Models/HttpResponse";
import { ErrorClient } from "./ErrorClient";
import moment from "moment";
import { INotificationHistory, NotificationSubType, NotificationType } from "../Models/Notification";

export class NotificationHistoryClient implements INotificationHistoryClient
{
    private readonly _http:APIUtility = new APIUtility();     
       
    public async getNotifications(page: number): Promise<INotificationHistory[] | undefined>
    {        
        let retValue: INotificationHistory[] | undefined = undefined;
        try 
        {     
            const apiUtility = new APIUtility();
            const dto: INotificationHistoryDTO[]  = await apiUtility.getNotificationHistory(page);                
            retValue = this.dtoToModels(dto);
        } 
        catch (err) 
        {
            this.storeError(err);
        } 
        finally 
        {
            return retValue;
        }
    }

    private storeError(err: HttpError): void {
        console.error(err);
        ErrorClient.storeHttpError(err);
      }

    private dtoToModels(dto: INotificationHistoryDTO[]): INotificationHistory[]
    {
        const items: INotificationHistory[] =
            dto
            .filter(t=> t.entityId && t.name)
            .map(t=> this.dtoToModel(t));
       
        return items;
    }

    private dtoToModel(dto: INotificationHistoryDTO): INotificationHistory
    {        
        const localDate = this.utcToLocalDate(dto.date);
        
        const value: INotificationHistory = {
            notificationId: dto.id,
            id: dto.entityId ? dto.entityId : -1,
            title: dto.name ? dto.name : '-',
            type: this.toNotificationType(dto.mainType),
            subType: this.toNotificationSubType(dto.subType),
            author: dto.author,
            date: localDate,
            identifier: dto.entityId.toString()
        };
        return value;            
    }

    private toNotificationType(value: string): NotificationType
    {
        let retValue = NotificationType.Unknown;
        
        switch (value.toLowerCase())
        {
            case "brief":
                retValue = NotificationType.Brief;
                break;
            case "microactivity":
                retValue = NotificationType.Micro;
                break;
            case "macroactivity":
                retValue = NotificationType.Macro;
                break;
        }

        return retValue;
    }

    private toNotificationSubType(value: string): NotificationSubType
    {        
        const lowerValue = value.toLowerCase();
        const retValue = lowerValue as keyof typeof NotificationSubType;
        return NotificationSubType[retValue];

        // let retValue = NotificationSubType.Unknown;        
        
        // switch (value.toLowerCase())
        // {            
        //     case "briefaddition":
        //         retValue = NotificationSubType.BriefAddition;
        //         break;
        //     case "briefsetpending":                
        //         retValue = NotificationSubType.BriefSetPending;
        //         break;
        //     case "briefactivitydelegation":                
        //         retValue = NotificationSubType.BriefActivityDelegation;
        //         break;
        //     case "briefapprovationdelegation":                
        //         retValue = NotificationSubType.BriefApprovationDelegation;
        //         break;
        //     case "briefsetrework":
        //         retValue = NotificationSubType.BriefSetRework;
        //         break;
        //     case "briefapprovation":
        //         retValue = NotificationSubType.BriefApprovation;
        //         break;
        //     case "briefrejection":
        //         retValue = NotificationSubType.BriefRejection;
        //         break;
        //     case "genericmessage":
        //         retValue = NotificationSubType.GenericMessage;
        //         break;
        //     case "briefinforequest":
        //         retValue = NotificationSubType.BriefInfoRequest;
        //         break;
        //     case "microactivityresourceassignment":
        //         retValue = NotificationSubType.MicroActivityResourceAssignment;
        //         break;
        //     case "microactivityresourceremoval":
        //         retValue = NotificationSubType.MicroActivityResourceRemoval;
        //         break;
        //     case "microactivitystatuschange":
        //         retValue = NotificationSubType.MicroActivityStatusChange;
        //         break;
        //     case "microactivityspocassignment":
        //         retValue = NotificationSubType.MicroActivitySpocAssignment;
        //         break;            
        //     case "microactivityspocremoval":
        //         retValue = NotificationSubType.MicroActivitySpocRemoval;
        //         break;                                
        //     case "microactivitytaskcreation":
        //         retValue = NotificationSubType.MicroActivityTaskCreation;
        //         break;
        //     case "microactivitytaskcompletion":
        //         retValue = NotificationSubType.MicroActivityTaskCompletion;
        //         break;
        //     case "microactivitytaskreopening":
        //         retValue = NotificationSubType.MicroActivityTaskReopening;
        //         break;
        //     case "macroactivityheadassignment":
        //         retValue = NotificationSubType.MacroActivityHeadAssignment;
        //         break;
        //     case "macroactivityHeadremoval":
        //         retValue = NotificationSubType.MacroActivityHeadRemoval;
        //         break;                
        //     case "macroactivityspocassignment":
        //         retValue = NotificationSubType.MacroActivitySpocAssignment;
        //         break;
        //     case "macroactivityspocremoval":
        //         retValue = NotificationSubType.MacroActivitySpocRemoval;
        //         break;            
        // }

        // return retValue;
    }
    
    private utcToLocalDate(input: Date): Date
    {
        return moment
        .utc(input)
        .local()
        .toDate();
    }

}