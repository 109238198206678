import React from "react";
// import './MonthCalendar.css';
import styles from "./MonthCalendar.module.scss";
import {Calendar, LinkedActivity} from '../../../../Models/Calendar';

export interface IActivityThinDetailProps {
  activities: Calendar[];
  date: Date
}
export interface IActivityThinDetailState {
  activities: Calendar[];  
}

export class ActivityThinDetail extends React.Component<IActivityThinDetailProps, IActivityThinDetailState> {
   
    constructor(props: IActivityThinDetailProps) {
        super(props);
        this.state = {
          activities: props.activities,
        };                
    }
    
    private getDayLinkedActivities(date: Date, linkedActivities: LinkedActivity[])
    {        
        return linkedActivities.filter((act) => date >= this.withoutTime(act.start) && date <= this.withoutTime(act.end));        
    }

    private withoutTime(date: Date): Date    
    {        
        const d = new Date(date.getTime());
        d.setHours(0, 0, 0, 0);
        return d;
    }

    generateId() {
        const length = 20;
        let result           = '';
        const characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for ( let i = 0; i < length; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
     }

    renderActivity(activity: Calendar, date: Date)
    {
        if (activity)
        {
            return this.renderMacro(activity, date);
        }

        return null;
    }
    
    renderMacro(activity: Calendar, date: Date)
    {
        if (activity)
        {                        
            const macroActivity = <div key={activity.id} className={styles.ActivityThinDetail}>
                <div key={activity.id} className={styles["tooltip-activity"]} >
                    {activity.title}
                </div>
                {activity.linkedActivities && this.renderMicros(this.getDayLinkedActivities(date, activity.linkedActivities))}
            </div>;

            return (
                    macroActivity                
            );
        }

        return null;
    }

    renderMicro(micro: LinkedActivity)
    {
        if (micro)
        {
            const microStatus = <span className={this.getMicroClassNames(micro)}></span>;
            
            return (
                <div key={micro.id} className={styles["tooltip-activity"]} >   
                    {microStatus}
                    {micro.title}
                </div>
            );
        }

        return null;
    }
    getMicroClassNames(micro: LinkedActivity): string | undefined {
        return `circle-status back-${micro.state.toLowerCase()}`;
    }

    renderMicros(microActivities: LinkedActivity[])
    {
        return (
            <React.Fragment>
               {microActivities.map((micro) => (
                    this.renderMicro(micro)
                ))}
            </React.Fragment>
        );
    }

    renderActivities(activities: Calendar[], date: Date)
    {
        return (
            <React.Fragment>
               {activities.map((activity) => (
                    this.renderActivity(activity, date)
                ))}
            </React.Fragment>
        );
    }
    
    render() {
        return (
           <React.Fragment>
               {this.renderActivities(this.props.activities, this.props.date)}
           </React.Fragment>
          );
    }  
    
};