import PropTypes from 'prop-types'
import React from 'react'
import * as dates from './utils/dates'
import { navigate } from './utils/constants'
import TimeGrid from './TimeGrid'
import {YearPlanner} from '../YearPlanner/YearPlanner'

class Year extends React.Component {
  render() {
    let { date, ...props } = this.props
    
    const year = date.getFullYear();

    return <YearPlanner year={year} t={props} activities={props.events} selectedDay={props.selectedDay} />
    
  }
}

Year.propTypes = {
  selectedDay: PropTypes.instanceOf(Date).isRequired,
}

Year.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
}

Year.propTypes = {
  translation: PropTypes.any.isRequired,
}

Year.defaultProps = TimeGrid.defaultProps

Year.navigate = (date, action) => {
  switch (action) {
    case navigate.PREVIOUS:
      return dates.add(date, -1, 'year')

    case navigate.NEXT:
      return dates.add(date, 1, 'year')

    default:
      return date
  }
}

Year.range = (date, { localizer }) => {
  let firstOfWeek = localizer.startOfWeek()
  let start = dates.startOf(date, 'week', firstOfWeek)
  let end = dates.endOf(date, 'week', firstOfWeek)

  return dates.range(start, end)
}

Year.title = (date, { localizer }) => {
  const currentYear = date.getFullYear();
  return currentYear; 
}

export default Year
