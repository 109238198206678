import * as React from "react";
import { filter, filter as filteroperator } from "rxjs/operators";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { BriefTranslation } from "../../../../Translations/Brief.Translation";
import { Utility } from "../../../../Utility/Utility";
import { LoaderComponent } from "../../../Shared/LoaderComponent/LoaderComponent";
import { RangeCalendar } from "../../../Shared/RangeCalendar/RangeCalendar";
import styles from "./ListBriefs.module.scss";
import { CellNotification } from "./CellNotification";

import {
  ColumnActionsMode,
  ContextualMenu,
  DefaultButton,
  DetailsRow,
  DirectionalHint,
  FontIcon,
  IContextualMenuProps,
  IDetailsListProps,
  IDetailsRowStyles,
  SearchBox,
  SelectionMode,
  Dropdown,
  IDropdownOption,
  ISelectableOption,
  Panel,
  PanelType,
  CommandBarButton,
  IButtonStyles,
  IDetailsColumnStyles,
} from "@fluentui/react";

import {
  DetailsHeader,
  DetailsList,
  IColumn,
  IDetailsHeaderProps,
  IDetailsList,
  IRenderFunction,
  IContextualMenuItem,
  Icon,
  Spinner,
  SpinnerSize,
} from "office-ui-fabric-react";

import { IOrderBy } from "../../../../Models/Activity";
import { IDateRangeValue } from "../../../Shared/RangeCalendar/IDateRangeValue";
import {
  IBaseProperties,
  IRoute,
  Page,
} from "../../../../Models/IBaseProperties";

import {
  IBriefFilter,
  IListBrief,
  BriefType,
  IBriefPermission,
  IListBriefDetails,
  IListBriefPermission,
  IBriefDetail,
} from "../../../../Models/Brief";
import { ToastNotificationType } from "../../../../Models/ToastNote";
import { FormEvent } from "react";
import { IBriefClient } from "../../../../Clients/IBriefClient";
import { DeliverableFileTypes } from "../../../../Models/Deliverable";
import { PanelBriefDetail } from "../PanelBriefDetail/PanelBriefDetail";
import { DeepPartial } from "redux";
import {
  INotification,
  NotificationSubType,
  NotificationType,
} from "../../../../Models/Notification";
import { Observable, Observer, Subscription } from "rxjs";
import { stat } from "fs/promises";
import { threadId } from "worker_threads";

const onRenderOption: IRenderFunction<ISelectableOption> = (option) =>
  option ? (
    <div className={styles.fileTypeOption}>
      {option.data && option.data.icon && (
        <Icon
          iconName={option.data.icon}
          aria-hidden="true"
          title={option.data.icon}
          className={styles.iconFileExtension}
        />
      )}
      <span>{option.text}</span>
    </div>
  ) : null;

export interface IListBriefProps extends IBaseProperties { }

export interface IListBriefState {
  briefContextualMenuProps?: IContextualMenuProps;
  filteredItems: IListBriefDetails[];
  permission?: IListBriefPermission;
  showItemIndexInView: boolean;
  isCompactMode: boolean;
  columns: IColumn[];
  contextualMenuProps?: IContextualMenuProps;
  idSearch?: number;
  titleSearch: string;
  statusSearch: string;
  applicantUnitSearch: string;
  referentSearch: string;
  dateRangeSearch?: IDateRangeValue;
  dateSearch?: string;
  lastCount: number;
  isLoading: boolean;
  openHistoryBriefVersion: boolean;
  activityId?: number;
  idBrief?: number;
  showDeleteButton: boolean;
  showBriefDetail: boolean;
  briefTitle?: string;
  referentOptions: IDropdownOption[];
  applicantUnitOptions: IDropdownOption[];
}

const mock: boolean = false;

export class ListBriefs extends React.Component<
  IListBriefProps,
  IListBriefState
> {
  private _root = React.createRef<IDetailsList>();
  private _columns: IColumn[];
  private _observer?: IntersectionObserver;
  private readonly _translation: BriefTranslation;
  private readonly _briefClient: IBriefClient;

  private readonly _statusOptions: IDropdownOption[] = [
    { key: "all", text: "All" },
    { key: "draft", text: "Draft" },
    { key: "pendingrework", text: "Pending/Rework" },
    // { key: "rework", text: "Rework" },
    { key: "rejected", text: "Rejected" },
    { key: "accepted", text: "Accepted" },
  ];

  private _allOption: IDropdownOption = { key: "all", text: "All" };

  private readonly _resetStateFilter = (this.state = {
    referentSearch: "",
    applicantUnitSearch: "",
    filteredItems: [],
    showItemIndexInView: false,
    isCompactMode: false,
    columns: [],
    titleSearch: "",
    statusSearch: "",
    dateRangeSearch: undefined,
    isLoading: true,
    lastCount: 0,
    idBrief: -1,
    openHistoryBriefVersion: false,
    showBriefDetail: false,
    referentOptions: [this._allOption],
    applicantUnitOptions: [this._allOption],
    showDeleteButton: false,
  });

  private readonly _urlParams = new URLSearchParams(window.location.search);
  private readonly _menuContextStyle: Partial<IButtonStyles> = {
    root: { backgroundColor: "transparent" },
  };
  private readonly _menuContextCellStyle: DeepPartial<IDetailsColumnStyles> = {
    cellName: { margin: "auto" },
  };
  private _routingObserver?: Subscription;
  private _notificationSubscription?: Subscription;

  constructor(props: IListBriefProps) {
    super(props);

    this._translation = new BriefTranslation(
      this.props.commonProps.translation
    );

    this._briefClient = this.props.commonProps.clientCreator.createBriefClient();

    this.bindEvents();

    this._observer = undefined;

    this._columns = this.getColumnsList();

    this.state = {
      filteredItems: [],
      showItemIndexInView: false,
      isCompactMode: false,
      columns: this._columns,
      isLoading: true,
      lastCount: 0,
      titleSearch: "",
      statusSearch: "",
      applicantUnitSearch: "",
      idBrief: 0,
      referentSearch: "",
      openHistoryBriefVersion: false,
      showBriefDetail: false,
      referentOptions: [],
      applicantUnitOptions: [],
      showDeleteButton: false,
    };
  }

  private bindEvents() {
    this.onSearchTitle = this.onSearchTitle.bind(this);
    this.onChangeTitle = this.onChangeTitle.bind(this);
    this.onChangeStatus = this.onChangeStatus.bind(this);
    this.onChangeReferent = this.onChangeReferent.bind(this);
    this.onChangeApplicantUnit = this.onChangeApplicantUnit.bind(this);
    this.onChangeActivityType = this.onChangeActivityType.bind(this);
    this._onRenderDetailsHeader = this._onRenderDetailsHeader.bind(this);
    this._resetFilter = this._resetFilter.bind(this);
    this._getItems = this._getItems.bind(this);
    this._getCount = this._getCount.bind(this);
    this._handleObserver = this._handleObserver.bind(this);
    this._rangeCalendarChange = this._rangeCalendarChange.bind(this);
    this._onBriefContextualMenuDismissed = this._onBriefContextualMenuDismissed.bind(
      this
    );
    this._getBriefContextualMenuProps = this._getBriefContextualMenuProps.bind(
      this
    );
    this.onRowClick = this.onRowClick.bind(this);
    this.onSaveBrief = this.onSaveBrief.bind(this);
    this.onChangeBriefStatus = this.onChangeBriefStatus.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onDismiss = this.onDismiss.bind(this);
  }

  private onDismiss(): void {
    this.setState({
      showBriefDetail: false,
      idBrief: undefined,
      briefTitle: undefined,
    });
  }

  private onSaveBrief(brief: IBriefDetail): void {
    this.setState(
      {
        isLoading: true,
      },
      async () => this.loadBriefsAsync(false)
    );
  }

  private onChangeBriefStatus(id: number): void {
    this.setState(
      {
        isLoading: true,
        showBriefDetail: false,
        idBrief: undefined,
        briefTitle: undefined,
      },
      async () => this.loadBriefsAsync(false)
    );
  }

  private onDelete(id: number): void {
    this.setState(
      {
        isLoading: true,
        showBriefDetail: false,
        idBrief: undefined,
        briefTitle: undefined,
      },
      async () => this.loadBriefsAsync(false)
    );
  }

  private onChangeActivityType(
    event: FormEvent<HTMLDivElement>,
    option?: IDropdownOption | undefined,
    index?: number | undefined
  ): void {
    if (option) {
      // this.setState(
      //   {
      //     referentSearch: option.selected
      //     ? [...this.state.referentSearch, option.key as string]
      //     : this.state.referentSearch.filter((key) => key !== option.key),
      //     isLoading: true
      //   },
      //   async ()=>
      //   {
      //     await this.loadBriefsAsync()
      //   }
      // );
    }
  }

  private onChangeStatus(
    event: FormEvent<HTMLDivElement>,
    option?: IDropdownOption | undefined,
    index?: number | undefined
  ): void {
    if (option) {
      const currentValue = option.key === "all" ? "" : (option.key as string);
      this.setState(
        {
          statusSearch: currentValue,
          isLoading: true,
        },
        async () => {
          await this.loadBriefsAsync(false);
        }
      );
    }
  }

  private onChangeReferent(
    event: FormEvent<HTMLDivElement>,
    option?: IDropdownOption | undefined,
    index?: number | undefined
  ): void {
    if (option) {
      const currentValue = option.key === "all" ? "" : (option.key as string);
      this.setState(
        {
          referentSearch: currentValue,
          isLoading: true,
        },
        async () => {
          await this.loadBriefsAsync(false);
        }
      );
    }
  }

  private onChangeApplicantUnit(
    event: FormEvent<HTMLDivElement>,
    option?: IDropdownOption | undefined,
    index?: number | undefined
  ): void {
    if (option) {
      const currentValue = option.key === "all" ? "" : (option.key as string);
      this.setState(
        {
          applicantUnitSearch: currentValue,
          isLoading: true,
        },
        async () => {
          await this.loadBriefsAsync(false);
        }
      );
    }
  }

  private getColumnsList(): IColumn[] {
    return [
      this.toColumnNotification(),
      this.toColumnTitle(),
      this.toColumnStatus(),
      this.toColumnReferent(),
      this.toColumnApplicantUnit(),
      this.toColumnDate(),
      this.toColumnMenu(),
    ];
  }

  private get canDownloadDocument(): boolean {
    const { permission } = this.state;
    //const canDownload = permission && permission.canDownloadBriefs ? true : false;
    const canDownload = true;
    return canDownload;
  }

  private toColumnMenu(): IColumn {
    return {
      key: "prop",
      name: "",
      fieldName: "prop",
      minWidth: 200,
      isResizable: true,
      styles: this._menuContextCellStyle,
      onRender: (item: IListBriefDetails) => {
        return (
          <div className={styles["DetailsCell"]}>
            {this.renderMenuContext(item)}
          </div>
        );
      },
    };
  }

  private _menuItems(id: number, hasAttachment: boolean): IContextualMenuItem[] {
    return [
      {
        key: "downloadPdf",
        text: "Download As PDF",
        disabled: false,
        onClick: () => this.onDownloadAsPdf(id),
      },
      {
        key: "downloadAll",
        text: "Download All",
        disabled: !hasAttachment,
        onClick: () => this.onDownloadAllAttachments(id),
      },
      {
        key: "downloadAttachments",
        text: "Download Attachments",
        disabled: !hasAttachment,
        onClick: () => this.onDownloadAttachments(id),
      },
      //{ key: "print", text: "Print", disabled: true },
    ];
  }

  private showToastAttachment() {
    this.props.commonProps.toastComponent?.showMessage(
      this._translation.info,
      this._translation.downloadAttachment,
      ToastNotificationType.INFO
    );
  }

  private buildFile(blob: Blob, fileName: string) {
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  }

  private onDownloadAsPdf(id: number): void {
    this.showToastAttachment();
    this.setState({}, async () => {
      try {
        const fileContent = await this.downloadAsPdf(id, "english");
        if (fileContent) {
          this.buildFile(fileContent, "brief_as_pdf.pdf");
        }
      } catch (err) {
      } finally {
      }
    });
  }

  private onDownloadAllAttachments(id: number): void {
    this.showToastAttachment();
    this.setState({}, async () => {
      try {
        const fileContent = await this.downloadAllAttachments(id, "english");
        if (fileContent) {
          this.buildFile(fileContent, "brief_all_attachments.zip");
        }
      } catch (err) {
      } finally {
      }
    });
  }

  private onDownloadAttachments(id: number): void {
    this.showToastAttachment();
    this.setState({}, async () => {
      try {
        const fileContent = await this.downloadAttachments(id);
        if (fileContent) {
          this.buildFile(fileContent, "brief_attachments.zip");
        }
      } catch (err) {
      } finally {
      }
    });
  }

  private async downloadAllAttachments(
    id: number,
    language: string
  ): Promise<Blob | undefined> {
    const file = await this._briefClient.downloadBriefAllAttachmentAsync(
      id,
      "english"
    );
    return file;
  }

  private async downloadAsPdf(
    id: number,
    language: string
  ): Promise<Blob | undefined> {
    const file = await this._briefClient.downloadBriefAsPdfAsync(id, "english");
    return file;
  }

  private async downloadAttachments(id: number): Promise<Blob | undefined> {
    const file = await this._briefClient.downloadAttachmentsAsync(id);
    return file;
  }

  private _menuContextual(id: number, hasAttachment: boolean): IContextualMenuProps {
    return { items: this._menuItems(id, hasAttachment) };
  }

  private renderMenuContext(item: IListBriefDetails): any {
    return (
      <div>
        <CommandBarButton
          role="menuitem"
          aria-label="More items"
          // styles={onRenderOverflowButtonStyles}
          menuIconProps={{ iconName: "MoreVertical" }}
          menuProps={this._menuContextual(item.id, item.attachmentsCount && item.attachmentsCount > 0 ? true : false)}
          styles={this._menuContextStyle}
        // onClick={this.onClickMenuItem}
        />
      </div>
    );
  }

  private getIconNameByFileExtension(ext: string): string {
    const DEFAULT_ICONNAME = "FileTemplate";
    const sExt = ext.startsWith(".") ? ext.substring(1) : ext;
    const mappedFileTypes = DeliverableFileTypes.filter((t) =>
      t.associatedExtensions.some((t) => t.toLowerCase() === sExt.toLowerCase())
    );
    if (mappedFileTypes && mappedFileTypes.length > 0) {
      return mappedFileTypes[0].iconName;
    } else {
      return DEFAULT_ICONNAME;
    }
  }

  private toColumnReferent(): IColumn {
    return {
      key: "referent",
      name: this._translation.referent,
      fieldName: "referent",
      minWidth: 200,
      isResizable: true,
      onColumnClick: this._onColumnClick,
      onRender: (item: IListBriefDetails) => {
        return (
          <div
            className={styles["DetailsCell"]}
            onClick={() => {
              this.onRowClick(
                item.id,
                item.title,
                this.deletePermissionByStatus(item.status)
              );
            }}
          >
            {item.referent}
          </div>
        );
      },
    };
  }

  private deletePermissionByStatus(status: string) {
    return status.toLowerCase() === "draft" ? true : false;
  }

  private toColumnApplicantUnit(): IColumn {
    return {
      key: "applicantUnit",
      name: this._translation.applicationUnit,
      fieldName: "applicantUnit",
      minWidth: 200,
      isResizable: true,
      onColumnClick: this._onColumnClick,
      onRender: (item: IListBriefDetails) => {
        return (
          <div
            className={styles["DetailsCell"]}
            onClick={() => {
              this.onRowClick(
                item.id,
                item.title,
                this.deletePermissionByStatus(item.status)
              );
            }}
          >
            {item.applicantUnit}
          </div>
        );
      },
    };
  }

  private toColumnDate(): IColumn {
    return {
      key: "date",
      name: "Date",
      fieldName: "date",
      minWidth: 100,
      isResizable: true,
      onColumnClick: this._onColumnClick,
      onRender: (item: IListBriefDetails) => {
        return (
          <div
            className={styles.DetailsCell}
            onClick={() => {
              this.onRowClick(
                item.id,
                item.title,
                this.deletePermissionByStatus(item.status)
              );
            }}
          >
            {item.createdOn && Utility.formatDate(item.createdOn, "DD/MM/YYYY")}
          </div>
        );
      },
    };
  }

  private toColumnStatus(): IColumn {
    return {
      key: "status",
      name: this._translation.status,
      fieldName: "status",
      minWidth: 100,
      isResizable: true,
      onColumnClick: this._onColumnClick,
      onRender: (item: IListBriefDetails) => {
        return (
          <div
            className={styles["DetailsCell"]}
            onClick={() => {
              this.onRowClick(
                item.id,
                item.title,
                this.deletePermissionByStatus(item.status)
              );
            }}
          >
            <span
              status-type={item.status.toLowerCase()}
              className={styles["status"]}
            >
              {item.status}
            </span>
          </div>
        );
      },
    };
  }

  private toFileExtensionFormatted(fileType?: string): string {
    let sFileExtensions = "...";
    if (fileType) {
      sFileExtensions = fileType.startsWith(".", 0) ? fileType : "." + fileType;
    }

    return sFileExtensions;
  }

  private _onRenderActivityDetailsHeader(ev: any, obj: any) {
    return <>TITOLO</>;
  }

  private toColumnTitle(): IColumn {
    return {
      key: "title",
      name: this._translation.title,
      fieldName: "title",
      minWidth: 200,
      isResizable: true,
      onColumnClick: this._onColumnClick,
      onRender: (item: IListBriefDetails) => {
        return (
          <div
            className={styles["DetailsCell--title"]}
            onClick={() => {
              this.onRowClick(
                item.id,
                item.title,
                this.deletePermissionByStatus(item.status)
              );
            }}
          >
            {item.title}
          </div>
        );
      },
    };
  }

  private async refreshList() {
    this.setState(
      {
        isLoading: true,
      },
      async () => {
        await this.loadBriefsAsync(false);
      }
    );

  }

  private toColumnNotification(): IColumn {
    return {
      key: "notification",
      name: "",
      fieldName: "notification",
      minWidth: 24,
      maxWidth: 24,
      isResizable: false,
      onColumnClick: async () => { await this.refreshList() },
      onRender: (item: IListBriefDetails) => {
        return (
          <div>
            <CellNotification
              key={item.id}
              id={item.id}
              commonProps={this.props.commonProps}
              imageClassName={styles.notification}
            ></CellNotification>
          </div>
        );
      },
    };
  }

  private onRowClick(idBrief: number, title: string, canDelete: boolean) {
    this.setState({
      idBrief: idBrief,
      briefTitle: title,
      showBriefDetail: true,
      showDeleteButton: canDelete,
    });
  }

  private get activityBriefSearch() {
    return this.state.referentSearch.length === 1
      ? this.state.referentSearch[0]
      : "";
  }

  componentDidUpdate(prevProps: IListBriefProps) {
    if (prevProps.commonProps.onRoute !== this.props.commonProps.onRoute) {
      this.buildRouteObserver();
    }

    if (
      prevProps.commonProps.onNotification !==
      this.props.commonProps.onNotification
    ) {
      this.buildNotificationObserver();
    }
  }

  componentWillMount() {
    this.buildRouteObserver();
    this.buildNotificationObserver();
  }

  private buildNotificationObserver() {
    const observer: Observer<INotification> = {
      next: async (value: INotification) => {
        this.updateListItems(
          value.id,
          value.name,
          value.additionalInfo?.referent,
          value.additionalInfo?.status,
          value.additionalInfo?.applicacantUnit,
          value.additionalInfo?.newdate
        );

        this.clearRoute();
      },
      error: (err) => console.error("Observer got an error: " + err),
      complete: () => console.log("Observer got a complete notification"),
    };

    const filterObs = this.props.commonProps.onNotification?.pipe(
      filter(
        (item) => item.subType === NotificationSubType.internalbriefchanged
      )
    );
    this._notificationSubscription = filterObs?.subscribe(observer);
  }

  private updateListItems(
    id: number,
    title: string,
    referent?: string,
    status?: string,
    applicantUnit?: string,
    newdate?: Date
  ) {
    const { filteredItems } = this.state;

    if (filteredItems && filteredItems.some((t) => t.id === id)) {
      const currentItem = filteredItems.filter((t) => t.id === id)[0];
      currentItem.applicantUnit = applicantUnit
        ? applicantUnit
        : currentItem.applicantUnit;
      currentItem.title = title;
      currentItem.referent = referent ? referent : currentItem.referent;
      currentItem.status = status ? status : currentItem.status;
      currentItem.date = newdate ? newdate : currentItem.date;
      const newItems = [...filteredItems];

      this.setState({
        filteredItems: newItems,
      });
    }
  }

  private buildRouteObserver() {
    const observer: Observer<IRoute> = {
      next: (route: IRoute) => {
        if (route.additionalInfo.keysValues.id) {
          const id = Number.parseInt(route.additionalInfo.keysValues.id);
          const title = route.additionalInfo.keysValues.title
            ? route.additionalInfo.keysValues.title
            : "-";
          this.onRowClick(id, title, false);
        }

        if (route.additionalInfo.optionalValues?.status) {
          const status = route.additionalInfo.optionalValues?.status;
          const items = this._getItems({
            title: "",
            status: status,
            referent: "",
            applicationUnit: "",
            fromDate: undefined,
            toDate: undefined,
            startIndex: 0,
          }).then((items) => {
            this.setState({
              filteredItems: items.briefs,
              permission: items.permission,
              statusSearch: status,
            });
          });
        }

        this.clearRoute();
      },
      error: (err) => console.error("Observer got an error: " + err),
      complete: () => console.log("Observer got a complete notification"),
    };

    const observableRouting = this.props.commonProps.onRoute?.pipe(
      filteroperator((route) => route.page === Page.MyBrief)
    );

    this._routingObserver = observableRouting?.subscribe(observer);
  }

  public async componentDidMount() {
    this.loadApplicant();

    const filter = this.toIBriefFilter(false);
    const result = await this._getItems(filter);

    if (result) {
      this.setState(
        {
          filteredItems: result.briefs,
          permission: result.permission,
          showItemIndexInView: false,
          isCompactMode: false,
          columns: this._columns,
          isLoading: false,
          lastCount: result.briefs?.length,
          idSearch: filter.id,
        },
        () => {
          if (
            filter.id &&
            result &&
            result.briefs &&
            result.briefs.length === 1 &&
            result.briefs[0].title
          ) {
            this.onRowClick(filter.id, result.briefs[0].title, false);
          }
        }
      );

      let options = {
        root: null,
        rootMargin: "0px",
        threshold: 1.0,
      };

      this._observer = new IntersectionObserver(
        this._handleObserver.bind(this),
        options
      );
      let obs = document.querySelector("." + styles.divInfiniteLoader);
      if (obs) this._observer.observe(obs);
    }

    if (filter.id) {
      this._urlParams.delete("page");
      this._urlParams.delete("IdBrief");
    }
  }

  private async loadApplicant(): Promise<void> {
    try {
      const applicant = await this._briefClient.getApplicant();

      if (applicant) {
        const referentOptions = applicant.referents.map((t) => {
          return {
            key: t.userPrincipalName,
            text: t.displayName,
          } as IDropdownOption;
        });
        const applicantUnitOptions = applicant.applicantUnits.map((t) => {
          return { key: t, text: t } as IDropdownOption;
        });

        referentOptions.unshift(this._allOption);
        applicantUnitOptions.unshift(this._allOption);

        this.setState({
          referentOptions: referentOptions,
          applicantUnitOptions: applicantUnitOptions,
        });
      }
    } catch (err) {
      console.error(err);
    }
  }

  public componentWillUnmount() {
    if (this.state.showItemIndexInView) {
      const itemIndexInView = this._root.current!.getStartItemIndexInView();
    }

    if (this._routingObserver) {
      this._routingObserver.unsubscribe();
    }

    if (this._notificationSubscription) {
      this._notificationSubscription.unsubscribe();
    }
  }

  private _handleObserver(entities: any, observer: any) {
    const y = entities[0];
    if (y.isIntersecting) {
      this._getItems(this.toIBriefFilter(true)).then((newItems) => {
        this.setState({
          filteredItems: this.state.filteredItems
            ? this.state.filteredItems.concat(newItems.briefs)
            : newItems.briefs,
          lastCount: this.state.filteredItems
            ? this.state.filteredItems.length + newItems.briefs.length
            : newItems.briefs.length,
          contextualMenuProps: undefined,
        });
      });
    }
  }

  private _onRenderDetailsHeader(
    props?: IDetailsHeaderProps,
    _defaultRender?: IRenderFunction<IDetailsHeaderProps>
  ) {
    const { columns } = this.state;
    let isSorted: boolean = false;
    columns.forEach((newCol: IColumn) => {
      if (newCol.isSorted) isSorted = true;
    });

    return (
      <div>
        {isSorted ||
          this.state.titleSearch ||
          (this.state.statusSearch !== "" &&
            this.state.statusSearch.toLowerCase() !== "all") ||
          this.state.idSearch ||
          (this.state.referentSearch !== "" &&
            this.state.referentSearch.toLowerCase() !== "all") ||
          (this.state.applicantUnitSearch !== "" &&
            this.state.applicantUnitSearch.toLowerCase() !== "all") ||
          this.state.dateRangeSearch ? (
          <>
            <div className={styles.divResetFilter}>
              <DefaultButton
                text={this._translation.resetFilters}
                iconProps={{ iconName: "ClearFilter" }}
                className="btn btn-dismiss"
                onClick={this._resetFilter}
              />
            </div>
          </>
        ) : (
          <div></div>
        )}
        <DetailsHeader {...props} layoutMode={0} />
      </div>
    );
  }

  public render() {
    const {
      filteredItems,
      isCompactMode,
      contextualMenuProps,
      briefContextualMenuProps,
    } = this.state;

    this._columns = this._getColumns();
    const { permission } = this.state;
    // const hasViewPermission: boolean = permission && permission.visibility ? true : false;
    const hasViewPermission: boolean = true;

    return (
      <div>
        <LoaderComponent
          commonProps={this.props.commonProps}
          isLoading={this.state.isLoading}
          label={this._translation.loadingMessageByKey("loadingBriefs")}
        />

        {hasViewPermission && (
          <DetailsList
            componentRef={this._root}
            items={filteredItems}
            selectionMode={SelectionMode.none}
            //groups={groups}
            className={styles.listBriefs}
            columns={this._columns}
            onRenderDetailsHeader={this._onRenderDetailsHeader}
            // groupProps={{
            //   showEmptyGroups: true,
            // }}
            onRenderItemColumn={this._onRenderColumn}
            compact={isCompactMode}
            onRenderRow={this._onRenderRow}
          />
        )}
        {contextualMenuProps && <ContextualMenu {...contextualMenuProps} />}

        {briefContextualMenuProps && (
          <ContextualMenu {...briefContextualMenuProps} />
        )}

        <div className={styles.divInfiniteLoader}></div>

        <PanelBriefDetail
          commonProps={this.props.commonProps}
          visible={this.state.showBriefDetail}
          title={this.state.briefTitle}
          id={this.state.idBrief}
          onDismiss={this.onDismiss}
          onSave={this.onSaveBrief}
          onChangeStatus={this.onChangeBriefStatus}
          onDelete={this.onDelete}
          onCancel={this.onDismiss}
          showDeleteButton={this.state.showDeleteButton}
        ></PanelBriefDetail>
      </div>
    );
  }

  private renderHistoryPanelHeader(headerText: string): JSX.Element {
    return <div>{headerText}</div>;
  }

  private _resetFilter() {
    const { columns } = this.state;
    const newColumns: IColumn[] = columns.slice();
    newColumns.forEach((newCol: IColumn) => {
      newCol.isSorted = false;
      newCol.isSortedDescending = true;
    });

    this.setState(
      {
        titleSearch: "",
        referentSearch: "",
        applicantUnitSearch: "",
        statusSearch: "",
        dateRangeSearch: undefined,
        isLoading: true,
      },
      async () => await this.loadBriefsAsync(false)
    );
  }

  private _onRenderColumn(
    item?: IListBriefDetails,
    index?: number,
    column?: IColumn
  ) {
    const value =
      item && column && column.fieldName
        ? item[column.fieldName as keyof IListBriefDetails] || ""
        : "";

    return (
      <div data-is-focusable={true}>
        <b>{value}</b>
      </div>
    );
  }

  private _onRenderRow: IDetailsListProps["onRenderRow"] = (props) => {
    let result: any = null;
    if (props) {
      result = (
        <DetailsRow
          {...props}
          key={"Brief" + props.item.id}
          className={styles.BriefsRow + " ms-MainRow"}
        />
      );
    }
    return result;
  };

  private _onColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn
  ): void => {
    if (column.columnActionsMode !== ColumnActionsMode.disabled) {
      this._getContextualMenuProps(ev, column).then((contextMenuProps) => {
        this.setState({
          contextualMenuProps: contextMenuProps,
        });
      });
    }
  };

  private _onContextualMenuDismissed = (ev: any, dp: any): void => {
    this.setState({
      contextualMenuProps: undefined,
    });
  };

  private renderColumnHeader(text: string): JSX.Element {
    return <span className={styles["detailcell-column-title"]}>{text}</span>;
  }

  private _getColumns(): IColumn[] {
    const notification: any = <span><FontIcon
      iconName="Refresh"
      className={styles.refreshButton}
    ></FontIcon></span>;

    let title = this.state.titleSearch
      ? ((
        <div>
          {this.renderColumnHeader(this._translation.title)}{" "}
          <FontIcon
            iconName="Filter"
            className={styles.iconFilter}
          ></FontIcon>
        </div>
      ) as any)
      : this.renderColumnHeader(this._translation.title);
    let status =
      this.state.statusSearch && this.state.statusSearch.length > 0
        ? ((
          <div>
            {this.renderColumnHeader(this._translation.status)}
            <FontIcon
              iconName="Filter"
              className={styles.iconFilter}
            ></FontIcon>
          </div>
        ) as any)
        : this.renderColumnHeader(this._translation.status);
    let referent =
      this.state.referentSearch && this.state.referentSearch.length > 0
        ? ((
          <div>
            {this.renderColumnHeader(this._translation.referent)}{" "}
            <FontIcon
              iconName="Filter"
              className={styles.iconFilter}
            ></FontIcon>
          </div>
        ) as any)
        : this.renderColumnHeader(this._translation.referent);
    let date = this.state.dateRangeSearch
      ? ((
        <div>
          {this.renderColumnHeader(this._translation.dateOnColumn)}{" "}
          <FontIcon
            iconName="Filter"
            className={styles.iconFilter}
          ></FontIcon>
        </div>
      ) as any)
      : this.renderColumnHeader(this._translation.dateOnColumn);
    let applicantUnit =
      this.state.referentSearch && this.state.referentSearch.length > 0
        ? ((
          <div>
            {this.renderColumnHeader(this._translation.applicationUnit)}{" "}
            <FontIcon
              iconName="Filter"
              className={styles.iconFilter}
            ></FontIcon>
          </div>
        ) as any)
        : this.renderColumnHeader(this._translation.applicationUnit);

    let columns: IColumn[] = Object.assign([], this.state.columns);
    columns[0].name = notification;
    columns[1].name = title;
    columns[2].name = status;
    columns[3].name = referent;
    columns[4].name = applicantUnit;
    columns[5].name = date;

    return columns;
  }

  private async loadBriefsAsync(isScrolling: boolean): Promise<void> {
    try {
      const items = await this._getItems(this.toIBriefFilter(isScrolling));

      this.setState({
        filteredItems: items.briefs,
        permission: items.permission,
      });
    } catch (err) {
      console.error(err);
      this.props.commonProps.toastComponent?.showMessage(
        this._translation.error,
        this._translation.error,
        ToastNotificationType.ERROR
      );
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  private toIBriefFilter(isScrolling: boolean): IBriefFilter {
    const {
      titleSearch,
      dateRangeSearch,
      applicantUnitSearch,
      referentSearch: referentSearch,
      statusSearch,
      filteredItems,
    } = this.state;
    const urlId = this._urlParams.get("IdBrief");
    const routeId =
      this.props.commonProps.additionalInfo &&
        this.props.commonProps.additionalInfo?.keysValues.id
        ? this.props.commonProps.additionalInfo?.keysValues.id
        : undefined;

    const id = urlId || routeId;
    const routeStatus = this.props.commonProps.additionalInfo?.optionalValues
      ?.status
      ? this.props.commonProps.additionalInfo?.optionalValues?.status
      : undefined;

    if (routeId || routeStatus) {
      this.clearRoute();
    }

    if (routeStatus) {
      this.setState({ statusSearch: routeStatus.toLowerCase() });
    }

    return {
      title: titleSearch,
      status: routeStatus ? routeStatus : statusSearch,
      applicationUnit: applicantUnitSearch,
      referent: referentSearch,
      fromDate: dateRangeSearch?.fromDate,
      toDate: dateRangeSearch?.toDate,
      startIndex: isScrolling ? filteredItems.length : 0,
      id: id ? Number.parseInt(id) : undefined,
    };
  }

  private clearRoute() {
    const route: IRoute = {
      page: Page.None,
      additionalInfo: {
        keysValues: {
          id: undefined,
          type: undefined,
          subtype: undefined,
          title: undefined,
          showMyActivities: undefined
        },
      },
    };

    this.props.commonProps.onRoute?.next(route);
  }

  private async _getItems(filter: IBriefFilter): Promise<IListBrief> {
    const emptyResult = {
      count: 0,
      briefs: [],
      permission: {
        visibility: false,
        canAdd: false,
        canDelete: false,
        canDownloadBriefs: false,
      },
    };
    const columns = filter.orderBy ? filter.orderBy : this.state.columns;
    const orderBy: (IOrderBy | undefined)[] = this.toOrderByClause(columns);

    if (!mock) {
      const items = await this._briefClient.getBriefs(filter, orderBy);
      if (items) {
        const retItems = items.briefs ? items : emptyResult;
        if (retItems.briefs.length === 0) {
          this.props.commonProps.toastComponent?.showMessage(
            this._translation.info,
            this._translation.noBriefFound,
            ToastNotificationType.INFO
          );
        }
        return retItems;
      } else {
        this.props.commonProps.toastComponent?.showMessage(
          this._translation.error,
          this._translation.loadingBriefError,
          ToastNotificationType.ERROR
        );
        return emptyResult;
      }
    } else {
      return emptyResult;
    }
  }

  private toOrderByClause(columns: IColumn[]): (IOrderBy | undefined)[] {
    return columns.map((column: IColumn) => {
      if (column.isSorted) {
        switch (column.fieldName) {
          case "title":
            return {
              field: "t",
              type: column.isSortedDescending ? "DESC" : "ASC",
            };
          case "status":
            return {
              field: "s",
              type: column.isSortedDescending ? "DESC" : "ASC",
            };
          case "referent":
            return {
              field: "r",
              type: column.isSortedDescending ? "DESC" : "ASC",
            };
          case "au":
            return {
              field: "applicantUnit",
              type: column.isSortedDescending ? "DESC" : "ASC",
            };
          case "date":
            return {
              field: "d",
              type: column.isSortedDescending ? "DESC" : "ASC",
            };
          default:
            return undefined;
        }
      }
    });
  }

  private async _getCount(filter: IBriefFilter): Promise<number> {
    const items = this._getItems(filter);

    return (await items).count;
  }

  private lastTitleSearch = 0;
  private delay = 500;
  private onChangeTitle(event?: React.ChangeEvent<HTMLInputElement> | undefined, newValue?: string | undefined) {
    if ((!newValue || newValue?.length > 2) && this.lastTitleSearch < (Date.now() - this.delay)) {
      this.lastTitleSearch = Date.now();
      this.searchByTitle(newValue);
    }
  }

  private searchByTitle(newValue?: string | undefined) {
    this.setState(
      {
        isLoading: true,
        titleSearch: newValue ? newValue : '',
      },
      async () => {
        await this.loadBriefsAsync(false);

        this.lastTitleSearch = Date.now();
      }
    );
  }

  private onSearchTitle(newValue: any): void {
    if (newValue && newValue.length >= 3) {
      this.searchByTitle(newValue)
    } else if (!newValue) {
      this.searchByTitle(newValue)
    }
  }

  private _renderContextMenuTitle(item: IListBriefDetails): any {
    return (
      <div className={styles.DetailsCell}>
        <SearchBox
          className={"SearchBox"}
          value={this.state.titleSearch}
          placeholder={
            this.state.titleSearch
              ? this.state.titleSearch +
              " (" +
              this.state.filteredItems.length +
              ")"
              : this._translation.title
          }
          onSearch={this.onSearchTitle}
          onChange={this.onChangeTitle}
        />
      </div>
    );
  }

  private _renderContextMenuReferent(item: IListBriefDetails): any {
    const currentItem =
      this.state.referentSearch === "" ? "all" : this.state.referentSearch;
    return (
      <div className={styles.filterContainerReferent}>
        <Dropdown
          className={"dropDownSearch"}
          defaultSelectedKey={currentItem}
          options={this.state.referentOptions}
          onRenderOption={onRenderOption}
          onChange={this.onChangeReferent}
        ></Dropdown>
      </div>
    );
  }

  private _renderContextMenuApplicantUnit(item: IListBriefDetails): any {
    const currentItem =
      this.state.applicantUnitSearch === ""
        ? "all"
        : this.state.applicantUnitSearch;

    return (
      <div className={styles.filterContainer}>
        <Dropdown
          className={"dropDownSearch"}
          defaultSelectedKey={currentItem}
          options={this.state.applicantUnitOptions}
          onRenderOption={onRenderOption}
          onChange={this.onChangeApplicantUnit}
        ></Dropdown>
      </div>
    );
  }

  private _renderContextMenuDate(item: IListBriefDetails): any {
    return (
      <div className={styles.DetailsCell}>
        <RangeCalendar
          commonProps={this.props.commonProps}
          showTime={false}
          disableTime={false}
          onChange={this._rangeCalendarChange}
          value={this.state.dateRangeSearch}
        ></RangeCalendar>
      </div>
    );
  }

  private _rangeCalendarChange(newValue?: IDateRangeValue | undefined) {
    if (newValue) {
      this.setState(
        {
          dateSearch: newValue.fromDate
            ? Utility.FormatDate(newValue.fromDate) +
            " to " +
            Utility.FormatDate(newValue.toDate)
            : "",
          dateRangeSearch: newValue,
          contextualMenuProps: undefined,
          isLoading: true,
        },
        async () => {
          await this.loadBriefsAsync(false);
        }
      );
    } else {
      this.setState(
        {
          isLoading: true,
          dateSearch: "",
          dateRangeSearch: undefined,
          contextualMenuProps: undefined,
        },
        async () => {
          await this.loadBriefsAsync(false);
        }
      );
    }
  }

  private async _getContextualMenuProps(
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn
  ): Promise<IContextualMenuProps> {
    let target = ev.currentTarget;

    let items: IContextualMenuItem[] =
      column.key === "type"
        ? []
        : [
          {
            key: "aToZ",
            name: "A to Z",
            iconProps: { iconName: "SortUp" },
            canCheck: true,
            checked: column.isSorted && !column.isSortedDescending,
            onClick: () => this._onSortColumn(column.key, false),
          },
          {
            key: "zToA",
            name: "Z to A",
            iconProps: { iconName: "SortDown" },
            canCheck: true,
            checked: column.isSorted && column.isSortedDescending,
            onClick: () => this._onSortColumn(column.key, true),
          },
          {
            key: "Divider",
            name: "Divider",
            canCheck: false,
            onRender: (item: IListBriefDetails) => {
              return (
                <div className={styles.DetailsCell}>
                  <hr />
                </div>
              );
            },
          },
        ];

    if (column.key === "title")
      items.push({
        key: "searchTitle",
        name: this._translation.title,
        onRender: (item: IListBriefDetails) =>
          this._renderContextMenuTitle(item),
      });
    else if (column.key === "status") {
      items.push({
        key: "status",
        name: "File types",
        onRender: (item: IListBriefDetails) =>
          this._renderContextMenuStatus(item),
      });
    } else if (column.key === "referent") {
      items.push({
        key: "referent",
        name: "Referent",
        onRender: (item: IListBriefDetails) =>
          this._renderContextMenuReferent(item),
      });
    } else if (column.key === "date") {
      items.push({
        key: "date",
        name: this._translation.selectDate,
        canCheck: false,
        target: "DPO",
        onRender: (item: IListBriefDetails) =>
          this._renderContextMenuDate(item),
      });
    } else if (column.key === "applicantUnit")
      items.push({
        key: "searchApplicabtUniy",
        name: "Search Applicant Uniy",
        onRender: (item: IListBriefDetails) =>
          this._renderContextMenuApplicantUnit(item),
      });

    return {
      items: items,
      target: target as HTMLElement,
      directionalHint: DirectionalHint.bottomLeftEdge,
      gapSpace: 10,
      isBeakVisible: false,
      onDismiss:
        column.key === "NO_DISMISS_SUPPORTED"
          ? undefined
          : this._onContextualMenuDismissed,
    };
  }

  onRenderOption2(option: IDropdownOption): JSX.Element {
    return (
      <div>
        {option.data && option.data.icon && (
          <Icon
            iconName={option.data.icon}
            aria-hidden="true"
            title={option.data.icon}
          />
        )}
        <span>{option.text}</span>
      </div>
    );
  }

  _renderContextMenuStatus(item: IListBriefDetails): React.ReactNode {
    const currentItem =
      this.state.statusSearch === "" ? "all" : this.state.statusSearch;
    return (
      <div className={styles.filterContainer}>
        <Dropdown
          className={"dropDownSearch"}
          defaultSelectedKey={currentItem}
          options={this._statusOptions}
          onRenderOption={onRenderOption}
          onChange={this.onChangeStatus}
        ></Dropdown>
      </div>
    );
  }

  private _onBriefContextualMenuDismissed = (ev: any, dp: any): void => {
    this.setState({
      briefContextualMenuProps: undefined,
    });
  };

  private _getBriefContextualMenuProps(
    ev: React.MouseEvent<HTMLElement>,
    filePath: string,
    fileDownload: string,
    fileName: string,
    iconName: string,
    deliverable: IListBriefDetails
  ): IContextualMenuProps {
    if (this.canDownloadDocument) {
      let items: IContextualMenuItem[] = [
        {
          key: "openBrief",
          name: this._translation.openInBrowser,
          iconProps: { iconName: iconName },
          canCheck: false,
          // checked: column.isSorted && !column.isSortedDescending,
          onClick: () => {
            if (filePath) {
              this.openFile(filePath, fileName);
            }
          },
          // href: filePath
        },
        {
          key: "downloadBrief",
          name: this._translation.download,
          iconProps: { iconName: "Download" },
          canCheck: false,
          // checked: column.isSorted && !column.isSortedDescending,
          onClick: () => {
            this.downloadFile(fileDownload, fileName);
            //todo
            // if (deliverable.idActivity && deliverable.fileName && deliverable.fileType)
            // {
            //   this.downloadFileByAPI(deliverable.idActivity, deliverable.id, deliverable.fileName,deliverable.fileType);
            // }
          },
          // href: filePath
        },
        {
          key: "historyMenu",
          // subMenuProps: {
          //   items: this.toHistoryMenuItems(deliverable),
          // },
          text: "getHistory",
          iconProps: { iconName: "FullHistory" },
          onClick: () => {
            this.openHistoryPanel(deliverable);
            //todo
            // if (deliverable.idActivity && deliverable.fileName && deliverable.fileType)
            // {
            //   this.downloadFileByAPI(deliverable.idActivity, deliverable.id, deliverable.fileName,deliverable.fileType);
            // }
          },
        },
        //todo, currently, it is not possible to print
        // {
        //   key: "printBrief",
        //   name: "Print",
        //   iconProps: { iconName: "Print" },
        //   canCheck: false,
        //   // checked: column.isSorted && !column.isSortedDescending,
        //   // onClick: () => this._deleteBrief(itemId, activityId),
        // },
      ];

      return {
        items: items,
        target: ev.currentTarget as HTMLElement,
        directionalHint: DirectionalHint.bottomLeftEdge,
        gapSpace: 10,
        isBeakVisible: false,
        onDismiss: this._onBriefContextualMenuDismissed,
      };
    } else {
      return {
        items: [],
        target: ev.currentTarget as HTMLElement,
        directionalHint: DirectionalHint.bottomLeftEdge,
        gapSpace: 10,
        isBeakVisible: false,
        onDismiss: this._onBriefContextualMenuDismissed,
      };
    }
  }
  openHistoryPanel(deliverable: IListBriefDetails) {
    this.setState({
      openHistoryBriefVersion: true,
      idBrief: deliverable.id,
    });
  }

  // private toHistoryMenuItems(deliverable: IBrief): IContextualMenuItem[]
  // {
  //   const values:IContextualMenuItem[] = deliverable.briefHistory
  //   .map((t)=> this.toHistoryMenuItem(t));
  //   return values;

  // }

  // private toHistoryMenuItem(brief: IBrief): IContextualMenuItem
  // {
  //   const value: IContextualMenuItem =
  //     { key: brief.fileName, text: brief.fileName, title: 'Download', onClick: ()=>{this.downloadFile(brief.fileDownload, brief.fileName);} };
  //     return value
  // }

  private _onSortColumn = (
    columnKey: string,
    isSortedDescending: boolean
  ): void => {
    const { columns, filteredItems } = this.state;
    const newColumns: IColumn[] = columns.slice();
    const currColumn: IColumn = newColumns.filter(
      (currCol) => columnKey === currCol.key
    )[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    this.setState(
      {
        columns: newColumns,
        lastCount: 0,
        contextualMenuProps: undefined,
        isLoading: true,
      },
      async () => await this.loadBriefsAsync(false)
    );

    // this._copyAndSort(
    //   filteredItems,
    //   currColumn.fieldName!,
    //   currColumn.isSortedDescending
    // );
  };

  private openFile(filePath: string, fileName: string) {
    this.createTemporaryHREF(filePath, "_blank");
  }

  private downloadFile(fileDownload: string, fileName: string) {
    this.createTemporaryHREF(fileDownload, "_self");
  }

  private createTemporaryHREF(href: string, target: string) {
    const link = document.createElement("a");
    link.target = target;
    link.href = href;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
}
