import PropTypes from 'prop-types'
import clsx from 'clsx'
import React from 'react'

const Header = ({ label, labelWeekDay }) => {
return <>
        <span className={clsx('rbc-header-day')} >{label}</span>
        <br/>
        <span className={clsx('rbc-header-weekDay')} >{labelWeekDay}</span>
      </>
}

Header.propTypes = {
  label: PropTypes.node,
}

export default Header
