import { TFunction } from "i18next";
import { CommonTranslation } from "./Common.Translation";

export class ResourcesTranslation extends CommonTranslation {
  constructor(translation: TFunction) {
    super(translation, "resources");
  }

  public addedResources(counter: number): string {
    if (counter == 1) {
      return super.getTextByMessageKey("oneNewResourceAssigned");
    } else {
      return this.getTextByMessageKey("multipleNewResourceAssigned", {
        num: counter,
      });
    }
  }

  public removedResources(counter: number): string {
    if (counter == 1) {
      return super.getTextByMessageKey("oneResourceRemoved");
    } else {
      return this.getTextByMessageKey("multipleResourceRemoved", {
        num: counter,
      });
    }
  }

  public get addResources(): string {
    return super.getTextByMessageKey("addResources");
  }

  public get selectedResources(): string {
    return super.getTextByMessageKey("selectedResources");
  }

  public get saveUpdates(): string {
    return super.getTextByMessageKey("saveUpdates");
  }

  public get sendInvitation(): string {
    return super.getTextByMessageKey("sendInvitation");
  }
  
  public get getUsersError(): string {
    return super.getTextByMessageKey("getUsersError");
  }
}
