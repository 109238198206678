import { HubConnection, HubConnectionBuilder, IHttpConnectionOptions, LogLevel } from '@microsoft/signalr';
import { AuthResponse } from 'msal';
import { INotification, IRealTimeNotification, NotificationSubType } from '../Models/Notification';
import { msalAuth } from '../msal/MsalAuthProvider';
import { tokenRequest } from '../msal/MsalConfig';

export class NotificationClient
{
    private readonly _reconnectionRetryInterval = 5000;
    private readonly _urlHub: string;
    private readonly _connection: HubConnection;
    public onNewNotification?: (value: INotification) => void
    public test?: number;
    private _connected = false;    

    constructor(urlHub: string)
    {
        this._urlHub = urlHub;

        //connect to signalr service endpoint
        this._connection = this.setConnection(urlHub);

        this.startConnection();     
    }

    private toNotificationSubType(value: string): NotificationSubType
    {        
        const lowerValue = value.toLowerCase();
        const retValue = lowerValue as keyof typeof NotificationSubType;
        return NotificationSubType[retValue];
    }

    private pushNotification(value: IRealTimeNotification, onNewNotification?: (message: INotification)=> void)
    {
        console.debug("New notification arrived", value);
        if (onNewNotification)
        {            
            const notification: INotification = {...value, subType:  
                this.toNotificationSubType(value.subType)}
            
            return onNewNotification(notification);
        }
    }

    private async startConnection():Promise<void>
    {
        if (this._connection && !this._connected)
        {
            try
            {                

                console.debug('Try connected to SignalR service');
                this._connection.start()
                    .then(()=> 
                        {                            
                            this._connected = true;
                            console.debug(`SignalR client connected. ConnectionID ${this._connection.connectionId}`);

                            this._connection.on("Notification", (value:IRealTimeNotification) => this.pushNotification(value, this.onNewNotification));                
                        }
                    )
                    .catch((t:any)=>
                        {
                            this._connected = false;
                            console.debug(`Error on SignalR connection`);
                            //retry policy
                            const timeout = setTimeout(async ()=>
                            {
                                this.startConnection();
                            }, this._reconnectionRetryInterval);                                                            
                        });
            }
            catch (err)
            {
                console.error("SignalR client connection error", err);
            }            
        }
    }

    /**
     * Returns the security access token
     */
    private async getAccessToken(): Promise<string> {
        let accessToken: AuthResponse;

        try 
        {
            accessToken = await msalAuth.acquireTokenSilent(tokenRequest);
        } 
        catch (error) 
        {
            console.log("AquireTokenSilent failure");
            accessToken = await msalAuth.acquireTokenPopup(tokenRequest);
        }

        return accessToken.accessToken;
    }

    private get options(): IHttpConnectionOptions
    {
        const options: IHttpConnectionOptions = { logger: LogLevel.Trace, accessTokenFactory: async () => this.getAccessToken()};

        return options;
    }

    private setConnection(url: string)
    {
        try
        {
            const connection: HubConnection = new HubConnectionBuilder()
                .withUrl(url, this.options)
                .withAutomaticReconnect()
                .build();            
            
            connection.onreconnecting((err: any)=> {
                console.error(err);
                this._connected = true;
            });

            return connection;
        }
        catch (err)
        {
            console.error(err);
            throw err;
        }
    }
}


// export interface INotification
// {
//     id: number;
//     title: string;
//     type: NotificationType;
//     subType: NotificationSubType;
//     author: User;
//     date: Date;
//     identifier: string
// }

// export enum NotificationType
// {
//     Brief = 0,
//     Message = 1,
//     Media = 1
// }

// export enum NotificationSubType
// {
//     GenericMessage = 0,
//     BriefApprovation = 1,
//     MediaAssigned = 2,
//     MediaApprovation = 3,
//     MediaRework = 4,
//     MediaApproved = 5
// }