import * as React from "react";

import "bootstrap/dist/css/bootstrap.css";

import "@fluentui/react/dist/css/fabric.min.css";

import { initializeIcons } from "@uifabric/icons";

import { Utility } from "../../../Utility/Utility";
import { ActivityTranslation } from "../../../Translations/Activity.Translation";

import styles from "./MicroActivityViewComponent.module.scss";

import { IColumn, PersonaSize } from "@fluentui/react";

import { PanelActivityHeaderComponent } from "../PanelActivityHeaderComponent/PanelActivityHeaderComponent";
import { IActivityAllAttributes, IMicro } from "../../../Models/Activity";

import { User } from "../../../Models/User";
import { ManageResourcesComponent } from "../ManageResourcesComponent/ManageResourcesComponent";
import { IBaseProperties } from "../../../Models/IBaseProperties";
import { IActivityClient } from "../../../Clients/IActivityClient";
import { ToastNotificationType } from "../../../Models/ToastNote";
import { SpinnerComponent } from "../SpinnerComponent/SpinnerComponent";
import { ICalendar } from "../../../Models/ICalendar";
import { ICategory } from "../../../Models/ICategory";
import { IResponsibility } from "../../../Models/IResponsibility";
import { ISensibility } from "../../../Models/ISensibility";

export interface IMicroActivityViewComponentProps extends IBaseProperties {
  calendars: ICalendar[];
  item?: IMicro;
  itemId?: number;
}

export interface IMicroActivityViewComponentState {
  item?: IMicro;
  isLoading: boolean;
  categories: ICategory[];
  responsibilities: IResponsibility[];
  sensibilities: ISensibility[];
  assignedResourcesItems: User[];
  externalResourcesItems: User[];
}

export class MicroActivityViewComponent extends React.Component<
  IMicroActivityViewComponentProps,
  IMicroActivityViewComponentState
> {
  _taskColumns: IColumn[] = [];
  private readonly _translation: ActivityTranslation;
  private readonly _activityClient: IActivityClient;

  constructor(props: IMicroActivityViewComponentProps) {
    super(props);

    initializeIcons();
    this._translation = new ActivityTranslation(
      this.props.commonProps.translation
    );
    this._activityClient = this.props.commonProps.clientCreator.createActivityClient();

    this.renderActivityModalHeader = this.renderActivityModalHeader.bind(this);

    this.state = {
      // item: this.props.item,
      categories: [],
      responsibilities: [],
      sensibilities: [],
      assignedResourcesItems: [],
      externalResourcesItems: [],
      isLoading: false,
    };
  }

  componentDidMount() {
    Promise.all([
      this._activityClient.getCategories(),
      this._activityClient.getResponsibilities(),
      this._activityClient.getSensibilities(),
    ]).then(([categories, responsibilities, sensibilities]) => {
      if (this.props.item) {
        this.setState({
          categories: categories ?? [],
          responsibilities: responsibilities ?? [],
          sensibilities: sensibilities ?? [],
          item: Object.assign({}, this.props.item),
          // item: response,
          assignedResourcesItems: this.props.item.resources
            ? this.props.item.resources
            : this.state.assignedResourcesItems,
          externalResourcesItems: this.props.item.externalResources
            ? this.props.item.externalResources
            : this.state.externalResourcesItems,
        });
      } else if (this.props.itemId) {
        this.setState({ isLoading: true });
        this._activityClient
          .getActivityDetails(this.props.itemId, false)
          .then((item) => {
            if (item) {
              this.setState({
                isLoading: false, // item: response,
                item: item,
                assignedResourcesItems: item.resources
                  ? item.resources
                  : this.state.assignedResourcesItems,
                externalResourcesItems: item.externalResources
                  ? item.externalResources
                  : this.state.externalResourcesItems,
              });
            } else {
              this.props.commonProps.toastComponent?.showMessage(
                this._translation.error,
                this._translation.getActivityError,
                ToastNotificationType.ERROR
              );
              this.setState({
                isLoading: false, // item: response,
              });
            }
          });
      }
    });
  }

  private renderActivityModalHeader() {
    return (
      <PanelActivityHeaderComponent
        commonProps={this.props.commonProps}
        item={
          {
            ...this.state.item,
            isMacro: false,
          } as IActivityAllAttributes
        }
        isMacro={false}
        showContextMenu={false}
        showStatus={false}
        showType={true}
        showManage={false}
      ></PanelActivityHeaderComponent>
    );
  }

  private _decodeHTMLEntities(text: string) {
    var span = document.createElement("span");
    span.innerHTML = text;
    return span.textContent || span.innerText;
  }

  public render() {
    return this.state.isLoading ? (
      <SpinnerComponent
        commonProps={this.props.commonProps}
        relative={false}
      ></SpinnerComponent>
    ) : (
      <div role="microActivityDetailsInfo" className={styles.MicroActivityViewComponent}>
        <div className={styles.activity_details_parent_body + " row"}>
          <div className={"ms-Grid-col ms-lg4 ms-md6 ms-sm12"}>
            <div className={styles.label_ActivityAttribute + " ms-Grid-col"}>
              {this._translation.activityCalendar}
            </div>
            <div className={styles.value_ActivityAttribute + " ms-Grid-col"}>
              {
                this.props.calendars.find(
                  (c) => c.key === this.props.item?.calendar
                )?.value
              }
            </div>
          </div>

          <div className={"ms-Grid-col ms-lg4 ms-md6 ms-sm12"}>
            <div className={styles.label_ActivityAttribute + " ms-Grid-col"}>
              {this._translation.createdBy}
            </div>
            <div className={styles.value_ActivityAttribute + " ms-Grid-col"}>
              {this.props.item?.createdBy?.displayName}
            </div>
          </div>

          <div className={"ms-Grid-col ms-lg4 ms-md6 ms-sm12"}>
            <div className={styles.label_ActivityAttribute + " ms-Grid-col"}>
              {this._translation.statusHeaderText}
            </div>
            <div className={styles.value_ActivityAttribute + " ms-Grid-col"}>
              <div
                className={
                  styles.rbc_event_dot +
                  " " +
                  Utility.GetActivityClassNameFromStatus(
                    this.state.item?.status,
                    true
                  )
                }
                // alt={item.fileType + " file icon"}
              />
              <span className="optionDropdownStatusChange">
                {this.state.item?.status}
              </span>
            </div>
          </div>

          <div className={"ms-Grid-col ms-lg4 ms-md6 ms-sm12"}>
            <div className={styles.label_ActivityAttribute + " ms-Grid-col"}>
              {/* {this._translation.dueTo} */}
              {this._translation.start} - {this._translation.end}
            </div>
            <div className={styles.value_ActivityAttribute + " ms-Grid-col"}>
              {this.state.item?.dateTo
                ? Utility.FormatDateAndTime(
                    new Date(this.state.item?.dateFrom)
                  ) +
                  " - " +
                  Utility.FormatDateAndTime(new Date(this.state.item?.dateTo))
                : "---"}{" "}
            </div>
          </div>
          <div className={"ms-Grid-col ms-lg4 ms-md6 ms-sm12"}>
            <div className={styles.label_ActivityAttribute + " ms-Grid-col"}>
              {this._translation.spoc}
            </div>
            <div className={styles.value_ActivityAttribute + " ms-Grid-col"}>
              {this.state.item?.spoc?.displayName}
            </div>
          </div>
          <div className={"ms-Grid-col ms-lg4 ms-md6 ms-sm12"}>&nbsp;</div>
          <div className={"ms-Grid-col ms-lg4 ms-md6 ms-sm12"}>
            <div className={styles.label_ActivityAttribute + " ms-Grid-col"}>
              {this._translation.activityCategory.toUpperCase()}
            </div>
            <div className={styles.value_ActivityAttribute + " ms-Grid-col"}>
              {this.state.item?.categories.length == 0
                ? this._translation.selectCategory
                : this.state.categories.filter(x => this.state.item?.categories.includes(+x.key)).map(x => x.value).join(" - ")}
            </div>
          </div>
          <div className={"ms-Grid-col ms-lg4 ms-md6 ms-sm12"}>
            <div className={styles.label_ActivityAttribute + " ms-Grid-col"}>
              {this._translation.activityResponsibility.toUpperCase()}
            </div>
            <div className={styles.value_ActivityAttribute + " ms-Grid-col"}>
              {this.state.item?.responsibilities.length == 0
                ? this._translation.selectResponsibility
                : this.state.responsibilities.filter(x => this.state.item?.responsibilities.includes(+x.key)).map(x => x.value).join(" - ")}
            </div>
          </div>
          <div className={"ms-Grid-col ms-lg4 ms-md6 ms-sm12"}>
            <div className={styles.label_ActivityAttribute + " ms-Grid-col"}>
              {this._translation.activitySensibility.toUpperCase()}
            </div>
            <div className={styles.value_ActivityAttribute + " ms-Grid-col"}>
              {this.state.item?.sensibility
                ? this.state.sensibilities.filter(x => x.key == this.state.item?.sensibility).map(x => x.value).join("")
                : this._translation.selectSensibility}
            </div>
          </div>
          <div className={"ms-Grid-col ms-lg4 ms-md6 ms-sm12"}>
            <div className={styles.label_ActivityAttribute + " ms-Grid-col"}>
              {this._translation.assignedResources}
            </div>
            <div className={styles.value_ActivityAttribute + " ms-Grid-col"}>
              <div className={styles.assignedResources}>
                <ManageResourcesComponent
                  commonProps={this.props.commonProps}
                  // allResources={this.state.allResources}
                  listResources={this.state.assignedResourcesItems}
                  onRenderModalHeader={this.renderActivityModalHeader}
                  saveFunction={() => {}}
                  maxLength={3}
                  personaSize={PersonaSize.size40}
                  userType={"internalResource"}
                  edit={false}
                  calendar={this.props.item?.calendar || -1}
                />
              </div>
            </div>
          </div>

          <div className={"ms-Grid-col ms-lg4 ms-md6 ms-sm12"}>
            <div className={styles.label_ActivityAttribute + " ms-Grid-col"}>
              {this._translation.externalResources}
            </div>
            <div className={styles.value_ActivityAttribute + " ms-Grid-col"}>
              <div className={styles.assignedResources}>
                <ManageResourcesComponent
                  commonProps={this.props.commonProps}
                  // allResources={this.state.allResources}
                  listResources={this.state.externalResourcesItems}
                  onRenderModalHeader={this.renderActivityModalHeader}
                  saveFunction={() => {}}
                  maxLength={3}
                  personaSize={PersonaSize.size40}
                  userType={"externalResource"}
                  edit={false}
                  calendar={this.props.item?.calendar || -1}
                />
              </div>
            </div>
          </div>

          <div className={"ms-Grid-col ms-sm12"}>
            <hr />
          </div>
          <div className={"ms-Grid-col ms-sm12"}>
            <div
              className={
                styles.label_ActivityAttribute + " ms-Grid-col ms-sm12"
              }
            >
              {this._translation.description}
            </div>
            <div
              className={
                styles.value_ActivityAttribute + " ms-Grid-col ms-sm12"
              }
              dangerouslySetInnerHTML={{
                __html:
                  this.state.item?.description &&
                  this._decodeHTMLEntities(this.state.item?.description)
                    ? this.state.item?.description //.replaceAll("\n", "<br />")
                    : "---",
              }}
            >
              {/* {this.state.item?.description} */}
            </div>
          </div>
        </div>
      </div>
    );

    // return [
    //   <LoaderComponent
    //     commonProps={this.props.commonProps}
    //     isLoading={this.state.isLoading}
    //   />,
    //   <div className={styles.MicroActivityViewComponent}>
    //     <div className={styles.activity_details_parent_body + " row"}>
    //       <div className={"ms-Grid-col ms-lg4 ms-md6 ms-sm12"}>
    //         <div className={styles.label_ActivityAttribute + " ms-Grid-col"}>
    //           {this._translation.statusHeaderText}
    //         </div>
    //         <div className={styles.value_ActivityAttribute + " ms-Grid-col"}>
    //           <div
    //             className={
    //               styles.rbc_event_dot +
    //               " " +
    //               Utility.GetActivityClassNameFromStatus(
    //                 this.state.item?.status,
    //                 true
    //               )
    //             }
    //             // alt={item.fileType + " file icon"}
    //           />
    //           <span className="optionDropdownStatusChange">
    //             {this.state.item?.status}
    //           </span>
    //         </div>
    //       </div>

    //       <div className={"ms-Grid-col ms-lg4 ms-md6 ms-sm12"}>
    //         <div className={styles.label_ActivityAttribute + " ms-Grid-col"}>
    //           {this._translation.dueTo}
    //         </div>
    //         <div className={styles.value_ActivityAttribute + " ms-Grid-col"}>
    //           {this.state.item?.dateTo
    //             ? Utility.FormatDate(new Date(this.state.item?.dateTo))
    //             : "---"}{" "}
    //         </div>
    //       </div>
    //       <div className={"ms-Grid-col ms-lg4 ms-md6 ms-sm12"}>
    //         <div className={styles.label_ActivityAttribute + " ms-Grid-col"}>
    //           {this._translation.spoc}
    //         </div>
    //         <div className={styles.value_ActivityAttribute + " ms-Grid-col"}>
    //           {this.state.item?.spoc?.displayName}
    //         </div>
    //       </div>
    //       <div className={"ms-Grid-col ms-lg4 ms-md6 ms-sm12"}>
    //         <div className={styles.label_ActivityAttribute + " ms-Grid-col"}>
    //           {this._translation.assignedResources}
    //         </div>
    //         <div className={styles.value_ActivityAttribute + " ms-Grid-col"}>
    //           <div className={styles.assignedResources}>
    //             <ManageResourcesComponent
    //               commonProps={this.props.commonProps}
    //               // allResources={this.state.allResources}
    //               listResources={this.state.assignedResourcesItems}
    //               onRenderModalHeader={this.renderActivityModalHeader}
    //               saveFunction={() => {}}
    //               maxLength={3}
    //               personaSize={PersonaSize.size40}
    //               userType={"internalResource"}
    //               edit={false}
    //             />
    //           </div>
    //         </div>
    //       </div>

    //       <div className={"ms-Grid-col ms-lg4 ms-md6 ms-sm12"}>
    //         <div className={styles.label_ActivityAttribute + " ms-Grid-col"}>
    //           {this._translation.externalResources}
    //         </div>
    //         <div className={styles.value_ActivityAttribute + " ms-Grid-col"}>
    //           <div className={styles.assignedResources}>
    //             <ManageResourcesComponent
    //               commonProps={this.props.commonProps}
    //               // allResources={this.state.allResources}
    //               listResources={this.state.externalResourcesItems}
    //               onRenderModalHeader={this.renderActivityModalHeader}
    //               saveFunction={() => {}}
    //               maxLength={3}
    //               personaSize={PersonaSize.size40}
    //               userType={"externalResource"}
    //               edit={false}
    //             />
    //           </div>
    //         </div>
    //       </div>

    //       <div className={"ms-Grid-col ms-sm12"}>
    //         <hr />
    //       </div>
    //       <div className={"ms-Grid-col ms-sm12"}>
    //         <div
    //           className={
    //             styles.label_ActivityAttribute + " ms-Grid-col ms-sm12"
    //           }
    //         >
    //           {this._translation.description}
    //         </div>
    //         <div
    //           className={
    //             styles.value_ActivityAttribute + " ms-Grid-col ms-sm12"
    //           }
    //         >
    //           {this.state.item?.description}
    //         </div>
    //       </div>
    //     </div>
    //   </div>,
    // ];
  }
}
