import * as React from "react";
import styles from "./SupportHomeComponent.module.scss";
import { FontIcon } from "@fluentui/react";
import "url-search-params-polyfill";
import { IBaseProperties } from "../../../../../../Models/IBaseProperties";
import { SupportPagesEnum } from "../../../../../../Models/SupportPage";
import { SupportTranslation } from "../../../../../../Translations/Support.Translation";

export interface ISupportHomeComponentProps extends IBaseProperties {
  // context: any;
  userGuidesVisibility: boolean;
  videoTutorialsVisibility: boolean;
  faqsVisibility: boolean;
  changePage: (p: SupportPagesEnum) => void;
}

export interface ISupportHomeComponentState {
  // persona: IPersonaComponentItem[];
}

export class SupportHomeComponent extends React.Component<
  ISupportHomeComponentProps,
  ISupportHomeComponentState
> {
  private readonly _translation: SupportTranslation;
  
  constructor(props: ISupportHomeComponentProps) {
    super(props);


    this._translation = new SupportTranslation(
      this.props.commonProps.translation
    );

    this.state = {};
  }

  public render() {
    return (
      <div>
        <div className={styles.HomeCards}>
          {this.props.userGuidesVisibility && (
            <div
              className={styles.HomeCard}
              onClick={(e) => this.props.changePage(SupportPagesEnum.UserGuide)}
            >
              <div className={styles.iconCardContainerSupportHome}>
                <FontIcon
                  iconName={"PDF"}
                  className={styles.iconCardSupportHome}
                />
              </div>
              <div className={styles.lblCardContainerSupportHome}>
                <div className={styles.lblCardSupportHome}>
                  {this._translation.headerUserGuides}
                </div>
              </div>
            </div>
          )}
          {this.props.videoTutorialsVisibility && (
            <div
              className={styles.HomeCard}
              onClick={(e) =>
                this.props.changePage(SupportPagesEnum.VideoTutorials)
              }
            >
              <div className={styles.iconCardContainerSupportHome}>
                <FontIcon
                  iconName={"MSNVideos"}
                  className={styles.iconCardSupportHome}
                />
              </div>
              <div className={styles.lblCardContainerSupportHome}>
                <div className={styles.lblCardSupportHome}>
                  {this._translation.headerVideoTutorials}
                </div>
              </div>
            </div>
          )}
          {this.props.faqsVisibility && (
            <div
              className={styles.HomeCard}
              onClick={(e) => this.props.changePage(SupportPagesEnum.Faq)}
            >
              <div className={styles.iconCardContainerSupportHome}>
                <FontIcon
                  iconName={"OfficeChat"}
                  className={styles.iconCardSupportHome}
                />
              </div>
              <div className={styles.lblCardContainerSupportHome}>
                <div className={styles.lblCardSupportHome}>
                  {this._translation.headerFAQ}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
