/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import React from 'react'
import PropTypes, { any } from 'prop-types'

import BasicElement from '../../Elements/Basic'

const Element = props => {
  const { time, style, title, start, end, classes, dataSet, tooltip, clickElement, tracks, tracks2, element } = props

  const handleClick = () => {
    clickElement(props)
  }
  const elementStyle = {
    ...time.toStyleLeftAndWidth(start, end),
    ...(clickElement ? { cursor: 'pointer' } : {}),
  }

  const width = elementStyle.width.replace("px", "");

  return (
    <div className="rt-track__element" tabIndex={0} style={elementStyle}
    onKeyDown={(e) => {
      if (e.key === "Enter") {
  clickElement && handleClick()                             ;
      }
    }}
    onClick={clickElement && handleClick}>
      <BasicElement
        title={title}
        start={start}
        end={end}
        style={style}
        classes={classes}
        dataSet={dataSet}
        tooltip={tooltip}
        tracks={tracks}
        tracks2={tracks2}
        element={element}
        width={width}
      />
    </div>
  )
}

Element.propTypes = {
  time: PropTypes.shape({
    toStyleLeftAndWidth: PropTypes.func,
  }).isRequired,
  style: PropTypes.shape({}),
  classes: PropTypes.arrayOf(PropTypes.string.isRequired),
  dataSet: PropTypes.shape({}),
  title: PropTypes.string,
  start: PropTypes.instanceOf(Date).isRequired,
  end: PropTypes.instanceOf(Date).isRequired,
  tooltip: PropTypes.string,
  clickElement: PropTypes.func,
  tracks: any,
  tracks2: any,
  element: any,
}

Element.defaultTypes = {
  clickElement: undefined,
}

export default Element
