import * as React from "react";
import "./Navigation.css";
import Hamburger from "hamburger-react";
import {
  CommandBarButton,
  INavLink,
  IOverflowSetItemProps,
  IPanelStyles,
  OverflowSet,
  Panel,
  PanelType,
  PrimaryButton,
  RefObject,
} from "@fluentui/react";
import { Security } from "../../../Utility/Security";
import { IBaseProperties } from "../../../Models/IBaseProperties";
import { NewActivityComponent } from "../../Shared/NewActivityComponent/NewActivityComponent";

const onRenderOverflowButton = (
  overflowItems: any[] | undefined
): JSX.Element => {
  return <CommandBarButton role="menuitem" title="More items" />;
};

export interface IHamburgerMenuProps extends IBaseProperties {
  page: string;
  changeSelNavCallback: (item: string) => void;
}
export interface IHamburgerMenuState {
  setLastHeader: any;
  lastHeader: any;
  isOpen: boolean;
}

export class HamburgerMenu extends React.Component<
  IHamburgerMenuProps,
  IHamburgerMenuState
> {
  private newActivityComponentRef: any;

  private readonly panelStyles: Partial<IPanelStyles> = {
    main: {
      backgroundColor: "#0152E8",
      borderRadius: "0px !important",
      width: "190px !important",
    },
    closeButton: { color: "#ffffff" },
  };

  constructor(props: IHamburgerMenuProps) {
    super(props);
    this.state = {
      isOpen: false,
      setLastHeader: null,
      lastHeader: null,
    };

    this.menuNavClick = this.menuNavClick.bind(this);
    this.newActivityComponentRef = React.createRef();
  }

  private readonly navLinkGroupsBase: IOverflowSetItemProps[] = [
    {
      name: this.props.commonProps.translation("header.menu1"),
      key: "Dashboard",
    },
    // {
    //   name: this.props.commonProps.translation("header.menu2"),
    //   key: 'My brief',
    // },
    {
      name: this.props.commonProps.translation("header.menu3"),
      key: "Activities",
    },
    {
      name: this.props.commonProps.translation("header.menu6"),
      key: "Attachments",
    },
  ];

  private readonly navLinkGroupsGM: IOverflowSetItemProps[] = [
    {
      name: this.props.commonProps.translation("header.menu4"),
      key: "Planner",
    },
    {
      name: this.props.commonProps.translation("header.menu5"),
      key: "Report",
    },
    {
      name: this.props.commonProps.translation("common.newActivityButton"),
      key: "NewActivity",
    },
    {
      name: this.props.commonProps.translation("planner.addMacro"),
      key: "NewMacro",
      sub: true,
    },
    {
      name: this.props.commonProps.translation("planner.addMicro"),
      key: "NewMicro",
      sub: true,
    },
    // {
    //   name: this.props.commonProps.translation("header.action2"),
    //   key: 'NewActivity',
    // },
  ];

  private readonly navLinkSupport: IOverflowSetItemProps[] = [
    {
      name: this.props.commonProps.translation("header.menu7"),
      key: "Support",
    },
    // {
    //   name: this.props.commonProps.translation("common.newActivityButton"),
    //   key: "NewActivity",
    // },
    // {
    //   name: this.props.commonProps.translation("planner.addMacro"),
    //   key: "NewMacro",
    //   sub: true,
    // },
    // {
    //   name: this.props.commonProps.translation("planner.addMicro"),
    //   key: "NewMicro",
    //   sub: true,
    // },
    // {
    //   name: this.props.commonProps.translation("header.action2"),
    //   key: 'NewActivity',
    // },
  ];

  private readonly navLinkNoGM: IOverflowSetItemProps[] = this.navLinkGroupsBase.concat(
    this.navLinkSupport
  );
  private readonly navLinkGM: IOverflowSetItemProps[] = this.navLinkGroupsBase
    .concat(this.navLinkGroupsGM)
    .concat(this.navLinkSupport);

  private onRenderItem = (item: IOverflowSetItemProps, items: IOverflowSetItemProps[]): JSX.Element => {
    let index = items.indexOf(items.filter(x => x.key == item.key)[0]);
    return item.sub ? (
      <ul className="subMenu">
        <li
          role="menuitem"
          className="subMenuButton"
          // key={item.key}
          aria-label={item.name}
          tabIndex={index}
          onClick={() => {
            if (item.key == "NewMacro") {
              this.newActivityComponentRef.current._openModalNewActivity(
                "macro"
              );
            } else if (item.key == "NewMicro") {
              this.newActivityComponentRef.current._openModalNewActivity(
                "micro"
              );
            }
          }}
        >
          {item.name}
        </li>
      </ul>
    ) : (
      <li
        role="menuitem"
        className="menuitem"
        key={item.key}
        aria-label={item.name}
        tabIndex={index}
        onClick={() => {
          this.props.changeSelNavCallback(item.key);
          this.setState({ isOpen: false });
        }}
      >
        {item.name}
      </li>
    );
  };

  private menuNavClick(ev?: any, item?: INavLink | undefined): void {
    if (item && item.key) {
      this.props.changeSelNavCallback(item.key);
    }
    ev?.stopPropagation();
  }

  renderNavMenu(isGM: boolean): React.ReactElement {
    const menuItems = isGM ? this.navLinkGM : this.navLinkNoGM;

    return (
      <Panel
        isOpen={this.state.isOpen}
        type={PanelType.customNear}
        // customWidth={"190px"}
        isBlocking={false}
        onDismiss={() => this.setState({ isOpen: false })}
        styles={this.panelStyles}
      >
        <div className="navControl">
          <OverflowSet
            className="navControl"
            aria-label="Media planning menu"
            role="menubar"
            // onLinkClick={this.menuNavClick}
            items={menuItems}
            vertical
            onRenderItem={(item) => this.onRenderItem(item, menuItems)}
            onRenderOverflowButton={onRenderOverflowButton}
            // styles={navStyles}
          />
        </div>
      </Panel>
    );
  }

  private renderHamburgerMenu(isGM: boolean): React.ReactElement {
    return (
      <>
        <Hamburger
          toggled={this.state.isOpen}
          toggle={() => {
            this.setState({ isOpen: !this.state.isOpen });
          }}
          direction="left"
          rounded
          label="Show menù"
        ></Hamburger>

        {this.state.isOpen && this.renderNavMenu(isGM)}
      </>
    );
  }

  render() {
    const isGM = this.isGMUser();
    return (
      <React.Fragment>
        {this.renderHamburgerMenu(isGM)}
        <div hidden={true}>
          <NewActivityComponent
            ref={this.newActivityComponentRef}
            commonProps={this.props.commonProps}
            position="HAMBURGER"
          ></NewActivityComponent>
        </div>
      </React.Fragment>
    );
  }

  private isGMUser() {
    return (
      Security.isGlobalMediaAdvanced ||
      Security.isGlobalMediaSpecialist ||
      Security.isAuditor ||
      Security.isAdmin
    );
  }
}
