import * as React from "react";
import "./PlannerNavigation.scss";
import {
  DefaultButton,
  Dropdown,
  FontIcon,
  IconButton,
  IDropdownOption,
  IRenderFunction,
  ISelectableOption,
  keyframes,
  Label,
} from "@fluentui/react";
import { Constants } from "../../../../Utility/Constants";
import { IBaseProperties } from "../../../../Models/IBaseProperties";
import { ActivityHelper, View } from "../../../../Models/ActivityStatus";
import { PlannerNavigationTranslation } from "./PlannerNavigation.Translation";
import { DateHelper } from "../../../../Models/DateHelper";
import { FormEvent } from "react";
import { PlannerView } from "../Planner";
import { Button } from "@material-ui/core";
import { ICalendar } from "../../../../Models/ICalendar";
import { ISensibility } from "../../../../Models/ISensibility";
import { getAppInsights } from "../../../../AppInsights/TelemetryService";
import { waitForDebugger } from "inspector";

const onRenderStatusOption: IRenderFunction<ISelectableOption> = (option) =>
  option ? (
    <div className={"status-container"}>
      {option.key && (
        <span
          className="circle-status"
          status-name={(option.key as string).toLowerCase().replace(/ /g, "")}
        ></span>
      )}
      <span>{option.text}</span>
    </div>
  ) : null;

const onRenderOption: IRenderFunction<ISelectableOption> = (option) =>
  option ? (
    <div className={"status-container"}>
      <span>{option.text}</span>
    </div>
  ) : null;

  const onRenderViewOption: IRenderFunction<ISelectableOption> = (option) =>
  option ? (
    <div>
      <span>{option.text}</span>
    </div>
  ) : null;

const onRenderTitle = (
  array: any[],
  options: IDropdownOption[] | undefined,
  label: string
) =>
  options?.length == array.length ? (
    <div className={"filter-status-container"}>{label}</div>
  ) : options?.length && options.length > 2 ? (
    <div className={"filter-status-container"}>
      {label} <FontIcon iconName="Filter" className={"iconFilter"}></FontIcon>{" "}
    </div>
  ) : (
    <div className={"filter-status-container"}>
      {label} <FontIcon iconName="Filter" className={"iconFilter"}></FontIcon>{" "}
      <span className="">({options?.map((s) => s.text).join(", ")})</span>
    </div>
  );

export interface IPlannerNavigationProps extends IBaseProperties {
  views: PlannerView;
  selectedFilter: string;
  selectedFilterStatus: string[];
  selectedFilterCalendar: string[];
  selectedFilterSensibility: string[];
  selectedFilterCategory: string[];
  selectedFilterResponsibility: string[];
  onChangeFilter: (
    event: FormEvent<HTMLDivElement>,
    option?: IDropdownOption | undefined,
    index?: number | undefined
  ) => void;
  onChangeFilterStatus: (event: any, item: any) => any;
  onChangeFilterCalendar: (event: any, item: any) => any;
  onChangeFilterSensibility: (event: any, item: any) => any;
  onChangeFilterCategory: (event: any, item: any) => any;
  onChangeFilterResponsibility: (event: any, item: any) => any;
  calendars: ICalendar[];
  sensibilities: IDropdownOption[];
  categories: IDropdownOption[];
  responsibilities: IDropdownOption[];
  viewNames: View[];
  selectedView: string;
  onClickToday: () => any;
  onChangeSelectedView: (view: string) => any;

  showCalendar: boolean;
  showTimeline: boolean;
  onChangePlannerType: (type: string) => any;

  onClickPrev: () => any;
  onClickNext: () => any;

  startDate: Date;
  endDate: Date;

  isSticky?: boolean;

  showFilters: boolean;
  isLoggedIn?: boolean;
  username?: string;
  user?: string;
  redirectUri: string;
  // getShowFilters: (event: any) => boolean
  onChangeShowFilter: (event: any) => void;
}
export interface IPlannerNavigationState {
  selectedView: string;
  showFilters: boolean;
  username?: string;
  user?: string;
  url: string;
}

export class PlannerNavigation extends React.Component<
  IPlannerNavigationProps,
  IPlannerNavigationState
> {
  private readonly _translation: PlannerNavigationTranslation;

  constructor(props: IPlannerNavigationProps) {
    super(props);
    this.state = {
      selectedView: props.selectedView.toLowerCase(),
      showFilters: false,
      user: props.user,
      username: props.username,
      url: props.redirectUri,      
    };
    this._translation = new PlannerNavigationTranslation(
      props.commonProps.translation
    );
  }

  private onViewDrillDown(viewName: string) {}

  private selectorLabel(): string {
    let label = "";

    switch (this.props.selectedView.toLowerCase()) {
      case "day":
        label = this.selectorDayLabel();
        break;
      case "week":
        label = this.selectorWeekLabel();
        break;
      case "month":
        label = this.selectorMonthLabel();
        break;
      case "year":
        label = this.selectorYearLabel();
        break;
    }
    return label;
  }

  private selectorYearLabel(): string {
    const label = DateHelper.toFormatDate(this.props.endDate, "YYYY");
    return label;
  }

  private selectorMonthLabel(): string {
    const label = DateHelper.toFormatDate(
      this.props.startDate.addDays(8),
      "MMM YYYY"
    );
    return label;
  }

  private selectorWeekLabel(): string {
    const label =
      DateHelper.toFormatDate(this.props.startDate, "MMM DD") +
      " - " +
      (this.props.startDate.getMonth() === this.props.endDate.getMonth()
        ? DateHelper.toFormatDate(this.props.endDate, "DD")
        : DateHelper.toFormatDate(this.props.endDate, "MMM DD"));
    return label;
  }

  private selectorDayLabel(): string {
    const label = DateHelper.toFormatDate(this.props.startDate, "dddd MMM DD");
    return label;
  }

  render() {
    const optionsFilter: IDropdownOption[] = ActivityHelper.types.map(
      (element) => {
        return { key: element.key, text: element.text };
      }
    );

    const userLoginName = this.state.username || this.state.user;
    const url = this.state.url;  

    const optionsFilterStatus: IDropdownOption[] = ActivityHelper.statuses.map(
      (element) => {
        return { key: element.key, text: element.text };
      }
    );

    const optionsFilterCalendar: IDropdownOption[] = this.props.calendars.map(
      (element: ICalendar) => {
        return { key: element.key.toString(), text: element.value };
      }
    );

    const optionsFilterSensibility: IDropdownOption[] = this.props.sensibilities
      .filter((s) => +s.key > 0)
      .map((element: IDropdownOption) => {
        return { key: element.key.toString(), text: element.text };
      });
    const optionsFilterCategory: IDropdownOption[] = this.props.categories
      .filter((s) => +s.key > 0)
      .map((element: IDropdownOption) => {
        return { key: element.key.toString(), text: element.text };
      });
    const optionsFilterResponsibility: IDropdownOption[] = this.props.responsibilities
      .filter((s) => +s.key > 0)
      .map((element: IDropdownOption) => {
        return { key: element.key.toString(), text: element.text };
      });

    const showCalendar =
      this.props.views === PlannerView.All ||
      this.props.views === PlannerView.Calendar
        ? true
        : false;

    return (
      <React.Fragment>
        <div
          className={`planner-nav-container ${
            this.props.isSticky ? "is-sticky" : ""
          }`}
        >
          <div
            className={`planner-nav ${this.props.isSticky ? "is-sticky" : ""}`}
          >
            
            <div className="planner-nav-switch">
              {showCalendar && (
                <DefaultButton
                    iconProps={Constants.iCalendar}
                    title="Calendar"
                    aria-label="Calendar"
                    ariaLabel="Calendar"
                    styles={
                        this.props.showCalendar
                            ? Constants.iconCalendarSelStyle
                            : Constants.iconCalendarStyle
                    }
                    onClick={() => {
                      getAppInsights().trackEvent({ name: 'MP - Calendar', properties: {userLoginName, url}});
                      this.props.onChangePlannerType("calendar");
                    }}
                >
                  Calendar
                </DefaultButton>
              )}
              <DefaultButton
                iconProps={Constants.iTimeline}
                title="Timeline"
                aria-label="Timeline"
                ariaLabel="Timeline"
                styles={
                  this.props.showTimeline
                    ? Constants.iconCalendarSelStyle
                    : Constants.iconCalendarStyle
                }
                onClick={() => {
                        getAppInsights().trackEvent({ name: 'MP - Timeline', properties: {userLoginName, url}});
                        this.props.onChangePlannerType("timeline");
                    }}
              >
                Timeline
              </DefaultButton>
            </div>
            <div className="planner-nav-actions">
              <div className="planner-nav-buttons">
                <DefaultButton
                  text={this._translation.date.today}
                  allowDisabledFocus
                  onClick={() => this.props.onClickToday()}
                  disabled={false}
                  checked={true}
                />
                {/* {this.props.viewNames.map((view: View) => {
                  return (
                    <DefaultButton
                      key={
                        this.props.selectedView === view.key
                          ? this.props.selectedView
                          : view.key
                      }
                      className={
                        this.props.selectedView.toLowerCase() ===
                        view.key.toLowerCase()
                          ? "btn-active"
                          : ""
                      }
                      text={view.text}
                      allowDisabledFocus
                      //onClick={this.view.bind(null, name)}
                      onClick={() => {
                        this.props.onChangeSelectedView(view.key);
                      }}
                      disabled={false}
                      checked={true}
                    />
                  );
                })} */}
              </div>
              <div className="planner-nav-selector">
                <DefaultButton
                  className={["btn-back"].join(" ")}
                  text=""
                  aria-label="Previous"
                  iconProps={Constants.iChevronLeft}
                  allowDisabledFocus
                  onClick={() => this.props.onClickPrev()}
                  disabled={false}
                  checked={true}
                />
                <DefaultButton
                  className={["btn-next"].join(" ")}
                  text=""
                  aria-label="Next"
                  iconProps={Constants.iChevronRight}
                  allowDisabledFocus
                  onClick={() => this.props.onClickNext()}
                  disabled={false}
                  checked={true}
                />
                <div className="nav-selector-label">{this.selectorLabel()}</div>
              </div>
              <div>
                <Dropdown
                  placeholder="Calendar filter"
                  label=""
                  onChange={(event, item) => {
                    if (item) {
                      this.props.onChangeSelectedView(item.key.toString());
                    }
                  }}
                  onRenderOption={onRenderViewOption}
                  selectedKey={this.props.selectedView}
                  options={this.props.viewNames.map((x) => {
                    return {
                      key: x.key,
                      text: x.text,
                    };
                  })}
                  className=""
                  styles={Constants.dropdownViewStyles}
                  title={"CALENDAR"}
                />
              </div>
            </div>
            <div className="planner-nav-showfilters">
              <DefaultButton
                iconProps={Constants.iFilters}
                title="Show filters"
                aria-label="Show filters"
                ariaLabel="Show filters"
                styles={
                  this.props.showFilters
                    ? Constants.iconCalendarSelStyle
                    : Constants.iconCalendarStyle
                }
                onClick={(event) => {
                  // this.setState({ showFilters: !this.state.showFilters });
                  this.props.onChangeShowFilter(event);
                }}
              >
                {this.props.showFilters
                  ? this._translation.hideFilters
                  : this._translation.showFilters}
              </DefaultButton>
            </div>
          </div>
          {this.props.showFilters && (
            <div
              className={`planner-nav-filters ${
                this.props.isSticky ? "is-sticky" : ""
              }`}
            >
              <div className="planner-nav-actions">
                <Dropdown
                  placeholder=""
                  label=""
                  defaultSelectedKey={this.props.selectedFilter}
                  onChange={this.props.onChangeFilter}
                  options={optionsFilter}
                  styles={Constants.dropdownCalendarStyles}
                />
                <Dropdown
                  placeholder="Status filter"
                  label=""
                  onChange={(event, item) =>
                    this.props.onChangeFilterStatus(event, item)
                  }
                  onRenderTitle={(options) =>
                    onRenderTitle(ActivityHelper.statuses, options, "Status")
                  }
                  onRenderOption={onRenderStatusOption}
                  selectedKeys={this.props.selectedFilterStatus}
                  multiSelect
                  options={optionsFilterStatus}
                  styles={Constants.dropdownFilterStyles}
                  // title={this.props.selectedFilterStatus.toString()}
                  title={"STATUS"}
                />
                <Dropdown
                  placeholder="Calendar filter"
                  label=""
                  onChange={(event, item) =>
                    this.props.onChangeFilterCalendar(event, item)
                  }
                  onRenderTitle={(options) =>
                    onRenderTitle(this.props.calendars, options, "Calendar")
                  }
                  onRenderOption={onRenderOption}
                  selectedKeys={this.props.selectedFilterCalendar}
                  multiSelect
                  options={optionsFilterCalendar}
                  styles={Constants.dropdownFilterStyles}
                  title={"CALENDAR"}
                />
                <Dropdown
                  placeholder="Sensibility"
                  label=""
                  onChange={(event, item) =>
                    this.props.onChangeFilterSensibility(event, item)
                  }
                  onRenderTitle={(options) =>
                    onRenderTitle(
                      this.props.sensibilities,
                      options,
                      "Sensibility"
                    )
                  }
                  onRenderOption={onRenderOption}
                  selectedKeys={this.props.selectedFilterSensibility}
                  multiSelect
                  options={optionsFilterSensibility}
                  styles={Constants.dropdownFilterStyles}
                  title={"SENSIBILITY"}
                />
                <Dropdown
                  placeholder="Category"
                  label=""
                  onChange={(event, item) =>
                    this.props.onChangeFilterCategory(event, item)
                  }
                  onRenderTitle={(options) =>
                    onRenderTitle(this.props.categories, options, "Category")
                  }
                  onRenderOption={onRenderOption}
                  selectedKeys={this.props.selectedFilterCategory}
                  multiSelect
                  options={optionsFilterCategory}
                  styles={Constants.dropdownFilterStyles}
                  style={{ minWidth: 210 }}
                  title={"CATEGORY"}
                />
                <Dropdown
                  placeholder="Responsibility"
                  label=""
                  onChange={(event, item) =>
                    this.props.onChangeFilterResponsibility(event, item)
                  }
                  onRenderTitle={(options) =>
                    onRenderTitle(
                      this.props.responsibilities,
                      options,
                      "Responsibility"
                    )
                  }
                  onRenderOption={onRenderOption}
                  selectedKeys={this.props.selectedFilterResponsibility}
                  multiSelect
                  options={optionsFilterResponsibility}
                  styles={Constants.dropdownFilterStyles}
                  title={"RESPONSIBILITY"}
                />
              </div>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}
