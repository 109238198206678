/* eslint-disable import/no-unresolved */
import * as React from "react";
import Timeline from "./index";
import "./scss/style.scss";
import {
  buildYearTimebar,
  buildMonthTimebar,
  buildWeekTimebar,
  buildDayTimebar,
} from "./utility/builders";

import { IBaseProperties } from "../../../../Models/IBaseProperties";
import { FormEvent } from "react";
import { MessageBar, MessageBarType } from "@fluentui/react";
import { IPlannerPermission } from "../../../../Models/Planner";
import { PlannerTranslation } from "../Planner.Translation";

/*
Helpers functions:
 - copy
 - containsText
*/
const copy = (o: any): any => Object.assign({}, o);

const containsText = (wordToCompare: any) => {

  return function (element: any) {

    const hasTitle = element && element.title && element.title.toLowerCase().includes(wordToCompare.toLowerCase());
    if (hasTitle) {
      return true;
    }

    if (element.elements) {
      if (element.elements = element.elements.map(copy).filter(containsText(wordToCompare)).length) {
        return true;
      }
    }

    if (element.tracks) {
      return (element.tracks = element.tracks.map(copy).filter(containsText(wordToCompare))).length;
    }

    return false;

  }
}

export interface ITimelineWrapProps extends IBaseProperties {
  startDate: Date;
  endDate: Date;
  selectedView: string;
  selectedFilter: string;
  selectedFilterStatus: string[];
  tracks: any[];
  tracksById: any[];
  lastUpdate?: number;
  onSelectActivity: (activity: any) => any;
  onChange: (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => void;
  selectedDay: Date;
  enableSticky: boolean;
  showFilters?: boolean;
  isSticky?: boolean;
  permission: IPlannerPermission;
  onClickDate: (date: Date, view: string) => void;

}
export interface ITimelineWrapState {
  open: boolean;
  zoom: number;
  tracks: any[];
  tracksById: any[];
  lastUpdate?: number;
  freeTextFilter?: string;
  showMessageBar?: boolean;
  lastTrackToggle: number[];
}

export class TimelineWrap extends React.Component<
  ITimelineWrapProps,
  ITimelineWrapState> {

  private readonly _translation: PlannerTranslation;

  constructor(props: ITimelineWrapProps) {
    super(props);
    this.state = {
      open: false,
      zoom: 1,
      tracksById: props.tracksById,
      tracks: props.tracks,
      lastUpdate: props.lastUpdate,
      showMessageBar: false,
      lastTrackToggle: [],
    };
    this._translation = new PlannerTranslation(props.commonProps.translation);
    this.onChangeTimeLineSearch = this.onChangeTimeLineSearch.bind(this);
  }

  static getDerivedStateFromProps(props: ITimelineWrapProps, state: ITimelineWrapState) {

    if (props.lastUpdate !== state.lastUpdate) {
      return {
        tracks: props.tracks,
        tracksById: props.tracksById,
        lastUpdate: props.lastUpdate
      };
    }
    //no change
    return null;
  }

  public manageZoom(): number {
    let zoom = 1;
    switch (this.props.selectedView.toLowerCase()) {
      case "day":
        zoom = 150;
        break;
      case "week":
        zoom = 80;
        break;
      case "month":
        zoom = 30;
        break;
      case "year":
        zoom = 5;
        break;
    }
    return zoom;
  }
  public manageTimebar(): any {
    let timebar;
    switch (this.props.selectedView.toLowerCase()) {
      case "day":
        timebar = buildDayTimebar(this.props.startDate, this.props.endDate);
        break;
      case "week":
        timebar = buildWeekTimebar(this.props.startDate, this.props.endDate);
        break;
      case "month":
        timebar = buildMonthTimebar(this.props.startDate, this.props.endDate);
        break;
      case "year":
        timebar = buildYearTimebar(this.props.startDate, this.props.endDate);
        break;
    }

    return timebar;
  }
  public handleToggleOpen = () => {

    this.setState(({ open }) => ({ open: !open }));
  };

  // handleZoomIn = () => {
  //   this.setState(({ zoom }) => ({ zoom: Math.min(zoom + 1, MAX_ZOOM) }));
  // }

  // handleZoomOut = () => {
  //   this.setState(({ zoom }) => ({ zoom: Math.max(zoom - 1, MIN_ZOOM) }));
  // }

  public handleToggleTrackOpen(track: any) {
    const { lastTrackToggle } = this.state;
    switch (track.trackLevel) {
      case 1:
        this.setState((state) => {
          const tracksById = {
            ...state.tracksById,
            [track.id]: {
              ...track,
              isOpen: !track.isOpen,
            },
          };

          const trackWithPrevState = Object.values(tracksById)
          trackWithPrevState.filter(t => t.id !== track.id && lastTrackToggle.includes(t.id)).forEach(t => t.isOpen = true);

          return {
            tracksById,
            //tracks: Object.values(tracksById),
            tracks: trackWithPrevState,
            //lastTrackToggle: Object.values(tracksById).filter(t=> t.isOpen).map(t=> t.id)
            lastTrackToggle: trackWithPrevState.filter(t => t.isOpen).map(t => t.id)
          };
        });
        break;
      case 2:
        this.setState((state) => {
          let open: number | undefined;
          let close: number | undefined;
          let trackParent = undefined;
          for (var i in state.tracksById) {
            if (state.tracksById[i].id === track.trackL1ID) {
              trackParent = state.tracksById[i];
              break;
            }
          }
          for (var j in trackParent.tracks) {
            if (trackParent.tracks[j].id === track.id) {
              trackParent.tracks[j].isOpen = !trackParent.tracks[j].isOpen;
              if (trackParent.tracks[j].isOpen) {
                open = trackParent.tracks[j].id;
              }
              else {
                close = trackParent.tracks[j].id;
              }

              break;
            }
          }
          const tracksById = {
            ...state.tracksById,
            [track.trackL1ID]: {
              ...trackParent,
              isOpen: true,
            },
          };

          const trackWithPrevState = Object.values(tracksById)
          trackWithPrevState
            .filter(t => t.identifier !== track.id && lastTrackToggle.includes(t.id))
            .forEach(t => t.isOpen = true);

          trackWithPrevState
            .filter(t => t.identifier !== track.identifier && lastTrackToggle.includes(t.id))
            .map(t => t.tracks)
            .filter(t => t.identifier !== track.id && lastTrackToggle.includes(t.id))
            .forEach(t => t.isOpen = true);

          return {
            tracksById,
            tracks: trackWithPrevState,
            lastTrackToggle: trackWithPrevState.filter(t => t.isOpen).map(t => t.identifier).concat(trackWithPrevState.filter(t => t.isOpen).map(t => t.tracks).filter(t => t.isOpen).map(t => t.identifier))
          };
        });
        break;
    }
  }

  private getTracks(): any[] {
    if (this.state.tracks) {
      const dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;

      const date_reviver = (key: any, value: any) => {
        //key: start, end, startActivity, endActivity
        if (typeof value === "string" && dateFormat.test(value)) {
          return new Date(value);
        };

        return value;
      };

      let tracks = JSON.parse(JSON.stringify(this.state.tracks), date_reviver);

      const { freeTextFilter } = this.state;

      if (tracks && freeTextFilter) {
        tracks = tracks.filter(containsText(freeTextFilter));

        tracks.forEach((item: any) => {
          if (!Array.isArray(item.elements)) {
            item.elements = this.state.tracks.filter(id => id.id === item.id)[0].elements;
          }
        });


      }

      // if (this.state.lastTackToggle)
      // {            
      //   this.handleToggleTrackOpen(this.state.lastTackToggle);
      // }

      const { lastTrackToggle } = this.state;

      if (lastTrackToggle && lastTrackToggle.length > 0) {
        tracks.filter((t: any) => lastTrackToggle.includes(t.id)).forEach((item: any) => {
          item.isOpen = true;
        });

        // this.setState(
        //   {tracks: tracks}
        // )
      }

      return tracks;
    }
    else {
      return [];
    }

  }

  private onChangeTimeLineSearch(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) {
    this.setState({ freeTextFilter: newValue });
  }

  private closeMessageBar() {
    this.setState({ showMessageBar: false });
  }

  private haveData(): boolean {
    return (this.state.tracks && this.state.tracks.length > 0) ? true : false;
  }

  public render() {
    const showMessageBar = !this.haveData();
    const messageBar = <MessageBar
      messageBarType={MessageBarType.warning}
      isMultiline={false}
      dismissButtonAriaLabel="Close"
      onDismiss={this.closeMessageBar}
    >
      {this._translation.getTimelineNoData}
    </MessageBar>;
    return (
      <div role="timeline" className="app">
        {showMessageBar && messageBar}

        <Timeline
          scale={{
            start: this.props.startDate,
            end: this.props.endDate,
            zoom: this.manageZoom(),
            // zoomMin: MIN_ZOOM,
            // zoomMax: MAX_ZOOM,
          }}
          isOpen={this.state.open}
          toggleOpen={this.handleToggleOpen}
          onChange={this.onChangeTimeLineSearch}
          selectedDay={this.props.selectedDay}
          onClickDate={this.props.onClickDate}
          // zoomIn={this.handleZoomIn}
          // zoomOut={this.handleZoomOut}
          clickElement={(activity: any) =>
            this.props.onSelectActivity(activity)
          }
          clickTrackButton={(track: any) => {
            // eslint-disable-next-line no-alert
            alert(JSON.stringify(track));
          }}
          onSelectActivity={(activity: any) =>
            this.props.onSelectActivity(activity)
          }
          timebar={this.manageTimebar()}
          tracks={this.getTracks()}
          tracks2={this.getTracks()}
          now={new Date()}
          toggleTrackOpen={(track: any) => this.handleToggleTrackOpen(track)}
          enableSticky={this.props.enableSticky}
          isSticky={this.props.isSticky}
          showFilters={this.props.showFilters}
          scrollToNow={true}
          view={this.props.selectedView}
          translation={this.props.commonProps.translation}

        />
      </div>
    );
  }
  // public clickElement(element: any) {
  //   alert(`Clicked element\n${JSON.stringify(element, null, 2)}`);
  // }
}
