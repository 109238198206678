import * as React from "react";
import styles from "./YourActivitiesComponent.module.scss";
import { IBaseProperties } from "../../../../Models/IBaseProperties";
import { ActivityCardComponent } from "./ActivityCardComponent/ActivityCardComponent";
import {
  ActivityStatusEnum,
  IActivitiesCount,
  IActivityAllAttributes,
  IListActivitiesFilter,
} from "../../../../Models/Activity";
import { ActivityTranslation } from "../../../../Translations/Activity.Translation";
import { DashboardTranslation } from "../../../../Translations/Dashboard.Translation";
import { LoaderComponent } from "../../../Shared/LoaderComponent/LoaderComponent";
import { IActivityClient } from "../../../../Clients/IActivityClient";
import { Constants } from "../../../../Utility/Constants";
import { count } from "console";
import { ToastNotificationType } from "../../../../Models/ToastNote";
import { DefaultButton } from "@fluentui/react";

export interface IYourActivitiesComponentProps extends IBaseProperties {
  activityCounters: IActivitiesCount[];
}
export interface IYourActivitiesComponentState {
  allActivitiesUrl: string;
  currentState: string;
  showMobileStates: boolean;
  allItems: IActivityAllAttributes[];
  itemsCount: number;
  isLoading: boolean;
  pageSize: number;
  offset: number;
  showLoadMore: boolean;
}

export class YourActivitiesComponent extends React.Component<
  IYourActivitiesComponentProps,
  IYourActivitiesComponentState
> {
  private readonly _translation: ActivityTranslation;
  private readonly _dashboardTranslation: DashboardTranslation;
  private readonly _activityClient: IActivityClient;

  constructor(props: IYourActivitiesComponentProps) {
    super(props);
    this._translation = new ActivityTranslation(
      this.props.commonProps.translation
    );
    this._dashboardTranslation = new DashboardTranslation(
      this.props.commonProps.translation
    );

    this._activityClient = this.props.commonProps.clientCreator.createActivityClient();

    this._changeStatus = this._changeStatus.bind(this);

    this.state = {
      allActivitiesUrl: "/?page=Activities",
      currentState: "focus",
      showMobileStates: false,
      allItems: [],
      itemsCount: 0,
      isLoading: true,
      pageSize: 12,
      offset: 0,
      showLoadMore: false,
    };
  }

  componentDidMount() {
    this._activityClient
      .getActivities(
        {
          maxLength: this.state.pageSize,
          type: 2,
          status: "focus",
          activity: "",
          startIndex: this.state.offset,
          showOnlyMyActivities: true,
        },
        [
          {
            field: "dt",
            type: "ASC",
          },
        ]
      )
      .then((items) => {
        if (items && items.activities) {
          this.setState({
            showLoadMore: items.activities.length >= this.state.pageSize,
            allItems: items.activities,
            itemsCount: items.count,
            isLoading: false,
          });
        } else {
          this.props.commonProps.toastComponent?.showMessage(
            this._translation.error,
            this._translation.getActivitiesError,
            ToastNotificationType.ERROR
          );
          this.setState({
            isLoading: false,
          });
        }
      });
  }

  private loadMode() {
    this.setState(
      {
        offset: this.state.offset + this.state.pageSize,
      },
      () => this.loadActivities()
    );
  }

  private loadActivities() {
    this.setState({
      isLoading: true,
    });
    var options: IListActivitiesFilter = {
      type: 2,
      activity: "",
      status: this.state.currentState,
      startIndex: this.state.offset,
      showOnlyMyActivities: true,
    };
    if (this.state.currentState !== "focus") {
      options.maxLength = this.state.pageSize;
    }
    this._activityClient
      .getActivities(options, [
        {
          field: "dt",
          type: "ASC",
        },
      ])
      .then((items) => {
        if (!items) {
          this.props.commonProps.toastComponent?.showMessage(
            this._translation.error,
            this._translation.getActivitiesError,
            ToastNotificationType.ERROR
          );
          this.setState({
            isLoading: false,
          });
        } else {
          this.setState({
            showLoadMore: items.activities.length >= this.state.pageSize,
            allItems: [...this.state.allItems, ...items.activities],
            isLoading: false,
          });
        }
      });
  }

  private _changeStatus(state: string) {
    this.setState(
      {
        offset: 0,
        allItems: [],
        currentState: state,
      },
      () => this.loadActivities()
    );
  }

  render() {
    return (
      // <div></div>

      <div className={styles.activity}>
        <LoaderComponent
          commonProps={this.props.commonProps}
          isLoading={this.state.isLoading}
        />
        <div className={styles.content}>
          <div className={styles.backgroundDiv}>
            <div>
              <div className={styles.lowpanel}>
                <div
                  role="filteredTabActivityList"
                  className="ms-Grid-col ms-xl12 ms-lg12 ms-md12 ms-sm12 hidden-mobile"
                >
                  <ul
                    className={"nav nav-tabs hidden-mobile " + styles.nav_tabs}
                    role="tablist"
                  >
                    <li className={styles.tab_pnl_home}>
                      <a
                        className={
                          "nav-link " +
                          " " +
                          (this.state.currentState == "focus" ||
                          this.state.currentState == ""
                            ? "active"
                            : "notActive")
                        }
                        data-toggle="tab"
                        role="tab"
                        aria-label={this._dashboardTranslation.focus(
                          this.state.itemsCount
                        )}
                        aria-controls="tab1"
                        aria-selected="true"
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this._changeStatus("focus");
                          }
                        }}
                        onClick={() => {
                          this._changeStatus("focus");
                        }}
                        tabIndex={0}
                      >
                        <div>
                          {this._dashboardTranslation.focus(
                            this.state.itemsCount
                          )}
                        </div>
                      </a>
                    </li>
                    {this.state.itemsCount > 0 &&
                      this.props.activityCounters
                        .filter(
                          (state) =>
                            state.state.toString().toLowerCase() !== "focus"
                        )
                        .map((state, i) => {
                          return (
                            <li className={styles.tab_pnl_home} key={i}>
                              <a
                                className={
                                  "nav-link " +
                                  " " +
                                  (this.state.currentState ==
                                  ActivityStatusEnum[state.state]
                                    ? "active"
                                    : "notActive")
                                }
                                data-toggle="tab"
                                role="tab"
                                aria-label={ActivityStatusEnum[state.state]}
                                aria-controls="tab1"
                                aria-selected="true"
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    this._changeStatus(
                                      ActivityStatusEnum[state.state]
                                    );
                                  }
                                }}
                                onClick={() => {
                                  this._changeStatus(
                                    ActivityStatusEnum[state.state]
                                  );
                                }}
                                tabIndex={0}
                              >
                                <div>
                                  {ActivityStatusEnum[state.state]} (
                                  {state.count})
                                </div>
                              </a>
                            </li>
                          );
                        })}
                  </ul>
                </div>
                <div className="ms-Grid-col ms-xl12 ms-lg12 ms-md12 ms-sm12">
                  <div className={styles.margin_top_mobile}>
                    <div
                      role="filteredTabActivityList"
                      className={styles.panel_state + " only-mobile"}
                    >
                      <div className={styles.statePanel}>
                        <span className={styles.activeState}>
                          {this.state.currentState}
                        </span>
                        <span
                          className={
                            styles.selState + " ms-Icon ms-Icon--Equalizer"
                          }
                          aria-label={this.state.currentState}
                          tabIndex={0}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              this.setState({
                                showMobileStates: !this.state.showMobileStates,
                              });
                            }
                          }}
                          onClick={() => {
                            this.setState({
                              showMobileStates: !this.state.showMobileStates,
                            });
                          }}
                        ></span>
                      </div>
                      <div className={styles.statesInternalPanel}>
                        {this.state.showMobileStates && (
                          <ul
                            className={
                              "nav nav-tabs hidden-sm hidden-md hidden-lg" +
                              styles.nav_tabs
                            }
                            role="tablist"
                          >
                            <li
                              className={
                                styles.tab_pnl_home +
                                " ms-Grid-col ms-xl6 ms-lg6 ms-md6 ms-sm12"
                              }
                            >
                              <a
                                className={
                                  "nav-link " +
                                  " " +
                                  (this.state.currentState == ""
                                    ? "active"
                                    : "notActive")
                                }
                                data-toggle="tab"
                                role="tab"
                                aria-controls="tab1"
                                aria-selected="true"
                                aria-label={this._dashboardTranslation.all(
                                  false,
                                  this.state.itemsCount
                                )}
                                tabIndex={0}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    this._changeStatus("");
                                  }
                                }}
                                onClick={() => {
                                  this._changeStatus("");
                                }}
                              >
                                <div>
                                  {this._dashboardTranslation.all(
                                    false,
                                    this.state.itemsCount
                                  )}
                                </div>
                              </a>
                            </li>
                            {this.props.activityCounters.map((state, i) => {
                              return (
                                <li
                                  className={
                                    styles.tab_pnl_home +
                                    " ms-Grid-col ms-xl6 ms-lg6 ms-md6 ms-sm12"
                                  }
                                >
                                  <a
                                    className={
                                      "nav-link " +
                                      " " +
                                      (this.state.currentState ==
                                      ActivityStatusEnum[state.state]
                                        ? "active"
                                        : "notActive")
                                    }
                                    data-toggle="tab"
                                    role="tab"
                                    aria-controls="tab1"
                                    aria-selected="true"
                                    aria-label={ActivityStatusEnum[state.state]}
                                    onClick={() => {
                                      this._changeStatus(
                                        ActivityStatusEnum[state.state]
                                      );
                                    }}
                                    tabIndex={0}
                                    onKeyDown={(e) => {
                                      this._changeStatus(
                                        ActivityStatusEnum[state.state]
                                      );
                                    }}
                                  >
                                    <div>
                                      {ActivityStatusEnum[state.state]} (
                                      {state.count})
                                    </div>
                                  </a>
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div role="activityList" className={styles.tab_content}>
                  <div
                    className={
                      styles.tab_pane + " " + styles.show + " " + styles.active
                    }
                    id="tab1"
                    role="tabpanel"
                    aria-label="your activities"
                  >
                    {this.state.currentState === "focus" && (
                      <p>{this._translation.focusNote}</p>
                    )}
                    <div className="row">
                      {this.state.allItems.length == 0 && (
                        <div
                          className={
                            "ms-Grid-col ms-xl3 ms-lg6 ms-md12 ms-sm12 " +
                            styles.noActivitiedFound
                          }
                        >
                          {this._translation.noActivityToDisplay}
                        </div>
                      )}
                      {this.state.allItems.map((item, i) => {
                        return (
                          <div
                            className="ms-Grid-col ms-xl3 ms-lg6 ms-md12 ms-sm12"
                            key={i}
                          >
                            <ActivityCardComponent
                              activity={item}
                              commonProps={this.props.commonProps}
                            ></ActivityCardComponent>
                          </div>
                        );
                      })}
                    </div>
                    {this.state.showLoadMore && (
                      <div className="row justify-content-md-center">
                        <div className="ms-Grid-col ms-md2">
                          <DefaultButton
                            onClick={() => this.loadMode()}
                            aria-label={this._translation.loadMore}
                            ariaLabel={this._translation.loadMore}
                            text={this._translation.loadMore}
                          ></DefaultButton>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
