import * as React from "react";
import styles from "./LoaderComponent.module.scss";
import {
  Modal,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";
import { IBaseProperties } from "../../../Models/IBaseProperties";

export interface ILoaderComponentProps extends IBaseProperties  {
  isLoading: boolean;
  label?: string;
}

export interface ILoaderComponentState {
}

export class LoaderComponent extends React.Component<ILoaderComponentProps, ILoaderComponentState> {
  constructor(props: ILoaderComponentProps) {
    super(props);
  }

  render() {
    return (
        <Modal titleAriaId="load" isOpen={this.props.isLoading}>
          <Spinner
            size={SpinnerSize.large}
            label={this.props.label ? this.props.label : this.props.commonProps.translation("body.sharedComponents.loader.spinner")}
            style={{ minHeight: 176 }}
          />
        </Modal>
    );
  }
}
