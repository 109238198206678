import React from 'react'
import PropTypes from 'prop-types'
import { Constants } from '../../../../../../Utility/Constants';
import { TextField } from "@fluentui/react";


const Header = ({ timebar, sticky: { isSticky, showFilters, sidebarWidth, headerHeight } = {}, onChange, translation }) => (
  <div style={isSticky ? {} : {}}>
    <div
      className={`rt-sidebar__header ${isSticky && showFilters ? 'rt-is-sticky-with-filters' : isSticky ? 'rt-is-sticky' : ''}`}
      style={isSticky ? { width: sidebarWidth } : {}}
    >
      <div className="rt-sidebar_search">
        <label htmlFor="searchToolBox" aria-label='search box label'></label>
        <TextField
          placeholder={translation("common.searchHere")}
          iconProps={Constants.iSearch}
          styles={Constants.timelineTextFieldSearchStyle}
          arialabel="search box"
          aria-label='search box'
          onChange={onChange}
          name="searchToolBox"
        >
        </TextField>
      </div>
      {/* {timebar.map(({ id, title, style }) => (
        <div key={id} className="rt-timebar-key" style={style}>          
        </div>
      ))} */}
    </div>
  </div>
)

Header.propTypes = {
  sticky: PropTypes.shape({
    isSticky: PropTypes.bool.isRequired,
    headerHeight: PropTypes.number.isRequired,
    sidebarWidth: PropTypes.number.isRequired,
  }),
  timebar: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string,
    }).isRequired
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  translation: PropTypes.any.isRequired
}

export default Header
