import { DocumentDetailsDTO } from "./../Models/DTO/DocumentDTO";
import { Utility } from "./../Utility/Utility";
import { IOrderBy } from "./../Models/Activity";
import {
  DeliverableType,
  ExtensionsDTO,
  IDeliverable,
  IDeliverableDetails,
  IDeliverableFilter,
  IDeliverableVersion,
  IListDeliverables,
  ITemplates,
} from "./../Models/Deliverable";
import { APIUtility } from "../Utility/APIUtility";
import { IDeliverableClient } from "./IDeliverableClient";
import deliverablemock from "../Mocks/DeliverablesMock.json";
import "../Utility/DateExtensions";
import { HttpError } from "../Models/HttpResponse";
import { ErrorClient } from "./ErrorClient";
import {
  DocumentItemDTO,
  DocumentPermissionDTO,
} from "../Models/DTO/DocumentDTO";
import { DeliverableVersionDTO } from "../Models/DTO/DeliverableDTO";
import moment from "moment";

const dateTimeReviver = function(key: string, value: any) {
  var a;
  if (typeof value === "string") {
    a = /\/Date\((\d*)\)\//.exec(value);
    if (a) {
      return new Date(+a[1]);
    }
  }
  return value;
};

export class DeliverableClient implements IDeliverableClient {
  private _getDateFromAPIString(dateString: any): Date {
    return moment
      .utc(dateString)
      .local()
      .toDate();
  }

  public async getDeliverables(
    id: number
  ): Promise<IDeliverableDetails[] | undefined> {
    let retValue: IDeliverableDetails[] | undefined = undefined;
    try {
      let apiUtility = new APIUtility();

      var elements: DocumentDetailsDTO[] = await apiUtility.getActivityDeliverables(
        id
      );

      retValue = elements
        .filter((x) => x.filePath !== undefined)
        .map((del) => {
          return {
            id: del.id,
            entityId: id,
            // idActivity: id,
            fileName: del.fileName,
            fileType: Utility.GetFileType(del.fileName),
            filePath: del.filePath,
            fileSize: del.fileSize,
            editedBy: del.editedBy,
            lastUpdate: this._getDateFromAPIString(del.lastModify),
            size: Utility.GetFileSize(del.fileSize),
            embedUrl: del.embedUrl,
          };
        });
    } catch (err) {
      console.error(err);
    } finally {
      return retValue;
    }
  }

  public async addDeliverable(
    idActivity: string,
    content: any,
    forced: boolean
  ): Promise<number> {
    let retValue: number = -3;
    try {
      let apiUtility = new APIUtility();
      let apiresult = await apiUtility.addDeliverable(
        idActivity,
        content,
        forced
      );
      if (Utility.isResponseStatusValid(apiresult)) retValue = 0;
      else if (apiresult == 415) retValue = -1;
      else if (apiresult == 409) retValue = -2;
      else retValue = -3;
    } catch (err) {
      console.error(err);
    } finally {
      return retValue;
    }
  }

  public async deleteDeliverable(
    idActivity: string,
    itemId: number
  ): Promise<boolean | undefined> {
    let retValue: boolean | undefined = undefined;
    try {
      let apiUtility = new APIUtility();
      await apiUtility.deleteDeliverable(idActivity, itemId.toString());
      retValue = true;
    } catch (err) {
      console.error(err);
    } finally {
      return retValue;
    }
  }

  public async downloadAttachment(
    idActivity: number,
    idDeliverable: number
  ): Promise<any | undefined> {
    let retValue: any | undefined = undefined;
    try {
      let apiUtility = new APIUtility();
      let file = await apiUtility.downloadDeliverable(
        idActivity,
        idDeliverable
      );
      retValue = file.fileContents;
    } catch (err) {
      console.error(err);
    } finally {
      return retValue;
    }
  }

  public async downloadAttachments(
    idActivity: number,
    deliverables: IDeliverable[]
  ): Promise<any | undefined> {
    let retValue: any | undefined = undefined;
    try {
      let apiUtility = new APIUtility();
      let file = await apiUtility.downloadDeliverables(
        idActivity,
        deliverables.map((x) => x.id)
      );
      retValue = file.fileContents;
    } catch (err) {
      console.error(err);
    } finally {
      return retValue;
    }
  }

  public async getAttachmentVersions(
    idActivity: number,
    idDeliverable: number
  ): Promise<IDeliverableVersion[] | undefined> {
    let retValue: DeliverableVersionDTO[] | undefined = undefined;
    try {
      let apiUtility = new APIUtility();
      let deliverableVersion = await apiUtility.getDeliverableVersions(
        idActivity,
        idDeliverable
      );
      retValue = deliverableVersion;
    } catch (err) {
      console.error(err);
    } finally {
      return retValue;
    }
  }

  public async downloadAttachmentVersion(
    idActivity: number,
    idDeliverable: number,
    idVersion: string
  ): Promise<any | undefined> {
    let retValue: any | undefined = undefined;
    try {
      let apiUtility = new APIUtility();
      let file = await apiUtility.downloadDeliverableVersions(
        idActivity,
        idDeliverable,
        idVersion
      );
      retValue = file.fileContents;
    } catch (err) {
      console.error(err);
    } finally {
      return retValue;
    }
  }

  public async restoreAttachmentVersion(
    idActivity: number,
    idDeliverable: number,
    idVersion: string
  ): Promise<boolean | undefined> {
    let retValue: any | undefined = undefined;
    try {
      let apiUtility = new APIUtility();
      let file = await apiUtility.restoreDeliverableVersions(
        idActivity,
        idDeliverable,
        idVersion
      );
      retValue = file;
    } catch (err) {
      console.error(err);
    } finally {
      return retValue;
    }
  }

  public async createNewDeliverable(
    idActivity: string,
    fileName: string,
    // fileType: DeliverableType,
    templateName: string
  ): Promise<number> {
    let retValue: number = -2;
    try {
      let apiUtility = new APIUtility();
      let apiresult = await apiUtility.createNewDeliverable(idActivity, {
        fileName: fileName,
        templateName: templateName,
        // fileExtension: Utility.GetFileExtensionByType(fileType),
      });
      if (Utility.isResponseStatusValid(apiresult)) retValue = 0;
      else if (apiresult == 409) retValue = -1;
      else retValue = -2;
    } catch (err) {
      console.error(err);
    } finally {
      return retValue;
    }
  }

  public async getListTemplates(): Promise<ITemplates[] | undefined> {
    let retValue: ITemplates[] | undefined = undefined;
    try {
      let apiUtility = new APIUtility();
      let templates = await apiUtility.getListTemplates();

      
      retValue = (templates as string[]).map((x) => {
        var fileExtension = x.split(".").pop();
        return {
          fileName: x,
          fileType: Utility.GetFileType(x),
          fileExtension: fileExtension ? fileExtension : "",
        };
      });
    } catch (err) {
      console.error(err);
    } finally {
      return retValue;
    }
  }

  public async getAllowedExtension(): Promise<string[] | undefined> {
    let retValue: string[] | undefined = undefined;
    try {
      let apiUtility = new APIUtility();
      let extensions = await apiUtility.getAllowedExtension();

      retValue = (extensions as ExtensionsDTO[]).map((x) => x.fileExtension);
    } catch (err) {
      console.error(err);
    } finally {
      return retValue;
    }
  }

  private getListDeliverablesMock(): IListDeliverables {
    const deliverablesMock = this.getDeliverableMock();
    const retValue: IListDeliverables = {
      count: deliverablesMock.length,
      deliverables: deliverablesMock,
      permission: {
        visibility: true,
        canAdd: true,
        canDelete: true,
        canDownloadDeliverables: true,
      },
    };

    return retValue;
  }

  private getDeliverableMock(): IDeliverable[] {
    const json = JSON.stringify(deliverablemock);
    return JSON.parse(json, dateTimeReviver);
  }

  public async getAttachments(
    briefAttachments: boolean,
    filter: IDeliverableFilter,
    orderBy: (IOrderBy | undefined)[]
  ): Promise<IListDeliverables | undefined> {
    const DEFAULT_LIMIT = 50;
    let retValue: IListDeliverables | undefined = undefined;
    try {
      //retValue = this.getListDeliverablesMock();

      const apiUtility = new APIUtility();
      const items = await apiUtility.getAttachments(
        DEFAULT_LIMIT,
        filter.startIndex,
        briefAttachments ? "brief" : "activity",
        filter.fileType,
        filter.fileName,
        filter.activityBrief,
        filter.lastModifyStartDate?.utc().toISOString(),
        filter.lastModifyEndDate?.utc().toISOString(),
        orderBy
      );

      retValue = this.documentDTOToDeliverables(items.items, items.permissions);
    } catch (err) {
      this.storeError(err);
    } finally {
      return retValue;
    }
  }

  public async getAttachmentPaths(
    idAttachment: number
  ): Promise<IDeliverableDetails | undefined> {
    let retValue: IDeliverableDetails | undefined = undefined;
    try {
      const apiUtility = new APIUtility();
      const item = await apiUtility.getAttachmentPaths(idAttachment);

      if (item) retValue = this.documentDetailsDTOtoIDeliverableDetails(item);
    } catch (err) {
      this.storeError(err);
    } finally {
      return retValue;
    }
  }

  private documentDTOToDeliverables(
    documents: DocumentItemDTO[],
    permission: DocumentPermissionDTO
  ): IListDeliverables {
    let retValue: IListDeliverables = {
      deliverables: [],
      count: 0,
      permission: permission,
    };
    documents.forEach((dto) => {
      retValue.deliverables.push(this.documentItemDTOToIDeliverable(dto));
    });

    retValue.count = retValue.deliverables.length;
    return retValue;
  }

  private documentItemDTOToIDeliverable(
    dtoItem: DocumentItemDTO
  ): IDeliverable {
    const value: IDeliverable = {
      fileName: dtoItem.fileName,
      // fileDownload: dtoItem.downloadPath,
      fileExtension: dtoItem.fileType,
      id: dtoItem.id,
      // fileSize: dtoItem.fileSize,
      lastUpdate: dtoItem.lastModify,
      // activityBrief: dtoItem.entity.value,
      // idActivity: dtoItem.entity.key,
      entityId: dtoItem.entity.id,
      entityName: dtoItem.entity.name,
      entityType: dtoItem.entity.type,
      // filePath: dtoItem.filePath,
      fileType: Utility.GetFileType(dtoItem.fileName),
      // embedUrl: dtoItem.embedUrl,
    };

    return value;
  }

  private documentDetailsDTOtoIDeliverableDetails(
    dtoItem: DocumentDetailsDTO
  ): IDeliverableDetails {
    const value: IDeliverableDetails = {
      fileName: dtoItem.fileName,
      fileDownload: dtoItem.downloadPath,
      fileExtension: dtoItem.fileType,
      id: dtoItem.id,
      fileSize: dtoItem.fileSize,
      lastUpdate: dtoItem.lastModify,
      // activityBrief: dtoItem.entity.value,
      // idActivity: dtoItem.entity.key,
      filePath: dtoItem.filePath,
      fileType: Utility.GetFileType(dtoItem.fileName),
      embedUrl: dtoItem.embedUrl,
      // entityId: dtoItem.entity.id,
      // entityName: dtoItem.entity.name,
      // entityType: dtoItem.entity.type,
    };

    return value;
  }

  private storeError(err: HttpError): void {
    console.error(err);
    ErrorClient.storeHttpError(err);
  }
}
