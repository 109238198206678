import React from "react";
// import './MonthCalendar.css';
import styles from "./MonthCalendar.module.scss";
import {MonthHeader} from './MonthHeader';
import {MonthDays} from './MonthDays';
import {Calendar} from '../../../../Models/Calendar';

export interface IMonthCalendarProps {
  t: any;
  year: number;
  month: number;
  activities: Calendar[];  
  selectedDay: Date;
}
export interface IMonthCalendarState {
  activities: any[];
  showAddbtn: boolean;
  isLoading: boolean;
  showTimeline: boolean;
  showCalendear: boolean;
  newActivityVisible: boolean;
  newActivityType: string;
  activityDetailsVisible: boolean;
  activityDetailsOpen: any;  
  timelineZoom: any;
  timelineOpen: boolean;
  tracks: any;  
}

export class MonthCalendar extends React.Component<IMonthCalendarProps, IMonthCalendarState> {
   
    constructor(props: IMonthCalendarProps) {
        super(props);
        this.state = {
          activities: [],
          showAddbtn: false,
          isLoading: true,
          showTimeline: false,
          showCalendear: true,
          newActivityVisible: false,
          newActivityType: "",
          activityDetailsVisible: false,
          activityDetailsOpen: {},          
          timelineZoom: null,
          timelineOpen: true,
          tracks: null,          
        };
    }

    monthDates(year: number, month: number): Date[] {
        const dateMonth = month - 1; //the month is 0-indexed
        const date = new Date(year, dateMonth, 1);
        const days = [];
        while (date.getMonth() === dateMonth) {
          days.push(new Date(date));
          date.setDate(date.getDate() + 1);
        }
        return days;
      }
     
    //Return the list of filtered activity
    filterMonthActivities(dateRange: Date[], activities: any)
    {
        const minDate = dateRange[0];
        const maxDate = dateRange[dateRange.length-1];
        return activities.filter((act:any) => {return  ( 
          ((act.start >= minDate && act.start <=  maxDate) ||
            (minDate >= act.start && minDate <= act.end ))
             || (act.linkedActivities.some((lact: any) => ((lact.start >= minDate && lact.start <=  maxDate) ||
             (minDate >= lact.start && minDate <= lact.end )))));});
    }

    renderMonthCalendar(year: number, month: number, activities: any)
    {
        const monthDateRange: Date[] = this.monthDates(year, month);        

        //get the montly activities
        const factivities: any = this.filterMonthActivities(monthDateRange, activities);                                
        
        return <div className={styles["month-calendar-container"]}>
            <MonthHeader year={year} month={month} t={this.props.t}></MonthHeader>            
            <div className={styles["month-calendar-days-container"]}>
            <MonthDays dateRange={monthDateRange} activities={factivities} onDrillDown={this.props.t.onDrillDown} selectedDay={this.props.selectedDay} ></MonthDays>
            </div>
        </div>
    }

    render() {
        return (
            <React.Fragment>
                {this.renderMonthCalendar(this.props.year, this.props.month, this.props.activities)}
            </React.Fragment>
        );
    }
    
};