
import {
  START_YEAR,
  NUM_OF_YEARS,
  MONTHS_PER_YEAR,
  QUARTERS_PER_YEAR,
  MONTHS_PER_QUARTER,
  NUM_OF_MONTHS,
  MAX_TRACK_START_GAP,
  MAX_ELEMENT_GAP,
  MAX_MONTH_SPAN,
  MIN_MONTH_SPAN,  
} from './constants'

import moment from 'moment';

import { fill, hexToRgb, colourIsLight, addMonthsToYear, addMonthsToYearAsDate, addMonthsToYearAsDateWithDay, nextColor, randomTitle } from './utils'
import { DateHelper } from '../../../../../Models/DateHelper';

export const buildQuarterCells = () => {
  const v = []
  for (let i = 0; i < QUARTERS_PER_YEAR * NUM_OF_YEARS; i += 1) {
    const quarter = (i % 4) + 1
    const startMonth = i * MONTHS_PER_QUARTER
    const s = addMonthsToYear(START_YEAR, startMonth)
    const e = addMonthsToYear(START_YEAR, startMonth + MONTHS_PER_QUARTER)
    v.push({
      id: `${s.year}-q${quarter}`,
      title: `Q${quarter} ${s.year}`,
      start: new Date(`${s.year}-${s.month}-01`),
      end: new Date(`${e.year}-${e.month}-01`),
    })
  }
  return v
}

export const buildMonthCells = (_start, end) => {
  const v = []
  for (let i = 0; i < MONTHS_PER_YEAR * NUM_OF_YEARS; i += 1) {
    const startMonth = i
    const start = addMonthsToYearAsDate(_start.getFullYear(), startMonth);
    start.setHours(0, 0, 0);

    // const vend = new Date(addMonthsToYearAsDate(_start.getFullYear(), startMonth + 1) - 1000);
    const vend = new Date(start.getFullYear(), start.getMonth()+1, 0);    
    vend.setHours(23, 59, 59);
    v.push({
      id: `m${start.getFullYear()}-${startMonth}`,
      title: DateHelper.monthName[i % 12],
      start,
      end: vend,
    })
  }
  return v
}

export const buildYearCells = (_start, end) => {
  const v = []
  for (let i = 0; i < MONTHS_PER_YEAR * NUM_OF_YEARS; i += 1) {
    const startMonth = i
    const start = addMonthsToYearAsDate(_start.getFullYear(), startMonth)
    start.setHours(0, 0, 0);
    // const vend = new Date(addMonthsToYearAsDate(_start.getFullYear(), startMonth + 1) - 1000);
    const vend = new Date(start.getFullYear(), start.getMonth()+1, 0);
    vend.setHours(23, 59, 59);
    v.push({
      id: `m${start.getFullYear()}-${startMonth}`,
      title:  DateHelper.monthShortName[i % 12], // MONTH_ABBREVIATION_NAMES[i % 12],
      start,
      end: vend,
    })
  }
  return v
}


function daysInMonth (year, month) {
  return new Date(year, month, 0).getDate();
}

export const buildDayCells = (_start, end) => {
  const v = []
  for (let i = 0; i < MONTHS_PER_YEAR * NUM_OF_YEARS; i += 1) {
    const startMonth = i
    const days = daysInMonth(_start.getFullYear(), startMonth + 1);
    for (let i = 1; i <= days; i += 1) {
      const startDay = i
      const start = addMonthsToYearAsDateWithDay(_start.getFullYear(), startMonth, startDay)
      start.setHours(0);
      
      const vend = new Date(start.getTime());
      vend.setDate(vend.getDate());
      vend.setHours(23, 59, 59);
      v.push({
        id: `m${start.getFullYear()}-${startMonth}-${startDay}`,
        title: startDay,
        start,
        end: vend,
      })
    }
  }
  return v
}

const buildSingleDayCell = (_start, _end) => {
  const v = []
    v.push({
      id: _start.getTime(),
      title: `${_start.getDate()} ${getMonthName(_start)}`,
      start: _start,
      end: _end,
    });
  
  return v
}

export const buildHourCells = (_start, _end) => {
  const v = []  

  for (let i = 0; i <= 23; i++) {
    const start = new Date(_start.getTime());
    start.setHours(i);
    const end = new Date(start.getTime());
    end.setMinutes(59, 59, 999);
    v.push({
      id: `h${i}`,
      title: i,      
      start: start,
      end: end,
    })
  }
  
  return v
}

export const buildWeekDayCells = (_start, _end) => {
  const v = []
  const getDateArray = function(start, end) {
    var arr = new Array();
    var dt = new Date(start);
    while (dt <= end) {
        arr.push(new Date(dt));
        dt.setDate(dt.getDate() + 1);
    }
    return arr;
  }

  const dateArr = getDateArray(_start, _end);

  dateArr.forEach((value, index)=>
  {
    const vend = new Date(value.getTime());
      vend.setDate(vend.getDate());
      vend.setHours(23, 59, 59);
    v.push({
      id: `m${index}`,
      title: value.getDate(),
      subtitle :getDayName(value),
      start: value,
      end: vend,
    })
  });

  return v
}

function getDayName(date)
{
  return moment(date).format('dddd');
}

function getMonthName(date)
{
  return moment(date).format('MMMM');
}

export const buildDayTimebar = (start, end) => [
  {
    id: 'days',
    title: 'days',
    cells: buildSingleDayCell(start, end),
    style: {
      textAlign: "left",
      font: "normal normal normal 28px/28px Arial",
      letterSpacing: "0.56px",
      color: "#667790",
      paddingLeft: "6px",
      height: "86px",
      lineHeight: "70px",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
  },
  {
    id: 'hours',
    title: 'Hours',
    cells: buildHourCells(start, end),
    style: {
      textAlign: "center",
      font: "normal normal normal 12px/16px Arial",
      letterSpacing: "0.12px",
      color: "#667790",
      height: "22px",
      lineHeight: "22px",
      borderLeft: "none",
      borderRight: "none"
    },
  },
]
export const buildWeekTimebar = (start, end) => [
  {
    id: 'weeks',
    title: 'Weeks',
    cells: buildWeekDayCells(start, end),
    style: {
      textAlign: "left",
      font: "normal normal normal 28px/28px Arial",
      letterSpacing: "0.28px",
      color: "#242A33",
      height: "108px",
      borderLeft: "none",            
      // borderRight: "none",      
    },
  },
]
export const buildMonthTimebar = (start, end) => [
  {
    id: 'months',
    title: 'Months',
    cells: buildMonthCells(start, end),
    style: {
      textAlign: "left",
      font: "normal normal normal 28px/28px Arial",
      letterSpacing: "0.56px",
      color: "#667790",
      paddingLeft: "6px",
      height: "86px",
      lineHeight: "70px",
      textOverflow: "ellipsis",
      overflow: "hidden",
      left: "-2px",
    },
  },
  {
    id: 'days',
    title: 'Days',
    cells: buildDayCells(start, end),
    style: {
      textAlign: "center",
      font: "normal normal normal 12px/16px Arial",
      letterSpacing: "0.12px",
      color: "#667790",
      height: "22px",
      lineHeight: "22px",
      borderLeft: "none",
      borderRight: "none"
    },
  },
]

export const buildYearTimebar = (start, end) => [
  {
    id: 'months',
    title: 'Months',
    cells: buildYearCells(start, end),
    style: {
      textAlign: "left",
      font: "normal normal normal 28px/28px Arial",
      letterSpacing: "0.56px",
      // backgroundColor: "#ffffff",
      // top: "32px",
      color: "#667790",
      paddingLeft: "6px",
      height: "108px",
      // top: "32px",
      // lineHeight: "70px",
      textOverflow: "ellipsis",
      overflow: "hidden",
      // left: "-2px",      
    },
  },
]


export const buildElement = ({ trackId, start, end, i }) => {
  const bgColor = nextColor()
  const color = colourIsLight(...hexToRgb(bgColor)) ? '#000000' : '#ffffff'
  return {
    id: `t-${trackId}-el-${i}`,
    title: randomTitle(),
    start,
    end,
    style: {
      backgroundColor: `#${bgColor}`,
      color,
      borderRadius: '4px',
      boxShadow: '1px 1px 0px rgba(0, 0, 0, 0.25)',
      textTransform: 'capitalize',
    },
  }
}

export const buildTrackStartGap = () => Math.floor(Math.random() * MAX_TRACK_START_GAP)
export const buildElementGap = () => Math.floor(Math.random() * MAX_ELEMENT_GAP)

export const buildElements = trackId => {
  const v = []
  let i = 1
  let month = buildTrackStartGap()

  while (month < NUM_OF_MONTHS) {
    let monthSpan = Math.floor(Math.random() * (MAX_MONTH_SPAN - (MIN_MONTH_SPAN - 1))) + MIN_MONTH_SPAN

    if (month + monthSpan > NUM_OF_MONTHS) {
      monthSpan = NUM_OF_MONTHS - month
    }

    const start = addMonthsToYearAsDate(START_YEAR, month)
    const end = addMonthsToYearAsDate(START_YEAR, month + monthSpan)
    v.push(
      buildElement({
        trackId,
        start,
        end,
        i,
      })
    )
    const gap = buildElementGap()
    month += monthSpan + gap
    i += 1
  }

  return v
}

export const buildSubtrack = (trackId, subtrackId) => ({
  id: `track-${trackId}-${subtrackId}`,
  title: `Subtrack ${subtrackId}`,
  elements: buildElements(subtrackId),
})

export const buildTrack = (trackId, tracks) => {
  //const tracks = fill(Math.floor(Math.random() * MAX_NUM_OF_SUBTRACKS) + 1).map(i => buildSubtrack(trackId, i + 1))
  return {
    id: `track-${trackId}`,
    title: `Track ${trackId}`,
    elements: buildElements(trackId),
    tracks,
    // hasButton: true,
    // link: 'www.google.com',
    isOpen: false,
  }
}
