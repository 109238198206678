import { TFunction } from "i18next";
import { CommonTranslation } from "./Common.Translation";

export class UsersTranslation extends CommonTranslation
{    
    constructor(translation: TFunction)
    {
        super(translation, "users");        
    }

    public get genericGetUsersError(): string
    {
        return super.getTextByMessageKey("genericGetUsersError");
    }

    public get logoutUserError(): string
    {
        return super.getTextByMessageKey("logoutUserError");
    }

}