
import { APIUtility } from "../Utility/APIUtility";
import { IHealthClient } from "./IHealthClient";

export class HealthClient implements IHealthClient {
  public async health(): Promise<void | undefined> {
    try {
      let apiUtility = new APIUtility();
      await apiUtility.checkHealth();
  } catch (err) {
      console.error(err);
  }
}
  public async checkClientVersion(): Promise<void | any | undefined> {
    try {
        let apiUtility = new APIUtility();
        await apiUtility.checkClientVersion();
    } catch (err) {
        console.error(err);
        return err;
    }
  }
}
