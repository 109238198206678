import * as React from "react";
import styles from "./LinkList.module.scss";
import {  
  PrimaryButton, 
  IColumn,
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode,
  ISelection,
  IObjectWithKey,  
  Selection,
  BaseButton,
  Button,
  Link,
  FontIcon
} from "@fluentui/react";
import {
  IBaseProperties,
  ICommonProperties,
} from "../../../Models/IBaseProperties";
import { style } from "dom-helpers";

export interface ILink {  
  url: string;
}

export interface ILinkListProps extends IBaseProperties {  
  items: ILink[];
  onRemoveItems: (items: ILink[])=>void;
}
export interface ILinkListState {  
  selectedItems: ILink[];  
  items: ILink[];
}

export class LinkList extends React.Component<ILinkListProps, ILinkListState> {    
  
  private readonly _columns: IColumn[] = [  
    {
      key: 'link',
      name: 'Link',
      // classNames: classNames.fileIconCell,
      // iconClassName: classNames.fileIconHeaderIcon,            
      fieldName: 'url',
      minWidth: 900,      
      data: 'string',
      onRender: (item: ILink) => {
        return (<div className={styles.linkContainer}> <Link rel="noreferrer" href={this.toLink(item)} target="_blank" >
            {item.url}
        </Link>      
        </div>    
        );
     }
    },
     {
       key: 'removeLink',
       name: '',
       // classNames: classNames.fileIconCell,
      // iconClassName: classNames.fileIconHeaderIcon,            
      fieldName: 'url',
      minWidth: 32,
      maxWidth: 32,      
      data: 'string',
      onRender: (item: ILink) => {
        return (
          <div className={styles.divRemove}>
            <FontIcon
              iconName="Delete"            
              onClick=
              {
                ()=> { this.props.onRemoveItems([item]);}
              }
            ></FontIcon>        
          </div>
        );
     },
    }
  ];
  private readonly _selection: ISelection<IObjectWithKey> = new Selection({
    onSelectionChanged: () => {
      this.setState(
        {
          selectedItems: this.getSelectionItems()
        }
      );
    },
  });        
 
  constructor(props: ILinkListProps) {
    
    super(props);            

    this.initializeState(props);

    this.bindEvents();
  }

  private toLink(item: ILink): string {
    if (item && item.url)
    {
      if (!item.url.toLowerCase().startsWith("http") && !item.url.startsWith("//"))
      {
        return "//" + item.url;        
      }
      else
      {
        return item.url;
      }
    }
    else
    {
      return '';
    }
  }

  private initializeState(props: ILinkListProps) {
    this.state = {
      selectedItems: [],      
      items: props.items
    };
  }

  private bindEvents() {    
    this.onClickRemoveButton = this.onClickRemoveButton.bind(this);
  }
  
  private onClickRemoveButton (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | BaseButton | Button | HTMLSpanElement, MouseEvent>)
  {
    const {selectedItems} = this.state;

    if (selectedItems && selectedItems.length > 0)
    {            
      this._selection.setAllSelected(false);

      this.props.onRemoveItems(selectedItems);
    }
  }

  private getSelectionItems(): ILink[] {
    
    const selection = this._selection.getSelection();
    
    return selection.map(t=> (t as ILink));    
  }

  private getKey(item: ILink, index?: number | undefined): string
  {
    const value = (item.url as string);
    return value;
  }

  private getItems(): any[]
  {
    return this.props.items;
  }
  
  render() {

    const showRemoveButton = this.state.selectedItems.length > 0;
    return (
      <React.Fragment>
        <div>          
          <div>
            <DetailsList     
                         
                items={this.getItems()}
                
                columns={this._columns}
                selectionMode={SelectionMode.none}
                //getKey={this.getKey}
                //setKey="multiple"
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}                
                //selection={this._selection}
                //selectionPreservedOnEmptyClick={true}                
                //enterModalSelectionOnTouch={true}
                //ariaLabelForSelectionColumn="Toggle selection"
                //ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                //checkButtonAriaLabel="Row checkbox"
              />
            </div>
            {showRemoveButton &&
              <div className="removeButtonContainer">
                <PrimaryButton
                  text="Remove selected links"
                  onClick={this.onClickRemoveButton}
                  disabled={this.state.selectedItems.length===0}
                ></PrimaryButton>
              </div>
          }
          </div>
      </React.Fragment>
    );
  }
}
