import PropTypes from 'prop-types'
import React from 'react'
import clsx from 'clsx'
import { navigate, views } from './utils/constants'
import { DefaultButton } from '@fluentui/react';
import { Constants } from '../../../../Utility/Constants'

class Toolbar extends React.Component {
  render() {
    let {
      localizer: { messages },
      label,
    } = this.props

    return (
      <div className="rbc-toolbar">
        <span className="">
          <button
            type="button"
            onClick={this.navigate.bind(null, navigate.TODAY)}
          >
            {messages.today}
          </button>
          {this.viewNamesGroup(messages)}
        </span>

        <span className="rbc-toolbar-label">
          <DefaultButton
              className={["btn-back"].join(' ')}
              text=""
              aria-label='Prev'
              iconProps={Constants.iChevronLeft}
              allowDisabledFocus
              onClick={this.navigate.bind(null, navigate.PREVIOUS)}
              disabled={false}
              checked={true}
          />
          {/* <button
            type="button"
            onClick={this.navigate.bind(null, navigate.PREVIOUS)}
          >
            {messages.previous}
          </button> */}
          <span className="selection" >
            {label}
          </span>
          {/* <button
            type="button"
            onClick={this.navigate.bind(null, navigate.NEXT)}
          >
            {messages.next}
          </button> */}
          <DefaultButton
              className={["btn-next"].join(' ')}
              text=""
              aria-label='Next'
              iconProps={Constants.iChevronRight}
              allowDisabledFocus
              onClick={this.navigate.bind(null, navigate.NEXT)}
              disabled={false}
              checked={true}
          />
        </span>
        
        <span className="rbc-btn-group">
          
        </span>

      </div>
    )
  }

  navigate = action => {
    this.props.onNavigate(action)
  }

  view = view => {
    this.props.onView(view)
  }

  viewNamesGroup(messages) {
    let viewNames = this.props.views
    const view = this.props.view

    if (viewNames.length > 1) {
      return viewNames.map(name => (
        <button
          type="button"
          key={name}
          className={clsx({ 'rbc-active': view === name })}
          onClick={this.view.bind(null, name)}
        >
          {messages[name]}
        </button>
      ))
    }
  }
}

Toolbar.propTypes = {
  view: PropTypes.string.isRequired,
  views: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.node.isRequired,
  localizer: PropTypes.object,
  onNavigate: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired,
}

export default Toolbar
