import * as React from "react";
import styles from "./BriefLinks.module.scss";
import {    
  IColumn,
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode, 
  Link,  
} from "@fluentui/react";
import {
  IBaseProperties,  
} from "../../../../Models/IBaseProperties";


export interface IBriefLinksProps extends IBaseProperties {  
  items: string[];  
}
export interface IBriefLinksState {
}

export class BriefLinks extends React.Component<IBriefLinksProps, IBriefLinksState> {    
  
  private readonly _columns: IColumn[] = [      
    {
      key: 'link',
      name: 'link',
      // classNames: classNames.fileIconCell,
      // iconClassName: classNames.fileIconHeaderIcon,            
      fieldName: 'name',
      minWidth: 400,        
      data: 'string',
       onRender: (item: string) => {        
         return(
           <div className={styles.linkRowStyle}>
            <Link href={item} target="_blank" >{item}</Link>            
            </div>
         )
       },
    },            
  ];    
  
  constructor(props: IBriefLinksProps) {
    
    super(props);            

    this.initializeState(props);

    this.bindEvents();
  }

  private initializeState(props: IBriefLinksProps) {
    this.state = {      
    };
  }

  private bindEvents() {    
    
  }

    
  render() {
    
    return (
      <React.Fragment>
          <div>            
            <DetailsList
                items={this.props.items}
                compact={true}
                selectionMode={SelectionMode.none}
                columns={this._columns}         
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={false}
              />
          </div>
      </React.Fragment>
    );
  }
}
