import * as React from "react";
import styles from "./SpinnerComponent.module.scss";
import {
  IStackProps,
  Label,
  Modal,
  Spinner,
  SpinnerSize,
  Stack,
} from "@fluentui/react";
import { IBaseProperties } from "../../../Models/IBaseProperties";

export interface ISpinnerComponentProps extends IBaseProperties {
  spinnerSize?: SpinnerSize;
  relative: boolean;
}

export interface ISpinnerComponentState {}

const tokens = {
  sectionStack: {
    childrenGap: 10,
  },
  spinnerStack: {
    childrenGap: 20,
  },
};
const rowProps: IStackProps = { horizontal: true, verticalAlign: "center" };

export class SpinnerComponent extends React.Component<
  ISpinnerComponentProps,
  ISpinnerComponentState
> {
  constructor(props: ISpinnerComponentProps) {
    super(props);
  }

  render() {
    return (
      <div
        className={
          this.props.relative ? styles.divOuterRelative : styles.divOuter
        }
      >
        <Stack tokens={tokens.sectionStack}>
          <Stack {...rowProps} tokens={tokens.spinnerStack}>
            <Spinner
              size={
                this.props.spinnerSize
                  ? this.props.spinnerSize
                  : SpinnerSize.large
              }
            />
          </Stack>
        </Stack>
      </div>
    );
  }
}
