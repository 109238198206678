import React from 'react'
import PropTypes from 'prop-types'

import Tracks from '.'
import Element from './Element'

const Track = ({ time, elements, isOpen, tracks, tracks2, clickElement }) => (
  <div className="tr-track">
    
    <div className="rt-track__elements">

      {elements && elements
        .filter(({ start, end }) => end > start)
        .map(element => (
          <Element key={element.id} time={time} clickElement={clickElement} tracks={tracks} tracks2={tracks2} element={element} {...element} />
        ))}
        
    </div>
  
    {isOpen && tracks && (tracks.length > 0) && <Tracks time={time} tracks={tracks} tracks2={tracks2} clickElement={clickElement} />}
    
  </div>
)

Track.propTypes = {
  time: PropTypes.shape({}).isRequired,
  isOpen: PropTypes.bool,
  elements: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  tracks: PropTypes.arrayOf(PropTypes.shape({})),
  tracks2: PropTypes.arrayOf(PropTypes.shape({})),
  clickElement: PropTypes.func,
}

Track.defaultProps = {
  clickElement: undefined,
}

export default Track
