import moment from 'moment';
declare global {
  export interface Date {
    addDays(days: number): Date;
    toEndOfDay(): Date;
    toStartOfDay(): Date;
    withoutTimeZone(): Date
    utc(): Date
    local(): Date
  }

}

Date.prototype.withoutTimeZone = function () {
  return new Date(Date.UTC(this.getFullYear(), this.getMonth(), this.getDate(), this.getHours(), this.getMinutes(), this.getSeconds()))
}

Date.prototype.local = function () {
  return moment.utc(this).local().toDate();
}

Date.prototype.utc = function () {
  return moment(this).utc().toDate();
}

Date.prototype.addDays = function (days: number) {
  const date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
}

Date.prototype.toEndOfDay = function () {
  const date = new Date(this.valueOf());
  date.setDate(date.getDate());
  date.setHours(23, 59, 59);
  return date;
}

Date.prototype.toStartOfDay = function () {
  const date = new Date(this.valueOf());
  date.setDate(date.getDate());
  date.setHours(0, 0, 0);
  return date;
}

export { };   