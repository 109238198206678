import React from 'react'
import PropTypes from 'prop-types'

import Row from './Row'

const Timebar = ({ time, rows, onClickDate, selectedDay }) => (
  <div className="rt-timebar">
    {/* todogiuseppe */}
    {rows.map(({ id, title, subtitle, cells, style }) => (
      <Row key={id} selectedDay={selectedDay} onClickDate={onClickDate} time={time} title={`${title}`} subtitle={subtitle} cells={cells.filter(cell => cell.start >= time.start && cell.end <= time.end)} style={style} />
    ))}
  </div>
)

Timebar.propTypes = {
  time: PropTypes.shape({}).isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onClickDate: PropTypes.func.isRequired,
  selectedDay: PropTypes.instanceOf(Date),
}

export default Timebar
