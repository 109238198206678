import { NotificationTranslation } from '../Translations/Notification.Translation';
import {User} from './User';

export interface INotificationHistory
{
    notificationId: number;
    id: number;
    title: string;
    type: NotificationType;
    subType: NotificationSubType;
    author: User;
    date: Date;
    identifier: string
}

export interface INotification
{
    id: number;
    name: string;
    type: NotificationType;
    subType: NotificationSubType;
    author?: User;
    date: Date;
    identifier: string;
    additionalInfo?: any;
}

export interface IRealTimeNotification
{
    id: number;
    name: string;
    type: NotificationType;
    subType: string;
    author?: User;
    date: Date;
    identifier: string;
    additionalInfo?: any;
}

export enum NotificationType
{
    Brief = 0,
    Micro = 1,
    Macro = 2,
    Unknown = 999,

}

export enum NotificationSubType
{
    briefaddition,
    briefsetpending,
    briefsetrework,
    briefapprovation,
    briefrejection,    
    briefinforequest,    
    briefactivitydelegation,
    briefapprovationdelegation,
    briefreferentsassignment,
    briefreferentsremoval,
    microactivityresourceassignment,
    microactivityresourceremoval,
    microactivitystatuschange,
    microactivityspocassignment, 
    microactivityspocremoval,          
    microactivitytaskcreation,   
    microactivitytaskcompletion, 
    microactivitytaskreopening, 
    macroactivityheadassignment,      
    macroactivityheadremoval,       
    macroactivityspocassignment,    
    macroactivityspocremoval,       
    openedbrief = 100,
    genericmessage = 200,
    internalbriefchanged = 200,
    Unknown = 999
}

export const NotificationUtilities = 
{
    getNotificationStringType(translation: NotificationTranslation, subType: NotificationSubType): string {
        
        let value = "Unknown";        
        let nameOfNotificationSubType = NotificationSubType[subType];

        value = translation.notificationByName(nameOfNotificationSubType);

        return value;
        
        // switch (subType) {
        //   case NotificationSubType.BriefSetPending:
        //     value = translation.briefsetpending;
        //     break;
        // case  NotificationSubType.BriefInfoRequest:
        //     value = translation.briefinforequest;
        //     break;
        //   case NotificationSubType.BriefSetRework:
        //     value = translation.briefsetrework;
        //     break;
        //   case NotificationSubType.BriefActivityDelegation:
        //     value = translation.briefactivitydelegation;
        //     break;          
        //   case NotificationSubType.BriefApprovation:
        //     value = translation.briefapprovation;
        //     break;
        // case NotificationSubType.BriefApprovationDelegation:
        //     value = translation.briefapprovationdelegation;
        //     break;
        //   case NotificationSubType.BriefAddition:
        //     value = translation.briefaddition;
        //     break;          
        //   case NotificationSubType.BriefRejection:
        //     value = translation.briefrejection;
        //     break;          
        // case NotificationSubType.MicroActivityResourceAssignment:
        //     value = translation.microactivityresourceassignment;
        //     break;
        // case NotificationSubType.MicroActivityResourceRemoval:
        //     value = translation.microactivityresourcetemoval;
        //     break;
        // case NotificationSubType.MicroActivityStatusChange:
        //     value = translation.microactivitystatuschange;
        //     break;
        // case NotificationSubType.MicroActivitySpocAssignment:
        //     value = translation.microActivitySpocAssignment;
        //     break;
        //   case NotificationSubType.MicroActivitySpocRemoval:
        //     value = translation.microActivitySpocRemoval;
        //     break;
        //   case NotificationSubType.MicroActivityTaskCreation:
        //     value = translation.microActivityTaskCreation;
        //     break;
        //   case NotificationSubType.MicroActivityTaskCompletion:
        //     value = translation.microActivityTaskCompletion;
        //     break;
        //   case NotificationSubType.MicroActivityTaskReopening:
        //     value = translation.microActivityTaskReopening;
        //     break;
        //   case NotificationSubType.MacroActivityHeadAssignment:
        //     value = translation.macroActivityHeadAssignment;
        //     break;
        //   case NotificationSubType.MacroActivityHeadRemoval:
        //     value = translation.macroActivityHeadRemoval;
        //     break;
        //   case NotificationSubType.MacroActivitySpocAssignment:
        //     value = translation.macroActivitySpocAssignment;
        //     break;
        //   case NotificationSubType.MacroActivitySpocRemoval:
        //     value = translation.macroActivitySpocRemoval;
        //     break;
        // }
    
        //
      }
}
