import React, { FocusEventHandler } from "react";
import {
  Autofill,
  IBasePicker,
  IBasePickerStyles,
  IBasePickerSuggestionsProps,
  ITag,
  TagPicker,
} from "@fluentui/react";
import { UserClient } from "../../../../../Clients/UserClient";
import { ConcurrentTask } from "../../../../../Utility/ConcurrentTasks";
import { IBaseProperties } from "../../../../../Models/IBaseProperties";
import { UserInfo } from "../../../../../Models/User";
import { UsersTranslation } from "../../../../../Translations/Users.Translation";
import { ToastNotificationType } from "../../../../../Models/ToastNote";

const makeCancelable = (
  promise: any
): { promise: Promise<unknown>; cancel(): void } => {
  let hasCanceled_ = false;
  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      (val: any) =>
        hasCanceled_ ? reject({ isCanceled: true }) : resolve(val),
      (error: any) =>
        hasCanceled_ ? reject({ isCanceled: true }) : reject(error)
    );
  });
  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true;
    },
  };
};

export interface IUserPickerProps extends IBaseProperties {
  styles?: Partial<IBasePickerStyles>;
  values: ITag[];
  onChange?: (selectedTags: ITag[], userId?: string) => void;
  onBlur?:  (selectedTags: ITag[], userId?: string) => void;
  minDigitBeforeSearch?: number;
}
export interface IUserPickerState {
  userId?: string;
  selectedUser: ITag[];
}

export class UserPicker extends React.Component<IUserPickerProps, IUserPickerState> {
  private readonly _usersTranslation: UsersTranslation;
  private readonly userPicker = React.createRef<IBasePicker<ITag>>();
  private readonly UserInfoToITag = (item: UserInfo): ITag =>
    ({ key: item.username, name: item.displayName } as ITag);

  private pickerSuggestionsProps: IBasePickerSuggestionsProps = {
    suggestionsHeaderText: "Suggested USER",
    noResultsFoundText: "No User found",
  };

  private readonly minDigitBeforeSearch: number;
  private readonly DEFAULTMINDIGIT = 3;
  private lastRequestCompleted: boolean = true;
  private pendingRequest: boolean = false;

  constructor(props: IUserPickerProps) {
    super(props);
    this._usersTranslation = new UsersTranslation(
      props.commonProps.translation
    );
    this.minDigitBeforeSearch = this.props.minDigitBeforeSearch
      ? this.props.minDigitBeforeSearch
      : this.DEFAULTMINDIGIT;

    this.state = {
      selectedUser: props.values,
    };

    this.bindEvents();
  }

  private readonly filterUser = (
    filter: string,
    selectedItems?: ITag[] | undefined
  ): ITag[] | PromiseLike<ITag[]> => {
    if (filter) {
      if (filter.length >= this.minDigitBeforeSearch) {
        this.pickerSuggestionsProps.noResultsFoundText = "No user found";

        const values = this.getUserFilterAsync(filter);

        return values;
      } else {
        this.pickerSuggestionsProps.noResultsFoundText = `Enter at least ${this.minDigitBeforeSearch}  characters`;
        return [];
      }
    } else {
      return [];
    }
  };

  private currentListContainsTag = (tag: ITag, tagList?: ITag[]) => {
    if (!tagList || !tagList.length || tagList.length === 0) {
      return false;
    }
    return tagList.some((compareTag) => compareTag.key === tag.key);
  };

  private readonly checkSelectedItem = (
    selectedItem?: ITag | undefined
  ): ITag | PromiseLike<ITag> | null => {
    if (
      selectedItem &&
      this.userPicker.current &&
      this.currentListContainsTag(
        selectedItem as ITag,
        this.userPicker.current.items
      )
    ) {
      return null;
    }

    return selectedItem as ITag;
  };

  private bindEvents() {
    this.pickerUserChange = this.pickerUserChange.bind(this);
    this.pickerUserBlur = this.pickerUserBlur.bind(this);
  }

  private pickerUserChange(items?: ITag[] | undefined): void {
    if (this.props.onChange) {
      if (items && items.length > 0) {
        const id: string = (items as ITag[])[0].key as string;

        this.setState({ userId: id, selectedUser: items });
        this.props.onChange(items, id);
      } else {
        this.setState({ userId: undefined, selectedUser: [] });
        this.props.onChange([], undefined);
      }
    }
  }

  private pickerUserBlur(event: React.FocusEvent<Autofill | HTMLInputElement>): void {
    if (this.props.onBlur) {
      if (this.userPicker.current?.items?.length && this.userPicker.current?.items?.length >= 1) {
        const id: string = (this.userPicker.current?.items as ITag[])[0].key as string;

        this.setState({ userId: id, selectedUser: this.userPicker.current?.items });
        this.props.onBlur(this.userPicker.current?.items, id);
      } else {
        this.setState({ userId: undefined, selectedUser: [] });
        this.props.onBlur([], undefined);
      }
    }
  }

  private readonly _concurrentTask: ConcurrentTask<
    UserInfo[] | undefined
  > = new ConcurrentTask<UserInfo[] | undefined>();

  private async getUserFilterAsync(filter: string): Promise<ITag[]> {
    const userclient: UserClient = new UserClient();

    const promise = () => userclient.getUsersByFilterAsync("briefReferent", filter);

    const result = await this._concurrentTask.executePromise(promise, []);

    if (result) {
      return this.pendingRequest ? [] : result.map(this.UserInfoToITag);
    } else {
      this.props.commonProps.toastComponent?.showMessage(
        this._usersTranslation.error,
        this._usersTranslation.genericGetUsersError,
        ToastNotificationType.ERROR
      );
      return [];
    }
  }

  render() {
    const jsxSpoc = (
      <TagPicker
        ref={this.userPicker}
        onItemSelected={this.checkSelectedItem}
        pickerSuggestionsProps={this.pickerSuggestionsProps}
        removeButtonAriaLabel="Remove"
        onResolveSuggestions={this.filterUser}
        resolveDelay={300}
        // itemLimit={1}
        onChange={this.pickerUserChange}
        onBlur={this.pickerUserBlur}
        selectedItems={this.state.selectedUser}
        styles={this.props.styles}
      ></TagPicker>
    );

    return jsxSpoc;
  }
}
