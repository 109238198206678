import * as React from "react";
import { filter as filteroperator } from "rxjs/operators";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";

// import { DateExtensions } from "../../../../Utility/DateExtensions";
import { ActivityTranslation } from "../../../../Translations/Activity.Translation";
import { Utility } from "../../../../Utility/Utility";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import withStyles from "@material-ui/core/styles/withStyles";

import { LoaderComponent } from "../../../Shared/LoaderComponent/LoaderComponent";
import { RangeCalendar } from "../../../Shared/RangeCalendar/RangeCalendar";

import "./ActivityDetail/ActivityDetails";

import styles from "./ListActivities.module.scss";

import {
  ColumnActionsMode,
  CommandBarButton,
  ContextualMenu,
  DefaultButton,
  DetailsRow,
  Dialog,
  DialogFooter,
  DialogType,
  DirectionalHint,
  DropdownMenuItemType,
  FontIcon,
  IContextualMenuProps,
  IDetailsListProps,
  IDetailsRowProps,
  IDetailsRowStyles,
  IDialogContentProps,
  IDropdownOption,
  IModalProps,
  mergeStyleSets,
  PrimaryButton,
  SearchBox,
  SelectionMode,
} from "@fluentui/react";

import {
  DetailsHeader,
  DetailsList,
  IColumn,
  IDetailsHeaderProps,
  IDetailsList,
  IGroup,
  IRenderFunction,
  IContextualMenuItem,
  isMac,
} from "office-ui-fabric-react";
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from "@material-ui/core";
import { ActivityDetails } from "./ActivityDetail/ActivityDetails";
import {
  ActivityPermissions,
  IActivityAllAttributes,
  IListActivities,
  IListActivitiesFilter,
  IOrderBy,
} from "../../../../Models/Activity";
import mockItems from "../../../../Mocks/ActivityMock.json";
import { IDateRangeValue } from "../../../Shared/RangeCalendar/IDateRangeValue";
import { Constants } from "../../../../Utility/Constants";
import {
  IBaseProperties,
  IRoute,
  Page,
} from "../../../../Models/IBaseProperties";

import {
  ToastNotificationPosition,
  ToastNotificationType,
} from "../../../../Models/ToastNote";
import { IActivityClient } from "../../../../Clients/IActivityClient";
import { IDeliverableClient } from "../../../../Clients/IDeliverableClient";
import "url-search-params-polyfill";
import { Observer, Subscription } from "rxjs";
import { ICalendar } from "../../../../Models/ICalendar";
import { ICategory } from "../../../../Models/ICategory";
import { ISensibility } from "../../../../Models/ISensibility";
import { ListActivitiesNavigation } from "./ListActivitiesNavigation/ListActivitiesNavigation";
import moment from "moment";

export interface IListActivitiesProps extends IBaseProperties {}

export interface IListActivitiesState {
  // allItemsWithMacro: IActivityAllAttributes[];
  // allMicro: IActivityAllAttributes[];
  activityContextualMenuProps?: IContextualMenuProps;
  filteredItems: IActivityAllAttributes[];
  calendars: ICalendar[];
  categories: IDropdownOption[];
  responsibilities: IDropdownOption[];
  sensibilities: IDropdownOption[];
  groups: IGroup[];
  showItemIndexInView: boolean;
  isCompactMode: boolean;
  columns: IColumn[];
  contextualMenuProps?: IContextualMenuProps;
  activitySearch: string;
  typeSearch: number;
  calendarSearch: number;
  categorySearch: number[];
  responsibilitySearch: number[];
  sensibilitySearch: number[];
  statusSearch: string;
  lastModifyStartSearch?: Date;
  lastModifyEndSearch?: Date;
  lastModifyRangeSearch?: IDateRangeValue;
  lastModifyStringSearch: string;
  activityDateStartSearch?: Date;
  activityDateEndSearch?: Date;
  activityDateRangeSearch?: IDateRangeValue;
  activityDateStringSearch: string;
  dueToStartSearch?: Date;
  dueToEndSearch?: Date;
  dueToRangeSearch?: IDateRangeValue;
  dueToStringSearch: string;
  activitySelected?: IActivityAllAttributes;
  statusSelected?: string;
  lastCount: number;
  isLoading: boolean;
  permissions: ActivityPermissions;
  showFilters: boolean;
  showMyActivities: boolean;

  showDeleteDialog: boolean;
  itemToDelete?: number;
  itemToDeleteIsMacro?: boolean;
  deleteAction?: (itemId: number, isMacro: boolean) => void;
}

const IconLeftExpansionPanelSummary = withStyles({
  expandIcon: {
    order: -1,
  },
})(ExpansionPanelSummary);

const mock: boolean = false;

export class ListActivities extends React.Component<
  IListActivitiesProps,
  IListActivitiesState
> {
  private _root = React.createRef<IDetailsList>();
  private _columns: IColumn[];
  private _observer?: IntersectionObserver;
  private readonly _translation: ActivityTranslation;
  private readonly _activityClient: IActivityClient;
  private readonly _deliverableClient: IDeliverableClient;
  private confirmDeleteDialogProps: IModalProps = { isBlocking: true };
  private _routingObserver?: Subscription;
  // private qs_page: string | null;
  // private qs_activity: string | null;
  // private qs_status: string | null;
  // private qs_activityType: string | null;

  constructor(props: IListActivitiesProps) {
    super(props);

    // var urlParams = new URLSearchParams(window.location.search);
    // this.qs_page = urlParams.get("page");
    // this.qs_activity = urlParams.get("activity");
    // this.qs_status = urlParams.get("status");
    // this.qs_activityType = urlParams.get("type");

    this._translation = new ActivityTranslation(
      this.props.commonProps.translation
    );
    this._activityClient = this.props.commonProps.clientCreator.createActivityClient();
    this._deliverableClient = this.props.commonProps.clientCreator.createDeliverableClient();

    this._onRenderDetailsHeader = this._onRenderDetailsHeader.bind(this);
    this._resetFilter = this._resetFilter.bind(this);

    this._getItems = this._getItems.bind(this);
    this._reloadActivities = this._reloadActivities.bind(this);
    this._getCount = this._getCount.bind(this);
    this._checkMicroByFilter = this._checkMicroByFilter.bind(this);
    this._handleObserver = this._handleObserver.bind(this);
    this._rangeCalendarLastModifyChange = this._rangeCalendarLastModifyChange.bind(
      this
    );
    this._rangeCalendarActivityDateChange = this._rangeCalendarActivityDateChange.bind(
      this
    );
    this._rangeCalendarDueToChange = this._rangeCalendarDueToChange.bind(this);
    this._clearRoute = this._clearRoute.bind(this);

    this._onRowClick = this._onRowClick.bind(this);
    this._onRenderRow = this._onRenderRow?.bind(this);
    this._onActivityContextualMenuDismissed = this._onActivityContextualMenuDismissed.bind(
      this
    );
    this._getActivityContextualMenuProps = this._getActivityContextualMenuProps.bind(
      this
    );

    this._deleteActivity = this._deleteActivity.bind(this);
    this._showDeleteDialog = this._showDeleteDialog.bind(this);
    this._onToggleFilter = this._onToggleFilter.bind(this);
    this._onChangeShowMyActivities = this._onChangeShowMyActivities.bind(this);

    this._observer = undefined;

    this._columns = [
      {
        ariaLabel: "Refresh List",
        key: "refresh",
        name: "",
        fieldName: "refresh",
        minWidth: 24,
        maxWidth: 24,
        isResizable: false,
        onColumnClick: async () => {
          await this.refreshList();
        },
        onRender: () => {
          return <div></div>;
        },
      },
      {
        key: "activity",
        ariaLabel: "Filter or Sort " + this._translation.activity,
        name: (<div>{this._translation.activity}</div>) as any,
        fieldName: "activity",
        minWidth: 100,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        onRender: (item: IActivityAllAttributes) => {
          return (
            <strong
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  this._onRowClick(item);
                }
              }}
              onClick={(ev) => {
                this._onRowClick(item);
              }}
              aria-label={"Filter or Sort activity"}
              className={styles.DetailsCell}
            >
              {item.name}
            </strong>
          );
        },
        // columnActionsMode: ColumnActionsMode.,
      },
      {
        key: "calendar",
        ariaLabel: "Filter or Sort " + this._translation.calendarHeaderText,
        name: this._translation.calendarHeaderText.toLocaleUpperCase(),
        fieldName: "calendar",
        minWidth: 150,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        onRender: (item: IActivityAllAttributes) => {
          return (
            <div
              aria-label={"View activity"}
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  this._onRowClick(item);
                }
              }}
              onClick={(ev) => {
                this._onRowClick(item);
              }}
              className={styles.DetailsCell}
            >
              {this.state.calendars.find((c) => c.key === item.calendar)?.value}
            </div>
          );
        },
      },
      {
        key: "type",
        ariaLabel: "Filter or Sort " + this._translation.typeHeaderText,
        name: this._translation.typeHeaderText,
        fieldName: "type",
        minWidth: 100,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        onRender: (item: IActivityAllAttributes) => {
          return (
            <div
              aria-label={"View activity"}
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  this._onRowClick(item);
                }
              }}
              onClick={(ev) => {
                this._onRowClick(item);
              }}
              className={styles.DetailsCell}
            >
              <strong className={styles.typeCell}>
                {item.isMacro ? "MACRO" : "MICRO"}
              </strong>
              {item.microActivities && item.microActivities.length > 0 ? (
                <div className={styles.countCell}>
                  {item.microActivities?.length}
                </div>
              ) : (
                <div></div>
              )}
            </div>
          );
        },
      },
      {
        key: "status",
        ariaLabel: "Filter or Sort " + this._translation.statusHeaderText,
        name: this._translation.statusHeaderText,
        fieldName: "status",
        minWidth: 150,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        onRender: (item: IActivityAllAttributes) => {
          let className: string = styles.iconStatus + " ";
          className += Utility.GetActivityClassNameFromStatus(
            item.status,
            true
          );
          return (
            <div
              aria-label={"View activity"}
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  this._onRowClick(item);
                }
              }}
              onClick={(ev) => {
                this._onRowClick(item);
              }}
              className={styles.DetailsCell}
            >
              <div
                className={className}
                // alt={item.fileType + " file icon"}
              />
              {item.status?.toLocaleUpperCase()}
            </div>
          );
        },
      },

      {
        key: "dateFrom",
        ariaLabel: "Filter or Sort " + this._translation.start,
        name: this._translation.start,
        fieldName: "dateFrom",
        minWidth: 80,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        onRender: (item: IActivityAllAttributes) => {
          return (
            <div
              aria-label={"View activity"}
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  this._onRowClick(item);
                }
              }}
              onClick={(ev) => {
                this._onRowClick(item);
              }}
              className={styles.DetailsCell}
            >
              {Utility.FormatDate(this._getDateFromAPIString(item.dateFrom))}
            </div>
          );
        },
      },
      {
        key: "dateTo",
        ariaLabel: "Filter or Sort " + this._translation.end,
        name: this._translation.end,
        fieldName: "dateTo",
        minWidth: 80,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        onRender: (item: IActivityAllAttributes) => {
          return (
            <div
              aria-label={"View activity"}
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  this._onRowClick(item);
                }
              }}
              onClick={(ev) => {
                this._onRowClick(item);
              }}
              className={styles.DetailsCell}
            >
              {Utility.FormatDate(this._getDateFromAPIString(item.dateTo))}
            </div>
          );
        },
      },
      {
        key: "lastUpdate",
        ariaLabel: "Filter or Sort " + this._translation.lastModify,
        name: this._translation.lastModify,
        fieldName: "lastUpdate",
        minWidth: 100,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        onRender: (item: IActivityAllAttributes) => {
          return (
            <div
              aria-label={"View activity"}
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  this._onRowClick(item);
                }
              }}
              onClick={(ev) => {
                this._onRowClick(item);
              }}
              className={styles.DetailsCell}
            >
              {Utility.FormatDate(item.lastUpdate)}
            </div>
          );
        },
      },
      // {
      //   key: "category",
      //   name: this._translation.activityCategory.toLocaleUpperCase(),
      //   fieldName: "category",
      //   minWidth: 100,
      //   isResizable: true,
      //   onColumnClick: this._onColumnClick,
      //   onRender: (item: IActivityAllAttributes) => {
      //     return (
      //       <div
      //         onClick={(ev) => {
      //           this._onRowClick(item);
      //         }}
      //         className={styles.DetailsCell}
      //       >
      //         {this.state.categories
      //           .filter(
      //             (c) => !item.isMacro && item.categories.includes(+c.key)
      //           )
      //           ?.map((x) => x?.text)
      //           .join(" - ")}
      //       </div>
      //     );
      //   },
      // },
      // {
      //   key: "responsibility",
      //   name: this._translation.activityResponsibility.toLocaleUpperCase(),
      //   fieldName: "responsibility",
      //   minWidth: 150,
      //   isResizable: true,
      //   onColumnClick: this._onColumnClick,
      //   onRender: (item: IActivityAllAttributes) => {
      //     return (
      //       <div
      //         onClick={(ev) => {
      //           this._onRowClick(item);
      //         }}
      //         className={styles.DetailsCell}
      //       >
      //         {this.state.responsibilities
      //           .filter(
      //             (c) => !item.isMacro && item.responsibilities.includes(+c.key)
      //           )
      //           ?.map((x) => x?.text)
      //           .join(" - ")}
      //       </div>
      //     );
      //   },
      // },
      // {
      //   key: "sensibility",
      //   name: this._translation.activitySensibility.toLocaleUpperCase(),
      //   fieldName: "sensibility",
      //   minWidth: 100,
      //   isResizable: true,
      //   onColumnClick: this._onColumnClick,
      //   onRender: (item: IActivityAllAttributes) => {
      //     return (
      //       <div
      //         onClick={(ev) => {
      //           this._onRowClick(item);
      //         }}
      //         className={styles.DetailsCell}
      //       >
      //         {
      //           this.state.sensibilities.find((c) => c.key === item.sensibility)
      //             ?.text
      //         }
      //       </div>
      //     );
      //   },
      // },
      {
        key: "prop",
        name: "",
        fieldName: "prop",
        minWidth: 100,
        isResizable: true,
        onRender: (item: IActivityAllAttributes) => {
          return (
            <div className={styles.DetailsCell}>
              {/* <img
                src={
                  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACACAQAAABpN6lAAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADdcAAA3XAUIom3gAAAAHdElNRQfiAgEMLBkBZB4mAAACBklEQVR42u2bsXHCQBREH04gMDkqgYAmaICm6IEKKIAGHNMBBRCQYxMQyQFD4BmwheD0v7X7Lv4zdzvS6bR7f0AEQ+YsmFJRAQcO7NjwwTlkNh1TseJIfWMcWVFFT68sI5acbi7+Ok4sGUVPsxQTtr8u/jq2TKKnWoIZ+0bLr6nZM4ue7quZPLD8iwS9egpGDR/+ny9Cj/aC5cPLr6lZRk/7VVR/7Pz3vwg9+SiuWi2/pmYVPfVXMLxz7GkyjgxLT++tuABzxq1rx8z/vwCLwOoUAkwDq1MI8NxOXvw7MCguwCfvT1R/PbGDNKL8E5Cc8gIcAqstQAYBdoHVKQTYBFanQP4ofGbdunbdD59Y/ndY3hCxJYa8KQrytjjIByMgH41dSBaOlvcDbiEdjxtjjDHGGGOMMSYaGyKdk8wS6xZxU1TcFhcPRsSjMflwVDwel78g4X6B1gL04pKU+wUCq1MI4H6BwOoGuF+guADJ8XV5C1Aa9wsEVqdA/ijsfgH/DssbIrbEkDdFQd4WB/lgBOSjsQvJwlHH48YYY4wxxhhjjJHDhkjnJLPEukXcFBW3xcWDEfFoTD4cFY/H5S9IuF+gtQC9uCTlfoHA6hQCuF8gsLoB7hcoLkByfF3eApTG/QKB1SmQPwq7X8C/w/KGiC0x5E1RkLfFQT4YAflo7EKycFQ+Hv8GZi55Q1ESbvsAAAAldEVYdGRhdGU6Y3JlYXRlADIwMTgtMDItMDFUMTI6NDQ6MjUrMDE6MDD1JibVAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDE4LTAyLTAxVDEyOjQ0OjI1KzAxOjAwhHueaQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAAASUVORK5CYII="
                }
                className={styles.iconProps}

                // alt={item.fileType + " file icon"}
              /> */}
              <CommandBarButton
                role="menuitem"
                aria-label="View actions for activity"
                className={styles.MenuProps}
                // styles={onRenderOverflowButtonStyles}
                menuIconProps={{ iconName: "MoreVertical" }}
                menuProps={this.state.activityContextualMenuProps}
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    if (item.id) {
                      this._getActivityContextualMenuProps(
                        item.id,
                        item.permissions,
                        item?.isMacro
                      ).then((props) => {
                        this.setState({
                          activityContextualMenuProps: props,
                        });
                      });
                    }
                  }
                }}
                onClick={(ev: React.MouseEvent<HTMLElement>) => {
                  if (item.id) {
                    this._getActivityContextualMenuProps(
                      item.id,
                      item.permissions,
                      item?.isMacro,
                      ev
                    ).then((props) => {
                      this.setState({
                        activityContextualMenuProps: props,
                      });
                    });
                  }
                }}
              />
            </div>
          );
        },
      },
    ];

    this.state = {
      // allItemsWithMacro: [],
      // allMicro: [],
      filteredItems: [],
      groups: [],
      calendars: [],
      categories: [],
      responsibilities: [],
      sensibilities: [],
      showItemIndexInView: false,
      isCompactMode: false,
      columns: this._columns,
      activitySearch: "",
      typeSearch: 0,
      calendarSearch: 0,
      categorySearch: [],
      responsibilitySearch: [],
      sensibilitySearch: [],
      lastModifyStringSearch: "",
      activityDateStringSearch: "",
      dueToStringSearch: "",
      statusSearch: "",
      activitySelected: undefined,
      isLoading: true,
      lastCount: 0,
      permissions: Utility.getDefaultActivityPermissions(),
      showDeleteDialog: false,
      showFilters: false,
      showMyActivities: false,
    };
  }

  private refreshList() {
    this.setState(
      {
        isLoading: true,
      },
      async () => {
        await this._reloadActivities();
      }
    );
  }

  public componentDidMount() {
    var urlParams = new URLSearchParams(window.location.search);
    // this.qs_page = urlParams.get("page");
    // this.qs_activity = urlParams.get("activity");
    // this.qs_status = urlParams.get("status");
    // this.qs_activityType = urlParams.get("type");
    const routeId =
      this.props.commonProps.additionalInfo &&
      this.props.commonProps.additionalInfo?.keysValues.id
        ? this.props.commonProps.additionalInfo?.keysValues.id
        : undefined;

    const routeMyActivities =
      this.props.commonProps.additionalInfo &&
      this.props.commonProps.additionalInfo.keysValues.showMyActivities
        ? this.props.commonProps.additionalInfo.keysValues.showMyActivities
        : undefined;

    const routeType =
      this.props.commonProps.additionalInfo &&
      this.props.commonProps.additionalInfo?.keysValues.type
        ? this.props.commonProps.additionalInfo?.keysValues.type
        : undefined;

    const routeStatus = this.props.commonProps.additionalInfo?.optionalValues
      ?.status
      ? this.props.commonProps.additionalInfo?.optionalValues?.status
      : undefined;

    if (routeId || routeStatus || routeMyActivities) {
      this._clearRoute();
    }

    if (routeStatus) {
      this.setState({ statusSearch: routeStatus.toLowerCase() });
    }

    Promise.all([
      this._activityClient.getCalendars(),
      this._activityClient.getCategories(),
      this._activityClient.getResponsibilities(),
      this._activityClient.getSensibilities(),
      this._getItems({
        idActivity: routeId ? parseInt(routeId) : undefined,
        activity: this.state.activitySearch,
        lastModifyStartDate: this.state.lastModifyStartSearch,
        lastModifyEndDate: this.state.lastModifyEndSearch,
        activityDateStartDate: this.state.activityDateStartSearch,
        activityDateEndDate: this.state.activityDateEndSearch,
        dueToStartDate: this.state.dueToStartSearch,
        dueToEndDate: this.state.dueToEndSearch,
        type: routeType ? parseInt(routeType) : this.state.typeSearch,
        calendar: this.state.calendarSearch,
        categories: this.state.categorySearch,
        responsibilities: this.state.responsibilitySearch,
        sensibilities: this.state.sensibilitySearch,
        status: routeStatus ? routeStatus : this.state.statusSearch,
        startIndex: this.state.filteredItems.length,
        showOnlyMyActivities: routeMyActivities
          ? true
          : this.state.showMyActivities,
      }),
    ]).then(
      ([calendars, categories, responsibilities, sensibilities, list]) => {
        //window.history.pushState({}, document.title, "/?page=Activities");
        const categoryItems = (categories || []).map(
          (o: ICategory): IDropdownOption => ({
            key: o.key,
            text: o.value,
            itemType: DropdownMenuItemType.Normal,
          })
        );
        categoryItems.unshift({
          key: 0,
          text: this._translation.selectCategory,
          itemType: DropdownMenuItemType.Normal,
        });

        const responsibilityItems = (responsibilities || []).map(
          (o: ICategory): IDropdownOption => ({
            key: o.key,
            text: o.value,
            itemType: DropdownMenuItemType.Normal,
          })
        );
        responsibilityItems.unshift({
          key: 0,
          text: this._translation.selectResponsibility,
          itemType: DropdownMenuItemType.Normal,
        });

        const sensibilityItems = (sensibilities || []).map(
          (o: ISensibility): IDropdownOption => ({
            key: o.key,
            text: o.value,
            itemType: DropdownMenuItemType.Normal,
          })
        );
        sensibilityItems.unshift({
          key: 0,
          text: this._translation.selectSensibility,
          itemType: DropdownMenuItemType.Normal,
        });

        this.setState({
          // allItemsWithMacro: items.items,
          // allMicro: items.allmicro,
          filteredItems: list.activities,
          calendars: calendars || [],
          categories: categoryItems || [],
          responsibilities: responsibilityItems || [],
          sensibilities: sensibilityItems || [],
          groups: [],
          showItemIndexInView: false,
          isCompactMode: false,
          columns: this._columns,
          activitySearch: routeId
            ? list.activities.filter((item) => item.id == parseInt(routeId))[0]
                .name
            : "",
          typeSearch: routeType ? parseInt(routeType) : 0,
          calendarSearch: 0,
          categorySearch: [],
          responsibilitySearch: [],
          sensibilitySearch: [],
          lastModifyStringSearch: "",
          activityDateStringSearch: "",
          dueToStringSearch: "",
          statusSearch: routeStatus ? routeStatus : "",
          activitySelected: routeType
            ? list.activities.filter(
                (item) => item.id == parseInt(routeId ? routeId : "0")
              )[0]
            : undefined,
          isLoading: false,
          lastCount: list.activities?.length,
          showMyActivities: routeMyActivities
            ? true
            : this.state.showMyActivities,
        });

        var options = {
          root: null,
          rootMargin: "0px",
          threshold: 1.0,
        };

        this._observer = new IntersectionObserver(
          this._handleObserver.bind(this),
          options
        );
        var obs = document.querySelector(".divInfiniteLoader");
        if (obs) this._observer.observe(obs);
      }
    );
  }

  private _clearRoute() {
    const route: IRoute = {
      page: Page.None,
      additionalInfo: {
        keysValues: {
          id: undefined,
          type: undefined,
          subtype: undefined,
          title: undefined,
          showMyActivities: undefined,
        },
      },
    };

    this.props.commonProps.onRoute?.next(route);
  }

  componentWillMount() {
    this.buildRouteObserver();
  }

  public componentWillUnmount() {
    if (this.state.showItemIndexInView) {
      const itemIndexInView = this._root.current!.getStartItemIndexInView();
    }
  }

  componentDidUpdate(prevProps: IListActivitiesProps) {
    if (prevProps.commonProps.onRoute !== this.props.commonProps.onRoute) {
      this.buildRouteObserver();
    }
  }

  private buildRouteObserver() {
    const observer: Observer<IRoute> = {
      next: (route: IRoute) => {
        if (route.additionalInfo.keysValues.id) {
          const id = Number.parseInt(route.additionalInfo.keysValues.id);
          const type = route.additionalInfo.keysValues.type;

          this._getItems({
            idActivity: id ? id : undefined,
            activity: "",
            lastModifyStartDate: undefined,
            lastModifyEndDate: undefined,
            type: type ? parseInt(type) : this.state.typeSearch,
            calendar: this.state.calendarSearch,
            categories: this.state.categorySearch,
            responsibilities: this.state.responsibilitySearch,
            sensibilities: this.state.sensibilitySearch,
            status: "",
            startIndex: 0,
            showOnlyMyActivities: this.state.showMyActivities,
          }).then((list) => {
            //window.history.pushState({}, document.title, "/?page=Activities");
            this.setState({
              // allItemsWithMacro: items.items,
              // allMicro: items.allmicro,
              filteredItems: list.activities,
              groups: [],
              showItemIndexInView: false,
              isCompactMode: false,
              columns: this._columns,
              activitySearch: id
                ? list.activities.filter((item) => item.id == id)[0].name
                : "",
              typeSearch: type ? parseInt(type) : 0,
              calendarSearch: 0,
              categorySearch: [],
              responsibilitySearch: [],
              sensibilitySearch: [],
              lastModifyStringSearch: "",
              activityDateStringSearch: "",
              dueToStringSearch: "",
              statusSearch: "",
              activitySelected: list.activities.filter(
                (item) => item.id == parseInt(id ? id.toString() : "0")
              )[0],
              isLoading: false,
              lastCount: list.activities?.length,
            });

            var options = {
              root: null,
              rootMargin: "0px",
              threshold: 1.0,
            };

            this._observer = new IntersectionObserver(
              this._handleObserver.bind(this),
              options
            );
            var obs = document.querySelector(".divInfiniteLoader");
            if (obs) this._observer.observe(obs);

            const item = this.state.filteredItems.find((t) => t.id === id);
            if (item) {
              this._onRowClick(item);
            }

            this._clearRoute();
          });
        } else if (route.additionalInfo.keysValues.showMyActivities) {
          this._getItems({
            idActivity: undefined,
            activity: "",
            lastModifyStartDate: undefined,
            lastModifyEndDate: undefined,
            type: this.state.typeSearch,
            calendar: this.state.calendarSearch,
            categories: this.state.categorySearch,
            responsibilities: this.state.responsibilitySearch,
            sensibilities: this.state.sensibilitySearch,
            status: "",
            startIndex: 0,
            showOnlyMyActivities: true,
          }).then((list) => {
            //window.history.pushState({}, document.title, "/?page=Activities");
            this.setState({
              // allItemsWithMacro: items.items,
              // allMicro: items.allmicro,
              filteredItems: list.activities,
              groups: [],
              showItemIndexInView: false,
              isCompactMode: false,
              columns: this._columns,
              activitySearch: "",
              typeSearch: 0,
              calendarSearch: 0,
              categorySearch: [],
              responsibilitySearch: [],
              sensibilitySearch: [],
              lastModifyStringSearch: "",
              activityDateStringSearch: "",
              dueToStringSearch: "",
              statusSearch: "",
              activitySelected: undefined,
              isLoading: false,
              lastCount: list.activities?.length,
            });

            var options = {
              root: null,
              rootMargin: "0px",
              threshold: 1.0,
            };

            this._observer = new IntersectionObserver(
              this._handleObserver.bind(this),
              options
            );
            var obs = document.querySelector(".divInfiniteLoader");
            if (obs) this._observer.observe(obs);

            this._clearRoute();
          });
        }
      },
      error: (err) => console.error("Observer got an error: " + err),
      complete: () => console.log("Observer got a complete notification"),
    };

    const observableRouting = this.props.commonProps.onRoute?.pipe(
      filteroperator((route) => route.page === Page.Activities)
    );

    this._routingObserver = observableRouting?.subscribe(observer);
  }

  private _handleObserver(entities: any, observer: any) {
    const y = entities[0];
    if (y.isIntersecting) {
      this._getItems({
        activity: this.state.activitySearch,
        lastModifyStartDate: this.state.lastModifyStartSearch,
        lastModifyEndDate: this.state.lastModifyEndSearch,
        activityDateStartDate: this.state.activityDateStartSearch,
        activityDateEndDate: this.state.activityDateEndSearch,
        dueToStartDate: this.state.dueToStartSearch,
        dueToEndDate: this.state.dueToEndSearch,
        type: this.state.typeSearch,
        calendar: this.state.calendarSearch,
        categories: this.state.categorySearch,
        responsibilities: this.state.responsibilitySearch,
        sensibilities: this.state.sensibilitySearch,
        status: this.state.statusSearch,
        startIndex: this.state.filteredItems.length,
        showOnlyMyActivities: this.state.showMyActivities,
      }).then((newItems) => {
        this.setState({
          filteredItems: this.state.filteredItems
            ? this.state.filteredItems.concat(newItems.activities)
            : newItems.activities,
          lastCount: this.state.filteredItems
            ? this.state.filteredItems.length + newItems.activities.length
            : newItems.activities.length,
          contextualMenuProps: undefined,
        });
      });
    }
  }

  private _showDeleteDialog(itemId: number, isMacro: boolean): void {
    this.setState({
      itemToDelete: itemId,
      showDeleteDialog: true,
      itemToDeleteIsMacro: isMacro,
      deleteAction: this._deleteActivity,
    });
  }

  private async _deleteActivity(
    itemId: number,
    isMacro: boolean
  ): Promise<boolean> {
    this.setState({ isLoading: true });

    var result = await this._activityClient.deleteActivity(
      itemId.toString(),
      isMacro
    );
    if (!result) {
      this.props.commonProps.toastComponent?.showMessage(
        this._translation.error,
        this._translation.deleteActivityError,
        ToastNotificationType.ERROR
      );
      this.setState({
        showDeleteDialog: false,
        itemToDelete: undefined,
        itemToDeleteIsMacro: undefined,
        deleteAction: undefined,
      });
    } else {
      var items = await this._getItems({
        idActivity: undefined,
        activity: this.state.activitySearch, //TO DELETE AFTER API UPDATE
        lastModifyStartDate: this.state.lastModifyStartSearch,
        lastModifyEndDate: this.state.lastModifyEndSearch,
        activityDateStartDate: this.state.activityDateStartSearch,
        activityDateEndDate: this.state.activityDateEndSearch,
        dueToStartDate: this.state.dueToStartSearch,
        dueToEndDate: this.state.dueToEndSearch,
        type: this.state.typeSearch,
        calendar: this.state.calendarSearch,
        categories: this.state.categorySearch,
        responsibilities: this.state.responsibilitySearch,
        sensibilities: this.state.sensibilitySearch,
        status: this.state.statusSearch,
        startIndex: 0,
        showOnlyMyActivities: this.state.showMyActivities,
      });

      this.setState({ filteredItems: [], activitySelected: undefined }, () => {
        this.setState({
          filteredItems: items.activities,
          isLoading: false,
          showDeleteDialog: false,
          itemToDelete: undefined,
          itemToDeleteIsMacro: undefined,
          deleteAction: undefined,
        });
      });
    }

    return true;
  }

  private _confirmDeleteDialogContentProps(): IDialogContentProps {
    return {
      type: DialogType.normal,
      title: this._translation.warningDeleteTitle,
      subText: this._translation.warningDeleteSubTitle,
    };
  }

  public render() {
    const {
      // allItemsWithMacro,
      // allMicro,
      filteredItems,
      groups,
      isCompactMode,
      contextualMenuProps,
      activityContextualMenuProps,
      activitySearch,
      activitySelected,
    } = this.state;

    this._columns = this._getColumns();

    return (
      <div>
        <LoaderComponent
          commonProps={this.props.commonProps}
          isLoading={this.state.isLoading}
        />
        <Dialog
          hidden={!this.state.showDeleteDialog}
          onDismiss={() => this.setState({ showDeleteDialog: false })}
          modalProps={this.confirmDeleteDialogProps}
          closeButtonAriaLabel={this._translation.cancel}
          dialogContentProps={this._confirmDeleteDialogContentProps()}
        >
          <DialogFooter>
            <PrimaryButton
              alt={this._translation.confirm}
              ariaLabel={this._translation.confirm}
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  if (
                    this.state.deleteAction &&
                    this.state.itemToDelete &&
                    this.state.itemToDeleteIsMacro != undefined
                  )
                    this.state.deleteAction(
                      this.state.itemToDelete,
                      this.state.itemToDeleteIsMacro
                    );
                }
              }}
              onClick={() => {
                if (
                  this.state.deleteAction &&
                  this.state.itemToDelete &&
                  this.state.itemToDeleteIsMacro != undefined
                )
                  this.state.deleteAction(
                    this.state.itemToDelete,
                    this.state.itemToDeleteIsMacro
                  );
              }}
              text={this._translation.confirm}
            />
            <DefaultButton
              alt={this._translation.cancel}
              ariaLabel={this._translation.cancel}
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  this.setState({ showDeleteDialog: false });
                }
              }}
              onClick={() => {
                this.setState({ showDeleteDialog: false });
              }}
              text={this._translation.cancel}
            />
          </DialogFooter>
        </Dialog>

        {this.state.activitySelected && (
          <ActivityDetails
            key="ActivityDetails"
            commonProps={this.props.commonProps}
            itemFromList={activitySelected}
            calendars={this.state.calendars}
            categories={this.state.categories}
            responsibilities={this.state.responsibilities}
            sensibilities={this.state.sensibilities}
            reloadActivities={this._reloadActivities}
            deleteActivity={this._showDeleteDialog}
          />
        )}
        <div className={styles.listContainer}>
          <ListActivitiesNavigation
            commonProps={this.props.commonProps}
            selectedFilterSensibility={this.state.sensibilitySearch.map((x) =>
              x.toString()
            )}
            selectedFilterCategory={this.state.categorySearch.map((x) =>
              x.toString()
            )}
            selectedFilterResponsibility={this.state.responsibilitySearch.map(
              (x) => x.toString()
            )}
            showFilters={this.state.showFilters}
            showMyActivities={this.state.showMyActivities}
            onChangeShowMyActivities={this._onChangeShowMyActivities}
            onChangeShowFilter={this._onToggleFilter}
            isSticky={true} //this.state.isSticky
            sensibilities={this.state.sensibilities}
            onChangeFilterSensibility={this.changeFilterSensibility.bind(this)}
            categories={this.state.categories}
            responsibilities={this.state.responsibilities}
            onChangeFilterCategory={this.changeFilterCategory.bind(this)}
            onChangeFilterResponsibility={this.changeFilterResponsibility.bind(
              this
            )}
          />
          <div
            role="activityList"
            className={
              this.state.showFilters
                ? styles.listactivities_withfilters
                : styles.listactivities_nofilter
            }
          >
            <DetailsList
              componentRef={this._root}
              items={filteredItems}
              selectionMode={SelectionMode.none}
              //groups={groups}
              className={styles.listActivities /*"listActivities"*/}
              columns={this._columns}
              onRenderDetailsHeader={this._onRenderDetailsHeader}
              groupProps={{
                showEmptyGroups: true,
              }}
              onItemInvoked={(item) => {
                this._onRowClick(item);
              }}
              onRenderItemColumn={this._onRenderColumn}
              compact={isCompactMode}
              onRenderRow={this._onRenderRow}
            />
            {(!filteredItems || filteredItems.length === 0) && (
              <div
                className={
                  "ms-Grid-col ms-xl3 ms-lg6 ms-md12 ms-sm12 paddingTop10 " +
                  styles.noActivitiedFound
                }
              >
                {this._translation.noActivityToDisplay}
              </div>
            )}
          </div>
        </div>

        {contextualMenuProps && <ContextualMenu {...contextualMenuProps} />}

        {activityContextualMenuProps && (
          <ContextualMenu {...activityContextualMenuProps} />
        )}

        <div className={"divInfiniteLoader"}></div>
      </div>
    );
  }

  private _resetFilter() {
    const { columns } = this.state;
    const newColumns: IColumn[] = columns.slice();
    newColumns.forEach((newCol: IColumn) => {
      newCol.isSorted = false;
      newCol.isSortedDescending = true;
    });

    this._getItems({
      activity: "",
      type: 0,
      status: "",
      startIndex: 0,
      showOnlyMyActivities: this.state.showMyActivities,
    }).then((items) => {
      this.setState({
        activitySearch: "",
        typeSearch: 0,
        calendarSearch: 0,
        categorySearch: [],
        responsibilitySearch: [],
        sensibilitySearch: [],
        lastModifyStringSearch: "",
        activityDateStringSearch: "",
        dueToStringSearch: "",
        lastModifyStartSearch: undefined,
        lastModifyEndSearch: undefined,
        lastModifyRangeSearch: undefined,
        activityDateStartSearch: undefined,
        activityDateEndSearch: undefined,
        activityDateRangeSearch: undefined,
        dueToStartSearch: undefined,
        dueToEndSearch: undefined,
        dueToRangeSearch: undefined,
        statusSearch: "",
        filteredItems: items.activities,
        columns: newColumns,
      });
    });
  }

  private _onToggleFilter(event: any) {
    this.setState({ showFilters: !this.state.showFilters });
  }

  private _onChangeShowMyActivities(item: any) {
    this._getItems({
      activity: this.state.activitySearch,
      lastModifyStartDate: this.state.lastModifyStartSearch,
      lastModifyEndDate: this.state.lastModifyEndSearch,
      type: this.state.typeSearch,
      calendar: this.state.calendarSearch,
      categories: this.state.categorySearch,
      responsibilities: this.state.responsibilitySearch,
      sensibilities: this.state.sensibilitySearch,
      status: this.state.statusSearch,
      startIndex: 0,
      showOnlyMyActivities: item.props.itemKey == "MY",
    }).then((filteredItems) => {
      this.setState({
        showMyActivities: item.props.itemKey == "MY",
        filteredItems: filteredItems.activities,
        contextualMenuProps: undefined,
      });
    });
  }

  public changeFilterSensibility(
    event: React.FormEvent<HTMLDivElement>,
    item?: IDropdownOption
  ) {
    if (item) {
      if (item.key == "allSensibilities") {
        this._getItems({
          activity: this.state.activitySearch,
          lastModifyStartDate: this.state.lastModifyStartSearch,
          lastModifyEndDate: this.state.lastModifyEndSearch,
          type: this.state.typeSearch,
          calendar: this.state.calendarSearch,
          categories: this.state.categorySearch,
          responsibilities: this.state.responsibilitySearch,
          sensibilities: [],
          status: this.state.statusSearch,
          startIndex: 0,
          showOnlyMyActivities: this.state.showMyActivities,
        }).then((filteredItems) => {
          this.setState({
            sensibilities: [],
            filteredItems: filteredItems.activities,
            contextualMenuProps: undefined,
          });
        });
      } else if (this.state.sensibilitySearch.includes(+item.key)) {
        this._getItems({
          activity: this.state.activitySearch,
          lastModifyStartDate: this.state.lastModifyStartSearch,
          lastModifyEndDate: this.state.lastModifyEndSearch,
          type: this.state.typeSearch,
          calendar: this.state.calendarSearch,
          categories: this.state.categorySearch,
          responsibilities: this.state.responsibilitySearch,
          sensibilities: this.state.sensibilitySearch.filter(
            (x) => x != +item.key
          ),
          status: this.state.statusSearch,
          startIndex: 0,
          showOnlyMyActivities: this.state.showMyActivities,
        }).then((filteredItems) => {
          this.setState({
            sensibilitySearch: this.state.sensibilitySearch.filter(
              (x) => x != +item.key
            ),
            filteredItems: filteredItems.activities,
            contextualMenuProps: undefined,
          });
        });
      } else {
        var sensSearch = this.state.sensibilitySearch;
        sensSearch.push(+item.key);
        this._getItems({
          activity: this.state.activitySearch,
          lastModifyStartDate: this.state.lastModifyStartSearch,
          lastModifyEndDate: this.state.lastModifyEndSearch,
          type: this.state.typeSearch,
          calendar: this.state.calendarSearch,
          categories: this.state.categorySearch,
          responsibilities: this.state.responsibilitySearch,
          sensibilities: sensSearch,
          status: this.state.statusSearch,
          startIndex: 0,
          showOnlyMyActivities: this.state.showMyActivities,
        }).then((filteredItems) => {
          this.setState({
            sensibilitySearch: sensSearch,
            filteredItems: filteredItems.activities,
            contextualMenuProps: undefined,
          });
        });
      }
    }
  }

  public changeFilterCategory(
    event: React.FormEvent<HTMLDivElement>,
    item?: IDropdownOption
  ) {
    if (item) {
      if (item.key == "allCategories") {
        this._getItems({
          activity: this.state.activitySearch,
          lastModifyStartDate: this.state.lastModifyStartSearch,
          lastModifyEndDate: this.state.lastModifyEndSearch,
          type: this.state.typeSearch,
          calendar: this.state.calendarSearch,
          categories: [],
          responsibilities: this.state.responsibilitySearch,
          sensibilities: this.state.sensibilitySearch,
          status: this.state.statusSearch,
          startIndex: 0,
          showOnlyMyActivities: this.state.showMyActivities,
        }).then((filteredItems) => {
          this.setState({
            categorySearch: [],
            filteredItems: filteredItems.activities,
            contextualMenuProps: undefined,
          });
        });
      } else if (this.state.categorySearch.includes(+item.key)) {
        this._getItems({
          activity: this.state.activitySearch,
          lastModifyStartDate: this.state.lastModifyStartSearch,
          lastModifyEndDate: this.state.lastModifyEndSearch,
          type: this.state.typeSearch,
          calendar: this.state.calendarSearch,
          categories: this.state.categorySearch.filter((x) => x != +item.key),
          responsibilities: this.state.responsibilitySearch,
          sensibilities: this.state.sensibilitySearch,
          status: this.state.statusSearch,
          startIndex: 0,
          showOnlyMyActivities: this.state.showMyActivities,
        }).then((filteredItems) => {
          this.setState({
            categorySearch: this.state.categorySearch.filter(
              (x) => x != +item.key
            ),
            filteredItems: filteredItems.activities,
            contextualMenuProps: undefined,
          });
        });
      } else {
        var catSearch = this.state.categorySearch;
        catSearch.push(+item.key);
        this._getItems({
          activity: this.state.activitySearch,
          lastModifyStartDate: this.state.lastModifyStartSearch,
          lastModifyEndDate: this.state.lastModifyEndSearch,
          type: this.state.typeSearch,
          calendar: this.state.calendarSearch,
          categories: catSearch,
          responsibilities: this.state.responsibilitySearch,
          sensibilities: this.state.sensibilitySearch,
          status: this.state.statusSearch,
          startIndex: 0,
          showOnlyMyActivities: this.state.showMyActivities,
        }).then((filteredItems) => {
          this.setState({
            categorySearch: catSearch,
            filteredItems: filteredItems.activities,
            contextualMenuProps: undefined,
          });
        });
      }
    }
  }

  public changeFilterResponsibility(
    event: React.FormEvent<HTMLDivElement>,
    item?: IDropdownOption
  ) {
    if (item) {
      if (item.key == "allResponsibilities") {
        this._getItems({
          activity: this.state.activitySearch,
          lastModifyStartDate: this.state.lastModifyStartSearch,
          lastModifyEndDate: this.state.lastModifyEndSearch,
          type: this.state.typeSearch,
          calendar: this.state.calendarSearch,
          categories: this.state.categorySearch,
          responsibilities: [],
          sensibilities: this.state.sensibilitySearch,
          status: this.state.statusSearch,
          startIndex: 0,
          showOnlyMyActivities: this.state.showMyActivities,
        }).then((filteredItems) => {
          this.setState({
            responsibilitySearch: [],
            filteredItems: filteredItems.activities,
            contextualMenuProps: undefined,
          });
        });
      } else if (this.state.responsibilitySearch.includes(+item.key)) {
        this._getItems({
          activity: this.state.activitySearch,
          lastModifyStartDate: this.state.lastModifyStartSearch,
          lastModifyEndDate: this.state.lastModifyEndSearch,
          type: this.state.typeSearch,
          calendar: this.state.calendarSearch,
          categories: this.state.categorySearch,
          responsibilities: this.state.responsibilitySearch.filter(
            (x) => x != +item.key
          ),
          sensibilities: this.state.sensibilitySearch,
          status: this.state.statusSearch,
          startIndex: 0,
          showOnlyMyActivities: this.state.showMyActivities,
        }).then((filteredItems) => {
          this.setState({
            responsibilitySearch: this.state.responsibilitySearch.filter(
              (x) => x != +item.key
            ),
            filteredItems: filteredItems.activities,
            contextualMenuProps: undefined,
          });
        });
      } else {
        var respSearch = this.state.responsibilitySearch;
        respSearch.push(+item.key);
        this._getItems({
          activity: this.state.activitySearch,
          lastModifyStartDate: this.state.lastModifyStartSearch,
          lastModifyEndDate: this.state.lastModifyEndSearch,
          type: this.state.typeSearch,
          calendar: this.state.calendarSearch,
          categories: this.state.categorySearch,
          responsibilities: respSearch,
          sensibilities: this.state.sensibilitySearch,
          status: this.state.statusSearch,
          startIndex: 0,
          showOnlyMyActivities: this.state.showMyActivities,
        }).then((filteredItems) => {
          this.setState({
            responsibilitySearch: respSearch,
            filteredItems: filteredItems.activities,
            contextualMenuProps: undefined,
          });
        });
      }
    }
  }

  private _onRenderDetailsHeader(
    props?: IDetailsHeaderProps,
    _defaultRender?: IRenderFunction<IDetailsHeaderProps>
  ) {
    const { columns } = this.state;
    let isSorted: boolean = false;
    columns.forEach((newCol: IColumn) => {
      if (newCol.isSorted) isSorted = true;
    });

    return (
      <div>
        {this.state.activitySearch ||
        this.state.typeSearch > 0 ||
        this.state.calendarSearch > 0 ||
        this.state.categorySearch.length > 0 ||
        this.state.responsibilitySearch.length > 0 ||
        this.state.sensibilitySearch.length > 0 ||
        this.state.lastModifyStartSearch ||
        this.state.activityDateStartSearch ||
        this.state.dueToStartSearch ||
        this.state.statusSearch ||
        isSorted ? (
          <div className={styles.divResetFilter}>
            <DefaultButton
              alt={this._translation.resetFilters}
              ariaLabel={this._translation.resetFilters}
              text={this._translation.resetFilters}
              iconProps={{ iconName: "ClearFilter" }}
              className={"btn btn-dismiss " + styles.btnDismiss}
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  this._resetFilter();
                }
              }}
              onClick={this._resetFilter}
            />
          </div>
        ) : (
          <div></div>
        )}
        <DetailsHeader
          {...props}
          className={styles.DetailsHeader}
          layoutMode={0}
        />
      </div>
    );
  }

  private _onRenderColumn(
    item?: IActivityAllAttributes,
    index?: number,
    column?: IColumn
  ) {
    const value =
      item && column && column.fieldName
        ? item[column.fieldName as keyof IActivityAllAttributes] || ""
        : "";

    return <div data-is-focusable={true}>{value}</div>;
  }

  private _onRenderRow: IDetailsListProps["onRenderRow"] = (props) => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    let result: any = null;
    if (props) {
      if (
        props.item.microActivities &&
        props.item.microActivities.length > 0 &&
        this.state.typeSearch !== 1
      ) {
        const renderMicros = (rowProps: IDetailsRowProps) => {
          return rowProps.item.microActivities.map(
            (element: any, index: number) => {
              if (
                // this._checkMicroByFilter(element, {
                //   activity: this.state.activitySearch,
                //   lastModifyStartDate: this.state.lastModifyStartSearch,
                //   lastModifyEndDate: this.state.lastModifyEndSearch,
                //   type: 2, //this.state.typeSearch,
                //   status: this.state.statusSearch,
                //   startIndex: 0,
                // })
                true
              ) {
                var rowMicroProps: IDetailsRowProps = {
                  columns: rowProps.columns,
                  selection: rowProps.selection,
                  selectionMode: rowProps.selectionMode,
                  item: element,
                  itemIndex: rowProps.itemIndex += 1,
                };
                // rowProps.itemIndex += 1;
                return (
                  <div
                    key={index}
                    className={styles.divRow + " ms-SecondaryRow"}
                  >
                    <div className={styles.divInnerSelection}>
                      <label
                        className={
                          this.state.activitySelected?.id ==
                            rowMicroProps.item.id &&
                          !this.state.activitySelected?.isMacro
                            ? styles.selected
                            : ""
                        }
                      ></label>
                    </div>
                    <DetailsRow
                      {...rowMicroProps}
                      key={"Micro" + element.id}
                      className={styles.ActivityRow}
                    />
                  </div>
                );
              }
            }
          );
        };

        result = (
          <div className={styles.divRow}>
            <div className={styles.divSelection + " hidden-tablet"}>
              <label
                className={
                  this.state.activitySelected?.id == props.item.id &&
                  this.state.activitySelected?.isMacro
                    ? styles.selected
                    : ""
                }
              ></label>
            </div>
            <ExpansionPanel expanded={props.item.expanded}>
              <IconLeftExpansionPanelSummary
                expandIcon={
                  <ExpandMoreIcon
                    tabIndex={0}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        var listItems: IActivityAllAttributes[] = Object.assign(
                          [],
                          this.state.filteredItems
                        );
                        var item = listItems.filter(
                          (activity) =>
                            activity.isMacro && activity.id == props.item.id
                        )[0];
                        item.expanded = !item.expanded;
                        this.setState({ filteredItems: listItems });
                      }
                    }}
                    onClick={() => {
                      var listItems: IActivityAllAttributes[] = Object.assign(
                        [],
                        this.state.filteredItems
                      );
                      var item = listItems.filter(
                        (activity) =>
                          activity.isMacro && activity.id == props.item.id
                      )[0];
                      item.expanded = !item.expanded;
                      this.setState({ filteredItems: listItems });
                    }}
                  />
                }
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <div>
                  <DetailsRow
                    {...props}
                    key={"Macro" + props.item.id}
                    className={styles.ActivityRow + " ms-MainRow"}
                  />
                </div>
              </IconLeftExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div>{renderMicros(props)}</div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
        );
      } else {
        result = (
          <div className={styles.divRow}>
            <div className={styles.divSelection + " hidden-tablet"}>
              <label
                className={
                  this.state.activitySelected?.id == props.item.id &&
                  !this.state.activitySelected?.isMacro
                    ? styles.selected
                    : ""
                }
              ></label>
            </div>
            <div className={styles.DetailsRowNoMicro}>
              <DetailsRow
                {...props}
                key={"Macro" + props.item.id}
                className={styles.ActivityRow + " ms-MainRow"}
              />
            </div>
          </div>
        );
      }
    }
    return result;
  };

  private _getDateFromAPIString(dateString: any): Date {
    return moment
      .utc(dateString)
      .local()
      .toDate();
  }

  private _onColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn
  ): void => {
    if (column.columnActionsMode !== ColumnActionsMode.disabled) {
      this._getContextualMenuProps(ev, column).then((contextMenuProps) => {
        this.setState({
          contextualMenuProps: contextMenuProps,
        });
      });
    }
  };

  private _onRowClick(activity: IActivityAllAttributes): void {
    var items = Object.assign(
      [] as IActivityAllAttributes[],
      this.state.filteredItems
    );
    activity.isMacro = activity.isMacro == undefined ? false : activity.isMacro;
    this.setState({ activitySelected: undefined }, () => {
      this.setState({
        activitySelected: activity,
        filteredItems: items,
      });
    });
  }

  private _onContextualMenuDismissed = (ev: any, dp: any): void => {
    this.setState({
      contextualMenuProps: undefined,
    });
  };

  private _getColumns(): IColumn[] {
    const refreshColumn: any = (
      <span>
        <FontIcon
          iconName="Refresh"
          className={styles.refreshButton}
        ></FontIcon>
      </span>
    );
    var activity = this.state.activitySearch
      ? ((
          <div>
            {this._translation.activity}{" "}
            <FontIcon
              iconName="Filter"
              className={styles.iconFilter}
            ></FontIcon>
          </div>
        ) as any)
      : this._translation.activity;
    var calendar = this.state.calendarSearch
      ? ((
          <div>
            {this._translation.activityCalendar.toLocaleUpperCase()}{" "}
            <FontIcon
              iconName="Filter"
              className={styles.iconFilter}
            ></FontIcon>
          </div>
        ) as any)
      : this._translation.activityCalendar.toLocaleUpperCase();
    var category =
      this.state.categorySearch.length > 0
        ? ((
            <div>
              {this._translation.activityCategory.toLocaleUpperCase()}{" "}
              <FontIcon
                iconName="Filter"
                className={styles.iconFilter}
              ></FontIcon>
            </div>
          ) as any)
        : this._translation.activityCategory.toLocaleUpperCase();
    var responsibility =
      this.state.responsibilitySearch.length > 0
        ? ((
            <div>
              {this._translation.activityResponsibility.toLocaleUpperCase()}{" "}
              <FontIcon
                iconName="Filter"
                className={styles.iconFilter}
              ></FontIcon>
            </div>
          ) as any)
        : this._translation.activityResponsibility.toLocaleUpperCase();
    var sensibility = this.state.sensibilitySearch
      ? ((
          <div>
            {this._translation.activitySensibility.toLocaleUpperCase()}{" "}
            <FontIcon
              iconName="Filter"
              className={styles.iconFilter}
            ></FontIcon>
          </div>
        ) as any)
      : this._translation.activitySensibility.toLocaleUpperCase();

    var type =
      this.state.typeSearch !== 0
        ? ((
            <div>
              {this._translation.typeHeaderText}{" "}
              <FontIcon
                iconName="Filter"
                className={styles.iconFilter}
              ></FontIcon>
            </div>
          ) as any)
        : this._translation.typeHeaderText;
    var status = this.state.statusSearch
      ? ((
          <div>
            {this._translation.statusHeaderText}{" "}
            <FontIcon
              iconName="Filter"
              className={styles.iconFilter}
            ></FontIcon>
          </div>
        ) as any)
      : this._translation.statusHeaderText;
    var activityDate =
      this.state.activityDateEndSearch && this.state.activityDateStartSearch
        ? ((
            <div>
              {this._translation.start}{" "}
              <FontIcon
                iconName="Filter"
                className={styles.iconFilter}
              ></FontIcon>
            </div>
          ) as any)
        : this._translation.start;
    var dueTo =
      this.state.dueToEndSearch && this.state.dueToStartSearch
        ? ((
            <div>
              {this._translation.end}{" "}
              <FontIcon
                iconName="Filter"
                className={styles.iconFilter}
              ></FontIcon>
            </div>
          ) as any)
        : this._translation.end;
    var lastModify =
      this.state.lastModifyEndSearch && this.state.lastModifyStartSearch
        ? ((
            <div>
              {this._translation.lastModify}{" "}
              <FontIcon
                iconName="Filter"
                className={styles.iconFilter}
              ></FontIcon>
            </div>
          ) as any)
        : this._translation.lastModify;

    var columns: IColumn[] = Object.assign([], this.state.columns);
    columns[0].name = refreshColumn;
    columns[1].name = activity;
    columns[2].name = calendar;
    columns[3].name = type;
    columns[4].name = status;
    columns[5].name = activityDate;
    columns[6].name = dueTo;
    columns[7].name = lastModify;
    // columns[8].name = category;
    // columns[9].name = responsibility;
    // columns[10].name = sensibility;

    return columns;
  }

  private async _reloadActivities() {
    var items = await this._getItems({
      idActivity: undefined,
      activity: this.state.activitySearch,
      lastModifyStartDate: this.state.lastModifyStartSearch,
      lastModifyEndDate: this.state.lastModifyEndSearch,
      activityDateStartDate: this.state.activityDateStartSearch,
      activityDateEndDate: this.state.activityDateEndSearch,
      dueToStartDate: this.state.dueToStartSearch,
      dueToEndDate: this.state.dueToEndSearch,
      type: this.state.typeSearch,
      calendar: this.state.calendarSearch,
      categories: this.state.categorySearch,
      responsibilities: this.state.responsibilitySearch,
      sensibilities: this.state.sensibilitySearch,
      status: this.state.statusSearch,
      startIndex: 0,
      showOnlyMyActivities: this.state.showMyActivities,
    });

    var activity = Object.assign({}, this.state.activitySelected);

    // var activity = items.activities.filter(
    //   (x) => x.id == this.state.activitySelected?.id
    // )[0];
    // if (activity) activity.status = status;

    this.setState(
      { filteredItems: [] /* activitySelected: undefined */ },
      () => {
        this.setState({
          // activitySelected: activity,
          filteredItems: items.activities,
          isLoading: false,
        });
      }
    );
  }

  private async _getItems(
    filter: IListActivitiesFilter
  ): Promise<IListActivities> {
    var columns = filter.orderBy ? filter.orderBy : this.state.columns;
    var orderBy: (IOrderBy | undefined)[] = columns.map((column: IColumn) => {
      if (column.isSorted) {
        switch (column.fieldName) {
          case "activity":
            return {
              field: "a",
              type: column.isSortedDescending ? "DESC" : "ASC",
            };
          case "type":
            return undefined;
          // return {
          //   field: column.fieldName,
          //   type: column.isSortedDescending ? "DESC" : "ASC",
          // };
          case "status":
            return {
              field: "as",
              type: column.isSortedDescending ? "DESC" : "ASC",
            };
          case "dateFrom":
            return {
              field: "df",
              type: column.isSortedDescending ? "DESC" : "ASC",
            };
          case "dateTo":
            return {
              field: "dt",
              type: column.isSortedDescending ? "DESC" : "ASC",
            };
          case "lastUpdate":
            return {
              field: "lm",
              type: column.isSortedDescending ? "DESC" : "ASC",
            };
        }
      }
    });

    if (!mock) {
      var items = await this._activityClient.getActivities(filter, orderBy);
      if (items == undefined) {
        this.props.commonProps.toastComponent?.showMessage(
          this._translation.error,
          this._translation.getActivitiesError,
          ToastNotificationType.ERROR
        );
        return { activities: [], count: 0 };
      } else {
        return items;
      }
    } else {
      var listItemsToFilter: IActivityAllAttributes[] =
        filter.type === 2
          ? mockItems.allmicro.map((x) => {
              return {
                isMacro: x.isMacro,
                id: x.id,
                name: x.name,
                description: x.description,
                dateFrom: new Date(this._getDateFromAPIString(x.dateFrom)),
                dateTo: new Date(this._getDateFromAPIString(x.dateTo)),
                lastUpdate: new Date(x.lastUpdate),
                status: x.status,
                parentMacroId: x.macroActivityId,
              } as IActivityAllAttributes;
            })
          : mockItems.items.map((x) => {
              return {
                permissions: Utility.getDefaultActivityPermissions(),
                isMacro: x.isMacro,
                expanded: false,
                id: x.id,
                name: x.name,
                calendar: x.calendar,
                description: x.description,
                dateFrom: new Date(this._getDateFromAPIString(x.dateFrom)),
                dateTo: new Date(this._getDateFromAPIString(x.dateTo)),
                lastUpdate: new Date(x.lastUpdate),
                categories: [], //x.categories,
                responsibilities: [], //x.responsibilities,
                status: x.status,
                microActivities: [],
                // x.microActivities.map((xMicro) => {
                //   return {
                //     isMacro: xMicro.isMacro,
                //     id: xMicro.id,
                //     name: xMicro.name,
                //     description: xMicro.description,
                //     dateFrom: new Date(xMicro.dateFrom),
                //     dateTo: new Date(xMicro.dateTo),
                //     lastUpdate: new Date(xMicro.lastUpdate),
                //     status: xMicro.status,
                //     parentMacroId: xMicro.macroActivityId,
                //     categories: [], //xMicro.categories,
                //     responsibilities: [], //xMicro.responsibilities
                //   } as IActivityAllAttributes;
                // }),
              } as IActivityAllAttributes;
            });

      var mockFilteredItems = listItemsToFilter
        .filter(
          (item) =>
            filter.activity === "" ||
            item.name.toUpperCase().indexOf(filter.activity.toUpperCase()) !==
              -1
        )
        .filter(
          (item) =>
            filter.type === 0 ||
            (filter.type === 1 && item.isMacro) ||
            (filter.type === 2 && !item.isMacro)
        )
        .filter(
          (item) =>
            filter.status === "" ||
            item.status === filter.status ||
            item.microActivities?.filter(
              (micro) => micro.status && micro.status === filter.status
            ).length
        )
        .filter(
          (item) =>
            !item.lastUpdate ||
            !filter.lastModifyStartDate ||
            !filter.lastModifyEndDate ||
            (item.lastUpdate >= filter.lastModifyStartDate &&
              item.lastUpdate <= filter.lastModifyEndDate)
        );

      return {
        count: mockFilteredItems.length,
        activities: mockFilteredItems,
      };
      // .filter(
      //   (item) =>
      //     !item.lastUpdate ||
      //     !filter.lastModifyStartDate ||
      //     !filter.lastModifyEndDate ||
      //     (item.lastUpdate >= filter.lastModifyStartDate &&
      //       item.lastUpdate <= filter.lastModifyEndDate) ||
      //     item.microActivities?.filter(
      //       (micro) =>
      //         !micro.lastUpdate ||
      //         !filter.lastModifyStartDate ||
      //         !filter.lastModifyEndDate ||
      //         (micro.lastUpdate >= filter.lastModifyStartDate &&
      //           micro.lastUpdate <= filter.lastModifyEndDate)
      //     ).length
      // );
    }
  }

  private async _getCount(filter: IListActivitiesFilter): Promise<number> {
    var items = this._getItems(filter);

    return (await items).count;
  }

  private _checkMicroByFilter(
    item: IActivityAllAttributes,
    filter: IListActivitiesFilter
  ): boolean {
    var listItemsToFilter = [item];

    return (
      listItemsToFilter
        .filter(
          (item) =>
            filter.activity === "" ||
            item.name.toUpperCase().indexOf(filter.activity.toUpperCase()) !==
              -1
        )
        // .filter(
        //   (item) =>
        //     filter.type === 0 ||
        //     (filter.type === 1 && item.isMacro) ||
        //     (filter.type === 2 && !item.isMacro)
        // )
        .filter(
          (item) =>
            filter.status === "" ||
            item.status === filter.status ||
            item.microActivities?.filter(
              (micro) => micro.status && micro.status === filter.status
            ).length
        )
        .filter(
          (item) =>
            !item.lastUpdate ||
            !filter.lastModifyStartDate ||
            !filter.lastModifyEndDate ||
            (new Date(item.lastUpdate) >= filter.lastModifyStartDate &&
              new Date(item.lastUpdate) <= filter.lastModifyEndDate)
        ).length > 0
    );
  }

  private _renderContextMenuActivity(item: IActivityAllAttributes): any {
    return (
      <div className={styles.DetailsCell}>
        <SearchBox
          className={"SearchBox"}
          value={this.state.activitySearch}
          placeholder={
            this.state.activitySearch
              ? this.state.activitySearch +
                " (" +
                this.state.filteredItems.length +
                ")"
              : this._translation.searchActivity
          }
          onSearch={(newValue) => {
            if (!newValue || newValue.length == 0 || newValue.length >= 3) {
              this._getItems({
                activity: newValue,
                type: this.state.typeSearch,
                calendar: this.state.calendarSearch,
                categories: this.state.categorySearch,
                responsibilities: this.state.responsibilitySearch,
                sensibilities: this.state.sensibilitySearch,
                status: this.state.statusSearch,
                lastModifyStartDate: this.state.lastModifyStartSearch,
                lastModifyEndDate: this.state.lastModifyEndSearch,
                startIndex: 0,
                showOnlyMyActivities: this.state.showMyActivities,
              }).then((items) => {
                this.setState({
                  activitySearch: newValue,
                  filteredItems: items.activities,
                  contextualMenuProps: undefined,
                });
              });
            }
          }}
          onChange={(newValue) => {
            if (
              !newValue ||
              newValue.target.value.length == 0 ||
              newValue.target.value.length >= 3
            ) {
              var text = newValue?.target.value;
              this._getItems({
                activity: text ? text : "",
                lastModifyStartDate: this.state.lastModifyStartSearch,
                lastModifyEndDate: this.state.lastModifyEndSearch,
                type: this.state.typeSearch,
                calendar: this.state.calendarSearch,
                categories: this.state.categorySearch,
                responsibilities: this.state.responsibilitySearch,
                sensibilities: this.state.sensibilitySearch,
                status: this.state.statusSearch,
                startIndex: 0,
                showOnlyMyActivities: this.state.showMyActivities,
              }).then((items) => {
                this.setState({
                  activitySearch: text ? text : "",
                  filteredItems: items.activities,
                });
              });
            }
          }}
        />
      </div>
    );
  }

  private _renderContextMenuLastModify(item: IActivityAllAttributes): any {
    return (
      <div className={styles.DetailsCell}>
        <RangeCalendar
          commonProps={this.props.commonProps}
          showTime={false}
          disableTime={false}
          onChange={this._rangeCalendarLastModifyChange}
          value={this.state.lastModifyRangeSearch}
        ></RangeCalendar>
      </div>
    );
  }

  private _renderContextMenuActivityDate(item: IActivityAllAttributes): any {
    return (
      <div className={styles.DetailsCell}>
        <RangeCalendar
          commonProps={this.props.commonProps}
          showTime={false}
          disableTime={false}
          onChange={this._rangeCalendarActivityDateChange}
          value={this.state.activityDateRangeSearch}
        ></RangeCalendar>
      </div>
    );
  }

  private _renderContextMenuDueTo(item: IActivityAllAttributes): any {
    return (
      <div className={styles.DetailsCell}>
        <RangeCalendar
          commonProps={this.props.commonProps}
          showTime={false}
          disableTime={false}
          onChange={this._rangeCalendarDueToChange}
          value={this.state.dueToRangeSearch}
        ></RangeCalendar>
      </div>
    );
  }

  private _rangeCalendarLastModifyChange(
    newValue?: IDateRangeValue | undefined
  ) {
    if (newValue) {
      this._getItems({
        activity: this.state.activitySearch,
        lastModifyStartDate: newValue.fromDate,
        lastModifyEndDate: newValue.toDate,
        activityDateStartDate: this.state.activityDateStartSearch,
        activityDateEndDate: this.state.activityDateEndSearch,
        dueToStartDate: this.state.dueToStartSearch,
        dueToEndDate: this.state.dueToEndSearch,
        type: this.state.typeSearch,
        calendar: this.state.calendarSearch,
        categories: this.state.categorySearch,
        responsibilities: this.state.responsibilitySearch,
        sensibilities: this.state.sensibilitySearch,
        status: this.state.statusSearch,
        startIndex: 0,
        showOnlyMyActivities: this.state.showMyActivities,
      }).then((items) => {
        this.setState({
          lastModifyStringSearch: newValue.fromDate
            ? Utility.FormatDate(newValue.fromDate) +
              " to " +
              Utility.FormatDate(newValue.toDate)
            : "",
          lastModifyStartSearch: newValue.fromDate,
          lastModifyEndSearch: newValue.toDate,
          filteredItems: items.activities,
          lastModifyRangeSearch: newValue,
          contextualMenuProps: undefined,
        });
      });
    } else {
      this._getItems({
        activity: this.state.activitySearch,
        lastModifyStartDate: undefined,
        lastModifyEndDate: undefined,
        activityDateStartDate: this.state.activityDateStartSearch,
        activityDateEndDate: this.state.activityDateEndSearch,
        dueToStartDate: this.state.dueToStartSearch,
        dueToEndDate: this.state.dueToEndSearch,
        type: this.state.typeSearch,
        calendar: this.state.calendarSearch,
        categories: this.state.categorySearch,
        responsibilities: this.state.responsibilitySearch,
        sensibilities: this.state.sensibilitySearch,
        status: this.state.statusSearch,
        startIndex: 0,
        showOnlyMyActivities: this.state.showMyActivities,
      }).then((items) => {
        this.setState({
          lastModifyStringSearch: "",
          lastModifyStartSearch: undefined,
          lastModifyEndSearch: undefined,
          lastModifyRangeSearch: undefined,
          filteredItems: items.activities,
          contextualMenuProps: undefined,
        });
      });
    }
  }

  private _rangeCalendarActivityDateChange(
    newValue?: IDateRangeValue | undefined
  ) {
    if (newValue) {
      this._getItems({
        activity: this.state?.activitySearch,
        lastModifyStartDate: this.state.lastModifyStartSearch,
        lastModifyEndDate: this.state.lastModifyEndSearch,
        activityDateStartDate: newValue.fromDate,
        activityDateEndDate: newValue.toDate,
        dueToStartDate: this.state.dueToStartSearch,
        dueToEndDate: this.state.dueToEndSearch,
        type: this.state.typeSearch,
        calendar: this.state.calendarSearch,
        categories: this.state.categorySearch,
        responsibilities: this.state.responsibilitySearch,
        sensibilities: this.state.sensibilitySearch,
        status: this.state.statusSearch,
        startIndex: 0,
        showOnlyMyActivities: this.state.showMyActivities,
      }).then((items) => {
        this.setState({
          activityDateStringSearch: newValue.fromDate
            ? Utility.FormatDate(newValue.fromDate) +
              " to " +
              Utility.FormatDate(newValue.toDate)
            : "",
          activityDateStartSearch: newValue.fromDate,
          activityDateEndSearch: newValue.toDate,
          filteredItems: items.activities,
          activityDateRangeSearch: newValue,
          contextualMenuProps: undefined,
        });
      });
    } else {
      this._getItems({
        activity: this.state.activitySearch,
        lastModifyStartDate: this.state.lastModifyStartSearch,
        lastModifyEndDate: this.state.lastModifyEndSearch,
        activityDateStartDate: undefined,
        activityDateEndDate: undefined,
        dueToStartDate: this.state.dueToStartSearch,
        dueToEndDate: this.state.dueToEndSearch,
        type: this.state.typeSearch,
        calendar: this.state.calendarSearch,
        categories: this.state.categorySearch,
        responsibilities: this.state.responsibilitySearch,
        sensibilities: this.state.sensibilitySearch,
        status: this.state.statusSearch,
        startIndex: 0,
        showOnlyMyActivities: this.state.showMyActivities,
      }).then((items) => {
        this.setState({
          activityDateStringSearch: "",
          activityDateStartSearch: undefined,
          activityDateEndSearch: undefined,
          activityDateRangeSearch: undefined,
          filteredItems: items.activities,
          contextualMenuProps: undefined,
        });
      });
    }
  }

  private _rangeCalendarDueToChange(newValue?: IDateRangeValue | undefined) {
    if (newValue) {
      this._getItems({
        activity: this.state?.activitySearch,
        lastModifyStartDate: this.state.lastModifyStartSearch,
        lastModifyEndDate: this.state.lastModifyEndSearch,
        activityDateStartDate: this.state.activityDateStartSearch,
        activityDateEndDate: this.state.activityDateEndSearch,
        dueToStartDate: newValue.fromDate,
        dueToEndDate: newValue.toDate,
        type: this.state.typeSearch,
        calendar: this.state.calendarSearch,
        categories: this.state.categorySearch,
        responsibilities: this.state.responsibilitySearch,
        sensibilities: this.state.sensibilitySearch,
        status: this.state.statusSearch,
        startIndex: 0,
        showOnlyMyActivities: this.state.showMyActivities,
      }).then((items) => {
        this.setState({
          dueToStringSearch: newValue.fromDate
            ? Utility.FormatDate(newValue.fromDate) +
              " to " +
              Utility.FormatDate(newValue.toDate)
            : "",
          dueToStartSearch: newValue.fromDate,
          dueToEndSearch: newValue.toDate,
          filteredItems: items.activities,
          dueToRangeSearch: newValue,
          contextualMenuProps: undefined,
        });
      });
    } else {
      this._getItems({
        activity: this.state.activitySearch,
        lastModifyStartDate: this.state.lastModifyStartSearch,
        lastModifyEndDate: this.state.lastModifyEndSearch,
        activityDateStartDate: this.state.activityDateStartSearch,
        activityDateEndDate: this.state.activityDateEndSearch,
        dueToStartDate: undefined,
        dueToEndDate: undefined,
        type: this.state.typeSearch,
        calendar: this.state.calendarSearch,
        categories: this.state.categorySearch,
        responsibilities: this.state.responsibilitySearch,
        sensibilities: this.state.sensibilitySearch,
        status: this.state.statusSearch,
        startIndex: 0,
        showOnlyMyActivities: this.state.showMyActivities,
      }).then((items) => {
        this.setState({
          dueToStringSearch: "",
          dueToStartSearch: undefined,
          dueToEndSearch: undefined,
          dueToRangeSearch: undefined,
          filteredItems: items.activities,
          contextualMenuProps: undefined,
        });
      });
    }
  }

  private async _getContextualMenuProps(
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn
  ): Promise<IContextualMenuProps> {
    var target = ev.currentTarget;

    var items: IContextualMenuItem[] =
      column.key === "type" ||
      column.key === "calendar" ||
      column.key === "category" ||
      column.key === "responsibility" ||
      column.key === "sensibility"
        ? []
        : [
            {
              key: "aToZ",
              name: "A to Z",
              iconProps: { iconName: "SortUp" },
              canCheck: true,
              checked: column.isSorted && !column.isSortedDescending,
              onClick: () => this._onSortColumn(column.key, false),
            },
            {
              key: "zToA",
              name: "Z to A",
              iconProps: { iconName: "SortDown" },
              canCheck: true,
              checked: column.isSorted && column.isSortedDescending,
              onClick: () => this._onSortColumn(column.key, true),
            },
            {
              key: "Divider",
              name: "Divider",
              canCheck: false,
              onRender: (item: IActivityAllAttributes) => {
                return (
                  <div className={styles.DetailsCell}>
                    <hr />
                  </div>
                );
              },
            },
          ];

    if (column.key === "activity") {
      items.push({
        key: "searchActivity",
        name: this._translation.searchActivity,
        onRender: (item: IActivityAllAttributes) =>
          this._renderContextMenuActivity(item),
      });
    } else if (column.key === "calendar") {
      items.push({
        key: "allCalendars",
        iconProps: {
          iconName: this.state.calendarSearch === 0 ? "Checkmark" : "",
        },
        name: this._translation.filterByCalendarAllCalendars,
        canCheck: true,
        onClick: () => {
          this._getItems({
            activity: this.state.activitySearch,
            lastModifyStartDate: this.state.lastModifyStartSearch,
            lastModifyEndDate: this.state.lastModifyEndSearch,
            type: this.state.typeSearch,
            calendar: 0,
            status: this.state.statusSearch,
            startIndex: 0,
            showOnlyMyActivities: this.state.showMyActivities,
          }).then((filteredItems) => {
            this.setState({
              calendarSearch: 0,
              filteredItems: filteredItems.activities,
              contextualMenuProps: undefined,
            });
          });
        },
      });
      this.state.calendars.forEach((c) => {
        items.push({
          key: `calendar-${c.key}`,
          iconProps: {
            iconName: this.state.calendarSearch === c.key ? "Checkmark" : "",
          },
          name: c.value,
          canCheck: true,
          onClick: () => {
            this._getItems({
              activity: this.state.activitySearch,
              lastModifyStartDate: this.state.lastModifyStartSearch,
              lastModifyEndDate: this.state.lastModifyEndSearch,
              type: this.state.typeSearch,
              calendar: c.key,
              status: this.state.statusSearch,
              startIndex: 0,
              showOnlyMyActivities: this.state.showMyActivities,
            }).then((filteredItems) => {
              this.setState({
                calendarSearch: c.key,
                filteredItems: filteredItems.activities,
                contextualMenuProps: undefined,
              });
            });
          },
        });
      });
    } else if (column.key === "category") {
      items.push({
        key: "allCategories",
        iconProps: {
          iconName: this.state.categorySearch.length === 0 ? "Checkmark" : "",
        },
        name: this._translation.filterByCategoryAllCategories,
        canCheck: true,
        onClick: () => {
          this._getItems({
            activity: this.state.activitySearch,
            lastModifyStartDate: this.state.lastModifyStartSearch,
            lastModifyEndDate: this.state.lastModifyEndSearch,
            type: this.state.typeSearch,
            calendar: this.state.calendarSearch,
            categories: [],
            responsibilities: this.state.responsibilitySearch,
            sensibilities: this.state.sensibilitySearch,
            status: this.state.statusSearch,
            startIndex: 0,
            showOnlyMyActivities: this.state.showMyActivities,
          }).then((filteredItems) => {
            this.setState({
              categorySearch: [],
              filteredItems: filteredItems.activities,
              contextualMenuProps: undefined,
            });
          });
        },
      });
      this.state.categories
        .filter((s) => +s.key > 0)
        .forEach((c) => {
          items.push({
            key: `category-${c.key}`,
            iconProps: {
              iconName: this.state.categorySearch.includes(+c.key)
                ? "Checkmark"
                : "",
            },
            name: c.text,
            canCheck: true,
            onClick: () => {
              if (this.state.categorySearch.includes(+c.key)) {
                this._getItems({
                  activity: this.state.activitySearch,
                  lastModifyStartDate: this.state.lastModifyStartSearch,
                  lastModifyEndDate: this.state.lastModifyEndSearch,
                  type: this.state.typeSearch,
                  calendar: this.state.calendarSearch,
                  categories: this.state.categorySearch.filter(
                    (x) => x != +c.key
                  ),
                  responsibilities: this.state.responsibilitySearch,
                  sensibilities: this.state.sensibilitySearch,
                  status: this.state.statusSearch,
                  startIndex: 0,
                  showOnlyMyActivities: this.state.showMyActivities,
                }).then((filteredItems) => {
                  this.setState({
                    categorySearch: this.state.categorySearch.filter(
                      (x) => x != +c.key
                    ),
                    filteredItems: filteredItems.activities,
                    contextualMenuProps: undefined,
                  });
                });
              } else {
                var catSearch = this.state.categorySearch;
                catSearch.push(+c.key);
                this._getItems({
                  activity: this.state.activitySearch,
                  lastModifyStartDate: this.state.lastModifyStartSearch,
                  lastModifyEndDate: this.state.lastModifyEndSearch,
                  type: this.state.typeSearch,
                  calendar: this.state.calendarSearch,
                  categories: catSearch,
                  responsibilities: this.state.responsibilitySearch,
                  sensibilities: this.state.sensibilitySearch,
                  status: this.state.statusSearch,
                  startIndex: 0,
                  showOnlyMyActivities: this.state.showMyActivities,
                }).then((filteredItems) => {
                  this.setState({
                    categorySearch: catSearch,
                    filteredItems: filteredItems.activities,
                    contextualMenuProps: undefined,
                  });
                });
              }
            },
          });
        });
    } else if (column.key === "responsibility") {
      items.push({
        key: "allResponsibilities",
        iconProps: {
          iconName:
            this.state.responsibilitySearch.length === 0 ? "Checkmark" : "",
        },
        name: this._translation.filterByResponsibilityAllResponsibilities,
        canCheck: true,
        onClick: () => {
          this._getItems({
            activity: this.state.activitySearch,
            lastModifyStartDate: this.state.lastModifyStartSearch,
            lastModifyEndDate: this.state.lastModifyEndSearch,
            type: this.state.typeSearch,
            calendar: this.state.calendarSearch,
            categories: this.state.categorySearch,
            responsibilities: [],
            sensibilities: this.state.sensibilitySearch,
            status: this.state.statusSearch,
            startIndex: 0,
            showOnlyMyActivities: this.state.showMyActivities,
          }).then((filteredItems) => {
            this.setState({
              responsibilitySearch: [],
              filteredItems: filteredItems.activities,
              contextualMenuProps: undefined,
            });
          });
        },
      });
      this.state.responsibilities
        .filter((s) => +s.key > 0)
        .forEach((c) => {
          items.push({
            key: `responsibility-${c.key}`,
            iconProps: {
              iconName: this.state.responsibilitySearch.includes(+c.key)
                ? "Checkmark"
                : "",
            },
            name: c.text,
            canCheck: true,
            onClick: () => {
              if (this.state.responsibilitySearch.includes(+c.key)) {
                this._getItems({
                  activity: this.state.activitySearch,
                  lastModifyStartDate: this.state.lastModifyStartSearch,
                  lastModifyEndDate: this.state.lastModifyEndSearch,
                  type: this.state.typeSearch,
                  calendar: this.state.calendarSearch,
                  categories: this.state.categorySearch,
                  responsibilities: this.state.responsibilitySearch.filter(
                    (x) => x != +c.key
                  ),
                  sensibilities: this.state.sensibilitySearch,
                  status: this.state.statusSearch,
                  startIndex: 0,
                  showOnlyMyActivities: this.state.showMyActivities,
                }).then((filteredItems) => {
                  this.setState({
                    responsibilitySearch: this.state.responsibilitySearch.filter(
                      (x) => x != +c.key
                    ),
                    filteredItems: filteredItems.activities,
                    contextualMenuProps: undefined,
                  });
                });
              } else {
                var respSearch = this.state.responsibilitySearch;
                respSearch.push(+c.key);
                this._getItems({
                  activity: this.state.activitySearch,
                  lastModifyStartDate: this.state.lastModifyStartSearch,
                  lastModifyEndDate: this.state.lastModifyEndSearch,
                  type: this.state.typeSearch,
                  calendar: this.state.calendarSearch,
                  categories: this.state.categorySearch,
                  responsibilities: respSearch,
                  sensibilities: this.state.sensibilitySearch,
                  status: this.state.statusSearch,
                  startIndex: 0,
                  showOnlyMyActivities: this.state.showMyActivities,
                }).then((filteredItems) => {
                  this.setState({
                    responsibilitySearch: respSearch,
                    filteredItems: filteredItems.activities,
                    contextualMenuProps: undefined,
                  });
                });
              }
            },
          });
        });
    } else if (column.key === "sensibility") {
      items.push({
        key: "allSensibilities",
        iconProps: {
          iconName:
            this.state.sensibilitySearch.length === 0 ? "Checkmark" : "",
        },
        name: this._translation.filterBySensibilityAllSensibilities,
        canCheck: true,
        onClick: () => {
          this._getItems({
            activity: this.state.activitySearch,
            lastModifyStartDate: this.state.lastModifyStartSearch,
            lastModifyEndDate: this.state.lastModifyEndSearch,
            type: this.state.typeSearch,
            calendar: this.state.calendarSearch,
            categories: this.state.categorySearch,
            responsibilities: this.state.responsibilitySearch,
            sensibilities: [],
            status: this.state.statusSearch,
            startIndex: 0,
            showOnlyMyActivities: this.state.showMyActivities,
          }).then((filteredItems) => {
            this.setState({
              sensibilities: [],
              filteredItems: filteredItems.activities,
              contextualMenuProps: undefined,
            });
          });
        },
      });
      this.state.sensibilities
        .filter((s) => +s.key > 0)
        .forEach((c) => {
          items.push({
            key: `sensibility-${c.key}`,
            iconProps: {
              iconName: this.state.sensibilitySearch.includes(+c.key)
                ? "Checkmark"
                : "",
            },
            name: c.text,
            canCheck: true,
            onClick: () => {
              if (this.state.sensibilitySearch.includes(+c.key)) {
                this._getItems({
                  activity: this.state.activitySearch,
                  lastModifyStartDate: this.state.lastModifyStartSearch,
                  lastModifyEndDate: this.state.lastModifyEndSearch,
                  type: this.state.typeSearch,
                  calendar: this.state.calendarSearch,
                  categories: this.state.categorySearch,
                  responsibilities: this.state.responsibilitySearch,
                  sensibilities: this.state.sensibilitySearch.filter(
                    (x) => x != +c.key
                  ),
                  status: this.state.statusSearch,
                  startIndex: 0,
                  showOnlyMyActivities: this.state.showMyActivities,
                }).then((filteredItems) => {
                  this.setState({
                    sensibilitySearch: this.state.sensibilitySearch.filter(
                      (x) => x != +c.key
                    ),
                    filteredItems: filteredItems.activities,
                    contextualMenuProps: undefined,
                  });
                });
              } else {
                var sensSearch = this.state.sensibilitySearch;
                sensSearch.push(+c.key);
                this._getItems({
                  activity: this.state.activitySearch,
                  lastModifyStartDate: this.state.lastModifyStartSearch,
                  lastModifyEndDate: this.state.lastModifyEndSearch,
                  type: this.state.typeSearch,
                  calendar: this.state.calendarSearch,
                  categories: this.state.categorySearch,
                  responsibilities: this.state.responsibilitySearch,
                  sensibilities: sensSearch,
                  status: this.state.statusSearch,
                  startIndex: 0,
                  showOnlyMyActivities: this.state.showMyActivities,
                }).then((filteredItems) => {
                  this.setState({
                    sensibilitySearch: sensSearch,
                    filteredItems: filteredItems.activities,
                    contextualMenuProps: undefined,
                  });
                });
              }
            },
          });
        });
    } else if (column.key === "type") {
      items.push({
        key: "allTypes",
        iconProps: { iconName: this.state.typeSearch === 0 ? "Checkmark" : "" },
        name: this._translation.filterByTypeAllActivities, //, countAllTypes),
        canCheck: true,
        onClick: () => {
          this._getItems({
            activity: this.state.activitySearch,
            lastModifyStartDate: this.state.lastModifyStartSearch,
            lastModifyEndDate: this.state.lastModifyEndSearch,
            type: 0,
            status: this.state.statusSearch,
            startIndex: 0,
            showOnlyMyActivities: this.state.showMyActivities,
          }).then((filteredItems) => {
            this.setState({
              typeSearch: 0,
              filteredItems: filteredItems.activities,
              contextualMenuProps: undefined,
            });
          });
        },
      });
      items.push({
        key: "isMicro",
        iconProps: { iconName: this.state.typeSearch === 2 ? "Checkmark" : "" },
        name: this._translation.filterByTypeOnlyMicro, // (" + countMicro + ")",
        canCheck: true,
        onClick: () => {
          this._getItems({
            activity: this.state.activitySearch,
            lastModifyStartDate: this.state.lastModifyStartSearch,
            lastModifyEndDate: this.state.lastModifyEndSearch,
            type: 2,
            status: this.state.statusSearch,
            startIndex: 0,
            showOnlyMyActivities: this.state.showMyActivities,
          }).then((filteredItems) => {
            this.setState({
              typeSearch: 2,
              filteredItems: filteredItems.activities,
              contextualMenuProps: undefined,
            });
          });
        },
      });
      items.push({
        key: "isMacro",
        iconProps: { iconName: this.state.typeSearch === 1 ? "Checkmark" : "" },
        name: this._translation.filterByTypeOnlyMacro, // (" + countMacro + ")",
        canCheck: true,
        onClick: () => {
          this._getItems({
            activity: this.state.activitySearch,
            lastModifyStartDate: this.state.lastModifyStartSearch,
            lastModifyEndDate: this.state.lastModifyEndSearch,
            type: 1,
            status: this.state.statusSearch,
            startIndex: 0,
            showOnlyMyActivities: this.state.showMyActivities,
          }).then((filteredItems) => {
            this.setState({
              typeSearch: 1,
              filteredItems: filteredItems.activities,
              contextualMenuProps: undefined,
            });
          });
        },
      });
    } else if (column.key === "status") {
      // var countAllStatus: number = await this._getCount({
      //   activity: this.state.activitySearch,
      //   lastModifyStartDate: this.state.lastModifyStartSearch,
      //   lastModifyEndDate: this.state.lastModifyStartSearch,
      //   type: 2, //this.state.typeSearch,
      //   status: "",
      //   startIndex: 0,
      // });

      items.push({
        key: "allStatus",
        iconProps: {
          iconName: this.state.statusSearch === "" ? "Checkmark" : "",
        },
        name: this._translation.all(true), // (" + countAllStatus + ")",
        canCheck: true,
        onClick: () => {
          this._getItems({
            activity: this.state.activitySearch,
            lastModifyStartDate: this.state.lastModifyStartSearch,
            lastModifyEndDate: this.state.lastModifyEndSearch,
            type: this.state.typeSearch,
            calendar: this.state.calendarSearch,
            categories: this.state.categorySearch,
            responsibilities: this.state.responsibilitySearch,
            sensibilities: this.state.sensibilitySearch,
            status: "",
            startIndex: 0,
            showOnlyMyActivities: this.state.showMyActivities,
          }).then((filteredItems) => {
            this.setState({
              statusSearch: "",
              filteredItems: filteredItems.activities,
              contextualMenuProps: undefined,
            });
          });
        },
      });

      if (this.state.typeSearch !== 1) {
        Constants.ActivityStatus.forEach(async (status) => {
          // var itemsFilteredByState = await this._getItems({
          //   activity: this.state.activitySearch,
          //   startIndex: this.state.lastCount,
          //   type: 2,
          //   lastModifyEndDate: this.state.lastModifyEndSearch,
          //   lastModifyStartDate: this.state.lastModifyStartSearch,
          //   orderBy: this.state.columns,
          //   status: status
          // });
          // if (itemsFilteredByState.count > 0) {
          items.push({
            key: "is" + status,
            iconProps: {
              iconName:
                this.state.statusSearch.toUpperCase() === status.toUpperCase()
                  ? "Checkmark"
                  : "",
            },
            name: status?.toUpperCase(), // + " (" + itemsFilteredByState.count + ")",
            canCheck: true,
            onClick: () => {
              this._getItems({
                activity: this.state.activitySearch,
                lastModifyStartDate: this.state.lastModifyStartSearch,
                lastModifyEndDate: this.state.lastModifyEndSearch,
                type: this.state.typeSearch,
                calendar: this.state.calendarSearch,
                categories: this.state.categorySearch,
                responsibilities: this.state.responsibilitySearch,
                sensibilities: this.state.sensibilitySearch,
                status: status,
                startIndex: 0,
                showOnlyMyActivities: this.state.showMyActivities,
              }).then((filteredItems) => {
                this.setState({
                  statusSearch: status ? status : "",
                  filteredItems: filteredItems.activities,
                  contextualMenuProps: undefined,
                });
              });
            },
          });
          // }
        });
      }
    } else if (column.key === "lastUpdate") {
      items.push({
        key: "searchDates",
        name: this._translation.selectDate,
        canCheck: false,
        target: "DPO",
        onRender: (item: IActivityAllAttributes) =>
          this._renderContextMenuLastModify(item),
      });
    } else if (column.key === "dateFrom") {
      items.push({
        key: "activityDateSearchDates",
        name: this._translation.selectDate,
        canCheck: false,
        target: "DPO",
        onRender: (item: IActivityAllAttributes) =>
          this._renderContextMenuActivityDate(item),
      });
    } else if (column.key === "dateTo") {
      items.push({
        key: "dueToSearchDates",
        name: this._translation.selectDate,
        canCheck: false,
        target: "DPO",
        onRender: (item: IActivityAllAttributes) =>
          this._renderContextMenuDueTo(item),
      });
    }

    return {
      items: items,
      target: target as HTMLElement,
      directionalHint: DirectionalHint.bottomLeftEdge,
      gapSpace: 10,
      isBeakVisible: false,
      onDismiss:
        column.key === "NO_DISMISS_SUPPORTED"
          ? undefined
          : this._onContextualMenuDismissed,
    };
  }

  private _onActivityContextualMenuDismissed = (ev: any, dp: any): void => {
    this.setState({
      activityContextualMenuProps: undefined,
    });
  };

  private async _getActivityContextualMenuProps(
    itemId: number,
    permissions: ActivityPermissions,
    isMacro: boolean,
    ev?: React.MouseEvent<HTMLElement>
  ): Promise<IContextualMenuProps> {
    // var item = await this._activityClient.getActivityDetails(itemId, isMacro);

    // var permissions = item?.permissions
    //   ? item?.permissions
    //   : Utility.getDefaultActivityPermissions();

    var items: IContextualMenuItem[] = [
      {
        key: "deleteActivity",
        name: this._translation.delete,
        // iconProps: { iconName: "SortUp" },
        canCheck: false,
        // checked: column.isSorted && !column.isSortedDescending,
        disabled: !permissions.canDelete, //CHECK PERMISSION
        onClick: () => this._showDeleteDialog(itemId, isMacro),
      },
      {
        key: "shareCard",
        name: this._translation.share,
        // iconProps: { iconName: "SortUp" },
        canCheck: false,
        disabled: false, //CHECK PERMISSION
        // checked: column.isSorted && !column.isSortedDescending,
        onClick: () => {
          var el = document.createElement("input");
          document.body.appendChild(el);
          if (el && itemId) {
            var url =
              document.location.protocol +
              "//" +
              document.location.host +
              document.location.pathname;
            el.value = isMacro
              ? url + "?page=Routing&type=1&activity=" + itemId.toString()
              : url + "?page=Routing&type=2&activity=" + itemId.toString();
            el.select();
            document.execCommand("copy");

            this.props.commonProps.toastComponent?.showMessage(
              this._translation.ok,
              this._translation.shareCardSuccess,
              ToastNotificationType.SUCCESS,
              false,
              ToastNotificationPosition.TOPCENTER
            );
          }

          document.body.removeChild(el);
        },
      },
    ];

    if (!isMacro) {
      items.push({
        key: "downloadDerivables",
        name: this._translation.downloadDeliverables,
        disabled: isMacro, //CHECK PERMISSION
        // iconProps: { iconName: "SortUp" },
        canCheck: permissions.canDownloadDeliverables,
        // checked: column.isSorted && !column.isSortedDescending,
        onClick: () => {
          this.props.commonProps.toastComponent?.showMessage(
            this._translation.info,
            this._translation.downloadAttachments,
            ToastNotificationType.INFO
          );
          this._deliverableClient
            .getDeliverables(itemId)
            .then((deliverables) => {
              if (deliverables) {
                this._deliverableClient
                  .downloadAttachments(itemId, deliverables)
                  .then((file) => {
                    //var blob = new Blob([file], { type: "application/pdf" });
                    var binary_string = window.atob(file);
                    var len = binary_string.length;
                    var bytes = new Uint8Array(len);
                    for (var i = 0; i < len; i++) {
                      bytes[i] = binary_string.charCodeAt(i);
                    }
                    var link = document.createElement("a");
                    var type = "application/octet-stream";
                    var blob = new Blob([bytes], { type: type });
                    link.href = window.URL.createObjectURL(blob);
                    link.download = "Attachments.zip";
                    link.click();
                  });
              }
            });
        },
      });
    }

    return {
      items: items,
      target: ev?.currentTarget as HTMLElement,
      directionalHint: DirectionalHint.bottomLeftEdge,
      gapSpace: 10,
      isBeakVisible: false,
      onDismiss: this._onActivityContextualMenuDismissed,
    };
  }

  private _onSortColumn = (
    columnKey: string,
    isSortedDescending: boolean
  ): void => {
    const { columns, filteredItems } = this.state;
    const newColumns: IColumn[] = columns.slice();
    const currColumn: IColumn = newColumns.filter(
      (currCol) => columnKey === currCol.key
    )[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });

    this._getItems({
      activity: this.state.activitySearch,
      lastModifyStartDate: this.state.lastModifyStartSearch,
      lastModifyEndDate: this.state.lastModifyEndSearch,
      activityDateStartDate: this.state.activityDateStartSearch,
      activityDateEndDate: this.state.activityDateEndSearch,
      dueToStartDate: this.state.dueToStartSearch,
      dueToEndDate: this.state.dueToEndSearch,
      type: this.state.typeSearch,
      calendar: this.state.calendarSearch,
      categories: this.state.categorySearch,
      responsibilities: this.state.responsibilitySearch,
      sensibilities: this.state.sensibilitySearch,
      status: this.state.statusSearch,
      startIndex: 0,
      showOnlyMyActivities: this.state.showMyActivities,
      orderBy: newColumns,
    }).then((newItems) => {
      this.setState({
        columns: newColumns,
        lastCount: 0,
        filteredItems: newItems.activities,
        contextualMenuProps: undefined,
      });
    });
  };
}
