import * as React from "react";
import { Constants } from "../../Utility/Constants";
import {
  Stack as Stack2,
  Label,
  IStackTokens,
  IStackStyles,
  IconButton,
  TooltipHost,
} from "@fluentui/react";
import { IBaseProperties } from "../../Models/IBaseProperties";
import styles from "./CustomLabel.module.scss";

const stackTokens: IStackTokens = {
  childrenGap: 4,
  // maxWidth: 300,
};

const stackStyles: IStackStyles = {
  root: {
    justifyContent: "space-between",
  },
};

export interface ICustomLabelProps extends IBaseProperties {
  controlId: string;
  label: string;
  tooltiptext: string;
  required?: boolean;
  disableTooltip?: boolean;
}
export interface ICustomLabelState {
  isCalloutVisible: boolean;
}

export class CustomLabel extends React.Component<
  ICustomLabelProps,
  ICustomLabelState
> {
  private get isCalloutVisible(): boolean {
    return this.state.isCalloutVisible;
  }

  constructor(props: ICustomLabelProps) {
    super(props);

    this.state = {
      isCalloutVisible: false,
    };

    this.handleClick = this.handleClick.bind(this);
  }
  handleClick() {
    const newValue = !this.state.isCalloutVisible;
    this.setState({ isCalloutVisible: newValue });
  }

  render() {
    const iconButtonId = `iconButtonId_${this.props.controlId}`;
    const tooltipId = `descriptionId_${this.props.controlId}`;
    const labelId = `labelId_${this.props.controlId}`;
    const required = this.props.required;
    const disableTooltip = this.props.disableTooltip;

    return (
      <>
        <Stack2
          horizontal
          verticalAlign="center"
          tokens={stackTokens}
          styles={stackStyles}
        >
          <div className="labelContainer">
            <div>
              <Label
                id={labelId}
                styles={Constants.labelStyle}
                htmlFor={this.props.controlId}
              >
                {this.props.label}
                {required && <sup className={styles.supStyle}>*</sup>}
              </Label>
            </div>

            {!disableTooltip && (
              <div>
                <TooltipHost content={this.props.tooltiptext} id={tooltipId}>
                  <IconButton
                    id={iconButtonId}
                    iconProps={Constants.iInfo}
                    aria-label="Tooltip"
                    aria-describedby={tooltipId}
                  ></IconButton>
                </TooltipHost>
              </div>
            )}
          </div>
        </Stack2>
        {/* {this.isCalloutVisible && (
            <Callout
              target={'#' + iconButtonId}
              setInitialFocus
              onDismiss={this.handleClick}
              ariaDescribedBy={descriptionId}
              role="alertdialog"
            >
              <Stack tokens={stackTokens} horizontalAlign="start" styles={labelCalloutStackStyles}>
                <Label id={descriptionId}>
                  {this.props.tooltiptext}
                  </Label>
                <DefaultButton  onClick={this.handleClick} >Close</DefaultButton>
              </Stack>
            </Callout>
          )} */}
      </>
    );
  }
}
