import { StatusCodes} from 'http-status-codes';
import { NoContent} from './NoContent';

const noContentResponse: NoContent = {hasContent: false};

export class HttpResponse<T>
{
    
    private readonly _response: Response;    

    constructor(response: Response)
    {
        this._response = response;
    }  

    private get httpStatusValid(): boolean
    {
        return ( this._response.status>= 200 && this._response.status<300) ? true : false;
    }

    public get isValid(): boolean {
        return this.httpStatusValid;        
    }

    public get hasContent(): boolean {
        return (this.isValid && this._response.status !== StatusCodes.NO_CONTENT);        
    }

    public get noContent(): boolean {
        return (this._response.status === StatusCodes.NO_CONTENT);        
    }
    
    private async getValue(): Promise<T>
    {
        return (await this.getResponse()) as unknown as T;
    }

    public async value(): Promise<T | NoContent> {
        if (this.hasContent)
        {            
            return await this.getValue(); 
        }
        else
        {
            if (this.isValid)
            {
                if (this.noContent)
                {
                    return noContentResponse;
                }
                else
                {
                    throw new HttpError('Http invalid response', this.getStatus(), (await this.getText()));
                }
            }
            else
            {
                throw new HttpError('Http invalid response', this.getStatus(), "Http invalid response");
            }
            
        }
    }

    private async getResponse(): Promise<any>
    {
        const result = await this._response.json();
        return result;
    }

    private async getText(): Promise<string>
    {
        const result = await this._response.text();
        return result;
    }

    private  getStatus(): number
    {
        return this._response.status;        
    }

    // public get Error(): Error {
    //     return this._error;        
    // }
}

export class HttpError extends Error {
    
    public readonly httpStatus: number;
    public readonly httpResponse: string;
    public readonly timestamp: Date = new Date().utc();
    public readonly stackTrace?: string;
    // private _callerName: string;

    constructor(message: string, httpStatus: number, httpResponse: string ) {
      super(message);
      this.stackTrace = this.stack;
    //   this._callerName = "Unassigned function name";
      this.httpStatus = httpStatus;
      this.httpResponse = httpResponse; 
    }

    // public set callerName(name: string)
    // {
    //     this._callerName = name;
    // }

    // public get callerName(): string
    // {
    //     return this._callerName;
    // }

  }


