import * as React from "react";
import "./ListActivitiesNavigation.scss";
import {
  DefaultButton,
  Dropdown,
  FontIcon,
  IconButton,
  IDropdownOption,
  IPanelStyles,
  IRenderFunction,
  ISelectableOption,
  keyframes,
  Label,
  Panel,
  Pivot,
  PivotItem,
  Toggle,
} from "@fluentui/react";
import { Constants } from "../../../../../Utility/Constants";
import { IBaseProperties } from "../../../../../Models/IBaseProperties";
import { ListActivitiesNavigationTranslation } from "./ListActivitiesNavigation.Translation";
import { ActivityTranslation } from "../../../../../Translations/Activity.Translation";
import { Badge, Button } from "@material-ui/core";

const onRenderOption: IRenderFunction<ISelectableOption> = (option) =>
  option ? (
    <div className={"status-container"}>
      {option.key && (
        <span
          className="circle-status"
          status-name={(option.key as string).toLowerCase().replace(/ /g, "")}
        ></span>
      )}
      <span>{option.text}</span>
    </div>
  ) : null;

const onRenderTitle = (
  array: any[],
  options: IDropdownOption[] | undefined,
  label: string
) =>
  options?.length == array.length ? (
    <div className={"filter-status-container"}>{label}</div>
  ) : options?.length && options.length > 2 ? (
    <div className={"filter-status-container"}>
      {label} <FontIcon iconName="Filter" className={"iconFilter"}></FontIcon>{" "}
    </div>
  ) : (
    <div className={"filter-status-container"}>
      {label} <FontIcon iconName="Filter" className={"iconFilter"}></FontIcon>{" "}
      <span className="">({options?.map((s) => s.text).join(", ")})</span>
    </div>
  );

export interface IListActivitiesNavigationProps extends IBaseProperties {
  selectedFilterSensibility: string[];
  selectedFilterCategory: string[];
  selectedFilterResponsibility: string[];
  onChangeFilterSensibility: (event: any, item: any) => any;
  onChangeFilterCategory: (event: any, item: any) => any;
  onChangeFilterResponsibility: (event: any, item: any) => any;
  sensibilities: IDropdownOption[];
  categories: IDropdownOption[];
  responsibilities: IDropdownOption[];

  isSticky?: boolean;

  showFilters: boolean;
  showMyActivities: boolean;
  // getShowFilters: (event: any) => boolean
  onChangeShowFilter: (event: any) => void;
  onChangeShowMyActivities: (event: any) => void;
}
export interface IListActivitiesNavigationState {
  showFilters: boolean;
}

export class ListActivitiesNavigation extends React.Component<
  IListActivitiesNavigationProps,
  IListActivitiesNavigationState
> {
  private readonly _translation: ListActivitiesNavigationTranslation;
  private readonly _activityTranslation: ActivityTranslation;

  constructor(props: IListActivitiesNavigationProps) {
    super(props);
    this.state = {
      showFilters: false,
    };
    this._translation = new ListActivitiesNavigationTranslation(
      props.commonProps.translation
    );
    this._activityTranslation = new ActivityTranslation(
      props.commonProps.translation
    );
  }

  private readonly panelListActivitiesFilterStyles: Partial<IPanelStyles> = {
    main: {
      backgroundColor: "#ffffff",
      borderRadius: "0px !important",
      minWidth: "25vw",
      width: "auto !important",
    },
  };

  render() {
    const optionsFilterSensibility: IDropdownOption[] = this.props.sensibilities
      .filter((s) => +s.key > 0)
      .map((element: IDropdownOption) => {
        return { key: element.key.toString(), text: element.text };
      });
    const optionsFilterCategory: IDropdownOption[] = this.props.categories
      .filter((s) => +s.key > 0)
      .map((element: IDropdownOption) => {
        return { key: element.key.toString(), text: element.text };
      });
    const optionsFilterResponsibility: IDropdownOption[] = this.props.responsibilities
      .filter((s) => +s.key > 0)
      .map((element: IDropdownOption) => {
        return { key: element.key.toString(), text: element.text };
      });

    var numFilters =
      (this.props.selectedFilterCategory.length > 0 ? 1 : 0) +
      (this.props.selectedFilterResponsibility.length > 0 ? 1 : 0) +
      (this.props.selectedFilterSensibility.length > 0 ? 1 : 0);

    return (
      <React.Fragment>
        <div
          role="filterActivityList"
          className={`listactivities-nav-container ${
            this.props.isSticky ? "is-sticky" : ""
          }`}
        >
          <div
            className={`listactivities-nav ${
              this.props.isSticky ? "is-sticky" : ""
            }`}
          >
            <div className="listactivities-nav-switch">
              {/* <DefaultButton
                iconProps={Constants.iUser}
                title="My Activities"
                ariaLabel="My Activities"
                styles={
                  this.props.showMyActivities == true
                    ? Constants.iconCalendarSelStyle
                    : Constants.iconCalendarStyle
                }
                onClick={(event) => {
                  this.props.onChangeShowMyActivities(event);
                }}
              >
                {this._activityTranslation.myActivities}
              </DefaultButton>
              <DefaultButton
                iconProps={Constants.iGroups}
                title="All Activities"
                ariaLabel="All Activities"
                styles={
                  this.props.showMyActivities == false
                    ? Constants.iconCalendarSelStyle
                    : Constants.iconCalendarStyle
                }
                onClick={(event) => {
                  this.props.onChangeShowMyActivities(event);
                }}
              >
                {this._activityTranslation.allActivities}
              </DefaultButton> */}
              <Pivot
                aria-label="Filter My Activities/All activities"
                className="FilterMyActivitiesPivot"
                selectedKey={this.props.showMyActivities ? "MY" : "ALL"}
                onLinkClick={(item, event) =>
                  this.props.onChangeShowMyActivities(item)
                }
              >
                <PivotItem
                  headerText={this._activityTranslation.allActivities}
                  itemKey="ALL"
                  headerButtonProps={{
                    "data-order": 1,
                    "data-title": "All Activities",
                  }}
                ></PivotItem>
                <PivotItem
                  headerText={this._activityTranslation.myActivitiesTab}
                  itemKey="MY"
                ></PivotItem>
              </Pivot>
              {/* <Toggle
                label={this._activityTranslation.myActivities}
                inlineLabel
                checked={this.props.showMyActivities}
                // defaultChecked=
                style={{ marginBottom: "auto" }}
                onChange={(event, checked) =>
                  this.props.onChangeShowMyActivities(event)
                }
                // onText={this._translation.yes.toUpperCase()}
                // offText={this._translation.no.toUpperCase()}
                // disabled={this.state.rangeDateValue ? false : true}
                // onChange={this.filterMacroChange}
              /> */}
            </div>

            {/* <div
              className="listactivities-nav-showfilters"
              style={{ width: 50, margin: 0, marginRight: 20 }}
            >
              <Badge
                className={"badgeCounter"}
                badgeContent={numFilters}
                color="primary"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
              >
                <DefaultButton
                  className={"showMenuButton"}
                  iconProps={Constants.iFilters}
                  title="Show filters"
                  ariaLabel="Show filters"
                  styles={
                    this.props.showFilters
                      ? Constants.iconCalendarSelStyle
                      : Constants.iconCalendarStyle
                  }
                  onClick={(event) => {
                    this.setState({
                      showFilters: !this.state.showFilters,
                    });
                  }}
                ></DefaultButton>
              </Badge>
              <Panel
                isOpen={this.state.showFilters}
                // type={PanelType.customNear}
                // customWidth={"190px"}
                isBlocking={false}
                onDismiss={() => this.setState({ showFilters: false })}
                styles={this.panelListActivitiesFilterStyles}
                isLightDismiss={true}
              >
                <div className="planner-nav-actions">
                  <div>
                    <label className="filterHeader">Activity Filter</label>

                    <div className="filterSection">
                      <label>Micro Activities Filter</label>

                      <Dropdown
                        placeholder="Sensibility"
                        label=""
                        onChange={(event, item) =>
                          this.props.onChangeFilterSensibility(event, item)
                        }
                        className={"filterDropdown"}
                        onRenderTitle={(options) =>
                          onRenderTitle(
                            this.props.sensibilities,
                            options,
                            "Sensibility"
                          )
                        }
                        onRenderOption={onRenderOption}
                        selectedKeys={this.props.selectedFilterSensibility}
                        multiSelect
                        options={optionsFilterSensibility}
                        styles={Constants.dropdownFilterStyles}
                        title={"SENSIBILITY"}
                      />
                      <Dropdown
                        placeholder="Category"
                        label=""
                        onChange={(event, item) =>
                          this.props.onChangeFilterCategory(event, item)
                        }
                        className={"filterDropdown"}
                        onRenderTitle={(options) =>
                          onRenderTitle(
                            this.props.categories,
                            options,
                            "Category"
                          )
                        }
                        onRenderOption={onRenderOption}
                        selectedKeys={this.props.selectedFilterCategory}
                        multiSelect
                        options={optionsFilterCategory}
                        styles={Constants.dropdownFilterStyles}
                        style={{ minWidth: 210 }}
                        title={"CATEGORY"}
                      />
                      <Dropdown
                        placeholder="Responsibility"
                        label=""
                        onChange={(event, item) =>
                          this.props.onChangeFilterResponsibility(event, item)
                        }
                        className={"filterDropdown"}
                        onRenderTitle={(options) =>
                          onRenderTitle(
                            this.props.responsibilities,
                            options,
                            "Responsibility"
                          )
                        }
                        onRenderOption={onRenderOption}
                        selectedKeys={this.props.selectedFilterResponsibility}
                        multiSelect
                        options={optionsFilterResponsibility}
                        styles={Constants.dropdownFilterStyles}
                        title={"RESPONSIBILITY"}
                      />
                    </div>
                  </div>
                </div>
              </Panel>
            </div> */}
            <div className="listactivities-nav-showfilters">
              <DefaultButton
                className="showFilters"
                iconProps={Constants.iFilters}
                title={
                  this.props.showFilters
                    ? this._translation.hideFilters
                    : this._translation.showFilters
                }
                ariaLabel={
                  this.props.showFilters
                    ? this._translation.hideFilters
                    : this._translation.showFilters
                }
                styles={
                  this.props.showFilters
                    ? Constants.iconCalendarSelStyle
                    : Constants.iconCalendarStyle
                }
                onClick={(event) => {
                  // this.setState({ showFilters: !this.state.showFilters });
                  this.props.onChangeShowFilter(event);
                }}
              >
                {this.props.showFilters
                  ? this._translation.hideFilters
                  : this._translation.showFilters}
              </DefaultButton>
            </div>
          </div>
          {this.props.showFilters && (
            <div
              className={`listactivities-nav-filters ${
                this.props.isSticky ? "is-sticky" : ""
              }`}
            >
              <div className="listactivities-nav-actions">
                <Dropdown
                  placeholder={this._translation.activitySensibility}
                  label=""
                  onChange={(event, item) =>
                    this.props.onChangeFilterSensibility(event, item)
                  }
                  onRenderTitle={(options) =>
                    onRenderTitle(
                      this.props.sensibilities,
                      options,
                      "Sensibility"
                    )
                  }
                  onRenderOption={onRenderOption}
                  selectedKeys={this.props.selectedFilterSensibility}
                  multiSelect
                  options={optionsFilterSensibility}
                  styles={Constants.dropdownFilterStyles}
                  title={"SENSIBILITY"}
                />
                <Dropdown
                  placeholder={this._translation.activityCategory}
                  label=""
                  onChange={(event, item) =>
                    this.props.onChangeFilterCategory(event, item)
                  }
                  onRenderTitle={(options) =>
                    onRenderTitle(this.props.categories, options, "Category")
                  }
                  onRenderOption={onRenderOption}
                  selectedKeys={this.props.selectedFilterCategory}
                  multiSelect
                  options={optionsFilterCategory}
                  styles={Constants.dropdownFilterStyles}
                  style={{ minWidth: 210 }}
                  title={"CATEGORY"}
                />
                <Dropdown
                  placeholder={this._translation.activityResponsibility}
                  label=""
                  onChange={(event, item) =>
                    this.props.onChangeFilterResponsibility(event, item)
                  }
                  onRenderTitle={(options) =>
                    onRenderTitle(
                      this.props.responsibilities,
                      options,
                      "Responsibility"
                    )
                  }
                  onRenderOption={onRenderOption}
                  selectedKeys={this.props.selectedFilterResponsibility}
                  multiSelect
                  options={optionsFilterResponsibility}
                  styles={Constants.dropdownFilterStyles}
                  title={"RESPONSIBILITY"}
                />
              </div>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}
