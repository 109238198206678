import * as React from "react";
import styles from "./MyBrief.module.scss";
import { FontIcon, Image, ImageFit } from "@fluentui/react";
import { Security } from "../../../Utility/Security";

import { ActivityTranslation } from "../../../Translations/Activity.Translation";
import { ToastComponent } from "../../Shared/ToastComponent/ToastComponent";
import { IBaseProperties } from "../../../Models/IBaseProperties";
import { Box, Tab, Tabs, Typography } from "@material-ui/core";
import { number } from "prop-types";
import { node } from "prop-types";
import { ListAttachments } from "../Activities/ListAttachments/ListAttachments";
import { Planner, PlannerView } from "../Planner/Planner";
import { ListBriefs } from "../MyBrief/ListBriefs/ListBriefs";
import { BriefTranslation } from "../../../Translations/Brief.Translation";

export interface IMyBriefProps extends IBaseProperties {}
export interface IMyBriefState {
  value: number;
}

function TabPanel(props: {
  [x: string]: any;
  children: any;
  value: any;
  index: any;
}) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: node,
  index: number,
  value: number,
};

export class MyBrief extends React.Component<IMyBriefProps, IMyBriefState> {
  private readonly _translation: BriefTranslation;
  private readonly _activityTranslation: ActivityTranslation;

  constructor(props: IMyBriefProps) {
    super(props);
    this.state = { value: 0 };

    this._translation = new BriefTranslation(
      this.props.commonProps.translation
    );
    this._activityTranslation = new ActivityTranslation(
      this.props.commonProps.translation
    );

    this._handleChange = this._handleChange.bind(this);
  }

  private _a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  private _handleChange(event: any, newValue: any) {
    this.setState({ value: newValue });
  }

  private isGMUser() {
    return (
      Security.isGlobalMediaAdvanced ||
      Security.isGlobalMediaSpecialist ||
      Security.isAuditor ||
      Security.isAdmin
    );
  }

  render() {
    const isGMUser = this.isGMUser();
    const tabListActivities = 0;
    const tabTimeline = 1;
    const tabAttachment = 1; //isGMUser ? 2 : 1;
    const tabMessages = isGMUser ? 3 : 2;
    return (
      <div>
        <Tabs
          value={this.state.value}
          onChange={this._handleChange}
          aria-label="Activities tab"
          className={styles.DivActivities}
        >
          <Tab
            label={
              <div className={"divMui"}>
                <FontIcon iconName={"BulletedList"} />
                <div>{this._activityTranslation.list}</div>
              </div>
            }
            {...this._a11yProps(tabListActivities)}
          />
          <Tab
            label={
              <div className={"divMui"}>
                <FontIcon iconName={"Attach"} />
                <div>{this._activityTranslation.attachments}</div>
              </div>
            }
            {...this._a11yProps(tabAttachment)}
          />
          {/* <Tab
            label={
              <div className={"divMui"}>
                <FontIcon iconName={"Mail"} />
                <div>{this._translation.messages}</div>
              </div>
            }
            {...this._a11yProps(tabMessages)}
          /> */}
        </Tabs>

        <TabPanel value={this.state.value} index={tabListActivities}>
          <ListBriefs commonProps={this.props.commonProps}></ListBriefs>
        </TabPanel>
        <TabPanel value={this.state.value} index={tabAttachment}>
          <ListAttachments
            commonProps={this.props.commonProps}
            briefAttachments={true}
          ></ListAttachments>
        </TabPanel>
        {/* <TabPanel value={this.state.value} index={tabMessages}>
          Messages
        </TabPanel> */}
      </div>
    );
  }
}
