import { TFunction } from "i18next";
import { CommonTranslation } from "../../../Translations/Common.Translation";

export class MultipleFileUploadTranslation extends CommonTranslation {

  constructor(translation: TFunction) {
    super(translation, "multipleFileUpload");    
  }      
  
 public maxFileSizeText(maxFileSize: number): string {
    return super.getTextByMessageKey("maxFileSizeText", {maxFileSize: maxFileSize});    
  }
  public warningFileSize(fileName: string, maxFileSize: number): string {
    return super.getTextByMessageKey("warningFileSize", {fileName: fileName, maxFileSize: maxFileSize});    
  }

  public get fileUploadSavingNote(): string
  {
    return super.getTextByMessageKey("fileUploadSavingNote");
  } 

  public get fileNameColumn(): string
  {
    return super.getTextByMessageKey("fileNameColumn");
  } 

  public get fileSizeColumn(): string
  {
    return super.getTextByMessageKey("fileSizeColumn");
  } 

  public get title(): string
  {
    return super.getTextByMessageKey("title");
  } 

  public get titleOr(): string
  {
    return super.getTextByMessageKey("titleOr");
  } 

  public get uploadButtonText(): string
  {
    return super.getTextByMessageKey("uploadButtonText");
  } 
}
