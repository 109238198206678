import * as React from "react";

import "bootstrap/dist/css/bootstrap.css";

import { Utility } from "../../../Utility/Utility";

// import "./PersonaComponent.scss";
import styles from "./PersonaComponent.module.scss";

import { Persona, PersonaSize } from "@fluentui/react";

import {
  IRenderFunction,
  ISelectableOption,
  sizeBoolean,
} from "office-ui-fabric-react";
import { LineThicknessIcon } from "@fluentui/react-icons";
import { User, PersonaStyle } from "../../../Models/User";
import { IBaseProperties } from "../../../Models/IBaseProperties";

export interface IPersonaComponentProps extends IBaseProperties {
  persona: User[];
  personaSize: PersonaSize;
  personaStyle?: PersonaStyle;
  maxLength: number;
  showText?: boolean;
}

export interface IPersonaComponentState {
  // persona: IPersonaComponentItem[];
}

export class PersonaComponent extends React.Component<
  IPersonaComponentProps,
  IPersonaComponentState
> {
  constructor(props: IPersonaComponentProps) {
    super(props);

    // this.state = {
    //   persona: props.persona
    // }
  }

  public render() {
    const { persona } = this.props;

    return (
      <div className={styles.divPersonaComponent}>
        {this.props.persona.map((value, index) => {
          if (index >= this.props.maxLength) return null;
          //return value.initials ? <Persona key={index}  text="" imageInitials={value.initials} size={this.props.personaSize} title={value.displayName} className={this.props.personaStyle ? this.props.personaStyle : PersonaStyle.NORMAL}  />
          return this.props.showText ? (
            <Persona
              key={index}
              text={value.displayName}
              size={this.props.personaSize}
              title={value.displayName}
              className={
                this.props.personaStyle
                  ? this.props.personaStyle
                  : PersonaStyle.NORMAL
              }
            />
          ) : (
            <Persona
              key={index}
              text={value.displayName}
              hidePersonaDetails
              size={this.props.personaSize}
              title={value.displayName}
              className={
                this.props.personaStyle
                  ? this.props.personaStyle
                  : PersonaStyle.NORMAL
              }
            />
          );
        })}
        {this.props.persona.length > this.props.maxLength ? (
          <div className={styles.personaEllipsis}>...</div>
        ) : (
          ""
        )}
      </div>
    );
  }
}
