import React from "react";
import './MonthCalendar.css';
import './yearplanner.css';
import * as dates from '../BigCalendar/utils/dates'
import {MonthDay} from './MonthDay';
import {Calendar} from '../../../../Models/Calendar';

export interface IMonthDaysProps {
  dateRange: Date[],
  activities: Calendar[];  
  onDrillDown: any;
  selectedDay: Date;
}
export interface IMonthDaysState {  
}

export class MonthDays extends React.Component<IMonthDaysProps, IMonthDaysState> {
   
    constructor(props: IMonthDaysProps) {
        super(props);       
    }
   
    renderDays(dates: Date[], activities: any)
    {
        const maxWeeksInMonths: number = 5;        
        const items: any[] = [];
        const firstWeekDay = dates[0].getDay() === 0 ? 7 : dates[0].getDay(); //get the weekday (Sunday...Saturday <-> 0...6). Added 1 in order to force start by Monday
        
        let daysToSkip = firstWeekDay - 1;
        let maxDatesArrayIndex = (7 - firstWeekDay) + 1;

        //Build month weeks
        for(let week=0;week<maxWeeksInMonths; week++)
        {
            //only for the first week subset will be check the weekday to start with the first available weeekday
            let weekDates : Array<Date>;
            //weekDates = dates.slice((week*7),(week*7)+7);
            if (week===0)
            {
                weekDates = dates.slice(0, maxDatesArrayIndex); //next cycle must start by maxDatesArrayIndex
            }
            else
            {
                daysToSkip = 0;
                const dateToRead = Math.min((maxDatesArrayIndex)+7, dates.length);
                weekDates = dates.slice(maxDatesArrayIndex, dateToRead); //next cycle must start by maxDatesArrayIndex
                maxDatesArrayIndex += 7;
            }

            try
            {
                items.push(this.renderOneWeek(week, weekDates, daysToSkip, activities)); 
            }
            catch (error) {
                console.error(error);
            }
        }
       
        return (
            <React.Fragment>
              {items}
            </React.Fragment>
          );       
    }

    //Return the activities of current date. To to: better use the activity extension method
    private getDayActivities(date: Date, activities: Calendar[])
    {        
        return activities.filter((act) => date >=act.start && date <= act.end || act.linkedActivities?.some((lact: any) => date >=lact.start && date <= lact.end));
    }

    //render a week
    private renderOneWeek(weekNumber: number, weekDates: Date[], dayToSkip: number, activities: Calendar[]) {
        const items:JSX.Element[] = [];

        //if the first day week of the month does not start on Monday, it adds as many empty elements
        this.addEmptyDays(dayToSkip, items);

        weekDates.forEach((date, _index) => {       
            const dayActivities = this.getDayActivities(date, activities);    
            const isToday = dates.isToday(date);
            const isSelected = dates.isEqual(date, this.props.selectedDay);
            items.push(this.renderDay(date.getDate().toString(), dayActivities, date, isToday, isSelected));            
        });

        if (dayToSkip===0)
        {
            //Add empty item id the last week has less than 7 days
            for(let day=0; day<7-weekDates.length; day++)     
            {
                items.push(this.renderDay("", null, null));
            }
        }

        return (
            <div key={weekNumber} className="month-calendar-week-container">
              {items}
            </div>
          )       
    }

    //Add blank days to calendar
    private addEmptyDays(dayToSkip: number, items: JSX.Element[]) {
        for (let day = 0; day < dayToSkip; day++) {
            items.push(this.renderDay("", null, null));
        }
    }

    renderDay(content: string, activities: Calendar[] | null,date: Date | null,  isCurrentDay: boolean = false, isSelectedDay: boolean = false)
    {
        return <MonthDay key={`${date?.toUTCString()}-${this.generateId()}`} onDrillDown={this.props.onDrillDown} content={content} activities={activities} isCurrentDay={isCurrentDay} isSelectedDay={isSelectedDay} date={date}></MonthDay>;        
    } 

    generateId() {
        const length = 20;
        let result           = '';
        const characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for ( let i = 0; i < length; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
     }
    
    render() {
        return (
            <div className="month-calendar-days-container">
                {this.renderDays(this.props.dateRange, this.props.activities)}            
            </div>
        );
    }  
    
};