export enum Colors {
    //#region Grey
    Black = "#242A33",
    Grey = "#C2CDDD",
    GreyDark = "#667790",
    GreyLight = "#EFF2F7",
    GreyUltraLight = "#F7F8FB",
    White = "#FFFFFF",
    //#endregion

    //#region Blue
    Blue = "#0152E8",
    BlueDark = "#0047CC",
    BlueLight = "#387CFA",
    //#endregion

    //#region Support
    Success = "#1BC75D",
    Warning = "#FF9B14",
    Error = "#EA2B41",
    //#endregion

    //#region Status
    Draft = "#0043AC",
    Planned = "#0098DB",
    Rework = "#D02963",
    InProgress = "#F36C0B",
    Approved = "#OAAD49",
    ReadyForDistribution = "#006F44",
    Distribuited = "#4D73Ac",
    Closed = "#242A33",
    //#endregion
}
