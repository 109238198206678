import * as React from "react";

import "bootstrap/dist/css/bootstrap.css";

import "@fluentui/react/dist/css/fabric.min.css";

import { initializeIcons } from "@uifabric/icons";

import { Utility } from "../../../Utility/Utility";
import { ActivityTranslation } from "../../../Translations/Activity.Translation";

// import "./PanelActivityHeaderComponent.scss";
import styles from "./PanelActivityHeaderComponent.module.scss";

import {
  CommandBarButton,
  ContextualMenu,
  DefaultButton,
  DirectionalHint,
  Dropdown,
  FontIcon,
  IColumn,
  IContextualMenuItem,
  IContextualMenuProps,
  IDropdownOption,
  IDropdownStyles,
  TextField,
} from "@fluentui/react";

import { IRenderFunction, ISelectableOption } from "office-ui-fabric-react";
import {
  ActivityPermissions,
  ActivityStatusEnum,
  IActivityAllAttributes,
  IAvailableStatus,
} from "../../../Models/Activity";
import { IBaseProperties } from "../../../Models/IBaseProperties";
import {
  ToastNotificationPosition,
  ToastNotificationType,
} from "../../../Models/ToastNote";
import { IDeliverableClient } from "../../../Clients/IDeliverableClient";
import { IActivityClient } from "../../../Clients/IActivityClient";
import { Constants } from "../../../Utility/Constants";

export interface IPanelActivityHeaderComponentProps extends IBaseProperties {
  item?: IActivityAllAttributes;
  text?: string;
  isMacro?: boolean;
  showType: boolean;
  showStatus: boolean;
  showContextMenu: boolean;
  showContextMenuViewMode?: boolean;
  showManage: boolean;
  statusChange?: (itemId: number, status: string) => Promise<boolean>;
  manageChange?: any;
  deleteActivity?: (itemId: number, isMacro: boolean) => void;
  titleChange?: (text: string) => Promise<boolean>;
}

export interface IPanelActivityHeaderComponentState {
  activityContextualMenuProps?: IContextualMenuProps;
  statusOptions: IDropdownOption[];
  statusSelected?: string;
  permissions: ActivityPermissions;
  availableStatus: IAvailableStatus[];
  activityName: string;
  editActivityName: boolean;
}

const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: { width: 200 },
};

export class PanelActivityHeaderComponent extends React.Component<
  IPanelActivityHeaderComponentProps,
  IPanelActivityHeaderComponentState
> {
  _taskColumns: IColumn[] = [];
  private readonly _translation: ActivityTranslation;
  private readonly _deliverableClient: IDeliverableClient;
  private readonly _activityClient: IActivityClient;

  constructor(props: IPanelActivityHeaderComponentProps) {
    super(props);

    initializeIcons();
    this._translation = new ActivityTranslation(
      this.props.commonProps.translation
    );
    this._deliverableClient = this.props.commonProps.clientCreator.createDeliverableClient();
    this._activityClient = this.props.commonProps.clientCreator.createActivityClient();

    var statusOptions: IDropdownOption[] = [];

    this.state = {
      statusOptions: statusOptions,
      statusSelected: this.props.item?.status,
      permissions: Utility.getDefaultActivityPermissions(),
      availableStatus: [],
      activityName: !!this.props.item?.name ? this.props.item.name : "",
      editActivityName: false,
    };
  }

  public componentDidMount() {
    if (this.props.item) {
      this.setState({
        permissions: this.props.item.permissions,
        availableStatus: this.props.item.availableStatus
          ? this.props.item.availableStatus
          : [],
        statusOptions: this.props.item.availableStatus
          ? this.props.item.availableStatus.map((status) => {
              return {
                key: ActivityStatusEnum[status.status].toUpperCase(),
                text: ActivityStatusEnum[status.status],
                disabled: !status.available,
                data: {
                  class: Utility.GetActivityClassNameFromStatus(
                    ActivityStatusEnum[status.status]
                  ),
                },
              };
            })
          : [],
      });
    }
  }

  private _onRenderOption(
    option?: ISelectableOption,
    _defaultRender?: IRenderFunction<ISelectableOption>
  ) {
    return (
      <div>
        {option?.data && option.data.class && (
          <div
            className={"rbc-event-dot " + option.data.class}
            // alt={item.fileType + " file icon"}
          />
        )}
        <span className={styles.optionDropdownStatusChange}>
          {option?.text}
        </span>
      </div>
    );
  }

  public render() {
    return (
      <div className={"row " + styles.PanelActivityHeaderComponent}>
        {this.props.showType && (
          <div className={styles.typeDetails + " typeDetails"}>
            {this.props.isMacro ? "MACRO" : "MICRO"}
          </div>
        )}

        {this.state.editActivityName && (
          <div className={styles.TitleDetails}>
            <TextField
              id="activityTitle"
              styles={Constants.textFieldStyle}
              maxLength={254}
              placeholder={this._translation.typeHere}
              value={this.state.activityName}
              deferredValidationTime={500}
              validateOnFocusOut={true}
              onChange={(el, newValue) => {
                this.setState({ activityName: !!newValue ? newValue : "" });
              }}
            ></TextField>
            <div className={styles.activityNameActions}>
              <FontIcon
                aria-label={this._translation.save}
                iconName={"Accept"}
                onClick={() => {
                  // this.setState({
                  //   editActivityName: false,
                  // });
                  if (
                    this.props.titleChange &&
                    !!this.state.activityName &&
                    this.state.activityName != this.props.item?.name
                  ) {
                    this.props
                      .titleChange(this.state.activityName)
                      .then((result) => {
                        if (result) {
                          this.setState({ editActivityName: false });
                        }
                      });
                  }
                }}
              ></FontIcon>

              <FontIcon
                aria-label={this._translation.cancel}
                iconName={"Cancel"}
                onClick={() => {
                  this.setState({
                    editActivityName: false,
                  });
                }}
              ></FontIcon>
            </div>
          </div>
        )}
        {!this.state.editActivityName && (
          <div className={styles.TitleDetails}>
            {this.props.item ? this.props.item?.name : this.props.text}
          </div>
        )}

        {!this.state.editActivityName &&
          this.props.showStatus &&
          this.props.item?.status && (
            <div className={styles.statusChangeDetails}>
              <div className={styles.statuslabelDetails}>STATUS</div>
              <Dropdown
                placeholder={this._translation.selectOption}
                // label="Basic uncontrolled example"
                options={this.state.statusOptions}
                selectedKey={this.state.statusSelected?.toUpperCase()}
                className={
                  "dropDownStatus " +
                  Utility.GetActivityClassNameFromStatus(
                    this.state.statusSelected,
                    false
                  )
                }
                onRenderOption={this._onRenderOption}
                // onRenderTitle={this._onRenderTitle}
                styles={dropdownStyles}
                disabled={!this.state.permissions.canUpdateStatus}
                onChange={(event, option?: IDropdownOption) => {
                  if (
                    this.props.item?.id &&
                    option?.key &&
                    this.props?.statusChange
                  ) {
                    this.props?.statusChange(this.props.item?.id, option?.text);
                  }
                }}
              />
            </div>
          )}

        {!this.state.editActivityName && this.props.showManage && (
          <div className={styles.statusChangeDetails}>
            <DefaultButton
                aria-label={this._translation.manageActivity}
              className={"action_button " + styles.manageActivity}
              text={this._translation.manageActivity}
              allowDisabledFocus
              onClick={this.props.manageChange}
              disabled={false}
              checked={true}
            />
          </div>
        )}
        {this.props.showContextMenu && (
          <div className={styles.contextMenuHeaderDetails}>
            <CommandBarButton
              role="menuitem"
              aria-label="Show activity actions"
              className={styles.MenuProps}
              // styles={onRenderOverflowButtonStyles}
              menuIconProps={{ iconName: "MoreVertical" }}
              menuProps={this.state.activityContextualMenuProps}
              onClick={(ev: React.MouseEvent<HTMLElement>) => {
                if (this.props.item?.id) {
                  this.setState({
                    activityContextualMenuProps: this._getActivityContextualMenuProps(
                      ev,
                      this.props.item.id,
                      this.props.item?.isMacro
                    ),
                  });
                }
              }}
            />
            {this.state.activityContextualMenuProps && (
              <ContextualMenu {...this.state.activityContextualMenuProps} />
            )}
          </div>
        )}
        <hr />
      </div>
    );
  }

  private _onActivityContextualMenuDismissed = (ev: any, dp: any): void => {
    this.setState({
      activityContextualMenuProps: undefined,
    });
  };

  private _getActivityContextualMenuProps(
    ev: React.MouseEvent<HTMLElement>,
    itemId: number,
    isMacro: boolean
  ): IContextualMenuProps {
    var items: IContextualMenuItem[] = [];

    if (!this.props.showContextMenuViewMode) {
      items = items.concat([
        {
          key: "renameActivity",
          name: this._translation.rename,
          // iconProps: { iconName: "SortUp" },

          canCheck: false,
          disabled: !this.state.permissions.canUpdate,
          // checked: column.isSorted && !column.isSortedDescending,
          onClick: () => {
            this.setState({ editActivityName: true });
          },
        },
        {
          key: "deleteActivity",
          name: this._translation.delete,
          // iconProps: { iconName: "SortUp" },
          canCheck: false,
          disabled: !this.state.permissions.canDelete,
          // checked: column.isSorted && !column.isSortedDescending,
          onClick: () => {
            if (this.props.deleteActivity) {
              this.props.deleteActivity(itemId, isMacro);
            }
          },
        },
      ]);
    }

    items = items.concat([
      {
        key: "shareCard",
        name: this._translation.share,
        // iconProps: { iconName: "SortUp" },
        canCheck: false,
        // checked: column.isSorted && !column.isSortedDescending,
        onClick: () => {
          var el = document.createElement("input");
          document.body.appendChild(el);
          if (el && itemId) {
            var url =
              document.location.protocol +
              "//" +
              document.location.host +
              document.location.pathname;
            el.value = isMacro
              ? url + "?page=Routing&type=1&activity=" + itemId.toString()
              : url + "?page=Routing&type=2&activity=" + itemId.toString();
            el.select();
            document.execCommand("copy");

            this.props.commonProps.toastComponent?.showMessage(
              this._translation.ok,
              this._translation.shareCardSuccess,
              ToastNotificationType.SUCCESS,
              false,
              ToastNotificationPosition.TOPCENTER
            );
          }

          document.body.removeChild(el);
        },
      },
    ]);

    if (!isMacro) {
      items.push({
        key: "downloadDerivables",
        name: this._translation.downloadDeliverables,
        // iconProps: { iconName: "SortUp" },
        canCheck: false,
        disabled: !this.state.permissions.canDownloadDeliverables && isMacro,
        // checked: column.isSorted && !column.isSortedDescending,
        onClick: () => {
          this.props.commonProps.toastComponent?.showMessage(
            this._translation.info,
            this._translation.downloadAttachments,
            ToastNotificationType.INFO
          );
          this._deliverableClient
            .getDeliverables(itemId)
            .then((deliverables) => {
              if (deliverables) {
                this._deliverableClient
                  .downloadAttachments(itemId, deliverables)
                  .then((file) => {
                    //var blob = new Blob([file], { type: "application/pdf" });
                    var binary_string = window.atob(file);
                    var len = binary_string.length;
                    var bytes = new Uint8Array(len);
                    for (var i = 0; i < len; i++) {
                      bytes[i] = binary_string.charCodeAt(i);
                    }
                    var link = document.createElement("a");
                    var type = "application/octet-stream";
                    var blob = new Blob([bytes], { type: type });
                    link.href = window.URL.createObjectURL(blob);
                    link.download = "Attachments.zip";
                    link.click();
                  });
              }
            });
        },
      });
    }

    return {
      items: items,
      target: ev.currentTarget as HTMLElement,
      directionalHint: DirectionalHint.bottomLeftEdge,
      gapSpace: 10,
      isBeakVisible: false,
      onDismiss: this._onActivityContextualMenuDismissed,
    };
  }
}
