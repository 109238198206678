import { User, UserInfo } from '../Models/User';

export enum Roles
{
    GMA = "GlobalMediaAdvanced",
    GMS = "GlobalMediaSpecialist",
    Guest = "Guest",
    Auditor = "Auditor",
    Admin = "Admin"
}

export class Security
{
    private static _userInfo: UserInfo | undefined = undefined;
   
    constructor (userInfo: UserInfo)
    {
        Security._userInfo = userInfo;
        window.sessionStorage.setItem("userInfo", JSON.stringify(userInfo));
    }

    private static getUserRole(): string | undefined  {        
        return Security._userInfo ? Security._userInfo.group : undefined;        
    }       
            
    public static getCurrentUserInfo(): UserInfo | undefined {
        
        const jsonValue = window.sessionStorage.getItem("userInfo");
        
        if (jsonValue)
        {            
            return  JSON.parse(jsonValue)
        }
        else
        {
            return undefined;
        }
    }

    public static getCurrentUser(): User | undefined {
        
        const jsonValue = window.sessionStorage.getItem("userInfo");
        
        if (jsonValue)
        {            
            return  JSON.parse(jsonValue)
        }
        else
        {
            return undefined;
        }
    }

    public static get isGlobalMedia(): boolean
    {        
        return this.isGlobalMediaAdvanced || this.isGlobalMediaSpecialist;
    }

    public static get isGlobalMediaAdvanced(): boolean
    {
        return this.isInRole(Roles.GMA);
    }

    public static get isAuditor(): boolean
    {
        return this.isInRole(Roles.Auditor);
    }

    public static get isGlobalMediaSpecialist(): boolean
    {
        return this.isInRole(Roles.GMS);
    }

    public static get isAdmin(): boolean
    {        
        return this.isInRole(Roles.Admin);
    }

    private static isInRole(roleToCheck: string): boolean
    {
        const role = Security.userRole;

        return  (role && role.toLowerCase() === roleToCheck.toLowerCase()) ? true :  false;        
    }

    private static get userRole(): string | undefined
    {
        return this.getUserRole();        
        
    }
        
}