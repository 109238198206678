import { TFunction } from "i18next";
import { CommonTranslation } from "./Common.Translation";

export class DeliverablesTranslation extends CommonTranslation {
  constructor(translation: TFunction) {
    super(translation, "deliverables");
  }

  public get loadingAttachmentError(): string {
    return super.getTextByMessageKey("noAttachmentFound");
  }

  public get noAttachmentFound(): string {
    return super.getTextByMessageKey("noAttachmentFound");
  }

  public get noReportFound(): string {
    return super.getTextByMessageKey("noReportFound");
  }

  public get loadingAttachment(): string {
    return super.getTextByMessageKey("loadingAttachment");
  }

  public get editBy(): string {
    return super.getTextByMessageKey("editBy");
  }

  public get file(): string {
    return super.getTextByMessageKey("file");
  }

  public get data(): string {
    return super.getTextByMessageKey("data");
  }

  public get size(): string {
    return super.getTextByMessageKey("size");
  }

  public get fileName(): string {
    return super.getTextByMessageKey("fileName");
  }

  public get fileType(): string {
    return super.getTextByMessageKey("fileType");
  }

  public get deliverable(): string {
    return super.getTextByMessageKey("deliverable");
  }

  public get deliverables(): string {
    return super.getTextByMessageKey("deliverables");
  }

  public get createFile(): string {
    return super.getTextByMessageKey("createFile");
  }

  public get downloadAllAttachments(): string {
    return super.getTextByMessageKey("downloadAllAttachments");
  }

  public get lastUpdate(): string {
    return super.getTextByMessageKey("lastUpdate");
  }

  public get createdOn(): string {
    return super.getTextByMessageKey("createdOn");
  }

  public get briefActivity(): string {
    return super.getTextByMessageKey("briefActivity");
  }

  public get brief(): string {
    return super.getTextByMessageKey("brief");
  }

  public get activity(): string {
    return super.getTextByMessageKey("activity");
  }

  public get searchFile(): string {
    return super.getTextByMessageKey("searchFile");
  }

  public get searchBrief(): string {
    return super.getTextByMessageKey("searchBrief");
  }

  public get searchActivity(): string {
    return super.getTextByMessageKey("searchActivity");
  }

  public get getDeliverablesError(): string {
    return super.getTextByMessageKey("getAttachmentsError");
  }

  public get getDeliverableError(): string {
    return super.getTextByMessageKey("getAttachmentError");
  }

  public get getTemplatesError(): string {
    return super.getTextByMessageKey("getTemplatesError");
  }

  public get insertDeliverableError(): string {
    return super.getTextByMessageKey("addAttachmentError");
  }

  public get updateDeliverableError(): string {
    return super.getTextByMessageKey("updateAttachmentError");
  }

  public get deleteDeliverableError(): string {
    return super.getTextByMessageKey("removeAttachmentError");
  }

  public get version(): string {
    return this.getTextByMessageKey("version");
  }

  public get downloadVersion(): string {
    return this.getTextByMessageKey("downloadVersion");
  }

  public get restoreVersion(): string {
    return this.getTextByMessageKey("restoreVersion");
  }

  public get deleteVersion(): string {
    return this.getTextByMessageKey("deleteVersion");
  }

  public get restoreVersionSuccess(): string {
    return this.getTextByMessageKey("restoreVersionSuccess");
  }

  public get restoreVersionError(): string {
    return this.getTextByMessageKey("restoreVersionError");
  }

  public get actionMenu(): string {
    return this.getTextByMessageKey("actionMenu");
  }

  public get noImage(): string {
    return this.getTextByMessageKey("noImage");
  }

  public get preview(): string {
    return this.getTextByMessageKey("preview");
  }
}
