import { IBriefsCount, BriefStatusEnum } from './../Models/Brief';
import { ActivityStatusEnum, IActivitiesCount } from "./../Models/Activity";
import { DeliverableType } from "./../Models/Deliverable";
import { DeliverableTypeIcons } from "../Models/Deliverable";
import { User } from "../Models/User";
import moment from "moment";
import { ActivityPermissions } from "../Models/Activity";

export class Utility {
  static GetTaskStatusIcon(status: string): string {
    if (status === "Done") return "CheckMark";
    else if (status === "ToDo") return "";
    else return "";
  }

  static GetIconType(fileName: string): string | undefined {
    if (fileName.endsWith(".docx")) {
      return DeliverableTypeIcons.get(DeliverableType.WORD);
    } else if (fileName.endsWith(".xlsx")) {
      return DeliverableTypeIcons.get(DeliverableType.EXCEL);
    } else if (fileName.endsWith(".pptx")) {
      return DeliverableTypeIcons.get(DeliverableType.POWERPOINT);
    } else {
      return "TextDocument";
    }
  }

  static GetFileExtensionByType(fileType: DeliverableType): string {
    switch (fileType) {
      case DeliverableType.EXCEL:
        return ".xlsx";
      case DeliverableType.WORD:
        return ".docx";
      case DeliverableType.POWERPOINT:
        return ".pptx";
      case DeliverableType.GIF:
        return ".gif";
      case DeliverableType.PNG:
        return ".png";
      case DeliverableType.JPG:
        return ".jpg";
      case DeliverableType.PDF:
        return ".pdf";
      case DeliverableType.ONENOTE:
        return ".one";
      default:
        return ".txt";
    }
  }

  static GetFileType(fileName: string): DeliverableType {
    if (fileName.endsWith(".docx") || fileName.endsWith(".doc")) {
      return DeliverableType.WORD;
    } else if (fileName.endsWith(".xlsx") || fileName.endsWith(".xls")) {
      return DeliverableType.EXCEL;
    } else if (fileName.endsWith(".pptx") || fileName.endsWith(".ppt")) {
      return DeliverableType.POWERPOINT;
    } else if (fileName.endsWith(".gif")) {
      return DeliverableType.GIF;
    } else if (fileName.endsWith(".png")) {
      return DeliverableType.PNG;
    } else if (fileName.endsWith(".jpeg") || fileName.endsWith(".jpg")) {
      return DeliverableType.JPG;
    } else if (fileName.endsWith(".pdf")) {
      return DeliverableType.PDF;
    } else if (fileName.endsWith(".txt")) {
      return DeliverableType.TEXT;
    } else {
      return DeliverableType.TEXT;
    }
  }

  public static GetTypesCreateNew(): DeliverableType[] {
    return [
      DeliverableType.EXCEL,
      DeliverableType.WORD,
      DeliverableType.POWERPOINT,
      // DeliverableType.ONENOTE,
    ];
  }

  public static GetActivityClassNameFromStatus(
    status?: string,
    point?: boolean
  ): string {
    let classResult: string = point ? " rbc-event-dot " : "";

    let sStatus;
    try {
      sStatus =
        status && typeof status === "string" ? status.toUpperCase() : "";
    } catch (error) {
      console.error(error);
    }

    switch (sStatus) {
      case "PLANNED":
        classResult += "back-planned ";
        break;
      case "DRAFT":
        classResult += "back-draft ";
        break;
      case "IN PROGRESS":
        classResult += "back-inprogress ";
        break;
      case "APPROVED":
        classResult += "back-approved ";
        break;
      case "REWORK":
        classResult += "back-rework ";
        break;
      case "READY FOR DISTRIBUTION":
        classResult += "back-ready ";
        break;
      case "DISTRIBUTED":
        classResult += "back-distributed ";
        break;
      case "CLOSED":
        classResult += "back-closed ";
        break;
    }
    return classResult;
  }

  public static GetActivityClassNameForStatus(
    status?: string,
    point?: boolean
  ): string {
    let classResult: string = point ? " rbc-event-dot " : "";

    let sStatus;
    try {
      sStatus =
        status && typeof status === "string" ? status.toUpperCase() : "";
    } catch (error) {
      console.error(error);
    }

    switch (sStatus) {
      case "PLANNED":
        classResult += "back-planned-status ";
        break;
      case "DRAFT":
        classResult += "back-draft-status ";
        break;
      case "IN PROGRESS":
        classResult += "back-inprogress-status ";
        break;
      case "APPROVED":
        classResult += "back-approved-status ";
        break;
      case "REWORK":
        classResult += "back-rework-status ";
        break;
      case "READY FOR DISTRIBUTION":
        classResult += "back-ready-status ";
        break;
      case "DISTRIBUTED":
        classResult += "back-distributed-status ";
        break;
      case "CLOSED":
        classResult += "back-closed-status ";
        break;
    }
    return classResult;
  }

  public static CheckItemIsInList(id: string, list: any[]): boolean {
    return list?.filter((item) => item.id === id).length > 0;
  }

  public static CheckuserInList(
    userPrincipalName: string,
    list: User[]
  ): boolean {
    return (
      list?.filter((item) => item.userPrincipalName === userPrincipalName)
        .length > 0
    );
  }

  public static formatDate(date: Date, format: string): string {
    return moment(date).format(format);
  }

  public static FormatDate(date?: string | Date): string {
    if (date) {
      if (typeof date === "string") {
        var d = new Date(date);
        return (
          d
            .getDate()
            .toString()
            .padStart(2, "0") +
          "/" +
          (d.getMonth() + 1).toString().padStart(2, "0") +
          "/" +
          (d.getFullYear() % 100).toString()
        );
      } else {
        return (
          date
            .getDate()
            .toString()
            .padStart(2, "0") +
          "/" +
          (date.getMonth() + 1).toString().padStart(2, "0") +
          "/" +
          (date.getFullYear() % 100).toString()
        );
      }
    } else {
      return "";
    }
  }

  public static FormatDateAndTime(date?: string | Date): string {
    if (date) {
      if (typeof date === "string") {
        var d = new Date(date);
        return (
          d
            .getDate()
            .toString()
            .padStart(2, "0") +
          "/" +
          (d.getMonth() + 1).toString().padStart(2, "0") +
          "/" +
          (d.getFullYear() % 100).toString() +
          " " +
          d
            .getHours()
            .toString()
            .padStart(2, "0") +
          ":" +
          d
            .getMinutes()
            .toString()
            .padStart(2, "0")
        );
      } else {
        return (
          date
            .getDate()
            .toString()
            .padStart(2, "0") +
          "/" +
          (date.getMonth() + 1).toString().padStart(2, "0") +
          "/" +
          (date.getFullYear() % 100).toString() +
          " " +
          date
            .getHours()
            .toString()
            .padStart(2, "0") +
          ":" +
          date
            .getMinutes()
            .toString()
            .padStart(2, "0")
        );
      }
    } else {
      return "";
    }
  }

  public static FormatTime(date?: string | Date): string {
    if (date) {
      if (typeof date === "string") {
        var d = new Date(date);
        return (
          d
            .getHours()
            .toString()
            .padStart(2, "0") +
          ":" +
          d
            .getMinutes()
            .toString()
            .padStart(2, "0")
        );
      } else {
        return (
          date
            .getHours()
            .toString()
            .padStart(2, "0") +
          ":" +
          date
            .getMinutes()
            .toString()
            .padStart(2, "0")
        );
      }
    } else {
      return "";
    }
  }

  public static GetCounterFromActivityState(
    state: ActivityStatusEnum,
    counters: IActivitiesCount[]
  ): IActivitiesCount {
    var element = counters.filter((x) => x.state == state);
    if (element && element.length > 0) {
      return element[0];
    } else {
      return {
        state: state,
        count: 0,
      };
    }
  }

  public static GetCounterFromBriefState(
    state: BriefStatusEnum,
    counters: IBriefsCount[]
  ): IBriefsCount {
    var element = counters.filter((x) => x.state == state);
    if (element && element.length > 0) {
      return element[0];
    } else {
      return {
        state: state,
        count: 0,
      };
    }
  }

  public static GetFileSize(size: number): string {
    if (size > 1000000000) {
      return (size / 1000000000).toFixed(2) + " GB";
    } else if (size > 1000000) {
      return (size / 1000000).toFixed(2) + " MB";
    } else if (size > 1000) {
      return (size / 1000).toFixed(2) + " KB";
    } else {
      return size + " bytes";
    }
  }

  public static getDefaultActivityPermissions(): ActivityPermissions {
    return {
      canAddNote: false,
      canAddTask: false,
      canDelete: false,
      canDeleteDeliverables: false,
      canDownloadDeliverables: false,
      canUpdate: false,
      canUpdateResources: false,
      canUpdateStatus: false,
      canUploadDeliverables: false,
      deletableNotes: [],
      deletableTasks: [],
      updatableNotes: [],
      updatableTasks: [],
    };
  }

  public static isResponseStatusValid(response: number) {
    return response >= 200 && response < 300 ? true : false;
  }

  public static CopyAndSort<T>(
    items: T[],
    columnKey: string,
    isSortedDescending?: boolean
  ): T[] {
    const key = columnKey as keyof T;
    return items
      .slice(0)
      .sort((a: T, b: T) =>
        (isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1
      );
  }
}
