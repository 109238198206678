import React from "react";
import { RangeCalendarTranslation } from "./RangeCalendar.Translation";
import { Constants } from "../../../Utility/Constants";
import "../../../Utility/DateExtensions";
import "./RangeCalendar.scss";
import {
  Calendar,
  Label,
  DayOfWeek,
  IDatePickerStrings,
  mergeStyleSets,
  TextField,
  IconButton,
  ActionButton,
  ICalendarProps,
  Toggle,
} from "@fluentui/react";
import { IDateRangeValue } from "./IDateRangeValue";
import moment from "moment";
import { IBaseProperties } from "../../../Models/IBaseProperties";
import { DateHelper } from "../../../Models/DateHelper";

interface ITime {
  fromHour: number;
  fromMinute: number;
  toHour: number;
  toMinute: number;
}

export interface IRangeCalendardProps extends IBaseProperties {
  showTime: boolean;
  disableTime: boolean;
  value?: IDateRangeValue | undefined;
  minDate?: Date | undefined;
  maxDate?: Date | undefined;
  onChange: ((newValue?: IDateRangeValue) => void) | undefined;
  onDismiss?: (() => void) | undefined;
}
export interface IRangeCalendardState {
  showTime: boolean;
  fromDate: Date | undefined;
  fromHour: number;
  fromMinutes: number;
  toDate: Date | undefined;
  toHour: number;
  toMinutes: number;
  showCalendar: boolean;
  value: IDateRangeValue | undefined;
}

const controlClass = mergeStyleSets({
  control: {
    margin: "0 0 0px 0",
    maxWidth: "315px",
  },
  wrap: {
    padding: "100px",
  },
});

export class RangeCalendar extends React.Component<
  IRangeCalendardProps,
  IRangeCalendardState
> {
  private node: any;
  private readonly _translation: RangeCalendarTranslation;

  constructor(props: IRangeCalendardProps) {
    super(props);
    this._translation = new RangeCalendarTranslation(
      props.commonProps.translation
    );
    const initialTime = this.initialTime;

    this.setInitialDateState(props, initialTime);

    this.bindEvents();
  }

  private setInitialDateState(props: IRangeCalendardProps, initialTime: ITime) {
    if (props.value) {
      let { fromHour, fromMinute, toHour, toMinute } = initialTime;
      if (!props.disableTime && props.showTime) {
        fromHour = props.value.fromDate.getHours();
        fromMinute = props.value.fromDate.getMinutes();
        toHour = props.value.toDate.getHours();
        toMinute = props.value.toDate.getMinutes();
      }

      this.state = {
        fromDate: props.value.fromDate,
        toDate: props.value.toDate,
        showCalendar: false,
        showTime: props.showTime,
        value: props.value,
        fromHour: fromHour,
        fromMinutes: fromMinute,
        toHour: toHour,
        toMinutes: toMinute,
      };
    } else {
      this.state = {
        fromDate: undefined,
        toDate: undefined,
        showCalendar: false,
        value: undefined,
        showTime: false,
        fromHour: initialTime.fromHour,
        fromMinutes: initialTime.fromMinute,
        toHour: initialTime.toHour,
        toMinutes: initialTime.toMinute,
      };
    }
  }

  private setDateState(props: IRangeCalendardProps, initialTime: ITime) {
    if (props.value) {
      this.setState({
        fromDate: props.value.fromDate,
        toDate: props.value.toDate,
        showCalendar: false,
        value: props.value,
        fromHour: initialTime.fromHour,
        fromMinutes: initialTime.fromMinute,
        toHour: initialTime.toHour,
        toMinutes: initialTime.toMinute,
      });
    } else {
      this.setState({
        fromDate: undefined,
        toDate: undefined,
        showCalendar: false,
        value: undefined,
        fromHour: initialTime.fromHour,
        fromMinutes: initialTime.fromMinute,
        toHour: initialTime.toHour,
        toMinutes: initialTime.toMinute,
      });
    }
  }

  componentDidUpdate(prevProps: IRangeCalendardProps) {
    if (prevProps.value !== this.props.value) {
      if (this.props.value) {
        const { fromDate, toDate } = this.props.value;

        this.setState({
          fromDate: fromDate,
          toDate: toDate,
          value: this.props.value,
        });

        if (!this.props.disableTime && this.state.showTime) {
          this.setState({
            fromHour: fromDate.getHours(),
            fromMinutes: fromDate.getMinutes(),
            toHour: toDate.getHours(),
            toMinutes: toDate.getMinutes(),
          });
        }
      } else {
        this.setState({
          fromDate: undefined,
          toDate: undefined,
          value: undefined,
        });
      }
    }
  }

  private getDayPickerStrings(): IDatePickerStrings {
    const capitalize = (s: string) => {
      if (typeof s !== "string") return "";
      return s.charAt(0).toUpperCase() + s.slice(1);
    };
    const monthNames = () => DateHelper.monthName.map((m) => capitalize(m));
    const shortDays = () =>
      DateHelper.weekDayShortName.map((m) => capitalize(m));
    return {
      months: monthNames(),
      shortMonths: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],

      days: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],

      // shortDays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      shortDays: shortDays(),
      goToToday: "Go to today",
      prevMonthAriaLabel: "Go to previous month",
      nextMonthAriaLabel: "Go to next month",
      prevYearAriaLabel: "Go to previous year",
      nextYearAriaLabel: "Go to next year",
      closeButtonAriaLabel: "Close date picker",
      monthPickerHeaderAriaLabel: "{0}, select to change the year",
      yearPickerHeaderAriaLabel: "{0}, select to change the month",
    };
  }
  private bindEvents() {
    this.onSelectFromDate = this.onSelectFromDate.bind(this);
    this.onSelectToDate = this.onSelectToDate.bind(this);
    this.toggleCalendar = this.toggleCalendar.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    this.hideCalendar = this.hideCalendar.bind(this);
    this.resetCalendar = this.resetCalendar.bind(this);
    this.acceptDate = this.acceptDate.bind(this);
  }

  private get dateSelected(): boolean {
    return this.state.fromDate && this.state.toDate ? true : false;
  }

  fromDate(): Date | undefined {
    let date: Date | undefined;
    if (this.state.fromDate) {
      date = this.state.fromDate;
    } else if (this.props.minDate) {
      date = this.props.minDate;
    }

    return date;
  }

  toDate(): Date | undefined {
    let date: Date | undefined;
    if (this.state.toDate) {
      date = this.state.toDate;
    } else if (this.props.maxDate) {
      date = this.props.maxDate;
    }

    return date;
  }

  fromDateSelected(): Date | undefined {
    return this.state.fromDate;
  }

  toDateSelected(): Date | undefined {
    return this.state.toDate;
  }

  private toDateWithTime(date: Date, hour: number, minute: number): Date {
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      hour,
      minute
    );
  }

  get fromDateTime(): Date | undefined {
    const date = this.fromDateSelected();
    if (!this.props.disableTime && this.state.showTime && date) {
      return this.toDateWithTime(
        date,
        this.state.fromHour,
        this.state.fromMinutes
      );
    } else {
      return this.fromDateSelected();
    }
  }

  get toDateTime(): Date | undefined {
    const date = this.toDateSelected();
    if (!this.props.disableTime && this.state.showTime && date) {
      return this.toDateWithTime(date, this.state.toHour, this.state.toMinutes);
    } else {
      return this.toDateSelected();
    }
  }

  private onSelectFromDate(date: Date | null | undefined): void | undefined {
    if (date) {
      let newDate = date;
      if (!this.props.disableTime && this.state.showTime) {
        newDate = this.toDateWithTime(
          date,
          this.state.fromHour,
          this.state.fromMinutes
        );
      } else {
        newDate = this.toDateWithTime(date, 0, 0);
      }

      let toDate: Date | undefined = this.toDateTime;
      let toHour: number = this.state.toHour;
      let toMinutes: number = this.state.toMinutes;

      if (!toDate) {
        if (newDate.getMonth() !== new Date().getMonth()) {
          if (!this.props.disableTime && this.state.showTime) {
            if (this.state.fromHour === 23) {
              if (this.state.fromMinutes === 30) {
                toHour = 0;
                toMinutes = 0;
                toDate = this.toDateWithTime(
                  newDate.addDays(1),
                  toHour,
                  toMinutes
                );
              } else {
                toHour = 23;
                toMinutes = 30;
                toDate = this.toDateWithTime(newDate, 23, 30);
              }
            } else {
              toHour = this.state.fromHour + 1;
              toMinutes = 0;
              toDate = this.toDateWithTime(newDate, toHour, toMinutes);
            }
          } else {
            toDate = newDate.toEndOfDay();
          }
        }
      } else if (toDate < newDate) {
        if (!this.props.disableTime && this.state.showTime) {
          if (this.state.fromHour === 23) {
            if (this.state.fromMinutes === 30) {
              toHour = 0;
              toMinutes = 0;
              toDate = this.toDateWithTime(
                newDate.addDays(1),
                toHour,
                toMinutes
              );
            } else {
              toHour = 23;
              toMinutes = 30;
              toDate = this.toDateWithTime(newDate, 23, 30);
            }
          } else {
            toHour = this.state.fromHour + 1;
            toMinutes = 0;
            toDate = this.toDateWithTime(newDate, toHour, toMinutes);
          }
        } else {
          toDate = newDate.toEndOfDay();
        }
      }

      if (toDate && toDate < newDate) {
        this.setState(
          {
            fromDate: newDate,
            toDate: undefined,
            toHour: toHour,
            toMinutes: toMinutes,
          },
          () => this.onChangeDateRangeValue()
        );
      } else {
        if (toDate) {
          this.setState(
            {
              fromDate: newDate,
              toDate: toDate,
              toHour: toHour,
              toMinutes: toMinutes,
            },
            () => this.onChangeDateRangeValue()
          );
        } else {
          this.setState(
            { fromDate: newDate, toHour: toHour, toMinutes: toMinutes },
            () => this.onChangeDateRangeValue()
          );
        }
      }
    }
  }

  getLocale() {
    return navigator.languages && navigator.languages.length
      ? navigator.languages[0]
      : navigator.language;
  }

  dateStringValue(): string | undefined {
    if (this.fromDateTime || this.toDateTime) {
      let from: string, to: string;
      if (!(!this.props.disableTime && this.state.showTime)) {
        const locale = "it"; //this.getLocale();
        const format: Intl.DateTimeFormatOptions = {
          day: "numeric",
          month: "2-digit",
          year: "numeric",
          hour12: false,
        };

        from = this.fromDateTime
          ? this.fromDateTime?.toLocaleDateString(locale, format)
          : "";
        to = this.toDateTime
          ? this.toDateTime?.toLocaleDateString(locale, format)
          : "";
      } else {
        const locale = "it"; //this.getLocale();
        const format: Intl.DateTimeFormatOptions = {
          day: "numeric",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        };

        from = this.fromDateTime
          ? this.fromDateTime?.toLocaleDateString(locale, format)
          : "";
        to = this.toDateTime
          ? this.toDateTime?.toLocaleDateString(locale, format)
          : "";
      }
      return `${from} - ${to}`;
    } else {
      return undefined;
    }
  }

  private onChangeDateRangeValue() {
    if (this.props.onChange && this.state.fromDate && this.state.toDate) {
      const value: IDateRangeValue = {
        fromDate: this.state.fromDate,
        toDate: this.state.toDate,
      };
      //this.props.onChange(value);
    }
  }

  private onSelectToDate(date: Date | null | undefined): void | undefined {
    if (date) {
      let newDate = date;
      if (!this.props.disableTime && this.state.showTime) {
        newDate = this.toDateWithTime(
          date,
          this.state.toHour,
          this.state.toMinutes
        );
      }

      if (
        !this.fromDateTime ||
        (this.fromDateTime && this.fromDateTime <= newDate)
      ) {
        this.setState({ toDate: newDate }, () => this.onChangeDateRangeValue());
      }
    }
  }

  private getMinDate(): Date | undefined {
    if (this.state.fromDate) {
      return this.state.fromDate;
    } else {
      return this.props.minDate;
    }
  }

  private range(start: number, end: number) {
    return Array(end - start + 1)
      .fill(0)
      .map((i, index) => start + index);
  }

  private get initialTime(): ITime {
    const hour = this.currentHours;
    // const minute = this.currentMinutes;

    if (this.currentMinutes < 30) {
      return { fromHour: hour, fromMinute: 30, toHour: hour + 1, toMinute: 0 };
    } else {
      return {
        fromHour: hour + 1,
        fromMinute: 0,
        toHour: hour + 1,
        toMinute: 30,
      };
    }
  }

  private get currentHours() {
    return new Date().getHours();
  }

  private get currentMinutes() {
    return new Date().getMinutes();
  }

  private get fromHour(): string | undefined {
    return this.timeToString(this.state.fromHour);
  }

  private get fromMinute(): string | undefined {
    return this.timeToString(this.state.fromMinutes);
  }

  private get toHour(): string | undefined {
    return this.timeToString(this.state.toHour);
  }

  private get toMinute(): string | undefined {
    return this.timeToString(this.state.toMinutes);
  }

  private timeToString(value?: number) {
    if (value) {
      return value.toString();
    } else {
      return "00";
    }
  }

  toTimeRow(
    isFrom: boolean,
    isHour: boolean,
    value?: string | undefined
  ): React.ReactElement {
    const jsxTime = (
      <div className="timeContainer">
        <div>{isHour ? "Hour" : "Minute"}</div>
        <div>
          <IconButton
            styles={Constants.iIconButtonCalendarStyle}
            aria-label="Calendar"
            iconProps={Constants.iChevronDownSmallCalendar}
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                this.changeTime(isFrom, false, isHour);
              }
            }}
            onClick={() => {
              this.changeTime(isFrom, false, isHour);
            }}
          ></IconButton>
        </div>
        <div>
          <TextField
            value={value}
            readOnly
            maxLength={2}
            styles={Constants.iTextCalendardStyle}
          ></TextField>
        </div>
        <div>
          <IconButton
            styles={Constants.iIconButtonCalendarStyle}
            aria-label="Edit"
            iconProps={Constants.iChevronUpSmallCalendar}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                this.changeTime(isFrom, true, isHour);
              }
            }}
            onClick={() => {
              this.changeTime(isFrom, true, isHour);
            }}
          ></IconButton>
        </div>
      </div>
    );

    return jsxTime;
  }

  render() {
    const fromCalendard = (
      <Calendar
        className={controlClass.control}
        firstDayOfWeek={DayOfWeek.Monday}
        strings={this.getDayPickerStrings()}
        showWeekNumbers={false}
        showMonthPickerAsOverlay={false}
        isMonthPickerVisible={false}
        showGoToToday={false}
        value={this.fromDate()!}
        minDate={this.props.minDate}
        maxDate={this.props.maxDate}
        onSelectDate={this.onSelectFromDate}
      ></Calendar>
    );

    const toCalendar = (
      <Calendar
        className={controlClass.control}
        firstDayOfWeek={DayOfWeek.Monday}
        strings={this.getDayPickerStrings()}
        showWeekNumbers={false}
        showMonthPickerAsOverlay={false}
        isMonthPickerVisible={false}
        showGoToToday={false}
        autoNavigateOnSelection={true}
        value={this.toDate()}
        onSelectDate={this.onSelectToDate}
        minDate={this.getMinDate()}
        maxDate={this.props.maxDate}
      ></Calendar>
    );

    const showCalendar = this.state.showCalendar;

    const jsxTimeFromHour = this.toTimeRow(true, true, this.fromHour);
    const jsxTimeFromMinute = this.toTimeRow(true, false, this.fromMinute);

    const jsxTimeToHour = this.toTimeRow(false, true, this.toHour);
    const jsxTimeToMinute = this.toTimeRow(false, false, this.toMinute);

    const jsxTimeFrom = (
      <div>
        {jsxTimeFromHour}
        {jsxTimeFromMinute}
      </div>
    );

    const jsxTimeTo = (
      <div>
        {jsxTimeToHour}
        {jsxTimeToMinute}
      </div>
    );

    const jsxCloseButton = (
      <ActionButton
      ariaLabel={this._translation.cancel}
        aria-label="Cancel"
        iconProps={Constants.iCancel}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            this.hideCalendar()
          }
        }}
        onClick={this.hideCalendar}
        title={this._translation.close}
      ></ActionButton>
    );
    const jsxResetButton = (
      <ActionButton
      ariaLabel={this._translation.reset}
        // iconProps={Constants.iDeleteCalendar}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                this.resetCalendar()
              }
            }}
        onClick={this.resetCalendar}
        title={this._translation.reset}
        text="Reset"
      ></ActionButton>
    );

    const toggleButton = !this.props.disableTime && (
      <Toggle
        className="toggleTime"
        label="Include time"
        inlineLabel
        // onText="On"
        // offText="Off"
        checked={this.state.showTime}
        onChange={(e, checked) => {
          if (checked != undefined) {
            this.setState({ showTime: checked });
            if (!checked) {
              this.setState({
                fromDate: this.state.fromDate?.toStartOfDay(),
                toDate: this.state.toDate?.toEndOfDay(),
              });
            }
          }
        }}
      />
    );
    const jsxAcceptButton = (
      <ActionButton
        iconProps={Constants.iAccept}
        disabled={!this.dateSelected}
        ariaLabel={this._translation.save}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            this.acceptDate()
          }
        }}
        onClick={this.acceptDate}
        aria-label="Confirm"
        title={this._translation.apply}
      ></ActionButton>
    );
    return (
      <React.Fragment>
        <div
          className="calendardContainer"
          ref={(node) => {
            this.node = node;
          }}
          id="rangeCalendar"
        >
          <div
            className=""
            data-automationid="rangeCalendardLabel"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                this.toggleCalendar()
              }
            }}
            onClick={this.toggleCalendar}
          >
            {/* <div className="" data-automationid="rangeCalendardLabel"> */}
            <TextField
              role="combobox"
              iconProps={Constants.iRangeCalendar}
              readOnly
              placeholder={this._translation.select}
              value={this.dateStringValue()}
              styles={Constants.textFieldDateRangeStyle}
            ></TextField>
          </div>
          {showCalendar && (
            <React.Fragment>
              <div
                className="rangeCalendardContainer"
                data-automationid="rangeCalendardContainer"
              >
                <div>
                  {this.state.showTime && jsxTimeFrom}
                  {!this.state.showTime && (
                    <Label styles={Constants.calendarlabelStyle}>
                      {this._translation.from}
                    </Label>
                  )}
                  {fromCalendard}
                </div>
                <div>
                  {this.state.showTime && jsxTimeTo}
                  {!this.state.showTime && (
                    <Label styles={Constants.calendarlabelStyle}>
                      {this._translation.to}
                    </Label>
                  )}
                  {toCalendar}
                </div>
                <div className="rangeCalendard-toolbar">
                  <div>
                    {/* {jsxResetButton} */}
                    {toggleButton}
                  </div>
                  <div>
                    {jsxAcceptButton}
                    {jsxCloseButton}
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    );
  }

  private getTimeVale(isFrom: boolean, isHour: boolean): number {
    if (isFrom) {
      return isHour ? this.state.fromHour : this.state.fromMinutes;
    } else {
      return isHour ? this.state.toHour : this.state.toMinutes;
    }
  }

  getProperty<T, K extends keyof T>(obj: T, key: K) {
    return obj[key]; // Inferred type is T[K]
  }

  private changeTime(isFrom: boolean, isUp: boolean, isHour: boolean) {
    const value = this.getTimeVale(isFrom, isHour);
    const max = 23;
    let newValue = value;

    if (isHour) {
      if (isUp && value < max) {
        newValue++;
      } else if (!isUp && value > 0) {
        newValue--;
      }
    } else {
      newValue = value === 0 ? 30 : 0;
    }

    if (newValue !== value) {
      this.setNewStateTimeValue(isFrom, isHour, newValue);
    }
  }

  private setNewStateTimeValue(
    isFrom: boolean,
    isHour: boolean,
    newValue: number
  ) {
    const checkValue = this.checkTimeValue(isFrom, isHour, newValue);

    if (checkValue) {
      if (isFrom && isHour) {
        this.setState({ fromHour: newValue });
      } else if (isFrom && !isHour) {
        this.setState({ fromMinutes: newValue });
      } else if (!isFrom && isHour) {
        this.setState({ toHour: newValue });
      } else {
        this.setState({ toMinutes: newValue });
      }
    }
  }

  private checkTimeValue(isFrom: boolean, isHour: boolean, newValue: number) {
    let retValue = true;

    const fromDate = moment(this.state.fromDate);
    const toDate = moment(this.state.toDate);
    //check that the new time is compatible with the existing dates
    if (moment(toDate).isSame(fromDate, "day")) {
      const { fromHour, fromMinutes, toHour, toMinutes } = this.state;

      if (isFrom) {
        if (isHour) {
          if (
            newValue > toHour ||
            (newValue === toHour && fromMinutes <= toMinutes)
          ) {
            retValue = false;
          }
        } else {
          if (fromHour === toHour && newValue >= toMinutes) {
            retValue = false;
          }
        }
      } else {
        if (isHour) {
          if (
            newValue < fromHour ||
            (newValue === fromHour && fromMinutes >= toMinutes)
          ) {
            retValue = false;
          }
        } else {
          if (fromHour === toHour && newValue <= fromMinutes) {
            retValue = false;
          }
        }
      }
    }

    return retValue;
  }

  private hideCalendar(
    event?: React.MouseEvent<
      | HTMLAnchorElement
      | HTMLButtonElement
      | HTMLDivElement
      | import("@fluentui/react").BaseButton
      | import("@fluentui/react").Button
      | HTMLSpanElement,
      MouseEvent
    >
  ): void | undefined {
    document.removeEventListener("click", this.handleOutsideClick, false);
    this.setDateState(this.props, this.initialTime);

    this.setState({ showCalendar: false });
    if (this.props.onDismiss) this.props?.onDismiss();
  }

  acceptDate(
    event?: React.MouseEvent<
      | HTMLAnchorElement
      | HTMLButtonElement
      | HTMLDivElement
      | import("@fluentui/react").BaseButton
      | import("@fluentui/react").Button
      | HTMLSpanElement,
      MouseEvent
    >
  ): void | undefined {
    this.hideCalendar(event);
    if (this.props.onChange && this.state.fromDate && this.state.toDate) {
      let value: IDateRangeValue;
      let {
        fromDate,
        toDate,
        fromHour,
        fromMinutes,
        toHour,
        toMinutes,
      } = this.state;
      if (!this.props.disableTime && this.state.showTime) {
        fromDate.setHours(fromHour);
        fromDate.setMinutes(fromMinutes);
        toDate.setHours(toHour);
        toDate.setMinutes(toMinutes);
        value = { fromDate: fromDate, toDate: this.state.toDate };
      } else {
        value = {
          fromDate: fromDate.toStartOfDay(),
          toDate: toDate.toEndOfDay(),
        };
      }

      this.props.onChange(value);
    }
  }

  resetCalendar(
    event?: React.MouseEvent<
      | HTMLAnchorElement
      | HTMLButtonElement
      | HTMLDivElement
      | import("@fluentui/react").BaseButton
      | import("@fluentui/react").Button
      | HTMLSpanElement,
      MouseEvent
    >
  ): void | undefined {
    document.removeEventListener("click", this.handleOutsideClick, false);
    this.setState(
      { showCalendar: false, fromDate: undefined, toDate: undefined },
      () => {
        if (this.props.onChange) this.props.onChange(undefined);
      }
    );
  }

  toggleCalendar() {
    const currentState = this.state.showCalendar;
    //disabled click outside range calendar
    // if (!currentState) {
    //   document.addEventListener("click", this.handleOutsideClick, false);
    // } else {
    //   document.removeEventListener("click", this.handleOutsideClick, false);
    // }

    if (currentState)
    {
      this.onChangeDateRangeValue();
      this.acceptDate();
    }

    this.setState({ showCalendar: !currentState });
  }

  handleOutsideClick(e: MouseEvent) {
    if (this.node && e) {
      if (!this.node.contains(e.target)) this.toggleCalendar();
    }
  }
}
