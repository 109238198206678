import PropTypes from "prop-types";
import React from "react";
import clsx from "clsx";
import * as dates from "./utils/dates";
import {
  TooltipHost,
  TooltipDelay,
  DirectionalHint,
  PersonaSize,
} from "@fluentui/react";
import { Utility } from "../../../../Utility/Utility";
import { PanelActivityHeaderComponent } from "../../../Shared/PanelActivityHeaderComponent/PanelActivityHeaderComponent";
import { ActivityTooltip } from "../../Planner/ActivityTooltip";

class EventCell extends React.Component {
  render() {
    let {
      style,
      className,
      event,
      selected,
      isAllDay,
      onSelect,
      onDoubleClick,
      onKeyPress,
      localizer,
      continuesPrior,
      continuesAfter,
      accessors,
      getters,
      children,
      components: { event: Event, eventWrapper: EventWrapper },
      slotStart,
      slotEnd,
      len,
      commonProps,
      ...props
    } = this.props;

    let title = accessors.title(event);
    let tooltip = accessors.tooltip(event);
    let end = accessors.end(event);
    let start = accessors.start(event);
    let allDay = accessors.allDay(event);

    let showAsAllDay =
      isAllDay ||
      allDay ||
      dates.diff(start, dates.ceil(end, "day"), "day") > 1;

    let userProps = getters.eventProp(event, start, end, selected);

    const content = (
      <div className="rbc-event-content" title={tooltip || undefined}>
        {Event ? (
          <Event
            event={event}
            continuesPrior={continuesPrior}
            continuesAfter={continuesAfter}
            title={title}
            isAllDay={allDay}
            localizer={localizer}
            slotStart={slotStart}
            slotEnd={slotEnd}
          />
        ) : (
          title
        )}
      </div>
    );
    let listaMicro = "";
    if (event.linkedActivities) {
      let eventStart = new Date(
        event.start.getFullYear(),
        event.start.getMonth(),
        event.start.getDate()
      );
      let eventEnd = new Date(
        event.end.getFullYear(),
        event.end.getMonth(),
        event.end.getDate()
      );
      eventStart = eventStart < slotStart ? slotStart : eventStart;
      eventEnd = eventEnd > slotEnd ? slotEnd : eventEnd;
      let slotsGG = len;
      let microList = event.linkedActivities.filter(function(micro) {
        return (
          new Date(
            micro.start.getFullYear(),
            micro.start.getMonth(),
            micro.start.getDate()
          ) < slotEnd &&
          new Date(
            micro.end.getFullYear(),
            micro.end.getMonth(),
            micro.end.getDate()
          ) >= slotStart
        );
      });
      listaMicro = microList.map((micro, index) => {
        let microStart = new Date(
          micro.start.getFullYear(),
          micro.start.getMonth(),
          micro.start.getDate()
        );
        let microEnd = new Date(
          micro.end.getFullYear(),
          micro.end.getMonth(),
          micro.end.getDate()
        );
        let dateStartToCompare =
          microStart < eventStart ? eventStart : microStart;
        let dateEndToCompare = microEnd > eventEnd ? eventEnd : microEnd;
        let blankGG = Math.ceil(
          Math.abs(dateStartToCompare - eventStart) / (1000 * 60 * 60 * 24)
        );
        let microGG =
          Math.ceil(
            Math.abs(dateEndToCompare - dateStartToCompare) /
              (1000 * 60 * 60 * 24)
          ) + 1;
        microGG = microGG > slotsGG - blankGG ? slotsGG - blankGG : microGG;

        let blankSpacePerc = (Math.abs(blankGG) / slotsGG) * 100 + "%";
        let spacePerc = (Math.abs(microGG) / slotsGG) * 100 + "%";

        return (
          <div key={index} style={{ margin: "0 -9px" }}>
            {blankGG != 0 && (
              <div
                style={{
                  WebkitFlexBasis: blankSpacePerc,
                  flexBasis: blankSpacePerc,
                  width: blankSpacePerc,
                  display: "inline-block",
                  padding: "0 9px",
                }}
              ></div>
            )}
            <div
              style={{
                WebkitFlexBasis: spacePerc,
                flexBasis: spacePerc,
                width: spacePerc,
                display: "inline-block",
                padding: "0 9px",
              }}
            >
              <TooltipHost
                calloutProps={{ gapSpace: 0 }}
                tooltipProps={{
                  onRenderContent: () =>
                    this.renderActivityToolTip(commonProps, micro),
                }}
                delay={TooltipDelay.zero}
                id={event.id}
                directionalHint={DirectionalHint.bottomCenter}
              >
                <div
                  className="rbc-event-internal-div rbc-event-micro"
                  tabIndex={0}
                  onClick={(e) => onSelect && onSelect(micro, e)}
                  onDoubleClick={(e) =>
                    onDoubleClick && onDoubleClick(micro, e)
                  } 
                  onKeyPress={(e) => onKeyPress && onKeyPress(micro, e)}
                >
                  <div style={{ float: "left" }}>
                    <div className="rbc-event-calendar">{micro.calendar}</div>
                    <div
                      className={[
                        "rbc-event-dot",
                        Utility.GetActivityClassNameFromStatus(
                          micro.state,
                          false
                        ),
                      ].join(" ")}
                    ></div>                                        
                  </div>
                  <div
                    className="rbc-event-content"
                    title={tooltip || undefined}
                    >                                                             
                    {/* <span style={{ fontWeight:"normal", fontSize:"smaller" }}> {micro.state} </span> */}
                    <span className={[                      
                      Utility.GetActivityClassNameForStatus(
                        micro.state,
                        false
                      ),
                    ].join(" ")} style={{ lineHeight: "0.93rem", fontWeight:"normal", marginRight:"0.5rem", fontSize:"small" }}>
                      {micro.state}
                    </span>
                     {micro.title} 
                  </div>                  
                  <div style={{ clear: "both" }}></div>
                </div>
              </TooltipHost>
            </div>
          </div>
        );
      });
    }
    let eventStateColor = Utility.GetActivityClassNameFromStatus(
      event.state,
      false
    );
    let noBackground = event.isMacro ? "" : "rbc-event-nobackground";

    return (
      <EventWrapper {...this.props} type="date">
        <div
          {...props}
          style={{ ...userProps.style, ...style }}
          className={clsx(
            "rbc-event",
            className,
            userProps.className,
            noBackground,
            {
              "rbc-selected": selected,
              "rbc-event-allday": showAsAllDay,
              "rbc-event-continues-prior": continuesPrior,
              "rbc-event-continues-after": continuesAfter,
            }
          )}
        >
          <TooltipHost
            calloutProps={{ gapSpace: 0 }}
            tooltipProps={{
              onRenderContent: () =>
                this.renderActivityToolTip(commonProps, event),
            }}
            delay={TooltipDelay.zero}
            id={event.id}
            directionalHint={DirectionalHint.bottomCenter}
          >
            <div
              className="rbc-event-internal-div"
              tabIndex={0}
              onClick={(e) => onSelect && onSelect(event, e)}
              onDoubleClick={(e) => onDoubleClick && onDoubleClick(event, e)}
              onKeyPress={(e) => onKeyPress && onKeyPress(event, e)}
            >
              {event.isMacro && (
                <>
                  <div className="rbc-event-macro">MACRO</div>
                  <div className="rbc-event-nmicro">{event.Nmicro}</div>
                </>
              )}
              <div style={{ float: "left" }}>
                <div className="rbc-event-calendar">{event.calendar}</div>
                {!event.isMacro && (                  
                    <div
                      className={["rbc-event-dot", eventStateColor].join(" ")}
                      >                          
                    </div>                                      
                )}                
                <span className={[                      
                      Utility.GetActivityClassNameForStatus(
                        event.state,
                        false
                      ),
                    ].join(" ")} style={{ lineHeight: "0.93rem", fontWeight:"normal", marginRight:"0.5rem", fontSize:"small", marginLeft:"0.625rem" }}> 
                  {event?.state}
                </span>
              </div>
              {typeof children === "function" ? children(content) : content}
              <div style={{ clear: "both" }}></div>
            </div>
          </TooltipHost>
          {event.linkedActivities && <div>{listaMicro}</div>}
        </div>
      </EventWrapper>
    );
  }

  renderActivityModalHeader() {
    return (
      <PanelActivityHeaderComponent
        commonProps={this.props.commonProps}
        item={{
          ...this.state.item?.parentMacro,
          isMacro: true,
        }}
        isMacro={false}
        showContextMenu={false}
        showStatus={false}
        showType={true}
        showManage={false}
      ></PanelActivityHeaderComponent>
    );
  }

  renderActivityToolTip(commonProps, event) {
    const activity = event;

    if (activity.linkedActivities) {
      activity.microsState = activity.linkedActivities.map((micro) => ({
        state: micro.state,
      }));
    }

    return (
      <ActivityTooltip
        commonProps={commonProps}
        activity={activity}
      ></ActivityTooltip>
    );
  }
}

EventCell.propTypes = {
  event: PropTypes.object.isRequired,
  slotStart: PropTypes.instanceOf(Date),
  slotEnd: PropTypes.instanceOf(Date),

  selected: PropTypes.bool,
  isAllDay: PropTypes.bool,
  continuesPrior: PropTypes.bool,
  continuesAfter: PropTypes.bool,

  accessors: PropTypes.object.isRequired,
  components: PropTypes.object.isRequired,
  getters: PropTypes.object.isRequired,
  localizer: PropTypes.object,

  onSelect: PropTypes.func,
  onDoubleClick: PropTypes.func,
  onKeyPress: PropTypes.func,
  commonProps: PropTypes.object,
};

export default EventCell;
