import * as React from "react";
import "./Footer.css";
import { ActionButton, Label, Separator, Stack } from "@fluentui/react";
import { IBaseProperties, IRoute, Page } from "../../Models/IBaseProperties";
import { accessibilityDocUrl } from "../../msal/MsalConfig"

export interface IFooterProps extends IBaseProperties {
  language: string;
  handleSetLanguage: (targetVal: any) => void;
}
export interface IFooterState { }

export class Footer extends React.Component<IFooterProps, IFooterState> {
  constructor(props: IFooterProps) {
    super(props);
    this.state = {};
    props.handleSetLanguage(props.language);
  }

  render() {
    return (
      <React.Fragment>
        <div className="ms-Grid footer">
          {/* <h1 style={{ height:0}}>{window.document.title}</h1> */}
          <div className="footer">
              <div className="footer-left">
                {this.props.commonProps.translation("footer.textLeft")}
              </div>
            <div className="footer-right">
              <a className="footer-button" href={accessibilityDocUrl.url} target="_blank" rel="noopener noreferrer" aria-label="Dichiarazione di accessiblita">Dichiarazione di Accessibilità</a>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
  private _alertClicked() {
    alert("Footer button clicked");
  }
}
