import * as React from 'react';
import './Report.css';
import { Image, ImageFit } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { IBaseProperties } from '../../../Models/IBaseProperties';
import { DeliverablesTranslation } from '../../../Translations/Deliverables.Translation';


export interface IReportProps extends IBaseProperties {
}
export interface IReportState {
}

export class Report extends React.Component<IReportProps, IReportState> {

  private readonly _translation: DeliverablesTranslation;
  constructor(props: IReportProps) {
    super(props);
    this.state = {
    };

    this._translation = new DeliverablesTranslation(
      this.props.commonProps.translation
    );
  }

  render() {
    return (
      <React.Fragment>
        <div className={"ms-Grid-col ms-xl3 ms-lg6 ms-md12 ms-sm12 paddingTop10"}>
          {/* {this._translation.noReportFound} */}
          Report - Work in progress
        </div>
      </React.Fragment>
    );
  }
};
