import React, { FocusEventHandler } from "react";
import {
  Autofill,
  IBasePicker,
  IBasePickerStyles,
  IBasePickerSuggestionsProps,
  ITag,
  TagPicker,
} from "@fluentui/react";
import { UserClient } from "../../../Clients/UserClient";
import { ConcurrentTask } from "../../../Utility/ConcurrentTasks";
import { IBaseProperties } from "../../../Models/IBaseProperties";
import { UserInfo } from "../../../Models/User";
import { UsersTranslation } from "../../../Translations/Users.Translation";
import { ToastNotificationType } from "../../../Models/ToastNote";

const makeCancelable = (
  promise: any
): { promise: Promise<unknown>; cancel(): void } => {
  let hasCanceled_ = false;
  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      (val: any) =>
        hasCanceled_ ? reject({ isCanceled: true }) : resolve(val),
      (error: any) =>
        hasCanceled_ ? reject({ isCanceled: true }) : reject(error)
    );
  });
  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true;
    },
  };
};

export interface ISpocProps extends IBaseProperties {
  styles?: Partial<IBasePickerStyles>;
  values: ITag[];
  onChange?: (selectedTags: ITag[], spocId?: string) => void;
  onBlur?:  (selectedTags: ITag[], spocId?: string) => void;
  minDigitBeforeSearch?: number;
  calendar: number;
}
export interface ISpocState {
  spocId?: string;
  selectedSpoc: ITag[];
}

export class Spoc extends React.Component<ISpocProps, ISpocState> {
  private readonly _usersTranslation: UsersTranslation;
  private readonly spocPicker = React.createRef<IBasePicker<ITag>>();
  private readonly UserInfoToITag = (item: UserInfo): ITag =>
    ({ key: item.username, name: item.displayName } as ITag);

  private pickerSuggestionsProps: IBasePickerSuggestionsProps = {
    suggestionsHeaderText: "Suggested SPOC",
    noResultsFoundText: "No SPOC found",
  };

  private readonly minDigitBeforeSearch: number;
  private readonly DEFAULTMINDIGIT = 3;
  private lastRequestCompleted: boolean = true;
  private pendingRequest: boolean = false;

  constructor(props: ISpocProps) {
    super(props);
    this._usersTranslation = new UsersTranslation(
      props.commonProps.translation
    );
    this.minDigitBeforeSearch = this.props.minDigitBeforeSearch
      ? this.props.minDigitBeforeSearch
      : this.DEFAULTMINDIGIT;

    this.state = {
      selectedSpoc: props.values,
    };

    this.bindEvents();
  }

  private readonly filterSpoc = (
    filter: string,
    selectedItems?: ITag[] | undefined
  ): ITag[] | PromiseLike<ITag[]> => {
    if (filter) {
      if (filter.length >= this.minDigitBeforeSearch) {
        this.pickerSuggestionsProps.noResultsFoundText = "No user found";

        const values = this.getSpocFilterAsync(filter);

        return values;
      } else {
        this.pickerSuggestionsProps.noResultsFoundText = `Enter at least ${this.minDigitBeforeSearch}  characters`;
        return [];
      }
    } else {
      return [];
    }
  };

  private currentListContainsTag = (tag: ITag, tagList?: ITag[]) => {
    if (!tagList || !tagList.length || tagList.length === 0) {
      return false;
    }
    return tagList.some((compareTag) => compareTag.key === tag.key);
  };

  private readonly checkSelectedItem = (
    selectedItem?: ITag | undefined
  ): ITag | PromiseLike<ITag> | null => {
    if (
      selectedItem &&
      this.spocPicker.current &&
      this.currentListContainsTag(
        selectedItem as ITag,
        this.spocPicker.current.items
      )
    ) {
      return null;
    }

    return selectedItem as ITag;
  };

  private bindEvents() {
    this.pickerSpocChange = this.pickerSpocChange.bind(this);
    this.pickerSpockBlur = this.pickerSpockBlur.bind(this);
  }

  private pickerSpocChange(items?: ITag[] | undefined): void {
    if (this.props.onChange) {
      if (items && (items as ITag[]).length == 1) {
        const id: string = (items as ITag[])[0].key as string;

        this.setState({ spocId: id, selectedSpoc: items });
        this.props.onChange(items, id);
      } else {
        this.setState({ spocId: undefined, selectedSpoc: [] });
        this.props.onChange([], undefined);
      }
    }
  }

  private pickerSpockBlur(event: React.FocusEvent<Autofill | HTMLInputElement>): void {
    if (this.props.onBlur) {
      if (this.spocPicker.current?.items?.length == 1) {
        const id: string = (this.spocPicker.current?.items as ITag[])[0].key as string;

        this.setState({ spocId: id, selectedSpoc: this.spocPicker.current?.items });
        this.props.onBlur(this.spocPicker.current?.items, id);
      } else {
        this.setState({ spocId: undefined, selectedSpoc: [] });
        this.props.onBlur([], undefined);
      }
    }
  }

  private pendingRequests: string[] = [];
  private cancelablePromise:
    | { promise: Promise<unknown>; cancel(): void }
    | undefined;

  private readonly _concurrentTask: ConcurrentTask<
    UserInfo[] | undefined
  > = new ConcurrentTask<UserInfo[] | undefined>();

  private async getSpocFilterAsync(filter: string): Promise<ITag[]> {
    const userclient: UserClient = new UserClient();

    const promise = () => userclient.getSpocByFilterAsync({term: filter, calendar: this.props.calendar});

    const result = await this._concurrentTask.executePromise(promise, []);

    if (result) {
      return this.pendingRequest ? [] : result.map(this.UserInfoToITag);
    } else {
      this.props.commonProps.toastComponent?.showMessage(
        this._usersTranslation.error,
        this._usersTranslation.genericGetUsersError,
        ToastNotificationType.ERROR
      );
      return [];
    }
  }

  private async getSpocFilterAsync2(filter: string): Promise<ITag[]> {
    this.pendingRequests.push(filter); //enqueue the incoming request

    if (this.cancelablePromise) {
      //if there's a pending request, it will be aborted
      this.cancelablePromise.cancel(); //cancel the previous request-task (promise)
    }

    console.debug(
      "Waiting for previous request. Requests ",
      this.pendingRequests.length
    );

    await this.waitForRequestCompletion(300); //wait completition of the previous request

    console.debug("Previous request completed", filter);

    if (this.cancelablePromise) {
      this.cancelablePromise.cancel(); //cancel the previous task (promise)
    }

    if (this.pendingRequests.length > 1) {
      this.pendingRequests.shift();
      return [];
    }

    this.pendingRequests.pop();
    const sFilter = filter;

    const userclient: UserClient = new UserClient();
    console.debug(
      "Pending http request ",
      sFilter,
      this.pendingRequests.length
    );
    this.cancelablePromise = makeCancelable(
      userclient.getSpocByFilterAsync({term: sFilter, calendar: this.props.calendar})
    );
    try {
      const promise = await this.cancelablePromise.promise;

      const result: UserInfo[] | undefined = promise as UserInfo[] | undefined;

      // const result = await userclient.getSpocByFilterAsync(sFilter);

      console.debug("Request http completed", sFilter);

      if (result) {
        return this.pendingRequest ? [] : result.map(this.UserInfoToITag);
      } else {
        this.props.commonProps.toastComponent?.showMessage(
          this._usersTranslation.error,
          this._usersTranslation.genericGetUsersError,
          ToastNotificationType.ERROR
        );
        return [];
      }
    } catch (err) {
      console.debug("Request aborted");
      return [];
    } finally {
      this.cancelablePromise = undefined;
      this.lastRequestCompleted = true;
    }
  }

  private timeout(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  private async waitForRequestCompletion(retryMs: number): Promise<void> {
    if (!this.lastRequestCompleted) {
      this.pendingRequest = true;
    }

    while (!this.lastRequestCompleted) {
      await this.timeout(retryMs);
    }

    this.pendingRequest = false;
  }

  render() {
    const jsxSpoc = (
      <TagPicker
        ref={this.spocPicker}
        onItemSelected={this.checkSelectedItem}
        pickerSuggestionsProps={this.pickerSuggestionsProps}
        removeButtonAriaLabel="Remove"
        onResolveSuggestions={this.filterSpoc}
        resolveDelay={300}
        itemLimit={1}
        onChange={this.pickerSpocChange}
        onBlur={this.pickerSpockBlur}
        selectedItems={this.state.selectedSpoc}
        styles={this.props.styles}
      ></TagPicker>
    );

    return jsxSpoc;
  }
}
