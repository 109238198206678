import * as React from "react";
import {LinkList, ILink} from "../LinkList/LinkList";
import styles from "./Links.module.scss";
import {   
  BaseButton,
  Button,  
  IconButton,
  IIconProps,
  TextField,
} from "@fluentui/react";
import {
  IBaseProperties,
  ICommonProperties,
} from "../../../Models/IBaseProperties";
import { Constants } from "../../../Utility/Constants";
import { CustomLabel } from "../CustomLabel";
import { BriefTranslation } from "../../../Translations/Brief.Translation";

const onRenderCustomLabel = (
  commonProps: ICommonProperties,
  controlid: string,
  label: string,
  tooltip: string,
  required?: boolean,
  disableTooltip?: boolean
) => (p: any) => {
  return (
    <CustomLabel
      commonProps={commonProps}
      controlId={controlid}
      label={label}
      tooltiptext={tooltip}
      required={required}
      disableTooltip={disableTooltip}
    ></CustomLabel>
  );
};


export interface ILinksProps extends IBaseProperties {  
  onAdd(link:ILink): void;
  onRemove(links:ILink[]): void;
  items: ILink[];
}

export interface ILinksState {  
  newLink?: string;
  links: ILink[];
}

export class Links extends React.Component<ILinksProps, ILinksState> {    
  
  private readonly _translation: BriefTranslation;
  private addIcon: IIconProps = { iconName: 'Add', styles: {
    root: { color: '#0152E8', fontWeight: "bold", fontSize: "13px" }
  }};  

  constructor(props: ILinksProps) {
    super(props);
    
    this._translation = new BriefTranslation(props.commonProps.translation);
    
    this.state = {      
      links: props.items
    };
    
    this.bindEvents();
  }

  private bindEvents() {         
    this.onValidateUrl = this.onValidateUrl.bind(this);
    this.onChangeUrl = this.onChangeUrl.bind(this);
    this.onRemoveLink = this.onRemoveLink.bind(this);
    this.addLink = this.addLink.bind(this);    
  }

  private addLink (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | BaseButton | Button | HTMLSpanElement, MouseEvent>) : void
  {
    const {newLink} = this.state;

    if (newLink && this.isUrlValid(newLink))
    {
      const addedLink = this.toILink(newLink)

      this.updateLinksState();
      this.props.onAdd(addedLink);

      this.setState(
        {
          newLink: '',
        }
      );
    }
  }

  private toILink(url: string): ILink
  {
    const item: ILink = {url: url};
    return item;
  }

  private onRemoveLink (items: ILink[]) : void
  {    
    this.updateLinksState();

    this.props.onRemove(items);
  }

  private updateLinksState()
  {
    this.setState({
      newLink: '',
    })    
  }

  private isUrlValid(input: string): boolean
  {
    //const res = input.match(/(http(s)?:\/\/.)(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);    
    const res = input.match(/[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    return res === null ? false : true;
  }

  private onValidateUrl(userInput: string): string {
    if (userInput)
    {
      const res = this.isUrlValid(userInput);
      const retValue = res === null ? 'Invalid url' :  '';

      // if (res)
      // {
      //   this.setState(
      //     {
      //       newLink: userInput
      //     }
      //   );
      // }

      return retValue;
    }
    else
    {
      return '';
    }
  }
  
  private onChangeUrl (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) 
  {
    this.setState(
          {
            newLink: newValue
          }
        );
  }
  
  render() {       

    const disableAddButton = !(this.state.newLink ? this.isUrlValid(this.state.newLink) : false);

    const jsxUsefulLinks = (
      <TextField              
        id="links"
        styles={Constants.textFieldStyle}
        maxLength={1024}         
        value={this.state.newLink}
        onGetErrorMessage={this.onValidateUrl}
         onChange={this.onChangeUrl}
        // validateOnFocusIn
        // validateOnFocusOut
        onRenderLabel={onRenderCustomLabel(
          this.props.commonProps,
          "links",
          this._translation.links,
          "Useful links tooltip",
          false,
          true
        )}
      ></TextField>      
    );    

    return (
      <React.Fragment>
        <div >
          <div className={styles["links-container"]}>
            <div className={styles["links-urlinput"]}>
              {jsxUsefulLinks}
            </div>
            <div className={styles["links-addbutton"]}>
              <IconButton
                sizes={"13px"}
                width={"13px"}
                height={"13px"}
                iconProps={this.addIcon}
                aria-label="Add new link"
                ariaLabel="Add new link"
                onClick={this.addLink}
                disabled={disableAddButton}
              >              
              </IconButton>
            </div>
          </div>
          <div>
          <div> 
              <LinkList
                commonProps={this.props.commonProps}
                // items={this.state.links}
                items={this.props.items}
                onRemoveItems={this.onRemoveLink}
              >
              </LinkList>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
