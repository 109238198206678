import * as React from "react";
import {
  ToastProvider,
  AppearanceTypes,
  useToasts,
} from "react-toast-notifications";

import "./ToastComponent.scss";
import { Button, FontIcon, Modal, Spinner, SpinnerSize } from "@fluentui/react";
import { useEffect } from "react";
import { useState } from "react";
import {
  IToastNote,
  ToastNotificationPosition,
  ToastNotificationType,
} from "../../../Models/ToastNote";
import { IBaseProperties } from "../../../Models/IBaseProperties";

export interface IToastComponentProps extends IBaseProperties {}

export interface IToastComponentState {
  //   addToast: any;
  toastList: IToastNote[];
  position: ToastNotificationPosition;
  autoDelete: boolean;
  dismissTime: number;
  intervalId?: any;
}

export class ToastComponent extends React.Component<
  IToastComponentProps,
  IToastComponentState
> {
  constructor(props: IToastComponentProps) {
    super(props);

    this._deleteToast = this._deleteToast.bind(this);
    this._deleteLastToast = this._deleteLastToast.bind(this);
    this.state = {
      toastList: [],
      position: ToastNotificationPosition.TOPRIGHT,
      autoDelete: true,
      dismissTime: 5000,
    };
  }

  componentDidMount() {
    var intervalId = setInterval(this._deleteLastToast, this.state.dismissTime);
    this.setState({ intervalId: intervalId });

    // if (this.state.toastList.length == 0) {
    //   useEffect(() => {
    //     const interval = setInterval(() => {
    //       this._deleteLastToast();
    //     }, this.state.dismissTime);
    //     if (this.state.toastList.length == 0)
    //       return () => clearInterval(interval);
    //   }, []);
    // }
  }

  componentWillUnmount() {
    // use intervalId from the state to clear the interval
    clearInterval(this.state.intervalId);
  }

  public showMessage(
    title: string,
    description: string,
    type: ToastNotificationType,
    stayOpen?: boolean,
    position: ToastNotificationPosition = ToastNotificationPosition.TOPRIGHT
  ) {
    this.setState({
      toastList: this.state.toastList.concat({
        start: new Date(),
        title: title,
        description: description,
        type: type,
        id:
          this.state.toastList.length == 0
            ? 1
            : Math.max(...this.state.toastList.map((x) => x.id)) + 1,
        stayOpen: stayOpen,
      }),
      position: position,
    });
  }

  public showMessages(
    messages: {
      title: string;
      description: string;
      type: ToastNotificationType;
      stayOpen?: boolean;
    }[]
  ) {
    this.setState({
      toastList: this.state.toastList.concat(
        messages.map((x) => {
          return {
            title: x.title,
            description: x.description,
            type: x.type,
            id: Math.max(...this.state.toastList.map((x) => x.id)) + 1,
            stayOpen: x.stayOpen,
            start: new Date(),
          };
        })
      ),
    });
  }

  private _deleteToast(id: number) {
    this.setState({
      toastList: this.state.toastList.filter((x) => x.id != id),
    });
  }

  private _deleteLastToast() {
    var list = this.state.toastList;
    if (list.length > 0) {
      var lastToast = list[0];
      var t = new Date();
      t.setSeconds(t.getSeconds() - 3);
      if (lastToast.start <= t) {
        list.shift();
        this.setState({
          toastList: list,
        });
      }
    }
  }

  private _mapTypeToIcon(type: ToastNotificationType): string | undefined {
    switch (type) {
      case ToastNotificationType.SUCCESS:
        return "StatusCircleCheckmark";
        break;
      case ToastNotificationType.WARNING:
        return "StatusCircleExclamation";
        break;
      case ToastNotificationType.ERROR:
        return "StatusCircleExclamation";
        break;
      case ToastNotificationType.INFO:
        return "StatusCircleInfo";
        break;
    }
  }

  render() {
    return (
      <div className={`notification-container ` + this.state.position}>
        {this.state.toastList.map((toast: IToastNote, i) => (
          <div
            key={i}
            className={
              `notification toast ` +
              // this.state.position +
              " " +
              toast.type?.toString()
            }
          >
            <button
              onClick={() => this._deleteToast(toast.id)}
              aria-label="Close"
            >
              <FontIcon iconName="StatusCircleErrorX"></FontIcon>
            </button>
            <div className="notification-image">
              <FontIcon
                iconName={this._mapTypeToIcon(toast.type)}
                className={this._mapTypeToIcon(toast.type)}
              ></FontIcon>
              <div></div>
            </div>
            <div>
              <p className="notification-title">{toast.title}</p>
              <p className="notification-message">{toast.description}</p>
            </div>
          </div>
        ))}
      </div>
    );
  }
}
