import * as React from "react";

import styles from "./PanelBriefDetail.module.scss";
import {
  BaseButton,
  Button,
  CommandBarButton,
  ContextualMenu,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IContextualMenuItem,
  IContextualMenuProps,
  IDialogContentProps,
  IModalProps,
  Panel,
  PanelType,
  PrimaryButton,
} from "@fluentui/react";
import { IBaseProperties } from "../../../../Models/IBaseProperties";
import { BriefDetail } from "../BriefDetail/BriefDetail";
import { BriefTranslation } from "../../../../Translations/Brief.Translation";
import { NewBrief } from "../NewBrief/NewBrief";
import { IBriefDetail } from "../../../../Models/Brief";
import { SyntheticEvent } from "react";
import { IBriefClient } from "../../../../Clients/IBriefClient";
import {
  ToastNotificationPosition,
  ToastNotificationType,
} from "../../../../Models/ToastNote";
import { LoaderComponent } from "../../../Shared/LoaderComponent/LoaderComponent";
import { PanelBriefHeaderComponent } from "../../../Shared/PanelBriefHeaderComponent/PanelBriefHeaderComponent";

export interface IPanelBriefDetailProps extends IBaseProperties {
  visible: boolean;
  id?: number;
  showDeleteButton: boolean;
  title?: string;
  onDismiss(): void;
  onSave(brief: IBriefDetail): void;
  onChangeStatus(id: number): void;
  onDelete(id: number): void;
  onCancel(): void;
}
export interface IPanelBriefDetailState {
  isLoading: boolean;
  activityContextualMenuProps?: IContextualMenuProps;
  showDetail: boolean;
  showEdit: boolean;
  title?: string;
  showDialog: boolean;
}

export class PanelBriefDetail extends React.Component<
  IPanelBriefDetailProps,
  IPanelBriefDetailState
> {
  private readonly _translation: BriefTranslation;
  private readonly _briefClient: IBriefClient;
  private confirmDialogProps: IModalProps = { isBlocking: true };

  constructor(props: IPanelBriefDetailProps) {
    super(props);

    this._translation = new BriefTranslation(props.commonProps.translation);
    this._briefClient = props.commonProps.clientCreator.createBriefClient();

    this.bindEvents();

    this.initializeState(props);
  }

  private initializeState(props: IPanelBriefDetailProps) {
    this.state = {
      isLoading: false,
      showDetail: true,
      showEdit: false,
      title: props.title,
      showDialog: false,
    };
  }

  private bindEvents() {
    this.onEditRequest = this.onEditRequest.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onDismiss = this.onDismiss.bind(this);
    this.onClickMenuDelete = this.onClickMenuDelete.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
    this.onDownloadAsPdf = this.onDownloadAsPdf.bind(this);
    this.showToastAttachment = this.showToastAttachment.bind(this);
    this.downloadAsPdf = this.downloadAsPdf.bind(this);
  }

  private buildFile(blob: Blob) {
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "brief_as_pdf.pdf";
    link.click();
  }

  private onDownloadAsPdf(id: number): void {
    this.showToastAttachment();
    this.setState({}, async () => {
      try {
        const fileContent = await this.downloadAsPdf(id, "english");
        if (fileContent) {
          this.buildFile(fileContent);
        }
      } catch (err) {
      } finally {
      }
    });
  }

  private async downloadAsPdf(
    id: number,
    language: string
  ): Promise<Blob | undefined> {
    const file = await this._briefClient.downloadBriefAsPdfAsync(id, "english");
    return file;
  }

  private confirmDialogContentProps(): IDialogContentProps {
    return {
      type: DialogType.normal,
      title: "Delete confirmation",
      subText: "Confirm?",
    };
  }

  private onClickDelete(
    event: React.MouseEvent<
      | HTMLAnchorElement
      | HTMLButtonElement
      | HTMLDivElement
      | BaseButton
      | Button
      | HTMLSpanElement,
      MouseEvent
    >
  ): void {
    const { id } = this.props;
    if (id) {
      this.setState(
        {
          isLoading: true,
        },
        () => {
          this.deleteBrief(id);
        }
      );
    } else {
      this.setState({
        showDialog: false,
      });
    }
  }

  private onClickShare(): boolean {
    const el = document.createElement("input");
    const { id } = this.props;
    document.body.appendChild(el);

    if (el && id) {
      const url =
        document.location.protocol +
        "//" +
        document.location.host +
        document.location.pathname;
      el.value = url + "?page=MyBrief&IdBrief=" + id.toString();
      el.select();
      document.execCommand("copy");

      this.props.commonProps.toastComponent?.showMessage(
        this._translation.ok,
        this._translation.success,
        ToastNotificationType.SUCCESS,
        false,
        ToastNotificationPosition.TOPCENTER
      );
    }

    document.body.removeChild(el);

    return true;
  }

  private onClickMenuDelete() {
    this.setState({
      showDialog: true,
    });

    return true;
  }

  private async deleteBrief(id: number): Promise<void> {
    try {
      const value = await this._briefClient.deleteBrief(id);

      if (value) {
        this.props.commonProps.toastComponent?.showMessage(
          this._translation.info,
          this._translation.deleteBriefSuccess,
          ToastNotificationType.INFO
        );
        this.props.onDelete(this.props.id ? this.props.id : -1);
      } else {
        this.props.commonProps.toastComponent?.showMessage(
          this._translation.error,
          this._translation.deleteBriefError,
          ToastNotificationType.ERROR
        );
      }
    } finally {
      this.setState({
        isLoading: false,
        showDialog: false,
      });
    }
  }

  private get _menuItems(): IContextualMenuItem[] {
    return [
      {
        key: "delete",
        text: this._translation.delete,
        disabled: !this.props.showDeleteButton,
        onClick: () => {
          return this.onClickMenuDelete();
        },
      },
      {
        key: "download",
        text: this._translation.download,
        onClick: () => this.onDownloadAsPdf(this.props.id ? this.props.id : -1),
      },
    ];
  }

  private showToastAttachment() {
    this.props.commonProps.toastComponent?.showMessage(
      this._translation.info,
      this._translation.downloadAttachment,
      ToastNotificationType.INFO
    );
  }

  private get _menuContextual(): IContextualMenuProps {
    return { items: this._menuItems };
  }

  private onDismiss(ev?: SyntheticEvent<HTMLElement, Event> | undefined): void {
    if (ev) {
      ev.preventDefault();
    }

    this.setState({
      showEdit: false,
      showDetail: true,
    });

    this.props.onDismiss();
  }

  private onSave(brief: IBriefDetail): void {
    this.setState({
      showDetail: true,
      showEdit: false,
      title: brief.title,
    });

    this.props.onSave(brief);
  }

  private onEditRequest(idBrief: number): void {
    this.setState({
      showDetail: false,
      showEdit: true,
    });
  }

  private _onRenderActivityDetailsHeader(ev: any, obj: any, title: string) {
    return (
      <PanelBriefHeaderComponent
        commonProps={this.props.commonProps}
        idBrief={this.props.id}
        titleBrief={title}
        // item={this.state.item}
        showContextMenu={true}
        showStatus={false}
        showDeleteButton={this.props.showDeleteButton}
        deleteBrief={this.onClickMenuDelete}
        downloadAsPdf={this.onDownloadAsPdf}
      ></PanelBriefHeaderComponent>
    );

    // return this.renderHeader(title);
  }

  private renderHeader(title: string): JSX.Element {
    return (
      <div className={styles["panel-header"]}>
        <div className={styles["panel-header-title"]}>
          <span>{title}</span>
        </div>
        <div className={styles["panel-header-menu"]}>
          <CommandBarButton
            role="menuitem"
            aria-label="More items"
            // styles={onRenderOverflowButtonStyles}
            menuIconProps={{ iconName: "MoreVertical" }}
            menuProps={this._menuContextual}
            // onClick={this.onClickMenuItem}
          />
        </div>
      </div>
    );
  }

  public render() {
    const { id, visible, onDismiss, title } = this.props;
    const { showDetail, showEdit, title: sTitle } = this.state;

    const nTitle = sTitle ? sTitle : title;

    const jsxConfirmDialog = this.state.showDialog && (
      <Dialog
        hidden={!this.state.showDialog}
        onDismiss={() => this.setState({ showDialog: false })}
        modalProps={this.confirmDialogProps}
        dialogContentProps={this.confirmDialogContentProps()}
      >
        <DialogFooter>
          <PrimaryButton
            onClick={this.onClickDelete}
            text={this._translation.confirm}
          />
          <DefaultButton
            onClick={() => {
              this.setState({ showDialog: false });
            }}
            text={this._translation.cancel}
          />
        </DialogFooter>
      </Dialog>
    );

    return (
      <>
        <LoaderComponent
          commonProps={this.props.commonProps}
          isLoading={this.state.isLoading}
          label={this._translation.waiting}
        />
        {visible && id && nTitle && (
          <Panel
            onRenderHeader={(ev: any, obj: any) => {
              return this._onRenderActivityDetailsHeader(ev, obj, nTitle);
            }}
            // headerText={title}
            isOpen={visible}
            onDismiss={this.onDismiss}
            customWidth={"75vw"}
            className={styles["panel-container"]}
            headerClassName={styles["panel-header-title"]}
            // scrollableContentClassName={styles["panel-container-scrollable"]}
            type={PanelType.custom}
            closeButtonAriaLabel={this._translation.close}
            isHiddenOnDismiss={false}
            isLightDismiss={false}
            // hasCloseButton={false}
          >
            <hr className={styles.brief_details_separator} />
            <div className={styles["brief-detail-container"]}>
              {jsxConfirmDialog}
              {showDetail && (
                <BriefDetail
                  onNavigateAfterSend={() => undefined}
                  commonProps={this.props.commonProps}
                  idBrief={id}
                  onEditRequest={this.onEditRequest}
                  enableScroll={true}
                  showTitleRow={false}
                  // onChageStatus={(this.props.onChangeStatus)}
                  onChageStatus={() => {}}
                  showHistory={true}
                  onCancel={() => {
                    this.props.onCancel();
                  }}
                  hideNavigationAfterSend={true}
                />
              )}
              {showEdit && (
                <NewBrief
                  commonProps={this.props.commonProps}
                  idBrief={id}
                  onSave={this.onSave}
                  enableScroll={true}
                  onDismiss={() => {}}
                  onCancel={this.props.onCancel}
                />
              )}
            </div>
          </Panel>
        )}
      </>
    );
  }
}
