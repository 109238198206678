
import { ActivityStatusEnum } from './Activity';
import { User } from './User';

export interface ActivityHistory {
    id: number;
    actionDate: Date;    
    description?: ActivityHistoryDescription;
    type?: ActivityHistoryType;
    user: User;
}

export enum ActivityHistoryType {
    StatusChange = 0
}

export interface ActivityHistoryDescription extends ActivityHistoryStatusChange {

}

export interface ActivityHistoryStatusChange {
    previousState?: ActivityStatusEnum,
    currentState?: ActivityStatusEnum
}