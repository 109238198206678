import * as React from "react";
import styles from "./CounterComponent.module.scss";
import {
  IBaseProperties,
  IRoute,
  Page,
} from "../../../../Models/IBaseProperties";
import { LoaderComponent } from "../../../Shared/LoaderComponent/LoaderComponent";
import { DashboardTranslation } from "../../../../Translations/Dashboard.Translation";
import {
  ActivityStatusEnum,
  IActivitiesCount,
} from "../../../../Models/Activity";
import { Utility } from "../../../../Utility/Utility";
import { BriefStatusEnum, IBriefsCount } from "../../../../Models/Brief";

export interface ICounterComponentProps extends IBaseProperties {
  // activityCounters: IActivitiesCount[];
  briefCounters: IBriefsCount[];
}
export interface ICounterComponentState {
  isLoading: boolean;
  briefCounters: {
    FirstColumn: {
      Link: string;
      Count: number;
      DisplayName: string;
      StateName: string;
    };
    SecondColumn?: {
      Link: string;
      Count: number;
      DisplayName: string;
      StateName: string;
    };
  }[];
}

export class CounterComponent extends React.Component<
  ICounterComponentProps,
  ICounterComponentState
> {
  private readonly _translation: DashboardTranslation;

  constructor(props: ICounterComponentProps) {
    super(props);

    this._translation = new DashboardTranslation(
      this.props.commonProps.translation
    );

    this.state = {
      isLoading: false,
      briefCounters: [],
    };
  }

  componentDidMount() {
    this.setState({
      briefCounters: [
        {
          FirstColumn: {
            Link: "?page=Activities&status=draft&type=2",
            Count: Utility.GetCounterFromBriefState(
              BriefStatusEnum.Draft,
              this.props.briefCounters
            ).count,
            DisplayName: "Draft",
            StateName: "Draft",
          },
          SecondColumn: {
            Link: "?page=Activities&status=in progress&type=2",
            Count:
              Utility.GetCounterFromBriefState(
                BriefStatusEnum.Pending,
                this.props.briefCounters
              ).count +
              Utility.GetCounterFromBriefState(
                BriefStatusEnum.Rework,
                this.props.briefCounters
              ).count,
            DisplayName: "Pending / Rework",
            StateName: "PendingRework",
          },
        },
        {
          FirstColumn: {
            Link: "?page=Activities&status=approved&type=2",
            Count: Utility.GetCounterFromBriefState(
              BriefStatusEnum.Accepted,
              this.props.briefCounters
            ).count,
            DisplayName: "Accepted",
            StateName: "Accepted",
          },
          SecondColumn: {
            Link: "?page=Activities&status=rework&type=2",
            Count: Utility.GetCounterFromBriefState(
              BriefStatusEnum.Rejected,
              this.props.briefCounters
            ).count,
            DisplayName: "Rejected",
            StateName: "Rejected",
          },
        },
      ],
    });
  }

  render() {
    return (
      <div className={styles.CounterComponent}>
        <div className={styles.request_status}>
          {this._translation.headerCounter}
        </div>
        <LoaderComponent
          commonProps={this.props.commonProps}
          isLoading={this.state.isLoading}
        />
        <div className={styles.box_counter_body}>
          {this.state.briefCounters.length > 0 &&
            this.state.briefCounters.map((currentRow, i) => {
              return (
                <div
                  key={i}
                  className={
                    styles.box_counter_row +
                    " ms-Grid-col ms-xl12 ms-lg12 ms-md12 ms-sm12"
                  }
                >
                  <div
                    className={
                      styles.box_counter_col +
                      " ms-Grid-col ms-xl6 ms-lg6 ms-md12 ms-sm12"
                    }
                  >
                    <div
                      className={styles.box_counter}
                      onClick={() => {
                        const route: IRoute = {
                          page: Page.MyBrief,
                          additionalInfo: {
                            keysValues: {
                              id: undefined,
                              type: undefined,
                              subtype: undefined,
                              title: undefined,
                              showMyActivities: undefined
                            },
                            optionalValues: {
                              status: currentRow.FirstColumn.StateName,
                            },
                          },
                        };
                        this.props.commonProps.onRoute?.next(route);

                        // window.location.href =
                        //   "/" + currentRow.FirstColumn.Link;
                      }}
                    >
                      <div
                        // href={currentRow.FirstColumn.Link}
                        className={styles.link_stat}
                      >
                        <div className={styles.title_stat}>
                          {currentRow.FirstColumn.Count}
                        </div>
                        <div className={styles.state_name} id="state-name">
                          {currentRow.FirstColumn.DisplayName}
                        </div>
                      </div>
                    </div>
                  </div>
                  {currentRow.SecondColumn && (
                    <div
                      className={
                        styles.box_counter_col +
                        " ms-Grid-col ms-xl6 ms-lg6 ms-md12 ms-sm12"
                      }
                    >
                      <div
                        className={styles.box_counter}
                        onClick={() => {
                          const route: IRoute = {
                            page: Page.MyBrief,
                            additionalInfo: {
                              keysValues: {
                                id: undefined,
                                type: undefined,
                                subtype: undefined,
                                title: undefined,
                                showMyActivities: undefined
                              },
                              optionalValues: {
                                status: currentRow.SecondColumn?.StateName,
                              },
                            },
                          };
                          this.props.commonProps.onRoute?.next(route);

                          // window.location.href =
                          //   "/" + currentRow.SecondColumn?.Link;
                        }}
                      >
                        <div
                          // href={currentRow.SecondColumn.Link}
                          className={styles.link_stat}
                        >
                          <div className={styles.title_stat}>
                            {currentRow.SecondColumn.Count}
                          </div>
                          <div className={styles.state_name} id="state-name">
                            {currentRow.SecondColumn.DisplayName}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}

          {/* {this.state.briefCounters.length == 0 && (
            <div
              className={
                styles.box_counter_row +
                " ms-Grid-col ms-xl12 ms-lg12 ms-md12 ms-sm12"
              }
            >
              <div
                className={
                  styles.box_counter_col +
                  " ms-Grid-col ms-xl6 ms-lg6 ms-md6 ms-sm6"
                }
              >
                <div className={styles.box_counter}>
                  <label className={styles.link_stat}>
                    <div className={styles.title_stat}>
                      {
                        Utility.GetCounterFromBriefState(
                          BriefStatusEnum.Draft,
                          this.props.briefCounters
                        ).count
                      }
                    </div>
                    <div className={styles.state_name}>Draft</div>
                  </label>
                </div>
              </div>

              <div
                className={
                  styles.box_counter_col +
                  " ms-Grid-col ms-xl6 ms-lg6 ms-md6 ms-sm6"
                }
              >
                <div className={styles.box_counter}>
                  <label className={styles.link_stat}>
                    <div className={styles.title_stat}>
                      {
                        Utility.GetCounterFromBriefState(
                          BriefStatusEnum.Pending,
                          this.props.briefCounters
                        ).count
                      }
                    </div>
                    <div className={styles.state_name}>Planned</div>
                  </label>
                </div>
              </div>
            </div>
          )} */}
        </div>
      </div>
    );
  }
}
