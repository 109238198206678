import * as React from "react";
import "./Navigation.css";
import Hamburger from "hamburger-react";
import {
  CommandBarButton,
  INavLink,
  IOverflowSetItemProps,
  IPanelStyles,
  IPivotStyles,
  OverflowSet,
  Panel,
  PanelType,
  Pivot,
  PivotItem,
  PivotLinkFormat,
  PivotLinkSize,
  PrimaryButton,
} from "@fluentui/react";
import { Security } from "../../../Utility/Security";
import { IBaseProperties } from "../../../Models/IBaseProperties";
import { apiConfig } from "../../../msal/MsalConfig";
import { Constants } from "../../../Utility/Constants";

const navLinkGroups: IOverflowSetItemProps[] = [
  {
    name: "Dashboard",
    key: "Dashboard",
  },
  // {
  //   name: 'My brief',
  //   key: 'My brief',
  // },
  {
    name: "Activities",
    key: "Activities",
  },
  {
    name: "Planner",
    key: "Planner",
  },
  {
    name: "Report",
    key: "Report",
  },
];

const onRenderOverflowButton = (
  overflowItems: any[] | undefined
): JSX.Element => {
  return (
    <CommandBarButton
      role="menuitem"
      title="More items"
      // styles={onRenderOverflowButtonStyles}
      // menuIconProps={{ iconName: 'More' }}
      // menuProps={{ items: overflowItems! }}
    />
  );
};

//const support = apiConfig.externalLinks.support;

export interface INavigationProps extends IBaseProperties {
  page: string;
  changeSelNavCallback: (item: string) => void;
}
export interface INavigationState {
  setLastHeader: any;
  lastHeader: any;
  isOpen: boolean;
}

export class Navigation extends React.Component<
  INavigationProps,
  INavigationState
> {
  private readonly panelStyles: Partial<IPanelStyles> = {
    main: {
      backgroundColor: "#0152E8",
      borderRadius: "0px !important",
      width: "190px !important",
    },
    closeButton: { color: "#ffffff" },
  };
  private _pivotStyles: Partial<IPivotStyles> = {
    linkIsSelected: { color: "red" },
  };

  constructor(props: INavigationProps) {
    super(props);
    this.state = {
      isOpen: false,
      setLastHeader: null,
      lastHeader: null,
    };

    this.menuPivotClick = this.menuPivotClick.bind(this);
    this.menuNavClick = this.menuNavClick.bind(this);
  }

  private navButton = (key: string): React.ReactElement => {
    return (
      <PrimaryButton
        role="menuitem"
        text={key}
        onClick={() => this.props.changeSelNavCallback(key)}
      />
    );
  };

  private onRenderItem = (item: IOverflowSetItemProps): JSX.Element => {
    return (
      <PrimaryButton
        role="menuitem"
        key={item.key}
        text={item.name}
        aria-label={item.name}
        onClick={() => {
          this.props.changeSelNavCallback(item.key);
          this.setState({ isOpen: false });
        }}
      />
    );
  };

  private menuPivotClick(item?: PivotItem | undefined, ev?: any): void {
    // if (item && item.props.itemKey && item.props.itemKey == "Support") {
    //   window.open(support);
    // } else
    if (item && item.props.itemKey) {
      this.props.changeSelNavCallback(item.props.itemKey);
    }
  }

  private menuNavClick(ev?: any, item?: INavLink | undefined): void {
    if (item && item.key) {
      this.props.changeSelNavCallback(item.key);
    }
    ev?.stopPropagation();
  }

  renderNavMenu(): React.ReactElement {
    return (
      <Panel
        isOpen={this.state.isOpen}
        type={PanelType.custom}
        // customWidth={"190px"}
        isBlocking={false}
        onDismiss={() => this.setState({ isOpen: false })}
        styles={this.panelStyles}      
      >
        <div className="navControl">
          <OverflowSet
            className="navControl"
            aria-label="Media planning menu"
            role="menubar"
            // onLinkClick={this.menuNavClick}
            items={navLinkGroups}
            vertical
            onRenderItem={this.onRenderItem}
            onRenderOverflowButton={onRenderOverflowButton}
            // styles={navStyles}
          />
        </div>
      </Panel>
      //
      //   <ul>
      //     {this.navButton("Dashboard")}
      //     {this.navButton("My brief")}
      //     {this.navButton("Activities")}
      //     {this.navButton("Planner")}
      //     {this.navButton("Report")}
      //   </ul>
    );
  }

  private renderHamburgerMenu(): React.ReactElement {
    return (
      <>
        <Hamburger
          toggled={this.state.isOpen}
          toggle={() => {
            this.setState({ isOpen: !this.state.isOpen });
          }}
          direction="left"
          rounded
          label="Show menù"
        ></Hamburger>

        {this.state.isOpen && this.renderNavMenu()}
      </>
    );
  }

  render() {
    const isGM = this.isGMUser();
    return (
      <React.Fragment>
        {this.renderPivotMenu(isGM)}

        {/* {this.renderHamburgerMenu()} */}
      </React.Fragment>
    );
  }

  private renderPivotMenu(isGM: boolean) {
    return (
      <Pivot
        className={
          "navigation pivotControl " +
          (this.props.page !== Constants.PageDashboard ? "noMargin" : "")
        }
        linkSize={PivotLinkSize.normal}
        linkFormat={PivotLinkFormat.links}
        selectedKey={this.props.page}
        // selectedKey="newBrief"
        onLinkClick={this.menuPivotClick}
        styles={this._pivotStyles}
      >
        {/* <PivotItem
        // headerText={this.props.commonProps.translation("header.menu1")}
        itemKey="newBrief"
        // headerButtonProps={{
        //   "data-order": 0,
        //   "data-title": "",
        // }}
      ></PivotItem> */}
        <PivotItem
          headerText={this.props.commonProps.translation("header.menu1")}
          itemKey="Dashboard"
          headerButtonProps={{
            "data-order": 1,
            "data-title": "Dashboard",
          }}
          aria-label="Dashboard"
          tabIndex={0}
        ></PivotItem>
        {/* <PivotItem
        headerText={this.props.commonProps.translation("header.menu2")}
        itemKey="MyBrief"
        headerButtonProps={{
          "data-order": 2,
          "data-title": "MyBrief",
        }}
          aria-label="MyBrief"
          tabIndex={6}
      ></PivotItem> */}
        <PivotItem
          headerText={this.props.commonProps.translation("header.menu3")}
          itemKey="Activities"
          headerButtonProps={{
            "data-order": 3,
            "data-title": "Activities",
          }}
          aria-label="Activities"
          tabIndex={1}
        ></PivotItem>
        <PivotItem
          headerText={this.props.commonProps.translation("header.menu6")}
          itemKey="Attachments"
          headerButtonProps={{
            "data-order": 4,
            "data-title": "Attachments",
          }}
          aria-label="Attachments"
          tabIndex={2}
        ></PivotItem>
        {isGM && (
          <PivotItem
            headerText={this.props.commonProps.translation("header.menu4")}
            itemKey="Planner"
            headerButtonProps={{
              "data-order": 5,
              "data-title": "Planner",
            }}
            tabIndex={3}
            aria-label="Activities"
          ></PivotItem>
        )}
        {isGM && (
          <PivotItem
            headerText={this.props.commonProps.translation("header.menu5")}
            itemKey="Report"
            headerButtonProps={{
              "data-order": 6,
              "data-title": "Report",
            }}
            tabIndex={4}
            aria-label="Report"
          ></PivotItem>
        )}
        <PivotItem
          headerText={this.props.commonProps.translation("header.menu7")}
          itemKey="Support"
          headerButtonProps={{
            "data-order": 7,
            "data-title": "Support",
          }}
          aria-label="Support"
          tabIndex={5}
        ></PivotItem>
      </Pivot>
    );
  }

  private isGMUser() {
    return (
      Security.isGlobalMediaAdvanced ||
      Security.isGlobalMediaSpecialist ||
      Security.isAuditor ||
      Security.isAdmin
    );
  }
}
