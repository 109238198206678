import * as React from "react";
import "./PlannerActivityDetails.scss";
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IDialogContentProps,
  IDropdownOption,
  Image,
  ImageFit,
  IModalProps,
  Panel,
  PanelType,
  PrimaryButton,
} from "@fluentui/react";
import { MicroActivityViewComponent } from "../../../Shared/MicroActivityViewComponent/MicroActivityViewComponent";
import { MacroActivityViewComponent } from "../../../Shared/MacroActivityViewComponent/MacroActivityViewComponent";
import { ActivitySidebarComponent } from "../../../Shared/ActivitySidebarComponent/ActivitySidebarComponent";
import { IActivityAllAttributes, IMicro } from "../../../../Models/Activity";
import { PanelActivityHeaderComponent } from "../../../Shared/PanelActivityHeaderComponent/PanelActivityHeaderComponent";
import {
  IBaseProperties,
  IRoute,
  Page,
} from "../../../../Models/IBaseProperties";
import { LoaderComponent } from "../../../Shared/LoaderComponent/LoaderComponent";
import { ActivityDetails } from "../../Activities/ListActivities/ActivityDetail/ActivityDetails";
import { IActivityClient } from "../../../../Clients/IActivityClient";
import { ToastNotificationType } from "../../../../Models/ToastNote";
import { ActivityTranslation } from "../../../../Translations/Activity.Translation";
import { ICalendar } from "../../../../Models/ICalendar";
import { ICategory } from "../../../../Models/ICategory";

export interface IPlannerActivityDetailsProps extends IBaseProperties {
  idActivity: string;
  activityDetailsVisible: boolean;
  activityDetailsOpen: IActivityAllAttributes;
  calendars: ICalendar[];
  categories: IDropdownOption[];
  responsibilities: IDropdownOption[];
  sensibilities: IDropdownOption[];
  hideActivityDetails: () => void;
  reloadActivities: (hideActivity?: boolean) => Promise<void>;
}
export interface IPlannerActivityDetailsState {
  viewDescription: boolean;
  viewParent: boolean;
  viewLinkedMicro: boolean;
  linkedMicro?: IMicro;
  isLoading: boolean;

  manageActivity: boolean;
  showDeleteDialog: boolean;
  itemToDelete?: number;
  itemToDeleteIsMacro?: boolean;
  deleteAction?: (itemId: number, isMacro: boolean) => void;
}

export class PlannerActivityDetails extends React.Component<
  IPlannerActivityDetailsProps,
  IPlannerActivityDetailsState
> {
  private readonly _translation: ActivityTranslation;
  private readonly _activityClient: IActivityClient;
  private confirmDeleteDialogProps: IModalProps = { isBlocking: true };

  constructor(props: IPlannerActivityDetailsProps) {
    super(props);

    this._translation = new ActivityTranslation(
      this.props.commonProps.translation
    );
    this._activityClient = this.props.commonProps.clientCreator.createActivityClient();

    this._onRenderDetailsHeader = this._onRenderDetailsHeader.bind(this);
    this._viewParent = this._viewParent.bind(this);
    this._viewLinkedMicro = this._viewLinkedMicro.bind(this);
    this._viewDescription = this._viewDescription.bind(this);

    this._onRenderParentDetailsHeader = this._onRenderParentDetailsHeader.bind(
      this
    );
    this._onRenderLinkedMicroDetailsHeader = this._onRenderLinkedMicroDetailsHeader.bind(
      this
    );

    this._showDeleteDialog = this._showDeleteDialog.bind(this);
    this._deleteActivity = this._deleteActivity.bind(this);

    this.state = {
      viewDescription: false,
      viewParent: false,
      viewLinkedMicro: false,
      isLoading: false,
      showDeleteDialog: false,
      manageActivity: false,
    };
  }

  private _showDeleteDialog(itemId: number, isMacro: boolean): void {
    this.setState({
      itemToDelete: itemId,
      showDeleteDialog: true,
      itemToDeleteIsMacro: isMacro,
      deleteAction: this._deleteActivity,
    });
  }

  private async _deleteActivity(
    itemId: number,
    isMacro: boolean
  ): Promise<boolean> {
    this.setState({ isLoading: true });

    var result = await this._activityClient.deleteActivity(
      itemId.toString(),
      isMacro
    );
    if (!result) {
      this.props.commonProps.toastComponent?.showMessage(
        this._translation.error,
        this._translation.deleteActivityError,
        ToastNotificationType.ERROR
      );
      this.setState({
        showDeleteDialog: false,
        itemToDelete: undefined,
        itemToDeleteIsMacro: undefined,
        deleteAction: undefined,
      });
    } else {
      this.props.commonProps.toastComponent?.showMessage(
        this._translation.ok,
        this._translation.success,
        ToastNotificationType.SUCCESS
      );
      this.setState(
        {
          showDeleteDialog: false,
          itemToDelete: undefined,
          itemToDeleteIsMacro: undefined,
          deleteAction: undefined,
          manageActivity: false,
          isLoading: false,
        },
        () => {
          this.props.reloadActivities(true);
        }
      );
    }

    return true;
  }

  private _confirmDeleteDialogContentProps(): IDialogContentProps {
    return {
      type: DialogType.normal,
      title: this._translation.warningDeleteTitle,
      subText: this._translation.warningDeleteSubTitle,
    };
  }

  render() {
    return (
      <React.Fragment>
        <LoaderComponent
          commonProps={this.props.commonProps}
          isLoading={this.state.isLoading}
        />
        <Dialog
          hidden={!this.state.showDeleteDialog}
          onDismiss={() => this.setState({ showDeleteDialog: false })}
          modalProps={this.confirmDeleteDialogProps}
          dialogContentProps={this._confirmDeleteDialogContentProps()}
        >
          <DialogFooter>
            <PrimaryButton
              onClick={() => {
                if (
                  this.state.deleteAction &&
                  this.state.itemToDelete &&
                  this.state.itemToDeleteIsMacro != undefined
                )
                  this.state.deleteAction(
                    this.state.itemToDelete,
                    this.state.itemToDeleteIsMacro
                  );
              }}
              text={this._translation.confirm}
            />
            <DefaultButton
              onClick={() => {
                this.setState({ showDeleteDialog: false });
              }}
              text={this._translation.cancel}
            />
          </DialogFooter>
        </Dialog>

        {this.state.manageActivity && this.props.idActivity && (
          <ActivityDetails
            commonProps={this.props.commonProps}
            calendars={this.props.calendars}
            categories={this.props.categories}
            responsibilities={this.props.responsibilities}
            sensibilities={this.props.sensibilities}
            itemFromList={
              {
                ...this.props.activityDetailsOpen,
                isMacro: !!this.props.activityDetailsOpen.isMacro
                  ? this.props.activityDetailsOpen.isMacro
                  : false,
              } as IActivityAllAttributes
            }
            reloadActivities={() => { }}
            deleteActivity={this._showDeleteDialog}
            onDismiss={(hideActivity?: boolean) => {
              this.props.reloadActivities(hideActivity).then(() => {
                this.setState({ manageActivity: false });
              });
            }}
          />
        )}
        {!this.state.manageActivity && this.props.idActivity && (
          <Panel
            isOpen={this.props.activityDetailsVisible}
            onRenderHeader={this._onRenderDetailsHeader}
            onDismiss={() => {
              this.props.hideActivityDetails();
            }}
            type={PanelType.custom}
            customWidth={window.innerWidth > 767 ? "75vw" : "100vw"}
            closeButtonAriaLabel="Close"
            headerText={this.props.activityDetailsOpen?.name}
            className="pa-details"
          >
            <div className="pa-details-separator"></div>
            <div className="pa-details-body row">
              <div className="pa-details-center ms-Grid-col ms-lg9 ms-md12">
                {!this.props.activityDetailsOpen.isMacro && (
                  <MicroActivityViewComponent
                    calendars={this.props.calendars}
                    item={this.props.activityDetailsOpen}
                    commonProps={this.props.commonProps}
                  />
                )}
                {this.props.activityDetailsOpen.isMacro && (
                  <MacroActivityViewComponent
                    calendars={this.props.calendars}
                    item={this.props.activityDetailsOpen}
                    commonProps={this.props.commonProps}
                    edit={false}
                  />
                )}
              </div>
              <div className="pa-details-right ms-Grid-col ms-lg3 ms-md12">
                <ActivitySidebarComponent
                  item={this.props.activityDetailsOpen}
                  calendars={this.props.calendars}
                  editable={false}
                  commonProps={this.props.commonProps}
                  viewDetails={false}
                  viewDescription={this._viewDescription}
                  viewLinkedMicro={this._viewLinkedMicro}
                  viewParent={this._viewParent}
                />
              </div>
            </div>

            <Panel
              // data={null}
              onRenderHeader={this._onRenderParentDetailsHeader}
              isOpen={this.state.viewParent ? true : false}
              isHiddenOnDismiss={false}
              isLightDismiss={true}
              onDismiss={() => {
                this.setState({ viewParent: false });
              }}
              customWidth={window.innerWidth > 767 ? "75vw" : "100vw"}
              className={"nestedPanelActivityDetails ActivityDetailsComponent"}
              type={PanelType.custom}
              // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
              closeButtonAriaLabel="Close"
            >
              <hr className="activity-details-separator" />
              <MacroActivityViewComponent
                calendars={this.props.calendars}
                item={this.props.activityDetailsOpen?.parentMacro}
                commonProps={this.props.commonProps}
                edit={false}
              />
            </Panel>

            <Panel
              // data={null}
              onRenderHeader={this._onRenderLinkedMicroDetailsHeader}
              isOpen={this.state.viewLinkedMicro ? true : false}
              isHiddenOnDismiss={false}
              isLightDismiss={true}
              onDismiss={() => {
                this.setState({ viewLinkedMicro: false });
              }}
              customWidth={window.innerWidth > 767 ? "75vw" : "100vw"}
              className={"nestedPanelActivityDetails ActivityDetailsComponent"}
              type={PanelType.custom}
              // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
              closeButtonAriaLabel="Close"
            >
              <hr className="activity-details-separator" />
              <MicroActivityViewComponent
                calendars={this.props.calendars}
                itemId={this.state.linkedMicro?.id}
                commonProps={this.props.commonProps}
              />
            </Panel>

            <Panel
              // data={null}
              headerText="Description"
              isOpen={this.state.viewDescription ? true : false}
              onDismiss={() => {
                this.setState({ viewDescription: false });
              }}
              isHiddenOnDismiss={false}
              isLightDismiss={true}
              customWidth={window.innerWidth > 767 ? "75vw" : "100vw"}
              className={"nestedPanelActivityDetails ActivityDetailsComponent"}
              type={PanelType.custom}
              // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
              closeButtonAriaLabel="Close"
            >
              <hr className="activity-details-separator" />
              <div className="activity-details-body row">
                <div className="ms-Grid-col ms-sm12">
                  {this.props.activityDetailsOpen?.description}
                </div>
              </div>
            </Panel>
          </Panel>
        )}
      </React.Fragment>
    );
  }

  private _onRenderDetailsHeader(ev: any, obj: any) {
    return (
      <PanelActivityHeaderComponent
        item={this.props.activityDetailsOpen}
        isMacro={this.props.activityDetailsOpen?.isMacro}
        showContextMenu={true}
        showContextMenuViewMode={true}
        showStatus={false}
        showType={true}
        commonProps={this.props.commonProps}
        showManage={true}
        manageChange={() => {
          if (this.props.activityDetailsOpen?.id) {
            this.setState({ manageActivity: true });
            // window.scrollTo(0, 0);
            // const route: IRoute = {
            //   page: Page.Activities,
            //   additionalInfo: {
            //     keysValues: {
            //       id: this.props.activityDetailsOpen.id.toString(),
            //       type: this.props.activityDetailsOpen.isMacro ? "1" : "2",
            //       subtype: undefined,
            //       title: undefined,
            //     },
            //   },
            // };
            // this.props.commonProps.onRoute?.next(route);
          }
        }}
      ></PanelActivityHeaderComponent>
    );
  }

  private _onRenderParentDetailsHeader(ev: any, obj: any) {
    return (
      <PanelActivityHeaderComponent
        item={
          {
            ...this.props.activityDetailsOpen?.parentMacro,
            isMacro: true,
          } as IActivityAllAttributes
        }
        isMacro={true}
        showContextMenu={false}
        showStatus={false}
        showType={true}
        commonProps={this.props.commonProps}
        showManage={false}
      ></PanelActivityHeaderComponent>
    );
  }

  private _onRenderLinkedMicroDetailsHeader(ev: any, obj: any) {
    return (
      <PanelActivityHeaderComponent
        item={
          {
            ...this.state.linkedMicro,
            isMacro: false,
          } as IActivityAllAttributes
        }
        isMacro={false}
        showContextMenu={false}
        showStatus={false}
        showType={true}
        commonProps={this.props.commonProps}
        showManage={false}
      ></PanelActivityHeaderComponent>
    );
  }

  private _alertClicked() {
    alert("Manage activity clicked");
  }

  private _viewDescription(ev: any): void {
    ev.preventDefault();
    this.setState({ viewDescription: true });
  }

  private _viewParent(ev: any): void {
    ev.preventDefault();
    this.setState({ viewParent: true });
  }

  private _viewLinkedMicro(ev: any, item: IMicro): void {
    ev.preventDefault();
    this.setState({ viewLinkedMicro: true, linkedMicro: item });
  }
}
