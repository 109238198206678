import * as React from "react";

import "bootstrap/dist/css/bootstrap.css";

import "@fluentui/react/dist/css/fabric.min.css";

import { initializeIcons } from "@uifabric/icons";

import { Utility } from "../../../../../../Utility/Utility";
import { Panel, PanelType } from "office-ui-fabric-react/lib/Panel";
import { PersonaComponent } from "../../../../../Shared/PersonaComponent/PersonaComponent";
import { NotesTranslation } from "../../../../../../Translations/Notes.Translation";

//import "./NoteComponent.scss";
import styles from "./NoteComponent.module.scss";

import {
  FontIcon,
  getTheme,
  IconButton,
  List,
  PersonaSize,
  TextField,
} from "@fluentui/react";

// import { TextField } from "@material-ui/core";

import { PersonaStyle, User } from "../../../../../../Models/User";
import {
  ActivityPermissions,
  IActivityAllAttributes,
} from "../../../../../../Models/Activity";
import { INote } from "../../../../../../Models/Note";
import { IBaseProperties } from "../../../../../../Models/IBaseProperties";
import { UserClient } from "../../../../../../Clients/UserClient";
import { NoteClient } from "../../../../../../Clients/NoteClient";
import { ActivityClient } from "../../../../../../Clients/ActivityClient";
import { Security } from "../../../../../../Utility/Security";
import { IActivityClient } from "../../../../../../Clients/IActivityClient";
import { INoteClient } from "../../../../../../Clients/INoteClient";
import { ToastNotificationType } from "../../../../../../Models/ToastNote";
import { LoaderComponent } from "../../../../../Shared/LoaderComponent/LoaderComponent";
import { Spinner } from "react-bootstrap";
import { SpinnerComponent } from "../../../../../Shared/SpinnerComponent/SpinnerComponent";
// import RichTextEditor, { EditorValue } from "react-rte";

export interface INoteComponentProps extends IBaseProperties {
  item?: IActivityAllAttributes;
  // notes: INote[];
}

export interface INoteComponentState {
  isLoading: boolean;
  viewActivityNotes: boolean;
  currentUser?: User;
  notes?: INote[];
  viewNotesCollapsed: boolean;
  textNote: string; //EditorValue;
  permissions: ActivityPermissions;
  reloadTime: number;
  intervalId?: any;
}

const theme = getTheme();

const iconButtonStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: "auto",
    marginTop: "4px",
    marginRight: "2px",
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

export class NoteComponent extends React.Component<
  INoteComponentProps,
  INoteComponentState
> {
  private readonly _translation: NotesTranslation;
  private readonly _activityClient: IActivityClient;
  private readonly _noteClient: INoteClient;

  constructor(props: INoteComponentProps) {
    super(props);

    initializeIcons();
    this._translation = new NotesTranslation(
      this.props.commonProps.translation
    );
    this._activityClient = this.props.commonProps.clientCreator.createActivityClient();
    this._noteClient = this.props.commonProps.clientCreator.createNoteClient();

    this._onRenderNote = this._onRenderNote.bind(this);
    this._addNote = this._addNote.bind(this);
    this._editNote = this._editNote.bind(this);
    this._deleteNote = this._deleteNote.bind(this);
    this._dismissNoteUpdate = this._dismissNoteUpdate.bind(this);
    this._updateNote = this._updateNote.bind(this);
    this._tempUpdateNote = this._tempUpdateNote.bind(this);
    this._reloadNotes = this._reloadNotes.bind(this);

    this.state = {
      isLoading: false,
      viewActivityNotes: false,
      // currentUser: currentUser,
      viewNotesCollapsed: true,
      textNote: "", //RichTextEditor.createEmptyValue(),
      notes: [],
      permissions: this.props.item?.permissions
        ? this.props.item?.permissions
        : Utility.getDefaultActivityPermissions(),
      reloadTime: 10000,
    };
  }

  componentDidMount() {
    var intervalId = setInterval(() => {
      if (
        !this.state.notes ||
        this.state.notes.filter((x) => x.edit).length == 0
      )
        this._reloadNotes();
    }, this.state.reloadTime);
    this.setState({ intervalId: intervalId });

    this._reloadNotes();
  }

  componentWillUnmount() {
    // use intervalId from the state to clear the interval
    clearInterval(this.state.intervalId);
  }

  private _reloadNotes() {
    var user = Security.getCurrentUserInfo();

    if (this.props.item?.id) {
      this.setState({ isLoading: true });
      this._noteClient
        .getNotes(this.props.item?.id, this.props.item?.isMacro)
        .then((notes) => {
          if (notes == undefined) {
            this.props.commonProps.toastComponent?.showMessage(
              this._translation.error,
              this._translation.getNotesError,
              ToastNotificationType.ERROR
            );
            this.setState({
              isLoading: false,
              permissions: Utility.getDefaultActivityPermissions(),
            });
          } else {
            this._activityClient
              .getActivityPermissions(
                this.props.item?.id,
                this.props.item?.isMacro
              )
              .then((permissions) => {
                if (permissions) {
                  this.setState({
                    isLoading: false,
                    currentUser: user
                      ? {
                          displayName: user.displayName,
                          id: user.id,
                          userPrincipalName: user.username,
                          initials: user.initials,
                        }
                      : undefined,
                    //textNote: "", //RichTextEditor.createEmptyValue(),
                    notes: notes,
                    permissions: permissions,
                  });
                } else {
                  this.props.commonProps.toastComponent?.showMessage(
                    this._translation.error,
                    this._translation.getNotesError,
                    ToastNotificationType.ERROR
                  );
                  this.setState({
                    isLoading: false,
                    permissions: Utility.getDefaultActivityPermissions(),
                  });
                }
              });
          }
        });
    }
  }

  private async _deleteNote(idNote: number) {
    if (this.state.currentUser && this.props.item?.id) {
      // this.setState({ isLoading: true });
      var result = await this._noteClient.deleteNote(
        this.props.item.id.toString(),
        idNote.toString(),
        this.props.item.isMacro
      );
      if (!result) {
        this.props.commonProps.toastComponent?.showMessage(
          this._translation.error,
          this._translation.deleteNoteError,
          ToastNotificationType.ERROR
        );
      }

      this.setState({ notes: undefined }, () => {
        this._reloadNotes();
      });
    }
    this.setState({ notes: this.state.notes?.filter((x) => x.id != idNote) });
  }

  private _editNote(idNote: number) {
    var notes: INote[] = Object.assign([], this.state.notes);
    notes.filter((x) => x.id == idNote)[0].edit = true;

    this.setState({
      notes: notes,
    });
  }

  private _dismissNoteUpdate(idNote: number) {
    var notes: INote[] = Object.assign([], this.state.notes);
    notes.filter((x) => x.id == idNote)[0].edit = false;

    this.setState({
      notes: notes,
    });
  }

  private _tempUpdateNote(idNote: number, text: string) {
    //EditorValue) {
    var notes: INote[] = Object.assign([], this.state.notes);
    notes.filter((x) => x.id == idNote)[0].text = text;

    this.setState({
      notes: notes,
    });
  }

  private async _updateNote(idNote: number, text: string) {
    //EditorValue) {
    if (this.state.currentUser && this.props.item?.id) {
      // this.setState({ isLoading: true });
      var result = await this._noteClient.updateNote(
        this.props.item.id.toString(),
        idNote.toString(),
        this.props.item.isMacro,
        text //.toString("html")
      );
      if (!result) {
        this.props.commonProps.toastComponent?.showMessage(
          this._translation.error,
          this._translation.updateNoteError,
          ToastNotificationType.ERROR
        );
      }
      this.setState({ notes: [] }, () => {
        this._reloadNotes();
      });
    }
  }

  private async _addNote() {
    if (this.state.currentUser && this.props.item?.id) {
      // this.setState({ isLoading: true });
      var result = await this._noteClient.insertNote(
        this.props.item.id.toString(),
        this.props.item.isMacro,
        {
          id: 0,
          text: this.state.textNote,
          lastUpdate: new Date(),
          editedBy: this.state.currentUser,
          edit: false,
        }
      );
      if (!result) {
        this.props.commonProps.toastComponent?.showMessage(
          this._translation.error,
          this._translation.insertNoteError,
          ToastNotificationType.ERROR
        );

        this.setState({ notes: undefined }, () => {
          this._reloadNotes();
        });
      } else {
        this.setState({ notes: undefined, textNote: "" }, () => {
          this._reloadNotes();
        });
      }
    }
  }

  private _onRenderNote(item?: INote, index?: number | undefined) {
    if (item && this.props.item) {
      var canDeleteNote: boolean =
        this.state.permissions.deletableNotes &&
        this.state.permissions.deletableNotes.indexOf(item.id) >= 0;
      var canUpdateNote: boolean =
        this.state.permissions.updatableNotes &&
        this.state.permissions.updatableNotes.indexOf(item.id) >= 0;

      return (
        <div className={styles.noteItem}>
          <div className={styles.headerNote}>
            <div className={styles.personNote}>
              <PersonaComponent
                commonProps={this.props.commonProps}
                personaSize={PersonaSize.size32}
                personaStyle={PersonaStyle.BLUE}
                maxLength={1}
                persona={[item.editedBy]}
              ></PersonaComponent>
            </div>
            <div className={styles.displayNameNote}>
              {item.editedBy.displayName}
            </div>
            <div className={styles.dateNote}>
              {Utility.FormatDateAndTime(item.lastUpdate)}
            </div>
          </div>
          {!item.edit && (
            <div
              className={styles.textNote}
              dangerouslySetInnerHTML={{
                __html: item.text, //.toString("html"),
              }}
            ></div>
          )}
          {item.edit && (
            <div className={styles.textNote}>
              {/* <RichTextEditor
                // multiline autoAdjustHeight
                className={styles.textActivityNotes}
                onChange={(newValue) => {
                  this._tempUpdateNote(
                    item.id,
                    newValue ? newValue : RichTextEditor.createEmptyValue()
                  );
                }}
                value={item.text}
              ></RichTextEditor> */}
              <TextField
                multiline
                autoAdjustHeight
                className={styles.textActivityNotes}
                onChange={(event, newValue) => {
                  this._tempUpdateNote(
                    item.id,
                    newValue ? newValue : "" //RichTextEditor.createEmptyValue()
                  );
                }}
                value={item.text}
              ></TextField>
            </div>
          )}
          <div className={styles.NoteActions}>
            {!item.edit && canDeleteNote && (
              <div className={styles.NoteEdit}>
                <FontIcon
                  aria-label={this._translation.delete}
                  iconName={"Cancel"}
                  className="iconGet"
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      this._deleteNote(item.id);
                    }
                  }}
                  onClick={() => {
                    this._deleteNote(item.id);
                  }}
                ></FontIcon>
              </div>
            )}
            {!item.edit && canUpdateNote && (
              <div className={styles.NoteEdit}>
                <FontIcon
                  aria-label={"Edit"}
                  iconName={"Edit"}
                  className="iconGet"
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      this._editNote(item.id);
                    }
                  }}
                  onClick={() => {
                    this._editNote(item.id);
                  }}
                ></FontIcon>
              </div>
            )}
            {item.edit && (
              <FontIcon
                aria-label={this._translation.save}
                iconName={"Accept"}
                className="iconGet"
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    this._updateNote(item.id, item.text);
                  }
                }}
                onClick={() => {
                  this._updateNote(item.id, item.text);
                }}
              ></FontIcon>
            )}
            {item.edit && (
              <FontIcon
                aria-label={this._translation.cancel}
                iconName={"Cancel"}
                className="iconGet"
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    this._dismissNoteUpdate(item.id);
                  }
                }}
                onClick={() => {
                  this._dismissNoteUpdate(item.id);
                }}
              ></FontIcon>
            )}
          </div>

          {!this.state.notes ||
            (!this.state.viewNotesCollapsed &&
              item.id != this.state.notes[this.state.notes.length - 1].id && (
                <hr />
              ))}

          {/* {!this.state.viewNotesCollapsed && <hr />} */}
        </div>
      );
    }
  }

  public render() {
    return [
      <div className={styles.NoteComponent}>
        <div
          className={
            styles.ActivityNotes + " offset-md-3 ms-Grid-col ms-lg9 ms-md12"
          }
        >
          {!this.state.viewActivityNotes && [
            <div
              key="divIconNotes"
              className={styles.divIconNotes}
              aria-label={"View notes"}
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  this.setState({ viewActivityNotes: true });
                }
              }}
              onClick={() => {
                this.setState({ viewActivityNotes: true });
              }}
            >
              <FontIcon iconName={"DietPlanNotebook"}></FontIcon>
            </div>,
            this.state.notes?.length != undefined &&
              this.state.notes.length > 0 && (
                <div
                  className={styles.divIconWithNotes}
                  key="divIconWithNotes"
                ></div>
              ),
          ]}
        </div>
        <Panel
          role="notifications"
          // data={null}
          onRenderHeader={() => {
            return <div></div>;
          }}
          isOpen={this.state.viewActivityNotes ? true : false}
          onDismiss={() => {
            var notes: INote[] = Object.assign([], this.state.notes);
            notes = notes.map((x) => {
              x.edit = false;
              return x;
            });
            this.setState({
              viewActivityNotes: false,
              textNote: "",
              notes: notes,
            });
          }}
          isHiddenOnDismiss={false}
          isLightDismiss={true}
          customWidth={window.innerWidth > 767 ? "55vw" : "100vw"}
          className={"PanelActivityNotes " + styles.PanelActivityNotes}
          type={PanelType.custom}
          // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
          closeButtonAriaLabel="Close"
        >
          {this.state.isLoading && (
            <SpinnerComponent
              relative={true}
              commonProps={this.props.commonProps}
            ></SpinnerComponent>
          )}
          {/* <LoaderComponent
            commonProps={this.props.commonProps}
            isLoading={this.state.isLoading}
          /> */}
          {true && (
            <div className={styles.formActivityNotes}>
              <div className={styles.headerActivityNotes + " row"}>
                <div className={styles.labelHeaderActivityNotes}>
                  {this._translation.note}
                </div>
                <div
                  aria-label={
                    this.state.viewNotesCollapsed
                      ? this._translation.viewAll(false)
                      : this._translation.collapse
                  }
                  className={styles.accordionHeaderActivityNotes}
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      this.setState({
                        viewNotesCollapsed: !this.state.viewNotesCollapsed,
                      });
                    }
                  }}
                  onClick={() => {
                    this.setState({
                      viewNotesCollapsed: !this.state.viewNotesCollapsed,
                    });
                  }}
                >
                  {this.state.viewNotesCollapsed
                    ? this._translation.viewAll(false)
                    : this._translation.collapse}
                  {this.state.viewNotesCollapsed ? (
                    <FontIcon iconName="ChevronRightSmall"></FontIcon>
                  ) : (
                    <FontIcon iconName="ChevronDownSmall"></FontIcon>
                  )}
                </div>
                <IconButton
                  styles={iconButtonStyles}
                  iconProps={{ iconName: "Cancel" }}
                  aria-label="Close"
                  ariaLabel="Close popup modal"
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      var notes: INote[] = Object.assign([], this.state.notes);
                      notes = notes.map((x) => {
                        x.edit = false;
                        return x;
                      });
                      this.setState({
                        viewActivityNotes: false,
                        textNote: "",
                        notes: notes,
                      });
                    }
                  }}
                  onClick={(ev: { preventDefault: () => void }) => {
                    ev?.preventDefault();
                    var notes: INote[] = Object.assign([], this.state.notes);
                    notes = notes.map((x) => {
                      x.edit = false;
                      return x;
                    });
                    this.setState({
                      viewActivityNotes: false,
                      textNote: "",
                      notes: notes,
                    });
                  }}
                />
              </div>

              <div className={styles.bodyActivityListNotes}>
                <List
                  items={
                    this.state.viewNotesCollapsed
                      ? this.state.notes?.slice(0, 1)
                      : this.state.notes
                  }
                  onRenderCell={this._onRenderNote}
                />
              </div>

              <hr />
              {/* {!this.state.viewNotesCollapsed && <hr />} */}
              {this.state.currentUser && this.state.permissions.canAddNote && (
                <div className={styles.bodyActivityNotes + " row"}>
                  <div className={styles.userBodyActivityNotes}>
                    <PersonaComponent
                      commonProps={this.props.commonProps}
                      persona={[this.state.currentUser]}
                      maxLength={1}
                      personaSize={PersonaSize.size40}
                      personaStyle={PersonaStyle.BLUE}
                    />
                  </div>
                  <div className={styles.textBodyActivityNotes}>
                    {/* <RichTextEditor
                      // multiline autoAdjustHeight
                      className={styles.textActivityNotes}
                      onChange={(newValue) => {
                        this.setState({
                          textNote: newValue
                            ? newValue
                            : RichTextEditor.createEmptyValue(),
                        });
                      }}
                      value={this.state.textNote}
                    ></RichTextEditor> */}
                    <TextField
                      multiline
                      autoAdjustHeight
                      className={styles.textActivityNotes}
                      onChange={(event, newValue) => {
                        this.setState({
                          textNote: newValue ? newValue : "", //RichTextEditor.createEmptyValue(),
                        });
                      }}
                      value={this.state.textNote}
                    ></TextField>
                    {this.state.textNote && (
                      <div className={styles.textActivityNotesActions}>
                        <FontIcon
                          aria-label={this._translation.save}
                          iconName={"Accept"}
                          className="iconGet"
                          tabIndex={0}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              this._addNote();
                            }
                          }}
                          onClick={this._addNote}
                        ></FontIcon>
                        {/* <FontIcon iconName={"Cancel"}></FontIcon> */}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        </Panel>
      </div>,
    ];
  }
}
