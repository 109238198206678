import * as React from "react";
import {FileList, IReadOnlyFileDocument} from "../FileList/FileList";
import {MultipleFileUploadTranslation} from "./MultipleFileUpload.Translation";
import styles from "./MultipleFileUpload.module.scss";
import { 
  PrimaryButton,  
  FontIcon,  
  BaseButton,
  Button,
} from "@fluentui/react";
import {
  IBaseProperties,
  ICommonProperties,
} from "../../../Models/IBaseProperties";
import { DeliverableFileTypes } from "../../../Models/Deliverable";
import { Constants } from "../../../Utility/Constants";
import { ToastNotificationType } from "../../../Models/ToastNote";

export interface IFileDocument {  
  name: string;
  file: File;
  fileSizeRaw: number;
}

export interface IMultipleFileUploadProps extends IBaseProperties {  
  onChange(files:File[]): void;
  onRemove(files:IReadOnlyFileDocument[]): void;
  maxFileSizeKB: number;
  items: IReadOnlyFileDocument[];
}
export interface IMultipleFileUploadState {
  fileList: IFileDocument[];
  items: IReadOnlyFileDocument[];
}

export class MultipleFileUpload extends React.Component<IMultipleFileUploadProps, IMultipleFileUploadState> {    

    private readonly _translation: MultipleFileUploadTranslation;

    private readonly _allExtensions = DeliverableFileTypes.flatMap(fkey=>  
        fkey.associatedExtensions
      );
    private readonly _acceptedExtensions = this._allExtensions.map(t=> { return `.${t}` }) .join(', ');    
    private readonly _fileUpload: React.RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>();    

  constructor(props: IMultipleFileUploadProps) {
    super(props);
    
    this._translation = new MultipleFileUploadTranslation(props.commonProps.translation);

    this.state = {
        fileList: [],
        items: props.items,
    };
    
    this.bindEvents();
  }

  componentWillReceiveProps(nextProps: IMultipleFileUploadProps) {
    
    if (nextProps.items.length !== this.props.items.length) {
      this.setState({ fileList: [] });
    }
  }

  private bindEvents() {    
      this.onDragOver = this.onDragOver.bind(this);
      this.onDropFile = this.onDropFile.bind(this);
      this.onRemoveFile = this.onRemoveFile.bind(this);
      this.onFileChange = this.onFileChange.bind(this);
      this.onClickUploadButton = this.onClickUploadButton.bind(this);
  }

  private onClickUploadButton (event?: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | BaseButton | Button | HTMLSpanElement, MouseEvent>) : void
  {
    if (this._fileUpload.current)
    { 
      this._fileUpload.current.click();
    }
  }    

  private updateFilesState(files: IFileDocument[])
  {
    this.setState(
      {
          fileList:files
      }
    );

    this.props.onChange(this.toHtmlFiles(files));    
  }

  private toHtmlFiles(files: IFileDocument[]): File[]
  {
    return files.map(t=> t.file);
  }  

  private onRemoveFile (files: IReadOnlyFileDocument[]) : void
  {
    const {fileList, items} = this.state;
    const fileIds = files.map(t=> t.id);
    //if edit-mode check in items collection    
    const removedExistingItems = files.filter(t=> t.id > 0);


  const newItems = fileList.filter(t=>
    {
      return !files.some(t2=> t2.id<=0 && t.name===t2.name);
    })

    this.updateFilesState(newItems);              
    this.props.onRemove(removedExistingItems);    
  }

  private onDragOver(event: React.DragEvent<HTMLDivElement>) : void | undefined
  {
      event.preventDefault();
  }  

  private onFileChange(event: React.ChangeEvent<HTMLInputElement>): void | undefined {
    if (event.target.files && event.target.files.length > 0) {
      const file: File = event.target.files[0];
      
      
      if (this.validateFile(file)) 
      {
        const items = this.toFileDocument(file);
        if (items)
        {
            const {fileList} = this.state;

            const mergedItems = fileList.concat(items);
            this.updateFilesState(mergedItems);            
        }
      }
    }
  }

  private validateFile(file: File): boolean
  {
    const fsize = Math.round(file.size / 1024);
    if (fsize < this.props.maxFileSizeKB) 
    {
      return true;
    }
    else
    {
      this.props.commonProps.toastComponent?.showMessage(this._translation.warning, this._translation.warningFileSize(file.name, this.maxFileSizeMB),  ToastNotificationType.WARNING);
      return false;
    }
      
  }

  private appendNewItems(files: globalThis.FileList) {

    const items = this.toFileDocuments(files);
    if (items && items.length > 0)
    {
        const {fileList} = this.state;

        const mergedItems = fileList.concat(items);

        this.updateFilesState(mergedItems);

    }
}
  
  private onDropFile(event: React.DragEvent<HTMLDivElement>) : void | undefined
  {
      event.preventDefault();      
      this.appendNewItems(event.dataTransfer.files)       
  }    

    private toFileDocuments(files: globalThis.FileList): IFileDocument[]
    {
        const items:IFileDocument[] = [];
        for (let i = 0; i < files.length; i++) 
        {
            const file = files[i];
            if (this.validateFile(file))
            {
              items.push(this.toFileDocument(file));
            }
        }

        return items;
    }

    private toFileDocument(file: File): IFileDocument
    {
        const item:IFileDocument = {name: file.name, fileSizeRaw: file.size, file: file};
        return item;
    }

    private get maxFileSizeMB(): number
    {
      return this.props.maxFileSizeKB/1024;
    }

  private get items(): IReadOnlyFileDocument[]
  {
    return this.props.items.concat(this.newItems);
    //return this.newItems;
  }


  private get newItems(): IReadOnlyFileDocument[]
  {
    return this.state.fileList.map(this.toIReadOnlyFileDocument);
  }

  private toIReadOnlyFileDocument(item: IFileDocument): IReadOnlyFileDocument
  {
    const value : IReadOnlyFileDocument = {name: item.name, fileSizeRaw: item.fileSizeRaw, id: -1};
    return value;
  }

 
  render() {       

    return (
      <React.Fragment>
        <div className={styles["multiplefileupload-container"]}>
            <div onDragOver={this.onDragOver} onDrop={this.onDropFile}>
                <div className={styles["multiplefileupload-icon"]}>
                    <FontIcon
                        iconName="BulkUpload"
                        aria-label="Multiple file upload"
                    ></FontIcon>
                </div>
                <div className={styles["multiplefileupload-title"]}>
                    <span>
                        {this._translation.title}
                    </span>
                </div>
                <div className={styles["multiplefileupload-subtitle"]}>
                    <span>
                    {this._translation.titleOr}
                    </span>
                </div>
                <div className={styles["multiplefileupload-button"]}>
                    <PrimaryButton
                        text={this._translation.uploadButtonText}
                        tabIndex={0}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.onClickUploadButton();
                          }
                        }}
                        onClick={this.onClickUploadButton}
                        styles={Constants.iStandardButtonLarge}
                    >
                    </PrimaryButton>
                    <input
                        id="fileBrief"
                        type="file"                    
                        onChange={this.onFileChange}
                        accept = {this._acceptedExtensions}
                        ref={this._fileUpload}
                        className={styles["multiplefileupload-inputfile"]}

                    /> 
                </div>
                <div className={styles["multiplefileupload-hint"]}>
                    <span>
                      {this._translation.maxFileSizeText(this.maxFileSizeMB)}                        
                    </span>                    
                </div>
                <div className={styles["multiplefileupload-note"]}>
                    <span>
                      {this._translation.fileUploadSavingNote}
                    </span>                    
                </div>
            </div>
            <div> 
                <FileList
                    commonProps={this.props.commonProps}
                    items={this.items}
                    onRemoveItems={this.onRemoveFile}
                >
                </FileList>
            </div>
        </div>          
      </React.Fragment>
    );
  }
}
