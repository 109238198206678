import * as React from "react";
import { IBaseProperties } from "../../../Models/IBaseProperties";
import { Utility } from "../../../Utility/Utility";
import { InternalEvent } from "../../../Models/Calendar";
import { PersonaSize } from "@fluentui/react";
import { PersonaComponent } from "../../Shared/PersonaComponent/PersonaComponent";
import styles from "./ActivityTooltip.module.scss";

export interface IActivityTooltipProps extends IBaseProperties {
  activity: InternalEvent;
}
export interface IActivityTooltipState {
}

export class ActivityTooltip extends React.Component<IActivityTooltipProps, IActivityTooltipState> {

  constructor(props: IActivityTooltipProps) {
    super(props);

    this.state = {
    };

  }

  private renderActivityToolTip(event: InternalEvent) {
    const users = event.users ? event.users : [];
    return (
      <div className={styles["tooltip-container"]}>
        <div className={styles["tooltip-calendar"]} >
          {event.calendar}
        </div>
        <div className={styles["tooltip-title"]} >
          {event.title}
        </div>
        <div className={styles['tooltip-daterange']} >
          {event.start.toLocaleDateString()} - {event.end.toLocaleDateString()}
        </div>
        <div>
          <div className={styles['tooltip-resources']} >
            <PersonaComponent commonProps={this.props.commonProps} persona={users} personaSize={PersonaSize.size24} maxLength={10} ></PersonaComponent>
          </div>
          {!event.isMacro && (
            <div className={styles['tooltip-micro']} >
              <div className={["rbc-event-dot", Utility.GetActivityClassNameFromStatus(event.state, false)].join(" ")}></div>
              <div className={styles['tooltip-state']} >
                {event.state}
              </div>
            </div>
          )}
        </div>
        <div className={styles['tooltip-separator']}></div>
        {
          event.microsState &&
          <div className="rt-element__states" >
            {event.microsState.map((micro, index) => {
              return <div key={index} className={["rt-track-key__state", Utility.GetActivityClassNameFromStatus(micro.state, false)].join(" ")} ></div>
            })
            }
          </div>
        }
        {event.macroName && (
          <>
            <div className={styles['tooltip-macro-separator']} ></div>
            <div className={styles['tooltip-macro']} >
              <div className={styles['tooltip-event-macro']} >
                MACRO
              </div>
              <div className={styles['tooltip-macroinfo']} >
                {event.macroName}
              </div>
            </div>
          </>
        )}
      </div>);
  }

  public render() {
    return (
      this.renderActivityToolTip(this.props.activity)
    );
  }

}
