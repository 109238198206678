import * as React from "react";
import { MicroTranslation } from "./Micro.Translation";
import "./Micro.scss";
import {
  Checkbox,
  DefaultButton,
  DropdownMenuItemType,
  IBasePicker,
  IBasePickerSuggestionsProps,
  IDropdownOption,
  ITag,
  Label,
  TextField,
  TagPicker,
  PrimaryButton,
  MessageBar,
  MessageBarType,
  Toggle,
  IComboBoxOption,
  IComboBox,
  SelectableOptionMenuItemType,
  Dialog,
  DialogFooter,
  DialogType,
  IModalProps,
  IDialogContentProps,
  Dropdown,
} from "@fluentui/react";
import { FormEvent } from "react";
import { IUserClient } from "../../../Clients/IUserClient";
import { MicroClient } from "../../../Clients/MicroClient";
import { RangeCalendar } from "../../Shared/RangeCalendar/RangeCalendar";
import { CustomLabel } from "../../Shared/CustomLabel";
import { UserInfo } from "../../../Models/User";
import { Constants } from "../../../Utility/Constants";
import { IDateRangeValue } from "../../Shared/RangeCalendar/IDateRangeValue";
import { IActivity, IMicro } from "../../../Models/Activity";
import { Spoc } from "./Spoc";
import {
  IBaseProperties,
  ICommonProperties,
} from "../../../Models/IBaseProperties";
import { ToastNotificationType } from "../../../Models/ToastNote";
import { UsersTranslation } from "../../../Translations/Users.Translation";
import { Utility } from "../../../Utility/Utility";
import { LoaderComponent } from "../../Shared/LoaderComponent/LoaderComponent";
import { ConcurrentTask } from "../../../Utility/ConcurrentTasks";
import RichTextEditor, { EditorValue } from "react-rte";
import { DeliverablesComponent } from "./ListActivities/ActivityDetail/DeliverablesComponent/DeliverablesComponent";
import { DeliverablesOnNewMicro } from "./DeliverablesOnNewMicro/DeliverablesOnNewMicro";
import { ICalendar } from "../../../Models/ICalendar";
import { ICategory } from "../../../Models/ICategory";
import { ISensibility } from "../../../Models/ISensibility";
import { IResponsibility } from "../../../Models/IResponsibility";

const onRenderLabelNew = (
  commonProps: ICommonProperties,
  controlid: string,
  label: string,
  tooltip: string,
  required?: boolean,
  disableTooltip?: boolean
) => (p: any) => {
  return (
    <CustomLabel
      commonProps={commonProps}
      controlId={controlid}
      label={label}
      tooltiptext={tooltip}
      required={required}
      disableTooltip={disableTooltip}
    ></CustomLabel>
  );
};

const panelStyle = {
  width: "100%",
  height: "100%",
};

export interface IMicroProps extends IBaseProperties {
  className: string | undefined;
  hideModalCallback: Function;
  onSave: (newMicro: IMicro) => void;
  slotinfo?: any;
}
export interface IMicroState {
  associatedMacro: boolean;
  activityType: string;
  spocId?: string;
  headOfActivity: string | null;
  name?: string;
  calendar: number;
  categories: number[];
  responsibilities: number[];
  sensibility?: number;
  alarm: number;
  calendarOptions: IDropdownOption[];
  categoryOptions: IDropdownOption[];
  responsibilityOptions: IDropdownOption[];
  sensibilityOptions: IDropdownOption[];
  description: EditorValue;
  dateFrom?: Date;
  dateTo?: Date;
  rangeDateValue?: IDateRangeValue | undefined;
  parentMacroId?: number;
  macroCollection: IActivity[];
  filterMacroByDate: boolean;
  macrosLoaded: boolean;
  calendarsLoaded: boolean;
  categoriesLoaded: boolean;
  responsibilitiesLoaded: boolean;
  sensibilitiesLoaded: boolean;
  showMacro: boolean;
  showExternalResource: boolean;
  externalResources: string[];
  selectedExternalResource: ITag[] | undefined;
  selectedMacro: ITag[] | undefined;
  macroPickerKey: number;
  resources: string[];
  alarmResources: string[];
  selectedResource: ITag[] | undefined;
  selectedAlarmResource: ITag[] | undefined;
  selectedHeadOfActivity: ITag[];
  selectedSpoc: ITag[];
  isSaving: boolean;
  showMessageBar: boolean;
  showDialog: boolean;
  btnDialog: boolean;
  messageBarText?: string;
  messageType?: MessageBarType;
  invalidMicroDate: boolean;
  deliverables: File[];
  titleError: boolean;
  categoryError: boolean;
  responsabilityError: boolean;
  calendarError: boolean;
}

export class Micro extends React.Component<IMicroProps, IMicroState> {
  private readonly externalResourcePicker = React.createRef<
    IBasePicker<ITag>
  >();
  private readonly macroPicker = React.createRef<IBasePicker<ITag>>();
  private readonly resourcePicker = React.createRef<IBasePicker<ITag>>();
  private readonly alarmResourcePicker = React.createRef<IBasePicker<ITag>>();
  private readonly _userClient: IUserClient;
  private readonly minDigitBeforeSearch: number;
  private readonly minDigitBeforeSearchMacro: number;
  private readonly DEFAULTMINDIGIT = 3;
  private macroItems: IActivity[] = [];
  private calendarItems: IDropdownOption[] = [];
  private categoryItems: IDropdownOption[] = [];
  private responsibilityItems: IDropdownOption[] = [];
  private sensibilityItems: IDropdownOption[] = [];
  private readonly _translation: MicroTranslation;
  private readonly _usersTranslation: UsersTranslation;
  private confirmDialogProps: IModalProps = { isBlocking: true };
  private readonly _concurrentTaskExtResource: ConcurrentTask<
    UserInfo[] | undefined
  > = new ConcurrentTask<UserInfo[] | undefined>();
  private readonly _concurrentTaskInternalResource: ConcurrentTask<
    UserInfo[] | undefined
  > = new ConcurrentTask<UserInfo[] | undefined>();
  private readonly _concurrentTaskHeadOf: ConcurrentTask<
    UserInfo[] | undefined
  > = new ConcurrentTask<UserInfo[] | undefined>();
  private readonly _concurrentTaskSpoc: ConcurrentTask<
    UserInfo[] | undefined
  > = new ConcurrentTask<UserInfo[] | undefined>();

  constructor(props: IMicroProps) {
    super(props);
    this._translation = new MicroTranslation(
      this.props.commonProps.translation
    );
    this._usersTranslation = new UsersTranslation(
      this.props.commonProps.translation
    );
    this.minDigitBeforeSearch = this.DEFAULTMINDIGIT;
    this.minDigitBeforeSearchMacro = 2;

    var rangeDateValue: IDateRangeValue | undefined = undefined;
    if (!!this.props.slotinfo) {
      // set date range
      rangeDateValue = {
        fromDate: this.props.slotinfo.start,
        toDate: this.props.slotinfo.end,
      };
    }

    this.state = {
      filterMacroByDate: false,
      associatedMacro: false,
      activityType: "micro",
      headOfActivity: null,
      showExternalResource: false,
      externalResources: [],
      selectedExternalResource: [],
      selectedMacro: [],
      macroPickerKey: 1,
      resources: [],
      categories: [],
      responsibilities: [],
      alarmResources: [],
      selectedResource: [],
      selectedAlarmResource: [],
      macroCollection: [],
      macrosLoaded: false,
      calendarsLoaded: false,
      categoriesLoaded: false,
      responsibilitiesLoaded: false,
      sensibilitiesLoaded: false,
      showMacro: false,
      selectedHeadOfActivity: [],
      selectedSpoc: [],
      isSaving: false,
      showMessageBar: false,
      showDialog: false,
      btnDialog: false,
      invalidMicroDate: false,
      description: RichTextEditor.createEmptyValue(),
      deliverables: [],
      calendar: 0,
      alarm: -1,
      calendarOptions: [{ key: 0, text: this._translation.loading }],
      categoryOptions: [{ key: 0, text: this._translation.loading }],
      responsibilityOptions: [{ key: 0, text: this._translation.loading }],
      sensibilityOptions: [{ key: 0, text: this._translation.loading }],
      rangeDateValue: rangeDateValue,
      titleError: false,
      categoryError: false,
      responsabilityError: false,
      calendarError: false
    };

    this._userClient = props.commonProps.clientCreator.createUserClient();

    this.bindEvents();
  }

  private bindEvents() {
    this.titleChange = this.titleChange.bind(this);
    this.calendarChange = this.calendarChange.bind(this);
    this.categoryChange = this.categoryChange.bind(this);
    this.responsibilityChange = this.responsibilityChange.bind(this);
    this.categoryChangeMultiple = this.categoryChangeMultiple.bind(this);
    this.responsibilityChangeMultiple = this.responsibilityChangeMultiple.bind(
      this
    );
    this.sensibilityChange = this.sensibilityChange.bind(this);
    this.alarmOnChange = this.alarmOnChange.bind(this);
    this.descriptionChange = this.descriptionChange.bind(this);
    this.activityTypeOnChange = this.activityTypeOnChange.bind(this);
    this.onSaveMicro = this.onSaveMicro.bind(this);
    this.onShowDialog = this.onShowDialog.bind(this);
    this.pickerSpocChange = this.pickerSpocChange.bind(this);
    this.headOfActivityChange = this.headOfActivityChange.bind(this);
    this.closePanel = this.closePanel.bind(this);
    this.associatedMacroChange = this.associatedMacroChange.bind(this);
    this.macroOnChange = this.macroOnChange.bind(this);
    this.rangeCalendarChange = this.rangeCalendarChange.bind(this);

    this.addExternalResourceChange = this.addExternalResourceChange.bind(this);
    this.selectedMacroChange = this.selectedMacroChange.bind(this);
    this.pickerExternalResourceMultipleItemChange = this.pickerExternalResourceMultipleItemChange.bind(
      this
    );
    this.pickerResourceMultipleItemChange = this.pickerResourceMultipleItemChange.bind(
      this
    );
    this.pickerAlarmResourceMultipleItemChange = this.pickerAlarmResourceMultipleItemChange.bind(
      this
    );
    this.closeMessageBar = this.closeMessageBar.bind(this);
    this.filterMacroChange = this.filterMacroChange.bind(this);
    this.macroOnChangeComboBox = this.macroOnChangeComboBox.bind(this);
  }

  async componentDidMount(): Promise<void> {
    // await this.loadMacroAsync();
    await this.loadCalendarsAsync();
    await this.loadCategoriesAsync();
    await this.loadResponsibilitiesAsync();
    await this.loadSensibilitiesAsync();
  }

  async componentWillUnmount(): Promise<void> {
    document.getElementById("newActivityButttonForBrief")?.focus();
  }

  private filterMacroChange(
    ev: React.MouseEvent<HTMLElement>,
    checked?: boolean
  ) {
    const check = checked ? true : false;

    this.setState(
      { filterMacroByDate: check },
      async () => await this.loadMacroAsync()
    );
  }

  private async loadCalendarsAsync() {
    try {
      const actClient = this.props.commonProps.clientCreator.createActivityClient();
      var calendarItems = await actClient.getCalendars();
      var calendarsPermissions =
        (await actClient.getCalendarsPermissions()) || [];
      var canCreateCalendarIds = calendarsPermissions
        .filter((p) => p.canCreate)
        .map((p) => p.calendarId);

      if (!!calendarItems && calendarItems.length > 0) {
        //filter by permissions
        calendarItems = calendarItems.filter((c) =>
          canCreateCalendarIds.some((p) => p === c.key)
        );

        this.calendarItems = calendarItems.map(
          (o: ICalendar): IDropdownOption => ({
            key: o.key,
            text: o.value,
            itemType: DropdownMenuItemType.Normal,
          })
        );
        const cal = this.state.calendar
          ? this.state.calendar
          : (this.calendarItems[0].key as number);
        this.setState(
          {
            calendarOptions: this.calendarItems,
            calendarsLoaded: true,
            calendar: cal,
          },
          () => this.loadMacroAsync()
        );
      } else {
        this.setState({
          showMessageBar: true,
          messageBarText: this._translation.errorInLoadCalendar,
          messageType: MessageBarType.error,
        });
      }
    } catch (error) {
      console.error(error);
      this.setState({
        showMessageBar: true,
        messageBarText: this._translation.errorInLoadCalendar,
        messageType: MessageBarType.error,
      });
    }
  }

  private async loadCategoriesAsync() {
    try {
      const actClient = this.props.commonProps.clientCreator.createActivityClient();
      const items = await actClient.getCategories();

      if (!!items && items.length > 0) {
        this.categoryItems = items.map(
          (o: ICategory): IDropdownOption => ({
            key: o.key,
            text: o.value,
            itemType: DropdownMenuItemType.Normal,
          })
        );
        this.categoryItems.unshift({
          key: 0,
          text: this._translation.selectCategory,
          itemType: DropdownMenuItemType.Normal,
        });
        this.setState({
          categoryOptions: this.categoryItems,
          categoriesLoaded: true,
        });
      } else {
        this.setState({
          showMessageBar: true,
          messageBarText: this._translation.errorInLoadCategories,
          messageType: MessageBarType.error,
        });
      }
    } catch (error) {
      console.error(error);
      this.setState({
        showMessageBar: true,
        messageBarText: this._translation.errorInLoadCategories,
        messageType: MessageBarType.error,
      });
    }
  }

  private async loadResponsibilitiesAsync() {
    try {
      const actClient = this.props.commonProps.clientCreator.createActivityClient();
      const items = await actClient.getResponsibilities();

      if (!!items && items.length > 0) {
        this.responsibilityItems = items.map(
          (o: IResponsibility): IDropdownOption => ({
            key: o.key,
            text: o.value,
            itemType: DropdownMenuItemType.Normal,
          })
        );
        this.responsibilityItems.unshift({
          key: 0,
          text: this._translation.selectResponsibility,
          itemType: DropdownMenuItemType.Normal,
        });
        this.setState({
          responsibilityOptions: this.responsibilityItems,
          responsibilitiesLoaded: true,
        });
      } else {
        this.setState({
          showMessageBar: true,
          messageBarText: this._translation.errorInLoadResponsibilities,
          messageType: MessageBarType.error,
        });
      }
    } catch (error) {
      console.error(error);
      this.setState({
        showMessageBar: true,
        messageBarText: this._translation.errorInLoadResponsibilities,
        messageType: MessageBarType.error,
      });
    }
  }

  private async loadSensibilitiesAsync() {
    try {
      const actClient = this.props.commonProps.clientCreator.createActivityClient();
      const items = await actClient.getSensibilities();

      if (!!items && items.length > 0) {
        this.sensibilityItems = items.map(
          (o: ISensibility): IDropdownOption => ({
            key: o.key,
            text: o.value,
            itemType: DropdownMenuItemType.Normal,
          })
        );
        this.sensibilityItems.unshift({
          key: 0,
          text: this._translation.selectSensibility,
          itemType: DropdownMenuItemType.Normal,
        });
        this.setState({
          sensibilityOptions: this.sensibilityItems,
          sensibilitiesLoaded: true,
        });
      } else {
        this.setState({
          showMessageBar: true,
          messageBarText: this._translation.errorInLoadSensibilities,
          messageType: MessageBarType.error,
        });
      }
    } catch (error) {
      console.error(error);
      this.setState({
        showMessageBar: true,
        messageBarText: this._translation.errorInLoadSensibilities,
        messageType: MessageBarType.error,
      });
    }
  }

  private async loadMacroAsync() {
    try {
      if (!this.state.calendar) {
        this.setState({
          showMessageBar: true,
          messageBarText: this._translation.errorInLoadMacro,
          messageType: MessageBarType.error,
        });
        return;
      }
      const actClient = this.props.commonProps.clientCreator.createActivityClient();
      let date: Date | undefined;
      if (this.state.filterMacroByDate && this.state.rangeDateValue) {
        date = this.state.rangeDateValue.fromDate;
      }

      const macroItems = await actClient.getMacroAsync(
        date,
        this.state.calendar
      );

      if (macroItems) {
        this.macroItems = macroItems;
        if (
          !!this.state.parentMacroId &&
          !macroItems.some((m) => m.id === this.state.parentMacroId)
        ) {
          this.setState({
            parentMacroId: undefined,
            selectedMacro: undefined,
            macroPickerKey: this.state.macroPickerKey + 1,
          });
        }
        this.setState({ macroCollection: this.macroItems, macrosLoaded: true });
      } else {
        this.setState({
          showMessageBar: true,
          messageBarText: this._translation.errorInLoadMacro,
          messageType: MessageBarType.error,
        });
      }
    } catch (error) {
      console.error(error);
      this.setState({
        showMessageBar: true,
        messageBarText: this._translation.errorInLoadMacro,
        messageType: MessageBarType.error,
      });
    }
  }

  private get alarmOptions(): IDropdownOption[] {
    return [
      {
        key: -1,
        text: this._translation.noAlarm,
        itemType: DropdownMenuItemType.Normal,
      },
      {
        key: 0,
        text: this._translation.alarmOnEndDate,
        itemType: DropdownMenuItemType.Normal,
      },
      {
        key: 1,
        text: this._translation.alarm1DayBefore,
        itemType: DropdownMenuItemType.Normal,
      },
      {
        key: 2,
        text: this._translation.alarm2DaysBefore,
        itemType: DropdownMenuItemType.Normal,
      },
      {
        key: 3,
        text: this._translation.alarm3DaysBefore,
        itemType: DropdownMenuItemType.Normal,
      },
      {
        key: 4,
        text: this._translation.alarm4DaysBefore,
        itemType: DropdownMenuItemType.Normal,
      },
      {
        key: 5,
        text: this._translation.alarm5DaysBefore,
        itemType: DropdownMenuItemType.Normal,
      },
      {
        key: 7,
        text: this._translation.alarm1WeekBefore,
        itemType: DropdownMenuItemType.Normal,
      },
      {
        key: 14,
        text: this._translation.alarm2WeeksBefore,
        itemType: DropdownMenuItemType.Normal,
      },
    ];
  }

  private addExternalResourceChange(
    ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
    checked?: boolean | undefined
  ) {
    const checkValue: boolean = checked ? true : false;
    this.setState({ showExternalResource: checkValue });
  }

  private pickerExternalResourceMultipleItemChange(
    items?: ITag[] | undefined
  ): void {
    if (items) {
      const ids: string[] = (items as ITag[]).map((t) => t.key as string);
      this.setState({
        externalResources: ids,
        selectedExternalResource: items,
      });
    }
  }

  private selectedMacroChange(items?: ITag[] | undefined): void {
    if (items) {
      const ids: number[] = (items as ITag[]).map((t) => t.key as number);
      this.setState({
        parentMacroId: ids[0] || undefined,
        selectedMacro: items,
      });
    }
  }

  private pickerResourceMultipleItemChange(items?: ITag[] | undefined): void {
    if (items) {
      const ids: string[] = (items as ITag[]).map((t) => t.key as string);
      this.setState({ resources: ids, selectedResource: items });
    }
  }

  private pickerAlarmResourceMultipleItemChange(
    items?: ITag[] | undefined
  ): void {
    if (items) {
      const ids: string[] = (items as ITag[]).map((t) => t.key as string);
      this.setState({ alarmResources: ids, selectedAlarmResource: items });
    }
  }

  private get getIMicro(): IMicro | undefined {
    let micro: IMicro | undefined;
    const {
      rangeDateValue,
      name,
      calendar,
      categories,
      responsibilities,
      sensibility,
      description,
      spocId,
      alarm,
      alarmResources,
      resources,
      externalResources,
      parentMacroId,
      deliverables,
    } = this.state;

    if (rangeDateValue && name) {
      micro = {
        name: name,
        calendar: calendar,
        categories: categories,
        responsibilities: responsibilities,
        sensibility: sensibility,
        alarm: alarm,
        alarmResourcesUPNs: alarmResources,
        isMacro: false,
        description: description.toString("html"),
        dateFrom: rangeDateValue.fromDate,
        dateTo: rangeDateValue.toDate,
        spocUPN: spocId,
        resourcesUPNs: resources,
        externalResourcesUPNs: externalResources,
        parentMacroId: parentMacroId,
        permissions: Utility.getDefaultActivityPermissions(),
        deliverables: deliverables,
      };
    }

    return micro;
  }

  private readonly spocPicker = React.createRef<IBasePicker<ITag>>();

  private currentListContainsTag = (tag: ITag, tagList?: ITag[]) => {
    if (!tagList || !tagList.length || tagList.length === 0) {
      return false;
    }
    return tagList.some((compareTag) => compareTag.key === tag.key);
  };

  //Activity type collection used by drop downlist
  private readonly activityTypeOptions: IDropdownOption[] = [
    { key: "macro", text: "Macro", itemType: DropdownMenuItemType.Normal },
    { key: "micro", text: "Micro", itemType: DropdownMenuItemType.Normal },
  ];

  private get pickerSuggestionsProps(): IBasePickerSuggestionsProps {
    const props: IBasePickerSuggestionsProps = {
      suggestionsHeaderText: this._translation.suggestedUser,
      noResultsFoundText: this._translation.noUsersFound,
    };
    return props;
  }

  private get pickerMacroSuggestionsProps(): IBasePickerSuggestionsProps {
    const props: IBasePickerSuggestionsProps = {
      suggestionsHeaderText: this._translation.suggestedMacro,
      noResultsFoundText: this._translation.noMacroFound,
    };
    return props;
  }

  private readonly UserInfoToITag = (item: UserInfo): ITag =>
    ({ key: item.username, name: item.displayName } as ITag);

  private readonly checkExternalResourceSelectedItem = (
    selectedItem?: ITag | undefined
  ): ITag | PromiseLike<ITag> | null => {
    return this.checkForDuplicate(selectedItem, this.externalResourcePicker);
  };

  private readonly filterExternalResource = (
    filter: string,
    selectedItems?: ITag[] | undefined
  ): ITag[] | PromiseLike<ITag[]> => {
    if (filter) {
      if (filter.length >= this.minDigitBeforeSearch) {
        this.pickerSuggestionsProps.noResultsFoundText = this._translation.noUsersFound;
        return this.getExternalResourceByFilterAsync(filter);
      } else {
        this.pickerSuggestionsProps.noResultsFoundText = this._translation.minDigitBeforeSearch(
          this.minDigitBeforeSearch
        );
        return [];
      }
    } else {
      return [];
    }
  };

  private readonly filterMacro = (
    filter: string,
    selectedItems?: ITag[] | undefined
  ): ITag[] | PromiseLike<ITag[]> => {
    if (filter) {
      if (filter.length >= this.minDigitBeforeSearchMacro) {
        this.pickerSuggestionsProps.noResultsFoundText = this._translation.noMacroFound;
        return this.getMacroByFilter(filter);
      } else {
        this.pickerSuggestionsProps.noResultsFoundText = this._translation.minDigitBeforeSearch(
          this.minDigitBeforeSearchMacro
        );
        return [];
      }
    } else {
      return [];
    }
  };

  private async getExternalResourceByFilterAsync(
    filter: string
  ): Promise<ITag[]> {
    const promise = () =>
      this._userClient.getExternalResourceFilterAsync({term: filter, calendar: this.state.calendar});

    const result = await this._concurrentTaskExtResource.executePromise(
      promise,
      []
    );

    if (result) {
      return result.map(this.UserInfoToITag);
    } else {
      this.props.commonProps.toastComponent?.showMessage(
        this._translation.error,
        this._usersTranslation.genericGetUsersError,
        ToastNotificationType.ERROR
      );
      return [];
    }
  }

  private getMacroByFilter(filter: string): ITag[] {
    return this.macroOptionItems
      .filter(
        (i) =>
          !!i.text && i.text.toLowerCase().indexOf(filter.toLowerCase()) >= 0
      )
      .map(
        (item: IComboBoxOption): ITag =>
          ({ key: item.key, name: item.text } as ITag)
      );
  }

  private async getInternalResourceByFilterAsync(
    filter: string
  ): Promise<ITag[]> {
    const promise = () =>
      this._userClient.getInternalResourceFilterAsync({term: filter, calendar: this.state.calendar});

    const result = await this._concurrentTaskInternalResource.executePromise(
      promise,
      []
    );

    if (result) {
      return result.map(this.UserInfoToITag);
    } else {
      this.props.commonProps.toastComponent?.showMessage(
        this._translation.error,
        this._usersTranslation.genericGetUsersError,
        ToastNotificationType.ERROR
      );
      return [];
    }
  }

  private async getAlarmResourceByFilterAsync(filter: string): Promise<ITag[]> {
    const promise = () =>
      this._userClient.getInternalResourceFilterAsync({term: filter, calendar: this.state.calendar});

    const result = await this._concurrentTaskInternalResource.executePromise(
      promise,
      []
    );

    if (result) {
      return result.map(this.UserInfoToITag);
    } else {
      this.props.commonProps.toastComponent?.showMessage(
        this._translation.error,
        this._usersTranslation.genericGetUsersError,
        ToastNotificationType.ERROR
      );
      return [];
    }
  }

  private readonly filterAssignActivity = (
    filter: string,
    selectedItems?: ITag[] | undefined
  ): ITag[] | PromiseLike<ITag[]> => {
    if (filter) {
      if (filter.length >= this.minDigitBeforeSearch) {
        this.pickerSuggestionsProps.noResultsFoundText = this._translation.noUsersFound;
        return this.getInternalResourceByFilterAsync(filter);
      } else {
        this.pickerSuggestionsProps.noResultsFoundText = this._translation.minDigitBeforeSearch(
          this.minDigitBeforeSearch
        );
        return [];
      }
    } else {
      return [];
    }
  };

  private readonly filterAlarmResources = (
    filter: string,
    selectedItems?: ITag[] | undefined
  ): ITag[] | PromiseLike<ITag[]> => {
    if (filter) {
      if (filter.length >= this.minDigitBeforeSearch) {
        this.pickerSuggestionsProps.noResultsFoundText = this._translation.noUsersFound;
        return this.getAlarmResourceByFilterAsync(filter);
      } else {
        this.pickerSuggestionsProps.noResultsFoundText = this._translation.minDigitBeforeSearch(
          this.minDigitBeforeSearch
        );
        return [];
      }
    } else {
      return [];
    }
  };

  private readonly checkResourceSelectedItem = (
    selectedItem?: ITag | undefined
  ): ITag | PromiseLike<ITag> | null => {
    return this.checkForDuplicate(selectedItem, this.resourcePicker);
  };
  private readonly checkAlarmResourceSelectedItem = (
    selectedItem?: ITag | undefined
  ): ITag | PromiseLike<ITag> | null => {
    return this.checkForDuplicate(selectedItem, this.alarmResourcePicker);
  };

  private readonly checkMacroSelectedItem = (
    selectedItem?: ITag | undefined
  ): ITag | PromiseLike<ITag> | null => {
    if (
      selectedItem &&
      this.macroPicker.current &&
      this.currentListContainsTag(
        selectedItem as ITag,
        this.macroPicker.current.items
      )
    ) {
      return null;
    }

    return selectedItem as ITag;
  };

  private readonly checkForDuplicate = (
    selectedItem?: ITag | undefined,
    picker?: React.RefObject<IBasePicker<ITag>>
  ): ITag | PromiseLike<ITag> | null => {
    if (
      selectedItem &&
      picker?.current &&
      this.currentListContainsTag(selectedItem as ITag, picker.current.items)
    ) {
      return null;
    }

    return selectedItem as ITag;
  };

  private readonly checkSelectedItem = (
    selectedItem?: ITag | undefined
  ): ITag | PromiseLike<ITag> | null => {
    if (
      selectedItem &&
      this.spocPicker.current &&
      this.currentListContainsTag(
        selectedItem as ITag,
        this.spocPicker.current.items
      )
    ) {
      return null;
    }

    return selectedItem as ITag;
  };

  private async getHeadOfActivityUserByFilterAsync(
    filter: string
  ): Promise<ITag[]> {
    const promise = () => this._userClient.getHeadOfActivityFilterAsync({term: filter, calendar: this.state.calendar});

    const result = await this._concurrentTaskHeadOf.executePromise(promise, []);

    if (result) {
      return result.map(this.UserInfoToITag);
    } else {
      this.props.commonProps.toastComponent?.showMessage(
        this._translation.error,
        this._usersTranslation.genericGetUsersError,
        ToastNotificationType.ERROR
      );
      return [];
    }
  }

  private async getSpocFilterAsync(filter: string): Promise<ITag[]> {
    const promise = () => this._userClient.getSpocByFilterAsync({term: filter, calendar: this.state.calendar});

    const result = await this._concurrentTaskSpoc.executePromise(promise, []);

    if (result) {
      return result.map(this.UserInfoToITag);
    } else {
      this.props.commonProps.toastComponent?.showMessage(
        this._translation.error,
        this._usersTranslation.genericGetUsersError,
        ToastNotificationType.ERROR
      );
      return [];
    }
  }

  private readonly filterHeadOfActivity = (
    filter: string,
    selectedItems?: ITag[] | undefined
  ): ITag[] | PromiseLike<ITag[]> => {
    return this.getHeadOfActivityUserByFilterAsync(filter);
  };

  private readonly filterSpoc = (
    filter: string,
    selectedItems?: ITag[] | undefined
  ): ITag[] | PromiseLike<ITag[]> => {
    return this.getSpocFilterAsync(filter);
  };

  private headOfActivityChange(items?: ITag[] | undefined): void {
    if (items && (items as ITag[]).length === 1) {
      const id: string = (items as ITag[])[0].key as string;

      this.setState({ headOfActivity: id, selectedHeadOfActivity: items });
    } else {
      this.setState({ headOfActivity: null, selectedHeadOfActivity: [] });
    }
  }

  private pickerSpocChange(selected: ITag[], spocId?: string): void {
    this.setState({ spocId: spocId, selectedSpoc: selected });
  }

  private get canSave(): boolean {
    const {
      name,
      rangeDateValue,
      invalidMicroDate,
      calendar,
      categories,
      responsibilities,
    } = {
      ...this.state,
    } as const;

    return calendar &&
      name &&
      rangeDateValue &&
      !invalidMicroDate &&
      categories.length > 0 &&
      responsibilities.length > 0
      ? true
      : false;
  }

  private readonly macroToIDropdownOption = (
    item: IActivity
  ): IDropdownOption =>
    ({
      key: item.id,
      text: item.name,
      itemType: DropdownMenuItemType.Normal,
    } as IDropdownOption);

  private readonly macroToIComboBoxOption = (
    item: IActivity
  ): IComboBoxOption =>
    ({
      key: item.id,
      text: item.name,
      itemType: SelectableOptionMenuItemType.Normal,
    } as IComboBoxOption);

  private get Macros(): IDropdownOption[] {
    if (this.state.macrosLoaded) {
      let values = this.state.macroCollection.map(this.macroToIDropdownOption);
      const emptyItems = {
        key: -1,
        text: "",
        itemType: DropdownMenuItemType.Normal,
      } as IDropdownOption;
      //add an empty item
      values.unshift(emptyItems);
      return values;
    } else {
      return [];
    }
  }

  private get macroOptionItems(): IComboBoxOption[] {
    if (this.state.macrosLoaded) {
      let values = this.state.macroCollection.map(this.macroToIComboBoxOption);
      const emptyItems: IComboBoxOption = {
        key: -1,
        text: "",
        itemType: SelectableOptionMenuItemType.Normal,
      } as IDropdownOption;
      //add an empty item
      values.unshift(emptyItems);
      return values;
    } else {
      return [];
    }
  }

  titleChange(
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined
  ) {
    this.setState({ name: newValue });
  }

  calendarChange(
    ev: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption
  ) {
    this.setState(
      {
        calendar: !!option?.key ? +option.key : 0,
        parentMacroId: undefined,
        selectedMacro: undefined,
        macroPickerKey: this.state.macroPickerKey + 1,
        macroCollection: [],
        selectedSpoc: [],
        selectedHeadOfActivity: []
      },
      () => this.loadMacroAsync()
    );    
  }
  categoryChangeMultiple(
    ev: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption
  ) {
    if (!!option?.key) {
      const value = !!option?.key ? +option.key : 0;
      var categories = this.state.categories;
      if (option?.selected) {
        categories.push(value);
      } else {
        categories = categories.filter((x) => x != value);
      }
      this.setState({
        categories: categories,
      });

      // var categories = this.state.categories;
      // categories.push(+option.key);
      // this.setState({ categories: categories });
    }
  }
  categoryChange(
    ev: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption
  ) {
    this.setState({ categories: !!option?.key ? [+option.key] : [] });
  }
  responsibilityChangeMultiple(
    ev: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption
  ) {
    if (!!option?.key) {
      const value = !!option?.key ? +option.key : 0;
      var responsibilities = this.state.responsibilities;
      if (option?.selected) {
        responsibilities.push(value);
      } else {
        responsibilities = responsibilities.filter((x) => x != value);
      }
      this.setState({
        responsibilities: responsibilities,
      });
    }
  }
  responsibilityChange(
    ev: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption
  ) {
    this.setState({ responsibilities: !!option?.key ? [+option.key] : [] });
  }
  sensibilityChange(
    ev: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption
  ) {
    this.setState({ sensibility: !!option?.key ? +option.key : 0 });
  }
  alarmOnChange(ev: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) {
    const value = option?.key !== undefined ? +option.key : -1;
    this.setState({ alarm: value }, () => {
      if (this.state.alarm < 0) {
        this.setState({ alarmResources: [], selectedAlarmResource: [] });
      }
    });
  }

  descriptionChange(
    // event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue: EditorValue
  ) {
    this.setState({ description: newValue });
  }

  private activityTypeOnChange(
    event: FormEvent<HTMLDivElement>,
    option?: IDropdownOption | undefined,
    index?: number | undefined
  ): void | undefined {
    if (option) {
      const actType: string = option.key.toString();
      this.setState({ activityType: actType }, () => {});
    }
  }

  private macroOnChange(
    event: FormEvent<HTMLDivElement>,
    option?: IDropdownOption | undefined,
    index?: number | undefined
  ): void | undefined {
    if (option) {
      const brief = option.key as number;
      const value = brief !== -1 ? brief : undefined;
      this.setState({ parentMacroId: value });
    }
  }

  private macroOnChangeComboBox(
    event: FormEvent<IComboBox>,
    option?: IComboBoxOption | undefined,
    index?: number | undefined
  ): void | undefined {
    if (option) {
      const itemkey = option.key as number;
      const value = itemkey !== -1 ? itemkey : undefined;
      this.setState({ parentMacroId: value }, () =>
        this.compareMicroMacroDate()
      );
    }
  }

  private compareMicroMacroDate() {
    let showError = false;
    let btnDialog = false;
    let messageBarText = "";
    let invalidMicroDate = false;
    let messageType: MessageBarType = MessageBarType.warning;

    const { showMacro } = this.state;

    if (this.state.parentMacroId && this.state.rangeDateValue) {
      const selectedItem = this.macroItems.filter(
        (t) => t.id === this.state.parentMacroId
      );
      if (selectedItem && selectedItem.length > 0) {
        const { dateFrom, dateTo } = selectedItem[0];
        if (this.state.rangeDateValue.fromDate < dateFrom) {
          showError = true;
          invalidMicroDate = true;
          messageBarText = this._translation.dateMicroLessMacro(
            Utility.formatDate(dateFrom, "DD/MM/YYYY"),
            Utility.formatDate(dateTo, "DD/MM/YYYY")
          );
          messageType = MessageBarType.error;
        } else if (this.state.rangeDateValue.toDate > dateTo) {
          showError = true;
          invalidMicroDate = false;
          btnDialog = true;
          messageBarText = this._translation.dateMicroGreaterMacro(
            Utility.formatDate(dateFrom, "DD/MM/YYYY"),
            Utility.formatDate(dateTo, "DD/MM/YYYY")
          );
          messageType = MessageBarType.warning;
        }
      }
    }
    invalidMicroDate = showMacro && invalidMicroDate;
    showError = showMacro && showError;
    if (!showMacro && messageBarText) {
      messageBarText = "";
    }

    this.setState({
      invalidMicroDate: invalidMicroDate,
      showMessageBar: showError,
      messageBarText: messageBarText,
      btnDialog: btnDialog,
      messageType: messageType,
    });
  }

  private onSaveMicro(
    event: React.MouseEvent<
      | HTMLAnchorElement
      | HTMLButtonElement
      | HTMLDivElement
      | import("@fluentui/react").BaseButton
      | import("@fluentui/react").Button
      | HTMLSpanElement,
      MouseEvent
    >
  ): void | undefined {
    event.preventDefault();
    this.setState(
      {
        showDialog: false,
        isSaving: true,
      },
      () => {
        if (this.canSave)
            this.saveMicro()
      }
    );
  }

  private onShowDialog(
    event: React.MouseEvent<
      | HTMLAnchorElement
      | HTMLButtonElement
      | HTMLDivElement
      | import("@fluentui/react").BaseButton
      | import("@fluentui/react").Button
      | HTMLSpanElement,
      MouseEvent
    >
  ): void | undefined {
    this.setState({ showDialog: true });
  }

  private onDialogSaveMicro(
    event: React.MouseEvent<
      | HTMLAnchorElement
      | HTMLButtonElement
      | HTMLDivElement
      | import("@fluentui/react").BaseButton
      | import("@fluentui/react").Button
      | HTMLSpanElement,
      MouseEvent
    >
  ): void | undefined {
    event.preventDefault();

    this.saveMicro();
  }

  private saveMicro() {
    this.setState({ isSaving: true }, () => {
      this.saveMicroAsync();
    });
  }

  private async saveMicroAsync() {
    try {

      if (!this.state.dateFrom || !this.state.dateTo)
      {
        this.setState({
          calendarError: true
        });
      }

      if (!this.state.name)
      {
        this.setState({
          titleError: true
        });
      }

      if (this.state.categories?.length == 0)
      {
        this.setState({
          categoryError: true
        });
      }

      if(this.state.responsibilities?.length == 0)
      {
        this.setState({
          responsabilityError: true
        });
      }

      const data = this.getIMicro;

      if (data) {
        const microClient = new MicroClient();

        const retValue = await microClient.saveMicroAsync(data);

        if (retValue) {
          this.props.onSave(data);
          this.setState({ messageBarText: undefined, showMessageBar: false });
        } else {
          this.setState({
            messageBarText: this.genericErrorMessage,
            showMessageBar: true,
            messageType: MessageBarType.error,
          });
        }
      }
    } catch (error) {
      console.error(error);
      this.setState({
        messageBarText: this.genericErrorMessage,
        showMessageBar: true,
        messageType: MessageBarType.error,
      });
    } finally {
      this.setState({ isSaving: false, showDialog: false });
    }
  }

  private get genericErrorMessage(): string {
    return this._translation.genericErrorInSave;
  }

  private renderMacro(): JSX.Element {
    return (
      <React.Fragment>
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-Grid-col-width100">
            <Label htmlFor="macro" styles={Constants.labelStyle}>
              {this._translation.selectMacro}
            </Label>
            <TagPicker
              ref={this.macroPicker}
              key={this.state.macroPickerKey}
              pickerSuggestionsProps={this.pickerMacroSuggestionsProps}
              onItemSelected={this.checkMacroSelectedItem}
              removeButtonAriaLabel={this._translation.remove}
              onResolveSuggestions={this.filterMacro}
              resolveDelay={300}
              itemLimit={1}
              onChange={this.selectedMacroChange}
              styles={Constants.pickerStyle}
              selectedItems={this.state.selectedMacro}
            ></TagPicker>
          </div>
        </div>
      </React.Fragment>
    );
  }

  private renderExternalResource(): JSX.Element {
    const externalResoucePicker = (
      <>
        <Label htmlFor="externalResources" styles={Constants.labelStyle}>
          {this._translation.externalResource}
        </Label>
        <TagPicker
          ref={this.externalResourcePicker}
          pickerSuggestionsProps={this.pickerSuggestionsProps}
          onItemSelected={this.checkExternalResourceSelectedItem}
          removeButtonAriaLabel={this._translation.remove}
          onResolveSuggestions={this.filterExternalResource}
          resolveDelay={300}
          onChange={this.pickerExternalResourceMultipleItemChange}
          styles={Constants.pickerStyle}
          selectedItems={this.state.selectedExternalResource}
        ></TagPicker>
      </>
    );

    return (
      <React.Fragment>
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-Grid-col-width100">
            {externalResoucePicker}
          </div>
        </div>
      </React.Fragment>
    );
  }

  private closeMessageBar() {
    this.setState({ showMessageBar: false });
  }

  private get showAlarmResources(): boolean {
    return this.state.alarm >= 0;
  }

  render() {
    const showMacro = this.state.showMacro;
    const isExternalResourceVisibile = this.state.showExternalResource;
    const showError = this.state.showMessageBar;

    let titleError;
    let categoryError;
    let responsabilityError;
    let calendarError;

    if (this.state.calendarError)
    {
      calendarError = <div className="microErrorMessage" role="alert" aria-label="calendar error message" id="calendarErrorMessageBox"><p className="microErrorMessage">Calendar date are required</p></div>;
    }

    if (this.state.titleError)
    {
      titleError = <div role="alert" aria-label="title error message" id="titleErrorMessageBox"><p className="microErrorMessage">Title is required</p></div>;
    }
    if (this.state.categoryError)
    {
      categoryError = <div role="alert" aria-label="category error message" id="categoryErrorMessageBox"><p className="microErrorMessage">Category is required</p></div>;
    }
    if (this.state.responsabilityError)
    {
      responsabilityError = <div role="alert" aria-label="responsability error message" id="responsabilityErrorMessageBox"><p className="microErrorMessage">Responsability is required</p></div>;
    }

    const jsxMessageBar = (
      <div className="ms-Grid-row ">
        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 messageError">
          <MessageBar
            messageBarType={this.messageBarType()}
            isMultiline={false}
            dismissButtonAriaLabel={this._translation.close}
            onDismiss={this.closeMessageBar}
            truncated={true}
            overflowButtonAriaLabel="See more"
          >
            {this.state.messageBarText}
          </MessageBar>
        </div>
      </div>
    );

    const jsxActivityDescription = (
      // <TextField
      //   id="activityDescription"
      //   multiline={true}
      //   styles={Constants.textFieldAreaStyle}
      //   placeholder= {this._translation.description}
      //   value={this.state.description}
      //   onChange={this.descriptionChange}
      //   onRenderLabel={onRenderLabelNew(
      //     this.props.commonProps,
      //     "activityDescription",
      //     this._translation.description,
      //     "Activity description tool tip",
      //     false,
      //     true
      //   )}
      // ></TextField>
      <RichTextEditor
        // id="activityDescription"
        // multiline={true}
        // styles={Constants.textFieldAreaStyle}
        placeholder={this._translation.description}
        value={this.state.description}
        onChange={this.descriptionChange}
        toolbarConfig={Constants.toolbarRichTextEditor}
        className={"RichTextboxCustom"}
        // onRenderLabel={onRenderLabelNew(
        //   this.props.commonProps,
        //   "activityDescription",
        //   this._translation.description,
        //   "Activity description tool tip",
        //   false,
        //   true
        // )}
      ></RichTextEditor>
    );

    const jsxActivityCalendar = (
      <Dropdown
        id="activityCalendar"
        options={this.state.calendarOptions}
        placeholder={
          !this.state.calendarsLoaded
            ? this._translation.loadingCalendar
            : this._translation.selectCalendar
        }
        onChange={this.calendarChange}
        selectedKey={this.state.calendar}
        styles={Constants.dropdownStyle}
        onRenderLabel={onRenderLabelNew(
          this.props.commonProps,
          "activityCalendar",
          this._translation.activityCalendar,
          this._translation.activityCalendarTooltip,
          true,
          false
        )}
      />
    );

    const jsxActivityCategory = (
      <Dropdown
        id="activityCategory"
        options={this.state.categoryOptions.filter((x) => +x.key > 0)}
        placeholder={
          !this.state.categoriesLoaded
            ? this._translation.loadingCategories
            : this._translation.selectCategory
        }
        // multiSelect={true}
        // selectedKeys={this.state.categories}
        onChange={this.categoryChange}
        styles={Constants.dropdownStyle}
        onRenderLabel={onRenderLabelNew(
          this.props.commonProps,
          "activityCategory",
          this._translation.activityCategory,
          this._translation.activityCategoryTooltip,
          true,
          false
        )}
      />
    );

    const jsxActivityResponsibility = (
      <Dropdown
        id="activityResponsibility"
        options={this.state.responsibilityOptions.filter((x) => +x.key > 0)}
        placeholder={
          !this.state.responsibilitiesLoaded
            ? this._translation.loadingResponsibilities
            : this._translation.selectResponsibility
        }
        // multiSelect={true}
        // selectedKeys={this.state.responsibilities}
        onChange={this.responsibilityChange}
        styles={Constants.dropdownStyle}
        onRenderLabel={onRenderLabelNew(
          this.props.commonProps,
          "activityResponsibility",
          this._translation.activityResponsibility,
          this._translation.activityResponsibilityTooltip,
          true,
          false
        )}
      />
    );

    const jsxActivitySensibility = (
      <Dropdown
        id="activitySensibility"
        options={this.state.sensibilityOptions}
        placeholder={
          !this.state.sensibilitiesLoaded
            ? this._translation.loadingSensibilities
            : this._translation.selectSensibility
        }
        onChange={this.sensibilityChange}
        selectedKey={this.state.sensibility}
        styles={Constants.dropdownStyle}
        onRenderLabel={onRenderLabelNew(
          this.props.commonProps,
          "activitySensibility",
          this._translation.activitySensibility,
          this._translation.activitySensibilityTooltip,
          false,
          false
        )}
      />
    );

    const jsxAlarm = (
      <Dropdown
        id="activityAlarm"
        options={this.alarmOptions}
        selectedKey={this.state.alarm}
        onChange={this.alarmOnChange}
        styles={Constants.dropdownStyle}
        onRenderLabel={onRenderLabelNew(
          this.props.commonProps,
          "activityAlarm",
          this._translation.activityAlarm,
          this._translation.activityAlarmTooltip,
          false,
          false
        )}
      />
    );

    const jsxAlarmResources = (
      <>
        <Label htmlFor="alarmResourcesActivity" styles={Constants.labelStyle}>
          {this._translation.alarmResourcesActivity}
        </Label>
        <TagPicker
          ref={this.alarmResourcePicker}
          pickerSuggestionsProps={this.pickerSuggestionsProps}
          onItemSelected={this.checkAlarmResourceSelectedItem}
          removeButtonAriaLabel={this._translation.remove}
          onResolveSuggestions={this.filterAlarmResources}
          resolveDelay={300}
          onChange={this.pickerAlarmResourceMultipleItemChange}
          styles={Constants.pickerStyle}
        ></TagPicker>
      </>
    );

    const jsxActivityTitle = (
      <TextField
        id="activityTitle"
        styles={Constants.textFieldStyle}
        maxLength={254}
        placeholder={this._translation.typeHere}
        value={this.state.name}
        onChange={this.titleChange}
        onRenderLabel={onRenderLabelNew(
          this.props.commonProps,
          "activityTitle",
          this._translation.activityTitle,
          "Activity title tool tip",
          true,
          true
        )}
      ></TextField>
    );

    const associatedMacroCheck = (
      <Checkbox
        id="associatedMacro"
        label={this._translation.associateMacro}
        onChange={this.associatedMacroChange}
        styles={Constants.checkBoxStyle}
        checked={this.state.showMacro}
        onRenderLabel={onRenderLabelNew(
          this.props.commonProps,
          "associatedMacro",
          this._translation.associateMacro,
          "",
          false,
          true
        )}
      />
    );

    const jsxAssign = (
      <>
        <Label htmlFor="assignActivity" styles={Constants.labelStyle}>
          {this._translation.assignActivity}
        </Label>
        <TagPicker
          ref={this.resourcePicker}
          pickerSuggestionsProps={this.pickerSuggestionsProps}
          onItemSelected={this.checkResourceSelectedItem}
          removeButtonAriaLabel={this._translation.remove}
          onResolveSuggestions={this.filterAssignActivity}
          resolveDelay={300}
          onChange={this.pickerResourceMultipleItemChange}
          styles={Constants.pickerStyle}
        ></TagPicker>
      </>
    );

    const jsxAddExternalResesource = (
      <>
        <Checkbox
          id="addExternalResesource"
          label={this._translation.addExternalResource}
          onChange={this.addExternalResourceChange}
          styles={Constants.checkBoxStyle}
          onRenderLabel={onRenderLabelNew(
            this.props.commonProps,
            "addExternalResesource",
            this._translation.addExternalResource,
            "",
            false,
            true
          )}
        />
      </>
    );
    const jsxSpoc = (
      <Spoc
        commonProps={this.props.commonProps}
        styles={Constants.pickerStyle}
        onChange={this.pickerSpocChange}
        values={this.state.selectedSpoc}
        calendar={this.state.calendar}
      ></Spoc>
    );
    const jsxRangeCalendar = (
      <>
        <CustomLabel
          commonProps={this.props.commonProps}
          controlId="rangeCalendar"
          label={this._translation.timePeriod}
          tooltiptext="Time period"
          required={true}
          disableTooltip={false}
        ></CustomLabel>
        <RangeCalendar
          commonProps={this.props.commonProps}
          showTime={true}
          disableTime={false}
          onChange={this.rangeCalendarChange}
          value={this.state.rangeDateValue}          
        ></RangeCalendar>
      </>
    );
    const onSave = this.state.btnDialog ? this.onShowDialog : this.onSaveMicro;
    const jsxConfirmDialog = this.state.showDialog && (
      <Dialog
        hidden={!this.state.showDialog}
        onDismiss={() => this.setState({ showDialog: false })}
        modalProps={this.confirmDialogProps}
        dialogContentProps={this.confirmDialogContentProps()}
      >
        <DialogFooter>
          <PrimaryButton
            alt={this._translation.confirm}
            onClick={this.onSaveMicro}
            text={this._translation.confirm}
          />
          <DefaultButton
            alt={this._translation.cancel}
            onClick={() => {
              this.setState({ showDialog: false });
            }}
            text={this._translation.cancel}
          />
        </DialogFooter>
      </Dialog>
    );
    return (
      <React.Fragment>
        <LoaderComponent
          commonProps={this.props.commonProps}
          isLoading={this.state.isSaving}
          label={this._translation.waiting}
        />

        <div role="ActivityConfiguration"
          className={this.props.className + " panel-add-activity"}
          style={panelStyle}
        >
          <div className="ms-Grid" dir="ltr">
            <div
              className={
                (this.state.calendarOptions.length > 1 ? "" : "display-none") +
                " ms-Grid-row"
              }
            >
              <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                {jsxActivityCalendar}
              </div>
            </div>
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                {jsxRangeCalendar}
              </div>
            </div>
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                {jsxActivityTitle}
              </div>
            </div>
            {titleError}
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                {jsxActivityDescription}
              </div>
            </div>

            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                {jsxActivityCategory}
              </div>
            </div>
            {categoryError}

            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                {jsxActivityResponsibility}
              </div>
            </div>
            {responsabilityError}

            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                {jsxActivitySensibility}
              </div>
            </div>

            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                {jsxAssign}
              </div>
            </div>

            <div className="ms-Grid-row briefPadding">
              <div className="ms-Grid-col">
                {/* <Label htmlFor="associatedBrief" styles={Constants.labelStyle}>Associate brief</Label> */}
                {jsxAddExternalResesource}
              </div>
            </div>

            {isExternalResourceVisibile && this.renderExternalResource()}

            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                <Label htmlFor="chooseSpoc" styles={Constants.labelStyle}>
                  {this._translation.chooseSpoc}
                </Label>
                {jsxSpoc}
              </div>
            </div>
            <div className="ms-Grid-row macroPadding">
              <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                {associatedMacroCheck}
              </div>

              <div className="ms-Grid-col ms-sm6 ms-md12 ms-lg6 toogleMacro">
                {showMacro && (
                  <Toggle
                    label={this._translation.filterMacro}
                    defaultChecked={false}
                    onText={this._translation.yes.toUpperCase()}
                    offText={this._translation.no.toUpperCase()}
                    disabled={this.state.rangeDateValue ? false : true}
                    onChange={this.filterMacroChange}
                  />
                )}
              </div>
            </div>
            {showMacro && this.renderMacro()}

            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">{jsxAlarm}</div>
            </div>
            {this.showAlarmResources && (
              <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                  {jsxAlarmResources}
                </div>
              </div>
            )}

            <div className="ms-Grid-row">
              <div></div>
            </div>
            <DeliverablesOnNewMicro
              commonProps={this.props.commonProps}
              onDeliverablesListChange={(files: File[]) => {
                // console.log(files);
                this.setState({ deliverables: files });
              }}
              showMessage={(
                title: string,
                description: string,
                type: ToastNotificationType
              ) => {
                this.props.commonProps.toastComponent?.showMessage(
                  title,
                  description,
                  type
                );
              }}
            ></DeliverablesOnNewMicro>
            <div className="ms-Grid-row">
              <div></div>
            </div>
            {showError && jsxMessageBar}
            <div className="ms-Grid-row commandFooter">
              <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                <DefaultButton
                  alt={this._translation.cancel}
                  onClick={this.closePanel}
                  styles={Constants.iStandardButton}
                >
                  {this._translation.cancel}
                </DefaultButton>
                <PrimaryButton
                  alt={this._translation.addToCalendar}
                  onClick={onSave}
                  disabled={!this.canSave}
                  //disabled={false}
                  styles={Constants.iPrincipalButton}
                >
                  {this._translation.addToCalendar}
                </PrimaryButton>
              </div>
            </div>
          </div>
        </div>
        {jsxConfirmDialog}
      </React.Fragment>
    );
  }

  private confirmDialogContentProps(): IDialogContentProps {
    return {
      type: DialogType.normal,
      title: this._translation.warningDateRangeTitle,
      subText: this._translation.warningDateRangeSubTitle,
    };
  }

  private messageBarType(): MessageBarType | undefined {
    return this.state.messageType;
  }

  private closePanel(): void {
    this.props.hideModalCallback();
  }

  rangeCalendarChange(newValue?: IDateRangeValue | undefined) {
    if (newValue) {
      this.setState(
        {
          dateFrom: newValue.fromDate,
          dateTo: newValue.toDate,
          rangeDateValue: newValue,
        },
        async () => await this.reloadAfterDateChanges()
      );
    } else {
      this.setState(
        {
          dateFrom: undefined,
          dateTo: undefined,
          rangeDateValue: undefined,
        },
        async () => await this.reloadAfterDateChanges()
      );
    }
  }

  private async reloadAfterDateChanges() {
    this.compareMicroMacroDate();
    await this.loadMacroAsync();
  }

  private associatedMacroChange(
    ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
    checked?: boolean | undefined
  ) {
    const checkValue: boolean = checked ? true : false;
    this.setState({ showMacro: checkValue }, () =>
      this.compareMicroMacroDate()
    );
  }
}
