import * as React from "react";
import $ from "jquery";

import "bootstrap/dist/css/bootstrap.css";

import "@fluentui/react/dist/css/fabric.min.css";

import { initializeIcons } from "@uifabric/icons";

import { DeliverablesTranslation } from "../../../Translations/Deliverables.Translation";
import { Utility } from "../../../Utility/Utility";
import "../../../Utility/DateExtensions";

// import "./DeliverablesComponent.scss";
import styles from "./VersionHistoryComponent.module.scss";
import { IDeliverable, IDeliverableVersion } from "../../../Models/Deliverable";
import {
  ContextualMenu,
  DefaultButton,
  DetailsList,
  DetailsListLayoutMode,
  DetailsRow,
  DirectionalHint,
  IColumn,
  IconButton,
  IContextualMenuItem,
  IContextualMenuProps,
  IDetailsListProps,
  Label,
  Modal,
  PersonaSize,
  SelectionMode,
} from "@fluentui/react";
import { IActivityAllAttributes } from "../../../Models/Activity";
import { IBaseProperties } from "../../../Models/IBaseProperties";
import { IDeliverableClient } from "../../../Clients/IDeliverableClient";
import { LoaderComponent } from "../LoaderComponent/LoaderComponent";
import { ManageResourcesComponent } from "../ManageResourcesComponent/ManageResourcesComponent";
import { PanelActivityHeaderComponent } from "../PanelActivityHeaderComponent/PanelActivityHeaderComponent";
import {
  ToastNotificationPosition,
  ToastNotificationType,
} from "../../../Models/ToastNote";
import { IActivityClient } from "../../../Clients/IActivityClient";

export interface IVersionHistoryComponentProps extends IBaseProperties {
  activityId?: number;
  briefId?: number;
  deliverableId: number;
  fileName: string;
  canUpdateVersion: boolean;
  hideHistory: () => void;
  onRenderModalHeader: () => any;
  hideTitle: boolean;
  calendar: number;
}

export interface Result {
  message: string;
  result: boolean;
}

export interface IVersionHistoryComponentState {
  isLoading: boolean;
  // activity?: IActivityAllAttributes;
  deliverableVersions?: IDeliverableVersion[];
  derivableVersionContextualMenuProps?: IContextualMenuProps;
  versionColumns: IColumn[];
  result?: Result;
}

export class VersionHistoryComponent extends React.Component<
  IVersionHistoryComponentProps,
  IVersionHistoryComponentState
> {
  _versionColumns: IColumn[];
  private readonly _translation: DeliverablesTranslation;
  private readonly _deliverableClient: IDeliverableClient;
  private readonly _activityClient: IActivityClient;

  constructor(props: IVersionHistoryComponentProps) {
    super(props);

    initializeIcons();
    this._translation = new DeliverablesTranslation(
      this.props.commonProps.translation
    );
    this._deliverableClient = this.props.commonProps.clientCreator.createDeliverableClient();
    this._activityClient = this.props.commonProps.clientCreator.createActivityClient();

    this._onVersionHistoryColumnClick = this._onVersionHistoryColumnClick.bind(
      this
    );
    this._renderActivityModalHeader = this._renderActivityModalHeader.bind(
      this
    );
    this._reloadHistory = this._reloadHistory.bind(this);

    this._versionColumns = [
      {
        key: "version",
        ariaLabel: "Sort by " + this._translation.version,
        name: (<div>{this._translation.version}</div>) as any,
        fieldName: "documentVersion",
        minWidth: 100,
        isResizable: true,
        onColumnClick: this._onVersionHistoryColumnClick,
        onRender: (item: IDeliverableVersion) => {
          return (
            <div className={styles.deliverableVersionsDetailsCell}>
              <div className={styles.text}>{item.documentVersion}</div>
            </div>
          );
        },
        // columnActionsMode: ColumnActionsMode.,
      },
      {
        key: "editedBy",
        ariaLabel: "Sort by " + this._translation.editBy,
        name: (<div>{this._translation.editBy}</div>) as any,
        fieldName: "editedBy",
        minWidth: 100,
        isResizable: true,
        onColumnClick: this._onVersionHistoryColumnClick,
        onRender: (item: IDeliverableVersion) => {
          return (
            <div className={styles.deliverableVersionsDetailsCell}>
              <div className={styles.TaskAssignedTo}>
                <ManageResourcesComponent
                  commonProps={this.props.commonProps}
                  // allResources={this.state.allResources}
                  listResources={item.editedBy ? [item.editedBy] : []}
                  onRenderModalHeader={this._renderActivityModalHeader}
                  saveFunction={() => {}}
                  maxLength={2}
                  personaSize={PersonaSize.size32}
                  userType={"internalResource"}
                  edit={false}
                  calendar={this.props.calendar || -1}
                />
              </div>
              {/* {item.editedBy} */}
            </div>
          );
        },
        // columnActionsMode: ColumnActionsMode.,
      },
      {
        key: "data",
        ariaLabel: "Sort by " + this._translation.data,
        name: (<div>{this._translation.data}</div>) as any,
        fieldName: "data",
        minWidth: 250,
        isResizable: true,
        onColumnClick: this._onVersionHistoryColumnClick,
        onRender: (item: IDeliverableVersion) => {
          return (
            <div className={styles.deliverableVersionsDetailsCell}>
              {item.lastModify ? Utility.FormatDate(item.lastModify) : ""}
            </div>
          );
        },
        // columnActionsMode: ColumnActionsMode.,
      },
      {
        key: "prop",
        name: "",
        fieldName: "prop",
        minWidth: 100,
        isResizable: false,
        onRender: (item: IDeliverableVersion) => {
          return (
            <div>
              <img
                src={
                  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACACAQAAABpN6lAAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADdcAAA3XAUIom3gAAAAHdElNRQfiAgEMLBkBZB4mAAACBklEQVR42u2bsXHCQBREH04gMDkqgYAmaICm6IEKKIAGHNMBBRCQYxMQyQFD4BmwheD0v7X7Lv4zdzvS6bR7f0AEQ+YsmFJRAQcO7NjwwTlkNh1TseJIfWMcWVFFT68sI5acbi7+Ok4sGUVPsxQTtr8u/jq2TKKnWoIZ+0bLr6nZM4ue7quZPLD8iwS9egpGDR/+ny9Cj/aC5cPLr6lZRk/7VVR/7Pz3vwg9+SiuWi2/pmYVPfVXMLxz7GkyjgxLT++tuABzxq1rx8z/vwCLwOoUAkwDq1MI8NxOXvw7MCguwCfvT1R/PbGDNKL8E5Cc8gIcAqstQAYBdoHVKQTYBFanQP4ofGbdunbdD59Y/ndY3hCxJYa8KQrytjjIByMgH41dSBaOlvcDbiEdjxtjjDHGGGOMMSYaGyKdk8wS6xZxU1TcFhcPRsSjMflwVDwel78g4X6B1gL04pKU+wUCq1MI4H6BwOoGuF+guADJ8XV5C1Aa9wsEVqdA/ijsfgH/DssbIrbEkDdFQd4WB/lgBOSjsQvJwlHH48YYY4wxxhhjjJHDhkjnJLPEukXcFBW3xcWDEfFoTD4cFY/H5S9IuF+gtQC9uCTlfoHA6hQCuF8gsLoB7hcoLkByfF3eApTG/QKB1SmQPwq7X8C/w/KGiC0x5E1RkLfFQT4YAflo7EKycFQ+Hv8GZi55Q1ESbvsAAAAldEVYdGRhdGU6Y3JlYXRlADIwMTgtMDItMDFUMTI6NDQ6MjUrMDE6MDD1JibVAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDE4LTAyLTAxVDEyOjQ0OjI1KzAxOjAwhHueaQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAAASUVORK5CYII="
                }
                alt={this._translation.preview}
                className={styles.iconVersionProps}
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    this.setState({
                      derivableVersionContextualMenuProps: this._getDerivableVersionContextualMenuProps(
                        item.id,
                        item.documentVersion,
                        item.isCurrent
                      ),
                    });
                  }
                }}
                onClick={(ev) => {
                  this.setState({
                    derivableVersionContextualMenuProps: this._getDerivableVersionContextualMenuProps(
                      item.id,
                      item.documentVersion,
                      item.isCurrent,
                      ev
                    ),
                  });
                }}
                // alt={item.fileType + " file icon"}
              />
            </div>
          );
        },
      },
    ];

    this.state = {
      isLoading: false,
      versionColumns: this._versionColumns,
      result: undefined,
    };
  }

  componentDidMount() {
    this._reloadHistory();
  }

  private _reloadHistory() {
    if (this.props.activityId && this.props.deliverableId > 0) {
      this.setState({ isLoading: true });

      this._deliverableClient
        .getAttachmentVersions(this.props.activityId, this.props.deliverableId)
        .then((versions) => {
          if (versions) {
            this.setState({
              deliverableVersions: versions,
              isLoading: false,
            });
          } else {
            this.setState({ isLoading: false });
            this.props.commonProps.toastComponent?.showMessage(
              this._translation.error,
              this._translation.getDeliverableError,
              ToastNotificationType.ERROR
            );
          }
        });
    }
  }

  private _renderActivityModalHeader() {
    return (
      <PanelActivityHeaderComponent
        commonProps={this.props.commonProps}
        // item={this.props.item}
        text={this._translation.editBy}
        isMacro={false}
        showContextMenu={false}
        showStatus={false}
        showType={false}
        showManage={false}
      ></PanelActivityHeaderComponent>
    );
  }

  private _onRenderVersionRow: IDetailsListProps["onRenderRow"] = (props) => {
    var result = null;

    if (props) {
      result = (
        <div className={styles.DetailsRowNoMicro}>
          <DetailsRow
            {...props}
            key={"Deliverables" + props.item.id}
            className={styles.DeliverableVersionsRow + " AAAAAA ms-MainRow"}
          />
        </div>
      );
    }

    return result;
  };

  public render() {
    const { derivableVersionContextualMenuProps } = this.state;

    return (
      <div className={styles.VersionHistoryComponent}>
        <LoaderComponent
          commonProps={this.props.commonProps}
          isLoading={this.state.isLoading}
        />

        <Modal
          titleAriaId="Modal Version History"
          isOpen={this.state.deliverableVersions != undefined}
          onDismiss={(ev) => {
            ev?.preventDefault();
            this.props.hideHistory();
            this.setState({ deliverableVersions: undefined });
          }}
          isBlocking={true}
          containerClassName={styles.modalContainer}
          className={styles.ModalAssignedResources}
        >
          <div role="header" className={styles.modalHeader}>
            {this.props.onRenderModalHeader()}
            <IconButton
              className={styles.ModalButton}
              iconProps={{ iconName: "Cancel" }}
              aria-label="Close"
              ariaLabel={this._translation.close}
              onClick={(ev: { preventDefault: () => void }) => {
                ev?.preventDefault();

                this.props.hideHistory();
                this.setState({ deliverableVersions: undefined });
              }}
            />
          </div>
          <hr className={this.props.hideTitle ? styles.hrHeader : ""} />

          <div
            className={
              this.props.hideTitle
                ? styles.modalBody
                : styles["modalBody-padding"]
            }
          >
            {!this.props.hideTitle && (
              <div
                className={
                  this.props.hideTitle
                    ? styles.divDeliverableVersionsList
                    : styles.divDeliverableVersionsList +
                      " " +
                      styles["divDeliverableVersionsList-margin"] +
                      " ms-Grid-col ms-sm12"
                }
              >
                <h3>{this._translation.getVersionHistory}</h3>
              </div>
            )}
            <div
              role="itemsList"
              className={
                this.props.hideTitle
                  ? styles.divDeliverableVersionsList
                  : styles.divDeliverableVersionsList +
                    " " +
                    styles["divDeliverableVersionsList-margin"] +
                    " ms-Grid-col ms-sm12"
              }
            >
              <DetailsList
                items={
                  this.state.deliverableVersions
                    ? this.state.deliverableVersions
                    : []
                }
                compact={true}
                columns={this.state.versionColumns}
                selectionMode={SelectionMode.none}
                onItemInvoked={(item) => {
                  this.setState({
                    derivableVersionContextualMenuProps: this._getDerivableVersionContextualMenuProps(
                      item.id,
                      item.documentVersion,
                      item.isCurrent
                    ),
                  });
                }}
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                onRenderRow={this._onRenderVersionRow}
              />
              {derivableVersionContextualMenuProps && (
                <ContextualMenu {...derivableVersionContextualMenuProps} />
              )}

              {this.state.result && (
                <Label
                  className={
                    this.state.result.result
                      ? styles.labelOKResult
                      : styles.labelKOResult
                  }
                >
                  {this.state.result.message}
                </Label>
              )}
            </div>
          </div>
          <hr />
          <div role="footer" className={styles.modalFooter}>
            <div className={styles.ModalFooterRow + " row"}>
              <div>
                <DefaultButton
                  text={this._translation.cancel}
                  className={"btn btn-dismiss"}
                  onClick={(ev: { preventDefault: () => void }) => {
                    ev?.preventDefault();

                    this.props.hideHistory();
                    this.setState({ deliverableVersions: undefined });
                  }}
                />
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }

  private _onDerivableVersionContextualMenuDismissed = (
    ev: any,
    dp: any
  ): void => {
    this.setState({
      derivableVersionContextualMenuProps: undefined,
    });
  };

  private _getDerivableVersionContextualMenuProps(
    itemId: number,
    version: string,
    isCurrent: boolean,
    ev?: React.MouseEvent<HTMLElement>
  ): IContextualMenuProps {
    var items: IContextualMenuItem[] = [
      {
        key: "GetVersion",
        name: this._translation.downloadVersion,
        // iconProps: { iconName: "SortUp" },
        disabled: !this.props.canUpdateVersion,
        canCheck: false,
        // checked: column.isSorted && !column.isSortedDescending,
        onClick: () => {
          if (this.props.activityId && this.props.deliverableId) {
            this._downloadAttachmentVersionActivity(
              this.props.activityId,
              this.props.deliverableId,
              version,
              this.props.fileName
            );
          }
        },
      },
      {
        key: "RestoreVersion",
        name: this._translation.restoreVersion,
        // iconProps: { iconName: "SortUp" },
        disabled: isCurrent || !this.props.canUpdateVersion,
        canCheck: false,
        // checked: column.isSorted && !column.isSortedDescending,
        onClick: () => {
          this.setState(
            {
              result: undefined,
              isLoading: true,
            },
            () => {
              if (this.props.activityId && this.props.deliverableId) {
                this._deliverableClient
                  .restoreAttachmentVersion(
                    this.props.activityId,
                    this.props.deliverableId,
                    version
                  )
                  .then((restore) => {
                    if (restore == undefined || !restore) {
                      this.setState(
                        {
                          result: {
                            result: false,
                            message: this._translation.restoreVersionError,
                          },
                        },
                        () => {
                          this._reloadHistory();
                        }
                      );
                      // this.props.commonProps.toastComponent?.showMessage(
                      //   this._translation.error,
                      //   this._translation.getDeliverableError,
                      //   ToastNotificationType.ERROR
                      // );
                    } else {
                      // this.props.commonProps.toastComponent?.showMessage(
                      //   this._translation.ok,
                      //   this._translation.success,
                      //   ToastNotificationType.SUCCESS,
                      //   false,
                      //   ToastNotificationPosition.TOPCENTER
                      // );
                      this.setState(
                        {
                          result: {
                            result: true,
                            message: this._translation.restoreVersionSuccess,
                          },
                        },
                        () => {
                          this._reloadHistory();
                        }
                      );
                    }
                  });
              }
            }
          );
        },
      },
      // {
      //   key: "DeleteVersion",
      //   name: this._translation.deleteVersion,
      //   // iconProps: { iconName: "SortUp" },
      //   disabled: !this.state.activity?.permissions.canDeleteDeliverables,
      //   canCheck: false,
      //   // checked: column.isSorted && !column.isSortedDescending,
      //   onClick: () => {},
      // },
    ];

    return {
      items: items,
      target: ev?.currentTarget as HTMLElement,
      directionalHint: DirectionalHint.bottomLeftEdge,
      gapSpace: 10,
      isBeakVisible: false,
      onDismiss: this._onDerivableVersionContextualMenuDismissed,
    };
  }

  private _downloadAttachmentVersionActivity(
    idActivity: number,
    idDeliverable: number,
    itemId: string,
    fileName: string
  ) {
    this._deliverableClient
      .downloadAttachmentVersion(idActivity, idDeliverable, itemId)
      .then((file) => {
        if (file == undefined) {
          this.props.commonProps.toastComponent?.showMessage(
            this._translation.error,
            this._translation.getDeliverableError,
            ToastNotificationType.ERROR
          );
        } else {
          //var blob = new Blob([file], { type: "application/pdf" });
          var binary_string = window.atob(file);
          var len = binary_string.length;
          var bytes = new Uint8Array(len);
          for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
          }
          var link = document.createElement("a");
          var type = "application/octet-stream";

          var blob = new Blob([bytes], { type: type });
          link.href = window.URL.createObjectURL(blob);
          link.download = fileName ? fileName : "doc";
          link.click();
        }
      });
  }

  private _onVersionHistoryColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn
  ): void => {
    const { versionColumns, deliverableVersions } = this.state;
    if (deliverableVersions) {
      const newColumns: IColumn[] = versionColumns.slice();
      const currColumn: IColumn = newColumns.filter(
        (currCol) => column.key === currCol.key
      )[0];
      newColumns.forEach((newCol: IColumn) => {
        if (newCol === currColumn) {
          currColumn.isSortedDescending = !currColumn.isSortedDescending;
          currColumn.isSorted = true;
        } else {
          newCol.isSorted = false;
          newCol.isSortedDescending = true;
        }
      });
      const newItems = Utility.CopyAndSort(
        deliverableVersions,
        currColumn.fieldName!,
        currColumn.isSortedDescending
      );
      this.setState({
        versionColumns: newColumns,
        deliverableVersions: newItems,
      });
    }
  };
}
