import React from "react";
import './MonthCalendar.css';
import styles from "./MonthCalendar.module.scss";
import { DateHelper } from "../../../../Models/DateHelper";

export interface IWeekDaysProps {}
export interface IWeekDaysState {}

export class WeekDays extends React.Component<IWeekDaysProps, IWeekDaysState> {
    
    private _weekDayShortNames = DateHelper.weekDayShortName;
    constructor(props: IWeekDaysProps) {
        super(props);                
    }
    
    private renderWeekDays()
    {        
        const weekDay = function (shortWeekDayName: string) {
            return <div key={shortWeekDayName} className={styles["month-calendar-day-name"]}>
            {shortWeekDayName}
        </div>;
          };
        const weekDays = this._weekDayShortNames;
        
        return <React.Fragment>
                    {weekDays.map(element => {
                        return weekDay(element)
                    })}                        
                </React.Fragment>
    }

    render() {
        return (
            <div className={styles["month-calendar-day-header-names"]}>
                {this.renderWeekDays()}
            </div>
        );
    }
}

export const WeekDaysMemo = React.memo(WeekDays, ()=> true);

