import { UserGuideDTO } from "./../Models/DTO/UserGuideDTO";
import { VideoTutorialDTO } from "./../Models/DTO/VideoTutorialDTO";
import { FaqDTO } from "./../Models/DTO/FaqDTO";
import { FAQ } from "./../Models/FAQ";
import { VideoTutorial } from "./../Models/VideoTutorial";
import { UserGuide } from "./../Models/UserGuide";
import { SupportPagesEnum, SupportCounter } from "./../Models/SupportPage";
import { ISupportClient } from "./ISupportClient";
import { UserDTO } from "../Models/DTO/UserDTO";
import { UserInfo } from "../Models/User";
import { APIUtility } from "../Utility/APIUtility";
import usermock from "../Mocks/DTO/UserDTOMock.json";
import { NoContent } from "../Models/NoContent";
import { HttpError } from "../Models/HttpResponse";
import { ErrorClient } from "./ErrorClient";
import { SupportLanguage } from "../Models/SupportPage";

export class SupportClient implements ISupportClient {
  private readonly _http: APIUtility = new APIUtility();

  public async getRoles(
    section: SupportPagesEnum,
    language: SupportLanguage
  ): Promise<string[]> {
    let apiUtility = new APIUtility();

    var roles = await apiUtility.getSupportRoles(section, language);
    if (roles) {
      return roles;
    } else {
      return [];
    }
  }

  public getLanguageLabel(lan: SupportLanguage): string {
    switch (lan) {
      case SupportLanguage.English:
        return "English";
      case SupportLanguage.Italian:
        return "Italian";
      case SupportLanguage.Spanish:
        return "Spanish";
    }
  }

  public async getCounters(
    section: SupportPagesEnum,
    languages: SupportLanguage[],
    role: string
  ): Promise<SupportCounter | null> {
    let apiUtility = new APIUtility();

    var languagesToString: string[] = languages.map((x) => {
      return this.getLanguageLabel(x);
    });

    var counters = await apiUtility.getSupportCounters(
      section,
      languagesToString.join("_"),
      role
    );
    if (counters) {
      return counters;
    } else {
      return null;
    }
  }

  public async getItems(
    section: SupportPagesEnum,
    language: SupportLanguage,
    role: string
  ): Promise<UserGuide[] | VideoTutorial[] | FAQ[]> {
    let apiUtility = new APIUtility();

    var languageToString = this.getLanguageLabel(language);

    var items = await apiUtility.getSupportItems(
      section,
      languageToString,
      role
    );
    if (items) {
      switch (section) {
        case SupportPagesEnum.Faq: {
          return (items as FaqDTO[]).map((x) => {
            return {
              Id: x.Id,
              Question: x.question,
              Answer: x.answer,
              Role: x.Role,
            };
          });
        }
        case SupportPagesEnum.UserGuide: {
          return (items as UserGuideDTO[]).map((x) => {
            return {
              Id: x.id,
              URL: x.url,
              Role: x.role,
              title: x.title,
              mainText: x.mainText,
              type: x.type,
              language: x.language,
              favourite: x.favourite,
              favId: x.favid,
            };
          });
        }
        case SupportPagesEnum.VideoTutorials: {
          return (items as VideoTutorialDTO[]).map((x) => {
            return {
              Id: x.id,
              URL: x.url,
              Role: x.role,
              title: x.title,
              isForDesktop: x.isForDesktop,
              isForMobile: x.isForMobile,
              type: x.type,
              language: x.language,
              favourite: x.favourite,
              favId: x.favId,
            };
          });
        }
        default:
          return [];
      }
    } else {
      return [];
    }
  }

  public async getFavourites(
    section: SupportPagesEnum,
    language: SupportLanguage,
    role: string
  ): Promise<UserGuide[] | VideoTutorial[] | FAQ[]> {
    let apiUtility = new APIUtility();

    var languageToString = this.getLanguageLabel(language);

    var items = await apiUtility.getSupportItems(
      section,
      languageToString,
      role
    );

    if (items) {
      switch (section) {
        case SupportPagesEnum.Faq: {
          return (items as FaqDTO[]).map((x) => {
            return {
              Id: x.Id,
              Question: x.question,
              Answer: x.answer,
              Role: x.Role,
            };
          });
        }
        case SupportPagesEnum.UserGuide: {
          return (items as UserGuideDTO[])
            .filter((x) => x.favourite)
            .map((x) => {
              return {
                Id: x.id,
                URL: x.role,
                Role: x.role,
                title: x.title,
                mainText: x.mainText,
                type: x.type,
                language: x.language,
                favourite: x.favourite,
                favId: x.favid,
              };
            });
        }
        case SupportPagesEnum.VideoTutorials: {
          return (items as VideoTutorialDTO[])
            .filter((x) => x.favourite)
            .map((x) => {
              return {
                Id: x.id,
                URL: x.url,
                Role: x.role,
                title: x.title,
                isForDesktop: x.isForDesktop,
                isForMobile: x.isForMobile,
                type: x.type,
                language: x.language,
                favourite: x.favourite,
                favId: x.favId,
              };
            });
        }
        default:
          return [];
      }
    } else {
      return [];
    }
  }

  public async AddFavourites(docsId: number[]): Promise<boolean> {
    let apiUtility = new APIUtility();

    var ok = await apiUtility.AddFavourites(docsId.map((x) => x.toString()));

    if (ok) return ok;
    else return false;
  }

  public async RemoveFavourites(docsId: number[]): Promise<boolean> {
    let apiUtility = new APIUtility();

    var ok = await apiUtility.RemoveFavourites(docsId.map((x) => x.toString()));

    if (ok) return ok;
    else return false;
  }
}
