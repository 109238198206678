import React from "react";
import classNames from "classnames";
import './MonthCalendar.css';
import styles from "./MonthCalendar.module.scss";
import { notify } from '../BigCalendar/utils/helpers';

export enum DisplayType {
    Empty = 1,
    WithActivities,
    WithoutActivities
  }

export interface IDayCellProps {    
  content: string;
  currentDay: boolean;  
  selectedDay: boolean;
  cellType: DisplayType;
  date: Date | null;
  onDrillDown: any | null;
}
export interface IDayCellState {  
}

export class DayCell extends React.Component<IDayCellProps, IDayCellState> {
    
    private handleHeaderClick (date: Date, view: any, e: any)  {
        e.preventDefault()
        notify(this.props.onDrillDown, [date, view])
    }


    private getOuterClasses(): string
    {
        const classes = classNames({
            'month-calendar-day': true,
            'month-calendar-day-pointer': (this.props.cellType === DisplayType.WithActivities)            
          });
        
        return classes;
    }

    private getHasActivityClasses(): string
    {
        const classes = classNames({
            
            'circle-yes-activities': (this.props.cellType === DisplayType.WithActivities),
            'circle-no-activities': (this.props.cellType === DisplayType.WithoutActivities)
          });
        
        return classes;
    }

    private getOuterDayClasses(): string
    {
        const classes = classNames({
            'month-calendar-dayinternal': true,
            'month-calendar-today': (this.props.currentDay),
            'month-calendar-selected-day' : this.props.selectedDay
          });
        
        return classes;
    }

    private getHasActivityContainerClasses(): string
    {
        const classes = classNames({
            
            'cicle-container': (this.props.cellType === DisplayType.WithActivities || this.props.cellType === DisplayType.WithoutActivities)            
          });
        
        return classes;
    }

    render() {
        const dayContainer = this.contentElement();

        return (
            <div className={this.getOuterClasses()}>	                            
                {dayContainer}                
                <div className={this.getHasActivityContainerClasses()}>
		            <span className={this.getHasActivityClasses()}>
		            </span>
	            </div>
            </div>
          );
    }  
    
    private contentElement(): JSX.Element {
        const hasContent = this.props.content !== "";
        
        const contentContainer :JSX.Element = 
            <div className={this.getOuterDayClasses()}>
                <span className={styles["month-calendar-day-number"]}>
                    {this.props.content}
                </span>
            </div>;

        const withDrillDown:JSX.Element = <a href="#" onClick={e => this.handleHeaderClick(this.props.date as Date, 'day', e)}>
            {contentContainer}
        </a>;        

        return hasContent ? withDrillDown : contentContainer;        
    }
};