export interface User
{
    id: string;
    userPrincipalName: string;
    displayName: string;
    initials: string; 
    // group: string;
}

export interface UserInfo
{
    initials: string;
    id: string;
    username: string;
    displayName: string;
    group: string;    
    businessUnit?: string;
}
export enum PersonaStyle {
    NORMAL = "normal",
    BLUE = "blue"
}

// export interface UserInfo
// {
//     initials: string;
//     id: string;
//     serialNumber: string;
//     username: string;
//     displayName: string;
    
// }