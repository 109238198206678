import React, { useState } from "react";
import {DayCell, DisplayType} from './DayCell';

import {
    TooltipHost,
    TooltipDelay,
    DirectionalHint,
    ITooltipProps,
    ITooltipHostStyles,
  } from 'office-ui-fabric-react/lib/Tooltip';
import {ActivityThinDetail}  from './ActivityThinDetail';
import { red, yellow } from "@material-ui/core/colors";

export interface IDayActivityToolTipProps {  
  activities: any;
  content: string;
  currentDay: boolean;
  selectedDay: boolean;
  date: Date | null;
  onDrillDown: any;
}
export interface IDayActivityToolTipState {
  activities: any[];  
}

const hostStyles: Partial<ITooltipHostStyles> = { root: { display: 'inline-block' } };

export class DayActivityToolTip extends React.Component<IDayActivityToolTipProps, IDayActivityToolTipState> {
  
    constructor(props: IDayActivityToolTipProps) {
        super(props);
        this.state = {
          activities: props.activities,
        };                    
    }


    
    get getDate(): Date
    {
      return this.props.date ? this.props.date : new Date();
    }

    tooltipProps: ITooltipProps = {
    onRenderContent: () => (
      <React.Fragment>          
        {this.props.activities && (<ActivityThinDetail activities={this.props.activities} date={this.getDate}></ActivityThinDetail>)}
      </React.Fragment>
    ),    
      // calloutProps: {
      //   styles: {
      //     beak: { background: 'red'  },
      //     beakCurtain: { background: 'blue' },
      //     calloutMain: { background: 'yellow' },
      //   },
      // },
    };   

    render() {
        return (
            <TooltipHost
              tooltipProps={this.tooltipProps}
              delay={TooltipDelay.zero}            
              directionalHint={DirectionalHint.bottomCenter}
              styles={hostStyles}     
              key={this.props.date?.toUTCString()}
            >   
              <DayCell date={this.props.date} currentDay={this.props.currentDay} selectedDay={this.props.selectedDay} onDrillDown={this.props.onDrillDown} content={this.props.content} cellType={DisplayType.WithActivities}  ></DayCell>
            </TooltipHost>
          );
    }  
    
};