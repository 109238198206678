import * as React from "react";
import parse from "html-react-parser";
import RichTextEditor, { EditorValue } from "react-rte";
import { BriefTranslation } from "../../../../Translations/Brief.Translation";
import { BriefFileList } from "./BriefFileList";
import { BriefHistory } from "../BriefHistory/BriefHistory";
import { BriefLinks } from "./BriefLinks";
import { Constants } from "../../../../Utility/Constants";
import styles from "./BriefDetail.module.scss";
import { UserPicker } from "./UserPicker/UserPicker";
import {
  BaseButton,
  Button,
  ContextualMenu,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  FontWeights,
  getTheme,
  IBasePickerSuggestionsProps,
  IconButton,
  IDialogContentProps,
  IDialogStyles,
  IDragOptions,
  IModalProps,
  ITag,
  mergeStyleSets,
  MessageBar,
  MessageBarType,
  Modal,
  PersonaSize,
  PrimaryButton,
  Spinner,
  Text,
  TextField,
} from "@fluentui/react";
import { IBriefClient } from "../../../../Clients/IBriefClient";
import {
  IBaseProperties,
  ICommonProperties,
  IRoute,
  Page,
} from "../../../../Models/IBaseProperties";
import { IBriefDetail, IFile } from "../../../../Models/Brief";
import { IUserClient } from "../../../../Clients/IUserClient";
import { ToastNotificationType } from "../../../../Models/ToastNote";
import { Utility } from "../../../../Utility/Utility";
import { LoaderComponent } from "../../../Shared/LoaderComponent/LoaderComponent";
import { Security } from "../../../../Utility/Security";
import {
  INotification,
  NotificationSubType,
  NotificationType,
} from "../../../../Models/Notification";
import { CustomLabel } from "../../../Shared/CustomLabel";
import { CustomPicker } from "../../../Shared/CustomPicker/CustomPicker";
import { PersonaStyle, User, UserInfo } from "../../../../Models/User";
import { IdToken } from "msal/lib-commonjs/IdToken";
import { PanelBriefHeaderComponent } from "../../../Shared/PanelBriefHeaderComponent/PanelBriefHeaderComponent";
import { PersonaComponent } from "../../../Shared/PersonaComponent/PersonaComponent";
import { Macro } from "../../Activities/Macro";
import { bool } from "prop-types";
import { IMacro } from "../../../../Models/Activity";

const theme = getTheme();

const dragOptions: IDragOptions = {
  moveMenuItemText: "Move",
  closeMenuItemText: "Close",
  menu: ContextualMenu,
  dragHandleSelector: ".panelHeader",
};

export interface IBriefDetailProps extends IBaseProperties {
  idBrief?: number;
  brief?: IBriefDetail;
  onEditRequest(idBrief: number): void;
  onChageStatus(dBrief: number): void;
  onNavigateAfterSend(page: string): void;
  enableScroll: boolean;
  showTitleRow: boolean;
  showSend?: boolean;
  showHistory: boolean;
  onCancel(): void;
  hideNavigationAfterSend: boolean;
}
export interface IBriefDetailState {
  isLoading: boolean;
  currentUser?: User;
  brief?: IBriefDetail;
  briefFiles?: IFile[];
  showSendDialog: boolean;
  newStatus?: string;
  mailSubTitle?: string;
  showMailComponent: boolean;
  emailTO: ITag[];
  emailCC: ITag[];
  emailSubject?: string;
  emailMessage: EditorValue;
  showError: boolean;
  messageError?: string;
  showInsertDelegatePanel: boolean;
  showDelegateInsertPanel: boolean;
  showDelegateApproverPanel: boolean;
  delegateInsertTag: ITag[];
  delegateApproverTag: ITag[];
  isSaving: boolean;
  emailBodyApproverValue: EditorValue;
  emailSubjectApproverValue?: string;
  emailBodyInsertValue: EditorValue;
  emailSubjectInsertValue?: string;
  canSend: boolean;

  showMacroComponent: boolean;
}

export class BriefDetail extends React.Component<
  IBriefDetailProps,
  IBriefDetailState
> {
  private readonly _translation: BriefTranslation;
  private readonly _briefClient: IBriefClient;
  private readonly _userClient: IUserClient;

  private toTimingLabel = (timingFrom?: Date, timingTo?: Date): string => {
    if (timingFrom && timingTo) {
      return `${Utility.formatDate(
        timingFrom,
        "DD/MM/YYYY"
      )} - ${Utility.formatDate(timingTo, "DD/MM/YYYY")}`;
    } else {
      return `-`;
    }
  };

  private renderCell = (
    containerClasses: string,
    titleClasses: string,
    contentClasses: string,
    label: string,
    content?: string,
    renderAsHtml: boolean = false
  ): JSX.Element => {
    const contentValue = renderAsHtml ? parse(content ? content : "") : content;
    const value = (
      <>
        <div className={containerClasses}>
          <div>
            <Text className={titleClasses}>{label}</Text>
          </div>
          <div>
            <Text className={contentClasses}>{contentValue}</Text>
          </div>
        </div>
      </>
    );

    return value;
  };
  private _panelStyle: Partial<IDialogStyles> = {
    main: { width: "600px !important" },
  };

  private readonly approvalStatus = "approval";

  private readonly informationStatus = "information";

  private readonly rejectStatus = "reject";

  constructor(props: IBriefDetailProps) {
    super(props);

    this._briefClient = props.commonProps.clientCreator.createBriefClient();
    this._userClient = props.commonProps.clientCreator.createUserClient();
    this._translation = new BriefTranslation(props.commonProps.translation);

    this.bindEvents();

    this.initializeState(props);
  }

  async componentDidMount() {
    const { idBrief, brief } = this.props;
    var user = Security.getCurrentUserInfo();

    if (!brief && idBrief) {
      let result = await this._briefClient.getBriefById(idBrief);
      if (result && result.hasOwnProperty("id")) {
        result = result as IBriefDetail;

        if (result) {
          this.setState(
            {
              brief: result,
              briefFiles: result.files,
              isLoading: false,
              currentUser: user
                ? {
                  displayName: user.displayName,
                  id: user.id,
                  userPrincipalName: user.username,
                  initials: user.initials,
                }
                : undefined,
            },
            async () => {
              result = result as IBriefDetail;
              const readNotification: INotification = {
                id: result.id,
                name: result.title,
                type: NotificationType.Brief,
                subType: NotificationSubType.openedbrief,
                author: undefined,
                date: new Date(),
                identifier: "",
              };
              this.props.commonProps.onNotification?.next(readNotification);

              if (!this.state.briefFiles) {
                let files = await this._briefClient.getFilesByBriefId(idBrief);
                if (!files) {
                  files = [];
                }
                this.setState({
                  briefFiles: files,
                });
              }
            }
          );
        }
      } else {
        if (result) {
          this.props.commonProps.toastComponent?.showMessage(
            this._translation.error,
            this._translation.errorBriefNotFound,
            ToastNotificationType.ERROR
          );
        }
        else {
          this.props.commonProps.toastComponent?.showMessage(
            this._translation.error,
            this._translation.errorRetrieveData,
            ToastNotificationType.ERROR
          );
        }
      }
    } else {
      const { briefFiles } = this.state;
      if (!briefFiles) {
        this.setState({
          briefFiles: [],
          isLoading: false,
          currentUser: user
            ? {
              displayName: user.displayName,
              id: user.id,
              userPrincipalName: user.username,
              initials: user.initials,
            }
            : undefined,
        });
      }
    }

    this.setState({
      isLoading: false,
    });
  }

  private get dialogContentProps(): IDialogContentProps {
    const value: IDialogContentProps = {
      type: DialogType.normal,
      title: this._translation.sendBrief,
      closeButtonAriaLabel: this._translation.close,
      subText: this._translation.briefAfterSendText,
    };

    return value;
  }

  private initializeState(props: IBriefDetailProps) {
    this.state = {
      showSendDialog: false, // props.showSend ? props.showSend : false,
      isLoading: true,
      brief: props.brief,
      briefFiles: props.brief ? props.brief.files : undefined,
      showMailComponent: false,
      emailTO: [],
      emailCC: [],
      emailSubject: "",
      emailMessage: RichTextEditor.createEmptyValue(),
      showError: false,
      showInsertDelegatePanel: false,
      showDelegateInsertPanel: false,
      showDelegateApproverPanel: false,
      delegateInsertTag: [],
      delegateApproverTag: [],
      isSaving: false,
      emailBodyApproverValue: RichTextEditor.createEmptyValue(),
      emailSubjectApproverValue: "",
      emailBodyInsertValue: RichTextEditor.createEmptyValue(),
      emailSubjectInsertValue: "",
      canSend: true,
      showMacroComponent: false,
    };
  }

  private bindEvents() {
    this.onAccept = this.onAccept.bind(this);
    this.onReject = this.onReject.bind(this);
    this.onEditBrief = this.onEditBrief.bind(this);
    this.onSendBrief = this.onSendBrief.bind(this);
    this._onContactApplicantUnit = this._onContactApplicantUnit.bind(this);
    this._onRenderMailModalHeader = this._onRenderMailModalHeader.bind(this);
    this._onRenderMacroModalHeader = this._onRenderMacroModalHeader.bind(this);
    this.pickerTOChange = this.pickerTOChange.bind(this);
    this.pickerCCChange = this.pickerCCChange.bind(this);
    this.subjectChange = this.subjectChange.bind(this);
    this.mailTextChange = this.mailTextChange.bind(this);
    this.closeMessageBarError = this.closeMessageBarError.bind(this);
    this.sendMail = this.sendMail.bind(this);
    this.sendMailAsync = this.sendMailAsync.bind(this);
    this.onDelegateInsertion = this.onDelegateInsertion.bind(this);
    this.onChangeDelegateInsert = this.onChangeDelegateInsert.bind(this);
    this.onDelegateApprover = this.onDelegateApprover.bind(this);
    this.onChangeDelegateApprover = this.onChangeDelegateApprover.bind(this);
    this.delegateApprover = this.delegateApprover.bind(this);
    this.onDelegateInsert = this.onDelegateInsert.bind(this);
    this.onChangeEmailBodyApprover = this.onChangeEmailBodyApprover.bind(this);
    this.onChangeEmailBodyInsert = this.onChangeEmailBodyInsert.bind(this);
    this.emailApproverSubjectChange = this.emailApproverSubjectChange.bind(
      this
    );
    this.emailInsertSubjectChange = this.emailInsertSubjectChange.bind(this);
    this.onNavigateToMyBrief = this.onNavigateToMyBrief.bind(this);
    this.onNavigateToHome = this.onNavigateToHome.bind(this);
  }

  private onChangeEmailBodyApprover(value: EditorValue) {
    this.setState({
      emailBodyApproverValue: value,
    });
  }

  private onChangeEmailBodyInsert(value: EditorValue) {
    this.setState({
      emailBodyInsertValue: value,
    });
  }

  private onNavigateToHome(
    event: React.MouseEvent<
      | HTMLAnchorElement
      | HTMLButtonElement
      | HTMLDivElement
      | BaseButton
      | Button
      | HTMLSpanElement,
      MouseEvent
    >
  ): void {
    const route: IRoute = {
      page: Page.Dashboard,
      additionalInfo: {
        keysValues: {
          id: undefined,
          type: undefined,
          subtype: undefined,
          title: undefined,
          showMyActivities: undefined
        },
      },
    };
    this.props.commonProps.onRoute?.next(route);
  }

  private onNavigateToMyBrief(
    event: React.MouseEvent<
      | HTMLAnchorElement
      | HTMLButtonElement
      | HTMLDivElement
      | BaseButton
      | Button
      | HTMLSpanElement,
      MouseEvent
    >
  ): void {
    const route: IRoute = {
      page: Page.MyBrief,
      additionalInfo: {
        keysValues: {
          id: undefined,
          type: undefined,
          subtype: undefined,
          title: undefined,
          showMyActivities: undefined
        },
      },
    };
    this.props.commonProps.onRoute?.next(route);
  }

  private delegateApprover(
    event: React.MouseEvent<
      | HTMLAnchorElement
      | HTMLButtonElement
      | HTMLDivElement
      | BaseButton
      | Button
      | HTMLSpanElement,
      MouseEvent
    >
  ): void {
    const {
      brief,
      delegateApproverTag,
      emailSubjectApproverValue,
      emailBodyApproverValue,
    } = this.state;
    if (brief && delegateApproverTag && delegateApproverTag.length > 0) {
      const user = JSON.parse(delegateApproverTag[0].key as string) as UserInfo;
      const upn = user.username;
      const id = brief.id;

      this.setState({ isSaving: true }, async () => {
        try {
          const mailSubject = emailSubjectApproverValue
            ? emailSubjectApproverValue
            : "";
          const mailBody = emailBodyApproverValue
            ? emailBodyApproverValue.toString("html")
            : "";

          const result = await this.delegateApprove(
            id,
            upn,
            mailSubject,
            mailBody
          );

          if (result) {
            this.props.commonProps.toastComponent?.showMessage(
              this._translation.success,
              this._translation.delegateInsertNotification,
              ToastNotificationType.SUCCESS
            );
          } else {
            this.props.commonProps.toastComponent?.showMessage(
              this._translation.error,
              "Error",
              ToastNotificationType.ERROR
            );
          }
        } finally {
          this.setState({
            isSaving: false,
            showDelegateApproverPanel: false,
          });
        }
      });
    }
  }

  private onDelegateInsert(
    event: React.MouseEvent<
      | HTMLAnchorElement
      | HTMLButtonElement
      | HTMLDivElement
      | BaseButton
      | Button
      | HTMLSpanElement,
      MouseEvent
    >
  ): void {
    const {
      brief,
      delegateInsertTag,
      emailSubjectInsertValue,
      emailBodyInsertValue,
    } = this.state;
    if (brief && delegateInsertTag && delegateInsertTag.length > 0) {
      const user = JSON.parse(delegateInsertTag[0].key as string) as UserInfo;
      const upn = user.username;
      const id = brief.id;

      this.setState({ isSaving: true }, async () => {
        try {
          const mailSubject = emailSubjectInsertValue
            ? emailSubjectInsertValue
            : "";
          const mailBody = emailBodyInsertValue
            ? emailBodyInsertValue.toString("html")
            : "";

          const result = await this.delegateInsert(
            id,
            upn,
            mailSubject,
            mailBody
          );

          if (result) {
            this.props.commonProps.toastComponent?.showMessage(
              this._translation.success,
              this._translation.delegateForInsertSuccess,
              ToastNotificationType.SUCCESS
            );

            this.props.onCancel();
          } else {
            this.props.commonProps.toastComponent?.showMessage(
              this._translation.error,
              "Error",
              ToastNotificationType.ERROR
            );
          }
        } finally {
          this.setState({
            isSaving: false,
            showSendDialog: false,
            showDelegateInsertPanel: false,
          });
        }
      });
    }
  }

  private async delegateApprove(
    id: number,
    upn: string,
    mailSubject: string,
    mailBody: string
  ): Promise<boolean> {
    return await this._briefClient.delegateApprove(
      id,
      upn,
      mailSubject,
      mailBody
    );
  }

  private async delegateInsert(
    id: number,
    upn: string,
    mailSubject: string,
    mailBody: string
  ): Promise<boolean> {
    return await this._briefClient.delegateInsert(
      id,
      upn,
      mailSubject,
      mailBody
    );
  }

  private onDelegateInsertion(
    event: React.MouseEvent<
      | HTMLAnchorElement
      | HTMLButtonElement
      | HTMLDivElement
      | BaseButton
      | Button
      | HTMLSpanElement,
      MouseEvent
    >
  ): void {
    this.setState({
      showInsertDelegatePanel: true,
    });
  }

  private onDelegateApprover(
    event: React.MouseEvent<
      | HTMLAnchorElement
      | HTMLButtonElement
      | HTMLDivElement
      | BaseButton
      | Button
      | HTMLSpanElement,
      MouseEvent
    >
  ): void {
    this.setState({
      showDelegateApproverPanel: true,
    });
  }

  private onAccept(
    event: React.MouseEvent<
      | HTMLAnchorElement
      | HTMLButtonElement
      | HTMLDivElement
      | BaseButton
      | Button
      | HTMLSpanElement,
      MouseEvent
    >
  ): void {
    this.initializeMailComponent("Approval");
  }

  private onReject(
    event: React.MouseEvent<
      | HTMLAnchorElement
      | HTMLButtonElement
      | HTMLDivElement
      | BaseButton
      | Button
      | HTMLSpanElement,
      MouseEvent
    >
  ): void {
    this.initializeMailComponent("Reject");
  }

  private onEditBrief(
    event: React.MouseEvent<
      | HTMLAnchorElement
      | HTMLButtonElement
      | HTMLDivElement
      | BaseButton
      | Button
      | HTMLSpanElement,
      MouseEvent
    >
  ): void {
    const id = this.props.idBrief
      ? this.props.idBrief
      : this.props.brief
        ? this.props.brief.id
        : undefined;
    if (id) {
      this.props.onEditRequest(id);
    }
  }

  private onSendBrief(
    event: React.MouseEvent<
      | HTMLAnchorElement
      | HTMLButtonElement
      | HTMLDivElement
      | BaseButton
      | Button
      | HTMLSpanElement,
      MouseEvent
    >
  ): void {
    const id = this.props.idBrief
      ? this.props.idBrief
      : this.props.brief
        ? this.props.brief.id
        : undefined;
    if (id) {
      const newStatus = "Pending";
      this._briefClient.changeStatus(id, newStatus).then((retValue) => {
        if (retValue) {
          this.props.commonProps.toastComponent?.showMessage(
            this._translation.success,
            "The brief was sent successfully",
            ToastNotificationType.INFO
          );
          const { brief } = this.state;
          let newBriefObject = brief ? Object.assign({}, brief) : undefined;
          if (newBriefObject) {
            newBriefObject.status = newStatus;

            this.sendChangeNotification(newBriefObject, false);
          }
          this.setState({
            showSendDialog: true,
            canSend: false,
            brief: newBriefObject,
          });
          this.props.onChageStatus(id);
        } else {
          this.props.commonProps.toastComponent?.showMessage(
            this._translation.error,
            "Error sending brief",
            ToastNotificationType.INFO
          );
        }
      });
    }
  }

  private sendChangeNotificationWithSingleParams(
    id: number,
    title: string,
    referent?: string,
    status?: string,
    applicantUnit?: string,
    date?: Date
  ) {
    const changeNotification: INotification = {
      id: id,
      name: title,
      type: NotificationType.Brief,
      subType: NotificationSubType.internalbriefchanged,
      author: undefined,
      date: new Date(),
      identifier: "",
      additionalInfo: {
        referent: referent,
        status: status,
        applicantUnit: applicantUnit,
        newdate: date,
      },
    };

    this.props.commonProps.onNotification?.next(changeNotification);
  }

  private sendChangeNotification(
    data: IBriefDetail,
    closeNotification: boolean
  ) {
    const changeNotification: INotification = {
      id: data && data.id ? data.id : -1,
      name: data.title,
      type: NotificationType.Brief,
      subType: NotificationSubType.internalbriefchanged,
      author: undefined,
      date: new Date(),
      identifier: "",
      additionalInfo: {
        referent: data.referent?.displayName,
        status: data.status,
        applicantUnit: data.applicantUnit,
        newdate: data.timingTo,
      },
    };

    this.props.commonProps.onNotification?.next(changeNotification);

    if (closeNotification) {
      //this.props.onCancel();
    }
  }

  private _onContactApplicantUnit(
    event: React.MouseEvent<
      | HTMLAnchorElement
      | HTMLButtonElement
      | HTMLDivElement
      | BaseButton
      | Button
      | HTMLSpanElement,
      MouseEvent
    >
  ): void {
    this.initializeMailComponent("Information");
  }

  private initializeMailComponent(newStatus?: string) {
    const id = this.props.idBrief
      ? this.props.idBrief
      : this.props.brief
        ? this.props.brief.id
        : undefined;
    if (id) {
      this.setState({
        showMailComponent: true,
        newStatus: newStatus,
        mailSubTitle: this.getMailPanelSubTitle(newStatus),
        emailTO: [
          {
            key: this.state.brief?.referent?.userPrincipalName
              ? this.state.brief?.referent?.userPrincipalName
              : "-1",
            name: this.state.brief?.referent?.displayName
              ? this.state.brief?.referent?.displayName
              : "",
          },
        ],
        emailCC: [],
        emailSubject: "",
        emailMessage: RichTextEditor.createEmptyValue(),
        showError: false,
        messageError: "",
      });
    }
  }

  private getMailPanelSubTitle(
    newStatus: string | undefined
  ): string | undefined {
    let retValue: string | undefined;

    if (newStatus) {
      switch (newStatus.toLowerCase()) {
        case this.approvalStatus:
          retValue = "BRIEF APPROVATION";
          break;
        case this.rejectStatus:
          retValue = "BRIEF REJECTION";
          break;
        case this.informationStatus:
          retValue = "BRIEF INFORMATION";
          break;
      }
    }

    return retValue;
  }

  private get canSend(): boolean {
    const { brief, canSend } = this.state;

    if (brief && canSend) {
      const {
        title,
        timingFrom,
        timingTo,
        description,
        referent,
        applicantUnit,
      } = brief;

      return title &&
        timingFrom &&
        timingTo &&
        description &&
        referent &&
        applicantUnit
        ? true
        : false;
    } else {
      return false;
    }
  }

  private get currentUpn(): string {
    const upn = Security.getCurrentUserInfo()?.username;

    return upn ? upn : "-";
  }

  private onChangeDelegateInsert(selectedTags: ITag[]): void {
    this.setState({
      delegateInsertTag: selectedTags,
    });
  }

  private onChangeDelegateApprover(selectedTags: ITag[]): void {
    this.setState({
      delegateApproverTag: selectedTags,
    });
  }

  private async getApprovers(filter: string): Promise<ITag[]> {
    const result = await this._userClient.getSpecialistFilterAsync({term: filter, calendar: -1});

    if (result) {
      return result.map(this._userInfoObjectToITag);
    } else {
      this.props.commonProps.toastComponent?.showMessage(
        this._translation.error,
        this._translation.errorRetrieveData,
        ToastNotificationType.ERROR
      );
      return [];
    }
  }

  private async getInternalUser(filter: string): Promise<ITag[]> {
    const result = await this._userClient.getInternalResourceFilterAsync(
      {term: filter, calendar: -1}
    );

    if (result) {
      return result.map(this._userInfoObjectToITag);
    } else {
      this.props.commonProps.toastComponent?.showMessage(
        this._translation.error,
        this._translation.errorRetrieveData,
        ToastNotificationType.ERROR
      );
      return [];
    }
  }

  private readonly _userInfoObjectToITag = (item: UserInfo): ITag =>
    ({ key: JSON.stringify(item), name: item.displayName } as ITag);

  private readonly onResolveSuggestionDelegateApprover = (
    filter: string,
    selectedItems?: ITag[] | undefined
  ): ITag[] | PromiseLike<ITag[]> => {
    if (filter) {
      if (filter.length >= 3) {
        return this.getApprovers(filter);
      } else {
        return [];
      }
    } else {
      return [];
    }
  };

  private readonly onResolveSuggestionDelegateInsert = (
    filter: string,
    selectedItems?: ITag[] | undefined
  ): ITag[] | PromiseLike<ITag[]> => {
    if (filter) {
      if (filter.length >= 3) {
        return this.getInternalUser(filter);
      } else {
        return [];
      }
    } else {
      return [];
    }
  };

  private get _pickerSuggestionsDelegates(): IBasePickerSuggestionsProps {
    return {
      suggestionsHeaderText: this._translation.suggestedReferent,
      noResultsFoundText: this._translation.noReferentFound,
    };
  }

  private activityModalPanelStyle = mergeStyleSets({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      width: "540px", //'30%',
      heigh: "440px",
      minWidth: "540px",
      //   minHeight: "910px",
      borderRadius: "16px 16px 16px 16px",
    },
    header: [
      {
        flex: "1 1 auto",
        borderTop: `4px solid ${theme.palette.themePrimary}`,
        color: theme.palette.neutralPrimary,
        display: "flex",
        alignItems: "center",
        fontWeight: FontWeights.semibold,
        padding: "12px 12px 14px 24px",
      },
    ],
    body: {
      flex: "4 4 auto",
      padding: "18px 48px 18px 48px",
      overflowY: "hidden",

      selectors: {
        p: { margin: "14px 0" },
        "p:first-child": { marginTop: 0 },
        "p:last-child": { marginBottom: 0 },
      },
    },
  });

  private _onRenderMailModalHeader() {
    return (
      <PanelBriefHeaderComponent
        commonProps={this.props.commonProps}
        subTitle={this.state.mailSubTitle}
        item={this.state.brief}
        showContextMenu={false}
        showDeleteButton={false}
        showStatus={true}
      ></PanelBriefHeaderComponent>
    );
  }

  private _onRenderMacroModalHeader() {
    return (
      <PanelBriefHeaderComponent
        commonProps={this.props.commonProps}
        item={this.state.brief}
        showContextMenu={false}
        showDeleteButton={false}
        showStatus={true}
      ></PanelBriefHeaderComponent>
    );
  }

  private pickerTOChange(selectedTags: ITag[], id?: string): void {
    this.setState({ emailTO: selectedTags });
  }

  private pickerCCChange(selectedTags: ITag[], id?: string): void {
    this.setState({ emailCC: selectedTags });
  }

  private subjectChange(
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined
  ) {
    this.setState({ emailSubject: newValue });
  }

  private emailApproverSubjectChange(
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined
  ) {
    this.setState({ emailSubjectApproverValue: newValue });
  }

  private emailInsertSubjectChange(
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined
  ) {
    this.setState({ emailSubjectInsertValue: newValue });
  }

  private mailTextChange(newValue: EditorValue) {
    this.setState({ emailMessage: newValue });
  }

  private closeMessageBarError() {
    this.setState({ showError: false });
  }

  private _decodeHTMLEntities(text: string) {
    const span = document.createElement("span");
    span.innerHTML = text;
    return span.textContent || span.innerText;
  }

  private sendMail(
    event: React.MouseEvent<
      | HTMLAnchorElement
      | HTMLButtonElement
      | HTMLDivElement
      | import("@fluentui/react").BaseButton
      | import("@fluentui/react").Button
      | HTMLSpanElement,
      MouseEvent
    >
  ): void | undefined {
    event.preventDefault();

    const validHttpStatus: number[] = [200];

    if (
      this.state.emailTO?.length > 0 &&
      this.state.emailSubject &&
      this._decodeHTMLEntities(this.state.emailMessage.toString("html"))
    ) {
      this.setState({ isSaving: true }, async () => {
        const retValue = await this.sendMailAsync();

        if (retValue && validHttpStatus.includes(retValue)) {
          const isAccepted =
            this.state.newStatus &&
              this.state.newStatus?.toLowerCase() === this.approvalStatus
              ? true
              : false;
          const isRejected =
            this.state.newStatus &&
              this.state.newStatus?.toLowerCase() === this.rejectStatus
              ? true
              : false;

          const { brief } = this.state;
          if (
            brief &&
            this.state.newStatus &&
            this.state.newStatus.toLowerCase() !== this.informationStatus
          ) {
            let status = this.state.newStatus;
            status =
              status.toLowerCase() === this.approvalStatus
                ? "accepted"
                : status;
            const newStatus = status.charAt(0).toUpperCase() + status.slice(1);
            this.sendChangeNotificationWithSingleParams(
              brief.id,
              brief.title,
              brief.referent?.displayName,
              newStatus,
              brief.applicantUnit,
              brief.timingTo
            );
          } else if (brief) {
            this.sendChangeNotification(brief, isAccepted || isRejected);
          }

          const { brief: currenBrief } = this.state;
          if ((isAccepted || isRejected) && currenBrief) {
            const permission = Object.assign({}, currenBrief.permissions, {
              canApprove: false,
              canEdit: false,
              canSendForApproval: false,
            });
            const status = (currenBrief.status = isAccepted
              ? "Accepted"
              : "Rejected");
            const newBrief = Object.assign({}, currenBrief, {
              permissions: permission,
              status: status,
            });
            this.setState({
              brief: newBrief,
            });
          }

          this.setState({
            showInsertDelegatePanel: isAccepted,
          });

          if (this.state.brief && isRejected) {
            this.props.onChageStatus(this.state.brief?.id);
          }
        } else if (retValue && retValue === 409) {
          this.setState({
            showError: true,
            messageError: this._translation.statusConflic,
          });
        }
      });
    } else {
      this.setState({
        showError: true,
        messageError: this._translation.requiredFieldError,
      });
    }
  }

  private get genericErrorMessage(): string {
    return this._translation.genericErrorInSave;
  }

  private async sendMailAsync(): Promise<number | undefined> {
    try {
      if (this.state.brief?.id) {
        const retValue = await this._briefClient.sendMailAsync(
          this.state.brief?.id.toString(),
          this.state.emailTO,
          this.state.emailCC,
          this.state.emailSubject ? this.state.emailSubject : "SUBJECT",
          this.state.emailMessage.toString("html"),
          this.state.newStatus
        );

        if (retValue) {
          this.setState({
            messageError: undefined,
            showError: false,
            showMailComponent: false,
          });
        } else {
          this.setState({
            messageError: this.genericErrorMessage,
            showError: true,
          });
        }

        return retValue;
      } else {
        return 500;
      }
    } catch (error) {
      console.error(error);
      this.setState({
        messageError: this.genericErrorMessage,
        showError: true,
      });
    } finally {
      this.setState({ isSaving: false });
    }
  }

  render() {
    const {
      brief,
      showSendDialog,
      showInsertDelegatePanel,
      showDelegateInsertPanel,
      showDelegateApproverPanel,
    } = this.state;

    // const scrollClass = this.props.enableScroll ? " " + styles["scroll-y"] : "";
    const scrollClass = "";

    const canEdit =
      brief && brief.permissions ? brief.permissions.canEdit : false;
    const canApprove =
      brief && brief.permissions ? brief.permissions.canApprove : false;
    const canSend =
      brief && brief.permissions && this.canSend
        ? brief.permissions.canSendForApproval
        : false;

    const canDelegate =
      brief && brief.permissions
        ? brief.permissions.canDelegateCreateActivity
        : false;

    const canDelegateApproval =
      brief && brief.permissions
        ? brief.permissions.canDelegateApproval
        : false;

    const showAddCalendarButton = brief && ((brief.macroActivityCount && brief.macroActivityCount === 0) || !brief.macroActivityCount);

    const jsxDelegate = (
      <>
        <CustomLabel
          commonProps={this.props.commonProps}
          controlId="delegate"
          label={"Delegate"}
          tooltiptext="Tooltip delegate"
          required={true}
          disableTooltip={true}
        ></CustomLabel>
        <CustomPicker
          commonProps={this.props.commonProps}
          values={this.state.delegateInsertTag}
          styles={Constants.pickerStyle}
          onChange={this.onChangeDelegateInsert}
          onResolveSuggestion={this.onResolveSuggestionDelegateInsert}
          pickerSuggestionsProps={this._pickerSuggestionsDelegates}
          itemLimit={1}
        ></CustomPicker>
      </>
    );

    const jsxDelegateApprover = (
      <>
        <CustomLabel
          commonProps={this.props.commonProps}
          controlId="delegate"
          label={"Delegate"}
          tooltiptext="Tooltip delegate"
          required={true}
          disableTooltip={true}
        ></CustomLabel>
        <CustomPicker
          commonProps={this.props.commonProps}
          values={this.state.delegateApproverTag}
          styles={Constants.pickerStyle}
          onChange={this.onChangeDelegateApprover}
          onResolveSuggestion={this.onResolveSuggestionDelegateApprover}
          pickerSuggestionsProps={this._pickerSuggestionsDelegates}
          itemLimit={1}
        ></CustomPicker>
      </>
    );

    const jsxDelegateApproverMail = (
      <>
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
            <CustomLabel
              commonProps={this.props.commonProps}
              controlId="emailSubject"
              label={this._translation.subject}
              tooltiptext="EMAIL SUBJECT"
              required={true}
              disableTooltip={true}
            ></CustomLabel>
            <div className={styles.divValue}>
              <TextField
                id="emailSubject"
                styles={Constants.textFieldStyle}
                maxLength={254}
                inputClassName={styles.input48px}
                placeholder={this._translation.typeHere}
                value={this.state.emailSubjectApproverValue}
                onChange={this.emailApproverSubjectChange}
              ></TextField>
            </div>
          </div>
        </div>
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
            <CustomLabel
              commonProps={this.props.commonProps}
              controlId="emailText"
              label={this._translation.message}
              tooltiptext="EMAIL TEXT"
              required={true}
              disableTooltip={true}
            ></CustomLabel>
            <RichTextEditor
              value={this.state.emailBodyApproverValue}
              onChange={this.onChangeEmailBodyApprover}
              placeholder={this._translation.description}
              toolbarConfig={Constants.toolbarRichTextEditor}
              className={styles.RichTextboxCustom}
            ></RichTextEditor>
          </div>
        </div>
      </>
    );

    const jsxDelegateInsertMail = (
      <>
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
            <CustomLabel
              commonProps={this.props.commonProps}
              controlId="emailSubject"
              label={this._translation.subject}
              tooltiptext="EMAIL SUBJECT"
              required={true}
              disableTooltip={true}
            ></CustomLabel>
            <div className={styles.divValue}>
              <TextField
                id="emailSubject"
                styles={Constants.textFieldStyle}
                inputClassName={styles.input48px}
                maxLength={254}
                placeholder={this._translation.typeHere}
                value={this.state.emailSubjectInsertValue}
                onChange={this.emailInsertSubjectChange}
              ></TextField>
            </div>
          </div>
        </div>
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
            <CustomLabel
              commonProps={this.props.commonProps}
              controlId="emailText"
              label={this._translation.message}
              tooltiptext="EMAIL TEXT"
              required={true}
              disableTooltip={true}
            ></CustomLabel>
            <RichTextEditor
              value={this.state.emailBodyInsertValue}
              onChange={this.onChangeEmailBodyInsert}
              placeholder={this._translation.description}
              toolbarConfig={Constants.toolbarRichTextEditor}
              className={styles.RichTextboxCustom}
            ></RichTextEditor>
          </div>
        </div>
      </>
    );

    const jsxApproverDelegationPanel = (
      <>
        <Modal
          isOpen={showDelegateApproverPanel}
          onDismiss={() => {
            this.setState({ showDelegateApproverPanel: true });
          }}
          isBlocking={false}
          dragOptions={dragOptions}
          // containerClassName={this.activityModalPanelStyle.container}
          containerClassName={
            styles.modalContainer + " " + styles.modalContainerPreview
          }
        >
          <div className={styles.modalHeader}>
            {this._onRenderMailModalHeader()}
            <IconButton
              className={styles.ModalButton}
              iconProps={{ iconName: "Cancel" }}
              ariaLabel={this._translation.close}
              onClick={(ev: { preventDefault: () => void }) => {
                ev?.preventDefault();
                this.setState({ showDelegateApproverPanel: false });
              }}
            />
          </div>

          <hr />
          <div className={styles.panelBody}>
            {/* <div className={styles.panelTitle}>
              <span>{this.state.brief?.title}</span>
            </div> */}
            <div className={styles.panelContent}>
              {this._translation.delegateForApprovation}
            </div>
            <div>{jsxDelegateApprover}</div>
            {jsxDelegateApproverMail}
            <div className={styles.panelButtons}>
              <span>
                <PrimaryButton
                  text={this._translation.back}
                  onClick={() => {
                    this.setState({ showDelegateApproverPanel: false });
                  }}
                />
              </span>

              <span>
                <DefaultButton
                  text={this._translation.send}
                  onClick={this.delegateApprover}
                />
              </span>
            </div>
          </div>
        </Modal>
      </>
    );

    const jsxInsertDelegationPanel = (
      <>
        <Modal
          isOpen={showDelegateInsertPanel}
          onDismiss={() => {
            this.setState({
              showDelegateInsertPanel: false,
              showInsertDelegatePanel: false,
            });
          }}
          isBlocking={false}
          dragOptions={dragOptions}
          // containerClassName={this.activityModalPanelStyle.container}
          containerClassName={
            styles.modalContainer + " " + styles.modalContainerPreview
          }
        >
          <div className={styles.modalHeader}>
            {this._onRenderMailModalHeader()}
            <IconButton
              className={styles.ModalButton}
              iconProps={{ iconName: "Cancel" }}
              ariaLabel={this._translation.close}
              onClick={(ev: { preventDefault: () => void }) => {
                ev?.preventDefault();
                this.setState({ showDelegateInsertPanel: false });
              }}
            />
          </div>

          <hr />
          <div className={styles.panelBody}>
            {/* <div className={styles.panelTitle}>
              <span>{this.state.brief?.title}</span>
            </div> */}
            <div className={styles.panelContent}>
              {this._translation.delegateForInsert}
            </div>
            <div>{jsxDelegate}</div>
            {this.state.isSaving && (
              <Spinner
                label={this._translation.loadingMessageByKey(
                  "sendingApprovalRequest"
                )}
              ></Spinner>
            )}
            {jsxDelegateInsertMail}
            <div className={styles.panelButtons}>
              <span>
                <PrimaryButton
                  // styles={Constants.iPrincipalButton}
                  text={this._translation.back}
                  onClick={() => {
                    this.setState({
                      showDelegateInsertPanel: false,
                      showInsertDelegatePanel: true,
                    });
                  }}
                />
              </span>
              <span>
                <DefaultButton
                  // styles={Constants.iPrincipalButton}
                  text={this._translation.send}
                  onClick={this.onDelegateInsert}
                />
              </span>
            </div>
          </div>
        </Modal>
      </>
    );

    const jsxDelegateButtons = (
      <>
        {" "}
        <div>
          <PrimaryButton
            text={this._translation.delegate}
            styles={Constants.iPrincipalButtonLarge}
            onClick={() => {
              this.setState({
                showSendDialog: false,
                showDelegateInsertPanel: true,
              });
            }}
          />
        </div>
        {showAddCalendarButton &&
          <div>
            <DefaultButton
              text={this._translation.addToCalendar}
              styles={Constants.iStandardButtonLarge}
              onClick={() => {
                this.setState({
                  showSendDialog: false,
                  showMacroComponent: true,
                });
              }}
            />
          </div>
        }
      </>
    );

    const jsxAfterAcceptButtons = (
      <div className={styles.panelButtons}>
        <span>
          <PrimaryButton
            text={this._translation.delegate}
            onClick={() => {
              this.setState({
                showSendDialog: false,
                showDelegateInsertPanel: true,
              });
            }}
          />
        </span>
        <span>
          <DefaultButton
            text={this._translation.addToCalendar}
            onClick={() => {
              this.setState({
                showSendDialog: false,
                showMacroComponent: true,
              });
            }}
          />
        </span>
      </div>
    );
    const jsxConfirmInsertDelegation = (
      <>
        <Modal
          isOpen={showInsertDelegatePanel}
          onDismiss={() => {
            this.setState({ showInsertDelegatePanel: false });
          }}
          isBlocking={false}
          dragOptions={dragOptions}
          // containerClassName={this.activityModalPanelStyle.container}
          containerClassName={
            styles.modalContainer + " " + styles.modalContainerPreview
          }
        >
          <div className={styles.modalHeader}>
            {this._onRenderMailModalHeader()}
            <IconButton
              className={styles.ModalButton}
              iconProps={{ iconName: "Cancel" }}
              ariaLabel={this._translation.close}
              onClick={(ev: { preventDefault: () => void }) => {
                ev?.preventDefault();
                this.setState({ showInsertDelegatePanel: false });
              }}
            />
          </div>
          <hr />
          <div className={styles.panelBody}>
            {/* <div className={styles.panelTitle}>
              <span>{this.state.brief?.title}</span>
            </div> */}
            <div className={styles.panelContent}>
              {this._translation.approvalNotification(
                brief && brief.referent ? brief.referent.displayName : "---"
              )}
            </div>
            {/* <div>
        {jsxDelegate}
      </div>         */}
            {jsxAfterAcceptButtons}
          </div>
        </Modal>
      </>
    );

    const jsxSendConfirmation = (
      <>
        <Dialog
          hidden={!showSendDialog}
          onDismiss={() => {
            this.setState({ showSendDialog: false });
          }}
          dialogContentProps={this.dialogContentProps}
        // modalProps={modalProps}
        >
          <DialogFooter>
            <PrimaryButton
              text="Back to home"
              onClick={this.onNavigateToHome}
            />
            <DefaultButton text="My Brief" onClick={this.onNavigateToMyBrief} />
          </DialogFooter>
        </Dialog>
      </>
    );

    //   const jsxSendConfirmation = <>
    //   <Modal
    //   isOpen={showSendDialog}
    //   onDismiss={()=>{this.setState({showSendDialog: false})}}
    //   dragOptions={dragOptions}
    //   containerClassName={
    //     this.activityModalPanelStyle.container
    //   }
    // >
    //     <div className={styles.panelHeader}>
    //       {/* <span>{this.state.brief?.title}</span> */}
    //       <span></span>
    //       <div>
    //         <IconButton
    //           style={{ float: "right" }}
    //           iconProps={Constants.iCancel}
    //           ariaLabel="Close popup modal"
    //           onClick={() => this.setState({showSendDialog: false})}
    //         />
    //       </div>
    //     </div>
    //     <div className={styles.panelBody}>

    //       <div className={styles.panelTitle}>
    //         <span>{this.state.brief?.title}</span>
    //       </div>
    //       <div className={styles.panelContent}>
    //         {this._translation.briefAfterSendText}
    //       </div>
    //       {/* <div>
    //         {jsxDelegate}
    //       </div>         */}
    //       <div className={styles.panelButtons}>
    //         <span>
    //             <PrimaryButton text = {this._translation.delegate} onClick={()=>{this.setState({showSendDialog: false, showDelegateInsertPanel: true}); }} />
    //           </span>
    //         <span>
    //             <DefaultButton text = {this._translation.addToCalendar} onClick={()=>{this.setState({showSendDialog: false});}}/>
    //          </span>
    //       </div>
    //     </div>
    // </Modal>
    //   </>;

    const jsxError = (
      <div className="ms-Grid-row ">
        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 messageError">
          <MessageBar
            messageBarType={MessageBarType.error}
            isMultiline={false}
            dismissButtonAriaLabel={this._translation.close}
            onDismiss={this.closeMessageBarError}
          >
            {this.state.messageError}
          </MessageBar>
        </div>
      </div>
    );

    const jsxMediaCoverageDisplayText =
      brief && brief.mediaCoverage
        ? "Require Media Coverage"
        : "Not require Media Coverage";
    return (
      <React.Fragment>
        <LoaderComponent
          commonProps={this.props.commonProps}
          isLoading={this.state.isLoading}
          label={this._translation.loadingMessageByKey("loadingBriefDetail")}
        />

        {this.renderMailComponent(jsxError)}
        {this.renderMacroComponent()}

        {brief && (
          <div className={styles["briefdetail-container"]}>
            <div
              className={
                styles["briefdetail-container-item-left"] +
                " ms-Grid-col ms-lg9 ms-md12 " +
                scrollClass
              }
            >
              {!this.props.hideNavigationAfterSend && jsxSendConfirmation}
              {jsxConfirmInsertDelegation}
              {jsxInsertDelegationPanel}
              {jsxApproverDelegationPanel}
              <div className="ms-Grid" dir="ltr">
                {this.props.showTitleRow && (
                  <div className={"ms-Grid-row " + styles["row-space"]}>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                      {this.renderCell(
                        styles["default-container"],
                        styles["main-title"],
                        styles["main-content"],
                        this._translation.title,
                        brief.title
                      )}
                    </div>
                  </div>
                )}
                <div className={"ms-Grid-row " + styles["row-space"]}>
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                    {this.renderCell(
                      styles["default-container"],
                      styles["default-title"],
                      styles["default-content"],
                      this._translation.referent,
                      brief.referent ? brief.referent.displayName : ""
                    )}
                  </div>
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                    {this.renderCell(
                      styles["default-container"],
                      styles["default-title"],
                      styles["default-content"],
                      this._translation.applicationUnit,
                      brief.applicantUnit
                    )}
                  </div>
                </div>
                <div className={"ms-Grid-row " + styles["row-space"]}>
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    {this.renderCell(
                      styles["default-container"],
                      styles["default-title"],
                      styles["default-content"],
                      this._translation.referents,
                      brief.otherReferents.map((t) => t.displayName).join(", ")
                    )}
                  </div>
                </div>
                <div className={"ms-Grid-row " + styles["row-space"]}>
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                    {this.renderCell(
                      styles["default-container"],
                      styles["default-title"],
                      styles["default-content"],
                      this._translation.timing,
                      this.toTimingLabel(brief.timingFrom, brief.timingTo)
                    )}
                  </div>
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                    {this.renderCell(
                      styles["default-container"],
                      styles["default-title"],
                      styles["default-content"],
                      this._translation.expectedDeliveryDate,
                      brief.expectedDelivery
                        ? Utility.formatDate(
                          brief.expectedDelivery,
                          "DD/MM/YYYY"
                        )
                        : "-"
                    )}
                  </div>
                </div>
                <div className={styles["line-break"]}>
                  <hr></hr>
                </div>

                <div className={"ms-Grid-row " + styles["row-space"]}>
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    {this.renderCell(
                      styles["default-container"],
                      styles["default-title"],
                      styles["default-content"],
                      this._translation.description,
                      brief.description,
                      true
                    )}
                  </div>
                </div>
                <div className={"ms-Grid-row " + styles["row-space"]}>
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    {this.renderCell(
                      styles["default-container"],
                      styles["default-title"],
                      styles["default-content"],
                      this._translation.goalsAndKeyMessage,
                      brief.goalsAndKeyMessages,
                      true
                    )}
                  </div>
                </div>
                <div className={"ms-Grid-row " + styles["row-space"]}>
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    {this.renderCell(
                      styles["default-container"],
                      styles["default-title"],
                      styles["default-content"],
                      this._translation.targets,
                      brief.targets.join(", ")
                    )}
                  </div>
                </div>
                <div className={"ms-Grid-row " + styles["row-space"]}>
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    {this.renderCell(
                      styles["default-container"],
                      styles["default-title"],
                      styles["default-content"],
                      this._translation.highlights,
                      brief.highlightsAndCriticalIssues,
                      true
                    )}
                  </div>
                </div>

                <div className={"ms-Grid-row " + styles["row-space"]}>
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    <div>
                      <Text className={styles["boolean-text"]}>
                        {brief.embargoed ? "Embargoed" : "Not Embargoed"}
                      </Text>
                    </div>
                  </div>
                </div>

                <div className={"ms-Grid-row " + styles["row-space"]}>
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    {this.renderCell(
                      styles["default-container"],
                      styles["default-title"],
                      styles["default-content"],
                      this._translation.relevantStakeholder,
                      brief.relevantStakeholders.join(", ")
                    )}
                  </div>
                </div>

                <div className={"ms-Grid-row " + styles["row-space"]}>
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    <div className={styles["default-container"]}>
                      <Text className={styles["boolean-text"]}>
                        {jsxMediaCoverageDisplayText}
                      </Text>
                    </div>
                  </div>
                </div>

                <div className={"ms-Grid-row " + styles["row-space"]}>
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    <div className={styles["default-container"]}>
                      <div>
                        <Text className={styles["default-title"]}>
                          {this._translation.uploadDocumentation}
                        </Text>
                      </div>
                      <div>
                        <BriefFileList
                          commonProps={this.props.commonProps}
                          items={this.state.briefFiles}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className={"ms-Grid-row " + styles["row-space"]}>
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    <div className={styles["default-container"]}>
                      <div>
                        <Text className={styles["default-title"]}>
                          {this._translation.links}
                        </Text>
                      </div>
                      <div>
                        <BriefLinks
                          commonProps={this.props.commonProps}
                          items={brief.links}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={
                styles["briefdetail-container-item-right"] +
                "  ms-Grid-col ms-lg3 ms-md12"
              }
            >
              <div className={styles["briefdetail-buttons"]}>
                {canEdit && (
                  <>
                    <div className={styles["briefdetail-button-top"]}>
                      <PrimaryButton
                        text={this._translation.send}
                        styles={Constants.iPrincipalButtonLarge}
                        onClick={this.onSendBrief}
                        disabled={!canSend}
                      ></PrimaryButton>
                    </div>
                    <div>
                      <DefaultButton
                        text={this._translation.edit}
                        styles={Constants.iStandardButtonLarge}
                        onClick={this.onEditBrief}
                        disabled={!canEdit}
                      ></DefaultButton>
                    </div>
                  </>
                )}

                {canDelegate && jsxDelegateButtons}

                {canApprove && (
                  <>
                    <div>
                      <PrimaryButton
                        text={this._translation.accept}
                        styles={Constants.iPrincipalButtonLarge}
                        onClick={this.onAccept}
                      ></PrimaryButton>
                    </div>
                    <div>
                      <DefaultButton
                        text={this._translation.reject}
                        styles={Constants.iStandardButtonLarge}
                        onClick={this.onReject}
                      ></DefaultButton>
                    </div>
                    <div>
                      <DefaultButton
                        text={this._translation.contactApplicantUnit}
                        styles={Constants.iStandardButtonLarge}
                        onClick={this._onContactApplicantUnit}
                      // disabled={!canEdit}
                      ></DefaultButton>
                    </div>
                  </>
                )}
                {canApprove && canDelegateApproval && (
                  <div>
                    <DefaultButton
                      text={this._translation.delegateApprover}
                      styles={Constants.iStandardButtonLarge}
                      onClick={this.onDelegateApprover}
                    ></DefaultButton>
                  </div>
                )}
              </div>
              {this.props.showHistory && (
                <>
                  <div className={styles.statusContainer}>
                    <div>
                      <Text className={styles.statusTitle}>
                        {this._translation.status}
                      </Text>
                    </div>
                    <div className={styles.statusMargin}>
                      <span
                        status-type={brief.status.toLowerCase()}
                        className={styles["status"]}
                      >
                        {brief.status}
                      </span>
                    </div>
                  </div>
                  <div className={styles.historyContainer}>
                    <div>
                      <Text className={styles.historyTitle}>
                        {this._translation.history}
                      </Text>
                    </div>
                    <div>
                      <BriefHistory
                        id={brief.id}
                        currentUpn={this.currentUpn}
                        commonProps={this.props.commonProps}
                      ></BriefHistory>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }

  private renderMailComponent(jsxError: JSX.Element) {
    return (
      <Modal
        titleAriaId=""
        isOpen={this.state.showMailComponent}
        onDismiss={(ev) => {
          ev?.preventDefault();
          this.setState({ showMailComponent: false });
        }}
        isBlocking={false}
        containerClassName={
          styles.modalContainer + " " + styles.modalContainerPreview
        }
        className={styles.ModalAssignedResources}
      >
        <div className={styles.modalHeader}>
          {this._onRenderMailModalHeader()}
          <IconButton
            className={styles.ModalButton}
            iconProps={{ iconName: "Cancel" }}
            ariaLabel={this._translation.close}
            onClick={(ev: { preventDefault: () => void }) => {
              ev?.preventDefault();
              this.setState({ showMailComponent: false });
            }}
          />
        </div>
        {!this.state.newStatus && (
          <div className={styles.actionInfo}>
            <div className={styles.dotInfo} />
            <span className="action">
              {this._translation.contactActionInfo}
            </span>
          </div>
        )}
        <hr />
        <div className={styles.modalBody + " " + styles.modalBodyPreview}>
          <div className={" panel-add-activity"}>
            <LoaderComponent
              commonProps={this.props.commonProps}
              isLoading={this.state.isSaving}
              label={this._translation.loadingMessageByKey(
                "sendingApprovalRequest"
              )}
            />

            <div className="ms-Grid" dir="ltr">
              {this.state.currentUser && (
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    <CustomLabel
                      commonProps={this.props.commonProps}
                      controlId="chooseFrom"
                      label={this._translation.mailFROM}
                      tooltiptext="FROM"
                      required={true}
                      disableTooltip={false}
                    ></CustomLabel>
                    <div className={styles.divValue}>
                      <PersonaComponent
                        commonProps={this.props.commonProps}
                        personaSize={PersonaSize.size32}
                        personaStyle={PersonaStyle.BLUE}
                        maxLength={1}
                        persona={[this.state.currentUser]}
                        showText={true}
                      ></PersonaComponent>
                    </div>
                  </div>
                </div>
              )}

              <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                  <CustomLabel
                    commonProps={this.props.commonProps}
                    controlId="chooseTO"
                    label={this._translation.mailTO}
                    tooltiptext="Choose TO"
                    required={true}
                    disableTooltip={false}
                  ></CustomLabel>
                  <div className={styles.divValue}>
                    <UserPicker
                      commonProps={this.props.commonProps}
                      styles={Constants.pickerStyle}
                      onChange={this.pickerTOChange}
                      values={this.state.emailTO}
                    ></UserPicker>
                  </div>
                </div>
              </div>
              <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                  <CustomLabel
                    commonProps={this.props.commonProps}
                    controlId="chooseCC"
                    label={this._translation.mailCC}
                    tooltiptext="Choose CC"
                    required={false}
                    disableTooltip={false}
                  ></CustomLabel>
                  <div className={styles.divValue}>
                    <UserPicker
                      commonProps={this.props.commonProps}
                      styles={Constants.pickerStyle}
                      onChange={this.pickerCCChange}
                      values={this.state.emailCC}                      
                    ></UserPicker>
                  </div>
                </div>
              </div>
              <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                  <CustomLabel
                    commonProps={this.props.commonProps}
                    controlId="emailSubject"
                    label={this._translation.subject}
                    tooltiptext="EMAIL SUBJECT"
                    required={true}
                    disableTooltip={false}
                  ></CustomLabel>
                  <div className={styles.divValue}>
                    <TextField
                      id="emailSubject"
                      styles={Constants.textFieldStyle}
                      inputClassName={styles.input48px}
                      maxLength={254}
                      placeholder={this._translation.typeHere}
                      value={this.state.emailSubject}
                      onChange={this.subjectChange}
                    ></TextField>
                  </div>
                </div>
              </div>
              <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                  <CustomLabel
                    commonProps={this.props.commonProps}
                    controlId="emailText"
                    label={this._translation.message}
                    tooltiptext="EMAIL TEXT"
                    required={true}
                    disableTooltip={false}
                  ></CustomLabel>
                  <div className={styles.divValue}>
                    <RichTextEditor
                      placeholder={this._translation.description}
                      value={this.state.emailMessage}
                      onChange={this.mailTextChange}
                      toolbarConfig={Constants.toolbarRichTextEditor}
                      className={styles.RichTextboxCustom}
                    ></RichTextEditor>
                  </div>
                </div>
              </div>
              <div className="ms-Grid-row">
                <div></div>
              </div>
              {this.state.showError && jsxError}
              <div className="ms-Grid-row commandFooter">
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                  <PrimaryButton
                    onClick={this.sendMail}
                    styles={Constants.iPrincipalButton}
                  >
                    {this._translation.send}
                  </PrimaryButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  private renderMacroComponent() {
    return (
      <Modal
        titleAriaId=""
        isOpen={this.state.showMacroComponent}
        onDismiss={(ev) => {
          ev?.preventDefault();
          this.setState({ showMacroComponent: false });
        }}
        isBlocking={false}
        containerClassName={
          styles.modalContainer + " " + styles.modalContainerPreview
        }
        className={styles.ModalAssignedResources}
      >
        <div className={styles.modalHeader}>
          {this._onRenderMacroModalHeader()}
          <IconButton
            className={styles.ModalButton}
            iconProps={{ iconName: "Cancel" }}
            ariaLabel={this._translation.close}
            onClick={(ev: { preventDefault: () => void }) => {
              ev?.preventDefault();
              this.setState({ showMacroComponent: false });
            }}
          />
        </div>
        <hr />
        <div className={styles.modalBody + " " + styles.modalBodyPreview}>
          <div className={" panel-add-activity"}>
            <Macro
              commonProps={this.props.commonProps}
              brief={this.state.brief}
              className={Constants.activityModalPanelStyle.body}
              hideModalCallback={() => {
                this.setState({ showMacroComponent: false });
              }}
              onSave={(macro: IMacro) => {
                this.navigateToCalendar(macro);
                // window.location.href = "/?page=" + Constants.PagePlanner;
              }}
            ></Macro>
          </div>
        </div>
      </Modal>
    );
  }
  private navigateToCalendar(macro: IMacro) {
    const id = (macro.id ? macro.id : -1).toString();
    const route: IRoute = {
      page: Page.Planner,
      additionalInfo: {
        keysValues: {
          id: id,
          type: undefined,
          subtype: undefined,
          title: macro.name,
          showMyActivities: undefined
        },
        optionalValues: {
          date: macro.dateFrom,
        },
      },
    };
    this.props.commonProps.onRoute?.next(route);
  }
}
