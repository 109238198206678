import { TFunction } from "i18next";
import { CommonTranslation } from "./Common.Translation";

export class ActivityTranslation extends CommonTranslation {
  constructor(translation: TFunction) {
    super(translation, "activity");
  }

  public get list(): string {
    return super.getTextByMessageKey("list");
  }

  public get timeline(): string {
    return super.getTextByMessageKey("timeline");
  }

  public get attachments(): string {
    return super.getTextByMessageKey("attachments");
  }

  public get messages(): string {
    return super.getTextByMessageKey("messages");
  }

  public get activity(): string {
    return super.getTextByMessageKey("activity");
  }

  public get calendarHeaderText(): string {
    return super.getTextByMessageKey("calendarHeaderText");
  }

  public get typeHeaderText(): string {
    return super.getTextByMessageKey("typeHeaderText");
  }

  public get statusHeaderText(): string {
    return super.getTextByMessageKey("statusHeaderText");
  }

  public get lastModify(): string {
    return super.getTextByMessageKey("lastModify");
  }

  public get activityDetails(): string {
    return super.getTextByMessageKey("activityDetails");
  }

  public get searchActivity(): string {
    return super.getTextByMessageKey("searchActivity");
  }

  public get shareCardSuccess(): string {
    return super.getTextByMessageKey("shareCardSuccess");
  }

  public get downloadDeliverables(): string {
    return super.getTextByMessageKey("downloadDeliverables");
  }

  public get validationInPlatform(): string {
    return super.getTextByMessageKey("validationInPlatform");
  }

  public get validationOutPlatform(): string {
    return super.getTextByMessageKey("validationOutPlatform");
  }

  public get sendForApproval(): string {
    return super.getTextByMessageKey("sendForApproval");
  }

  public get assignedResourcesUpdateSuccess(): string {
    return super.getTextByMessageKey("assignedResourcesUpdateSuccess");
  }

  public get externalResourcesUpdateSuccess(): string {
    return super.getTextByMessageKey("externalResourcesUpdateSuccess");
  }

  public get dueDateUpdateSuccess(): string {
    return super.getTextByMessageKey("dueDateUpdateSuccess");
  }

  public get timingUpdateSuccess(): string {
    return super.getTextByMessageKey("timingUpdateSuccess");
  }

  public get spocUpdateSuccess(): string {
    return super.getTextByMessageKey("spocUpdateSuccess");
  }

  public get headOfActivityUpdateSuccess(): string {
    return super.getTextByMessageKey("headOfActivityUpdateSuccess");
  }

  public get createdBy(): string {
    return super.getTextByMessageKey("createdBy");
  }

  public get createdOn(): string {
    return super.getTextByMessageKey("createdOn");
  }

  public get timing(): string {
    return super.getTextByMessageKey("timing");
  }

  public get dueTo(): string {
    return super.getTextByMessageKey("dueTo");
  }

  public get end(): string {
    return super.getTextByMessageKey("end");
  }

  public get activityDate(): string {
    return super.getTextByMessageKey("date");
  }

  public get start(): string {
    return super.getTextByMessageKey("start");
  }

  public get spoc(): string {
    return super.getTextByMessageKey("spoc");
  }

  public get headOfActivity(): string {
    return super.getTextByMessageKey("headOfActivity");
  }

  public get assignedResources(): string {
    return super.getTextByMessageKey("assignedResources");
  }

  public get externalResources(): string {
    return super.getTextByMessageKey("externalResources");
  }

  public get description(): string {
    return super.getTextByMessageKey("description");
  }

  public get parent(): string {
    return super.getTextByMessageKey("parent");
  }

  public get associatedActivities(): string {
    return super.getTextByMessageKey("associatedActivities");
  }

  public get macroDocumentation(): string {
    return super.getTextByMessageKey("macroDocumentation");
  }

  public get brief(): string {
    return super.getTextByMessageKey("brief");
  }

  public get manageActivity(): string {
    return super.getTextByMessageKey("manageActivity");
  }

  public get getActivitiesError(): string {
    return super.getTextByMessageKey("getActivitiesError");
  }

  public get getActivityError(): string {
    return super.getTextByMessageKey("getActivityError");
  }

  public get updateActivityError(): string {
    return super.getTextByMessageKey("updateActivityError");
  }

  public get deleteActivityError(): string {
    return super.getTextByMessageKey("removeActivityError");
  }

  public get noActivityToDisplay(): string {
    return super.getTextByMessageKey("noActivitiesToDisplay");
  }
  public get loadMore(): string {
    return super.getTextByMessageKey("loadMore");
  }
  public get focusNote(): string {
    return super.getTextByMessageKey("focusNote");
  }

  public get editDescription(): string {
    return super.getTextByMessageKey("editDescription");
  }

  public get downloadAttachment(): string {
    return super.getTextByMessageKey("downloadAttachment");
  }

  public get downloadAttachments(): string {
    return super.getTextByMessageKey("downloadAttachments");
  }

  public get resourceCannotBeRemoved(): string {
    return super.getTextByMessageKey("resourceCannotBeRemoved");
  }

  public get changeAssociatedMacro(): string {
    return super.getTextByMessageKey("changeAssociatedMacro");
  }

  public get associateMacro(): string {
    return super.getTextByMessageKey("associateMacro");
  }

  public get setMicroAsIndipendent(): string {
    return super.getTextByMessageKey("setMicroAsIndipendent");
  }

  public viewAllMyActivities(counter?: number): string {
    if (counter) {
      return super.getTextByMessageKey("viewMyActivitiesCounter", {
        counter: counter,
      });
    } else {
      return super.getTextByMessageKey("viewMyActivities");
    }
  }

  public statusChangeActionDescription(status: string, date: string): string {
    return super.getTextByMessageKey("statusChangeActionDescription", {
      status: status,
      date: date,
    });
  }

  public statusChangeActionDescriptionDashboard(status: string): string {
    return super.getTextByMessageKey("statusChangeActionDescriptionDashboard", {
      status: status,
    });
  }

  public getByKey(key: string): string {
    return super.getTextByMessageKey(key);
  }

  public get filterByTypeAllActivities(): string {
    return super.getTextByMessageKey("type.all");
  }

  public get filterByCalendarAllCalendars(): string {
    return super.getTextByMessageKey("calendar.all");
  }

  public get filterByCategoryAllCategories(): string {
    return super.getTextByMessageKey("category.all");
  }

  public get filterByResponsibilityAllResponsibilities(): string {
    return super.getTextByMessageKey("responsibility.all");
  }

  public get filterBySensibilityAllSensibilities(): string {
    return super.getTextByMessageKey("sensibility.all");
  }

  public get filterByTypeOnlyMacro(): string {
    return super.getTextByMessageKey("type.macro");
  }

  public get filterByTypeOnlyMicro(): string {
    return super.getTextByMessageKey("type.micro");
  }

  public get myActivities(): string {
    return super.getTextByMessageKey("myActivities");
  }

  public get myActivitiesTab(): string {
    return super.getTextByMessageKey("myActivitiesTab");
  }

  public get allActivities(): string {
    return super.getTextByMessageKey("allActivities");
  }
}
