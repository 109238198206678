import { TFunction } from "i18next";
import { CommonTranslation } from "./Common.Translation";

export class DashboardTranslation extends CommonTranslation {
  constructor(translation: TFunction) {
    super(translation, "dashboard");
  }

  public welcomeMessage(user:string): string {
    return super.getTextByMessageKey("title1", {
      user: user,
    });
  }
  public welcomeMessage2(appName: string): string {
    return super.getTextByMessageKey("title2", {
      appName: appName,
    });
  }
  public get headerCounter(): string {
    return super.getTextByMessageKey("request");
  }
  public get headerActivities(): string {
    return super.getTextByMessageKey("activities.title");
  }
  public get headerBriefs(): string {
    return super.getTextByMessageKey("briefs.title");
  }
}
