import * as React from "react";
import styles from "./VideoTutorialsComponent.module.scss";
import { VideoTutorialCardComponent } from "./VideoTutorialCardComponent/VideoTutorialCardComponent";
import { Dropdown, FontIcon, IDropdownOption } from "office-ui-fabric-react";
import { IBaseProperties } from "../../../../../../Models/IBaseProperties";
import {
  ALL,
  CardComponent,
  LanguageCounter,
  RoleCounter,
  SupportLanguage,
  SupportPagesEnum,
} from "../../../../../../Models/SupportPage";
import { VideoTutorial } from "../../../../../../Models/VideoTutorial";
import { SupportTranslation } from "../../../../../../Translations/Support.Translation";
import { ISupportClient } from "../../../../../../Clients/ISupportClient";
import { LoaderComponent } from "../../../../../Shared/LoaderComponent/LoaderComponent";
import DropdownItem from "react-bootstrap/esm/DropdownItem";

export interface VideoTutorialsComponentProps extends IBaseProperties {
  component: CardComponent;
}
export interface VideoTutorialsComponentState {
  showMobileStates: boolean;

  videoTutorialCounters: RoleCounter[];
  languagesCounters: LanguageCounter[];
  items: VideoTutorial[];
  favourites: VideoTutorial[];
  filteredItems: VideoTutorial[];
  itemsCount: number;
  isLoading: boolean;
  changeFilterLoading: boolean;
  videoSelected?: VideoTutorial;
  roles: string[];
  roleSelected: string;
  languagesSelected: SupportLanguage[];
}

export class VideoTutorialsComponent extends React.Component<
  VideoTutorialsComponentProps,
  VideoTutorialsComponentState
> {
  private readonly _translation: SupportTranslation;
  private readonly _supportClient: ISupportClient;

  private renderer = (label: string) => this._renderTitle;

  private _renderTitle(item: IDropdownOption) {
    const option = item;
    const options = [item];
    if (option.key == "-1") {
      return (
        <div>
          <FontIcon iconName={"Globe"} className={styles.IconGlobe} />
          <div className={styles.divTextSelected}>
            {this.state.languagesCounters.map((x) => {
              return (
                <div className={styles.ItemSelected}>
                  <div className={styles.TextItemSelected}>
                    {x.language + " (" + x.count + ")"}
                  </div>
                  {/* <FontIcon
                      iconName={"Cancel"}
                      className={styles.IconItemSelected}
                    /> */}
                </div>
              );
            })}
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <FontIcon iconName={"Globe"} className={styles.IconGlobe} />
          <div className={styles.divTextSelected}>
            {options.map((x) => {
              return (
                <div className={styles.ItemSelected}>
                  <div className={styles.TextItemSelected}>{x.text}</div>
                  {/* <FontIcon
                      iconName={"Cancel"}
                      className={styles.IconItemSelected}
                    /> */}
                </div>
              );
            })}
          </div>
        </div>
      );
    }
  }

  constructor(props: VideoTutorialsComponentProps) {
    super(props);

    this._translation = new SupportTranslation(
      this.props.commonProps.translation
    );
    this._supportClient = this.props.commonProps.clientCreator.createSupportClient();

    this._changeRole = this._changeRole.bind(this);
    this._changeVideoTutorial = this._changeVideoTutorial.bind(this);
    this._changeLanguage = this._changeLanguage.bind(this);
    this._refreshList = this._refreshList.bind(this);
    this._getLanguages = this._getLanguages.bind(this);

    this.state = {
      roleSelected: ALL,
      roles: [],
      showMobileStates: false,
      videoTutorialCounters: [{ role: ALL, count: 0 }],
      languagesCounters: [],
      items: [],
      favourites: [],
      filteredItems: [],
      itemsCount: 0,
      isLoading: true,
      changeFilterLoading: false,
      languagesSelected: Object.assign([], this.props.component.languages),
    };
  }

  public componentDidMount() {
    this._refreshList();
  }

  private async _changeRole(role: string) {
    this._refreshList(role, this.state.languagesSelected);
  }

  private async _changeLanguage(item: IDropdownOption) {
    var languagesSelected =
      item.key == "-1"
        ? Object.assign([], this.props.component.languages)
        : this.props.component.languages.filter((key) => key == item.key);

    // var languagesSelected = item.selected
    //   ? [...this.state.languagesSelected, item.key.toString()]
    //   : this.state.languagesSelected.filter((key) => key !== item.key);

    this._refreshList(this.state.roleSelected, languagesSelected);
  }

  public async _refreshList(
    role?: string,
    languagesSelected?: SupportLanguage[]
  ) {
    this.setState(
      {
        // isLoading: true,
        changeFilterLoading: true,
        items: [],
        filteredItems: [],
      },
      () => {
        if (role == undefined) role = this.state.roleSelected;
        if (languagesSelected == undefined)
          languagesSelected = this.state.languagesSelected;
        this._supportClient
          .getRoles(
            SupportPagesEnum.VideoTutorials,
            this.state.languagesSelected[0]
          )
          .then((roles) => {
            if (languagesSelected) {
              this._supportClient
                .getCounters(
                  SupportPagesEnum.VideoTutorials,
                  languagesSelected,
                  role ? role : ALL
                )
                .then((counters) => {
                  this._supportClient
                    .getFavourites(
                      SupportPagesEnum.VideoTutorials,
                      this.state.languagesSelected[0],
                      this.state.roleSelected
                    )
                    .then((favourites) => {
                      this._supportClient
                        .getItems(
                          SupportPagesEnum.VideoTutorials,
                          languagesSelected
                            ? languagesSelected[0]
                            : SupportLanguage.English,
                          role ? role : ALL
                        )
                        .then((items) => {
                          if (
                            this.state.favourites.length != favourites.length
                          ) {
                            this.setState({ favourites: [] }, () => {
                              this.setState({
                                items: items as VideoTutorial[],
                                favourites: favourites as VideoTutorial[],
                                filteredItems: items as VideoTutorial[],
                                videoTutorialCounters: counters?.roleCounter
                                  ? counters?.roleCounter
                                  : [],
                                languagesCounters: counters?.languageCounter
                                  ? counters?.languageCounter
                                  : [],
                                isLoading: false,
                                changeFilterLoading: false,
                                languagesSelected: languagesSelected
                                  ? languagesSelected
                                  : [],
                                roleSelected: role ? role : ALL,
                                roles: roles,
                              });
                            });
                          } else {
                            this.setState({
                              items: items as VideoTutorial[],
                              favourites: favourites as VideoTutorial[],
                              filteredItems: items as VideoTutorial[],
                              videoTutorialCounters: counters?.roleCounter
                                ? counters?.roleCounter
                                : [],
                              languagesCounters: counters?.languageCounter
                                ? counters?.languageCounter
                                : [],
                              isLoading: false,
                              changeFilterLoading: false,
                              languagesSelected: languagesSelected
                                ? languagesSelected
                                : [],
                              roleSelected: role ? role : ALL,
                              roles: roles,
                            });
                          }
                        });
                    });
                });
            }
          });
      }
    );
  }

  private _changeVideoTutorial(video: VideoTutorial) {
    this.setState({ videoSelected: undefined }, () => {
      this.setState({ videoSelected: video });
    });
  }

  private _getLanguages(): IDropdownOption[] {
    var retValues: IDropdownOption[] = [{ key: "-1", text: "All languages" }];
    var languages = this.state.languagesCounters.map((x) => {
      return {
        key: x.language,
        text: x.language + " (" + x.count + ")",
      };
    });

    return retValues.concat(languages);
  }

  public render() {
    return (
      // <div></div>

      <div className={styles.userguides}>
        <LoaderComponent
          commonProps={this.props.commonProps}
          isLoading={this.state.isLoading}
        />
        <div className={styles.content}>
          <div className={styles.backgroundDiv}>
            <div>
              <div className={styles.lowpanel}>
                {this.state.favourites.length > 0 && (
                  <div
                    className={styles.tab_favourites + " " + styles.tab_content}
                  >
                    <div id="tab1" role="tabpanel" aria-labelledby="tab1-tab">
                      <div className="ms-Grid-col ms-xl12 ms-lg12 ms-md12 ms-sm12">
                        <div className={styles.HeaderFavourites}>
                          {this._translation.headerFavourites}
                        </div>
                      </div>
                      <div className="">
                        {this.state.favourites.map((item) => {
                          return (
                            <div className="ms-Grid-col ms-xl4 ms-lg6 ms-md12 ms-sm12">
                              <VideoTutorialCardComponent
                                videoTutorial={item}
                                commonProps={this.props.commonProps}
                                selectVideoTutorial={this._changeVideoTutorial}
                                refreshList={this._refreshList}
                              ></VideoTutorialCardComponent>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                )}
                <div
                  className={
                    "ms-Grid-col ms-xl4 ms-lg12 ms-md12 ms-sm12 " +
                    styles.panel_filter
                  }
                >
                  <div id="filterLanguage" className={styles.divFilterLanguage}>
                    <Dropdown
                      placeholder="Filter language"
                      defaultSelectedKey={
                        this.state.languagesSelected.length > 1
                          ? "-1"
                          : this.state.languagesSelected[0]
                      }
                      onChange={(
                        event: React.FormEvent<HTMLDivElement>,
                        item: any
                      ) => {
                        if (item) {
                          this._changeLanguage(item);
                        }
                      }}
                      options={this._getLanguages()}
                      className={styles.filterLanguage}
                      onRenderOption={(op: any) => {
                        return (
                          <div className={"optionDropdown"}>
                            <FontIcon
                              iconName={
                                (this.state.languagesSelected.length == 1 &&
                                  op.key == this.state.languagesSelected[0]) ||
                                (op.key == "-1" &&
                                  this.state.languagesSelected.length > 1)
                                  ? "CheckboxComposite"
                                  : "Checkbox"
                              }
                              className={styles.IconGlobe}
                            />
                            {op.text}
                          </div>
                        );
                      }}
                      onRenderPlaceholder={() => {
                        return (
                          <div>
                            <FontIcon
                              iconName={"Globe"}
                              className={styles.IconGlobe}
                            />
                            <div className={styles.divTextSelected}>
                              Filter language
                            </div>
                          </div>
                        );
                      }}
                      onRenderTitle={(
                        options: IDropdownOption[] | undefined
                      ) => {
                        if (options) {
                          const option = options[0];
                          if (option.key == "-1") {
                            return (
                              <div>
                                <FontIcon
                                  iconName={"Globe"}
                                  className={styles.IconGlobe}
                                />
                                <div className={styles.divTextSelected}>
                                  {this.state.languagesCounters.map((x) => {
                                    return (
                                      <div className={styles.ItemSelected}>
                                        <div
                                          className={styles.TextItemSelected}
                                        >
                                          {x.language + " (" + x.count + ")"}
                                        </div>
                                        {/* <FontIcon
                                        iconName={"Cancel"}
                                        className={styles.IconItemSelected}
                                      /> */}
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            );
                          } else {
                            return (
                              <div>
                                <FontIcon
                                  iconName={"Globe"}
                                  className={styles.IconGlobe}
                                />
                                <div className={styles.divTextSelected}>
                                  {options.map((x) => {
                                    return (
                                      <div className={styles.ItemSelected}>
                                        <div
                                          className={styles.TextItemSelected}
                                        >
                                          {x.text}
                                        </div>
                                        {/* <FontIcon
                                        iconName={"Cancel"}
                                        className={styles.IconItemSelected}
                                      /> */}
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            );
                          }
                        } else {
                          return <div></div>;
                        }
                      }}
                      onRenderCaretDown={() => {
                        return (
                          <div>
                            <FontIcon
                              iconName={"ChevronDownMed"}
                              className={styles.IconGlobe}
                            />
                          </div>
                        );
                      }}
                    />
                  </div>
                </div>
                <div
                  className={
                    "ms-Grid-col ms-xl8 ms-lg12 ms-md12 ms-sm12 " +
                    styles.hiddenMobile
                  }
                >
                  <ul
                    className={"nav nav-tabs " + styles.navTabs}
                    role="tablist"
                  >
                    <li className={styles.tab_pnl_home}>
                      <a
                        className={
                          styles.navLink +
                          " " +
                          (this.state.roleSelected == ALL
                            ? styles.active
                            : styles.notActive)
                        }
                        data-toggle="tab"
                        role="tab"
                        aria-controls="tab1"
                        aria-selected="true"
                        onClick={() => {
                          this._changeRole(ALL);
                        }}
                      >
                        <div>
                          All (
                          {
                            this.state.videoTutorialCounters.filter(
                              (x) => x.role == ALL
                            )[0].count
                          }
                          )
                        </div>
                      </a>
                    </li>
                    {this.state.videoTutorialCounters
                      .filter((x) => x.role != ALL)
                      .map((counter) => {
                        return (
                          <li className={styles.tab_pnl_home}>
                            <a
                              className={
                                styles.navLink +
                                " " +
                                (this.state.roleSelected == counter.role
                                  ? styles.active
                                  : styles.notActive)
                              }
                              data-toggle="tab"
                              role="tab"
                              aria-controls="tab1"
                              aria-selected="true"
                              onClick={() => {
                                this._changeRole(counter.role);
                              }}
                            >
                              <div>
                                {counter.role + " (" + counter.count + ")"}
                              </div>
                            </a>
                          </li>
                        );
                      })}
                  </ul>
                </div>

                <div className="ms-Grid-col ms-xl12 ms-lg12 ms-md12 ms-sm12">
                  <div className={styles.margin_top_mobile}>
                    <div
                      className={styles.panel_state + " " + styles.onlyMobile}
                    >
                      <div className={styles.statePanel}>
                        <span className={styles.activeState}>
                          {this.state.roleSelected == ALL
                            ? "All"
                            : this.state.roleSelected}
                        </span>
                        <span
                          className={
                            styles.selState + " ms-Icon ms-Icon--Equalizer"
                          }
                          onClick={() => {
                            this.setState({
                              showMobileStates: !this.state.showMobileStates,
                            });
                          }}
                        ></span>
                      </div>
                      <div>
                        {this.state.showMobileStates && (
                          <ul
                            className={
                              "nav nav-tabs hidden-sm hidden-md hidden-lg " +
                              styles.navTabs
                            }
                            role="tablist"
                          >
                            <li
                              className={
                                styles.tab_pnl_home +
                                " ms-Grid-col ms-xl6 ms-lg6 ms-md6 ms-sm12"
                              }
                            >
                              <a
                                className={
                                  "nav-link " +
                                  " " +
                                  (this.state.roleSelected == ALL
                                    ? styles.active
                                    : styles.notActive)
                                }
                                data-toggle="tab"
                                role="tab"
                                aria-controls="tab1"
                                aria-selected="true"
                                onClick={() => {
                                  this._changeRole(ALL);
                                }}
                              >
                                <div>
                                  All (
                                  {
                                    this.state.videoTutorialCounters.filter(
                                      (x) => x.role == ALL
                                    )[0].count
                                  }
                                  )
                                </div>
                              </a>
                            </li>
                            {this.state.videoTutorialCounters
                              .filter((x) => x.role != ALL)
                              .map((counter) => {
                                return (
                                  <li
                                    className={
                                      styles.tab_pnl_home +
                                      " ms-Grid-col ms-xl6 ms-lg6 ms-md6 ms-sm12"
                                    }
                                  >
                                    <a
                                      className={
                                        styles.navLink +
                                        " " +
                                        (this.state.roleSelected == counter.role
                                          ? styles.active
                                          : styles.notActive)
                                      }
                                      data-toggle="tab"
                                      role="tab"
                                      aria-controls="tab1"
                                      aria-selected="true"
                                      onClick={() => {
                                        this._changeRole(counter.role);
                                      }}
                                    >
                                      <div>
                                        {counter.role +
                                          " (" +
                                          counter.count +
                                          ")"}
                                      </div>
                                    </a>
                                  </li>
                                );
                              })}
                          </ul>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className={styles.tab_content}>
                  <div id="tab1" role="tabpanel" aria-labelledby="tab1-tab">
                    {this.state.changeFilterLoading && (
                      <div
                        className={
                          "ms-Grid-col ms-xl12 ms-lg12 ms-md12 ms-sm12"
                        }
                      >
                        <LoaderComponent
                          commonProps={this.props.commonProps}
                          isLoading={this.state.changeFilterLoading}
                        ></LoaderComponent>
                      </div>
                    )}
                    {!this.state.changeFilterLoading && (
                      <div className="">
                        {this.state.filteredItems.length == 0 && (
                          <div
                            className={
                              "ms-Grid-col ms-xl12 ms-lg12 ms-md12 ms-sm12 " +
                              styles.noContacts
                            }
                          >
                            NO VIDEOS TO DISPLAY
                          </div>
                        )}
                        {this.state.filteredItems.map((item) => {
                          return (
                            <div className="ms-Grid-col ms-xl4 ms-lg6 ms-md12 ms-sm12">
                              <VideoTutorialCardComponent
                                videoTutorial={item}
                                commonProps={this.props.commonProps}
                                selectVideoTutorial={this._changeVideoTutorial}
                                refreshList={this._refreshList}
                              ></VideoTutorialCardComponent>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
