import { TFunction } from "i18next";
import { ActivityTranslation } from "../Translations/Activity.Translation";

interface IGenericValue {
    readonly id: number;
    readonly key: string;
    readonly text: string;
}


export interface ActivityStatus extends IGenericValue {
}

export interface ActivityType extends IGenericValue {

}

export interface View extends IGenericValue {

}

export class ActivityHelper {
    private readonly _translation: ActivityTranslation;
    private static _statuses: ActivityStatus[] = [];
    private static _types: ActivityType[] = [];
    private static _views: View[] = [];

    constructor(translation: TFunction) {
        this._translation = new ActivityTranslation(translation);
        ActivityHelper._statuses = this.loadStatuses();
        ActivityHelper._types = this.loadActivityTypes();
        ActivityHelper._views = this.loadViews();
    }

    private loadStatuses(): ActivityStatus[] {
        const values: ActivityStatus[] = [];
        values.push(this.toActivityStatus(0, "Planned"));
        // values.push(this.toActivityStatus(1, "Draft"));
        values.push(this.toActivityStatus(2, "In progress"));
        // values.push(this.toActivityStatus(3, "Rework"));
        // values.push(this.toActivityStatus(4, "Approved"));
        // values.push(this.toActivityStatus(5, "Ready for Distribution"));
        // values.push(this.toActivityStatus(6, "Distributed"));
        values.push(this.toActivityStatus(7, "Closed"));
        return values;
    }

    private loadActivityTypes(): ActivityType[] {
        const values: ActivityType[] = [];
        values.push(this.toActivityType(0, "all"));
        values.push(this.toActivityType(1, "macro"));
        values.push(this.toActivityType(2, "micro"));
        return values;
    }

    private loadViews(): View[] {
        const values: View[] = [];
        values.push(this.toGenericValue(0, "day", this._translation.date["day"]));
        values.push(this.toGenericValue(1, "week", this._translation.date["week"]));
        values.push(this.toGenericValue(2, "month", this._translation.date["month"]));
        values.push(this.toGenericValue(3, "year", this._translation.date["year"]));
        return values;
    }

    private toActivityStatus(id: number, key: string): ActivityStatus {
        return this.toGenericType("status", id, key);
    }

    private toActivityType(id: number, key: string): ActivityType {
        return this.toGenericType("type", id, key);
    }

    private toGenericType(parentPropertyName: string, id: number, key: string): IGenericValue {
        const fullKey = `${parentPropertyName}.${key.replace(/ /g, '').toLowerCase()}`;
        const text: string = this._translation.getByKey(fullKey);
        return this.toGenericValue(id, key, text);
    }

    private toGenericValue(id: number, key: string, text: string) {
        const value: IGenericValue = {
            id,
            key,
            text: text
        };
        return value;
    }

    public static get statuses(): ActivityStatus[] {
        return ActivityHelper._statuses;
    }

    public static get views(): View[] {
        return ActivityHelper._views;
    }

    public static get types(): ActivityType[] {
        return ActivityHelper._types;
    }
}