import clsx from "clsx";
import React from "react";
import { TooltipHost, TooltipDelay, DirectionalHint } from "@fluentui/react";
import { Utility } from "../../../../Utility/Utility";
import { ActivityTooltip } from "../../Planner/ActivityTooltip";

function stringifyPercent(v) {
  return typeof v === "string" ? v : v + "%";
}

/* eslint-disable react/prop-types */
function TimeGridEvent(props) {
  const {
    style,
    className,
    event,
    accessors,
    rtl,
    selected,
    label,
    continuesEarlier,
    continuesLater,
    getters,
    onClick,
    onDoubleClick,
    onKeyPress,
    components: { event: Event, eventWrapper: EventWrapper },
    commonProps: commonProps,
  } = props;
  let title = accessors.title(event);
  let tooltip = accessors.tooltip(event);
  let end = accessors.end(event);
  let start = accessors.start(event);

  let userProps = getters.eventProp(event, start, end, selected);

  let { height, top, width, xOffset } = style;
  const inner = [
    // <div key="1" className="rbc-event-label">
    //   {label}
    // </div>,
    <div key="2" className="rbc-event-content">
      {Event ? <Event event={event} title={title} /> : title}
    </div>,
  ];

  let eventStateColor = Utility.GetActivityClassNameFromStatus(
    event.state,
    false
  );

  let listaMicro = "";
  if (event.linkedActivities) {
    listaMicro = event.linkedActivities.map((micro) => (
      <TooltipHost
        calloutProps={{ gapSpace: 0 }}
        tooltipProps={{
          onRenderContent: () => renderActivityToolTip2(commonProps, micro),
        }}
        delay={TooltipDelay.zero}
        id={event.id}
        directionalHint={DirectionalHint.bottomCenter}
      >
        <div
          className="rbc-event-internal-div rbc-event-micro"
          tabIndex={0}
          onClick={onClick}
          onDoubleClick={onDoubleClick}
          onKeyPress={onKeyPress}
        >
          <div style={{ float: "left" }}>
            <div className="rbc-event-calendar">{micro.calendar}</div>
            <div
              className={[
                "rbc-event-dot",
                Utility.GetActivityClassNameFromStatus(micro.state, false),
              ].join(" ")}
            ></div>
          </div>
          <div className="rbc-event-content" title={tooltip || undefined}>
            {micro.title}
          </div>
          <div style={{ clear: "both" }}></div>
        </div>
      </TooltipHost>
    ));
  }
  //let noBackground = event.isMacro ? "" : "rbc-event-nobackground"
  let noBackground = "";

  return (
    <EventWrapper type="time" {...props}>
      <div
        style={{
          ...userProps.style,
          top: stringifyPercent(top),
          [rtl ? "right" : "left"]: stringifyPercent(xOffset),
          width: stringifyPercent(width),
          height: stringifyPercent(height),
        }}
        title={
          tooltip
            ? (typeof label === "string" ? label + ": " : "") + tooltip
            : undefined
        }
        className={clsx(
          "rbc-event",
          className,
          userProps.className,
          noBackground,
          {
            "rbc-selected": selected,
            "rbc-event-continues-earlier": continuesEarlier,
            "rbc-event-continues-later": continuesLater,
          }
        )}
      >
        <TooltipHost
          calloutProps={{ gapSpace: 0 }}
          tooltipProps={{
            onRenderContent: () => renderActivityToolTip2(commonProps, event),
          }}
          delay={TooltipDelay.zero}
          id={event.id}
          directionalHint={DirectionalHint.bottomCenter}
        >
          <div
            className="rbc-event-internal-div"
            tabIndex={0}
            onClick={onClick}
            onDoubleClick={onDoubleClick}
            onKeyPress={onKeyPress}
          >
            {event.isMacro && (
              <>
                <div className="rbc-event-macro">MACRO</div>
                <div className="rbc-event-nmicro">{event.Nmicro}</div>
              </>
            )}
            <div style={{ float: "left" }}>
              <div className="rbc-event-calendar">{event.calendar}</div>
              {!event.isMacro && (
                <div
                  key="1"
                  className={["rbc-event-dot", eventStateColor].join(" ")}
                ></div>
              )}
            </div>
            {inner}
          </div>
        </TooltipHost>
        {event.linkedActivities && <div>{listaMicro}</div>}
      </div>
    </EventWrapper>
  );
}

function renderActivityToolTip2(commonProps, event) {
  const activity = event;

  if (activity.linkedActivities) {
    activity.microsState = activity.linkedActivities.map((micro) => ({
      state: micro.state,
    }));
  }

  return (
    <ActivityTooltip
      commonProps={commonProps}
      activity={activity}
    ></ActivityTooltip>
  );
}

function renderActivityToolTip(event) {
  return (
    <div
      style={{
        paddingLeft: 12,
        paddingTop: 8,
        paddingRight: 12,
        minWidth: 300,
      }}
    >
      <div className="tooltip-title">{event.title}</div>
      <div className="tooltip-daterange">
        {event.start.toLocaleDateString()} - {event.end.toLocaleDateString()}
      </div>
      <div className="tooltip-line3">
        <div className="tooltip-resources" style={{ float: "left" }}>
          ***
        </div>
        {!event.isMacro && (
          <div style={{ float: "right", marginTop: 12 }}>
            <div
              className={[
                "rbc-event-dot",
                Utility.GetActivityClassNameFromStatus(event.state, false),
              ].join(" ")}
              style={{ float: "left" }}
            ></div>
            <div className="tooltip-state" style={{ float: "left" }}>
              {event.state}
            </div>
          </div>
        )}
      </div>
      <div style={{ clear: "both" }}></div>
      {event.macroName != undefined && (
        <>
          <div
            style={{
              height: 1,
              width: "100%",
              backgroundColor: "#C2CDDD",
              marginTop: 12,
            }}
          ></div>
          <div style={{ marginTop: 10 }}>
            <div className="tooltip-event-macro">MACRO</div>
            <div className="tooltip-macroinfo">{event.macroName}</div>
          </div>
        </>
      )}
    </div>
  );
}

export default TimeGridEvent;
