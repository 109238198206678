import * as React from "react";
import "./Macro.scss";
import { MediaPlanTranslation } from "./MediaPlan.Translation";
import {
  Checkbox,
  DefaultButton,
  IBasePicker,
  IBasePickerSuggestionsProps,
  ITag,
  TextField,
  TagPicker,
  PrimaryButton,
  MessageBar,
  MessageBarType,
  Modal,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";
import { IUserClient } from "../../../Clients/IUserClient";
import { IMacro, IMediaPlan } from "../../../Models/Activity";
import { UserInfo } from "../../../Models/User";
import { RangeCalendar } from "../../Shared/RangeCalendar/RangeCalendar";
import { CustomLabel } from "../../Shared/CustomLabel";
import { Constants } from "../../../Utility/Constants";
import { IDateRangeValue } from "../../Shared/RangeCalendar/IDateRangeValue";
import { IMacroClient } from "../../../Clients/IMacroClient";
import { Spoc } from "./Spoc";
import {
  IBaseProperties,
  ICommonProperties,
} from "../../../Models/IBaseProperties";
import { UsersTranslation } from "../../../Translations/Users.Translation";
import { ToastNotificationType } from "../../../Models/ToastNote";
import { Utility } from "../../../Utility/Utility";
import { ConcurrentTask } from "../../../Utility/ConcurrentTasks";
import RichTextEditor, { EditorValue } from "react-rte";

const panelStyle = {
  width: "100%",
  height: "100%",
};

const onRenderLabelNew = (
  commonProps: ICommonProperties,
  controlid: string,
  label: string,
  tooltip: string,
  required?: boolean,
  disableTooltip?: boolean
) => (p: any) => {
  return (
    <CustomLabel
      commonProps={commonProps}
      controlId={controlid}
      label={label}
      tooltiptext={tooltip}
      required={required}
      disableTooltip={disableTooltip}
    ></CustomLabel>
  );
};

export interface IMediaPlanProps extends IBaseProperties {
  className: string | undefined;
  macro: IMacro;
  onCancel: () => void;
  onChange: (value: IMediaPlan | undefined) => void;
  onSave: (newMacro: IMacro) => void;
}
export interface IMediaPlanState {
  rangeDateValue: IDateRangeValue | undefined;
  name?: string;
  calendar: number;
  description: EditorValue;
  spocId?: string;
  selectedSpoc: ITag[];
  headOfActivity?: string;
  resources: string[];
  showExternalResource: boolean;
  externalResources: string[];
  selectedExternalResource: ITag[] | undefined;
  selectedResource: ITag[] | undefined;
  isSaving: boolean;
  showError: boolean;
  messageError?: string;
  messageType: MessageBarType;
}

export class MediaPlan extends React.Component<
  IMediaPlanProps,
  IMediaPlanState
> {
  private readonly minDigitBeforeSearch: number;
  private readonly DEFAULTMINDIGIT = 3;
  private readonly _translation: MediaPlanTranslation;
  private readonly _usersTranslation: UsersTranslation;
  private readonly _userClient: IUserClient;
  private readonly _macroClient: IMacroClient;
  private readonly _concurrentTaskExtResource: ConcurrentTask<
    UserInfo[] | undefined
  > = new ConcurrentTask<UserInfo[] | undefined>();
  private readonly _concurrentTaskInternalResource: ConcurrentTask<
    UserInfo[] | undefined
  > = new ConcurrentTask<UserInfo[] | undefined>();

  constructor(props: IMediaPlanProps) {
    super(props);
    this._macroClient = props.commonProps.clientCreator.createMacroClient();
    this._translation = new MediaPlanTranslation(props.commonProps.translation);
    this._usersTranslation = new UsersTranslation(
      props.commonProps.translation
    );
    this.minDigitBeforeSearch = this.DEFAULTMINDIGIT;
    this.state = {
      headOfActivity: undefined,
      name: "Piano media",
      description: RichTextEditor.createEmptyValue(), //"",
      showExternalResource: false,
      selectedSpoc: [],
      rangeDateValue: undefined,
      resources: [],
      externalResources: [],
      selectedExternalResource: undefined,
      selectedResource: undefined,
      showError: false,
      isSaving: false,
      calendar: 0,
      messageType: MessageBarType.info,
    };

    this._userClient = props.commonProps.clientCreator.createUserClient();

    this.pickerExternalResourceMultipleItemChange = this.pickerExternalResourceMultipleItemChange.bind(
      this
    );
    this.pickerResourceMultipleItemChange = this.pickerResourceMultipleItemChange.bind(
      this
    );
    this.titleChange = this.titleChange.bind(this);
    this.descriptionChange = this.descriptionChange.bind(this);
    this.pickerSpocChange = this.pickerSpocChange.bind(this);
    this.headOfActivityChange = this.headOfActivityChange.bind(this);
    this.closePanel = this.closePanel.bind(this);
    this.rangeCalendarChange = this.rangeCalendarChange.bind(this);
    this.addExternalResourceChange = this.addExternalResourceChange.bind(this);
    this.save = this.save.bind(this);
    this.closeMessageBarError = this.closeMessageBarError.bind(this);
  }

  async componentDidMount(): Promise<void> {}

  private readonly externalResourcePicker = React.createRef<
    IBasePicker<ITag>
  >();
  private readonly resourcePicker = React.createRef<IBasePicker<ITag>>();

  private get getIMediaPlan(): IMediaPlan | undefined {
    let mediaPlan: IMediaPlan | undefined;
    const {
      rangeDateValue,
      name,
      description,
      spocId,
      resources,
      externalResources,
      calendar,
    } = this.state;

    if (rangeDateValue && name) {
      mediaPlan = {
        name: name,
        calendar: calendar,
        isMacro: false,
        description: description?.toString("html"), // description,
        dateFrom: rangeDateValue.fromDate,
        dateTo: rangeDateValue.toDate,
        spocUPN: spocId,
        resourcesUPNs: resources,
        externalResourcesUPNs: externalResources,
        permissions: Utility.getDefaultActivityPermissions(),
      };
    }

    return mediaPlan;
  }

  private currentListContainsTag = (tag: ITag, tagList?: ITag[]) => {
    if (!tagList || !tagList.length || tagList.length === 0) {
      return false;
    }
    return tagList.some((compareTag) => compareTag.key === tag.key);
  };

  private readonly UserInfoToITag = (item: UserInfo): ITag =>
    ({ key: item.username, name: item.displayName } as ITag);
  private readonly checkExternalResourceSelectedItem = (
    selectedItem?: ITag | undefined
  ): ITag | PromiseLike<ITag> | null => {
    return this.checkForDuplicate(selectedItem, this.externalResourcePicker);
  };

  private readonly checkResourceSelectedItem = (
    selectedItem?: ITag | undefined
  ): ITag | PromiseLike<ITag> | null => {
    return this.checkForDuplicate(selectedItem, this.resourcePicker);
  };

  private readonly checkForDuplicate = (
    selectedItem?: ITag | undefined,
    picker?: React.RefObject<IBasePicker<ITag>>
  ): ITag | PromiseLike<ITag> | null => {
    if (
      selectedItem &&
      picker?.current &&
      this.currentListContainsTag(selectedItem as ITag, picker.current.items)
    ) {
      return null;
    }

    return selectedItem as ITag;
  };

  private closeMessageBarError() {
    this.setState({ showError: false });
  }

  private async getExternalResourceByFilterAsync(
    filter: string
  ): Promise<ITag[]> {
    const promise = () =>
      this._userClient.getExternalResourceFilterAsync({term: filter, calendar: this.state.calendar});

    const result = await this._concurrentTaskExtResource.executePromise(
      promise,
      []
    );

    if (result) {
      return result.map(this.UserInfoToITag);
    } else {
      this.props.commonProps.toastComponent?.showMessage(
        this._translation.error,
        this._usersTranslation.genericGetUsersError,
        ToastNotificationType.ERROR
      );
      return [];
    }
  }

  private async getInternalResourceByFilterAsync(
    filter: string
  ): Promise<ITag[]> {
    const promise = () =>
      this._userClient.getInternalResourceFilterAsync({term: filter, calendar: this.state.calendar});

    const result = await this._concurrentTaskInternalResource.executePromise(
      promise,
      []
    );

    if (result) {
      return result.map(this.UserInfoToITag);
    } else {
      this.props.commonProps.toastComponent?.showMessage(
        this._translation.error,
        this._usersTranslation.genericGetUsersError,
        ToastNotificationType.ERROR
      );
      return [];
    }
  }

  private async getSpocFilterAsync(filter: string): Promise<ITag[]> {
    const result = await this._userClient.getSpocByFilterAsync({term: filter,calendar: this.state.calendar});
    if (result) {
      return result.map(this.UserInfoToITag);
    } else {
      this.props.commonProps.toastComponent?.showMessage(
        this._translation.error,
        this._usersTranslation.genericGetUsersError,
        ToastNotificationType.ERROR
      );
      return [];
    }
  }

  private get pickerSuggestionsProps(): IBasePickerSuggestionsProps {
    const props: IBasePickerSuggestionsProps = {
      suggestionsHeaderText: this._translation.suggestedUser,
      noResultsFoundText: this._translation.noUsersFound,
    };
    return props;
  }

  private readonly filterAssignActivity = (
    filter: string,
    selectedItems?: ITag[] | undefined
  ): ITag[] | PromiseLike<ITag[]> => {
    if (filter) {
      if (filter.length >= this.minDigitBeforeSearch) {
        this.pickerSuggestionsProps.noResultsFoundText = this._translation.noUsersFound;
        return this.getInternalResourceByFilterAsync(filter);
      } else {
        this.pickerSuggestionsProps.noResultsFoundText = this._translation.minDigitBeforeSearch(
          this.minDigitBeforeSearch
        );
        return [];
      }
    } else {
      return [];
    }
  };

  private readonly filterExternalResource = (
    filter: string,
    selectedItems?: ITag[] | undefined
  ): ITag[] | PromiseLike<ITag[]> => {
    if (filter) {
      if (filter.length >= this.minDigitBeforeSearch) {
        this.pickerSuggestionsProps.noResultsFoundText = this._translation.noUsersFound;
        return this.getExternalResourceByFilterAsync(filter);
      } else {
        this.pickerSuggestionsProps.noResultsFoundText = this._translation.minDigitBeforeSearch(
          this.minDigitBeforeSearch
        );
        return [];
      }
    } else {
      return [];
    }
  };

  private pickerExternalResourceMultipleItemChange(
    items?: ITag[] | undefined
  ): void {
    if (items) {
      const ids: string[] = (items as ITag[]).map((t) => t.key as string);
      this.setState({
        externalResources: ids,
        selectedExternalResource: items,
      });
    }
  }

  private pickerResourceMultipleItemChange(items?: ITag[] | undefined): void {
    if (items) {
      const ids: string[] = (items as ITag[]).map((t) => t.key as string);
      this.setState({ resources: ids, selectedResource: items });
    }
  }

  private headOfActivityChange(items?: ITag[] | undefined): void {
    if (items && (items as ITag[]).length === 1) {
      const id: string = (items as ITag[])[0].key as string;

      this.setState({ headOfActivity: id });
    } else {
      this.setState({ headOfActivity: undefined });
    }
  }

  private pickerSpocChange(selected: ITag[], spocId?: string): void {
    this.setState({ spocId: spocId, selectedSpoc: selected });
  }

  private get canSave(): boolean {
    const { rangeDateValue, name } = this.state;

    //check for mandatory properties
    return rangeDateValue && name ? true : false;
  }

  private closePanel(): void {
    this.props.onCancel();
  }

  private save(
    event: React.MouseEvent<
      | HTMLAnchorElement
      | HTMLButtonElement
      | HTMLDivElement
      | import("@fluentui/react").BaseButton
      | import("@fluentui/react").Button
      | HTMLSpanElement,
      MouseEvent
    >
  ): void | undefined {
    event.preventDefault();

    this.setState({ isSaving: true }, () => {
      this.saveMacroAsync();
    });
  }

  private async saveMacroAsync() {
    try {
      const mediaPlan = this.getIMediaPlan;
      let macro = Object.assign({}, this.props.macro);
      macro.mediaPlan = mediaPlan;

      if (macro) {
        //check if macro end date is less than media end date, if true, the macro end date will be extended
        if (mediaPlan && macro.dateTo < mediaPlan.dateTo) {
          macro.dateTo = mediaPlan.dateTo.toEndOfDay();
        }

        const retValue = await this._macroClient.saveMacroAsync(macro);

        if (retValue) {
          this.props.onSave(macro);
          this.setState({ messageError: undefined, showError: false });
        } else {
          this.setState({
            messageError: this.genericErrorMessage,
            showError: true,
            messageType: MessageBarType.error,
          });
        }
      }
    } catch (error) {
      console.error(error);
      this.setState({
        messageError: this.genericErrorMessage,
        showError: true,
        messageType: MessageBarType.error,
      });
    } finally {
      this.setState({ isSaving: false });
    }
  }

  private get genericErrorMessage(): string {
    return this._translation.genericErrorInSave;
  }

  private renderExternalResource(): JSX.Element {
    const externalResoucePicker = (
      <>
        <CustomLabel
          commonProps={this.props.commonProps}
          controlId="externalResources"
          label={this._translation.externalResource}
          tooltiptext="External resource"
          required={false}
          disableTooltip={false}
        ></CustomLabel>
        <TagPicker
          ref={this.externalResourcePicker}
          pickerSuggestionsProps={this.pickerSuggestionsProps}
          onItemSelected={this.checkExternalResourceSelectedItem}
          removeButtonAriaLabel="Remove"
          onResolveSuggestions={this.filterExternalResource}
          resolveDelay={300}
          onChange={this.pickerExternalResourceMultipleItemChange}
          styles={Constants.pickerStyle}
          selectedItems={this.state.selectedExternalResource}
        ></TagPicker>
      </>
    );

    return (
      <React.Fragment>
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-Grid-col-width100">
            {externalResoucePicker}
          </div>
        </div>
      </React.Fragment>
    );
  }

  render() {
    const showError = this.state.showError;
    const isExternalResourceVisibile = this.state.showExternalResource;
    const jsxError = (
      <div className="ms-Grid-row ">
        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 messageError">
          <MessageBar
            messageBarType={this.state.messageType}
            isMultiline={false}
            dismissButtonAriaLabel="Close"
            onDismiss={this.closeMessageBarError}
          >
            {this.state.messageError}
          </MessageBar>
        </div>
      </div>
    );
    const jsxRangeCalendar = (
      <>
        <CustomLabel
          commonProps={this.props.commonProps}
          controlId="rangeCalendar"
          label={this._translation.timePeriod}
          tooltiptext="Time period"
          required={true}
          disableTooltip={false}
        ></CustomLabel>
        <RangeCalendar
          commonProps={this.props.commonProps}
          showTime={true}
          disableTime={false}
          onChange={this.rangeCalendarChange}
          value={this.state.rangeDateValue}
          minDate={this.props.macro.dateFrom}
          // maxDate={this.props.macro.dateTo}
        ></RangeCalendar>
      </>
    );
    const jsxActivityTitle = (
      <TextField
        id="activityTitle"
        styles={Constants.textFieldStyle}
        maxLength={254}
        placeholder="Piano media"
        value={this.state.name}
        onChange={this.titleChange}
        onRenderLabel={onRenderLabelNew(
          this.props.commonProps,
          "activityTitle",
          this._translation.activityTitle,
          "Activity title tool tip",
          true,
          true
        )}
      ></TextField>
    );
    const jsxActivityDescription = (
      // <TextField
      //   id="activityDescription"
      //   multiline={true}
      //   styles={Constants.textFieldAreaStyle}
      //   placeholder={this._translation.description}
      //   value={this.state.description}
      //   onChange={this.descriptionChange}
      //   onRenderLabel={onRenderLabelNew(
      //     this.props.commonProps,
      //     "activityDescription",
      //     this._translation.description,
      //     "Activity description tool tip",
      //     false,
      //     true
      //   )}
      // ></TextField>
      <RichTextEditor
        // id="activityDescription"
        // multiline={true}
        // styles={Constants.textFieldAreaStyle}
        placeholder={this._translation.description}
        value={this.state.description}
        onChange={this.descriptionChange}
        toolbarConfig={Constants.toolbarRichTextEditor}
        className={"RichTextboxCustom"}
        // onRenderLabel={onRenderLabelNew(
        //   this.props.commonProps,
        //   "activityDescription",
        //   this._translation.description,
        //   "Activity description tool tip",
        //   false,
        //   true
        // )}
      >
        {" "}
      </RichTextEditor>
    );
    const jsxAssignActivity = (
      <>
        <CustomLabel
          commonProps={this.props.commonProps}
          controlId="assignActivity"
          label={this._translation.assignActivity}
          tooltiptext="Assign activity"
          required={false}
          disableTooltip={false}
        ></CustomLabel>
        <TagPicker
          ref={this.resourcePicker}
          pickerSuggestionsProps={this.pickerSuggestionsProps}
          onItemSelected={this.checkResourceSelectedItem}
          removeButtonAriaLabel="Remove"
          onResolveSuggestions={this.filterAssignActivity}
          resolveDelay={300}
          onChange={this.pickerResourceMultipleItemChange}
          styles={Constants.pickerStyle}
        ></TagPicker>
      </>
    );
    const jsxChooseSpoc = (
      <>
        <CustomLabel
          commonProps={this.props.commonProps}
          controlId="chooseSpoc"
          label={this._translation.chooseSpoc}
          tooltiptext="choose spoc"
          required={false}
          disableTooltip={false}
        ></CustomLabel>
        <Spoc
          commonProps={this.props.commonProps}
          styles={Constants.pickerStyle}
          onChange={this.pickerSpocChange}
          values={this.state.selectedSpoc}
          calendar={this.state.calendar}
        ></Spoc>
      </>
    );
    const jsxAddExternalResource = (
      <>
        <Checkbox
          id="addExternalResesource"
          label={this._translation.addExternalResource}
          onChange={this.addExternalResourceChange}
          styles={Constants.checkBoxStyle}
          onRenderLabel={onRenderLabelNew(
            this.props.commonProps,
            "addExternalResesource",
            this._translation.addExternalResource,
            "",
            false,
            true
          )}
        />
      </>
    );
    const jsxToolbarCommandButtons = (
      <>
        <DefaultButton
          alt={this._translation.cancel}
          onClick={this.closePanel}
          styles={Constants.iStandardButton}
        >
          {this._translation.cancel}
        </DefaultButton>
        <PrimaryButton
          alt={this._translation.addToCalendar}
          onClick={this.save}
          disabled={!this.canSave}
          styles={Constants.iPrincipalButton}
        >
          {this._translation.addToCalendar}
        </PrimaryButton>
      </>
    );
    const jsxSpinner = (
      <Modal titleAriaId="load" isOpen={this.state.isSaving}>
        <Spinner
          size={SpinnerSize.large}
          label={this._translation.savingData}
          style={{ minHeight: 176 }}
        />
      </Modal>
    );
    return (
      <React.Fragment>
        {jsxSpinner}
        <div
          className={this.props.className + " panel-add-activity"}
          style={panelStyle}
        >
          <div className="ms-Grid" dir="ltr">
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                {jsxRangeCalendar}
              </div>
            </div>
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                {jsxActivityTitle}
              </div>
            </div>
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                {jsxActivityDescription}
              </div>
            </div>
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                {jsxAssignActivity}
              </div>
            </div>

            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                {jsxChooseSpoc}
              </div>
            </div>
            <div className="ms-Grid-row briefPadding">
              <div className="ms-Grid-col">{jsxAddExternalResource}</div>
            </div>

            {isExternalResourceVisibile && this.renderExternalResource()}

            <div className="ms-Grid-row">
              <br></br>{" "}
            </div>
            {showError && jsxError}
            <div className="ms-Grid-row commandFooter">
              <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                {jsxToolbarCommandButtons}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
  titleChange(
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined
  ) {
    this.setState({ name: newValue });
  }

  descriptionChange(
    //event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue: EditorValue
  ) {
    this.setState({ description: newValue });
  }

  rangeCalendarChange(newValue?: IDateRangeValue | undefined) {
    this.setState({ rangeDateValue: newValue }, () =>
      this.compareMediaMacroDate()
    );
  }

  private compareMediaMacroDate() {
    const { rangeDateValue } = this.state;

    if (rangeDateValue) {
      if (rangeDateValue.toDate > this.props.macro.dateTo) {
        this.setState({
          messageError: this._translation.warningCompareMacroMediaDate,
          showError: true,
          messageType: MessageBarType.info,
        });
      }
    } else {
      this.setState({ messageError: undefined, showError: false });
    }
  }

  private addExternalResourceChange(
    ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
    checked?: boolean | undefined
  ) {
    const checkValue: boolean = checked ? true : false;
    this.setState({ showExternalResource: checkValue });
  }
}
