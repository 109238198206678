import * as React from "react";
import { filter } from 'rxjs/operators';
import notification_icon from "../../../../Style/Images/ICO-SMALL-notification.svg";
import { IBaseProperties } from "../../../../Models/IBaseProperties";
import { INotification, NotificationSubType, NotificationType } from "../../../../Models/Notification";
import { Observer, Subscription } from "rxjs";

export interface ICellNotificationProps extends IBaseProperties {
    imageClassName: string;
    id: number;    
}

export interface ICellNotificationState {
    showNotification: boolean;    
}

export class CellNotification extends React.Component<ICellNotificationProps, ICellNotificationState> {
    private _subscription?: Subscription;

    constructor(props: ICellNotificationProps) {
        
        super(props);

        this.state = {
            showNotification: false
        };
                  
    }

    componentWillMount() {
        
        const observer: Observer<INotification> = {
            next: (value:INotification) => {                
                const showNotification = value.subType === NotificationSubType.openedbrief ? false : true;
                this.setState({ 
                    showNotification: showNotification,
                    }
                );},
            error: err => console.error('Observer got an error: ' + err),
            complete: () => console.log('Observer got a complete notification'),
          };

        const filterObs = this.props.commonProps.onNotification?.pipe(filter(item => item.id === this.props.id && item.type === NotificationType.Brief && item.subType < 200));
        this._subscription = filterObs?.subscribe(observer);
        
      }

      componentWillUnmount() {
          if (this._subscription)
          {
            this._subscription.unsubscribe();
          }
        
      }
   
    render()    
    {
        const {showNotification} = this.state;
        const {imageClassName} = this.props;

        return (showNotification && <img src={notification_icon} className={imageClassName} alt="Notification"></img>);
    }
}