import * as React from "react";
import styles from "./ActivityCardComponent.module.scss";
import {
  IBaseProperties,
  IRoute,
  Page,
} from "../../../../../Models/IBaseProperties";
import {
  ActivityStatusEnum,
  IActivityAllAttributes,
} from "../../../../../Models/Activity";
import { Utility } from "../../../../../Utility/Utility";
import { ActivityTranslation } from "../../../../../Translations/Activity.Translation";
import {
  ActivityHistory,
  ActivityHistoryType,
} from "../../../../../Models/ActivityHistory";

export interface IActivityCardComponentProps extends IBaseProperties {
  activity: IActivityAllAttributes;
}
export interface IActivityCardComponentState {}

export class ActivityCardComponent extends React.Component<
  IActivityCardComponentProps,
  IActivityCardComponentState
> {
  private readonly _translation: ActivityTranslation;
  constructor(props: IActivityCardComponentProps) {
    super(props);
    this._translation = new ActivityTranslation(
      this.props.commonProps.translation
    );
    this.state = {};
  }

  private _getActionText(action: ActivityHistory): string {
    var retValue: string = "";
    switch (action.type) {
      case ActivityHistoryType.StatusChange:
        if (
          action.description &&
          action.description.currentState != undefined
        ) {
          retValue = this._translation.statusChangeActionDescriptionDashboard(
            ActivityStatusEnum[action.description.currentState]
          );
        }
        break;
    }
    return retValue;
  }

  render() {
    return (
      <div role="activityCard" className={styles.activityCard}>
        <div className={styles.card_body}>
          <div className={styles.card_body_header}>
            <h6
              className={
                styles.cardState +
                " " +
                styles.card_subtitle +
                " ms-Grid-col ms-xl6 ms-lg6 ms-md6 ms-sm12"
              }
            >
              <div
                className={
                  styles.rbc_event_dot +
                  " " +
                  Utility.GetActivityClassNameFromStatus(
                    this.props.activity.status,
                    true
                  )
                }
                // alt={item.fileType + " file icon"}
              />
              {this.props.activity.status}
            </h6>
            <h6
              className={
                styles.cardDate +
                " " +
                styles.card_subtitle +
                " " +
                styles.dateLabel +
                " ms-Grid-col ms-xl6 ms-lg6 ms-md6 ms-sm12"
              }
            >
              <span className="">
                {this._translation.lastModify +
                  ": " +
                  Utility.FormatDate(this.props.activity.lastUpdate)}
              </span>

              <span className="">
                {this._translation.start +
                  ": " +
                  Utility.FormatDate(this.props.activity.dateFrom)}
              </span>
              <span className="">
                {this._translation.end +
                  ": " +
                  Utility.FormatDate(this.props.activity.dateTo)}
              </span>
            </h6>
          </div>
          <div className={styles.card_body_content}>
            <h5
              className={
                styles.card_title +
                " " +
                styles.linkEdit +
                " " +
                styles.truncate_overflow_parent
              }
              aria-label={"View " + this.props.activity.name}
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  window.scrollTo(0, 0);
                  const route: IRoute = {
                    page: Page.Activities,
                    additionalInfo: {
                      keysValues: {
                        id: this.props.activity.id?.toString(),
                        type: "2",
                        subtype: undefined,
                        title: undefined,
                        showMyActivities: undefined,
                      },
                      // optionalValues: {
                      //   status: currentRow.FirstColumn.StateName,
                      // },
                    },
                  };
                  this.props.commonProps.onRoute?.next(route);
                  // window.location.href =
                  //   "/?page=Activities&type=2&activity=" +
                  //   this.props.activity.id?.toString();
                }
              }}
              onClick={() => {
                window.scrollTo(0, 0);
                const route: IRoute = {
                  page: Page.Activities,
                  additionalInfo: {
                    keysValues: {
                      id: this.props.activity.id?.toString(),
                      type: "2",
                      subtype: undefined,
                      title: undefined,
                      showMyActivities: undefined,
                    },
                    // optionalValues: {
                    //   status: currentRow.FirstColumn.StateName,
                    // },
                  },
                };
                this.props.commonProps.onRoute?.next(route);
                // window.location.href =
                //   "/?page=Activities&type=2&activity=" +
                //   this.props.activity.id?.toString();
              }}
            >
              {this.props.activity.name}
            </h5>
            <div className={styles.typeDetails + " typeDetails"}>{"MICRO"}</div>
          </div>
          <div className={styles.card_body_footer}>
            {this.props.activity.lastAction && (
              <p className={styles.card_text + " " + styles.card_greytext}>
                <span className={styles.date}>
                  {Utility.FormatDate(
                    this.props.activity.lastAction?.actionDate
                  ) + " "}
                </span>
                <span className={styles.author}>
                  {this.props.activity.lastAction?.user?.displayName
                    ? this.props.activity.lastAction?.user?.displayName
                    : "User"}
                </span>
                {this.props.activity.lastAction
                  ? " " + this._getActionText(this.props.activity.lastAction)
                  : " "}
                <span className={styles.date}>
                  {Utility.FormatTime(
                    this.props.activity.lastAction?.actionDate
                  )}
                </span>
              </p>
            )}
          </div>
        </div>
      </div>
    );
  }
}
