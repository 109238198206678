import { TFunction } from "i18next";
import { CommonTranslation } from "./Common.Translation";

export class SupportTranslation extends CommonTranslation {
  constructor(translation: TFunction) {
    super(translation, "support");
  }

  public get headerSupport(): string {
    return super.getTextByMessageKey("headerSupport");
  }

  public get textSupport(): string {
    return super.getTextByMessageKey("textSupport");
  }

  public get backToSupportText(): string {
    return super.getTextByMessageKey("backToSupportText");
  }

  public get textUserGuides(): string {
    return super.getTextByMessageKey("textUserGuides");
  }

  public get textVideoTutorials(): string {
    return super.getTextByMessageKey("textVideoTutorials");
  }

  public get textFAQ(): string {
    return super.getTextByMessageKey("textFAQ");
  }

  public get footerHeaderQuestions(): string {
    return super.getTextByMessageKey("footerHeaderQuestions");
  }

  public get footerTextQuestions(): string {
    return super.getTextByMessageKey("footerTextQuestions");
  }

  public get footerHrefTextQuestions(): string {
    return super.getTextByMessageKey("footerHrefTextQuestions");
  }

  public get footerHeaderTechSupport(): string {
    return super.getTextByMessageKey("footerHeaderTechSupport");
  }

  public get footerTextTechSupport(): string {
    return super.getTextByMessageKey("footerTextTechSupport");
  }

  public get footerHrefTextTechSupport(): string {
    return super.getTextByMessageKey("footerHrefTextTechSupport");
  }

  public get headerUserGuides(): string {
    return super.getTextByMessageKey("headerUserGuides");
  }

  public get headerVideoTutorials(): string {
    return super.getTextByMessageKey("headerVideoTutorials");
  }

  public get headerFAQ(): string {
    return super.getTextByMessageKey("headerFAQ");
  }

  public get headerFavourites(): string {
    return super.getTextByMessageKey("headerFavourites");
  }

  public statusChangeActionDescriptionDashboard(status: string): string {
    return super.getTextByMessageKey("statusChangeActionDescriptionDashboard", {
      status: status,
    });
  }

  public getByKey(key: string): string {
    return super.getTextByMessageKey(key);
  }

  public get filterByTypeAllActivities(): string {
    return super.getTextByMessageKey("type.all");
  }
}
