import React from "react";
import { UsersTranslation } from "../../../Translations/Users.Translation";
import {
  Autofill,
  IBasePicker,
  IBasePickerStyles,
  IBasePickerSuggestionsProps,
  ITag,
  TagPicker,
} from "@fluentui/react";
import { UserInfo } from "../../../Models/User";
import { IUserClient } from "../../../Clients/IUserClient";
import { IBaseProperties } from "../../../Models/IBaseProperties";
import { ToastNotificationType } from "../../../Models/ToastNote";
import { ConcurrentTask } from "../../../Utility/ConcurrentTasks";

export interface IHeadOfActivityProps extends IBaseProperties {
  styles?: Partial<IBasePickerStyles>;
  values: ITag[];
  onChange?: (selectedTags: ITag[], headOfActivityId?: string) => void;
  onBlur?: (selectedTags: ITag[], headOfActivityId?: string) => void;
  minDigitBeforeSearch?: number;
  calendar: number;
}
export interface IHeadOfActivityState {
  headOfActivity?: string;
  selectedHeadOfActivity: ITag[];
}

export class HeadOfActivity extends React.Component<
  IHeadOfActivityProps,
  IHeadOfActivityState
> {
  private readonly _translation: UsersTranslation;
  private readonly headOfActivityPicker = React.createRef<IBasePicker<ITag>>();
  private readonly _userClient: IUserClient;
  private readonly _concurrentTask: ConcurrentTask<
    UserInfo[] | undefined
  > = new ConcurrentTask<UserInfo[] | undefined>();

  private readonly UserInfoToITag = (item: UserInfo): ITag =>
    ({ key: item.username, name: item.displayName } as ITag);

  private readonly filterHeadOfActivity = (
    filter: string,
    selectedItems?: ITag[] | undefined
  ): ITag[] | PromiseLike<ITag[]> => {
    if (filter) {
      if (filter.length >= this.minDigitBeforeSearch) {
        this.pickerSuggestionsProps.noResultsFoundText = this._translation.noUsersFound;
        return this.getHeadOfActivityUserByFilterAsync(filter);
      } else {
        this.pickerSuggestionsProps.noResultsFoundText = this._translation.minDigitBeforeSearch(
          this.minDigitBeforeSearch
        );
        return [];
      }
    } else {
      return [];
    }
  };

  private get pickerSuggestionsProps(): IBasePickerSuggestionsProps {
    const props: IBasePickerSuggestionsProps = {
      suggestionsHeaderText: this._translation.suggestedUser,
      noResultsFoundText: this._translation.noUsersFound,
    };
    return props;
  }

  private async getHeadOfActivityUserByFilterAsync(
    filter: string
  ): Promise<ITag[]> {
    const promise = () => this._userClient.getHeadOfActivityFilterAsync({term: filter, calendar: this.props.calendar});

    const result = await this._concurrentTask.executePromise(promise, []);

    if (result) {
      return result.map(this.UserInfoToITag);
    } else {
      this.props.commonProps.toastComponent?.showMessage(
        this._translation.error,
        this._translation.genericGetUsersError,
        ToastNotificationType.ERROR
      );
      return [];
    }

    // const result = await this._userClient.getHeadOfActivityFilterAsync(filter);
    // if(result)
    // {
    //   return result.map(this.UserInfoToITag);
    // }
    // else
    // {
    //   this.props.commonProps.toastComponent?.showMessage(this._translation.error, this._translation.genericGetUsersError, ToastNotificationType.ERROR);
    //   return [];
    // }
  }

  private minDigitBeforeSearch: number;
  private readonly DEFAULTMINDIGIT = 3;

  constructor(props: IHeadOfActivityProps) {
    super(props);
    this._translation = new UsersTranslation(props.commonProps.translation);
    this._userClient = props.commonProps.clientCreator.createUserClient();
    this.minDigitBeforeSearch = this.props.minDigitBeforeSearch
      ? this.props.minDigitBeforeSearch
      : this.DEFAULTMINDIGIT;

    this.state = {
      selectedHeadOfActivity: props.values,
    };

    this.bindEvents();
  }

  private bindEvents() {
    this.headOfActivityChange = this.headOfActivityChange.bind(this);
    this.headOfActivityBlur = this.headOfActivityBlur.bind(this);
  }

  private readonly checkSelectedItem = (
    selectedItem?: ITag | undefined
  ): ITag | PromiseLike<ITag> | null => {
    if (
      selectedItem &&
      this.headOfActivityPicker.current &&
      this.currentListContainsTag(
        selectedItem as ITag,
        this.headOfActivityPicker.current.items
      )
    ) {
      return null;
    }

    return selectedItem as ITag;
  };

  private currentListContainsTag = (tag: ITag, tagList?: ITag[]) => {
    if (!tagList || !tagList.length || tagList.length === 0) {
      return false;
    }
    return tagList.some((compareTag) => compareTag.key === tag.key);
  };

  private headOfActivityChange(items?: ITag[] | undefined): void {
    if (this.props.onChange) {
      if (items && (items as ITag[]).length === 1) {
        const id: string = (items as ITag[])[0].key as string;

        this.setState({ headOfActivity: id, selectedHeadOfActivity: items });
        this.props.onChange(items, id);
      } else {
        this.setState({
          headOfActivity: undefined,
          selectedHeadOfActivity: [],
        });
        this.props.onChange([], undefined);
      }
    }
  }

  private headOfActivityBlur(
    event: React.FocusEvent<Autofill | HTMLInputElement>
  ): void {
    if (this.props.onBlur) {
      if (this.headOfActivityPicker.current?.items?.length == 1) {
        const id: string = (this.headOfActivityPicker.current
          ?.items as ITag[])[0].key as string;

        this.setState({
          headOfActivity: id,
          selectedHeadOfActivity: this.headOfActivityPicker.current?.items,
        });
        this.props.onBlur(this.headOfActivityPicker.current?.items, id);
      } else {
        this.setState({
          headOfActivity: undefined,
          selectedHeadOfActivity: [],
        });
        this.props.onBlur([], undefined);
      }
    }
  }

  render() {
    const jsxHeadOfActivity = (
      <TagPicker
        ref={this.headOfActivityPicker}
        onItemSelected={this.checkSelectedItem}
        pickerSuggestionsProps={this.pickerSuggestionsProps}
        removeButtonAriaLabel={this._translation.remove}
        onResolveSuggestions={this.filterHeadOfActivity}
        resolveDelay={300}
        itemLimit={1}
        onChange={this.headOfActivityChange}
        onBlur={this.headOfActivityBlur}
        selectedItems={this.state.selectedHeadOfActivity}
        styles={this.props.styles}
      ></TagPicker>
    );

    return jsxHeadOfActivity;
  }
}
