import { IResponsabilityDTO } from './../Models/DTO/IResponsabilityDTO';
import { bool } from 'prop-types';
import { SupportCounterDTO } from './../Models/DTO/SupportCounterDTO';
import { FaqDTO } from './../Models/DTO/FaqDTO';
import { VideoTutorialDTO } from './../Models/DTO/VideoTutorialDTO';
import { UserGuideDTO } from './../Models/DTO/UserGuideDTO';
import {
  SupportLanguage, SupportPagesEnum,
} from "./../Models/SupportPage";
import { CustomPage } from "./../Models/CustomPage";
import { EmailDTO } from "./../Models/DTO/EmailDTO";
import { INotificationHistoryDTO } from "./../Models/DTO/NotificationHistoryDTO";
import {
  DocumentItemDTO,
  DocumentDetailsDTO,
} from "./../Models/DTO/DocumentDTO";
import { ActivityPermissions } from "./../Models/DTO/ActivityDTO";
import { CustomPageDTO } from "./../Models/DTO/CustomPageDTO";
import { NoteDTO } from "./../Models/DTO/NoteDTO";
import { count } from "console";

import {
  ApplicantDTO,
  BriefDetailDTO,
  BriefFileDTO,
} from "../Models/DTO/BriefDetailDTO";
import { BriefListDTO } from "../Models/DTO/BriefListDTO";
import { BriefHistoryStatusDTO } from "../Models/DTO/BriefHistoryStatusDTO";

import {
  DeliverableCreateNewDTO,
  DeliverableVersionDTO,
} from "./../Models/DTO/DeliverableDTO";
import {
  TaskDTO,
  TaskDTO_Insert,
  TaskDTO_Update,
} from "./../Models/DTO/TaskDTO";
import { ICalendarPermissionDTO, TimelineDTO } from "../Models/DTO/TimelineDTO";
import { DocumentDTO } from "../Models/DTO/DocumentDTO";
import moment from "moment";
import { msalAuth } from "../msal/MsalAuthProvider";
import { apiConfig, tokenRequest } from "../msal/MsalConfig";
import { Utility } from "./Utility";
import {
  ActivitiesCountDTO,
  ActivityDTO_update,
  ActivityResponse,
  ActivityTypeFilter,
  ListActivities,
} from "../Models/DTO/ActivityDTO";
import { UserDTO } from "../Models/DTO/UserDTO";
import { HttpError, HttpResponse } from "../Models/HttpResponse";
import {
  BriefApprovedDTO,
  BriefDTO,
  BriefsCountDTO,
} from "../Models/DTO/BriefDTO";
import { MacroDTO } from "../Models/DTO/MacroDTO";
import { CalendarDTO } from "../Models/DTO/CalendarDTO";
import { IMacro } from "../Models/Activity";
import { ExtensionsDTO } from "../Models/Deliverable";
import { env } from "process";
import { ICalendarDTO } from '../Models/DTO/ICalendarDTO';
import { ICategoryDTO } from '../Models/DTO/ICategoryDTO';
import { ISensibilityDTO } from '../Models/DTO/ISensibilityDTO';

const emptyPlannerDTO: TimelineDTO = {
  allActivities: [],
  allMicroActivities: [],
  permissions: {
    visibility: false,
    canAdd: false,
    canDelete: false,
    canDownloadDeliverables: false,
  },
  calendarsPermissions: []
};
const emptyDocumentDTO: DocumentDTO = {
  items: [],
  permissions: {
    visibility: false,
    canAdd: false,
    canDelete: false,
    canDownloadDeliverables: false,
  },
};

const emptyBriefListDTO: BriefListDTO = {
  briefs: [],
  permission: {},
  count: 0,
};

export class APIUtility {
  public async callActivities(
    limit: number,
    offset: number,
    idActivity?: number,
    type?: number,
    calendar?: number,
    categories?: number[],
    responsibilities?: number[],
    sensibilities?: number[],
    activity?: string,
    showOnlyMyActivities?: boolean,
    status?: string,
    lastModifyStart?: string,
    lastModifyEnd?: string,
    activityDateStart?: string,
    activityDateEnd?: string,
    dueToStart?: string,
    dueToEnd?: string,
    orderBy?: ({ field: string; type: string } | undefined)[]
  ): Promise<ListActivities> {
    // // DATI di TEST
    // let promiseList: Promise<any>[] = [];
    // return Promise.all(promiseList).then(() => {
    //     return testActivities;
    // });

    // TODO:
    var queryString = "";
    queryString += "limit=" + limit;
    queryString += "&offset=" + offset;
    queryString += "&type=" + (type ? type.toString() : "0");
    queryString += "&myActivities=" + (showOnlyMyActivities ? "1" : "0");
    queryString += calendar ? "&calendar=" + calendar : "";
    queryString += categories && categories.length > 0 ? categories.map(x => "&categories=" + x).join("") : "";
    queryString += responsibilities && responsibilities.length > 0 ? responsibilities.map(x => "&responsibilities=" + x).join("") : "";
    queryString += sensibilities && sensibilities.length > 0 ? sensibilities.map(x => "&sensibilities=" + x).join("") : "";
    queryString += idActivity ? "&id=" + idActivity : "";
    queryString += activity ? "&activity=" + activity : "";
    queryString += status ? "&status=" + status : "";
    queryString += lastModifyStart ? "&lastModify[gte]=" + lastModifyStart : "";
    queryString += lastModifyEnd ? "&lastModify[lte]=" + lastModifyEnd : "";
    queryString += activityDateStart
      ? "&activityDate[gte]=" + activityDateStart
      : "";
    queryString += activityDateEnd
      ? "&activityDate[lte]=" + activityDateEnd
      : "";
    queryString += dueToStart ? "&dueTo[gte]=" + dueToStart : "";
    queryString += dueToEnd ? "&dueTo[lte]=" + dueToEnd : "";
    var data: {
      limit: string;
      offset: string;
      type: string;
      id?: string;
      activity?: string;
      status?: string;
      "lastModify[gte]"?: string;
      "lastModify[lte]"?: string;
      "activityDate[gte]"?: string;
      "activityDate[lte]"?: string;
      "dueTo[gte]"?: string;
      "dueTo[lte]"?: string;
    } = {
      limit: limit.toString(),
      offset: offset.toString(),
      type: type ? type.toString() : "0",
      id: idActivity ? idActivity.toString() : undefined,
      activity: activity ? activity : undefined,
      status: status ? status : undefined,
      "lastModify[gte]": lastModifyStart ? lastModifyStart : undefined,
      "lastModify[lte]": lastModifyEnd ? lastModifyEnd : undefined,
      "activityDate[gte]": activityDateStart ? activityDateStart : undefined,
      "activityDate[lte]": activityDateEnd ? activityDateEnd : undefined,
      "dueTo[gte]": dueToStart ? dueToStart : undefined,
      "dueTo[lte]": dueToEnd ? dueToEnd : undefined,
    };

    if (orderBy) {
      orderBy.forEach((element) => {
        if (element)
          queryString += "&orderBy[" + element.field + "]=" + element.type;
      });
    }

    const httpresponse = await this.HTTPGetAsyncNew<ListActivities>(
      "activities/list?" + queryString,
      null //data
    );

    // return this.callAPI<ListActivities>("activities/list?" + queryString).then(
    const result = await httpresponse.value();

    return !httpresponse.hasContent
      ? {
        count: 0,
        activities: [],
        permissions: Utility.getDefaultActivityPermissions(),
      }
      : (result as ListActivities);
  }

  public async getActivitiesCount(): Promise<ActivitiesCountDTO> {
    // // DATI di TEST
    // let promiseList: Promise<any>[] = [];
    // return Promise.all(promiseList).then(() => {
    //     return testActivities;
    // });

    // TODO:
    const httpresponse = await this.HTTPGetAsyncNew<ActivitiesCountDTO>(
      "activities/count",
      null
    );

    const result = await httpresponse.value();

    return result as ActivitiesCountDTO;
  }

  public async getBriefList(
    limit: number,
    offset: number,
    title?: string,
    status?: string,
    referent?: string,
    applicantUnit?: string,
    dateStart?: string,
    dateEnd?: string,
    id?: number,
    orderBy?: ({ field: string; type: string } | undefined)[]
  ): Promise<BriefListDTO> {
    let queryString = "";
    queryString += "limit=" + limit;
    queryString += "&offset=" + offset;
    queryString += id ? "&id=" + id : "";
    queryString += title ? "&Title=" + title : "";
    queryString += status ? "&Status=" + status : "";
    queryString += referent ? "&Referent=" + referent : "";
    queryString += applicantUnit ? "&ApplicantUnit=" + applicantUnit : "";
    queryString += dateStart ? "&Date[gte]=" + dateStart : "";
    queryString += dateEnd ? "&Date[lte]=" + dateEnd : "";

    if (orderBy) {
      orderBy.forEach((element) => {
        if (element)
          queryString += "&orderBy[" + element.field + "]=" + element.type;
      });
    }

    const httpresponse = await this.HTTPGetAsyncNew<BriefListDTO>(
      "briefs/list?" + queryString,
      null
    );

    const result = await httpresponse.value();

    return !httpresponse.hasContent
      ? emptyBriefListDTO
      : (result as BriefListDTO);
  }

  public async getBriefsCount(): Promise<BriefsCountDTO> {
    // // DATI di TEST
    // let promiseList: Promise<any>[] = [];
    // return Promise.all(promiseList).then(() => {
    //     return testActivities;
    // });

    // TODO:
    const httpresponse = await this.HTTPGetAsyncNew<BriefsCountDTO>(
      "briefs/count",
      null
    );

    const result = await httpresponse.value();

    return result as BriefsCountDTO;
  }

  public async getAttachments(
    limit: number,
    offset: number,
    entityType: string,
    fileTypes: string[],
    fileName?: string,
    entityName?: string,
    lastModifyStart?: string,
    lastModifyEnd?: string,
    orderBy?: ({ field: string; type: string } | undefined)[]
  ): Promise<DocumentDTO> {
    let queryString = "";
    queryString += "limit=" + limit;
    queryString += "&offset=" + offset;
    queryString += "&EntityType=" + entityType;
    queryString += fileName ? "&File=" + fileName : "";
    fileTypes.forEach((ft) => {
      queryString += "&fileType=." + ft;
    });
    queryString += entityName ? "&EntityName=" + entityName : "";
    queryString += lastModifyStart ? "&lastModify[gte]=" + lastModifyStart : "";
    queryString += lastModifyEnd ? "&lastModify[lte]=" + lastModifyEnd : "";

    if (orderBy) {
      orderBy.forEach((element) => {
        if (element)
          queryString += "&orderBy[" + element.field + "]=" + element.type;
      });
    }

    const httpresponse = await this.HTTPGetAsyncNew<DocumentDTO>(
      "documents?" + queryString,
      null
    );

    const result = await httpresponse.value();

    return !httpresponse.hasContent
      ? emptyDocumentDTO
      : (result as DocumentDTO);
  }

  public async getAttachmentPaths(
    idAttachment: number
  ): Promise<DocumentDetailsDTO | undefined> {
    const httpresponse = await this.HTTPGetAsyncNew<DocumentDetailsDTO>(
      "document/" + idAttachment,
      null
    );

    const result = await httpresponse.value();

    return !httpresponse.hasContent
      ? undefined
      : (result as DocumentDetailsDTO);
  }

  public async getMacroActivity(idItem?: number): Promise<ActivityResponse> {
    const httpresponse = await this.HTTPGetAsyncNew<ActivityResponse>(
      "macroactivity/" + idItem,
      null
    );

    const result = await httpresponse.value();

    return result as ActivityResponse;
  }

  public async getMicroActivity(idItem?: number): Promise<ActivityResponse> {
    const httpresponse = await this.HTTPGetAsyncNew<ActivityResponse>(
      "microactivity/" + idItem,
      null
    );

    const result = await httpresponse.value();

    return result as ActivityResponse;
  }

  public async getMicroActivityPermissions(
    idItem?: number
  ): Promise<ActivityPermissions> {
    const httpresponse = await this.HTTPGetAsyncNew<ActivityPermissions>(
      "microactivity/" + idItem + "/permissions",
      null
    );

    const result = await httpresponse.value();

    return result as ActivityPermissions;
  }

  public async getMacroActivityPermissions(
    idItem?: number
  ): Promise<ActivityPermissions> {
    const httpresponse = await this.HTTPGetAsyncNew<ActivityPermissions>(
      "macroactivity/" + idItem + "/permissions",
      null
    );

    const result = await httpresponse.value();

    return result as ActivityPermissions;
  }

  public async deleteMacroActivity(idActivity: string): Promise<number> {
    var a = await this.HTTPDeleteAsync("macroactivity/" + idActivity, "", null);

    return a;
  }

  public async deleteMicroActivity(idActivity: string): Promise<number> {
    var a = await this.HTTPDeleteAsync("microactivity/" + idActivity, "", null);

    return a;
  }

  public async updateMicroActivity(
    idActivity: string,
    activity: ActivityDTO_update[]
  ): Promise<number> {
    var a = await this.HTTPPutAsync(
      "microactivity/" + idActivity,
      activity,
      null
    );

    return a;
  }

  public async updateMacroActivity(
    idActivity: string,
    activity: ActivityDTO_update[]
  ): Promise<number> {
    var a = await this.HTTPPutAsync(
      "macroactivity/" + idActivity,
      activity,
      null
    );

    return a;
  }

  public async updateMicroActivityStatus(
    idActivity: string,
    status: string
  ): Promise<number> {
    var a = await this.HTTPPutAsync(
      "microactivity/" + idActivity + "/status/" + status,
      "",
      null
    );

    return a;
  }

  public async getActivityDeliverables(
    idItem?: number
  ): Promise<DocumentDetailsDTO[]> {
    const httpresponse = await this.HTTPGetAsyncNew<DocumentDetailsDTO[]>(
      "microactivity/" + idItem + "/deliverables",
      null
    );

    const result = await httpresponse.value();

    return result as DocumentDetailsDTO[];
  }

  public async getActivityTasks(idItem?: number): Promise<TaskDTO[]> {
    const httpresponse = await this.HTTPGetAsyncNew<TaskDTO[]>(
      "microactivity/" + idItem + "/tasks",
      null
    );

    const result = await httpresponse.value();

    return result as TaskDTO[];
  }

  public async getMicroActivityNotes(idItem?: number): Promise<NoteDTO[]> {
    const httpresponse = await this.HTTPGetAsyncNew<NoteDTO[]>(
      "microactivity/" + idItem + "/notes",
      null
    );

    const result = await httpresponse.value();

    return result as NoteDTO[];
  }

  public async getMacroActivityNotes(idItem?: number): Promise<NoteDTO[]> {
    const httpresponse = await this.HTTPGetAsyncNew<NoteDTO[]>(
      "macroactivity/" + idItem + "/notes",
      null
    );

    const result = await httpresponse.value();

    return result as NoteDTO[];
  }

  public async checkClientVersion(): Promise<void> {
    const httpresponse = await this.HTTPGetAsyncNew<void>(
      "health/clientversion",
      null
    );
    await httpresponse.value();
  }

  public async checkHealth(): Promise<void> {
    const httpresponse = await this.HTTPGetAsyncNew<void>(
      "health",
      null
    );
    await httpresponse.value();
  }

  public async getActivityResources(
    idActivity: string,
    text: string
  ): Promise<UserDTO[]> {
    const httpresponse = await this.HTTPGetAsyncNew<UserDTO[]>(
      `microactivity/${idActivity}/resources/${text}`,
      null
    );

    const result = await httpresponse.value();

    return !httpresponse.hasContent ? [] : (result as UserDTO[]);
  }

  public async updateActivityResources(
    idActivity: string,
    type: string,
    resources: string[]
  ): Promise<number> {
    var a = await this.HTTPPutAsync(
      "microactivity/" + idActivity + "/resources/" + type,
      resources,
      null
    );

    return a;
  }

  public async insertTask(
    idActivity: string,
    task: TaskDTO_Insert
  ): Promise<number> {
    var a = await this.HTTPPostAsync(
      "microactivity/" + idActivity + "/task",
      task,
      null
    );

    return a;
  }

  public async updateTask(
    idActivity: string,
    idTask: string,
    task: TaskDTO_Update
  ): Promise<number> {
    var a = await this.HTTPPutAsync(
      "microactivity/" + idActivity + "/task/" + idTask,
      task.status,
      null
    );

    return a;
  }

  public async deleteTask(idActivity: string, itemId: string): Promise<number> {
    var a = await this.HTTPDeleteAsync(
      "microactivity/" + idActivity + "/task/" + itemId,
      "",
      null
    );

    return a;
  }

  public async addDeliverable(
    idActivity: string,
    content: any,
    forced: boolean
  ): Promise<number> {
    const formData = new FormData();
    formData.append("file", content);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    if (forced) {
      var a = await this.HTTPPostAsync(
        "microactivity/" + idActivity + "/deliverable/overwrite",
        formData,
        "multipart/form-data"
      );

      return a;
    } else {
      var a = await this.HTTPPostAsync(
        "microactivity/" + idActivity + "/deliverable",
        formData,
        "multipart/form-data"
      );

      return a;
    }
  }

  public async createNewDeliverable(
    idActivity: string,
    del: DeliverableCreateNewDTO
  ): Promise<number> {
    var a = await this.HTTPPostAsync(
      "microactivity/" + idActivity + "/deliverable/copy",
      del,
      null
    );
    return a;
  }

  public async downloadDeliverable(
    idActivity: number,
    idDeliverable: number
  ): Promise<any> {
    const file = await this.HTTPGetAsyncNew<UserDTO[]>(
      `microactivity/${idActivity}/deliverable/${idDeliverable}/download`,
      null
    );

    const result = await file.value();

    return !file.hasContent ? undefined : result;
  }

  public async getDeliverableVersionsOLD(
    idActivity: number,
    idDeliverable: number
  ): Promise<DeliverableVersionDTO[]> {
    const httpresponse = await this.HTTPGetAsyncNew<DeliverableVersionDTO[]>(
      `microactivity/${idActivity}/deliverable/${idDeliverable}/version`,
      null
    );

    const result = await httpresponse.value();

    return !httpresponse.hasContent ? [] : (result as DeliverableVersionDTO[]);
  }

  public async getDeliverableVersions(
    idActivity: number,
    idDeliverable: number
  ): Promise<DeliverableVersionDTO[]> {
    const httpresponse = await this.HTTPGetAsyncNew<DeliverableVersionDTO[]>(
      `document/${idDeliverable}/version`,
      null
    );

    const result = await httpresponse.value();

    return !httpresponse.hasContent ? [] : (result as DeliverableVersionDTO[]);
  }

  public async getListTemplates(): Promise<string[]> {
    const httpresponse = await this.HTTPGetAsyncNew<string[]>(
      `documents/templates`,
      null
    );

    const result = await httpresponse.value();

    return !httpresponse.hasContent ? [] : (result as string[]);
  }

  public async downloadDeliverableVersionsOLD(
    idActivity: number,
    idDeliverable: number,
    idVersion: string
  ): Promise<any> {
    const httpresponse = await this.HTTPGetAsyncNew<DeliverableVersionDTO[]>(
      `microactivity/${idActivity}/deliverable/${idDeliverable}/version/` +
      idVersion +
      `/download`,
      null
    );

    const result = await httpresponse.value();

    return !httpresponse.hasContent ? undefined : result;
  }

  public async downloadDeliverableVersions(
    idActivity: number,
    idDeliverable: number,
    idVersion: string
  ): Promise<any> {
    const httpresponse = await this.HTTPGetAsyncNew<DeliverableVersionDTO[]>(
      `document/${idDeliverable}/version/` + idVersion + `/download`,
      null
    );

    const result = await httpresponse.value();

    return !httpresponse.hasContent ? undefined : result;
  }

  public async restoreDeliverableVersions(
    idActivity: number,
    idDeliverable: number,
    idVersion: string
  ): Promise<boolean> {
    const httpresponse = await this.HTTPGetAsyncNew<DeliverableVersionDTO[]>(
      `document/${idDeliverable}/version/` + idVersion + `/restore`,
      null
    );

    const result = await httpresponse.value();

    return !httpresponse.hasContent ? false : true;
  }

  public async restoreDeliverableVersionsOLD(
    idActivity: number,
    idDeliverable: number,
    idVersion: string
  ): Promise<boolean> {
    const httpresponse = await this.HTTPGetAsyncNew<DeliverableVersionDTO[]>(
      `microactivity/${idActivity}/deliverable/${idDeliverable}/version/` +
      idVersion +
      `/restore`,
      null
    );

    const result = await httpresponse.value();

    return !httpresponse.hasContent ? false : true;
  }

  public async downloadDeliverables(
    idActivity: number,
    idDeliverables: number[]
  ): Promise<any> {
    const httpresponse = await this.HTTPGetAsyncNew<any>(
      `microactivity/${idActivity}/deliverables/zip?idDel=${idDeliverables.join(
        "|"
      )}`,
      idDeliverables
    );

    const result = await httpresponse.value();

    return !httpresponse.hasContent ? undefined : result;
  }

  public async downloadBriefAsPdf(id: number, language: string): Promise<Blob> {
    const httpresponse = await this.HTTPGet(
      `brief/${id}/language/${language}/downloadpdf`,
      null
    );

    const result = await httpresponse.blob();
    return result;
  }

  public async downloadBriefAllAttachments(
    id: number,
    language: string
  ): Promise<Blob> {
    const httpresponse = await this.HTTPGet(
      `brief/${id}/downloadall/language/${language}`,
      null
    );

    const result = await httpresponse.blob();
    return result;
  }

  public async downloadBriefAttachments(id: number): Promise<Blob> {
    const httpresponse = await this.HTTPGet(
      `brief/${id}/download/attachments`,
      null
    );

    const result = await httpresponse.blob();
    return result;
  }

  public async getAllowedExtension(): Promise<ExtensionsDTO[]> {
    const httpresponse = await this.HTTPGetAsyncNew<ExtensionsDTO[]>(
      `file-extension`,
      null
    );

    const result = await httpresponse.value();

    return !httpresponse.hasContent ? [] : (result as ExtensionsDTO[]);
  }

  public async deleteDeliverable(
    idActivity: string,
    itemId: string
  ): Promise<number> {
    var a = await this.HTTPDeleteAsync(
      "microactivity/" + idActivity + "/deliverable/" + itemId,
      "",
      null
    );

    return a;
  }

  public async addNote(
    idActivity: string,
    isMacro: boolean,
    text: string
  ): Promise<number> {
    var a = await this.HTTPPostAsync(
      isMacro
        ? "macroactivity/" + idActivity + "/note"
        : "microactivity/" + idActivity + "/note",
      text,
      null
    );

    return a;
  }

  public async updateNote(
    idActivity: string,
    idNote: string,
    isMacro: boolean,
    note: string
  ): Promise<number> {
    var a = await this.HTTPPutAsync(
      isMacro
        ? "macroactivity/" + idActivity + "/note/" + idNote
        : "microactivity/" + idActivity + "/note/" + idNote,
      note,
      null
    );

    return a;
  }

  public async deleteNote(
    idActivity: string,
    idNote: string,
    isMacro: boolean
  ): Promise<number> {
    var a = await this.HTTPDeleteAsync(
      isMacro
        ? "macroactivity/" + idActivity + "/note/" + idNote
        : "microactivity/" + idActivity + "/note/" + idNote,
      "",
      null
    );

    return a;
  }

  public async getNotificationHistory(
    page: number
  ): Promise<INotificationHistoryDTO[]> {
    const notifications = await this.HTTPGetAsyncNew<INotificationHistoryDTO[]>(
      `notifications/${page}`,
      null
    );
    const result = await notifications.value();

    if (!notifications.hasContent) {
      throw new HttpError(
        "Cannot find the request",
        204,
        "Cannot find the request"
      );
    } else {
      return result as [];
    }
  }

  public async getStackeholder(filter: string): Promise<string[]> {
    const items = await this.HTTPGetAsyncNew<string[]>(
      `stakeholders/list?containedString=${filter}`,
      null
    );

    const result = await items.value();
    if (!items.hasContent) {
      return [];
    } else {
      return result as [];
    }
  }

  public async getApplicant(): Promise<ApplicantDTO> {
    const items = await this.HTTPGetAsyncNew<ApplicantDTO>(
      "briefs/applicants",
      null
    );

    const result = await items.value();
    if (!items.hasContent) {
      return { referents: [], applicantUnits: [] };
    } else {
      return result as ApplicantDTO;
    }
  }

  public async getTargets(filter: string): Promise<string[]> {
    const items = await this.HTTPGetAsyncNew<string[]>(
      `targets/list?containedString=${filter}`,
      null
    );

    const result = await items.value();
    if (!items.hasContent) {
      return [];
    } else {
      return result as [];
    }
  }

  public getUser(userprincipalname?: string): Promise<any> {
    return this.callAPI(
      "user/" + (userprincipalname ? userprincipalname : "me")
    ).then((response: any) => {
      return response;
    });
  }

  public getUsers(mode: string, text?: string, calendarId?: number): Promise<any[]> {
    return this.callAPI("users/" + mode + "/" + text + "/" + calendarId).then((response: any) => {
      return response;
    });
  }

  private toActivityTypeFilter(filter: string): ActivityTypeFilter {
    return (ActivityTypeFilter as any)[filter.toLowerCase()];
  }

  public async saveMacro(macro: IMacro): Promise<number> {
    return await this.HTTPPostAsync("macroactivity", macro, null);
  }

  public async saveMacroForm(macro: FormData): Promise<number> {
    try {
      return await this.HTTPPostAsync(
        "macroactivity",
        macro,
        "multipart/form-data"
      );
    } catch (err) {
      throw new HttpError("Http saveMacroForm error", 400, err.message);
    }
  }

  public async saveMicro(formData: FormData): Promise<number> {
    try {
      return await this.HTTPPostAsync(
        "microactivity",
        formData,
        "multipart/form-data"
      );
    } catch (err) {
      throw new HttpError("Http saveMicro error", 400, err.message);
    }
  }

  public async addNewBrief(brief: FormData): Promise<Response> {
    try {
      return await this.HTTPPostWithResultAsync(
        "brief",
        brief,
        "multipart/form-data"
      );
    } catch (err) {
      throw new HttpError("Http addNewBrief error", 400, err.message);
    }
  }

  public async updateBrief(
    idBrief: number,
    brief: FormData
  ): Promise<Response> {
    try {
      return await this.HTTPPutWithResultAsync(
        `brief/${idBrief}`,
        brief,
        "multipart/form-data"
      );
    } catch (err) {
      throw new HttpError("Http updateBrief error", 400, err.message);
    }
  }

  public async getUsersByFilterAsync(
    mode: string,
    filter: {term: string, calendar: number}
  ): Promise<UserDTO[]> {
    const users = await this.HTTPGetAsyncNew<UserDTO[]>(
      `users/${mode}/${filter.term}/${filter.calendar}`,
      null
    );

    const result = await users.value();
    if (!users.hasContent) {
      return [];
    } else {
      return result as UserDTO[];
    }
  }

  public async getSharepointLoginPageUrlAsync(): Promise<string | undefined> {
    const httpresponse = await this.HTTPGetAsyncNew(
      `documents/sharepointloginpage`,
      null
    );

    const result: any = await httpresponse.value();
    if (!httpresponse.hasContent) {
      return undefined;
    } else {
      return result.url as string;
    }
  }

  public async getUserInfoAsync(upn: string): Promise<UserDTO> {
    const user = await this.HTTPGetAsyncNew<UserDTO>(`user/${upn}`, null);
    const result = await user.value();

    if (!user.hasContent) {
      throw new HttpError(
        "Cannot find user information",
        204,
        "Cannot find user information"
      );
    } else {
      return result as UserDTO;
    }
  }

  public async deleteBrief(id: number): Promise<number> {
    const retValue = await this.HTTPDeleteAsync(`brief/${id}`, "", null);
    return retValue;
  }

  public async delegateBrief(
    id: number,
    upn: string,
    type: string,
    mailSubject: string,
    mailBody: string
  ): Promise<number> {
    const payload = {
      delegationType: type,
      delegated: upn,
      emailSubject: mailSubject,
      emailBody: mailBody,
    };

    const retValue = await this.HTTPPutAsync(
      `brief/${id}/delegation`,
      payload,
      null
    );
    return retValue;
  }

  public async changeBriefStatus(id: number, status: string): Promise<number> {
    const retValue = await this.HTTPPutAsync(
      `brief/${id}/status/${status}`,
      "",
      null
    );
    return retValue;
  }

  public async getBriefByIdAsync(id: number): Promise<BriefDetailDTO> {
    const user = await this.HTTPGetAsyncNew<BriefDetailDTO>(
      `brief/${id}`,
      null
    );
    const result = await user.value();

    if (!user.hasContent) {
      throw new HttpError(
        "Cannot find the request brief",
        204,
        "Cannot find the request brief"
      );
    } else {
      return result as BriefDetailDTO;
    }
  }

  public async getBriefActionsAsync(
    id: number
  ): Promise<BriefHistoryStatusDTO[]> {
    const user = await this.HTTPGetAsyncNew<BriefHistoryStatusDTO[]>(
      `brief/${id}/actions`,
      null
    );
    const result = await user.value();

    if (!user.hasContent) {
      throw new HttpError(
        "Cannot find the request brief",
        204,
        "Cannot find the request brief"
      );
    } else {
      return result as BriefHistoryStatusDTO[];
    }
  }

  public async getFilesByBriefIdAsync(id: number): Promise<BriefFileDTO[]> {
    const files = await this.HTTPGetAsyncNew<BriefDetailDTO[]>(
      `brief/${id}/attachments`,
      null
    );
    const result = await files.value();

    if (!files.hasContent) {
      throw new HttpError(
        "Cannot find the request brief",
        204,
        "Cannot find the request brief"
      );
    } else {
      return result as [];
    }
  }

  public async getBriefsApprovedAsync(): Promise<BriefApprovedDTO[]> {
    return await this.HTTPGetAsync<BriefApprovedDTO[]>("briefs/approved", null);
  }

  public async getCalendarsAsync(): Promise<ICalendarDTO[]> {
    const httpresponse = await this.HTTPGetAsyncNew<ICalendarDTO[]>(
      "activities/calendars",
      null
    );
    const result = await httpresponse.value();
    return !httpresponse.hasContent ? [] : (result as ICalendarDTO[]);
    // return Promise.resolve([{ key: 1, value: 'Global Media' }, { key: 2, value: 'Spain' }]);
  }

  public async getCategoriesAsync(): Promise<ICategoryDTO[]> {
    const httpresponse = await this.HTTPGetAsyncNew<ICategoryDTO[]>(
      "activities/categories",
      null
    );
    const result = await httpresponse.value();
    return !httpresponse.hasContent ? [] : (result as ICategoryDTO[]);
    // return Promise.resolve([{ key: 1, value: 'Press Release' }]);
  }

  public async getResponsibilitiesAsync(): Promise<IResponsabilityDTO[]> {
    const httpresponse = await this.HTTPGetAsyncNew<IResponsabilityDTO[]>(
      "activities/responsibilities",
      // "activities/categories",
      null
    );
    const result = await httpresponse.value();
    return !httpresponse.hasContent ? [] : (result as IResponsabilityDTO[]);
    // return Promise.resolve([{ key: 1, value: 'Press Release' }]);
  }

  public async getCalendarsPermissionsAsync(): Promise<ICalendarPermissionDTO[]> {
    const httpresponse = await this.HTTPGetAsyncNew<ICalendarPermissionDTO[]>(
      "activities/permissions",
      null
    );
    const result = await httpresponse.value();
    return !httpresponse.hasContent ? [] : (result as ICalendarPermissionDTO[]);
  }

  public async getSensibilitiesAsync(): Promise<ISensibilityDTO[]> {
    const httpresponse = await this.HTTPGetAsyncNew<ISensibilityDTO[]>(
      "activities/sensibilities",
      null
    );
    const result = await httpresponse.value();
    return !httpresponse.hasContent ? [] : (result as ISensibilityDTO[]);
    // return Promise.resolve([{ key: 1, value: 'Price Sensitive' }]);
  }

  public async getMacrosAsync(date: string | undefined, calendar: number | undefined): Promise<MacroDTO[]> {
    const filter: any = {};
    let hasFilter = false;
    if (calendar !== undefined) {
      filter.calendarId = calendar.toString();
      hasFilter = true;
    };
    if (!!date) {
      filter.searchDate = date;
      hasFilter = true;
    };

    const httpresponse = await this.HTTPGetAsyncNew<MacroDTO[]>(
      "macroactivity/list",
      hasFilter ? filter : null
    );
    const result = await httpresponse.value();
    return !httpresponse.hasContent ? [] : (result as MacroDTO[]);
  }

  public async getCalendarActivities(
    fromDate: Date,
    toDate: Date
  ): Promise<CalendarDTO> {
    const queryFilter = {
      dateFrom: fromDate.toISOString(),
      dateTo: toDate.toISOString(),
    };

    const httpresponse: HttpResponse<CalendarDTO> = await this.HTTPGetAsyncNew<
      CalendarDTO
    >("activities/planner", queryFilter);

    const result = await httpresponse.value();
    return !httpresponse.hasContent
      ? { allActivities: [], allMicroActivities: [] }
      : (result as CalendarDTO);
  }

  public async getTimelineActivities(
    fromDate: Date,
    toDate: Date
  ): Promise<TimelineDTO> {
    const queryFilter = {
      dateFrom: fromDate.toISOString(),
      dateTo: toDate.toISOString(),
    };

    const httpresponse: HttpResponse<TimelineDTO> = await this.HTTPGetAsyncNew<
      TimelineDTO
    >("activities/timeline", queryFilter);

    const result = await httpresponse.value();
    return !httpresponse.hasContent ? emptyPlannerDTO : (result as TimelineDTO);
  }

  public async getPlannerActivities(
    type: string,
    fromDate: Date,
    toDate: Date
  ): Promise<TimelineDTO> {
    const queryFilter = {
      dateFrom: fromDate.toISOString(),
      dateTo: toDate.toISOString(),
    };

    const httpresponse: HttpResponse<TimelineDTO> = await this.HTTPGetAsyncNew<
      TimelineDTO
    >(`activities/${type}`, queryFilter);

    const result = await httpresponse.value();

    return !httpresponse.hasContent ? emptyPlannerDTO : (result as TimelineDTO);
  }

  public async getTimelineActivities2(
    filter: string,
    statusFilter: string[],
    fromDate: Date,
    toDate: Date
  ) {
    const queryFilter = {
      dateFrom: fromDate.toISOString(),
      dateTo: toDate.toISOString(),
    };

    const apiResult: HttpResponse<TimelineDTO> = await this.HTTPGetAsyncNew<
      TimelineDTO
    >("activities/timeline", queryFilter);
    //const result = this.callAPI("activities/timeline");
    const result = apiResult.value();

    switch (filter) {
      case "all":
        return result.then((response: any) => {
          //return this.getTestData().then((response) => {
          let activities = response.allActivities.filter(function (item: any) {
            return (
              (statusFilter.includes(item.status) && !item.isMacro) ||
              item.isMacro
            );
          });
          return activities.map((itemMacro: any) => {
            let linkedMicroActivities =
              itemMacro.microActivities !== undefined
                ? itemMacro.microActivities.map((itemMicro: any) => {
                  let classes = Utility.GetActivityClassNameFromStatus(
                    itemMicro.status,
                    false
                  );
                  let tasks =
                    itemMicro.tasks !== undefined
                      ? itemMicro.tasks.map((task: any, index: any) => {
                        let completed =
                          task.status.toLocaleUpperCase() === "DONE"
                            ? true
                            : false;
                        let taskId =
                          itemMacro.id.toString() +
                          "-" +
                          itemMicro.id.toString() +
                          "-" +
                          task.id.toString();
                        return {
                          id: taskId,
                          identifier: task.id.toString(),
                          title: task.title,
                          state: task.status,
                          macroName: itemMacro.name,
                          elements: [
                            {
                              id: taskId,
                              identifier: task.id.toString(),
                              title: task.name,
                              start: moment
                                .utc(task.dateFrom)
                                .local()
                                .toDate(),
                              end: moment
                                .utc(task.dateTo)
                                .local()
                                .toDate(),
                              //start: new Date(task.dateFrom),
                              //end: new Date(task.dateTo),
                              state: task.status,
                              classes: completed
                                ? "back-task-completed "
                                : "back-task ",
                              isMacro: false,
                              isTask: true,
                              style: {
                                color: "#ffffff",
                                borderRadius: "4px",
                                border: "1px solid #C2CDDD",
                                height: 16,
                                marginTop: 8,
                              },
                            },
                          ],
                          trackLevel: 3,
                          trackL1ID: itemMacro.id.toString(),
                          trackL2ID: itemMicro.id.toString(),
                          completed: completed,
                        };
                      })
                      : [];

                  let microId =
                    itemMacro.id.toString() + "-" + itemMicro.id.toString();
                  return {
                    id: microId,
                    identifier: itemMicro.id.toString(),
                    title: itemMicro.name,
                    desc: itemMicro.description,
                    state: itemMicro.status,
                    macroName: itemMacro.name,
                    elements: [
                      {
                        id: microId,
                        identifier: itemMicro.id.toString(),
                        title: itemMicro.name,
                        // start: new Date(itemMicro.dateFrom),
                        // end: new Date(itemMicro.dateTo),
                        start: moment
                          .utc(itemMicro.dateFrom)
                          .local()
                          .toDate(),
                        end: moment
                          .utc(itemMicro.dateTo)
                          .local()
                          .toDate(),
                        classes: classes,
                        state: itemMicro.status,
                        isMacro: false,
                        macroName: itemMacro.name,
                        style: {
                          color: "#ffffff",
                          borderRadius: "4px",
                          border: "1px solid #C2CDDD",
                          height: 24,
                          marginTop: 4,
                        },
                      },
                    ],
                    tracks: tasks,
                    isOpen: false,
                    trackLevel: 2,
                    trackL1ID: itemMacro.id.toString(),
                  };
                })
                : [];
            //linkedMicroActivities = linkedMicroActivities != undefined ? linkedMicroActivities.filter(function (item: any) { return statusFilter.includes(item.state); }) : [];
            let classes = itemMacro.isMacro
              ? ""
              : Utility.GetActivityClassNameFromStatus(itemMacro.status, false);
            let style = itemMacro.isMacro
              ? {
                color: "black",
                borderRadius: "4px",
                background: "#FFFFFF 0% 0% no-repeat padding-box",
                border: "1px solid #C2CDDD",
                textAlign: "left",
              }
              : {
                color: "#ffffff",
                borderRadius: "4px",
                border: "1px solid #C2CDDD",
                height: 24,
                marginTop: 4,
              };
            return {
              id: itemMacro.id.toString(),
              identifier: itemMacro.id.toString(),
              title: itemMacro.name,
              desc: itemMacro.description,
              state: itemMacro.status,
              isMacro: itemMacro.isMacro,
              Nmicro:
                itemMacro.microActivities !== undefined
                  ? itemMacro.microActivities.length
                  : 0,
              elements: [
                {
                  id: itemMacro.id.toString(),
                  identifier: itemMacro.id.toString(),
                  title: itemMacro.name,
                  start: moment
                    .utc(itemMacro.dateFrom)
                    .local()
                    .toDate(),
                  end: moment
                    .utc(itemMacro.dateTo)
                    .local()
                    .toDate(),
                  // start: new Date(itemMacro.dateFrom),
                  // end: new Date(itemMacro.dateTo),
                  isMacro: itemMacro.isMacro,
                  state: itemMacro.status,
                  classes: classes,
                  style: style,
                },
              ],
              tracks: linkedMicroActivities,
              isOpen: false,
              trackLevel: 1,
            };
          });
        });
      case "macro":
        return result.then((response: any) => {
          let activities = response.allActivities.filter(function (item: any) {
            return item.isMacro;
          });
          return activities.map((itemMacro: any) => {
            return {
              id: itemMacro.id,
              identifier: itemMacro.id,
              title: itemMacro.name,
              // start: new Date(itemMacro.dateFrom),
              // end: new Date(itemMacro.dateTo),
              start: moment
                .utc(itemMacro.dateFrom)
                .local()
                .toDate(),
              end: moment
                .utc(itemMacro.dateTo)
                .local()
                .toDate(),
              desc: itemMacro.description,
              state: itemMacro.status,
              isMacro: itemMacro.isMacro,
              Nmicro:
                itemMacro.microActivities !== undefined
                  ? itemMacro.microActivities.length
                  : 0,
              linkedActivities: [],
              allDay: false,
            };
          });
        });
      case "micro":
        return result.then((response: any) => {
          let activities = response.allMicroActivities.filter(function (
            item: any
          ) {
            return statusFilter.includes(item.status);
          });
          return activities.map((itemMicro: any) => {
            return {
              id: itemMicro.id,
              identifier: itemMicro.id,
              title: itemMicro.name,
              // start: new Date(itemMicro.dateFrom),
              // end: new Date(itemMicro.dateTo),
              start: moment
                .utc(itemMicro.dateFrom)
                .local()
                .toDate(),
              end: moment
                .utc(itemMicro.dateTo)
                .local()
                .toDate(),
              desc: itemMicro.description,
              state: itemMicro.status,
              isMacro: false,
              Nmicro: 0,
              linkedActivities: [],
              allDay: false,
            };
          });
        });
    }
  }

  public async sendMail(idBrief: string, language: string, email: EmailDTO) {
    try {
      return await this.HTTPPutAsync(
        `brief/${idBrief}/language/${language}/sendemail`,
        email,
        null
      );
    } catch (err) {
      throw new HttpError("Http sendMail error", 400, err.message);
    }
  }

  public async getCustomPage(
    pageName: string
  ): Promise<CustomPageDTO | undefined> {
    const httpresponse = await this.HTTPGetAsyncNew(
      `pagecontent/` + pageName,
      null
    );

    const result: any = await httpresponse.value();
    if (!httpresponse.hasContent) {
      return undefined;
    } else {
      return result as CustomPageDTO;
    }
  }

  public async updateCustomPage(
    title: string,
    content: FormData
  ): Promise<Response> {
    try {
      return await this.HTTPPutWithResultAsync(
        `pagecontent/${title}`,
        content,
        "multipart/form-data"
      );
    } catch (err) {
      throw new HttpError("Http updateCustomPage error", 400, err.message);
    }
  }

  public async getSupportRoles(
    section: SupportPagesEnum,
    language: SupportLanguage
  ): Promise<string[] | undefined> {
    const httpresponse = await this.HTTPGetAsyncNew(
      `support/` + section + "/" + language + "/roles",
      null
    );

    const result: any = await httpresponse.value();
    if (!httpresponse.hasContent) {
      return undefined;
    } else {
      return result as string[];
    }
  }

  public async getSupportCounters(
    section: SupportPagesEnum,
    languages: string,
    role: string
  ): Promise<SupportCounterDTO | undefined> {
    const httpresponse = await this.HTTPGetAsyncNew(
      `support/` + section + "/" + languages + "/" + role + "/counters",
      null
    );

    const result: any = await httpresponse.value();
    if (!httpresponse.hasContent) {
      return undefined;
    } else {
      return result as SupportCounterDTO;
    }
  }

  public async getSupportItems(
    section: SupportPagesEnum,
    language: string,
    role: string
  ): Promise<
    UserGuideDTO[] | VideoTutorialDTO[] | FaqDTO[] | undefined
  > {
    const httpresponse = await this.HTTPGetAsyncNew(
      `support/` + section + "/" + language + "/" + role + "/items",
      null
    );

    const result: any = await httpresponse.value();
    if (!httpresponse.hasContent) {
      return undefined;
    } else {
      switch (section) {
        case SupportPagesEnum.Faq:
          return result as FaqDTO[];
        case SupportPagesEnum.UserGuide:
          return result as UserGuideDTO[];
        case SupportPagesEnum.VideoTutorials:
          return result as VideoTutorialDTO[];
      }
    }
  }

  public async AddFavourites(
    docsId: string[]
  ): Promise<boolean | undefined
  > {
    var a = await this.HTTPPostAsync(
      "support/favourites/add",
      docsId,
      null
    );

    if (a) {
      return true;
    }
  }

  public async RemoveFavourites(
    docsId: string[]
  ): Promise<boolean | undefined
  > {
    var a = await this.HTTPPostAsync(
      "support/favourites/remove",
      docsId,
      null
    );

    if (a) {
      return true;
    }
  }

  // TODO: da evolvere con gli altri possibili parametri in ingresso es. idActivity, dataFrom, dataTo
  private async callAPI(apiName: string) {
    //Get the security access token
    const accessToken = await this.getAccessToken();

    return fetch(apiConfig.restResource + "/" + apiName, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + accessToken.accessToken,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response && response.status === 500) {
          // TODO: da gestire con un messaggio di errore
          return response.json();
        }
        if (response && response.status !== 404) {
          return response.json();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  private async HTTPGetAsyncNew<T>(
    apiName: string,
    data: {} | null
  ): Promise<HttpResponse<T>> {
    const url: string = this.getApiUrl(apiName, data);

    const headers: Headers = await this.getRequestHeaderAsync(null);

    const request = new Request(url, {
      method: "GET",
      headers: headers,
    });

    try {
      const response = await fetch(request);
      let httpResp: HttpResponse<T>;

      httpResp = new HttpResponse(response);

      return httpResp;
    } catch (error) {
      console.error(error);
      throw new HttpError(error.message, 500, error.message);
    }
  }

  private async HTTPGet(apiName: string, data: {} | null): Promise<Response> {
    const url: string = this.getApiUrl(apiName, data);

    const headers: Headers = await this.getRequestHeaderAsync(null);

    const request = new Request(url, {
      method: "GET",
      headers: headers,
    });

    try {
      const response = await fetch(request);
      return response;
    } catch (error) {
      console.error(error);
      throw new HttpError(error.message, 500, error.message);
    }
  }

  private async HTTPGetAsync<T>(apiName: string, data: {} | null): Promise<T> {
    const url: string = this.getApiUrl(apiName, data);

    const headers: Headers = await this.getRequestHeaderAsync(null);

    const request = new Request(url, {
      method: "GET",
      headers: headers,
    });

    try {
      const response = await fetch(request);

      return ((await response.json()) as unknown) as T;
    } catch (error) {
      console.error(error);
      throw new HttpError(error.message, 500, error.message);
    }
  }

  private async HTTPPostAsync<T>(
    apiName: string,
    data: any,
    contentType: string | null
  ): Promise<number> {
    const url: string = this.getApiUrl(apiName, null); //, data);
    const headers: Headers = await this.getRequestHeaderAsync(contentType);

    let sData;
    if (data instanceof FormData) {
      sData = data;
    } else {
      sData = JSON.stringify(data);
    }

    const request = new Request(url, {
      method: "POST",
      headers: headers,
      body: sData,
    });

    try {
      const response = await fetch(request);
      return response.status;
    } catch (error) {
      console.error(error);
      throw new HttpError(error.message, 500, error.message);
    }
  }

  private async HTTPPostWithResultAsync<T>(
    apiName: string,
    data: any,
    contentType: string | null
  ): Promise<Response> {
    return await this.executeWithResultAsync(
      "POST",
      apiName,
      data,
      contentType
    );
  }

  private async HTTPPutWithResultAsync<T>(
    apiName: string,
    data: any,
    contentType: string | null
  ): Promise<Response> {
    return await this.executeWithResultAsync("PUT", apiName, data, contentType);
  }

  private async executeWithResultAsync<T>(
    method: string,
    apiName: string,
    data: any,
    contentType: string | null
  ): Promise<Response> {
    const url: string = this.getApiUrl(apiName, data);
    const headers: Headers = await this.getRequestHeaderAsync(contentType);

    let sData;
    if (data instanceof FormData) {
      sData = data;
    } else {
      sData = JSON.stringify(data);
    }

    const request = new Request(url, {
      method: method,
      headers: headers,
      body: sData,
    });

    try {
      const response = await fetch(request);
      return response;
    } catch (error) {
      console.error(error);
      throw new HttpError(error.message, 500, error.message);
    }
  }

  private async HTTPPutAsync<T>(
    apiName: string,
    data: any,
    contentType: string | null
  ): Promise<number> {
    const url: string = this.getApiUrl(apiName, null); //data);
    const headers: Headers = await this.getRequestHeaderAsync(contentType);

    let sData;
    if (data instanceof FormData) {
      sData = data;
    } else {
      sData = JSON.stringify(data);
    }

    const request = new Request(url, {
      method: "PUT",
      headers: headers,
      body: sData,
    });

    try {
      const response = await fetch(request);
      return response.status;
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }

  private async HTTPDeleteAsync<T>(
    apiName: string,
    data: any,
    contentType: string | null
  ): Promise<number> {
    const url: string = this.getApiUrl(apiName, null); //, data);
    const headers: Headers = await this.getRequestHeaderAsync(contentType);

    let sData;
    if (data instanceof FormData) {
      sData = data;
    } else {
      sData = JSON.stringify(data);
    }

    const request = new Request(url, {
      method: "DELETE",
      headers: headers,
      body: sData,
    });

    try {
      const response = await fetch(request);
      return response.status;
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }

  private async getRequestHeaderAsync(
    contentType: string | null
  ): Promise<Headers> {
    //Get the security access token
    const accessToken = await this.getAccessToken();

    const headers: Headers = new Headers();
    headers.append("Authorization", "Bearer " + accessToken.accessToken);
    headers.append("X-Client-Version", apiConfig.clientAppVersion || '0');
    if (!contentType) {
      headers.append("Content-Type", "application/json");
    }

    return headers;
  }

  getProperty<T, K extends keyof T>(o: T, propertyName: K): T[K] {
    return o[propertyName]; // o[propertyName] is of type T[K]
  }

  private getApiUrl(apiName: string, params: any | null): string {
    //return new URL('api/' + apiName, apiConfig.resourceUri).toString();
    let url = new URL("api/" + apiName, apiConfig.restResource);

    if (params) {
      this.addQueryParameters(params, url);
    }

    return url.toString();
  }

  private addQueryParameters(params: any, url: URL) {
    for (const [key, value] of Object.entries(params)) {
      if (typeof value === "string") {
        url.searchParams.append(key, value);
      }
    }
  }

  /**
   * Returns the security access token
   */
  public async getAccessToken() {
    let accessToken = null;

    try {
      accessToken = await msalAuth.acquireTokenSilent(tokenRequest);
    } catch (error) {
      console.log("AquireTokenSilent failure");
      console.error(error);
      throw new HttpError(
        "AcquireTokenSilent failure",
        401,
        "AcquireTokenSilent failure"
      );
    }

    return accessToken;
  }

  public async getCurrentUsername(): Promise<string> {
    try {
      var b = await msalAuth.getAllAccounts();
      var a = await msalAuth.getAccount();
      return a.userName;
    } catch (error) {
      console.error(error);
      return "USER NOT RECOGNIZED";
    }
  }

  public async logout() {
    try {
      await msalAuth.logout();
      // await msalAuth.loginRedirect();
    } catch (err) {
      console.error(err);
      throw new HttpError("Logout failure", 500, "Logout failure");
    }
  }
}
