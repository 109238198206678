import * as React from "react";
import "./HeaderAction.css";
import { BaseButton, Button, DefaultButton } from "@fluentui/react";
import { Constants } from "../../../Utility/Constants";
import { IBaseProperties } from "../../../Models/IBaseProperties";
import { Security } from "../../../Utility/Security";

export interface IHeaderActionProps extends IBaseProperties {
  visible: boolean;
  onClickNewBrief(): void;
}
export interface IHeaderActionState {}

export class HeaderAction extends React.Component<
  IHeaderActionProps,
  IHeaderActionState
> {
  constructor(props: IHeaderActionProps) {
    super(props);
    this.state = {};

    this.onClickNewBrief = this.onClickNewBrief.bind(this);
  }

  private headerRef = React.createRef();

  private onClickNewBrief(
    event: React.MouseEvent<
      | HTMLAnchorElement
      | HTMLButtonElement
      | HTMLDivElement
      | BaseButton
      | Button
      | HTMLSpanElement,
      MouseEvent
    >
  ): void {
    event.preventDefault();
    this.props.onClickNewBrief();
  }

  private isGMUser() {
    return (
      Security.isGlobalMediaAdvanced ||
      Security.isGlobalMediaSpecialist ||
      Security.isAuditor ||
      Security.isAdmin
    );
  }

  render() {
    const isGMUser = this.isGMUser();
    let visible = this.props.visible ? "" : "display-none";
    return (
      <React.Fragment>
        {isGMUser && (
          <DefaultButton
            className={["action-button", visible].join(" ")}
            text={this.props.commonProps.translation("header.action2")}
            aria-label={this.props.commonProps.translation("header.action2")}
            ariaLabel={this.props.commonProps.translation("header.action2")}
            iconProps={Constants.iAdd}
            allowDisabledFocus
            onClick={this.onClickNewBrief}
            disabled={false}
            checked={true}
            id="newActivityButttonForBrief"
          />
        )}
      </React.Fragment>
    );
  }
}
