import { TFunction } from "i18next";
import { CommonTranslation } from "../../../Translations/Common.Translation";

export class MediaPlanTranslation extends CommonTranslation
{    
    constructor(translation: TFunction)
    {
        super(translation, "mediaPlan");        
    }
   
    public get genericErrorInSave(): string
    {
        return super.getTextByMessageKey("genericErrorOnSave");
    }

    public get warningCompareMacroMediaDate(): string
    {
        return super.getTextByMessageKey("warningCompareMacroMediaDate");
    }

    public get addToCalendar(): string
    {
        return super.getTextByMessageKey("addToCalendar");
    }

    public get timePeriod(): string
    {
        return super.getTextByMessageKey("timePeriod");
    }

    public get activityTitle(): string
    {
        return super.getTextByMessageKey("activityTitle");
    }

    public get description(): string
    {
        return super.getTextByMessageKey("description");
    }

    public get assignActivity(): string
    {
        return super.getTextByMessageKey("assignActivity");
    }

    public get addExternalResource(): string
    {
        return super.getTextByMessageKey("addExternalResource");
    }

    public get externalResource(): string
    {
        return super.getTextByMessageKey("externalResource");
    }

    public get chooseSpoc(): string
    {
        return super.getTextByMessageKey("chooseSpoc");
    }

    public get associateMacro(): string
    {
        return super.getTextByMessageKey("associateMacro");
    }

    public get filterMacro(): string
    {
        return super.getTextByMessageKey("filterMacro");
    }    

    public get loadingMacro(): string
    {
        return super.getTextByMessageKey("loadingMacro");
    }    
}