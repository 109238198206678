import { TFunction } from "i18next";
import { CommonTranslation } from "../../../Translations/Common.Translation";

export class MacroTranslation extends CommonTranslation
{    
    constructor(translation: TFunction)
    {
        super(translation, "macro");        
    }    

    public get addMediaPlanTitle(): string
    {
        return super.getTextByMessageKey("addMediaPlanTitle");
    }

    public get addMediaPlan(): string
    {
        return super.getTextByMessageKey("addMediaPlan");
    }

    public get headOfActivity(): string
    {
        return super.getTextByMessageKey("headOfActivity");
    }

    public get associateBrief(): string
    {
        return super.getTextByMessageKey("associateBrief");
    }

    public get mediaPlan(): string
    {
        return super.getTextByMessageKey("mediaPlan");
    }

    public get loadingBrief(): string
    {
        return super.getTextByMessageKey("loadingBrief");
    }

    public get selectBrief(): string
    {
        return super.getTextByMessageKey("selectBrief");
    }

    public get selectExistingBrief(): string
    {
        return super.getTextByMessageKey("selectExistingBrief");
    }
   
    public get genericErrorInSave(): string
    {
        return super.getTextByMessageKey("genericErrorOnSave");
    }    

    public get addToCalendar(): string
    {
        return super.getTextByMessageKey("addToCalendar");
    }

    public get timePeriod(): string
    {
        return super.getTextByMessageKey("timePeriod");
    }

    public get activityTitle(): string
    {
        return super.getTextByMessageKey("activityTitle");
    }

    public get description(): string
    {
        return super.getTextByMessageKey("description");
    }

    public get assignActivity(): string
    {
        return super.getTextByMessageKey("assignActivity");
    }

    public get addExternalResource(): string
    {
        return super.getTextByMessageKey("addExternalResource");
    }

    public get externalResource(): string
    {
        return super.getTextByMessageKey("externalResource");
    }

    public get chooseSpoc(): string
    {
        return super.getTextByMessageKey("chooseSpoc");
    }

    public get associateMacro(): string
    {
        return super.getTextByMessageKey("associateMacro");
    }

    public get filterMacro(): string
    {
        return super.getTextByMessageKey("filterMacro");
    }    

    public get loadingMacro(): string
    {
        return super.getTextByMessageKey("loadingMacro");
    } 
    
    public get errorDateRangeTitle(): string
    {
        return super.getTextByMessageKey("errorDateRangeTitle");
    }

    public get errorDateRangeMacroSubTitle(): string
    {
        return super.getTextByMessageKey("errorDateRangeMacroSubTitle");
    }

    public get errorDateRangeMicroTasksSubTitle(): string
    {
        return super.getTextByMessageKey("errorDateRangeMicroTasksSubTitle");
    }

    public get associatedBrief(): string {
        return super.getTextByMessageKey("associatedBrief");
    }
}