const makeCancelable = (promise: any): CancellableTask => {
    let hasCanceled_ = false;
    const wrappedPromise = new Promise((resolve, reject) => {
        promise.then(
            (val: any) => hasCanceled_ ? reject({isCanceled: true}) : resolve(val),
            (error: any) => hasCanceled_ ? reject({isCanceled: true}) : reject(error)
        );
    });
    return {
        promise: wrappedPromise,
        cancel() {
            hasCanceled_ = true;
        },
    };
  };

export interface CancellableTask
{
    promise: Promise<unknown>;
    cancel(): void;
}

export class ConcurrentTask<T>
{   
    private pendingRequests: number[] = [];
    private cancelablePromise: CancellableTask | undefined;
    private lastRequestCompleted: boolean = true;    
    
    public async executePromise(        
        promiseFunc: ()=> Promise<T>,
         defaultResult: any
         ): Promise<T | undefined>
    {
       
        // await this.delay(100);

        this.pendingRequests.push(0); //enqueue the incoming request
    
        this.tryAbort();

        console.debug("Waiting for previous request complete. Requests numbers", this.pendingRequests.length);
    
        await this.waitForRequestCompletion(300); //wait completition of the previous request        

        // const sFilter = this.pendingRequests.pop();
        // this.pendingRequests = [];

        console.debug("Requests", this.pendingRequests.length);

        this.tryAbort();        

        // if (this.pendingRequests.length > 1)
        // {
        //     this.pendingRequests.shift();
        //     this.lastRequestCompleted = true;
        //     return defaultResult;
        // }        

        try
        {            
            this.pendingRequests.pop();
            this.lastRequestCompleted = false;
            console.debug("Execute task");
            this.cancelablePromise = makeCancelable(promiseFunc());
            const result = await this.cancelablePromise.promise as T;
            console.debug("Executed task", this.pendingRequests.length);
            return result;
        }
        catch (err)
        {
            if (err.isCanceled)
            {
                console.debug("Task aborted");
                return defaultResult;
            }
            else
            {
                throw err;
            }
        }
        finally
        {
            this.cancelablePromise = undefined;
            this.lastRequestCompleted = true;
        }
        
    }

    private tryAbort() {
        if (this.cancelablePromise) {
            this.cancelablePromise.cancel(); //cancel the previous task (promise)
            console.log("Previous request has been aborted");
        }
    }

    private async waitForRequestCompletion(retryMs: number): Promise<void>{
                
        while (!this.lastRequestCompleted)
        {            
          await this.delay(retryMs);    
        }
        this.lastRequestCompleted = false;
    }

    private delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
      }
}