import { ITaskClient } from "./ITaskClient";
import { APIUtility } from "./../Utility/APIUtility";
import { ITask } from "./../Models/Task";
import {
  TaskDTO,
  TaskDTO_Insert,
  TaskDTO_Update,
} from "./../Models/DTO/TaskDTO";

import "../Utility/DateExtensions";
import { NoContent } from "../Models/NoContent";
import { User } from "../Models/User";
import { HttpError } from "../Models/HttpResponse";
import { ErrorClient } from "./ErrorClient";
import moment from "moment";

export class TaskClient implements ITaskClient {
  private readonly _validStatusCode: number[] = [200, 204];

  public async getTasks(id: number): Promise<ITask[] | undefined> {
    let retValue: ITask[] | undefined = undefined;
    try {
      let apiUtility = new APIUtility();

      var elements: TaskDTO[] = await apiUtility.getActivityTasks(id);

      retValue = elements.map((task) => {
        return {
          id: task.id,
          title: task.name,
          assignedTo: task.assignedResources,
          dateTo: moment
            .utc(task.dateTo)
            .local()
            .toDate(),
          dateFrom: moment
            .utc(task.dateFrom)
            .local()
            .toDate(),
          status: task.status,
          edit: false,
        };
      });
    } catch (err) {
      console.error(err);
    } finally {
      return retValue;
    }
  }

  public async insertTask(
    idActivity: string,
    task: ITask
  ): Promise<boolean | undefined> {
    let retValue: boolean | undefined = undefined;
    try {
      let taskDTO: TaskDTO_Insert = {
        title: task.title,
        dateFrom: new Date().toUTCString(),
        dateTo: task.dateTo?.toUTCString(),
        userUPNs: task.assignedTo?.map((user) => user.userPrincipalName),
      };

      let apiUtility = new APIUtility();
      await apiUtility.insertTask(idActivity, taskDTO);
      retValue = true;
    } catch (err) {
      console.error(err);
    } finally {
      return retValue;
    }
  }

  public async getActivityResources(
    idActivity: string,
    text: string
  ): Promise<User[] | undefined> {
    let retValue: User[] | undefined = undefined;
    try {
      let apiUtility = new APIUtility();

      let sText = text;

      if (!sText || !sText.trim()) {
        sText = "%20";
      }

      const usersDTO = await apiUtility.getActivityResources(idActivity, sText);

      if (usersDTO instanceof NoContent) {
        retValue = [];
      } else {
        const users = usersDTO.map((userDTO) => {
          return {
            id: userDTO.id,
            userPrincipalName: userDTO.userPrincipalName,
            displayName: userDTO.displayName,
            group: userDTO.group,
            initials: userDTO.initials,
          };
        });

        retValue = users;
      }
    } catch (err) {
      console.error(err);
    } finally {
      return retValue;
    }
  }

  public async completeTask(
    idActivity: string,
    idTask: string
  ): Promise<boolean | undefined> {
    let retValue: boolean | undefined = undefined;
    try {
      let taskDTO: TaskDTO_Update = {
        status: "Done",
      };

      let apiUtility = new APIUtility();
      await apiUtility.updateTask(idActivity, idTask, taskDTO);
      retValue = true;
    } catch (err) {
      console.error(err);
    } finally {
      return retValue;
    }
  }

  public async openTask(
    idActivity: string,
    idTask: string
  ): Promise<boolean | undefined> {
    let retValue: boolean | undefined = undefined;
    try {
      let taskDTO: TaskDTO_Update = {
        status: "InProgress",
      };

      let apiUtility = new APIUtility();
      await apiUtility.updateTask(idActivity, idTask, taskDTO);
      retValue = true;
    } catch (err) {
      console.error(err);
    } finally {
      return retValue;
    }
  }

  public async deleteTask(
    idActivity: string,
    idTask: string
  ): Promise<boolean | undefined> {
    let retValue: boolean | undefined = undefined;
    try {
      let apiUtility = new APIUtility();
      const result = await apiUtility.deleteTask(idActivity, idTask);

      retValue = this._validStatusCode.includes(result);
    } catch (err) {
      this.storeError(err);
    } finally {
      return retValue;
    }
  }

  private storeError(err: HttpError): void {
    console.error(err);
    ErrorClient.storeHttpError(err);
  }
}
