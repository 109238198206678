import { CustomPage } from "./../Models/CustomPage";
import { CustomPageDTO } from "./../Models/DTO/CustomPageDTO";
import { ICustomPageClient } from "./ICustomPageClient";
import { Utility } from "./../Utility/Utility";
import { APIUtility } from "../Utility/APIUtility";
import { HttpError } from "../Models/HttpResponse";
import { ErrorClient } from "./ErrorClient";

export class CustomPageClient implements ICustomPageClient {
  public async getCustomPage(title: string): Promise<CustomPage | undefined> {
    let retValue: CustomPage | undefined = undefined;
    try {
      let apiUtility = new APIUtility();

      var element = await apiUtility.getCustomPage(title);

      if (element) {
        retValue = {
          page: {
            pageName: element.page.pageName,
            content: element.page.content,
          },
          // permissions: element.permissions,
          permissions: {
            canEdit: element.permissions.canEdit,
          },
        };
      }
    } catch (err) {
      this.storeError(err);
    } finally {
      return retValue;
    }
  }

  public async updateCustomPage(
    title: string,
    content: string
  ): Promise<boolean | undefined> {
    let retValue: boolean | undefined = undefined;

    try {
      let apiUtility = new APIUtility();

      const formData = this.buildFormData(content);

      await apiUtility.updateCustomPage(title, formData);
      retValue = true;
    } catch (err) {
      this.storeError(err);
    } finally {
      return retValue;
    }
  }

  private buildFormData(content: string): FormData {
    const form = new FormData();

    form.append("content", content);
    return form;
  }

  private storeError(err: HttpError): void {
    console.error(err);
    ErrorClient.storeHttpError(err);
  }
}
