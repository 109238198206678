import { IResponsibility } from './../Models/IResponsibility';
import { DocumentDetailsDTO } from "./../Models/DTO/DocumentDTO";
import {
  ActivityHistory,
  ActivityHistoryDescription,
  ActivityHistoryType,
} from "./../Models/ActivityHistory";
import { HistoryDTO } from "./../Models/DTO/HistoryDTO";
import { BriefDTO, BriefLookupDTO } from "./../Models/DTO/BriefDTO";
import { IDeliverable, IDeliverableDetails } from "./../Models/Deliverable";
// import { DeliverableDTO } from "./../Models/DTO/DeliverableDTO";
import {
  ActivityPermissions,
  ActivityStatusEnum,
  IActivitiesCount,
  IAvailableStatus,
  IMediaPlan,
  IMicro,
} from "./../Models/Activity";
import { IDateRangeValue } from "./../Components/Shared/RangeCalendar/IDateRangeValue";
import {
  Activity,
  ActivityResponse,
  AvailableStatusDTO,
} from "./../Models/DTO/ActivityDTO";
import { IActivityClient, IFilterPlannerActivity } from "./IActivityClient";
import { ErrorClient } from "./ErrorClient";
import {
  IActivity,
  IActivityAllAttributes,
  IListActivities,
  IListActivitiesFilter,
  IOrderBy,
} from "../Models/Activity";
import { APIUtility } from "../Utility/APIUtility";
import moment from "moment";
import { Utility } from "../Utility/Utility";
import {
  AllActivityDTO,
  ICalendarPermissionDTO,
  IPermissionDTO,
  MicroActivityDTO,
  TimelineDTO,
} from "../Models/DTO/TimelineDTO";
import { Style, Timeline, Track, TimelineResult } from "../Models/Timeline";
import { MacroDTO } from "../Models/DTO/MacroDTO";
import { User } from "../Models/User";
import { Calendar, CalendarResult, LinkedActivity } from "../Models/Calendar";
import { UserDTO } from "../Models/DTO/UserDTO";
import { TaskDTO } from "../Models/DTO/TaskDTO";
import { ICalendarPermission, IPlannerPermission } from "../Models/Planner";
import { IBriefLookup } from "../Models/Brief";
import { ICalendar } from "../Models/ICalendar";
import { ICalendarDTO } from "../Models/DTO/ICalendarDTO";
import { ICategory } from "../Models/ICategory";
import { ICategoryDTO } from "../Models/DTO/ICategoryDTO";
import { ISensibility } from "../Models/ISensibility";
import { ISensibilityDTO } from "../Models/DTO/ISensibilityDTO";
import { IResponsabilityDTO } from '../Models/DTO/IResponsabilityDTO';

export class ActivityClient implements IActivityClient {
  private readonly _http: APIUtility = new APIUtility();

  public async getMacroActivity(
    id: number
  ): Promise<IActivityAllAttributes | undefined> {
    // return (await this._http.getMacroActivity(id)).activity;
    return this.getActivityDetails(id, true);
  }

  public async getMicroActivity(
    id: number
  ): Promise<IActivityAllAttributes | undefined> {
    // return (await this._http.getMicroActivity(id)).activity;
    return this.getActivityDetails(id, false);
  }

  private storeError(err: any): void {
    console.error(err);
    ErrorClient.storeHttpError(err);
  }

  public async getTimelineByFilters(
    filterOptions: IFilterPlannerActivity
  ): Promise<TimelineResult | undefined> {
    let retValue: TimelineResult | undefined = undefined;
    try {
      const dto = await this._http.getPlannerActivities(
        "timeline",
        filterOptions.fromDate,
        filterOptions.toDate
      );

      const data = this.toTimelineModel(
        dto,
        filterOptions.activityType,
        filterOptions.activityStatus,
        filterOptions.activityCalendar,
        filterOptions.activitySensibility,
        filterOptions.activityCategory,
        filterOptions.activityResponsibility
      );

      const permissions = this.toPlannerPermission(dto.permissions);

      retValue = { data: data, permissions, calendarsPermissions: dto.calendarsPermissions };
    } catch (err) {
      this.storeError(err);
    } finally {
      return retValue;
    }
  }

  public async getCalendarActivities(
    filterOptions: IFilterPlannerActivity
  ): Promise<CalendarResult | undefined> {
    let retValue: CalendarResult | undefined = undefined;
    try {
      const dto = await this._http.getPlannerActivities(
        "planner",
        filterOptions.fromDate,
        filterOptions.toDate
      );

      const data = this.toCalendarModel(
        dto,
        filterOptions.activityType,
        filterOptions.activityStatus,
        filterOptions.activityCalendar,
        filterOptions.activitySensibility,
        filterOptions.activityCategory,
        filterOptions.activityResponsibility
      );

      const permission = this.toPlannerPermission(dto.permissions);

      retValue = { data: data, permissions: permission, calendarsPermissions: dto.calendarsPermissions };
    } catch (err) {
      this.storeError(err);
    } finally {
      return retValue;
    }
  }

  toPlannerPermission(permissions: IPermissionDTO): IPlannerPermission {
    return permissions;
  }

  public async getCalendars(): Promise<ICalendar[] | undefined> {
    let retValue: ICalendar[] | undefined = undefined;
    try {
      const calDTO = await this.getAPICalendarsAsync();
      retValue = this.toICalendars(calDTO);
    } catch (err) {
      console.error(err);
    } finally {
      return retValue;
    }
  }
  private async getAPICalendarsAsync(): Promise<ICalendarDTO[]> {
    return await this._http.getCalendarsAsync();
  }

  public async getCalendarsPermissions(): Promise<ICalendarPermission[] | undefined> {
    let retValue: ICalendarPermission[] | undefined = undefined;
    try {
      const calPermDTO = await this.getAPICalendarsPermissionsAsync();
      retValue = this.toICalendarsPermissions(calPermDTO);
    } catch (err) {
      console.error(err);
    } finally {
      return retValue;
    }
  }
  private async getAPICalendarsPermissionsAsync(): Promise<ICalendarPermissionDTO[]> {
    return await this._http.getCalendarsPermissionsAsync();
  }
  private toICalendarsPermissions(dto: ICalendarPermissionDTO[]): ICalendarPermission[] {
    return dto.map((t) => this.toICalendarPermission(t));
  }
  private toICalendarPermission(dto: ICalendarPermissionDTO): ICalendarPermission {
    return dto;
  }


  public async getCategories(): Promise<ICategory[] | undefined> {
    let retValue: ICategory[] | undefined = undefined;
    try {
      const dto = await this.getAPICategoriesAsync();
      retValue = this.toICategories(dto);
    } catch (err) {
      console.error(err);
    } finally {
      return retValue;
    }
  }
  private async getAPICategoriesAsync(): Promise<ICategoryDTO[]> {
    return await this._http.getCategoriesAsync();
  }
  private toICategories(dto: ICategoryDTO[]): ICategory[] {
    return dto.map((t) => this.toICategory(t));
  }
  private toICategory(dto: ICategoryDTO): ICategory {
    const entity: ICategory = {
      key: dto.categoryID,
      value: dto.title,
    };
    return entity;
  }

  public async getResponsibilities(): Promise<IResponsibility[] | undefined> {
    let retValue: IResponsibility[] | undefined = undefined;
    try {
      const dto = await this.getAPIResponsibilitiesAsync();
      retValue = this.toIResponsibilities(dto);
    } catch (err) {
      console.error(err);
    } finally {
      return retValue;
    }
  }
  private async getAPIResponsibilitiesAsync(): Promise<IResponsabilityDTO[]> {
    return await this._http.getResponsibilitiesAsync();
  }
  private toIResponsibilities(dto: IResponsabilityDTO[]): IResponsibility[] {
    return dto.map((t) => this.toIResponsibility(t));
  }
  private toIResponsibility(dto: IResponsabilityDTO): IResponsibility {
    const entity: IResponsibility = {
      key: dto.responsibilityID,
      value: dto.title,
    };
    return entity;
  }


  public async getSensibilities(): Promise<ISensibility[] | undefined> {
    let retValue: ISensibility[] | undefined = undefined;
    try {
      const dto = await this.getAPISensibilitiesAsync();
      retValue = this.toISensibilities(dto);
    } catch (err) {
      console.error(err);
    } finally {
      return retValue;
    }
  }
  private async getAPISensibilitiesAsync(): Promise<ISensibilityDTO[]> {
    return await this._http.getSensibilitiesAsync();
  }
  private toISensibilities(dto: ISensibilityDTO[]): ISensibility[] {
    return dto.map((t) => this.toISensibility(t));
  }
  private toISensibility(dto: ISensibilityDTO): ISensibility {
    const entity: ICategory = {
      key: dto.sensibilityID,
      value: dto.title,
    };
    return entity;
  }


  public async getMacroAsync(date: Date | undefined, calendar: number): Promise<IActivity[] | undefined> {
    let retValue: IActivity[] | undefined = undefined;
    try {
      const actDTO = await this.getAPIMacroAsync(date, calendar);
      retValue = this.toIActivities(actDTO);
    } catch (err) {
      console.error(err);
    } finally {
      return retValue;
    }
  }

  private async getAPIMacroAsync(date: Date | undefined, calendar: number): Promise<MacroDTO[]> {
    let fdate: string | undefined = undefined;
    if (date) {
      fdate = date.utc().toISOString();
    }

    return await this._http.getMacrosAsync(fdate, calendar);
  }

  private dynamicSort(property: any) {
    // let sortOrder = 1; //asc
    let sortOrder = -1; //desc
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a: any, b: any) {
      const result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }

  private toICalendars(dto: ICalendarDTO[]): ICalendar[] {
    return dto.map((t) => this.toICalendar(t));
  }

  private toICalendar(dto: ICalendarDTO): ICalendar {
    const cal: ICalendar = {
      key: dto.calendarID,
      value: dto.title,
    };
    return cal;
  }

  private toIActivities(dto: MacroDTO[]): IActivity[] {
    return dto.map((t) => this.toIActivity(t));
  }

  private toIActivity(dto: MacroDTO): IActivity {
    const act: IActivity = {
      id: dto.id,
      isMacro: false,
      name: dto.name,
      calendar: dto.calendarId,
      dateFrom: moment
        .utc(dto.dateFrom)
        .local()
        .toDate(),
      dateTo: moment
        .utc(dto.dateTo)
        .local()
        .toDate(),
      permissions: Utility.getDefaultActivityPermissions(),
    };

    return act;
  }

  private toLocalDate(date: Date, onlyDate: boolean = false): Date {
    var dateNew = moment
      .utc(date)
      .local()
      .toDate();

    if (onlyDate) dateNew.setHours(0, 0, 0, 0);

    return dateNew;
  }

  private toLinkedActivity(
    macroName: string,
    microDTO: MicroActivityDTO
  ): LinkedActivity {
    const linkedActivityModel = new LinkedActivity();

    linkedActivityModel.id = microDTO.id ? microDTO.id : -1;
    linkedActivityModel.title = microDTO.name;
    linkedActivityModel.calendar = microDTO.calendarId;
    linkedActivityModel.start = this.toLocalDate(microDTO.dateFrom);
    linkedActivityModel.end = this.toLocalDate(microDTO.dateTo);
    linkedActivityModel.desc = microDTO.description ? microDTO.description : "";
    linkedActivityModel.state = microDTO.status ? microDTO.status : "";
    linkedActivityModel.macroName = macroName;
    linkedActivityModel.users = this.toUsers(microDTO);

    return linkedActivityModel;
  }

  private toUsers(micro: any): User[] {
    if (micro.externalResources && micro.resources) {
      const externalUsers = micro.externalResources
        ? micro.externalResources.map(this.toUser)
        : [];
      const internalUsers = micro.resources
        ? micro.resources.map(this.toUser)
        : [];

      return externalUsers.concat(internalUsers);
    } else {
      return [];
    }
  }

  private toUser(dto: UserDTO): User {
    const user: User = {
      id: dto.id,
      userPrincipalName: dto.userPrincipalName,
      initials: dto.initials,
      displayName: dto.displayName,
    };

    return user;
  }

  private toLinkedActivities(
    macroName: string,
    linkedActivities: MicroActivityDTO[],
    statusFilter: string[]
  ): LinkedActivity[] | undefined {
    let linkedMicroActivities: LinkedActivity[] | undefined = linkedActivities
      ? linkedActivities.map((item) => {
        return this.toLinkedActivity(macroName, item);
      })
      : undefined;

    linkedMicroActivities = linkedMicroActivities
      ? linkedMicroActivities.filter(function (item) {
        return statusFilter.includes(item.state);
      })
      : undefined;

    return linkedMicroActivities;
  }

  private getEvents(
    activities: AllActivityDTO[],
    includeMicro: boolean,
    statusFilter: string[]
  ): Calendar[] {
    const acts: Calendar[] = activities.map((item: AllActivityDTO) => {
      const linkedMicroActivities =
        includeMicro && item.microActivities != undefined
          ? this.toLinkedActivities(
            item.name,
            item.microActivities,
            statusFilter
          )
          : [];

      var diff = Math.abs((new Date(item.dateFrom)).getTime() - (new Date(item.dateTo)).getTime());
      var diffDays = Math.ceil(diff / (1000 * 3600 * 24));
      const calendar: Calendar = {
        id: item.id,
        title: item.name,
        state: item.status,
        isMacro: item.isMacro,
        calendar: item.calendarId,
        start: item.isMacro
          ? diffDays <= 1
            ? this.toLocalDate(item.dateFrom, true)
            : this.toLocalDate(item.dateFrom, false)
          : this.toLocalDate(item.dateFrom),
        end: item.isMacro
          ? diffDays <= 1
            ? this.toLocalDate(item.dateTo, true)
            : this.toLocalDate(item.dateTo, false)
          : this.toLocalDate(item.dateTo),
        desc: item.description,
        Nmicro: linkedMicroActivities ? linkedMicroActivities.length : 0,
        allDay: false,
        linkedActivities: linkedMicroActivities,
        users: [],
      };

      return calendar;
    });

    return acts;
  }

  private getOnlyMicroEvents(activities: MicroActivityDTO[]): Calendar[] {
    return activities.map((item: MicroActivityDTO) => {
      const calendar: Calendar = {
        id: item.id,
        title: item.name,
        state: item.status,
        isMacro: item.isMacro,
        calendar: item.calendarId,
        start: this.toLocalDate(item.dateFrom),
        end: this.toLocalDate(item.dateTo),
        desc: item.description,
        Nmicro: 0,
        allDay: false,
        users: this.toUsers(item),
      };

      return calendar;
    });
  }

  private toCalendarModel(
    response: TimelineDTO,
    filter: string,
    statusFilter: string[],
    calendarFilter: string[],
    sensibilityFilter: string[],
    categoryFilter: string[],
    responsibilityFilter: string[]
  ): Calendar[] {
    let events: Calendar[] = [];
    switch (filter.toLowerCase()) {
      case "all":
        for (const activity of response.allActivities) {
          if (activity.isMacro) {
            activity.microActivities = activity.microActivities.filter(item =>
              statusFilter.includes(item.status)
              && calendarFilter.includes(item.calendarId.toString())
            )

            // filter by sensibilities
            if (!!sensibilityFilter && sensibilityFilter.length > 0) {
              activity.microActivities = activity.microActivities.filter(a => !!a.sensibility && sensibilityFilter.includes(a.sensibility.toString()))
            }
            // filter by categories
            if (!!categoryFilter && categoryFilter.length > 0) {
              activity.microActivities = activity.microActivities.filter(a => !!a.categoryIds && a.categoryIds.filter(x => categoryFilter.includes(x.toString())).length > 0)
            }
            // filter by responsibilities
            if (!!responsibilityFilter && responsibilityFilter.length > 0) {
              activity.microActivities = activity.microActivities.filter(a => !!a.responsibilityIds && a.responsibilityIds.filter(x => responsibilityFilter.includes(x.toString())).length > 0)
            }
          }
        }
        var activities: AllActivityDTO[] = response.allActivities.filter(
          (item) => {
            return (
              (statusFilter.includes(item.status) && !item.isMacro) ||
              item.isMacro
            ) && calendarFilter.includes(item.calendarId.toString()
            );
          }
        );

        // filter by sensibilities
        if (!!sensibilityFilter && sensibilityFilter.length > 0) {
          activities = activities.filter(a => a.isMacro || !!a.sensibility && sensibilityFilter.includes(a.sensibility.toString()))
        }
        // filter by categories
        if (!!categoryFilter && categoryFilter.length > 0) {
          activities = activities.filter(a => a.isMacro || !!a.categoryIds && a.categoryIds.filter(x => categoryFilter.includes(x.toString())).length > 0)
        }
        // filter by responsibilities
        if (!!responsibilityFilter && responsibilityFilter.length > 0) {
          activities = activities.filter(a => a.isMacro || !!a.responsibilityIds && a.responsibilityIds.filter(x => responsibilityFilter.includes(x.toString())).length > 0)
        }


        events = this.getEvents(activities, true, statusFilter);

        break;

      case "macro":
        const activities_macro: AllActivityDTO[] = response.allActivities.filter(
          (item) => {
            return item.isMacro && calendarFilter.includes(item.calendarId.toString());
          }
        );
        events = this.getEvents(activities_macro, false, statusFilter);

        break;

      case "micro":
        var activities_micro: MicroActivityDTO[] = response.allMicroActivities.filter(
          (item) => {
            return statusFilter.includes(item.status) && calendarFilter.includes(item.calendarId.toString());
          }
        );

        // filter by sensibilities
        if (!!sensibilityFilter && sensibilityFilter.length > 0) {
          activities_micro = activities_micro.filter(a => a.isMacro || !!a.sensibility && sensibilityFilter.includes(a.sensibility.toString()))
        }
        // filter by categories
        if (!!categoryFilter && categoryFilter.length > 0) {
          activities_micro = activities_micro.filter(a => a.isMacro || !!a.categoryIds && a.categoryIds.filter(x => categoryFilter.includes(x)).length > 0)
        }
        // filter by responsibilities
        if (!!responsibilityFilter && responsibilityFilter.length > 0) {
          activities_micro = activities_micro.filter(a => a.isMacro || !!a.responsibilityIds && a.responsibilityIds.filter(x => responsibilityFilter.includes(x)).length > 0)
        }

        events = this.getOnlyMicroEvents(activities_micro);

        break;
    }

    return events;
  }

  private toTimelineModel(
    response: TimelineDTO,
    filter: string,
    statusFilter: string[],
    calendarFilter: string[],
    sensibilityFilter: string[],
    categoryFilter: string[],
    responsibilityFilter: string[]
  ): Timeline[] {
    let timelines: Timeline[] = [];
    switch (filter.toLowerCase()) {
      case "all":
        for (const activity of response.allActivities) {
          if (activity.isMacro) {
            activity.microActivities = activity.microActivities.filter(item => statusFilter.includes(item.status) && calendarFilter.includes(item.calendarId.toString()))

            // filter by sensibilities
            if (!!sensibilityFilter && sensibilityFilter.length > 0) {
              activity.microActivities = activity.microActivities.filter(a => !!a.sensibility && sensibilityFilter.includes(a.sensibility.toString()))
            }
            // filter by categories
            if (!!categoryFilter && categoryFilter.length > 0) {
              activity.microActivities = activity.microActivities.filter(a => !!a.categoryIds && a.categoryIds.filter(x => categoryFilter.includes(x.toString())).length > 0)
            }
            // filter by responsibilities
            if (!!responsibilityFilter && responsibilityFilter.length > 0) {
              activity.microActivities = activity.microActivities.filter(a => !!a.responsibilityIds && a.responsibilityIds.filter(x => responsibilityFilter.includes(x.toString())).length > 0)
            }
          }
        }
        var activities: AllActivityDTO[] = response.allActivities.filter(
          (item) => {
            return (
              ((statusFilter.includes(item.status) && !item.isMacro) || (item.isMacro && item.microActivities.length > 0)) && calendarFilter.includes(item.calendarId.toString())
            );
          }
        );

        // filter by sensibilities
        if (!!sensibilityFilter && sensibilityFilter.length > 0) {
          activities = activities.filter(a => (a.isMacro && a.microActivities.length > 0) || !!a.sensibility && sensibilityFilter.includes(a.sensibility.toString()))
        }
        // filter by categories
        if (!!categoryFilter && categoryFilter.length > 0) {
          activities = activities.filter(a => (a.isMacro && a.microActivities.length > 0) || !!a.categoryIds && a.categoryIds.filter(x => categoryFilter.includes(x.toString())).length > 0)
        }
        // filter by responsibilities
        if (!!responsibilityFilter && responsibilityFilter.length > 0) {
          activities = activities.filter(a => (a.isMacro && a.microActivities.length > 0) || !!a.responsibilityIds && a.responsibilityIds.filter(x => responsibilityFilter.includes(x.toString())).length > 0)
        }

        timelines = this.getTimelines(activities, true);
        break;

      case "macro":
        const activities_macro: AllActivityDTO[] = response.allActivities.filter(
          (item) => {
            return item.isMacro && calendarFilter.includes(item.calendarId.toString());
          }
        );
        timelines = this.getTimelines(activities_macro, false);

        break;

      case "micro":
        var activities_micro: MicroActivityDTO[] = response.allMicroActivities.filter(
          (item) => {
            return statusFilter.includes(item.status) && calendarFilter.includes(item.calendarId.toString());
          }
        );
        // filter by sensibilities
        if (!!sensibilityFilter && sensibilityFilter.length > 0) {
          activities_micro = activities_micro.filter(a => !!a.sensibility && sensibilityFilter.includes(a.sensibility.toString()))
        }
        // filter by categories
        if (!!categoryFilter && categoryFilter.length > 0) {
          activities_micro = activities_micro.filter(a => a.isMacro || !!a.categoryIds && a.categoryIds.filter(x => categoryFilter.includes(x.toString())).length > 0)
        }
        // filter by responsibilities
        if (!!responsibilityFilter && responsibilityFilter.length > 0) {
          activities_micro = activities_micro.filter(a => a.isMacro || !!a.responsibilityIds && a.responsibilityIds.filter(x => responsibilityFilter.includes(x.toString())).length > 0)
        }

        timelines = this.getTimelinesByMicro(activities_micro);
        break;
    }

    return timelines;
  }

  private getTimelines(
    activities: AllActivityDTO[],
    includeMicro: boolean
  ): Timeline[] {
    return activities.map((item: AllActivityDTO) => {
      const linkedMicroActivities =
        includeMicro && item.microActivities
          ? this.macroToTracks(item)
          : this.getTracksByMicro(item);
      let tt = linkedMicroActivities.map((t) => ({ state: t.state }));

      if (!includeMicro) {
        const linkedMicroActivities = item.microActivities
          ? this.macroToTracks(item)
          : [];
        tt = linkedMicroActivities.map((t) => ({ state: t.state }));
      }

      let classes = item.isMacro ? "timelineMacro " : "timelineMicro ";

      classes += item.isMacro
        ? ""
        : Utility.GetActivityClassNameFromStatus(item.status, false);

      const style: Style = this.getActivityTimelineStyle(item);
      const timeline: Timeline = {
        id: item.id.toString(),
        identifier: item.id.toString(),
        title: item.name,
        state: item.status,
        isMacro: item.isMacro,
        Nmicro: item.microActivities ? item.microActivities.length : 0,
        elements: [
          {
            id: item.id.toString(),
            identifier: item.id.toString(),
            title: item.name,
            start: moment
              .utc(item.dateFrom)
              .local()
              .toDate(),
            end: moment
              .utc(item.dateTo)
              .local()
              .toDate(),
            startActivity: moment
              .utc(item.dateFrom)
              .local()
              .toDate(),
            endActivity: moment
              .utc(item.dateTo)
              .local()
              .toDate(),
            isMacro: item.isMacro,
            state: item.status,
            classes: classes,
            calendar: item.calendarId,
            style: style,
            users: [],
          },
        ],
        tracks: linkedMicroActivities,
        tracks2: tt,
        isOpen: false,
        trackLevel: 1,
      };

      return timeline;
    });
  }

  private getTracksByMicro(
    itemMicro: MicroActivityDTO | AllActivityDTO
  ): Track[] {
    return itemMicro.tasks
      ? itemMicro.tasks.map((task: TaskDTO, index: number) =>
        this.toTrackByMicro(task, itemMicro)
      )
      : [];
  }

  private toTrackByMicro(
    task: TaskDTO,
    itemMicro: MicroActivityDTO | AllActivityDTO
  ): Track {
    const completed = this.isTaskCompleted(task);
    const taskId = task.id.toString();
    return {
      id: taskId,
      identifier: task.id.toString(),
      title: task.name,
      state: task.status ? task.status : "",
      macroName: itemMicro.name,
      calendar: itemMicro.calendarId,
      elements: [
        {
          id: taskId,
          identifier: task.id.toString(),
          title: task.name,
          start: moment
            .utc(task.dateFrom)
            .local()
            .toDate(),
          end: moment
            .utc(task.dateTo)
            .local()
            .toDate(),
          startActivity: moment
            .utc(task.dateFrom)
            .local()
            .toDate(),
          endActivity: moment
            .utc(task.dateTo)
            .local()
            .toDate(),
          state: task.status ? task.status : "",
          classes: completed ? "back-task-completed " : "back-task ",
          calendar: task.calendarId,
          isMacro: false,
          isTask: true,
          style: {
            color: "red",
            borderRadius: "42px",
            border: "12px solid #C2CDDD",
            height: 16,
            marginTop: 8,
          },
          users: this.toUsers(itemMicro),
        },
      ],
      trackLevel: 3,
      trackL1ID: itemMicro.id.toString(),
      trackL2ID: itemMicro.id.toString(),
      completed: completed,
      isOpen: false,
      tracks: [],
      tracks2: [],
    };
  }

  private getTimelinesByMicro(activities: MicroActivityDTO[]): Timeline[] {
    return activities.map((item: MicroActivityDTO) => {
      const tracksByMicro = this.getTracksByMicro(item);
      const classes = Utility.GetActivityClassNameFromStatus(
        item.status,
        false
      );
      const style: Style = this.getActivityTimelineStyle(item);
      const timeline: Timeline = {
        id: item.id.toString(),
        elements: [
          {
            id: item.id.toString(),
            identifier: item.id.toString(),
            title: item.name,
            start: moment
              .utc(item.dateFrom)
              .local()
              .toDate(),
            end: moment
              .utc(item.dateTo)
              .local()
              .toDate(),
            startActivity: moment
              .utc(item.dateFrom)
              .local()
              .toDate(),
            endActivity: moment
              .utc(item.dateTo)
              .local()
              .toDate(),
            isMacro: false,
            state: item.status,
            classes: classes,
            calendar: item.calendarId,
            style: style,
            users: this.toUsers(item),
          },
        ],
        // tracks: tasks,
        tracks: tracksByMicro,
        tracks2: [],
        isOpen: false,
        trackLevel: 1,
        identifier: item.id.toString(),
        title: item.name,
        state: item.status,
        isMacro: item.isMacro,
        Nmicro: 0,
      };

      return timeline;
    });
  }

  private macroToTracks(itemMacro: AllActivityDTO): Track[] {
    return itemMacro.microActivities.map((itemMicro: MicroActivityDTO) => {
      const classes = Utility.GetActivityClassNameFromStatus(
        itemMicro.status,
        false
      );
      const tasks = this.getTracks(itemMicro, itemMacro);
      const microId = getMicroId(itemMacro, itemMicro);

      return this.microToTrack(microId, itemMicro, itemMacro, classes, tasks);
    });
  }

  private microToTrack(
    microId: string,
    itemMicro: MicroActivityDTO,
    itemMacro: AllActivityDTO,
    classes: string,
    itracks: Track[]
  ): Track {
    const tt = itracks.map((t) => ({ state: t.state }));
    const micro_classes = classes + "timelineMicro ";
    return {
      id: microId,
      identifier: itemMicro.id.toString(),
      title: itemMicro.name,
      // desc: itemMicro.description, TODOGIUSEPPE - USELESS
      state: itemMicro.status,
      macroName: itemMacro.name,
      calendar: itemMacro.calendarId,
      elements: [
        {
          id: microId,
          identifier: itemMicro.id.toString(),
          title: itemMicro.name,
          start: moment
            .utc(itemMicro.dateFrom)
            .local()
            .toDate(),
          end: moment
            .utc(itemMicro.dateTo)
            .local()
            .toDate(),
          startActivity: moment
            .utc(itemMicro.dateFrom)
            .local()
            .toDate(),
          endActivity: moment
            .utc(itemMicro.dateTo)
            .local()
            .toDate(),
          classes: micro_classes,
          calendar: itemMicro.calendarId,
          state: itemMicro.status,
          isMacro: false,
          macroName: itemMacro.name,
          style: {
            // color: "#ffffff",
            // borderRadius: "4px",
            // border: "1px solid #C2CDDD",
            // height: 24,
            // marginTop: 4,
          },
          users: this.toUsers(itemMicro),
        },
      ],
      tracks: itracks,
      tracks2: tt,
      isOpen: false,
      trackLevel: 2,
      trackL1ID: itemMacro.id.toString(),
    };
  }

  private getTracks(
    itemMicro: MicroActivityDTO,
    itemMacro: AllActivityDTO
  ): Track[] {
    return itemMicro.tasks
      ? itemMicro.tasks.map((task: TaskDTO, index: number) =>
        this.toTrack(task, itemMacro, itemMicro)
      )
      : [];
  }

  private toTrack(
    task: TaskDTO,
    itemMacro: AllActivityDTO,
    itemMicro: MicroActivityDTO
  ): Track {
    const completed = this.isTaskCompleted(task);
    const taskId = this.getTaskId(itemMacro, itemMicro, task);
    const classes =
      (completed ? "back-task-completed " : "back-task ") + "timelineTask ";
    return {
      id: taskId,
      identifier: task.id.toString(),
      title: task.name,
      state: task.status ? task.status : "",
      macroName: itemMacro.name,
      calendar: itemMacro.calendarId,
      elements: [
        {
          id: taskId,
          identifier: task.id.toString(),
          title: task.name,
          start: moment
            .utc(task.dateFrom)
            .local()
            .toDate(),
          end: moment
            .utc(task.dateTo)
            .local()
            .toDate(),
          startActivity: moment
            .utc(task.dateFrom)
            .local()
            .toDate(),
          endActivity: moment
            .utc(task.dateTo)
            .local()
            .toDate(),
          state: task.status ? task.status : "",
          classes: classes,
          calendar: task.calendarId,
          isMacro: false,
          isTask: true,
          style: {
            // color: "#ffffff",
            // borderRadius: "4px",
            // border: "1px solid #C2CDDD",
            // height: 16,
            // marginTop: 8,
          },
          users: this.toUsers(itemMicro),
        },
      ],
      trackLevel: 3,
      trackL1ID: itemMacro.id.toString(),
      trackL2ID: itemMicro.id.toString(),
      completed: completed,
      isOpen: false,
      tracks: [],
      tracks2: [],
    };
  }

  private getTaskId(
    itemMacro: AllActivityDTO,
    itemMicro: MicroActivityDTO,
    task: TaskDTO
  ): string {
    return (
      itemMacro.id.toString() +
      "-" +
      itemMicro.id.toString() +
      "-" +
      task.id.toString()
    );
  }

  private isTaskCompleted(task: TaskDTO): boolean {
    return task.status
      ? task.status.toLocaleUpperCase() === "DONE"
        ? true
        : false
      : false;
  }

  private getActivityTimelineStyle(itemMacro: any): Style {
    // const macro = {
    //   color: "black",
    //   borderRadius: "4px",
    //   background: "#FFFFFF 0% 0% no-repeat padding-box",
    //   border: "1px solid #C2CDDD",
    //   textAlign: "left",
    // };
    // const micro = {
    //   color: "#ffffff",
    //   borderRadius: "4px",
    //   border: "1px solid #C2CDDD",
    //   height: 24,
    //   marginTop: 4,
    // };

    const macro = {
      // color: "red",
      // borderRadius: "44px",
      // background: "red 0% 0% no-repeat padding-box",
      // border: "10px solid #C2CDDD",
      // textAlign: "left",
    };
    const micro = {
      // color: "red",
      // borderRadius: "124px",
      // border: "1px solid #C2CDDD",
      // height: 249,
      // marginTop: 49,
    };

    return itemMacro.isMacro ? macro : micro;
  }

  public async getActivities(
    filter: IListActivitiesFilter,
    orderBy: (IOrderBy | undefined)[]
  ): Promise<IListActivities | undefined> {
    let retValue: IListActivities | undefined = undefined;
    try {
      let apiUtility = new APIUtility();
      var items = await apiUtility.callActivities(
        filter.maxLength ? filter.maxLength : 50,
        filter.startIndex,
        filter.idActivity,
        filter.type,
        filter.calendar,
        filter.categories,
        filter.responsibilities,
        filter.sensibilities,
        filter.activity,
        filter.showOnlyMyActivities,
        filter.status?.replaceAll(" ", ""),
        filter.lastModifyStartDate?.toUTCString(),
        filter.lastModifyEndDate?.toUTCString(),
        filter.activityDateStartDate?.toUTCString(),
        filter.activityDateEndDate?.toUTCString(),
        filter.dueToStartDate?.toUTCString(),
        filter.dueToEndDate?.toUTCString(),
        orderBy
      );

      retValue = {
        count: items.count,
        activities: items.activities?.map((element) => {
          return {
            permissions: items.permissions,
            isMacro: element.isMacro,
            expanded: false,
            id: element.id,
            name: element.name,
            calendar: element.calendarId,
            categories: element.categoryIds,
            responsibilities: element.responsibilityIds,
            sensibility: element.sensibility,
            description: element.description,
            lastUpdate: element.lastUpdate,
            dateFrom: element.dateFrom,
            dateTo: element.dateTo,
            file: element.file,
            mediaPlan: element.mediaPlan
              ? this._getMediaPlan(element.mediaPlan)
              : undefined,
            createdBy: element.editedBy,
            createdOn: element.createdOn,
            microActivities: element.microActivities
              ? this._getMappedMicroActivities(
                element.microActivities,
                items.permissions
              )
              : undefined,
            parentMacroId: element.parentMacroId,
            parentMacro: element.parentMacro
              ? this._getMappedParentMacro(
                element.parentMacro,
                Utility.getDefaultActivityPermissions()
              )
              : undefined,
            status: element.status,
            lastAction: element.lastAction
              ? this._getMappedHistory(element.lastAction)
              : undefined,
          };
        }),
      };
    } catch (err) {
      console.error(err);
    } finally {
      return retValue;
    }
  }

  public async getActivitiesCount(): Promise<IActivitiesCount[] | undefined> {
    let retValue: IActivitiesCount[] | undefined = undefined;
    try {
      let apiUtility = new APIUtility();
      var counters = await apiUtility.getActivitiesCount();
      retValue = [
        {
          state: "Focus" as any,
          count: counters.focus,
        },
        {
          state: ActivityStatusEnum.Planned,
          count: counters.planned,
        },
        // {
        //   state: ActivityStatusEnum.Draft,
        //   count: counters.draft,
        // },
        {
          state: ActivityStatusEnum["In progress"],
          count: counters.inProgress,
        },
        // {
        //   state: ActivityStatusEnum.Rework,
        //   count: counters.rework,
        // },
        // {
        //   state: ActivityStatusEnum.Approved,
        //   count: counters.approved,
        // },
        // {
        //   state: ActivityStatusEnum.Distributed,
        //   count: counters.distributed,
        // },
        // {
        //   state: ActivityStatusEnum["Ready for Distribution"],
        //   count: counters.readyForDistribution,
        // },
        {
          state: ActivityStatusEnum.Closed,
          count: counters.closed,
        },
      ];
    } catch (err) {
      console.error(err);
    } finally {
      return retValue;
    }
  }

  private _getDateFromAPIString(dateString: any): Date {
    return moment
      .utc(dateString)
      .local()
      .toDate();
  }

  private _getMappedHistoryType(type: string): ActivityHistoryType | undefined {
    switch (type) {
      case "StateChange":
        return ActivityHistoryType.StatusChange;
      default:
        return undefined;
    }
  }

  private _getMappedHistoryDescription(
    type: string,
    description: string
  ): ActivityHistoryDescription | undefined {
    switch (type) {
      case "StateChange":
        var jsonDescription = JSON.parse(description);
        return {
          previousState: this._getMappedStatus(
            jsonDescription.previousState as string
          ),
          currentState: this._getMappedStatus(
            jsonDescription.currentState as string
          ),
        };
      default:
        return undefined;
    }
  }

  private _getMappedStatus(status: string): ActivityStatusEnum {
    var retValue: ActivityStatusEnum = ActivityStatusEnum.Planned;
    switch (status.toLowerCase()) {
      case "planned":
        retValue = ActivityStatusEnum.Planned;
        break;
      // case "draft":
      //   retValue = ActivityStatusEnum.Draft;
      //   break;
      case "inprogress":
      case "in progress":
        retValue = ActivityStatusEnum["In progress"];
        break;
      // case "approved":
      //   retValue = ActivityStatusEnum.Approved;
      //   break;
      // case "rework":
      //   retValue = ActivityStatusEnum.Rework;
      //   break;
      // case "readyfordistribution":
      // case "ready for distribution":
      //   retValue = ActivityStatusEnum["Ready for Distribution"];
      //   break;
      // case "distributed":
      //   retValue = ActivityStatusEnum.Distributed;
      //   break;
      case "closed":
        retValue = ActivityStatusEnum.Closed;
        break;
    }
    return retValue;
  }

  private _getMappedHistory(historyDTO: HistoryDTO): ActivityHistory {
    return {
      id: historyDTO.id,
      type: this._getMappedHistoryType(historyDTO.type),
      description: this._getMappedHistoryDescription(
        historyDTO.type,
        historyDTO.description
      ),
      actionDate: historyDTO.actionDate,
      user: historyDTO.user,
    };
  }

  private _getMediaPlan(mediaPlanDTO: Activity): IMediaPlan {
    return {
      id: mediaPlanDTO.id,
      isMacro: false,
      name: mediaPlanDTO.name,
      calendar: mediaPlanDTO.calendarId,
      description: mediaPlanDTO.description,
      lastUpdate: this._getDateFromAPIString(mediaPlanDTO.lastUpdate),
      dateFrom: this._getDateFromAPIString(mediaPlanDTO.dateFrom),
      dateTo: this._getDateFromAPIString(mediaPlanDTO.dateTo),
      spoc: mediaPlanDTO.spoc,
      headOfActivity: mediaPlanDTO.headOfActivity,
      permissions: Utility.getDefaultActivityPermissions(),
    };
  }

  private _getMappedMicroActivities(
    activities: Activity[],
    permissions: ActivityPermissions
  ): IMicro[] {
    return activities.map((micro: Activity) => {
      return {
        id: micro.id,
        isMacro: false,
        name: micro.name,
        calendar: micro.calendarId,
        categories: micro.categoryIds,
        responsibilities: micro.responsibilityIds,
        sensibility: micro.sensibility,
        description: micro.description,
        lastUpdate: this._getDateFromAPIString(micro.lastUpdate),
        dateFrom: this._getDateFromAPIString(micro.dateFrom),
        dateTo: this._getDateFromAPIString(micro.dateTo),
        spoc: micro.spoc,
        status: micro.status,
        permissions: permissions,
      };
    });
  }

  private _getMappedAvailableStatus(
    availableStatus: AvailableStatusDTO
  ): IAvailableStatus[] {
    return [
      {
        status: ActivityStatusEnum.Planned,
        available: availableStatus.planned,
      },
      // {
      //   status: ActivityStatusEnum.Draft,
      //   available: availableStatus.draft,
      // },
      {
        status: ActivityStatusEnum["In progress"],
        available: availableStatus.inProgress,
      },
      // {
      //   status: ActivityStatusEnum.Approved,
      //   available: availableStatus.approved,
      // },
      // {
      //   status: ActivityStatusEnum.Rework,
      //   available: availableStatus.rework,
      // },
      // {
      //   status: ActivityStatusEnum["Ready for Distribution"],
      //   available: availableStatus.readyForDistribution,
      // },
      // {
      //   status: ActivityStatusEnum.Distributed,
      //   available: availableStatus.distributed,
      // },
      {
        status: ActivityStatusEnum.Closed,
        available: availableStatus.closed,
      },
    ];
  }

  private __getMappedFileBrief(
    idActivity: number,
    brief: BriefDTO
  ): IDeliverableDetails {
    return {
      id: brief.id,
      fileName: brief.fileName,
      fileType: brief.fileName
        ? Utility.GetFileType(brief.fileName)
        : undefined,
      // idActivity: idActivity,

      entityId: idActivity,
      filePath: brief.filePath,
      fileDownload: brief.downloadPath,
      fileSize: brief.fileSize,
      editedBy: brief.editedBy,
      lastUpdate: brief.lastModify,
      size: brief.fileSize ? Utility.GetFileSize(brief.fileSize) : "",
      embedUrl: brief.embedUrl,
    };
  }

  private __getMappedItemBrief(
    idActivity: number,
    brief: BriefLookupDTO
  ): IBriefLookup {
    return {
      id: brief.id,
      title: brief.name,
    };
  }

  private __getMappedBriefDetails(
    idActivity: number,
    brief: BriefDTO
  ): IDeliverableDetails {
    return {
      id: brief.id,
      fileName: brief.fileName,
      fileType: brief.fileName
        ? Utility.GetFileType(brief.fileName)
        : undefined,
      // idActivity: idActivity,
      entityId: idActivity,
      filePath: brief.filePath,
      fileDownload: brief.downloadPath,
      fileSize: brief.fileSize,
      editedBy: brief.editedBy,
      lastUpdate: brief.lastModify,
      size: brief.fileSize ? Utility.GetFileSize(brief.fileSize) : "",
      embedUrl: brief.embedUrl,
    };
  }

  private _getMappedParentMacro(
    macro: Activity,
    permissions: ActivityPermissions
  ): IActivityAllAttributes {
    return {
      permissions: permissions,
      isMacro: true,
      expanded: false,
      id: macro.id,
      name: macro.name,
      categories: [],
      responsibilities: [],
      calendar: macro.calendarId,
      description: macro.description,
      spoc: macro.spoc,
      headOfActivity: macro.headOfActivity,
      lastUpdate: this._getDateFromAPIString(macro.lastUpdate),
      dateFrom: this._getDateFromAPIString(macro.dateFrom),
      dateTo: this._getDateFromAPIString(macro.dateTo),
      // notes: macro.notes ? this._getMappedNotes(macro.notes) : [],
      linkedFileBrief: macro.documentBrief
        ? this.__getMappedFileBrief(macro.id, macro.documentBrief)
        : undefined,
      linkedItemBrief: macro.brief
        ? this.__getMappedItemBrief(macro.id, macro.brief)
        : undefined,
      file: macro.file,
      mediaPlan: macro.mediaPlan
        ? this._getMediaPlan(macro.mediaPlan)
        : undefined,
      createdBy: macro.createdBy,
      editedBy: macro.editedBy,
      createdOn: this._getDateFromAPIString(macro.createdOn),
      microActivities: macro.microActivities
        ? this._getMappedMicroActivities(macro.microActivities, permissions)
        : [],
    };
  }

  private _getMappedDeliverables(
    idActivity: number,
    deliverables: DocumentDetailsDTO[]
  ): IDeliverable[] {
    return deliverables
      .filter((x) => x.filePath !== undefined)
      .map((del) => {
        return {
          id: del.id,
          // idActivity: idActivity,
          entityId: idActivity,
          // entityName: del.entity.name,
          // entityType: del.entity.type,
          fileName: del.fileName,
          fileType: Utility.GetFileType(del.fileName),
          filePath: del.filePath,
          fileSize: del.fileSize,
          editedBy: del.editedBy,
          lastUpdate: this._getDateFromAPIString(del.lastModify),
          size: Utility.GetFileSize(del.fileSize),
          embedUrl: del.embedUrl,
        };
      });
  }

  private _getMappedTasks(tasks: TaskDTO[]) {
    return tasks.map((task) => {
      return {
        id: task.id,
        title: task.name,
        assignedTo: task.assignedResources,
        data: moment
          .utc(task.dateTo)
          .local()
          .toDate(),
        status: task.status,
        edit: false,
      };
    });
  }

  public async getActivityDetails(
    id?: number,
    isMacro?: boolean
  ): Promise<IActivityAllAttributes | undefined> {
    let retValue: IActivityAllAttributes | undefined = undefined;
    try {
      let apiUtility = new APIUtility();

      if (isMacro) {
        var macro: ActivityResponse = await apiUtility.getMacroActivity(id);

        retValue = this._getMappedParentMacro(
          macro.activity,
          macro.permissions
        );
      } else {
        var element: ActivityResponse = await apiUtility.getMicroActivity(id);

        var actions = element.activity.actions
          ? element.activity.actions.sort(
            (a1, a2) =>
              new Date(a2.actionDate).getTime() -
              new Date(a1.actionDate).getTime()
          )
          : [];
        if (
          element.activity.parentMacro &&
          element.activity.parentMacro.id > 0
        ) {
          var macro: ActivityResponse = await apiUtility.getMacroActivity(
            element.activity.parentMacro?.id
          );

          retValue = {
            permissions: element.permissions,
            isMacro: element.activity.isMacro,
            expanded: false,
            id: element.activity.id,
            name: element.activity.name,
            calendar: element.activity.calendarId,
            categories: element.activity.categoryIds,
            responsibilities: element.activity.responsibilityIds,
            sensibility: element.activity.sensibility,
            description: element.activity.description,
            lastUpdate: this._getDateFromAPIString(element.activity.lastUpdate),
            dateFrom: this._getDateFromAPIString(element.activity.dateFrom),
            dateTo: this._getDateFromAPIString(element.activity.dateTo),
            spoc: element.activity.spoc,
            createdBy: element.activity.createdBy,
            headOfActivity: element.activity.headOfActivity,
            // notes: element.activity.notes
            //   ? this._getMappedNotes(element.activity.notes)
            //   : [],
            parentMacroId: element.activity.parentMacroId,
            parentMacro: this._getMappedParentMacro(
              macro.activity,
              macro.permissions
            ),
            status: element.activity.status,
            // deliverables: element.activity.deliverables
            //   ? this._getMappedDeliverables(
            //       element.activity.id,
            //       element.activity.deliverables
            //     )
            //   : [],
            // tasks: element.activity.tasks
            //   ? this._getMappedTasks(element.activity.tasks)
            //   : undefined,
            resources: element.activity.resources,
            alarmResources: element.activity.reminderResources,
            alarm: (element.activity.reminder !== undefined && element.activity.reminder !== null) ? element.activity.reminder : -1,
            externalResources: element.activity.externalResources,
            actions: actions.map((action) => {
              return this._getMappedHistory(action);
            }),
            availableStatus: element.activity.availableStatus
              ? this._getMappedAvailableStatus(element.activity.availableStatus)
              : [],
          };
        } else {
          retValue = {
            permissions: element.permissions,
            isMacro: element.activity.isMacro,
            expanded: false,
            id: element.activity.id,
            name: element.activity.name,
            calendar: element.activity.calendarId,
            categories: element.activity.categoryIds,
            responsibilities: element.activity.responsibilityIds,
            sensibility: element.activity.sensibility,
            description: element.activity.description,
            lastUpdate: this._getDateFromAPIString(element.activity.lastUpdate),
            dateFrom: this._getDateFromAPIString(element.activity.dateFrom),
            dateTo: this._getDateFromAPIString(element.activity.dateTo),
            spoc: element.activity.spoc,
            createdBy: element.activity.createdBy,
            headOfActivity: element.activity.headOfActivity,
            // notes: element.activity.notes
            //   ? this._getMappedNotes(element.activity.notes)
            //   : [],
            parentMacroId: element.activity.parentMacroId,
            parentMacro: undefined,
            status: element.activity.status,
            // deliverables: element.activity.deliverables
            //   ? this._getMappedDeliverables(
            //       element.activity.id,
            //       element.activity.deliverables
            //     )
            //   : [],
            // tasks: element.activity.tasks
            //   ? this._getMappedTasks(element.activity.tasks)
            //   : undefined,
            resources: element.activity.resources,
            alarmResources: element.activity.reminderResources,
            alarm: (element.activity.reminder !== undefined && element.activity.reminder !== null) ? element.activity.reminder : -1,
            externalResources: element.activity.externalResources,
            actions: actions.map((action) => {
              return this._getMappedHistory(action);
            }),
            availableStatus: element.activity.availableStatus
              ? this._getMappedAvailableStatus(element.activity.availableStatus)
              : [],
          };
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      return retValue;
    }
  }

  public async getActivityPermissions(
    id?: number,
    isMacro?: boolean
  ): Promise<ActivityPermissions | undefined> {
    let retValue: ActivityPermissions | undefined = undefined;
    try {
      let apiUtility = new APIUtility();

      if (isMacro) {
        var macroPermissions: ActivityPermissions = await apiUtility.getMacroActivityPermissions(
          id
        );

        retValue = macroPermissions;
      } else {
        var microPermissions: ActivityPermissions = await apiUtility.getMicroActivityPermissions(
          id
        );

        retValue = microPermissions;
      }
    } catch (err) {
      console.error(err);
    } finally {
      return retValue;
    }
  }

  public async updateActivityName(
    id: number,
    name: string | null,
    isMacro?: boolean
  ): Promise<boolean | undefined> {
    let retValue: boolean | undefined = undefined;
    try {
      let apiUtility = new APIUtility();
      var retValueNumber = 0;
      if (isMacro == true)
        retValueNumber = await apiUtility.updateMacroActivity(id.toString(), [
          {
            key: "name",
            value: name,
          },
        ]);
      else if (isMacro == false)
        retValueNumber = await apiUtility.updateMicroActivity(id.toString(), [
          {
            key: "name",
            value: name,
          },
        ]);
      retValue = retValueNumber < 300;
    } catch (err) {
      console.error(err);
    } finally {
      return retValue;
    }
  }

  public async updateActivityParent(
    id: number,
    idMacro: number
  ): Promise<boolean | undefined> {
    let retValue: boolean | undefined = undefined;
    try {
      let apiUtility = new APIUtility();
      var retValueNumber = await apiUtility.updateMicroActivity(id.toString(), [
        {
          key: "idMacro",
          value: idMacro.toString(),
        },
      ]);
      retValue = retValueNumber < 300;
    } catch (err) {
      console.error(err);
    } finally {
      return retValue;
    }
  }

  public async updateActivityDescription(
    id: number,
    description: string | null,
    isMacro?: boolean
  ): Promise<boolean | undefined> {
    let retValue: boolean | undefined = undefined;
    try {
      let apiUtility = new APIUtility();
      var retValueNumber = 0;
      if (isMacro == true)
        retValueNumber = await apiUtility.updateMacroActivity(id.toString(), [
          {
            key: "description",
            value: description,
          },
        ]);
      else if (isMacro == false)
        retValueNumber = await apiUtility.updateMicroActivity(id.toString(), [
          {
            key: "description",
            value: description,
          },
        ]);
      retValue = retValueNumber < 300;
    } catch (err) {
      console.error(err);
    } finally {
      return retValue;
    }
  }

  public async updateActivityStatus(
    id: number,
    status: string
  ): Promise<boolean | undefined> {
    let retValue: boolean | undefined = undefined;
    try {
      let apiUtility = new APIUtility();
      var retValueNumber = await apiUtility.updateMicroActivityStatus(
        id.toString(),
        status.replaceAll(" ", "")
      );

      retValue = retValueNumber < 300;
    } catch (err) {
      console.error(err);
    } finally {
      return retValue;
    }
  }

  public async updateActivityDueTo(
    id: number,
    dateRange: IDateRangeValue
  ): Promise<boolean | undefined> {
    let retValue: boolean | undefined = undefined;
    try {
      let apiUtility = new APIUtility();
      var retValueNumber = await apiUtility.updateMicroActivity(id.toString(), [
        {
          key: "dateFrom",
          value: dateRange.fromDate.toUTCString(),
        },
        {
          key: "dateTo",
          value: dateRange.toDate.toUTCString(),
        },
      ]);
      retValue = retValueNumber < 300;
    } catch (err) {
      console.error(err);
    } finally {
      return retValue;
    }
  }

  public async updateActivityTiming(
    id: number,
    dateRange: IDateRangeValue
  ): Promise<boolean | undefined> {
    let retValue: boolean | undefined = undefined;
    try {
      let apiUtility = new APIUtility();
      var retValueNumber = await apiUtility.updateMacroActivity(id.toString(), [
        {
          key: "dateFrom",
          value: dateRange.fromDate.toUTCString(),
        },
        {
          key: "dateTo",
          value: dateRange.toDate.toUTCString(),
        },
      ]);
      retValue = retValueNumber < 300;
    } catch (err) {
      console.error(err);
    } finally {
      return retValue;
    }
  }

  public async updateActivityInternalResources(
    id: number,
    resources: User[]
  ): Promise<number> {
    let retValue: number = -2;
    try {
      let apiUtility = new APIUtility();
      var result = await apiUtility.updateActivityResources(
        id.toString(),
        "internalResource",
        resources.map((x) => x.userPrincipalName)
      );
      if (Utility.isResponseStatusValid(result)) retValue = 0;
      else if (result == 409) retValue = -1;
      else retValue = -2;
    } catch (err) {
      console.error(err);
    } finally {
      return retValue;
    }
  }

  public async updateActivityAlarmResources(
    id: number,
    resources: User[]
  ): Promise<number> {
    let retValue: number = -2;
    try {
      let apiUtility = new APIUtility();
      var result = await apiUtility.updateActivityResources(
        id.toString(),
        "reminderResource",
        resources.map((x) => x.userPrincipalName)
      );
      if (Utility.isResponseStatusValid(result)) retValue = 0;
      else if (result == 409) retValue = -1;
      else retValue = -2;
    } catch (err) {
      console.error(err);
    } finally {
      return retValue;
    }
  }

  public async updateActivityExternalResources(
    id: number,
    resources: User[]
  ): Promise<number> {
    let retValue: number = -2;
    try {
      let apiUtility = new APIUtility();
      var result = await apiUtility.updateActivityResources(
        id.toString(),
        "externalResource",
        resources.map((x) => x.userPrincipalName)
      );
      if (Utility.isResponseStatusValid(result)) retValue = 0;
      else if (result == 409) retValue = -1;
      else retValue = -2;
    } catch (err) {
      console.error(err);
    } finally {
      return retValue;
    }
  }

  public async updateSpoc(
    id: number,
    spocUPN: string | null,
    isMacro: boolean
  ): Promise<number> {
    let retValue: number = -2;
    let result: number;
    try {
      let apiUtility = new APIUtility();
      if (isMacro) {
        result = await apiUtility.updateMacroActivity(id.toString(), [
          {
            key: "spocUPN",
            value: spocUPN,
          },
        ]);
      } else {
        result = await apiUtility.updateMicroActivity(id.toString(), [
          {
            key: "spocUPN",
            value: spocUPN,
          },
        ]);
      }
      if (Utility.isResponseStatusValid(result)) retValue = 0;
      else if (result == 409) retValue = -1;
      else retValue = -2;
    } catch (err) {
      console.error(err);
    } finally {
      return retValue;
    }
  }

  public async updateReminder(id: number, reminder: number): Promise<number> {
    let retValue: number = -2;
    let result: number;
    try {
      let apiUtility = new APIUtility();
      result = await apiUtility.updateMicroActivity(id.toString(), [
        {
          key: "reminder",
          value: reminder.toString(),
        },
      ]);
      if (Utility.isResponseStatusValid(result)) retValue = 0;
      else if (result == 409) retValue = -1;
      else retValue = -2;
    } catch (err) {
      console.error(err);
    } finally {
      return retValue;
    }
  }

  public async updateActivityCategories(id: number, categories: number[]): Promise<number> {
    let retValue: number = -2;
    let result: number;
    try {
      let apiUtility = new APIUtility();
      result = await apiUtility.updateMicroActivity(id.toString(), [
        {
          key: "categories",
          value: JSON.stringify(categories),
        },
      ]);
      if (Utility.isResponseStatusValid(result)) retValue = 0;
      else if (result == 409) retValue = -1;
      else retValue = -2;
    } catch (err) {
      console.error(err);
    } finally {
      return retValue;
    }
  }

  public async updateActivityCategory(id: number, value: string): Promise<number> {
    let retValue: number = -2;
    let result: number;
    try {
      let apiUtility = new APIUtility();
      result = await apiUtility.updateMicroActivity(id.toString(), [
        {
          key: "categories",
          value: JSON.stringify([value]),
        },
      ]);
      if (Utility.isResponseStatusValid(result)) retValue = 0;
      else if (result == 409) retValue = -1;
      else retValue = -2;
    } catch (err) {
      console.error(err);
    } finally {
      return retValue;
    }
  }

  public async updateActivityResponsibilities(id: number, responsibilities: number[]): Promise<number> {
    let retValue: number = -2;
    let result: number;
    try {
      let apiUtility = new APIUtility();
      result = await apiUtility.updateMicroActivity(id.toString(), [
        {
          key: "responsibilities",
          value: JSON.stringify(responsibilities),
        },
      ]);
      if (Utility.isResponseStatusValid(result)) retValue = 0;
      else if (result == 409) retValue = -1;
      else retValue = -2;
    } catch (err) {
      console.error(err);
    } finally {
      return retValue;
    }
  }

  public async updateActivityResponsibility(id: number, value: string): Promise<number> {
    let retValue: number = -2;
    let result: number;
    try {
      let apiUtility = new APIUtility();
      result = await apiUtility.updateMicroActivity(id.toString(), [
        {
          key: "responsibilities",
          value: JSON.stringify([value]),
        },
      ]);
      if (Utility.isResponseStatusValid(result)) retValue = 0;
      else if (result == 409) retValue = -1;
      else retValue = -2;
    } catch (err) {
      console.error(err);
    } finally {
      return retValue;
    }
  }

  public async updateActivitySensibility(id: number, value: string): Promise<number> {
    let retValue: number = -2;
    let result: number;
    try {
      let apiUtility = new APIUtility();
      result = await apiUtility.updateMicroActivity(id.toString(), [
        {
          key: "sensibility",
          value: value,
        },
      ]);
      if (Utility.isResponseStatusValid(result)) retValue = 0;
      else if (result == 409) retValue = -1;
      else retValue = -2;
    } catch (err) {
      console.error(err);
    } finally {
      return retValue;
    }
  }


  public async updateHeadOfActivity(
    id: number,
    headOfActivityUPN: string | null,
    isMacro: boolean
  ): Promise<boolean | undefined> {
    let retValue: boolean | undefined = undefined;
    try {
      let apiUtility = new APIUtility();
      var retValueNumber = 0;
      if (isMacro) {
        retValueNumber = await apiUtility.updateMacroActivity(id.toString(), [
          {
            key: "headOfActivityUPN",
            value: headOfActivityUPN,
          },
        ]);
      } else {
        retValueNumber = await apiUtility.updateMicroActivity(id.toString(), [
          {
            key: "headOfActivityUPN",
            value: headOfActivityUPN,
          },
        ]);
      }
      retValue = retValueNumber < 300;
    } catch (err) {
      console.error(err);
    } finally {
      return retValue;
    }
  }

  public async deleteActivity(
    idActivity: string,
    isMacro: boolean
  ): Promise<boolean | undefined> {
    let retValue: boolean | undefined = undefined;
    try {
      let apiUtility = new APIUtility();
      var retValueNumber = isMacro
        ? await apiUtility.deleteMacroActivity(idActivity)
        : await apiUtility.deleteMicroActivity(idActivity);
      retValue = retValueNumber < 300;
    } catch (err) {
      console.error(err);
    } finally {
      return retValue;
    }
  }
}
function getMicroId(itemMacro: AllActivityDTO, itemMicro: MicroActivityDTO) {
  return itemMacro.id.toString() + "-" + itemMicro.id.toString();
}
