import { TFunction } from "i18next";
import { CommonTranslation } from "../../../Translations/Common.Translation";

export class MicroTranslation extends CommonTranslation {
  constructor(translation: TFunction) {
    super(translation, "micro");
  }

  public get genericErrorInSave(): string {
    return super.getTextByMessageKey("genericErrorOnSave");
  }

  public get errorInLoadMacro(): string {
    return super.getTextByMessageKey("errorInLoadMacro");
  }
  public get errorInLoadCategories(): string {
    return super.getTextByMessageKey("errorInLoadCategories");
  }
  public get errorInLoadSensibilities(): string {
    return super.getTextByMessageKey("errorInLoadSensibilities");
  }
  public get errorInLoadResponsibilities(): string {
    return super.getTextByMessageKey("errorInLoadResponsibilities");
  }

  public dateMicroLessMacro(fromDate: string, toDate: string): string {
    return super.getTextByMessageKey("dateMicroLessMacro", {
      fromDate: fromDate,
      toDate: toDate,
    });
  }
  public dateMicroGreaterMacro(fromDate: string, toDate: string): string {
    return super.getTextByMessageKey("dateMicroGreaterMacro", {
      fromDate: fromDate,
      toDate: toDate,
    });
  }

  public get addToCalendar(): string {
    return super.getTextByMessageKey("addToCalendar");
  }

  public get warningDateRangeTitle(): string {
    return super.getTextByMessageKey("warningDateRangeTitle");
  }

  public get warningDateRangeSubTitle(): string {
    return super.getTextByMessageKey("warningDateRangeSubTitle");
  }

  public get selectMacro(): string {
    return super.getTextByMessageKey("selectMacro");
  }

  public get timePeriod(): string {
    return super.getTextByMessageKey("timePeriod");
  }

  public get activityTitle(): string {
    return super.getTextByMessageKey("activityTitle");
  }

  public get description(): string {
    return super.getTextByMessageKey("description");
  }

  public get assignActivity(): string {
    return super.getTextByMessageKey("assignActivity");
  }

  public get addExternalResource(): string {
    return super.getTextByMessageKey("addExternalResource");
  }

  public get externalResource(): string {
    return super.getTextByMessageKey("externalResource");
  }

  public get chooseSpoc(): string {
    return super.getTextByMessageKey("chooseSpoc");
  }

  public get associateMacro(): string {
    return super.getTextByMessageKey("associateMacro");
  }

  public get filterMacro(): string {
    return super.getTextByMessageKey("filterMacro");
  }

  public get loadingMacro(): string {
    return super.getTextByMessageKey("loadingMacro");
  }

}