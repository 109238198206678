import * as React from "react";

import "bootstrap/dist/css/bootstrap.css";

import "@fluentui/react/dist/css/fabric.min.css";

import { initializeIcons } from "@uifabric/icons";

import { Utility } from "../../../Utility/Utility";
import { ActivityTranslation } from "../../../Translations/Activity.Translation";
import { Panel, PanelType } from "office-ui-fabric-react/lib/Panel";

// import "./MacroActivityViewComponent.scss";
import styles from "./MacroActivityViewComponent.module.scss";

import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  FontIcon,
  IColumn,
  IDialogContentProps,
  IModalProps,
  TextField,
} from "@fluentui/react";

import { IMacro } from "../../../Models/Activity";
import { IBaseProperties, IRoute, Page } from "../../../Models/IBaseProperties";
import { IDateRangeValue } from "../RangeCalendar/IDateRangeValue";
import { RangeCalendar } from "../RangeCalendar/RangeCalendar";
import { SpinnerComponent } from "../SpinnerComponent/SpinnerComponent";
import RichTextEditor, { EditorValue } from "react-rte";
import { Constants } from "../../../Utility/Constants";
import { ICalendar } from "../../../Models/ICalendar";

export interface IMacroActivityViewComponentProps extends IBaseProperties {
  item?: IMacro;
  edit: boolean;
  calendars: ICalendar[];
  updateDescription?: (text: string) => Promise<boolean>;
  updateTimingActivity?: (
    range: IDateRangeValue | undefined
  ) => Promise<boolean>;
}

export interface IMacroActivityViewComponentState {
  // item?: IMacro;

  showTimingActivity: boolean;
  editTiming: boolean;
  timingActivityRange?: IDateRangeValue;

  showDescription: boolean;
  editDescription: boolean;
  descriptionTemp: EditorValue;
}

export class MacroActivityViewComponent extends React.Component<
  IMacroActivityViewComponentProps,
  IMacroActivityViewComponentState
> {
  _taskColumns: IColumn[] = [];
  private readonly _translation: ActivityTranslation;

  constructor(props: IMacroActivityViewComponentProps) {
    super(props);

    initializeIcons();
    this._translation = new ActivityTranslation(
      this.props.commonProps.translation
    );

    this.state = {
      // item: this.props.item,
      editTiming: false,
      showTimingActivity: true,
      timingActivityRange: this.props.item
        ? {
            fromDate: new Date(this.props.item.dateFrom),
            toDate: new Date(this.props.item.dateTo),
          }
        : undefined,
      showDescription: true,
      editDescription: false,
      descriptionTemp: this.props.item?.description
        ? RichTextEditor.createValueFromString(
            this.props.item?.description,
            "html"
          )
        : RichTextEditor.createEmptyValue(),
    };
  }

  private _decodeHTMLEntities(text: string) {
    var span = document.createElement("span");
    span.innerHTML = text;
    return span.textContent || span.innerText;
  }

  public render() {
    return (
      <div
        role="macroActivityDetailsInfo"
        className={styles.MacroActivityViewComponent}
      >
        <div className={styles.activity_details_parent_body + " row"}>
          <div className={"ms-Grid-col ms-lg3 ms-md6 ms-sm12"}>
            <div className={styles.label_ActivityAttribute + " ms-Grid-col"}>
              {this._translation.activityCalendar}
            </div>
            <div className={styles.value_ActivityAttribute + " ms-Grid-col"}>
              {
                this.props.calendars.find(
                  (c) => c.key === this.props.item?.calendar
                )?.value
              }
            </div>
          </div>

          <div className={"ms-Grid-col ms-lg3 ms-md6 ms-sm12"}>
            <div className={styles.label_ActivityAttribute + " ms-Grid-col"}>
              {this._translation.createdBy}
            </div>
            <div className={styles.value_ActivityAttribute + " ms-Grid-col"}>
              {this.props.item?.createdBy?.displayName}
            </div>
          </div>

          <div className={"ms-Grid-col ms-lg3 ms-md6 ms-sm12"}>
            <div className={styles.label_ActivityAttribute + " ms-Grid-col"}>
              {this._translation.createdOn}
            </div>
            <div className={styles.value_ActivityAttribute + " ms-Grid-col"}>
              {this.props.item?.createdOn
                ? Utility.FormatDate(new Date(this.props.item?.createdOn))
                : "---"}
            </div>
          </div>

          <div className={"ms-Grid-col ms-lg3 ms-md6 ms-sm12"}>
            <div className={styles.label_ActivityAttribute + " ms-Grid-col"}>
              {this._translation.start} - {this._translation.end}
            </div>

            {this.props.edit && (
              <div
                className={
                  styles.value_ActivityAttribute +
                  " ms-Grid-col ms-md4 " +
                  styles.value_RightDiv
                }
              >
                {!this.state.editTiming &&
                  (!this.state.showTimingActivity ? (
                    <SpinnerComponent
                      commonProps={this.props.commonProps}
                      relative={true}
                    ></SpinnerComponent>
                  ) : (
                    <div
                      className={
                        this.props.item?.permissions.canUpdate
                          ? styles.editableField + " " + styles.DivTiming
                          : ""
                      }
                      aria-label={
                        "Edit " +
                        this._translation.start +
                        " - " +
                        this._translation.end
                      }
                      tabIndex={0}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          if (this.props.item?.permissions.canUpdate)
                            this.setState({ editTiming: true });
                        }
                      }}
                      onClick={() => {
                        if (this.props.item?.permissions.canUpdate)
                          this.setState({ editTiming: true });
                      }}
                    >
                      <div
                        className={styles.LabelEditTiming + " " + styles.text}
                      >
                        {this.state.timingActivityRange?.fromDate
                          ? Utility.FormatDate(
                              this.state.timingActivityRange?.fromDate
                            )
                          : "---"}{" "}
                        -{" "}
                        {this.state.timingActivityRange?.toDate
                          ? Utility.FormatDate(
                              this.state.timingActivityRange?.toDate
                            )
                          : "---"}
                      </div>
                      <FontIcon
                        iconName="Edit"
                        className={styles.IconEditTiming + " iconGet"}
                      ></FontIcon>
                    </div>
                  ))}
                {this.state.editTiming && (
                  <RangeCalendar
                    commonProps={this.props.commonProps}
                    showTime={false}
                    disableTime={true}
                    onChange={(newValue) => {
                      if (this.props.updateTimingActivity) {
                        this.setState({ showTimingActivity: false });
                        this.props
                          .updateTimingActivity(newValue)
                          .then((result) => {
                            if (result) {
                              this.setState({
                                timingActivityRange: newValue,
                                editTiming: false,
                                showTimingActivity: true,
                              });
                            } else {
                              this.setState({
                                editTiming: false,
                                showTimingActivity: true,
                              });
                            }
                          });
                      }
                    }}
                    value={this.state.timingActivityRange}
                    onDismiss={() => {
                      this.setState({ editTiming: false });
                    }}
                  ></RangeCalendar>
                )}
              </div>
            )}
            {!this.props.edit && (
              <div className={styles.value_ActivityAttribute + " ms-Grid-col"}>
                {this.props.item?.dateFrom
                  ? Utility.FormatDate(new Date(this.props.item?.dateFrom))
                  : "---"}{" "}
                -{" "}
                {this.props.item?.dateTo
                  ? Utility.FormatDate(new Date(this.props.item?.dateTo))
                  : "---"}
              </div>
            )}
          </div>

          {this.props.item?.linkedFileBrief &&
            this.props.item?.linkedFileBrief?.id > 0 && (
              <div className={"ms-Grid-col ms-sm12"}>
                <hr />
              </div>
            )}

          {this.props.item?.linkedFileBrief &&
            this.props.item?.linkedFileBrief?.id > 0 && (
              <div className={"ms-Grid-col ms-sm12"}>
                <div
                  className={
                    styles.label_ActivityAttribute + " ms-Grid-col ms-sm12"
                  }
                >
                  {this._translation.macroDocumentation}
                </div>
                <div
                  className={
                    "ms-Grid-col ms-md12 " +
                    styles.value_ActivityAttribute +
                    " " +
                    styles.MacroDocumentation
                  }
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      var link = document.createElement("a");
                      link.target = "_blank";
                      link.href = this.props.item?.linkedFileBrief?.fileDownload
                        ? this.props.item?.linkedFileBrief?.fileDownload
                        : "";
                      link.click();
                      link.parentNode?.removeChild(link);
                    }
                  }}
                  onClick={() => {
                    var link = document.createElement("a");
                    link.target = "_blank";
                    link.href = this.props.item?.linkedFileBrief?.fileDownload
                      ? this.props.item?.linkedFileBrief?.fileDownload
                      : "";
                    link.click();
                    link.parentNode?.removeChild(link);
                  }}
                >
                  <FontIcon
                    iconName="TextDocument"
                    className={"iconGet"}
                  ></FontIcon>
                  <div className={styles.editableField}>
                    {this.props.item?.linkedFileBrief.fileName}
                  </div>
                  <FontIcon
                    iconName="ChevronRightSmall"
                    className={"iconGet"}
                  ></FontIcon>
                </div>

                {/* <div
                  className={
                    "ms-Grid-col ms-md12 " +
                    styles.value_ActivityAttribute +
                    " " +
                    styles.MacroDocumentation
                  }
                >
                  <div>{this.props.item?.linkedBrief.fileName}</div>
                </div> */}
              </div>
            )}

          {this.props.item?.linkedItemBrief &&
            this.props.item?.linkedItemBrief?.id > 0 && (
              <div className={"ms-Grid-col ms-sm12"}>
                <hr />
              </div>
            )}

          {this.props.item?.linkedItemBrief &&
            this.props.item?.linkedItemBrief?.id > 0 && (
              <div className={"ms-Grid-col ms-sm12"}>
                <div
                  className={
                    styles.label_ActivityAttribute + " ms-Grid-col ms-sm12"
                  }
                >
                  {this._translation.macroDocumentation}
                </div>
                <div
                  className={
                    "ms-Grid-col ms-md12 " +
                    styles.value_ActivityAttribute +
                    " " +
                    styles.MacroDocumentation
                  }
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      window.scrollTo(0, 0);
                      const route: IRoute = {
                        page: Page.MyBrief,
                        additionalInfo: {
                          keysValues: {
                            id: this.props.item?.linkedItemBrief?.id.toString(),
                            type: undefined,
                            subtype: undefined,
                            title: undefined,
                            showMyActivities: undefined,
                          },
                        },
                      };
                      this.props.commonProps.onRoute?.next(route);
                    }
                  }}
                  onClick={() => {
                    window.scrollTo(0, 0);
                    const route: IRoute = {
                      page: Page.MyBrief,
                      additionalInfo: {
                        keysValues: {
                          id: this.props.item?.linkedItemBrief?.id.toString(),
                          type: undefined,
                          subtype: undefined,
                          title: undefined,
                          showMyActivities: undefined,
                        },
                      },
                    };
                    this.props.commonProps.onRoute?.next(route);
                  }}
                >
                  <FontIcon
                    iconName="TextDocument"
                    className={"iconGet"}
                  ></FontIcon>
                  <div className={styles.editableField}>
                    {this.props.item?.linkedItemBrief.title}
                  </div>
                  <FontIcon
                    iconName="ChevronRightSmall"
                    className={"iconGet"}
                  ></FontIcon>
                </div>

                {/* <div
                  className={
                    "ms-Grid-col ms-md12 " +
                    styles.value_ActivityAttribute +
                    " " +
                    styles.MacroDocumentation
                  }
                >
                  <div>{this.props.item?.linkedBrief.fileName}</div>
                </div> */}
              </div>
            )}

          <div className={"ms-Grid-col ms-sm12"}>
            <hr />
          </div>
          <div className={"ms-Grid-col ms-sm12"}>
            <div
              className={
                styles.label_ActivityAttribute + " ms-Grid-col ms-sm12"
              }
            >
              {this._translation.description}
            </div>
            {!this.state.showDescription && (
              <div className="ms-Grid-col ms-md12 ">
                <SpinnerComponent
                  commonProps={this.props.commonProps}
                  relative={false}
                ></SpinnerComponent>
              </div>
            )}
            {this.props.edit && this.state.showDescription && (
              <div
                className={
                  styles.value_ActivityAttribute +
                  " ms-Grid-col ms-md12 " +
                  styles.descriptionText
                }
              >
                <div className={styles.descriptionEditor}>
                  {!this.state.editDescription && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: !this.state.editDescription
                          ? this.state.descriptionTemp &&
                            this._decodeHTMLEntities(
                              this.state.descriptionTemp?.toString("html")
                            )
                            ? this.state.descriptionTemp?.toString("html") //.replaceAll("\n", "<br />")
                            : "---"
                          : "",
                      }}
                      className={
                        styles.labelDescription + " " + styles.editableField
                      }
                      tabIndex={0}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          if (this.props.item?.permissions.canUpdate)
                            this.setState({ editDescription: true });
                        }
                      }}
                      onClick={() => {
                        if (this.props.item?.permissions.canUpdate)
                          this.setState({ editDescription: true });
                      }}
                    >
                      {/* {!this.state.editDescription && .replace("\n", "<br />")} */}
                    </div>
                  )}
                  {this.state.editDescription && (
                    <RichTextEditor
                      // multiline autoAdjustHeight
                      className={styles.textDescription}
                      onChange={(newValue) => {
                        this.setState({
                          descriptionTemp: newValue
                            ? newValue
                            : RichTextEditor.createEmptyValue(),
                        });
                      }}
                      value={this.state.descriptionTemp}
                      toolbarConfig={Constants.toolbarRichTextEditor}
                    ></RichTextEditor>
                  )}
                  <div className={styles.descriptionEdit}>
                    {!this.state.editDescription &&
                      this.props.item?.permissions.canUpdate && (
                        <div>
                          <FontIcon
                            aria-label={"Edit"}
                            iconName={"Edit"}
                            tabIndex={0}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                this.setState({ editDescription: true });
                              }
                            }}
                            onClick={() => {
                              this.setState({ editDescription: true });
                            }}
                          ></FontIcon>
                        </div>
                      )}
                  </div>
                </div>
                <div className={styles.descriptionActions}>
                  {this.state.editDescription &&
                    this.props.item?.permissions.canUpdate && (
                      <FontIcon
                        aria-label={this._translation.save}
                        iconName={"Accept"}
                        tabIndex={0}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            if (this.props.updateDescription) {
                              this.setState({ showDescription: false });
                              this.props
                                .updateDescription(
                                  this.state.descriptionTemp.toString("html")
                                )
                                .then((result) => {
                                  if (result) {
                                    this.setState({
                                      editDescription: false,
                                      showDescription: true,
                                    });
                                  }
                                });
                            }
                          }
                        }}
                        onClick={() => {
                          if (this.props.updateDescription) {
                            this.setState({ showDescription: false });
                            this.props
                              .updateDescription(
                                this.state.descriptionTemp.toString("html")
                              )
                              .then((result) => {
                                if (result) {
                                  this.setState({
                                    editDescription: false,
                                    showDescription: true,
                                  });
                                }
                              });
                          }
                        }}
                      ></FontIcon>
                    )}
                  {this.state.editDescription &&
                    this.props.item?.permissions.canUpdate && (
                      <FontIcon
                        aria-label={this._translation.cancel}
                        iconName={"Cancel"}
                        tabIndex={0}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.setState({ editDescription: false });
                          }
                        }}
                        onClick={() => {
                          this.setState({ editDescription: false });
                        }}
                      ></FontIcon>
                    )}
                </div>
              </div>
            )}
            {!this.props.edit && this.state.showDescription && (
              <div
                className={
                  styles.value_ActivityAttribute + " ms-Grid-col ms-sm12"
                }
                dangerouslySetInnerHTML={{
                  __html:
                    this.props.item?.description &&
                    this._decodeHTMLEntities(this.props.item?.description)
                      ? this.props.item?.description //.replaceAll("\n", "<br />")
                      : "---",
                }}
              >
                {/* {this.props.item?.description} */}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
