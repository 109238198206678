import * as React from "react";

import "bootstrap/dist/css/bootstrap.css";

import "@fluentui/react/dist/css/fabric.min.css";

import { initializeIcons } from "@uifabric/icons";

import { Utility } from "../../../Utility/Utility";
import { ActivityTranslation } from "../../../Translations/Activity.Translation";
import { Panel, PanelType } from "office-ui-fabric-react/lib/Panel";

// import "./MacroActivityViewComponent.scss";
import styles from "./NewActivityComponent.module.scss";

import {
  ContextualMenu,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  FontIcon,
  IColumn,
  IconButton,
  IDialogContentProps,
  IDragOptions,
  IModalProps,
  Modal,
  PrimaryButton,
  TextField,
} from "@fluentui/react";

import { PanelActivityHeaderComponent } from "../PanelActivityHeaderComponent/PanelActivityHeaderComponent";
import { IMacro, IMicro } from "../../../Models/Activity";
import { IBaseProperties, IRoute, Page } from "../../../Models/IBaseProperties";
import { IDateRangeValue } from "../RangeCalendar/IDateRangeValue";
import { RangeCalendar } from "../RangeCalendar/RangeCalendar";
import { MacroTranslation } from "../../Body/Activities/Macro.Translation";
import { PlannerTranslation } from "../../Body/Planner/Planner.Translation";
import { DashboardTranslation } from "../../../Translations/Dashboard.Translation";
import { IActivityClient } from "../../../Clients/IActivityClient";
import { SpinnerComponent } from "../SpinnerComponent/SpinnerComponent";
import RichTextEditor, { EditorValue } from "react-rte";
import { Constants } from "../../../Utility/Constants";
import {
  ICalendarPermission,
  IPlannerPermission,
} from "../../../Models/Planner";
import { Macro } from "../../Body/Activities/Macro";
import { Micro } from "../../Body/Activities/Micro";
import { HeaderAction } from "../../Header/HeaderAction/HeaderAction";

const dragOptions: IDragOptions = {
  moveMenuItemText: "Move",
  closeMenuItemText: "Close",
  menu: ContextualMenu,
  dragHandleSelector: ".panelHeader",
};

export interface INewActivityComponentProps extends IBaseProperties {
  position: string;
}

export interface INewActivityComponentState {
  showNewActivityButton: boolean;
  permissions?: IPlannerPermission;
  calendarsPermisisons?: ICalendarPermission[];
  showAddbtn: boolean;
  newActivityType: string;
  newActivityVisible: boolean;
}

export class NewActivityComponent extends React.Component<
  INewActivityComponentProps,
  INewActivityComponentState
> {
  _taskColumns: IColumn[] = [];
  private readonly _plannerTranslation: PlannerTranslation;
  private readonly _dashboardTranslation: DashboardTranslation;
  private readonly _activityClient: IActivityClient;

  constructor(props: INewActivityComponentProps) {
    super(props);

    initializeIcons();

    this._activityClient = this.props.commonProps.clientCreator.createActivityClient();
    this._plannerTranslation = new PlannerTranslation(
      this.props.commonProps.translation
    );
    this._dashboardTranslation = new DashboardTranslation(
      this.props.commonProps.translation
    );

    this.state = {
      showNewActivityButton: false,
      showAddbtn: false,
      newActivityType: "",
      newActivityVisible: false,
    };

    this._showBtns = this._showBtns.bind(this);
    this._openModalNewActivity = this._openModalNewActivity.bind(this);
    this._hideModalNewActivity = this._hideModalNewActivity.bind(this);
    this._macroSaved = this._macroSaved.bind(this);
    this._microSaved = this._microSaved.bind(this);
    this._activitySaved = this._activitySaved.bind(this);
  }

  componentDidMount(): void {
    this._activityClient
      .getCalendarActivities({
        activityType: "All",
        activityStatus: [],
        activityCalendar: [],
        activitySensibility: [],
        activityCategory: [],
        activityResponsibility: [],
        fromDate: new Date(),
        toDate: new Date(),
      })
      .then((response) => {
        if (response) {
          this.setState({
            permissions: response.permissions,
            calendarsPermisisons: response.calendarsPermissions,
            showNewActivityButton: this.canAddActivities(
              response.permissions,
              response.calendarsPermissions
            ),
          });
        }
      });
  }
  private canAddActivities(
    permissions: IPlannerPermission,
    calendarsPermissions: ICalendarPermission[]
  ): boolean {
    var result = permissions.canAdd;
    if (!!calendarsPermissions && calendarsPermissions.length > 0) {
      result = result && calendarsPermissions.some((c) => c.canCreate);
    }
    return result;
  }

  private _showBtns() {
    const { showAddbtn } = this.state;
    this.setState({
      showAddbtn: !showAddbtn,
    });
  }

  private _openModalNewActivity(msg: string) {
    this.setState({
      newActivityType: msg,
      newActivityVisible: true,
      showAddbtn: false,
    });
  }

  private _hideModalNewActivity() {
    this.setState({
      newActivityType: "",
      newActivityVisible: false,
      showAddbtn: false,
    });
  }

  private async _macroSaved(newMacro: IMacro) {
    await this._activitySaved("Macro", newMacro);
  }

  private async _microSaved(newMacro: IMicro) {
    await this._activitySaved("Micro", newMacro);    
  }

  private _activitySaved(activityType: string, newActivity: IMacro | IMicro) {
    window.scrollTo(0, 0);
    const route: IRoute = {
      page: Page.Activities,
      additionalInfo: {
        keysValues: {
          id: newActivity.id?.toString(),
          type: activityType == "Macro" ? "1" : "2",
          subtype: undefined,
          title: undefined,
          showMyActivities: undefined,          
        },
      },
    };
    this._hideModalNewActivity();
    this.props.commonProps.onRoute?.next(route);
  }

  public render() {
    const showAddbtn = this.state.showAddbtn ? "" : "display-none";
    const showMacro =
      this.state.newActivityType.toLowerCase() === "macro" ? true : false;
    const activityTitle = this._plannerTranslation.activityTitle(
      showMacro ? "Macro" : "Micro"
    );
    const macro = (
      <Macro
        commonProps={this.props.commonProps}
        className={Constants.activityModalPanelStyle.body}
        hideModalCallback={this._hideModalNewActivity}
        onSave={this._macroSaved}
      >        
      </Macro>
    );
    const micro = (
      <Micro
        commonProps={this.props.commonProps}
        className={Constants.activityModalPanelStyle.body}
        hideModalCallback={this._hideModalNewActivity}
        onSave={this._microSaved}
      ></Micro>
    );

    const renderActivity = showMacro ? macro : micro;

    return (
      <div role="activityAction">
        {this.state.showNewActivityButton && (
          <div
            className={
              this.props.position == "DASHBOARD"
                ? "brief-button"
                : this.props.position == "HEADER"
                ? styles.headerButton
                : ""
              }
              autoFocus={true}            
          >
            {/* <PrimaryButton
              text={this._dashboardTranslation.newActivityButton}
              styles={Constants.iBriefButton}
              onClick={this._showBtns}
            ></PrimaryButton> */}
            <HeaderAction
              commonProps={this.props.commonProps}
              visible={true}
              onClickNewBrief={this._showBtns}
            ></HeaderAction>
            <DefaultButton
              className={[
                "btn-dashboard-add-activity2",
                styles.macro,
                showAddbtn,
              ].join(" ")}
              text={this._plannerTranslation.addMacro}
              aria-label={this._plannerTranslation.addMacro}
              ariaLabel={this._plannerTranslation.addMacro}
              allowDisabledFocus
              onClick={() => this._openModalNewActivity("macro")}
              disabled={false}
              checked={true}
            />
            <DefaultButton
              className={[
                "btn-dashboard-add-activity2",
                styles.micro,
                showAddbtn,
              ].join(" ")}
              text={this._plannerTranslation.addMicro}
              aria-label={this._plannerTranslation.addMicro}
              ariaLabel={this._plannerTranslation.addMicro}
              allowDisabledFocus
              onClick={() => this._openModalNewActivity("micro")}
              disabled={false}
              checked={true}
            />
          </div>
        )}

        <Modal
          isOpen={this.state.newActivityVisible}
          closeButtonAriaLabel="Close"
          onDismiss={this._hideModalNewActivity}
          containerClassName={Constants.activityModalPanelStyle.container}
          dragOptions={dragOptions}
        >
          <div className="panelHeader">
            <span>{activityTitle}</span>
            <div>
              <IconButton
                style={{ float: "right" }}
                iconProps={Constants.iCancel}
                aria-label="Close"
                ariaLabel="Close popup modal"
                onClick={() => this._hideModalNewActivity()}
              />
            </div>
          </div>

          <div>{renderActivity}</div>
        </Modal>
      </div>
    );
  }
}
