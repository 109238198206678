import * as React from "react";
import styles from "./FileList.module.scss";
import {MultipleFileUploadTranslation} from "../MultipleFileUpload/MultipleFileUpload.Translation";
import {  
  PrimaryButton, 
  IColumn,
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode,
  ISelection,
  IObjectWithKey,  
  Selection,
  BaseButton,
  Button,
  FontIcon
} from "@fluentui/react";
import {
  IBaseProperties,
  ICommonProperties,
} from "../../../Models/IBaseProperties";
import { Utility } from "../../../Utility/Utility";


export interface IReadOnlyFileDocument {  
  id: number;
  name: string;  
  fileSizeRaw: number;
}

export interface IFileListProps extends IBaseProperties {  
  items: IReadOnlyFileDocument[];
  onRemoveItems: (items: IReadOnlyFileDocument[])=>void;
}
export interface IFileListState {  
  selectedItems: IReadOnlyFileDocument[];
}

export class FileList extends React.Component<IFileListProps, IFileListState> {    
  
  private readonly _translation: MultipleFileUploadTranslation;
  
  private readonly _selection: ISelection<IObjectWithKey> = new Selection({
    onSelectionChanged: () => {
      this.setState(
        {
          selectedItems: this.getSelectionItems()
        }
      );
    },
  });        
 
  constructor(props: IFileListProps) {
    
    super(props);            

    this._translation = new MultipleFileUploadTranslation(props.commonProps.translation);

    this.initializeState(props);

    this.bindEvents();
  }

  private initializeState(props: IFileListProps) {
    this.state = {
      selectedItems: [],      
    };
  }

  private bindEvents() {    
    this.onClickRemoveButton = this.onClickRemoveButton.bind(this);
  }

  private get _columns(): IColumn[] { 
    return [  
    {
      key: 'fileName',
      name: this._translation.fileNameColumn,
      // classNames: classNames.fileIconCell,
      // iconClassName: classNames.fileIconHeaderIcon,            
      fieldName: 'name',
      minWidth: 480,
      // maxWidth: 300,
      data: 'string',
      // onRender: (item: IReadOnlyFileDocument) => {
      //   return <span>{item.name}</span>;
      // },
    },    
    {
      key: 'column5',
      name: this._translation.fileSizeColumn,
      fieldName: 'fileSizeRaw',
      minWidth: 70,      
      isResizable: true,
      isCollapsible: true,
      data: 'number',     
      onRender: (item: IReadOnlyFileDocument) => {
        return <span>
          {Utility.GetFileSize(item.fileSizeRaw)}</span>;
      },
    },    
     {
       key: 'removeLink',
       name: '',
       // classNames: classNames.fileIconCell,
      // iconClassName: classNames.fileIconHeaderIcon,            
      fieldName: 'url',
      minWidth: 32,
      maxWidth: 32,      
      data: 'string',
      onRender: (item: IReadOnlyFileDocument) => {
        return (
          <div className={styles.divRemove}>
            <FontIcon
              iconName="Delete"            
              onClick=
              {
                ()=> { this.props.onRemoveItems([item]);}
              }
            ></FontIcon>        
          </div>
        );
     },
    }
  ]}

  private onClickRemoveButton (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | BaseButton | Button | HTMLSpanElement, MouseEvent>)
  {
    const {selectedItems} = this.state;

    if (selectedItems && selectedItems.length > 0)
    {            
      this._selection.setAllSelected(false);

      this.props.onRemoveItems(selectedItems);
    }
  }

  private getSelectionItems(): IReadOnlyFileDocument[] {
    
    const selection = this._selection.getSelection();
    
    return selection.map(t=> (t as IReadOnlyFileDocument));    
  }

  private getKey(item: any, index?: number | undefined): string
  {
    const value = (item.name as string);
    return value;
  }
  
  render() {

    const showRemoveButton = this.state.selectedItems.length > 0;
    return (
      <React.Fragment>
        <div>
          {showRemoveButton &&
            <div>
              <PrimaryButton              
                text="Remove selected files"
                onClick={this.onClickRemoveButton}
                disabled={this.state.selectedItems.length===0}
              ></PrimaryButton>
            </div>
        }
          <div>
            <DetailsList
                items={this.props.items}
                compact={true}
                columns={this._columns}
                selectionMode={SelectionMode.none}
                getKey={this.getKey}
                setKey="multiple"
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}                
                selection={this._selection}
                selectionPreservedOnEmptyClick={true}                
                enterModalSelectionOnTouch={true}
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                checkButtonAriaLabel="Row checkbox"
              />
            </div>
          </div>
      </React.Fragment>
    );
  }
}
