import { UserDTO } from "../Models/DTO/UserDTO";
import { UserInfo } from "../Models/User";
import { APIUtility } from "../Utility/APIUtility";
import { IUserClient } from "./IUserClient";
import usermock from "../Mocks/DTO/UserDTOMock.json";
import { NoContent } from "../Models/NoContent";
import { HttpError } from "../Models/HttpResponse";
import { ErrorClient } from "./ErrorClient";

export class UserClient implements IUserClient {
  private readonly _http: APIUtility = new APIUtility();

  public async getCurrentUserInfoAsync(): Promise<UserInfo | undefined> {
    let userInfo: UserInfo | undefined = undefined;

    try {
      const userDTO = await this._http.getUserInfoAsync("me");

      userInfo = this.toUserInfo(userDTO);
    } catch (err) {
      if (err.httpStatus && err.httpStatus == 401) {
        this.storeError(err);
        // await this._http.getAccessToken(true);
        // this.getCurrentUserInfoAsync();
      } else {
        this.storeError(err);
      }
    } finally {
      return userInfo;
    }
  }

  public isCurrentUserStored(): boolean | undefined {
    return window.sessionStorage.getItem("userInfo") ? true : false;
  }

  private storeError(err: HttpError): void {
    console.error(err);
    ErrorClient.storeHttpError(err);
  }

  //Converto DTO to Model
  private toUserInfo(userDTO: UserDTO): UserInfo {
    const user: UserInfo = {
      id: userDTO.id,
      username: userDTO.userPrincipalName,
      displayName: userDTO.displayName,
      group: userDTO.group,
      initials: userDTO.initials,
      businessUnit: userDTO.businessUnit ? userDTO.businessUnit : "",
    };

    return user;
  }

  public async getSpocByFilterAsync(
    filter: {term: string, calendar: number}
  ): Promise<UserInfo[] | undefined> {
    return await this.getUsersByFilterAsync("spoc", filter);
  }

  public async getReferentByFilterAsync(
    filter: {term: string, calendar: number}
  ): Promise<UserInfo[] | undefined> {
    return await this.getUsersByFilterAsync("referent", filter);
  }

  public async getBriefReferentByFilterAsync(
    filter: {term: string, calendar: number}
  ): Promise<UserInfo[] | undefined> {
    return await this.getUsersByFilterAsync("briefReferent", filter);
  }

  public async getStakeholderByFilterAsync(
    filter: {term: string, calendar: number}
  ): Promise<UserInfo[] | undefined> {
    return await this.getUsersByFilterAsync("stakeholder", filter);
  }

  private timeout(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  public async getExternalResourceFilterAsync(
    filter: {term: string, calendar: number}
  ): Promise<UserInfo[] | undefined> {
    return await this.getUsersByFilterAsync("externalResource", filter);
  }

  public async getHeadOfActivityFilterAsync(
    filter: {term: string, calendar: number}
  ): Promise<UserInfo[] | undefined> {
    return await this.getUsersByFilterAsync("headOfActivity", filter);
  }

  public async getInternalResourceFilterAsync(
    filter: {term: string, calendar: number}
  ): Promise<UserInfo[] | undefined> {
    return await this.getUsersByFilterAsync("internalResource", filter);
  }

  public async getSpecialistFilterAsync(
    filter: {term: string, calendar: number}
  ): Promise<UserInfo[] | undefined> {
    return await this.getUsersByFilterAsync("gmSpecialist", filter);
  }

  public async getUsersByFilterAsync(
    mode: string,
    filter: {term: string, calendar: number}
  ): Promise<UserInfo[] | undefined> {
    let users: UserInfo[] | undefined = undefined;

    try {
      const usersDTO = await this.getUserAsync(mode, filter);

      users = usersDTO.map((userDTO) => this.toUserInfo(userDTO));
    } catch (err) {
      this.storeError(err);
    } finally {
      return users;
    }
  }

  private async getUserAsync(mode: string, filter: {term: string, calendar: number}): Promise<UserDTO[]> {
    const result = await this._http.getUsersByFilterAsync(mode, filter);
    if (result instanceof NoContent) {
      return [];
    } else {
      return result;
    }
  }

  public async getSharepointLoginPageUrl(): Promise<string | undefined> {
    var result: string | undefined = "";
    try {
      result = await this._http.getSharepointLoginPageUrlAsync();
      // const result =
      //   "https://m365x178943.sharepoint.com/sites/MediaPlanningNew/SitePages/Login.aspx";
    } catch (err) {
      this.storeError(err);
    } finally {
      return result;
    }
  }

  public async getCurrentUsername(): Promise<string | undefined> {
    return await this._http.getCurrentUsername();
  }

  public async logoutCurrentUser(): Promise<boolean | undefined> {
    var result: boolean | undefined = false;
    try {
      await this._http.logout();
      result = true;
      // const result =
      //   "https://m365x178943.sharepoint.com/sites/MediaPlanningNew/SitePages/Login.aspx";
    } catch (err) {
      this.storeError(err);
    } finally {
      return result;
    }
  }
}

function getUserDTOMock(): UserDTO {
  const userInfo: UserDTO = usermock[0];
  return userInfo;
}
