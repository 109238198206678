import * as React from "react";
import "./Dashboard.scss";
import { IBaseProperties, IRoute, Page } from "../../../Models/IBaseProperties";
import { NewBrief } from "../MyBrief/NewBrief/NewBrief";
import { BriefDetail } from "../MyBrief/BriefDetail/BriefDetail";
import {
  ActivityTypeFilter,
  IActivitiesCount,
  IMacro,
  IMicro,
} from "../../../Models/Activity";
import { WelcomeMessageComponent } from "./WelcomeMessageComponent/WelcomeMessageComponent";
import { CounterComponent } from "./CounterComponent/CounterComponent";
import { YourActivitiesComponent } from "./YourActivitiesComponent/YourActivitiesComponent";
import { YourBriefsComponent } from "./YourBriefsComponent/YourBriefsComponent";
import { IActivityClient } from "../../../Clients/IActivityClient";
import { ActivityTranslation } from "../../../Translations/Activity.Translation";
import { ToastNotificationType } from "../../../Models/ToastNote";
import { Security } from "../../../Utility/Security";
import { IBriefDetail, IBriefsCount } from "../../../Models/Brief";
import {
  BaseButton,
  Button,
  ColorClassNames,
  CommandBarButton,
  ContextualMenu,
  DefaultButton,
  IconButton,
  IDragOptions,
  Modal,
  PrimaryButton,
} from "@fluentui/react";
import { Constants } from "../../../Utility/Constants";
import { BriefTranslation } from "../../../Translations/Brief.Translation";
import { IBriefClient } from "../../../Clients/IBriefClient";
import { DashboardTranslation } from "../../../Translations/Dashboard.Translation";
import { Observer, Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { NewActivityComponent } from "../../Shared/NewActivityComponent/NewActivityComponent";

const dragOptions: IDragOptions = {
  moveMenuItemText: "Move",
  closeMenuItemText: "Close",
  menu: ContextualMenu,
  dragHandleSelector: ".panelHeader",
};

export interface IDashboardProps extends IBaseProperties {
  showNewBrief: boolean;
  onDismissBrief(): void;
  onNavigate(page: string): void;
}
export interface IDashboardState {
  activityCounters?: IActivitiesCount[];
  briefCounters?: IBriefsCount[];
  showActivities: boolean;
  showActivityCounter: boolean;
  showNewBrief: boolean;
  showBriefDetail: boolean;
  showNewBriefButton: boolean;
  showNewBriefTitle: boolean;
  brief?: IBriefDetail;
  idBrief?: number;
  showSend: boolean;
  currentTab: string;
}

export class Dashboard extends React.Component<
  IDashboardProps,
  IDashboardState
> {
  private readonly _activityClient: IActivityClient;
  private readonly _briefClient: IBriefClient;
  private readonly _activityTranslation: ActivityTranslation;
  private readonly _translation: DashboardTranslation;
  private readonly _briefTranslation: BriefTranslation;
  private _routingObserver?: Subscription;

  constructor(props: IDashboardProps) {
    super(props);

    this._translation = new DashboardTranslation(
      this.props.commonProps.translation
    );
    this._activityTranslation = new ActivityTranslation(
      this.props.commonProps.translation
    );
    this._briefTranslation = new BriefTranslation(
      this.props.commonProps.translation
    );

    this._activityClient = this.props.commonProps.clientCreator.createActivityClient();
    this._briefClient = this.props.commonProps.clientCreator.createBriefClient();
    this.state = {
      showActivities: !props.showNewBrief,
      showActivityCounter: !props.showNewBrief,
      showNewBrief: props.showNewBrief,
      showBriefDetail: !props.showNewBrief,
      showNewBriefButton: !props.showNewBrief,
      showNewBriefTitle: props.showNewBrief,
      showSend: false,
      currentTab: "Activity", //Security.isGlobalMediaSpecialist ? "Activity" : "Brief",
    };

    this.onCancel = this.onCancel.bind(this);
    this.onSaveBrief = this.onSaveBrief.bind(this);
    this.onClickNewBrief = this.onClickNewBrief.bind(this);
    this.onEditRequestBrief = this.onEditRequestBrief.bind(this);
    this.onNavigateAfterSend = this.onNavigateAfterSend.bind(this);
    this._hasBriefCounter = this._hasBriefCounter.bind(this);
  }

  private buildRouteObserver() {
    const observer: Observer<IRoute> = {
      next: (route: IRoute) => {
        this.setState({
          showBriefDetail: false,
          showNewBrief: false,
          showNewBriefTitle: false,
          showNewBriefButton: true,
          showActivities: true,
        });
      },
      error: (err) => console.error("Observer got an error: " + err),
      complete: () => console.log("Observer got a complete notification"),
    };

    const observableRouting = this.props.commonProps.onRoute?.pipe(
      filter((route) => route.page === Page.Dashboard)
    );

    this._routingObserver = observableRouting?.subscribe(observer);
  }

  private renderNewBriefMessage() {
    return (
      <div className="brief-new-message">
        <h1>{this._briefTranslation.newBriefTitle}</h1>
        <div>{this._briefTranslation.newBriefSubTitle}</div>
      </div>
    );
  }

  private onNavigateAfterSend(page: string): void {
    this.setState({
      showNewBrief: false,
      showBriefDetail: false,
      showNewBriefButton: true,
      showActivities: true,
      showActivityCounter: true,
      showNewBriefTitle: false,
      showSend: false,
      brief: undefined,
      idBrief: undefined,
    });

    if (page.toLowerCase() === "mybrief") {
      this.props.onNavigate(page);
    }
  }

  private onClickNewBrief(
    event: React.MouseEvent<
      | HTMLAnchorElement
      | HTMLButtonElement
      | HTMLDivElement
      | BaseButton
      | Button
      | HTMLSpanElement,
      MouseEvent
    >
  ): void {
    this.setState({
      showNewBrief: true,
      showBriefDetail: false,
      showNewBriefButton: false,
      showActivities: false,
      showActivityCounter: false,
      showNewBriefTitle: true,
      showSend: false,
      brief: undefined,
      idBrief: undefined,
    });
  }

  private onEditRequestBrief(idBrief: number): void {
    this.setState({
      showBriefDetail: false,
      showNewBrief: true,
      brief: undefined,
      idBrief: idBrief,
      showActivityCounter: true,
      showNewBriefTitle: false,
      showSend: false,
    });
  }

  private onSaveBrief(brief: IBriefDetail, send: boolean): void {
    this.setState({
      showBriefDetail: true,
      showNewBrief: false,
      brief: brief,
      showNewBriefButton: true,
      showActivityCounter: true,
      showNewBriefTitle: false,
      showSend: send,
    });

    this.props.onDismissBrief();
  }

  private onCancel(): void {
    this.setState({
      showBriefDetail: false,
      showNewBrief: false,
      brief: undefined,
      showNewBriefButton: true,
      showActivityCounter: true,
      showNewBriefTitle: false,
      showActivities: true,
    });

    this.props.onDismissBrief();
  }

  componentDidUpdate(prevProps: IDashboardProps) {
    if (prevProps.commonProps.onRoute !== this.props.commonProps.onRoute) {
      this.buildRouteObserver();
    }
  }

  componentDidMount() {
    this.buildRouteObserver();

    this._activityClient.getActivitiesCount().then((counters) => {
      if (counters) {
        this.setState({ activityCounters: counters });
      } else {
        this.props.commonProps.toastComponent?.showMessage(
          this._activityTranslation.error,
          this._activityTranslation.getActivitiesError,
          ToastNotificationType.ERROR
        );
      }
    });

    // this._briefClient.getBriefsCount().then((counters) => {
    //   if (counters) {
    //     this.setState({ briefCounters: counters });
    //   } else {
    //     this.props.commonProps.toastComponent?.showMessage(
    //       this._briefTranslation.error,
    //       this._briefTranslation.getBriefsError,
    //       ToastNotificationType.ERROR
    //     );
    //   }
    // });
  }

  private _hasBriefCounter(): boolean {
    var retValue: boolean = false;
    this.state.briefCounters?.forEach((element) => {
      if (element.count > 0) retValue = true;
    });
    return retValue;
  }

  render() {
    const userInfo = Security.getCurrentUserInfo();
    const applicationUnit = userInfo?.businessUnit
      ? userInfo.businessUnit
      : "---";

    const isGMSpecialist = Security.isGlobalMediaSpecialist;
    const tabBrief = (
      <li className="tab_pnl_home">
        <a
          className={
            "nav-link " +
            " " +
            (this.state.currentTab == "Brief" ? "active" : "notActive")
          }
          data-toggle="tab"
          role="tab"
          aria-controls="tab1"
          aria-selected="true"
          onClick={() => {
            this.setState({ currentTab: "Brief" });
          }}
        >
          <h3 className={"panel_title"}>{this._translation.headerBriefs}</h3>
        </a>
      </li>
    );
    const tabActivity = (
      <li className="tab_pnl_home" key="headerActivity">
        <a
          className={
            "nav-link " +
            " " +
            (this.state.currentTab == "Activity" ? "active" : "notActive")
          }
          data-toggle="tab"
          role="tab"
          aria-controls="tab1"
          aria-selected="true"
          onClick={() => {
            this.setState({ currentTab: "Activity" });
          }}
        >
          <h3 className={"panel_title"}>
            {this._translation.headerActivities}
          </h3>
        </a>
      </li>
    );

    return (
      <React.Fragment>
        <div className="dashboard">
          <div className="ms-Grid">
            <div className="ms-Grid-row banner">
              <div className="ms-Grid-col ms-xl7 ms-lg12 ms-md12 ms-sm12">
                <div>
                  {!this.state.showNewBriefTitle && (
                    <WelcomeMessageComponent
                      commonProps={this.props.commonProps}
                      userLogged={userInfo ? userInfo.displayName : "-"}
                    ></WelcomeMessageComponent>
                  )}
                  {this.state.showNewBriefTitle && this.renderNewBriefMessage()}
                  {/* {this.state.showNewBriefButton && (
                    <div className="brief-button">
                      <PrimaryButton
                        text="New brief +"
                        styles={Constants.iBriefButton}
                        onClick={this.onClickNewBrief}
                      ></PrimaryButton>
                    </div>
                  )} */}
                  {/* {this.state.showNewActivityButton && (
                    <div className="brief-button">
                      <CommandBarButton
                        className="btn btn-dashboard-add-activity "
                        iconProps={{ iconName: "Add" }}
                        styles={Constants.iBriefButton}
                        text={this._translation.newActivityButton}
                        // Set split=true to render a SplitButton instead of a regular button with a menu
                        // split={true}
                        menuProps={{
                          className: "btn-dashboard-add-activity-contextMenu",
                          items: [
                            {
                              key: "addMacro",
                              text: this._plannerTranslation.addMacro,
                              className: "btn-dashboard-add-activity-children",
                              onClick: () =>
                                this._openModalNewActivity("macro"),
                              // iconProps: { iconName: 'Mail' },
                            },
                            {
                              key: "addMicro",
                              text: this._plannerTranslation.addMicro,
                              className: "btn-dashboard-add-activity-children",
                              onClick: () =>
                                this._openModalNewActivity("micro"),
                              // iconProps: { iconName: 'Calendar' },
                            },
                          ],
                        }}

                        // disabled={disabled}
                        // checked={checked}
                      />
                    </div>
                  )} */}
                  <NewActivityComponent
                    commonProps={this.props.commonProps}
                    position={"DASHBOARD"}                    
                  ></NewActivityComponent>
                </div>
              </div>

              {this.state.showActivityCounter && this._hasBriefCounter() && (
                <div className="ms-Grid-col ms-xl5 ms-lg12 ms-md12 ms-sm12">
                  <CounterComponent
                    commonProps={this.props.commonProps}
                    // activityCounters={this.state.activityCounters}
                    briefCounters={
                      this.state.briefCounters ? this.state.briefCounters : []
                    }
                  ></CounterComponent>
                </div>
              )}
            </div>

            <div className="ms-Grid-row rowDashboard">
              {(this.state.showNewBrief || this.state.showBriefDetail) && (
                <div className="brief-container">
                  {/* <div className="ms-Grid-col ms-xl5 ms-lg12 ms-md12 ms-sm12">  */}
                  {this.state.showNewBrief && (
                    <NewBrief
                      commonProps={this.props.commonProps}
                      // applicantUnit={applicationUnit}
                      onSave={this.onSaveBrief}
                      idBrief={this.state.idBrief}
                      enableScroll={false}
                      onDismiss={this.props.onDismissBrief}
                      onCancel={this.onCancel}
                    ></NewBrief>
                  )}
                  {this.state.showBriefDetail && (
                    <BriefDetail
                      commonProps={this.props.commonProps}
                      brief={this.state.brief}
                      onEditRequest={this.onEditRequestBrief}
                      onNavigateAfterSend={this.onNavigateAfterSend}
                      enableScroll={false}
                      showTitleRow={true}
                      onChageStatus={() => {}}
                      showSend={this.state.showSend}
                      showHistory={false}
                      onCancel={this.onCancel}
                      hideNavigationAfterSend={false}
                    ></BriefDetail>
                  )}
                </div>
              )}

              {this.state.showActivities &&
                // this.state.briefCounters &&
                this.state.activityCounters && (
                  <div className="dashboard_backgroundDiv">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 div_tabs">
                      <div className="ms-Grid-col ms-xl8 ms-lg8 ms-md6 ms-sm6">
                        <ul
                          className={
                            "nav nav-tabs hidden-mobile " + "dashboard_nav_tabs"
                          }
                          role="tablist"
                        >
                          {// isGMSpecialist
                          //   ? [tabActivity, tabBrief]
                          //   :
                          [tabActivity] //, tabBrief]
                          }
                        </ul>
                      </div>

                      <div className="ms-Grid-col ms-xl4 ms-lg4 ms-md6 ms-sm12">
                        <div
                          // href={this.state.allActivitiesUrl}
                          aria-label={
                            this.state.currentTab == "Activity"
                              ? this._activityTranslation
                                  .viewAllMyActivities
                                  // this.state.itemsCount
                                  ()
                              : this._translation.viewAll(
                                  false
                                  // this.state.itemsCount
                                )
                          }
                          onClick={() => {
                            const route: IRoute = {
                              page:
                                this.state.currentTab == "Activity"
                                  ? Page.Activities
                                  : Page.MyBrief,
                              additionalInfo: {
                                keysValues: {
                                  id: undefined,
                                  type: undefined,
                                  subtype: undefined,
                                  title: undefined,
                                  showMyActivities: "1",
                                },
                              },
                            };
                            this.props.commonProps.onRoute?.next(route);
                            // window.location.href = "/?page=MyBrief";
                          }}
                          id="viewAllActivities"
                          className={"dashboard_view_all"}
                        >
                          {this.state.currentTab == "Activity"
                            ? this._activityTranslation
                                .viewAllMyActivities
                                // this.state.itemsCount
                                ()
                            : this._translation.viewAll(
                                false
                                // this.state.itemsCount
                              )}
                        </div>
                      </div>
                    </div>
                    {/* {this.state.currentTab == "Brief" && (
                      <YourBriefsComponent
                        commonProps={this.props.commonProps}
                        briefCounters={this.state.briefCounters}
                      ></YourBriefsComponent>
                    )} */}

                    {this.state.currentTab == "Activity" && (
                      <YourActivitiesComponent
                        commonProps={this.props.commonProps}
                        activityCounters={this.state.activityCounters}
                      ></YourActivitiesComponent>
                    )}
                  </div>
                )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
