import * as React from "react";
import RichTextEditor, { EditorValue } from "react-rte";
import "bootstrap/dist/css/bootstrap.css";
import "@fluentui/react/dist/css/fabric.min.css";
import { initializeIcons } from "@uifabric/icons";
import { ActivityTranslation } from "../../../../../Translations/Activity.Translation";
import { Utility } from "../../../../../Utility/Utility";
import { Panel, PanelType } from "office-ui-fabric-react/lib/Panel";
import { DeliverablesComponent } from "./DeliverablesComponent/DeliverablesComponent";
import { TasksComponent } from "./TasksComponent/TasksComponent";
import { ActivitySidebarComponent } from "../../../../Shared/ActivitySidebarComponent/ActivitySidebarComponent";
import { MacroActivityViewComponent } from "../../../../Shared/MacroActivityViewComponent/MacroActivityViewComponent";
import { MicroActivityViewComponent } from "../../../../Shared/MicroActivityViewComponent/MicroActivityViewComponent";
import { DescriptionActivityViewComponent } from "../../../../Shared/DescriptionActivityViewComponent/DescriptionActivityViewComponent";
import { NoteComponent } from "./NoteComponent/NoteComponent";

// import "./ActivitiesDetails.scss";
import styles from "./ActivityDetails.module.scss";
import {
  ActivityItem,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Dropdown,
  FontIcon,
  IBasePicker,
  IColumn,
  IComboBoxOption,
  IContextualMenuProps,
  IDialogContentProps,
  IDropdownOption,
  IModalProps,
  ITag,
  Label,
  MessageBar,
  Modal,
  PrimaryButton,
  TagPicker,
  Toggle,
} from "@fluentui/react";

import {
  IBasePickerSuggestionsProps,
  IRenderFunction,
  ISelectableOption,
  MessageBarType,
  SelectableOptionMenuItemType,
} from "office-ui-fabric-react";
import { PanelActivityHeaderComponent } from "../../../../Shared/PanelActivityHeaderComponent/PanelActivityHeaderComponent";
// import { TextField } from "@material-ui/core";
import {
  ActivityStatusEnum,
  IActivity,
  IActivityAllAttributes,
  IMacro,
  IMicro,
} from "../../../../../Models/Activity";
import { User } from "../../../../../Models/User";
import {
  ToastNotificationPosition,
  ToastNotificationType,
} from "../../../../../Models/ToastNote";

import { LoaderComponent } from "../../../../Shared/LoaderComponent/LoaderComponent";
import { SpinnerComponent } from "../../../../Shared/SpinnerComponent/SpinnerComponent";

import {
  IBaseProperties,
  ICommonProperties,
} from "../../../../../Models/IBaseProperties";
import { IActivityClient } from "../../../../../Clients/IActivityClient";
import { ITaskClient } from "../../../../../Clients/ITaskClient";
import { IDateRangeValue } from "../../../../Shared/RangeCalendar/IDateRangeValue";
import {
  ActivityHistory,
  ActivityHistoryType,
} from "../../../../../Models/ActivityHistory";
import { MacroTranslation } from "../../Macro.Translation";
import { MicroTranslation } from "../../Micro.Translation";
// import { Label } from "@material-ui/icons";
import { ToastComponent } from "../../../../Shared/ToastComponent/ToastComponent";
import { Constants } from "../../../../../Utility/Constants";
import { ICalendar } from "../../../../../Models/ICalendar";
import { ICategory } from "../../../../../Models/ICategory";
import { number } from "prop-types";

export interface IActivityDetailsProps extends IBaseProperties {
  itemFromList?: IActivityAllAttributes;
  calendars: ICalendar[];
  categories: IDropdownOption[];
  responsibilities: IDropdownOption[];
  sensibilities: IDropdownOption[];
  onDismiss?: (hideActivity?: boolean) => void;
  reloadActivities: () => void;
  deleteActivity: (itemId: number, isMacro: boolean) => void;
}

export interface IActivityDetailsState {
  toastRefState: React.RefObject<ToastComponent>;

  isLoading: boolean;
  isExternalLoading: boolean;
  showPanel: boolean;
  item?: IActivityAllAttributes;

  isMacro?: boolean;
  parentMacro?: IMacro;
  linkedMicro?: IMicro;
  microActivities?: IMicro[];

  historySelected?: IDropdownOption;
  viewDescription: boolean;
  viewParent: boolean;
  viewLinkedMicro: boolean;
  activityContextualMenuProps?: IContextualMenuProps;

  validationOutOfPlatform: boolean;

  historyOptions: IDropdownOption[];

  editDescription: boolean;
  descriptionTemp: EditorValue;

  editCategory: boolean;
  editResponsibility: boolean;
  editSensibility: boolean;

  selectedMacro: ITag[] | undefined;
  macroPickerKey: number;
  editParentMacro: boolean;
  selectedParentMacroId?: number;
  macroCollection: IActivity[];
  filterMacroByDate: boolean;
  macrosLoaded: boolean;
  showMessageBar: boolean;
  messageBarText?: string;
  messageType?: MessageBarType;
  // rangeDateValue?: IDateRangeValue | undefined;

  showTimingDialog: boolean;
  showConfirmDialog: boolean;
  newDateValue?: IDateRangeValue | Date;

  showDeliverables: boolean;
  showTasks: boolean;
  showPanelHeader: boolean;
  showSidebar: boolean;
  showDetails: boolean;
}

export class ActivityDetails extends React.Component<
  IActivityDetailsProps,
  IActivityDetailsState
> {
  _taskColumns: IColumn[] = [];
  private readonly _translation: ActivityTranslation;
  private readonly _activityClient: IActivityClient;
  private readonly _taskClient: ITaskClient;
  private readonly _microTranslation: MicroTranslation;
  private readonly _macroTranslation: MacroTranslation;
  private confirmTimingDialogProps: IModalProps = { isBlocking: true };
  private readonly macroPicker = React.createRef<IBasePicker<ITag>>();
  private readonly minDigitBeforeSearchMacro: number;

  constructor(props: IActivityDetailsProps) {
    super(props);

    initializeIcons();
    this._translation = new ActivityTranslation(
      this.props.commonProps.translation
    );
    this._microTranslation = new MicroTranslation(
      this.props.commonProps.translation
    );
    this._macroTranslation = new MacroTranslation(
      this.props.commonProps.translation
    );
    this._activityClient = this.props.commonProps.clientCreator.createActivityClient();
    this._taskClient = this.props.commonProps.clientCreator.createTaskClient();

    this._viewParent = this._viewParent.bind(this);
    this._viewLinkedMicro = this._viewLinkedMicro.bind(this);
    this._viewDescription = this._viewDescription.bind(this);
    this._updateDescription = this._updateDescription.bind(this);
    this._updateTimingActivity = this._updateTimingActivity.bind(this);
    this._categoryOnChange = this._categoryOnChange.bind(this);
    this._categoryOnChangeMultiple = this._categoryOnChangeMultiple.bind(this);
    this._categoryDismiss = this._categoryDismiss.bind(this);
    this._responsibilityOnChangeMultiple = this._responsibilityOnChangeMultiple.bind(
      this
    );
    this._responsibilityOnChange = this._responsibilityOnChange.bind(this);
    this._responsibilityOnDismiss = this._responsibilityOnDismiss.bind(this);
    this._sensibilityOnChange = this._sensibilityOnChange.bind(this);
    this.selectedMacroChange = this.selectedMacroChange.bind(this);
    this.minDigitBeforeSearchMacro = 2;

    this._onRenderActivityDetailsHeader = this._onRenderActivityDetailsHeader.bind(
      this
    );

    this._onRenderParentDetailsHeader = this._onRenderParentDetailsHeader.bind(
      this
    );
    this._onRenderLinkedMicroDetailsHeader = this._onRenderLinkedMicroDetailsHeader.bind(
      this
    );

    this._renderActivityModalHeader = this._renderActivityModalHeader.bind(
      this
    );

    this._setActivityState = this._setActivityState.bind(this);
    this._onRenderHistoryTitle = this._onRenderHistoryTitle.bind(this);
    this._updateTitle = this._updateTitle.bind(this);
    this._updateParent = this._updateParent.bind(this);
    this._updateStatus = this._updateStatus.bind(this);
    this._updateAssignedResources = this._updateAssignedResources.bind(this);
    this._updateExternalResources = this._updateExternalResources.bind(this);
    this._updateAlarmResources = this._updateAlarmResources.bind(this);
    this._updateDueToActivity = this._updateDueToActivity.bind(this);
    this._updateTimingActivity = this._updateTimingActivity.bind(this);
    this._updateHeadOfActivity = this._updateHeadOfActivity.bind(this);
    this._updateSpoc = this._updateSpoc.bind(this);
    this._updateAlarm = this._updateAlarm.bind(this);
    this._updateMacroTiming = this._updateMacroTiming.bind(this);
    this._compareMicroTaskDate = this._compareMicroTaskDate.bind(this);
    this._viewParentChangeModal = this._viewParentChangeModal.bind(this);
    this._loadMacroItems = this._loadMacroItems.bind(this);
    this._macroOptionItems = this._macroOptionItems.bind(this);
    this._compareMicroMacroDateForChange = this._compareMicroMacroDateForChange.bind(
      this
    );

    var historyOptions: IDropdownOption[] = [];

    var ref: React.RefObject<ToastComponent> = React.createRef();

    this.state = {
      isLoading: true,
      isExternalLoading: true,
      showPanel: false,
      viewDescription: false,
      viewParent: false,
      viewLinkedMicro: false,
      validationOutOfPlatform: true,
      historyOptions: historyOptions,
      descriptionTemp: RichTextEditor.createEmptyValue(),
      editDescription: false,
      showTimingDialog: false,
      showConfirmDialog: false,
      showDeliverables: false,
      showTasks: false,
      showPanelHeader: false,
      showSidebar: false,
      showDetails: false,
      editCategory: false,
      editResponsibility: false,
      editSensibility: false,

      selectedMacro: [],
      macroPickerKey: 1,
      editParentMacro: false,
      macroCollection: [],
      filterMacroByDate: false,
      macrosLoaded: false,
      showMessageBar: false,

      toastRefState: ref,
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });

    if (
      !this.props.itemFromList ||
      this.props.itemFromList?.isMacro == undefined
    ) {
      this.setState({
        // item: undefined,
        isLoading: false,
        isExternalLoading: false,
      });
    } else {
      this._setActivityState();
    }
  }

  private _getActionText(action: ActivityHistory): string {
    var retValue: string = "";
    switch (action.type) {
      case ActivityHistoryType.StatusChange:
        if (
          action.description &&
          action.description.currentState != undefined
        ) {
          retValue = this._translation.statusChangeActionDescription(
            ActivityStatusEnum[action.description.currentState],
            Utility.FormatDate(action.actionDate)
          );
        }
        break;
    }
    return retValue;
  }

  private _mapAction(action: ActivityHistory): IDropdownOption {
    // activityDescription: [
    //   <Link key={action.id} className="">
    //     {action.user.displayName}
    //   </Link>,
    //   <span key={2}>
    //     {" "}
    //     {this._getActionText(action)}{" "}
    //   </span>,
    // ],
    // activityPersonas: [
    //   {
    //     imageInitials: action.user.initials,
    //     text: action.user.displayName,
    //   },
    // ],
    // comments: "",
    // timeStamp: "23m ago",

    return {
      key: action.id,
      text: this._getActionText(action),
      data: {
        activityDescription: [
          action.description && action.description.currentState != undefined ? (
            <div
              className={
                styles.iconAction +
                " rbc-event-dot " +
                Utility.GetActivityClassNameFromStatus(
                  ActivityStatusEnum[action.description.currentState]
                )
              }
              // alt={item.fileType + " file icon"}
            />
          ) : (
            <div className={styles.iconAction}></div>
          ),
          <span className={styles.author}>
            <strong>
              {action.user?.displayName ? action.user?.displayName : "User"}{" "}
            </strong>
          </span>,
          this._getActionText(action),
          <span className={styles.date}></span>,
        ],
        // activityPersonas: [
        //   {
        //     imageInitials: action.user.initials,
        //     text: action.user.displayName,
        //   },
        // ],
        comments: "",
        // timeStamp: (
        //   <a className={styles.viewDetailsAction} href="#">
        //     View details
        //   </a>
        // ), //Utility.FormatDateAndTime(action.actionDate),
      },
    };
  }

  private _setActivityState() {
    if (this.props.itemFromList?.isMacro) {
      this._activityClient
        .getActivityDetails(
          this.props.itemFromList?.id,
          this.props.itemFromList?.isMacro
        )
        .then((response) => {
          if (response != undefined) {
            response.isMacro = true;

            this.setState({
              item: response,
              showPanel: true,
              viewDescription: false,
              viewParent: false,
              viewLinkedMicro: false,
              validationOutOfPlatform: true,
              historyOptions: response.actions
                ? response.actions.map((action) => {
                    return this._mapAction(action);
                  })
                : this.state.historyOptions,
              historySelected:
                response.actions && response.actions.length > 0
                  ? this._mapAction(response.actions[0])
                  : undefined,
              isMacro: true,
              parentMacro: undefined,
              linkedMicro: undefined,
              microActivities: response.microActivities,
              isLoading: false,
              isExternalLoading: false,
              descriptionTemp: response.description
                ? RichTextEditor.createValueFromString(
                    response.description,
                    "html"
                  )
                : RichTextEditor.createEmptyValue(),
              editDescription: false,

              showDeliverables: true,
              showTasks: true,
              showPanelHeader: true,
              showSidebar: true,
              showDetails: true,
              selectedParentMacroId: this.state.item?.parentMacro?.id,
            });
          } else {
            this.props.commonProps.toastComponent?.showMessage(
              this._translation.error,
              this._translation.getActivityError,
              ToastNotificationType.ERROR
            );
            this.setState({
              // item: undefined,
              isLoading: false,
              isExternalLoading: false,
            });
          }
        });
    } else {
      this._activityClient
        .getActivityDetails(
          this.props.itemFromList?.id,
          this.props.itemFromList?.isMacro
        )
        .then((response) => {
          if (response != undefined) {
            response.isMacro = false;

            if (response?.parentMacro) {
              var microActivities = (response.parentMacro
                .microActivities as IMicro[]).filter(
                (x) => x.id != response.id
              );
              this.setState({
                item: response,
                showPanel: true,
                viewDescription: false,
                viewParent: false,
                viewLinkedMicro: false,
                validationOutOfPlatform: true,
                historyOptions: response.actions
                  ? response.actions
                      .filter((a) => a.actionDate)
                      .map((action) => {
                        return this._mapAction(action);
                      })
                  : this.state.historyOptions,
                historySelected:
                  response.actions && response.actions.length > 0
                    ? this._mapAction(response.actions[0])
                    : undefined,
                isMacro: false,
                parentMacro: response.parentMacro,
                linkedMicro: undefined,
                microActivities: microActivities,
                isLoading: false,
                isExternalLoading: false,
                descriptionTemp: response.description
                  ? RichTextEditor.createValueFromString(
                      response.description,
                      "html"
                    )
                  : RichTextEditor.createEmptyValue(),
                editDescription: false,

                showDeliverables: true,
                showTasks: true,
                showPanelHeader: true,
                showSidebar: true,
                showDetails: true,
                selectedMacro: !!response.parentMacro
                  ? ([
                      {
                        name: response.parentMacro.name,
                        key: response.parentMacro.id,
                      },
                    ] as ITag[])
                  : undefined,
              });
            } else {
              this.setState({
                item: response,
                showPanel: true,
                viewDescription: false,
                viewParent: false,
                viewLinkedMicro: false,
                validationOutOfPlatform: true,
                historyOptions: response.actions
                  ? response.actions
                      .filter((a) => a.actionDate)
                      .map((action) => {
                        return this._mapAction(action);
                      })
                  : this.state.historyOptions,
                historySelected:
                  response.actions && response.actions.length > 0
                    ? this._mapAction(response.actions[0])
                    : undefined,
                isMacro: false,
                parentMacro: undefined,
                linkedMicro: undefined,
                microActivities: [],
                isLoading: false,
                isExternalLoading: false,
                descriptionTemp: response.description
                  ? RichTextEditor.createValueFromString(
                      response.description,
                      "html"
                    )
                  : RichTextEditor.createEmptyValue(),
                editDescription: false,

                showDeliverables: true,
                showTasks: true,
                showPanelHeader: true,
                showSidebar: true,
                showDetails: true,
              });
            }
          } else {
            this.props.commonProps.toastComponent?.showMessage(
              this._translation.error,
              this._translation.getActivityError,
              ToastNotificationType.ERROR
            );
            this.setState({
              // item: undefined,
              isLoading: false,
              isExternalLoading: false,
            });
          }
        });
    }
  }

  private _onRenderHistoryTitle(options?: IDropdownOption[]) {
    var option = options ? options[0] : null;

    return (
      <div>
        <ActivityItem
          className={styles.ActionTitleClosed}
          {...option?.data}
          key={option?.key}
        />
        <div className={styles.ActionTitleExpanded}>
          <strong>{this._translation.getActionHistory}</strong>
        </div>
      </div>
    );
  }

  private _onRenderHistoryOption(
    option?: ISelectableOption,
    _defaultRender?: IRenderFunction<ISelectableOption>
  ) {
    return (
      <div>
        <ActivityItem {...option?.data} key={option?.key}></ActivityItem>
      </div>
    );
  }

  private _onRenderActivityDetailsHeader(ev: any, obj: any) {
    var commonProps: ICommonProperties = {
      clientCreator: this.props.commonProps.clientCreator,
      translation: this.props.commonProps.translation,
      toastComponent: this.state.toastRefState?.current,
      onNotification: this.props.commonProps.onNotification,
      onRoute: this.props.commonProps.onRoute,
    };
    return !this.state.showPanelHeader ? (
      <SpinnerComponent
        commonProps={commonProps}
        relative={false}
      ></SpinnerComponent>
    ) : (
      <PanelActivityHeaderComponent
        commonProps={commonProps}
        item={this.state.item}
        isMacro={this.state.item?.isMacro}
        showContextMenu={true}
        showStatus={true}
        showType={true}
        showManage={false}
        deleteActivity={this.props.deleteActivity}
        statusChange={this._updateStatus}
        titleChange={this._updateTitle}
      ></PanelActivityHeaderComponent>
    );
  }

  private _onRenderParentDetailsHeader(ev: any, obj: any) {
    var commonProps: ICommonProperties = {
      clientCreator: this.props.commonProps.clientCreator,
      translation: this.props.commonProps.translation,
      toastComponent: this.state.toastRefState?.current,
      onNotification: this.props.commonProps.onNotification,
      onRoute: this.props.commonProps.onRoute,
    };
    return (
      <PanelActivityHeaderComponent
        commonProps={commonProps}
        item={
          {
            ...this.state.item?.parentMacro,
            isMacro: true,
          } as IActivityAllAttributes
        }
        isMacro={true}
        showContextMenu={false}
        showStatus={false}
        showType={true}
        showManage={false}
      ></PanelActivityHeaderComponent>
    );
  }

  private _onRenderLinkedMicroDetailsHeader(ev: any, obj: any) {
    var commonProps: ICommonProperties = {
      clientCreator: this.props.commonProps.clientCreator,
      translation: this.props.commonProps.translation,
      toastComponent: this.state.toastRefState?.current,
      onNotification: this.props.commonProps.onNotification,
      onRoute: this.props.commonProps.onRoute,
    };
    return (
      <PanelActivityHeaderComponent
        commonProps={commonProps}
        item={
          {
            ...this.state.linkedMicro,
            isMacro: false,
          } as IActivityAllAttributes
        }
        isMacro={false}
        showContextMenu={false}
        showStatus={false}
        showType={true}
        showManage={false}
      ></PanelActivityHeaderComponent>
    );
  }

  private _renderActivityModalHeader() {
    var commonProps: ICommonProperties = {
      clientCreator: this.props.commonProps.clientCreator,
      translation: this.props.commonProps.translation,
      toastComponent: this.state.toastRefState?.current,
      onNotification: this.props.commonProps.onNotification,
      onRoute: this.props.commonProps.onRoute,
    };
    return (
      <PanelActivityHeaderComponent
        commonProps={commonProps}
        item={this.state.item}
        isMacro={this.state.item?.isMacro}
        showContextMenu={false}
        showStatus={false}
        showType={true}
        showManage={false}
      ></PanelActivityHeaderComponent>
    );
  }

  private _viewDescription(ev: any): void {
    ev.preventDefault();
    this.setState({
      viewDescription: true,
      editDescription: this.state.item?.permissions.canUpdate ? true : false,
    });
  }

  private _viewParent(ev: any): void {
    ev.preventDefault();
    this.setState({ viewParent: true });
  }

  private _viewLinkedMicro(ev: any, item: IMicro): void {
    ev.preventDefault();
    this.setState({ viewLinkedMicro: true, linkedMicro: item });
  }

  private async _updateStatus(
    itemId: number,
    status: string
  ): Promise<boolean> {
    this.setState({
      isLoading: true,
      showPanelHeader: false,
      showDetails: false,
    });

    var result = await this._activityClient.updateActivityStatus(
      itemId,
      status
    );
    if (!result) {
      this.state.toastRefState?.current?.showMessage(
        this._translation.error,
        this._translation.updateActivityError,
        ToastNotificationType.ERROR
      );
    } else {
      this.state.toastRefState?.current?.showMessage(
        this._translation.ok,
        this._translation.success,
        ToastNotificationType.SUCCESS,
        false,
        ToastNotificationPosition.TOPCENTER
      );
    }
    this.props.reloadActivities();
    this._setActivityState();
    return true;
  }

  private async _updateTitle(name: string): Promise<boolean> {
    var result: boolean | undefined = true;

    if (this.state.item?.id) {
      this.setState({
        isLoading: true,
        showPanelHeader: false,
      });

      result = await this._activityClient.updateActivityName(
        this.state.item.id,
        name,
        this.state.item.isMacro
      );
      if (!result) {
        this.state.toastRefState?.current?.showMessage(
          this._translation.error,
          this._translation.updateActivityError,
          ToastNotificationType.ERROR
        );
      } else {
        this.state.toastRefState?.current?.showMessage(
          this._translation.ok,
          this._translation.success,
          ToastNotificationType.SUCCESS,
          false,
          ToastNotificationPosition.TOPCENTER
        );
      }
      this.props.reloadActivities();
      this._setActivityState();
    }
    return !!result;
  }

  private async _updateParent(idMacro: number): Promise<boolean> {
    var result: boolean | undefined = true;

    if (this.state.item?.id) {
      this.setState({
        isLoading: true,
        showPanelHeader: false,
      });

      result = await this._activityClient.updateActivityParent(
        this.state.item.id,
        idMacro
      );
      if (!result) {
        this.state.toastRefState?.current?.showMessage(
          this._translation.error,
          this._translation.updateActivityError,
          ToastNotificationType.ERROR
        );
      } else {
        this.state.toastRefState?.current?.showMessage(
          this._translation.ok,
          this._translation.success,
          ToastNotificationType.SUCCESS,
          false,
          ToastNotificationPosition.TOPCENTER
        );
      }
      this.props.reloadActivities();
      this._setActivityState();
    }
    return !!result;
  }

  private async _updateDescription(description: string) {
    this.setState({ isLoading: true });
    if (this.state.item?.id) {
      this.setState({
        isLoading: true,
        showSidebar: this.state.isMacro ? true : false,
        viewDescription: false,
        // showDetails: this.state.isMacro ? false : true,
      });
      var result = await this._activityClient.updateActivityDescription(
        this.state.item?.id,
        description,
        this.state.isMacro
      );
      if (!result) {
        this.state.toastRefState?.current?.showMessage(
          this._translation.error,
          this._translation.updateActivityError,
          ToastNotificationType.ERROR
        );
      } else {
        this.state.toastRefState?.current?.showMessage(
          this._translation.ok,
          this._translation.success,
          ToastNotificationType.SUCCESS,
          false,
          ToastNotificationPosition.TOPCENTER
        );
      }

      this.setState(
        {
          /*item: undefined,*/ descriptionTemp: RichTextEditor.createEmptyValue(),
        },
        this._setActivityState
      );
    }
    return true;
  }

  private async _categoryOnChangeMultiple(
    ev: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption
  ) {
    if (this.state.item?.id) {
      const value = !!option?.key ? +option.key : 0;
      var item = this.state.item;
      var categories = item?.categories;
      if (option?.selected) {
        categories.push(value);
      } else {
        categories = categories.filter((x) => x != value);
      }
      item.categories = categories;
      this.setState({
        item: item,
      });
    }

    return true;
  }

  private async _categoryOnChange(
    ev: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption
  ) {
    this.setState({ isLoading: true });
    if (this.state.item?.id) {
      this.setState({
        isLoading: true,
        showSidebar: this.state.isMacro ? true : false,
        viewDescription: false,
        // showDetails: this.state.isMacro ? false : true,
      });
      const value = !!option?.key ? +option.key : 0;

      var result = await this._activityClient.updateActivityCategory(
        this.state.item?.id,
        value.toString()
      );
      if (result < 0) {
        this.state.toastRefState?.current?.showMessage(
          this._translation.error,
          this._translation.updateActivityError,
          ToastNotificationType.ERROR
        );
      } else {
        this.state.toastRefState?.current?.showMessage(
          this._translation.ok,
          this._translation.success,
          ToastNotificationType.SUCCESS,
          false,
          ToastNotificationPosition.TOPCENTER
        );
      }

      this.setState(
        {
          /*item: undefined,*/ descriptionTemp: RichTextEditor.createEmptyValue(),
          editCategory: false,
        },
        this._setActivityState
      );
    }
    return true;
  }

  private async _categoryDismiss() // ev: React.FormEvent<HTMLDivElement>,
  // option?: IDropdownOption
  {
    this.setState({ isLoading: true });
    if (this.state.item?.id) {
      this.setState({
        isLoading: true,
        showSidebar: this.state.isMacro ? true : false,
        viewDescription: false,
        // showDetails: this.state.isMacro ? false : true,
      });

      var result = await this._activityClient.updateActivityCategories(
        this.state.item?.id,
        this.state.item?.categories
      );
      if (result < 0) {
        this.state.toastRefState?.current?.showMessage(
          this._translation.error,
          this._translation.updateActivityError,
          ToastNotificationType.ERROR
        );
      } else {
        this.state.toastRefState?.current?.showMessage(
          this._translation.ok,
          this._translation.success,
          ToastNotificationType.SUCCESS,
          false,
          ToastNotificationPosition.TOPCENTER
        );
      }

      this.setState(
        {
          /*item: undefined,*/ descriptionTemp: RichTextEditor.createEmptyValue(),
          editCategory: false,
        },
        this._setActivityState
      );
    }

    return true;
  }

  private async _responsibilityOnChangeMultiple(
    ev: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption
  ) {
    if (this.state.item?.id) {
      const value = !!option?.key ? +option.key : 0;
      var item = this.state.item;
      var responsibilities = item?.responsibilities;
      if (option?.selected) {
        responsibilities.push(value);
      } else {
        responsibilities = responsibilities.filter((x) => x != value);
      }
      item.responsibilities = responsibilities;
      this.setState({
        item: item,
      });
    }
    return true;
  }

  private async _responsibilityOnChange(
    ev: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption
  ) {
    this.setState({ isLoading: true });
    if (this.state.item?.id) {
      this.setState({
        isLoading: true,
        showSidebar: this.state.isMacro ? true : false,
        viewDescription: false,
        // showDetails: this.state.isMacro ? false : true,
      });
      const value = !!option?.key ? +option.key : 0;

      var result = await this._activityClient.updateActivityResponsibility(
        this.state.item?.id,
        value.toString()
      );
      if (result < 0) {
        this.state.toastRefState?.current?.showMessage(
          this._translation.error,
          this._translation.updateActivityError,
          ToastNotificationType.ERROR
        );
      } else {
        this.state.toastRefState?.current?.showMessage(
          this._translation.ok,
          this._translation.success,
          ToastNotificationType.SUCCESS,
          false,
          ToastNotificationPosition.TOPCENTER
        );
      }

      this.setState(
        {
          /*item: undefined,*/ descriptionTemp: RichTextEditor.createEmptyValue(),
          editResponsibility: false,
        },
        this._setActivityState
      );
    }
    return true;
  }

  private async _responsibilityOnDismiss() // ev: React.FormEvent<HTMLDivElement>,
  // option?: IDropdownOption
  {
    this.setState({ isLoading: true });
    if (this.state.item?.id) {
      this.setState({
        isLoading: true,
        showSidebar: this.state.isMacro ? true : false,
        viewDescription: false,
        // showDetails: this.state.isMacro ? false : true,
      });

      var result = await this._activityClient.updateActivityResponsibilities(
        this.state.item?.id,
        this.state.item?.responsibilities
      );
      if (result < 0) {
        this.state.toastRefState?.current?.showMessage(
          this._translation.error,
          this._translation.updateActivityError,
          ToastNotificationType.ERROR
        );
      } else {
        this.state.toastRefState?.current?.showMessage(
          this._translation.ok,
          this._translation.success,
          ToastNotificationType.SUCCESS,
          false,
          ToastNotificationPosition.TOPCENTER
        );
      }

      this.setState(
        {
          /*item: undefined,*/ descriptionTemp: RichTextEditor.createEmptyValue(),
          editResponsibility: false,
        },
        this._setActivityState
      );
    }
    return true;
  }

  private async _sensibilityOnChange(
    ev: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption
  ) {
    this.setState({ isLoading: true });
    if (this.state.item?.id) {
      this.setState({
        isLoading: true,
        showSidebar: this.state.isMacro ? true : false,
        viewDescription: false,
        // showDetails: this.state.isMacro ? false : true,
      });
      const value = !!option?.key ? +option.key : 0;

      var result = await this._activityClient.updateActivitySensibility(
        this.state.item?.id,
        value.toString()
      );
      if (result < 0) {
        this.state.toastRefState?.current?.showMessage(
          this._translation.error,
          this._translation.updateActivityError,
          ToastNotificationType.ERROR
        );
      } else {
        this.state.toastRefState?.current?.showMessage(
          this._translation.ok,
          this._translation.success,
          ToastNotificationType.SUCCESS,
          false,
          ToastNotificationPosition.TOPCENTER
        );
      }

      this.setState(
        {
          /*item: undefined,*/ descriptionTemp: RichTextEditor.createEmptyValue(),
          editSensibility: false,
        },
        this._setActivityState
      );
    }
    return true;
  }

  private async _updateTimingActivity(
    newValue: IDateRangeValue | undefined
  ): Promise<boolean> {
    var result: boolean | undefined = true;
    if (newValue && this.state.item?.id) {
      if (this._compareMicroMacroDate(newValue)) {
        this.setState({ isLoading: true /* showDetails: false */ });
        // this.setState({ isLoading: true });
        result = await this._activityClient.updateActivityTiming(
          this.state.item?.id,
          newValue
        );
        if (!result) {
          result = false;
          this.state.toastRefState?.current?.showMessage(
            this._translation.error,
            this._translation.updateActivityError,
            ToastNotificationType.ERROR
          );
        } else {
          this.state.toastRefState?.current?.showMessage(
            this._translation.ok,
            this._translation.success,
            ToastNotificationType.SUCCESS,
            false,
            ToastNotificationPosition.TOPCENTER
          );
        }

        this.setState(
          {
            /*item: undefined,*/ descriptionTemp: RichTextEditor.createEmptyValue(),
          },
          this._setActivityState
        );
      } else {
        result = false;
      }
    }

    return result;
  }

  private async _updateAssignedResources(
    listResources: User[],
    update: boolean
  ): Promise<User[] | undefined> {
    if (update && this.state.item?.id) {
      this.setState({
        isLoading: true,
        showTasks: false /*showSidebar: false*/,
      });
      // this.setState({ isLoading: true });
      var result = await this._activityClient.updateActivityInternalResources(
        this.state.item?.id,
        listResources
      );
      if (result == -1) {
        this.state.toastRefState?.current?.showMessage(
          this._translation.error,
          this._translation.resourceCannotBeRemoved,
          ToastNotificationType.ERROR
        );
      } else if (result < 0) {
        this.state.toastRefState?.current?.showMessage(
          this._translation.error,
          this._translation.updateActivityError,
          ToastNotificationType.ERROR
        );
      } else {
        this.state.toastRefState?.current?.showMessage(
          this._translation.ok,
          this._translation.success,
          ToastNotificationType.SUCCESS,
          false,
          ToastNotificationPosition.TOPCENTER
        );
      }

      this.setState(
        { descriptionTemp: RichTextEditor.createEmptyValue() },
        this._setActivityState
      );

      return result >= 0 ? listResources : this.state.item.resources;
    }

    return this.state.item?.resources;
  }

  private async _updateAlarmResources(
    listResources: User[],
    update: boolean
  ): Promise<User[] | undefined> {
    if (update && this.state.item?.id) {
      this.setState({
        isLoading: true,
        showTasks: false /*showSidebar: false*/,
      });
      // this.setState({ isLoading: true });
      var result = await this._activityClient.updateActivityAlarmResources(
        this.state.item?.id,
        listResources
      );
      if (result == -1) {
        this.state.toastRefState?.current?.showMessage(
          this._translation.error,
          this._translation.resourceCannotBeRemoved,
          ToastNotificationType.ERROR
        );
      } else if (result < 0) {
        this.state.toastRefState?.current?.showMessage(
          this._translation.error,
          this._translation.updateActivityError,
          ToastNotificationType.ERROR
        );
      } else {
        this.state.toastRefState?.current?.showMessage(
          this._translation.ok,
          this._translation.success,
          ToastNotificationType.SUCCESS,
          false,
          ToastNotificationPosition.TOPCENTER
        );
      }

      this.setState(
        { descriptionTemp: RichTextEditor.createEmptyValue() },
        this._setActivityState
      );

      return result >= 0 ? listResources : this.state.item.alarmResources;
    }

    return this.state.item?.alarmResources;
  }

  private async _updateExternalResources(
    listResources: User[],
    update: boolean
  ): Promise<User[] | undefined> {
    if (update && this.state.item?.id) {
      this.setState({
        isLoading: true,
        showTasks: false /*showSidebar: false*/,
      });
      // this.setState({ isLoading: true });
      var result = await this._activityClient.updateActivityExternalResources(
        this.state.item?.id,
        listResources
      );
      if (result == -1) {
        this.state.toastRefState?.current?.showMessage(
          this._translation.error,
          this._translation.resourceCannotBeRemoved,
          ToastNotificationType.ERROR
        );
      } else if (result < 0) {
        this.state.toastRefState?.current?.showMessage(
          this._translation.error,
          this._translation.updateActivityError,
          ToastNotificationType.ERROR
        );
      } else {
        this.state.toastRefState?.current?.showMessage(
          this._translation.ok,
          this._translation.success,
          ToastNotificationType.SUCCESS,
          false,
          ToastNotificationPosition.TOPCENTER
        );
      }

      this.setState(
        { descriptionTemp: RichTextEditor.createEmptyValue() },
        this._setActivityState
      );

      return result >= 0 ? listResources : this.state.item.externalResources;
    }
    return this.state.item?.externalResources;
  }

  private async _updateDueToActivity(
    newValue: IDateRangeValue | undefined
  ): Promise<boolean> {
    if (newValue && this.state.item?.id) {
      var noProblemWithTasks = await this._compareMicroTaskDate(newValue);
      if (!noProblemWithTasks) {
      } else if (this._compareMicroMacroDate(newValue)) {
        this.setState({ isLoading: true /*showSidebar: false*/ });
        // this.setState({ isLoading: true });
        var result = await this._activityClient.updateActivityDueTo(
          this.state.item?.id,
          newValue
        );
        if (!result) {
          this.state.toastRefState?.current?.showMessage(
            this._translation.error,
            this._translation.updateActivityError,
            ToastNotificationType.ERROR
          );
        } else {
          this.state.toastRefState?.current?.showMessage(
            this._translation.ok,
            this._translation.success,
            ToastNotificationType.SUCCESS,
            false,
            ToastNotificationPosition.TOPCENTER
          );
        }

        this.setState(
          {
            showTasks: false,
            /*item: undefined,*/ descriptionTemp: RichTextEditor.createEmptyValue(),
          },
          this._setActivityState
        );
        return result ? result : false;
      }
    }

    return false;
  }

  private async _updateSpoc(selected: ITag[]): Promise<ITag | undefined> {
    if (
      // selected.length > 0 &&
      this.state.item?.id &&
      this.state.isMacro != undefined
    ) {
      this.setState({ isLoading: true /*showSidebar: false*/ });
      // this.setState({ isLoading: true });
      var result = await this._activityClient.updateSpoc(
        this.state.item?.id,
        selected.length > 0 ? selected[0].key.toString() : null,
        this.state.isMacro
      );
      if (result == -1) {
        this.state.toastRefState?.current?.showMessage(
          this._translation.error,
          this._translation.resourceCannotBeRemoved,
          ToastNotificationType.ERROR
        );
      } else if (result < 0) {
        this.state.toastRefState?.current?.showMessage(
          this._translation.error,
          this._translation.updateActivityError,
          ToastNotificationType.ERROR
        );
      } else {
        this.state.toastRefState?.current?.showMessage(
          this._translation.ok,
          this._translation.success,
          ToastNotificationType.SUCCESS,
          false,
          ToastNotificationPosition.TOPCENTER
        );
      }

      this.setState(
        {
          /*item: undefined, */ descriptionTemp: RichTextEditor.createEmptyValue(),
        },
        this._setActivityState
      );
      return result >= 0 && selected.length > 0
        ? selected[0]
        : selected.length == 0
        ? undefined
        : this.state.item?.spoc
        ? {
            key: this.state.item?.spoc.userPrincipalName,
            name: this.state.item?.spoc.displayName,
          }
        : undefined;
    }

    return this.state.item?.spoc
      ? {
          key: this.state.item?.spoc.userPrincipalName,
          name: this.state.item?.spoc.displayName,
        }
      : undefined;
  }

  private async _updateAlarm(alarm: number): Promise<number> {
    if (this.state.item?.id && !this.state.isMacro) {
      this.setState({ isLoading: true /*showSidebar: false*/ });
      // this.setState({ isLoading: true });
      var result = await this._activityClient.updateReminder(
        this.state.item?.id,
        alarm
      );
      if (result == -1) {
        this.state.toastRefState?.current?.showMessage(
          this._translation.error,
          this._translation.resourceCannotBeRemoved,
          ToastNotificationType.ERROR
        );
      } else if (result < 0) {
        this.state.toastRefState?.current?.showMessage(
          this._translation.error,
          this._translation.updateActivityError,
          ToastNotificationType.ERROR
        );
      } else {
        this.state.toastRefState?.current?.showMessage(
          this._translation.ok,
          this._translation.success,
          ToastNotificationType.SUCCESS,
          false,
          ToastNotificationPosition.TOPCENTER
        );
      }

      this.setState(
        {
          descriptionTemp: RichTextEditor.createEmptyValue(),
        },
        this._setActivityState
      );
      return result;
    }

    return this.state.item?.alarm !== undefined ? this.state.item.alarm : -1;
  }

  private async _updateHeadOfActivity(
    selected: ITag[]
  ): Promise<ITag | undefined> {
    if (
      // selected.length > 0 &&
      this.state.item?.id &&
      this.state.isMacro != undefined
    ) {
      this.setState({ isLoading: true /*showSidebar: false*/ });
      // this.setState({ isLoading: true });
      var result = await this._activityClient.updateHeadOfActivity(
        this.state.item?.id,
        selected.length > 0 ? selected[0].key.toString() : null,
        this.state.isMacro
      );
      if (!result) {
        this.state.toastRefState?.current?.showMessage(
          this._translation.error,
          this._translation.updateActivityError,
          ToastNotificationType.ERROR
        );
      } else {
        this.state.toastRefState?.current?.showMessage(
          this._translation.ok,
          this._translation.success,
          ToastNotificationType.SUCCESS,
          false,
          ToastNotificationPosition.TOPCENTER
        );
      }

      this.setState(
        {
          /*item: undefined, */ descriptionTemp: RichTextEditor.createEmptyValue(),
        },
        this._setActivityState
      );
      return result && selected.length > 0
        ? selected[0]
        : selected.length == 0
        ? undefined
        : this.state.item?.headOfActivity
        ? {
            key: this.state.item?.headOfActivity.userPrincipalName,
            name: this.state.item?.headOfActivity.displayName,
          }
        : undefined;
    }

    return this.state.item?.headOfActivity
      ? {
          key: this.state.item?.headOfActivity.userPrincipalName,
          name: this.state.item?.headOfActivity.displayName,
        }
      : undefined;
  }

  private async _updateMacroTiming() {
    if (this.state.isMacro == true) {
      this._setActivityState();
    } else if (this.state.isMacro == false) {
      if (this.state.newDateValue && this.state.item?.id) {
        this.setState({ isLoading: true, showSidebar: false });
        // this.setState({ isLoading: true });
        var result = await this._activityClient.updateActivityDueTo(
          this.state.item?.id,
          this.state.newDateValue as IDateRangeValue
        );
        if (!result) {
          this.state.toastRefState?.current?.showMessage(
            this._translation.error,
            this._translation.updateActivityError,
            ToastNotificationType.ERROR
          );
        } else {
          this.state.toastRefState?.current?.showMessage(
            this._translation.ok,
            this._translation.success,
            ToastNotificationType.SUCCESS,
            false,
            ToastNotificationPosition.TOPCENTER
          );
        }

        this.setState(
          {
            /*item: undefined,*/ descriptionTemp: RichTextEditor.createEmptyValue(),
            showTimingDialog: false,
          },
          this._setActivityState
        );
      }
    }
  }

  private _confirmDialogContentProps(): IDialogContentProps {
    if (this.state.showConfirmDialog == false) {
      if (this.state.isMacro == true)
        return {
          type: DialogType.normal,
          title: this._macroTranslation.errorDateRangeTitle,
          subText: this._macroTranslation.errorDateRangeMacroSubTitle,
        };
      else
        return {
          type: DialogType.normal,
          title: this._macroTranslation.errorDateRangeTitle,
          subText: this._macroTranslation.errorDateRangeMicroTasksSubTitle,
        };
    } else {
      return {
        type: DialogType.normal,
        title: this._microTranslation.warningDateRangeTitle,
        subText: this._microTranslation.warningDateRangeSubTitle,
      };
    }
  }

  private async _compareMicroTaskDate(
    newValue: IDateRangeValue
  ): Promise<boolean> {
    var result: boolean = true;
    if (this.state.item?.id && this.state.item?.isMacro == false) {
      var newStartDate: Date = (newValue as IDateRangeValue).fromDate;
      var newEndDate: Date = (newValue as IDateRangeValue).toDate;

      var timingProblem: boolean = false;
      var items = await this._taskClient.getTasks(this.state.item.id);
      items?.forEach((item) => {
        var childStartDate = item.dateFrom;
        var childEndDate = item.dateTo;
        var childStartDateToCheck: Date = item.dateTo
          ? new Date(item.dateTo.getTime())
          : new Date(); //Object.assign({}, childEndDate);
        childStartDateToCheck?.setHours(23, 59, 59);
        var childEndDateToCheck: Date = item.dateTo
          ? new Date(item.dateTo.getTime())
          : new Date(); //Object.assign({}, childEndDate);
        childEndDateToCheck?.setHours(0, 0, 0);

        if (
          childStartDate &&
          childEndDate &&
          (childStartDateToCheck < newStartDate ||
            childEndDateToCheck > newEndDate)
        ) {
          timingProblem = true;
        }
      });

      if (timingProblem) {
        this.setState({
          showTimingDialog: true,
          showConfirmDialog: false,
          newDateValue: newValue,
        });
        result = false;
      }
    }

    return result;
  }

  private _compareMicroMacroDate(newValue: IDateRangeValue): boolean {
    var result: boolean = true;
    if (this.state.item?.isMacro == true) {
      var newStartDate: Date = (newValue as IDateRangeValue).fromDate;
      var newEndDate: Date = (newValue as IDateRangeValue).toDate;

      var timingProblem: boolean = false;

      this.state.item?.microActivities?.forEach((item) => {
        var childStartDate: Date = item.dateFrom;
        var childEndDate: Date = item.dateTo;
        if (childStartDate < newStartDate || childEndDate > newEndDate) {
          timingProblem = true;
        }
      });

      if (timingProblem) {
        this.setState({
          showTimingDialog: true,
          showConfirmDialog: false,
          newDateValue: newValue,
        });
        result = false;
      }
    } else {
      var newStartDate: Date = (newValue as IDateRangeValue).fromDate;
      var newEndDate: Date = (newValue as IDateRangeValue).toDate;
      var timingProblem: boolean = false;

      if (this.state.item?.parentMacro) {
        var parentStartDate: Date = this.state.item?.parentMacro.dateFrom;
        var parentEndDate: Date = this.state.item?.parentMacro.dateTo;
        if (parentStartDate > newStartDate || newEndDate > parentEndDate) {
          timingProblem = true;
        }
      }

      if (timingProblem) {
        this.setState({
          showTimingDialog: true,
          showConfirmDialog: true,
          newDateValue: newValue,
        });
        result = false;
      }
    }

    return result;
  }

  private _decodeHTMLEntities(text: string) {
    var span = document.createElement("span");
    span.innerHTML = text;
    return span.textContent || span.innerText;
  }

  private _viewParentChangeModal() {
    this._loadMacroItems();
    this.setState({
      editParentMacro: true,
      selectedParentMacroId: this.state.item?.parentMacro?.id,
    });
  }

  private _loadMacroItems() {
    let date: Date | undefined;
    if (!!this.state.filterMacroByDate) {
      date = this.state.item?.dateFrom;
    }

    this._activityClient
      .getMacroAsync(date, this.state.item?.calendar)
      .then((macroItems) => {
        if (macroItems) {
          if (
            !!this.state.selectedParentMacroId &&
            !macroItems.some((m) => m.id === this.state.selectedParentMacroId)
          ) {
            this.setState({
              selectedParentMacroId: undefined,
              selectedMacro: undefined,
              macroPickerKey: this.state.macroPickerKey + 1,
            });
          }
          this.setState({
            macroCollection: macroItems,
            macrosLoaded: true,
          });
        } else {
          this.setState({
            showMessageBar: true,
            messageBarText: this._microTranslation.errorInLoadMacro,
            messageType: MessageBarType.error,
          });
        }
      });
  }

  private get pickerMacroSuggestionsProps(): IBasePickerSuggestionsProps {
    const props: IBasePickerSuggestionsProps = {
      suggestionsHeaderText: this._translation.suggestedMacro,
      noResultsFoundText: this._translation.noMacroFound,
    };
    return props;
  }

  private currentListContainsTag = (tag: ITag, tagList?: ITag[]) => {
    if (!tagList || !tagList.length || tagList.length === 0) {
      return false;
    }
    return tagList.some((compareTag) => compareTag.key === tag.key);
  };

  private readonly checkMacroSelectedItem = (
    selectedItem?: ITag | undefined
  ): ITag | PromiseLike<ITag> | null => {
    if (
      selectedItem &&
      this.macroPicker.current &&
      this.currentListContainsTag(
        selectedItem as ITag,
        this.macroPicker.current.items
      )
    ) {
      return null;
    }

    return selectedItem as ITag;
  };

  private readonly filterMacro = (
    filter: string,
    selectedItems?: ITag[] | undefined
  ): ITag[] | PromiseLike<ITag[]> => {
    if (filter) {
      if (filter.length >= this.minDigitBeforeSearchMacro) {
        this.pickerSuggestionsProps.noResultsFoundText = this._translation.noMacroFound;
        return this.getMacroByFilter(filter);
      } else {
        this.pickerSuggestionsProps.noResultsFoundText = this._translation.minDigitBeforeSearch(
          this.minDigitBeforeSearchMacro
        );
        return [];
      }
    } else {
      return [];
    }
  };

  private getMacroByFilter(filter: string): ITag[] {
    return this.state.macroCollection
      .filter(
        (i) =>
          !!i.name && i.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0
      )
      .map(
        (item: IActivity): ITag => ({ key: item.id, name: item.name } as ITag)
      );
  }

  private get pickerSuggestionsProps(): IBasePickerSuggestionsProps {
    const props: IBasePickerSuggestionsProps = {
      suggestionsHeaderText: this._translation.suggestedUser,
      noResultsFoundText: this._translation.noUsersFound,
    };
    return props;
  }

  private selectedMacroChange(items?: ITag[] | undefined): void {
    if (items) {
      const ids: number[] = (items as ITag[]).map((t) => t.key as number);
      this.setState({
        selectedParentMacroId: ids[0] || undefined,
        selectedMacro: items,
      });
    }
  }

  private _macroOptionItems(): IComboBoxOption[] {
    if (this.state.macrosLoaded) {
      let values = this.state.macroCollection.map((item) => {
        return {
          key: item.id,
          text: item.name,
          itemType: SelectableOptionMenuItemType.Normal,
        } as IComboBoxOption;
      });
      return values;
    } else {
      return [];
    }
  }

  private _compareMicroMacroDateForChange() {
    const selectedItem = this.state.macroCollection.filter(
      (t) => t.id === this.state.selectedParentMacroId
    );
    const { dateFrom, dateTo } = selectedItem[0];
    var timingProblem: boolean = false;

    if (this.state.item?.parentMacro) {
      if (
        dateFrom > this.state.item.dateFrom ||
        this.state.item.dateTo > dateTo
      ) {
        timingProblem = true;
      }
    }

    if (timingProblem) {
      this.setState({
        showMessageBar: true,
        messageBarText: this._microTranslation.dateMicroGreaterMacro(
          Utility.formatDate(dateFrom, "DD/MM/YYYY"),
          Utility.formatDate(dateTo, "DD/MM/YYYY")
        ),
        messageType: MessageBarType.warning,
      });
    }
  }

  public render() {
    var commonProps: ICommonProperties = {
      clientCreator: this.props.commonProps.clientCreator,
      translation: this.props.commonProps.translation,
      toastComponent: this.state.toastRefState?.current,
      onNotification: this.props.commonProps.onNotification,
      onRoute: this.props.commonProps.onRoute,
    };

    return [
      <LoaderComponent
        commonProps={this.props.commonProps}
        isLoading={this.state.isExternalLoading}
      />,
      <Panel
        role="activityDetails"
        onRenderHeader={this._onRenderActivityDetailsHeader}
        headerText={this._translation.activityDetails}
        isOpen={this.state.showPanel ? true : false}
        onDismiss={(ev) => {
          ev?.preventDefault();
          if (this.props.onDismiss) {
            this.props.onDismiss();
          }
          this.setState({ showPanel: false });
        }}
        customWidth={window.innerWidth > 767 ? "75vw" : "100vw"}
        className={styles.panelActivityDetails}
        type={PanelType.custom}
        closeButtonAriaLabel={this._translation.close}
        isHiddenOnDismiss={false}
        isLightDismiss={false}

        // hasCloseButton={false}
      >
        <ToastComponent
          commonProps={commonProps}
          ref={this.state.toastRefState}
        ></ToastComponent>
        <div className={styles.ActivityDetailsComponent}>
          <LoaderComponent
            commonProps={commonProps}
            isLoading={this.state.isLoading}
          />

          <Dialog
            hidden={!this.state.showTimingDialog}
            onDismiss={() => this.setState({ showTimingDialog: false })}
            modalProps={this.confirmTimingDialogProps}
            closeButtonAriaLabel={this._translation.cancel}
            dialogContentProps={this._confirmDialogContentProps()}
          >
            <DialogFooter>
              {this.state.showConfirmDialog && (
                <PrimaryButton
                  ariaLabel={this._translation.confirm}
                  aria-label={this._translation.confirm}
                  alt={this._translation.confirm}
                  onClick={this._updateMacroTiming}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      this._updateMacroTiming();
                    }
                  }}
                  tabIndex={0}
                  text={this._translation.confirm}
                />
              )}
              <DefaultButton
                alt={this._translation.cancel}
                ariaLabel={this._translation.cancel}
                aria-label={this._translation.cancel}
                onClick={() => {
                  this.setState({ showTimingDialog: false });
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    this.setState({ showTimingDialog: false });
                  }
                }}
                tabIndex={0}
                text={this._translation.cancel}
              />
            </DialogFooter>
          </Dialog>

          <Modal
            titleAriaId="Micro dependency configuration"
            isOpen={this.state.editParentMacro}
            onDismiss={(ev) => {
              ev?.preventDefault();
              this.setState({ editParentMacro: false });
            }}
            isBlocking={true}
            containerClassName={
              styles.modalContainer + " " + styles.modalContainerFileOverwrite
            }
            closeButtonAriaLabel={this._translation.cancel}
            className={styles.ModalAssignedResources}
          >
            <strong className={styles.modalHeader}>
              {this._renderActivityModalHeader()}
              <FontIcon
                iconName={"Cancel"}
                style={{ cursor: "pointer" }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    this.setState({ editParentMacro: false });
                  }
                }}
                tabIndex={0}
                onClick={(ev: { preventDefault: () => void }) => {
                  ev?.preventDefault();
                  this.setState({ editParentMacro: false });
                }}
              ></FontIcon>
            </strong>
            <hr />
            <div className={styles.modalBody}>
              {this.state.showMessageBar && (
                <MessageBar
                  messageBarType={this.state.messageType}
                  isMultiline={false}
                  dismissButtonAriaLabel={this._translation.close}
                  onDismiss={() => {
                    this.setState({ showMessageBar: false });
                  }}
                  truncated={true}
                  overflowButtonAriaLabel="See more"
                  className={styles.modalBodyItem}
                >
                  {this.state.messageBarText}
                </MessageBar>
              )}
              {this.state.editParentMacro && (
                <Toggle
                  className={styles.modalBodyItem}
                  label={this._microTranslation.filterMacro}
                  checked={this.state.filterMacroByDate}
                  onText={this._translation.yes.toUpperCase()}
                  offText={this._translation.no.toUpperCase()}
                  ariaLabel={this._microTranslation.filterMacro}
                  // disabled={this.state.rangeDateValue ? false : true}
                  onChange={(ev, checked) => {
                    const check = checked ? true : false;

                    this.setState(
                      { filterMacroByDate: check },
                      async () => await this._loadMacroItems()
                    );
                  }}
                />
              )}
              <Label htmlFor="macro" styles={Constants.labelStyle}>
                {this._microTranslation.selectMacro}
              </Label>
              <TagPicker
                ref={this.macroPicker}
                key={this.state.macroPickerKey}
                defaultSelectedItems={this.state.selectedMacro}
                pickerSuggestionsProps={this.pickerMacroSuggestionsProps}
                onItemSelected={this.checkMacroSelectedItem}
                removeButtonAriaLabel={this._translation.remove}
                onResolveSuggestions={this.filterMacro}
                resolveDelay={300}
                itemLimit={1}
                onChange={this.selectedMacroChange}
                styles={Constants.pickerStyle}
                selectedItems={this.state.selectedMacro}
              ></TagPicker>
            </div>
            <div
              className={
                styles.modalFooter + " " + styles.modalFooterFileOverwrite
              }
            >
              <div className={styles.ModalFooterRow + " row"}>
                <div>
                  <DefaultButton
                    alt={this._translation.cancel}
                    ariaLabel={this._translation.cancel}
                    text={this._translation.cancel}
                    className={"btn btn-dismiss " + styles.btnAction}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        this.setState({
                          editParentMacro: false,
                          isLoading: false,
                        });
                      }
                    }}
                    tabIndex={0}
                    onClick={(ev: { preventDefault: () => void }) => {
                      ev?.preventDefault();
                      this.setState({
                        editParentMacro: false,
                        isLoading: false,
                      });
                    }}
                  />
                </div>
                <div className={styles.divBtnNewFile}>
                  <PrimaryButton
                    alt={this._translation.setMicroAsIndipendent}
                    ariaLabel={this._translation.setMicroAsIndipendent}
                    text={this._translation.setMicroAsIndipendent}
                    className={"btn btn-secondary " + styles.btnAction}
                    disabled={!this.state.item?.parentMacro?.id}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        this._updateParent(0).then((result) => {
                          if (result) {
                            this.setState({
                              editParentMacro: false,
                              isLoading: false,
                            });
                          } else {
                            this.setState({
                              showMessageBar: true,
                              messageBarText: this._translation
                                .updateActivityError,
                              messageType: MessageBarType.error,
                            });
                          }
                        });
                      }
                    }}
                    tabIndex={0}
                    onClick={(ev: { preventDefault: () => void }) => {
                      ev?.preventDefault();
                      this._updateParent(0).then((result) => {
                        if (result) {
                          this.setState({
                            editParentMacro: false,
                            isLoading: false,
                          });
                        } else {
                          this.setState({
                            showMessageBar: true,
                            messageBarText: this._translation
                              .updateActivityError,
                            messageType: MessageBarType.error,
                          });
                        }
                      });
                    }}
                  />
                </div>
                <div className={styles.divBtnNewFile}>
                  <PrimaryButton
                    alt={this._translation.save}
                    text={this._translation.save}
                    ariaLabel={this._translation.save}
                    className={"btn btn-primary " + styles.btnAction}
                    disabled={!this.state.selectedParentMacroId}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        if (this.state.selectedParentMacroId) {
                          this._updateParent(
                            this.state.selectedParentMacroId
                          ).then((result) => {
                            if (result) {
                              this.setState({
                                editParentMacro: false,
                                isLoading: false,
                              });
                            } else {
                              this.setState({
                                showMessageBar: true,
                                messageBarText: this._translation
                                  .updateActivityError,
                                messageType: MessageBarType.error,
                              });
                            }
                          });
                        }
                      }
                    }}
                    tabIndex={0}
                    onClick={(ev: { preventDefault: () => void }) => {
                      ev?.preventDefault();
                      if (this.state.selectedParentMacroId) {
                        this._updateParent(
                          this.state.selectedParentMacroId
                        ).then((result) => {
                          if (result) {
                            this.setState({
                              editParentMacro: false,
                              isLoading: false,
                            });
                          } else {
                            this.setState({
                              showMessageBar: true,
                              messageBarText: this._translation
                                .updateActivityError,
                              messageType: MessageBarType.error,
                            });
                          }
                        });
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </Modal>

          <hr className={styles.activity_details_separator} />
          <div className={styles.activity_details_body + " row"}>
            <div
              className={
                styles.activity_details_left + " ms-Grid-col ms-lg3 ms-md12"
              }
            >
              {this.state.showSidebar && (
                <ActivitySidebarComponent
                  commonProps={commonProps}
                  item={this.state.item}
                  calendars={this.props.calendars}
                  editable={true}
                  viewDetails={true}
                  viewDescription={this._viewDescription}
                  viewLinkedMicro={this._viewLinkedMicro}
                  viewParent={this._viewParent}
                  updateAssignedResources={this._updateAssignedResources}
                  updateAlarmResources={this._updateAlarmResources}
                  updateExternalResources={this._updateExternalResources}
                  updateDueToActivity={this._updateDueToActivity}
                  updateTimingActivity={this._updateTimingActivity}
                  updateAlarm={this._updateAlarm}
                  updateSpoc={this._updateSpoc}
                  updateHeadOfActivity={this._updateHeadOfActivity}
                  updateParent={this._viewParentChangeModal}
                />
              )}
              {!this.state.showSidebar && (
                <SpinnerComponent
                  commonProps={commonProps}
                  relative={false}
                ></SpinnerComponent>
              )}
            </div>
            <hr
              className={
                styles.activity_details_separator +
                " only-mobile ms-Grid-col ms-md12 ms-sm12"
              }
            />
            {!this.state.isMacro && this.state.showDetails && (
              <div
                className={
                  styles.activity_details_center +
                  " ms-Grid-col ms-lg9 ms-md12 "
                }
              >
                <div className={"row " + styles.headerActivityDetails}>
                  <div role="microHistory" className={styles.history}>
                    {this.state.historyOptions &&
                      this.state.historyOptions.length > 0 && (
                        <Dropdown
                          placeholder=""
                          // label="Basic uncontrolled example"

                          // disabled={!this.state.historyOptions || this.state.historyOptions.length == 0}
                          options={this.state.historyOptions}
                          ariaLabel="Show history"
                          selectedKey={this.state.historySelected?.key}
                          className={styles.historyDropdown + " dropDownCaret"}
                          onRenderOption={this._onRenderHistoryOption}
                          onRenderTitle={this._onRenderHistoryTitle}
                          onChange={(event, option?: IDropdownOption) => {
                            this.setState({
                              //historySelected: option,
                            });
                          }}
                        />
                      )}
                  </div>

                  {false && (
                    <div className={styles.toggleValidation}>
                      <Toggle
                        // label="Validation out of platform"
                        // checked={false}
                        className={
                          this.state.validationOutOfPlatform
                            ? styles.toggleValidation + " " + styles.is_checked
                            : styles.toggleValidation
                        }
                        onText={this._translation.validationOutPlatform}
                        offText={this._translation.validationOutPlatform}
                        checked={this.state.validationOutOfPlatform}
                        onChange={(event, checked?: boolean) => {
                          // this.setState({
                          //   validationOutOfPlatform: !this.state
                          //     .validationOutOfPlatform,
                          // });
                        }}
                      />
                    </div>
                  )}

                  {false && (
                    <>
                      {this.state.validationOutOfPlatform ? (
                        <div></div>
                      ) : (
                        <div className={styles.sendForApproval}>
                          <DefaultButton
                            alt={this._translation.sendForApproval}
                            ariaLabel={this._translation.sendForApproval}
                            className={"action_button"}
                            text={this._translation.sendForApproval}
                            allowDisabledFocus
                            onClick={(event) => {}}
                            disabled={true}
                            checked={true}
                            hidden={this.state.validationOutOfPlatform}
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>

                <div role="microConfiguration" className="d-flex w-100">
                  <div className="flex-grow-1 w-50 pr-2">
                    <div className={styles.label_RightDiv}>
                      {this._translation.activityCategory.toLocaleUpperCase()}
                    </div>
                    <div className={styles.value_RightDiv}>
                      {(!this.props.categories ||
                        this.props.categories.length === 0) && (
                        <SpinnerComponent
                          commonProps={this.props.commonProps}
                          relative={true}
                        ></SpinnerComponent>
                      )}
                      {!this.state.editCategory && (
                        <div
                          aria-label={
                            "Edit " + this._translation.activityCategory
                          }
                          className={
                            this.state.item?.permissions.canUpdate
                              ? styles.editableField
                              : ""
                          }
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              if (this.state.item?.permissions.canUpdate)
                                this.setState({ editCategory: true });
                            }
                          }}
                          tabIndex={0}
                          onClick={() => {
                            if (this.state.item?.permissions.canUpdate)
                              this.setState({ editCategory: true });
                          }}
                        >
                          {this.state.item?.categories &&
                            this.state.item?.categories.length > 0 && (
                              <div className={styles.text}>
                                {this.props.categories
                                  .filter((o) =>
                                    this.state.item?.categories.includes(+o.key)
                                  )
                                  .map((x) => x.text)
                                  .join(" - ")}
                              </div>
                            )}
                          {(!this.state.item?.categories ||
                            this.state.item?.categories.length == 0) && (
                            <div className={styles.text}>
                              {this._translation.selectCategory}
                            </div>
                          )}
                          {this.state.item?.permissions.canUpdate && (
                            <FontIcon
                              iconName="Edit"
                              className={"iconGet"}
                            ></FontIcon>
                          )}
                        </div>
                      )}
                      {!!this.state.editCategory &&
                        !!this.props.categories &&
                        this.props.categories.length > 0 && (
                          <Dropdown
                            className="w-100"
                            id="activityCategory"
                            aria-label={
                              "Choose " + this._translation.activityCategory
                            }
                            options={this.props.categories.filter(
                              (x) => +x.key > 0
                            )}
                            // multiSelect={true}
                            // selectedKeys={this.state.item?.categories || [0]}
                            selectedKey={this.state.item?.categories || [0]}
                            // onDismiss={this._categoryDismiss}
                            onChange={this._categoryOnChange}
                            styles={Constants.dropdownStyle}
                          />
                        )}
                    </div>
                  </div>
                  <div className="flex-grow-1 w-50 pl-2">
                    <div className={styles.label_RightDiv}>
                      {this._translation.activitySensibility.toLocaleUpperCase()}
                    </div>
                    <div className={styles.value_RightDiv}>
                      {(!this.props.sensibilities ||
                        this.props.sensibilities.length === 0) && (
                        <SpinnerComponent
                          commonProps={this.props.commonProps}
                          relative={true}
                        ></SpinnerComponent>
                      )}
                      {!this.state.editSensibility && (
                        <div
                          aria-label={
                            "Edit " + this._translation.activitySensibility
                          }
                          className={
                            this.state.item?.permissions.canUpdate
                              ? styles.editableField
                              : ""
                          }
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              if (this.state.item?.permissions.canUpdate)
                                this.setState({ editSensibility: true });
                            }
                          }}
                          tabIndex={0}
                          onClick={() => {
                            if (this.state.item?.permissions.canUpdate)
                              this.setState({ editSensibility: true });
                          }}
                        >
                          <div className={styles.text}>
                            {
                              this.props.sensibilities.find(
                                (o) =>
                                  o.key === (this.state.item?.sensibility || 0)
                              )?.text
                            }
                          </div>
                          {this.state.item?.permissions.canUpdate && (
                            <FontIcon
                              iconName="Edit"
                              className={"iconGet"}
                            ></FontIcon>
                          )}
                        </div>
                      )}
                      {!!this.state.editSensibility &&
                        !!this.props.sensibilities &&
                        this.props.sensibilities.length > 0 && (
                          <Dropdown
                            className="w-100"
                            ariaLabel={
                              "Choose " + this._translation.activitySensibility
                            }
                            id="activitySensibility"
                            options={this.props.sensibilities}
                            selectedKey={this.state.item?.sensibility || 0}
                            onChange={this._sensibilityOnChange}
                            styles={Constants.dropdownStyle}
                          />
                        )}
                    </div>
                  </div>
                </div>

                <br />

                <div role="microConfiguration" className="d-flex w-100">
                  <div className="flex-grow-1 w-50 pr-2">
                    <div className={styles.label_RightDiv}>
                      {this._translation.activityResponsibility.toLocaleUpperCase()}
                    </div>
                    <div className={styles.value_RightDiv}>
                      {!this.state.editResponsibility && (
                        <div
                          aria-label={
                            "Edit " + this._translation.activityResponsibility
                          }
                          className={
                            this.state.item?.permissions.canUpdate
                              ? styles.editableField
                              : ""
                          }
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              if (this.state.item?.permissions.canUpdate)
                                this.setState({ editResponsibility: true });
                            }
                          }}
                          tabIndex={0}
                          onClick={() => {
                            if (this.state.item?.permissions.canUpdate)
                              this.setState({ editResponsibility: true });
                          }}
                        >
                          {this.state.item?.responsibilities &&
                            this.state.item?.responsibilities.length > 0 && (
                              <div className={styles.text}>
                                {this.props.responsibilities
                                  .filter((o) =>
                                    this.state.item?.responsibilities.includes(
                                      +o.key
                                    )
                                  )
                                  .map((x) => x.text)
                                  .join(" - ")}
                              </div>
                            )}
                          {(!this.state.item?.responsibilities ||
                            this.state.item?.responsibilities.length == 0) && (
                            <div className={styles.text}>
                              {this._translation.selectResponsibility}
                            </div>
                          )}
                          {this.state.item?.permissions.canUpdate && (
                            <FontIcon
                              iconName="Edit"
                              className={"iconGet"}
                            ></FontIcon>
                          )}
                        </div>
                      )}
                      {!!this.state.editResponsibility &&
                        !!this.props.responsibilities &&
                        this.props.responsibilities.length > 0 && (
                          <Dropdown
                            className="w-100"
                            ariaLabel={
                              "Choose " +
                              this._translation.activityResponsibility
                            }
                            id="activityResponsibility"
                            options={this.props.responsibilities.filter(
                              (x) => +x.key > 0
                            )}
                            // multiSelect={true}
                            // selectedKeys={
                            //   this.state.item?.responsibilities || [0]
                            // }
                            selectedKey={
                              this.state.item?.responsibilities || [0]
                            }
                            // onDismiss={this._responsibilityOnDismiss}
                            onChange={this._responsibilityOnChange}
                            styles={Constants.dropdownStyle}
                          />
                        )}
                    </div>
                  </div>
                  <div className="flex-grow-1 w-50 pl-2">&nbsp;</div>
                </div>

                <DescriptionActivityViewComponent
                  commonProps={commonProps}
                  itemId={this.state.linkedMicro?.id}
                  item={this.state.item}
                  edit={
                    this.state.item
                      ? this.state.item.permissions.canUpdate
                      : false
                  }
                  updateDescription={this._updateDescription}
                />

                <hr className="ms-Grid-col ms-sm12" />
                <div
                  className={styles.ActivityComponents}
                  data-is-scrollable="true"
                >
                  {this.state.showDeliverables && (
                    <DeliverablesComponent
                      commonProps={commonProps}
                      activity={this.state.item}
                      onRenderModalHeader={this._renderActivityModalHeader}
                      showMessage={(
                        title: string,
                        description: string,
                        type: ToastNotificationType
                      ) => {
                        this.state.toastRefState?.current?.showMessage(
                          title,
                          description,
                          type
                        );
                      }}
                    ></DeliverablesComponent>
                  )}
                </div>
              </div>
            )}
            {this.state.isMacro && this.state.showDetails && (
              <div
                className={
                  styles.activity_details_center +
                  " ms-Grid-col ms-lg9 ms-md12 "
                }
              >
                <MacroActivityViewComponent
                  calendars={this.props.calendars}
                  commonProps={commonProps}
                  item={this.state.item}
                  edit={
                    this.state.item
                      ? this.state.item.permissions.canUpdate
                      : false
                  }
                  updateDescription={this._updateDescription}
                  updateTimingActivity={this._updateTimingActivity}
                />
              </div>
            )}
            {!this.state.showDetails && (
              <SpinnerComponent
                commonProps={commonProps}
                relative={false}
              ></SpinnerComponent>
            )}
            {
              <div>
                <NoteComponent
                  commonProps={commonProps}
                  item={this.state.item}
                />
              </div>
            }
            <Panel
              // data={null}
              onRenderHeader={this._onRenderParentDetailsHeader}
              isOpen={this.state.viewParent ? true : false}
              isHiddenOnDismiss={false}
              isLightDismiss={true}
              onDismiss={() => {
                this.setState({ viewParent: false });
              }}
              customWidth={window.innerWidth > 767 ? "55vw" : "100vw"}
              className={
                styles.nestedPanelActivityDetails +
                " nestedPanel " +
                styles.ActivityDetailsComponent
              }
              type={PanelType.custom}
              // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
              closeButtonAriaLabel={this._translation.close}
            >
              <hr className={styles.activity_details_separator} />
              <MacroActivityViewComponent
                calendars={this.props.calendars}
                commonProps={commonProps}
                item={this.state.parentMacro}
                edit={false}
              />
            </Panel>

            <Panel
              // data={null}
              onRenderHeader={this._onRenderLinkedMicroDetailsHeader}
              isOpen={this.state.viewLinkedMicro ? true : false}
              isHiddenOnDismiss={false}
              isLightDismiss={true}
              onDismiss={() => {
                this.setState({ viewLinkedMicro: false });
              }}
              customWidth={window.innerWidth > 767 ? "55vw" : "100vw"}
              className={
                styles.nestedPanelActivityDetails +
                " nestedPanel " +
                styles.ActivityDetailsComponent
              }
              type={PanelType.custom}
              // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
              closeButtonAriaLabel={this._translation.close}
            >
              <hr className={styles.activity_details_separator} />
              <MicroActivityViewComponent
                calendars={this.props.calendars}
                commonProps={commonProps}
                itemId={this.state.linkedMicro?.id}
              />
            </Panel>

            <Panel
              // data={null}
              headerText="Description"
              isOpen={this.state.viewDescription ? true : false}
              onDismiss={() => {
                this.setState({ viewDescription: false });
              }}
              isHiddenOnDismiss={false}
              isLightDismiss={true}
              customWidth={window.innerWidth > 767 ? "55vw" : "100vw"}
              className={
                styles.nestedPanelActivityDetails +
                " nestedPanel " +
                styles.ActivityDetailsComponent
              }
              type={PanelType.custom}
              // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
              closeButtonAriaLabel={this._translation.close}
            >
              <hr className={styles.activity_details_separator} />
              <div className={styles.activity_details_body + " row"}>
                <div
                  className={"ms-Grid-col ms-md12 " + styles.descriptionText}
                >
                  <div className={styles.descriptionEditor}>
                    {!this.state.editDescription && (
                      <div
                        className={styles.ActivityDescription}
                        dangerouslySetInnerHTML={{
                          __html:
                            this.state.descriptionTemp?.toString("html") &&
                            this._decodeHTMLEntities(
                              this.state.descriptionTemp?.toString("html")
                            )
                              ? this.state.descriptionTemp?.toString("html")
                              : "---",
                        }}
                      ></div>
                    )}
                    {this.state.editDescription && (
                      <RichTextEditor
                        // multiline
                        // autoAdjustHeight
                        // styles={Constants.textFieldAreaStyle}
                        className={styles.textDescription}
                        onChange={(newValue) => {
                          this.setState({
                            descriptionTemp: newValue
                              ? newValue
                              : RichTextEditor.createEmptyValue(), //event.currentTarget.value,
                          });
                        }}
                        value={this.state.descriptionTemp}
                        toolbarConfig={Constants.toolbarRichTextEditor}
                      ></RichTextEditor>
                    )}
                    <div className={styles.descriptionEdit}>
                      {!this.state.editDescription &&
                        this.state.item?.permissions.canUpdate && (
                          <div>
                            <FontIcon
                              aria-label={this._translation.editDescription}
                              iconName={"Edit"}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  this.setState({ editDescription: true });
                                }
                              }}
                              tabIndex={0}
                              onClick={() => {
                                this.setState({ editDescription: true });
                              }}
                            ></FontIcon>
                          </div>
                        )}
                    </div>
                  </div>
                  <div className={styles.descriptionActions}>
                    {this.state.editDescription &&
                      this.state.item?.permissions.canUpdate && (
                        <FontIcon
                          aria-label={this._translation.save}
                          iconName={"Accept"}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              this._updateDescription(
                                this.state.descriptionTemp.toString("html")
                              );
                            }
                          }}
                          tabIndex={0}
                          onClick={() => {
                            this._updateDescription(
                              this.state.descriptionTemp.toString("html")
                            );
                          }}
                        ></FontIcon>
                      )}
                    {this.state.editDescription &&
                      this.state.item?.permissions.canUpdate && (
                        <FontIcon
                          aria-label={this._translation.cancel}
                          iconName={"Cancel"}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              this.setState({
                                editDescription: false,
                                viewDescription: false,
                              });
                            }
                          }}
                          tabIndex={0}
                          onClick={() => {
                            this.setState({
                              editDescription: false,
                              viewDescription: false,
                            });
                          }}
                        ></FontIcon>
                      )}
                  </div>
                </div>
              </div>
            </Panel>
          </div>
        </div>
      </Panel>,
    ];
  }
}
