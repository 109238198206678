import * as React from "react";
import styles from "./UserGuideCardComponent.module.scss";
import { FontIcon, PersonaSize } from "@fluentui/react";
import { IBaseProperties } from "../../../../../../../Models/IBaseProperties";
import { UserGuide } from "../../../../../../../Models/UserGuide";
import { SupportTranslation } from "../../../../../../../Translations/Support.Translation";
import { ISupportClient } from "../../../../../../../Clients/ISupportClient";

export interface IUserGuideCardComponentProps extends IBaseProperties {
  userGuide: UserGuide;
  selectUserGuide: (userGuide: UserGuide) => void;
  refreshList: () => void;
}
export interface IUserGuideCardComponentState {
  isFav: boolean;
  userGuideSelected?: UserGuide;
}

export class UserGuideCardComponent extends React.Component<
  IUserGuideCardComponentProps,
  IUserGuideCardComponentState
> {
  private readonly _translation: SupportTranslation;
  private readonly _supportClient: ISupportClient;

  constructor(props: IUserGuideCardComponentProps) {
    super(props);

    this._translation = new SupportTranslation(
      this.props.commonProps.translation
    );
    this._supportClient = this.props.commonProps.clientCreator.createSupportClient();

    this.state = {
      isFav: this.props.userGuide.favourite,
      userGuideSelected: Object.assign({}, this.props.userGuide),
    };
  }

  private _getStyle(role: string): string {
    switch (role) {
      case "APPROVER":
        return styles.APPROVER;
      case "WORKING GROUP":
        return styles.WORKING_GROUP;
      case "EDITOR":
        return styles.EDITOR;
    }
    return "";
  }

  public render() {
    return (
      <div className={styles.activityCard}>
        <div
          className={styles.card_body}
          onClick={() => {
            if (this.state.userGuideSelected)
              this.props.selectUserGuide(this.state.userGuideSelected);
          }}
        >
          <div className={styles.card_body_header}>
            <h6
              className={
                styles.cardState +
                " " +
                " ms-Grid-col ms-xl8 ms-lg8 ms-md8 ms-sm8"
              }
            >
              <div
                className={
                  styles.divRole +
                  " " +
                  (this.state.userGuideSelected?.Role
                    ? this._getStyle(this.state.userGuideSelected.Role)
                    : "")
                }
              >
                {this.state.userGuideSelected?.Role
                  ? this.state.userGuideSelected.Role
                  : ""}
              </div>
              {/* <FontIcon
                iconName={
                  this.props.contact.favourite
                    ? "FavoriteStarFill"
                    : "FavoriteStar"
                }
                className={styles.iconFav}
              ></FontIcon> */}
            </h6>
            <h6
              className={
                styles.cardDate +
                " " +
                styles.dateLabel +
                " ms-Grid-col ms-xl4 ms-lg4 ms-md4 ms-sm4"
              }
            >
              <FontIcon
                iconName={
                  this.state.isFav ? "FavoriteStarFill" : "FavoriteStar"
                }
                className={styles.iconFav}
                onClick={(e) => {
                  if (this.state.isFav) {
                    this._supportClient.RemoveFavourites(
                      this.state.userGuideSelected
                        ? [this.state.userGuideSelected.Id]
                        : []
                    );

                    this.setState({ isFav: false });
                  } else {
                    this._supportClient.AddFavourites(
                      this.state.userGuideSelected
                        ? [this.state.userGuideSelected.Id]
                        : []
                    );

                    this.setState({ isFav: true });
                  }

                  this.props.refreshList();
                }}
              ></FontIcon>
              {/* <span className="">
                {Utility.FormatDate(this.props.contact.lastUpdate)}
              </span> */}
            </h6>
          </div>
          <div className={styles.card_body_content}>
            <div className={"ms-Grid-col ms-xl2 ms-lg2 ms-md2 ms-sm2"}>
              <FontIcon iconName={"PDF"} className={styles.iconPdf}></FontIcon>
            </div>
            <div className={"ms-Grid-col ms-xl10 ms-lg10 ms-md10 ms-sm10"}>
              <h5
                className={
                  styles.card_title + " " + styles.truncate_overflow_parent
                }
                title={this.state.userGuideSelected?.title}
                onClick={() => {
                  window.open(this.props.userGuide.URL);
                }}
              >
                {this.state.userGuideSelected?.title}
              </h5>
              <div className={styles.Language}>
                {this.state.userGuideSelected?.language
                  ? this.state.userGuideSelected.language
                      .toString()
                      .toUpperCase()
                  : ""}
              </div>
              <div
                className={styles.MainText}
                title={this.state.userGuideSelected?.mainText}
              >
                {this.state.userGuideSelected?.mainText}
              </div>
            </div>
          </div>
          <div className={styles.card_body_footer}></div>
        </div>
      </div>
    );
  }
}
