import React from "react";
import { CustomCalendarTranslation } from "./CustomCalendar.Translation";
import { Constants } from "../../../Utility/Constants";
import "../../../Utility/DateExtensions";
import "./CustomCalendar.scss";
import {
  Calendar,
  DayOfWeek,
  IDatePickerStrings,
  mergeStyleSets,
  TextField,
  ActionButton,
} from "@fluentui/react";
import { IBaseProperties } from "../../../Models/IBaseProperties";
import { DateHelper } from "../../../Models/DateHelper";

export interface ICustomCalendardProps extends IBaseProperties {
  value?: Date;
  minDate?: Date | undefined;
  maxDate?: Date | undefined;
  onChange: ((newValue?: Date) => void) | undefined;
  onDismiss?: (() => void) | undefined;
}
export interface ICustomCalendardState {
  date: Date | undefined;
  showCalendar: boolean;
  value?: Date;
}

const controlClass = mergeStyleSets({
  control: {
    margin: "0 0 0px 0",
    maxWidth: "315px",
  },
  wrap: {
    padding: "100px",
  },
});

export class CustomCalendar extends React.Component<
  ICustomCalendardProps,
  ICustomCalendardState
> {
  private node: any;
  private readonly _translation: CustomCalendarTranslation;

  constructor(props: ICustomCalendardProps) {
    super(props);
    this._translation = new CustomCalendarTranslation(
      props.commonProps.translation
    );

    if (props.value) {
      this.state = {
        date: props.value,
        showCalendar: false,
        value: props.value,
      };
    } else {
      this.state = {
        date: undefined,
        showCalendar: false,
        value: undefined,
      };
    }

    this.bindEvents();
  }

  componentDidUpdate(prevProps: ICustomCalendardProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({
        date: this.props.value,
      });
    }
  }

  private getDayPickerStrings(): IDatePickerStrings {
    const capitalize = (s: string) => {
      if (typeof s !== "string") return "";
      return s.charAt(0).toUpperCase() + s.slice(1);
    };
    const monthNames = () => DateHelper.monthName.map((m) => capitalize(m));
    const shortDays = () =>
      DateHelper.weekDayShortName.map((m) => capitalize(m));
    return {
      months: monthNames(),
      shortMonths: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],

      days: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],

      // shortDays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      shortDays: shortDays(),
      goToToday: "Go to today",
      prevMonthAriaLabel: "Go to previous month",
      nextMonthAriaLabel: "Go to next month",
      prevYearAriaLabel: "Go to previous year",
      nextYearAriaLabel: "Go to next year",
      closeButtonAriaLabel: "Close date picker",
      monthPickerHeaderAriaLabel: "{0}, select to change the year",
      yearPickerHeaderAriaLabel: "{0}, select to change the month",
    };
  }
  private bindEvents() {
    this.onSelectDate = this.onSelectDate.bind(this);
    this.toggleCalendar = this.toggleCalendar.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    this.hideCalendar = this.hideCalendar.bind(this);
    this.resetCalendar = this.resetCalendar.bind(this);
    this.acceptDate = this.acceptDate.bind(this);
  }

  private get dateSelected(): boolean {
    return this.state.date ? true : false;
  }

  date(): Date | undefined {
    let date: Date | undefined;
    if (this.state.date) {
      date = this.state.date;
    } else if (this.props.minDate) {
      date = this.props.minDate;
    }

    return date;
  }

  getDate(): Date | undefined {
    return this.state.date;
  }

  private getLocale() {
    return navigator.languages && navigator.languages.length
      ? navigator.languages[0]
      : navigator.language;
  }

  private onSelectDate(date: Date | null | undefined): void | undefined {
    const newValue = date ? date : undefined;
    this.setState({
      date: newValue,
    });
  }

  private dateStringValue(): string | undefined {
    const date = this.date();
    if (date) {
      const locale = "it"; //this.getLocale();
      const format: Intl.DateTimeFormatOptions = {
        day: "numeric",
        month: "2-digit",
        year: "numeric",
      };

      const sDate = date.toLocaleDateString(locale, format);
      return `${sDate}`;
    } else {
      return undefined;
    }
  }

  render() {
    const jsxCalendar = (
      <Calendar
        className={controlClass.control}
        firstDayOfWeek={DayOfWeek.Monday}
        strings={this.getDayPickerStrings()}
        showWeekNumbers={false}
        showMonthPickerAsOverlay={false}
        isMonthPickerVisible={false}
        showGoToToday={false}
        value={this.date()!}
        minDate={this.props.minDate}
        maxDate={this.props.maxDate}
        onSelectDate={this.onSelectDate}
      ></Calendar>
    );

    const showCalendar = this.state.showCalendar;

    const jsxCloseButton = (
      <ActionButton
        iconProps={Constants.iCancel}
        aria-label="Close"
        onClick={this.hideCalendar}
        title={this._translation.close}
      ></ActionButton>
    );

    const jsxResetButton = (
      <ActionButton
        // iconProps={Constants.iDeleteCalendar}
        onClick={this.resetCalendar}
        aria-label="Reset"
        title={this._translation.reset}
        text="Reset"
      ></ActionButton>
    );

    const jsxAcceptButton = (
      <ActionButton
        iconProps={Constants.iAccept}
        disabled={!this.dateSelected}
        aria-label="Confirm"
        onClick={this.acceptDate}
        title={this._translation.apply}
      ></ActionButton>
    );

    return (
      <React.Fragment>
        <div
          className="calendardContainer"
          ref={(node) => {
            this.node = node;
          }}
        >
          <div
            className=""
            data-automationid="customCalendardLabel"
            onClick={this.toggleCalendar}
          >
            {/* <div className="" data-automationid="CustomCalendardLabel"> */}
            <TextField
              role="combobox"
              iconProps={Constants.iRangeCalendar}
              readOnly
              placeholder={this._translation.select}
              value={this.dateStringValue()}
              styles={Constants.textFieldDateRangeStyle}
            ></TextField>
          </div>
          {showCalendar && (
            <React.Fragment>
              <div
                className="customCalendardContainer"
                data-automationid="customCalendardContainer"
              >
                <div>{jsxCalendar}</div>
                <div></div>
                <div className="customCalendard-toolbar">
                  <div>{jsxResetButton}</div>
                  <div>
                    {jsxAcceptButton}
                    {jsxCloseButton}
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    );
  }

  hideCalendar(
    event: React.MouseEvent<
      | HTMLAnchorElement
      | HTMLButtonElement
      | HTMLDivElement
      | import("@fluentui/react").BaseButton
      | import("@fluentui/react").Button
      | HTMLSpanElement,
      MouseEvent
    >
  ): void | undefined {
    document.removeEventListener("click", this.handleOutsideClick, false);
    this.setState({ showCalendar: false });
    this.setDate();
    if (this.props.onDismiss) this.props?.onDismiss();
  }

  private setDate() {
    if (this.props.value) {
      this.setState({
        date: this.props.value,
        showCalendar: false,
        value: this.props.value,
      });
    } else {
      this.setState({
        date: undefined,
        showCalendar: false,
        value: undefined,
      });
    }
  }

  private acceptDate(
    event: React.MouseEvent<
      | HTMLAnchorElement
      | HTMLButtonElement
      | HTMLDivElement
      | import("@fluentui/react").BaseButton
      | import("@fluentui/react").Button
      | HTMLSpanElement,
      MouseEvent
    >
  ): void | undefined {
    this.hideCalendar(event);
    if (this.props.onChange && this.state.date) {
      let value: Date;
      let { date } = this.state;

      value = date;

      this.props.onChange(value);
    }
  }

  private resetCalendar(
    event: React.MouseEvent<
      | HTMLAnchorElement
      | HTMLButtonElement
      | HTMLDivElement
      | import("@fluentui/react").BaseButton
      | import("@fluentui/react").Button
      | HTMLSpanElement,
      MouseEvent
    >
  ): void | undefined {
    document.removeEventListener("click", this.handleOutsideClick, false);
    this.setState({ showCalendar: false, date: undefined }, () => {
      if (this.props.onChange) this.props.onChange(undefined);
    });
  }

  toggleCalendar() {
    const currentState = this.state.showCalendar;
    // disabled click outside Custom calendar
    if (!currentState) {
      document.addEventListener("click", this.handleOutsideClick, false);
    } else {
      document.removeEventListener("click", this.handleOutsideClick, false);
    }

    this.setState({ showCalendar: !currentState });
  }

  handleOutsideClick(e: MouseEvent) {
    if (this.node && e) {
      if (!this.node.contains(e.target)) this.toggleCalendar();
    }
  }
}
