import * as React from "react";
import styles from "./SupportHeaderComponent.module.scss";
import { FontIcon } from "@fluentui/react";
import "url-search-params-polyfill";
import { IBaseProperties } from "../../../../../Models/IBaseProperties";
import { SupportPagesEnum } from "../../../../../Models/SupportPage";
import { SupportTranslation } from "../../../../../Translations/Support.Translation";

export interface ISupportHeaderComponentProps extends IBaseProperties {
  page: SupportPagesEnum;
  // header: Header;
  changePage: (p: SupportPagesEnum) => void;
}

export interface ISupportHeaderComponentState {
  // persona: IPersonaComponentItem[];
}

export class SupportHeaderComponent extends React.Component<
  ISupportHeaderComponentProps,
  ISupportHeaderComponentState
> {
  private readonly _translation: SupportTranslation;
  
  constructor(props: ISupportHeaderComponentProps) {
    super(props);

    this._translation = new SupportTranslation(
      this.props.commonProps.translation
    );

    this.state = {};
  }

  private _getStyle(icon: string): string {
    switch (icon) {
      case "MSNVideos":
        return styles.MSNVideos;
      case "PDF":
        return styles.PDF;
      case "OfficeChat":
        return styles.OfficeChat;
    }

    return "";
  }

  public render() {
    var title: string = "";
    var text: string = "";
    var icon: string = "";
    var supportVisible: boolean = true;

    switch (this.props.page) {
      case SupportPagesEnum.Home:
        title = this._translation.headerSupport;
        text = this._translation.textSupport;
        icon = "";
        supportVisible = false;
        break;
      case SupportPagesEnum.VideoTutorials:
        title = this._translation.headerVideoTutorials;
        text = this._translation.textVideoTutorials;
        icon = "MSNVideos";
        break;
      case SupportPagesEnum.UserGuide:
        title = this._translation.headerUserGuides;
        text = this._translation.textUserGuides;
        icon = "PDF";
        break;
      case SupportPagesEnum.Faq:
        title = this._translation.headerFAQ;
        text = this._translation.textFAQ;
        icon = "OfficeChat";
        break;
    }

    return (
      <div className={styles.SupportHeaderComponent}>
        <div className={styles.Heading}>{title}</div>
        <div className={styles.Text}>{text}</div>
        {supportVisible && (
          <div className={styles.gridContainer}>
            <div
              className={styles.gridItem}
              onClick={() => this.props.changePage(SupportPagesEnum.Home)}
            >
              <FontIcon
                iconName={"ChevronLeftMed"}
                className={styles.iconBack}
              />
              <div className={styles.textBack}>
                {this._translation.backToSupportText}
              </div>
            </div>
          </div>
        )}

        {icon && (
          <div className={styles.IconBackground}>
            <FontIcon iconName={icon} className={this._getStyle(icon)} />
          </div>
        )}
      </div>
    );
  }
}
