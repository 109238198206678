import * as React from "react";
import { MacroTranslation } from "./Macro.Translation";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { Spoc } from "./Spoc";
import { HeadOfActivity } from "./HeadOfActivity";
import "./Macro.scss";
import {
  Checkbox,
  DefaultButton,
  Dropdown,
  DropdownMenuItemType,
  IBasePicker,
  IDropdownOption,
  ITag,
  Label,
  TextField,
  PrimaryButton,
  ActionButton,
  IStackTokens,
  IStackStyles,
  Text,
  Stack as Stack2,
  Modal,
  IconButton,
  Spinner,
  SpinnerSize,
  MessageBar,
  MessageBarType,
  IDragOptions,
  ContextualMenu,
} from "@fluentui/react";
import { FormEvent } from "react";
import { BriefClient } from "../../../Clients/BriefClient";
import { IMacro, IMediaPlan } from "../../../Models/Activity";
import { RangeCalendar } from "../../Shared/RangeCalendar/RangeCalendar";
import { CustomLabel } from "../../Shared/CustomLabel";
import { BriefDTO } from "../../../Models/DTO/BriefDTO";
import { Constants } from "../../../Utility/Constants";
import { IDateRangeValue } from "../../Shared/RangeCalendar/IDateRangeValue";
import { MediaPlan } from "./MediaPlan";
import { TFunction } from "i18next";
import {
  IBaseProperties,
  ICommonProperties,
} from "../../../Models/IBaseProperties";
import { IMacroClient } from "../../../Clients/IMacroClient";
import { Utility } from "../../../Utility/Utility";
import { DeliverableFileTypes } from "../../../Models/Deliverable";
import RichTextEditor, { EditorValue } from "react-rte";
import { IBriefDetail } from "../../../Models/Brief";
import { ICalendar } from "../../../Models/ICalendar";

const dragOptions: IDragOptions = {
  moveMenuItemText: "Move",
  closeMenuItemText: "Close",
  menu: ContextualMenu,
  dragHandleSelector: ".panelHeader",
};

const stackTokens: IStackTokens = {
  childrenGap: 4,
};

const stackStyles: IStackStyles = {
  root: {
    marginTop: 20,
  },
};

const onRenderCustomLabel = (
  commonProps: ICommonProperties,
  controlid: string,
  label: string,
  tooltip: string,
  required?: boolean,
  disableTooltip?: boolean
) => (p: any) => {
  return (
    <CustomLabel
      commonProps={commonProps}
      controlId={controlid}
      label={label}
      tooltiptext={tooltip}
      required={required}
      disableTooltip={disableTooltip}
    ></CustomLabel>
  );
};

const panelStyle = {
  width: "100%",
  height: "100%",
};

export interface IMacroProps extends IBaseProperties {
  className: string | undefined;
  hideModalCallback: Function;
  brief?: IBriefDetail;
  onSave: (newMacro: IMacro) => void;
  slotinfo?: any;
}
export interface IMacroState {
  isSaving: boolean;
  associatedBrief: boolean;
  activityType: string;
  mediaPlanCheckboxStatus: boolean;
  spocId?: string;
  headOfActivity?: string;
  selectedHeadOfActivity: ITag[];
  name?: string;
  description: EditorValue;
  dateFrom?: Date;
  dateTo?: Date;
  rangeDateValue: IDateRangeValue | undefined;
  briefId?: number;
  briefTitle?: string;
  briefs: BriefDTO[];
  briefsLoaded: boolean;
  file?: File;
  showMediaPlan: boolean;
  showMacro: boolean;
  showError: boolean;
  messageError?: string;
  mediaPlan: IMediaPlan | undefined;
  selectedSpoc: ITag[];
  messageFunction?: TFunction;
  calendarOptions: IDropdownOption[];
  calendarsLoaded: boolean;
  calendar: number;
  showCalendarError: boolean;
  showTitleError: boolean;
}

export class Macro extends React.Component<IMacroProps, IMacroState> {
  private readonly _translation: MacroTranslation;
  private readonly _macroClient: IMacroClient;
  private readonly _allExtensions = DeliverableFileTypes.flatMap(
    (fkey) => fkey.associatedExtensions
  );
  private calendarItems: IDropdownOption[] = [];

  private readonly _acceptedExtensions = this._allExtensions
    .map((t) => {
      return `.${t}`;
    })
    .join(", ");

  constructor(props: IMacroProps) {
    super(props);

    this._macroClient = props.commonProps.clientCreator.createMacroClient();

    var rangeDateValue: IDateRangeValue | undefined = undefined;
    if (!!this.props.slotinfo) {
      // set date range
      rangeDateValue = {
        fromDate: this.props.slotinfo.start,
        toDate: this.props.slotinfo.end,
      };
    }
    this._translation = new MacroTranslation(props.commonProps.translation);

    this.state = {
      isSaving: false,
      associatedBrief: false,
      activityType: "macro",
      mediaPlanCheckboxStatus: false,
      headOfActivity: undefined,
      name: "",
      description: RichTextEditor.createEmptyValue(),
      briefs: [],
      briefsLoaded: false,
      file: undefined,
      showMediaPlan: false,
      showMacro: true,
      showError: false,
      mediaPlan: undefined,
      selectedHeadOfActivity: [],
      selectedSpoc: [],
      rangeDateValue: rangeDateValue,
      calendarOptions: [{ key: 0, text: this._translation.loading }],
      calendarsLoaded: false,
      calendar: 0,
      showCalendarError: false,
      showTitleError: false
    };
    this.bindEvents();
  }

  private bindEvents() {
    this.titleChange = this.titleChange.bind(this);
    this.descriptionChange = this.descriptionChange.bind(this);
    this.activityTypeOnChange = this.activityTypeOnChange.bind(this);
    this.mediaPlanOnChange = this.mediaPlanOnChange.bind(this);
    this.calendarChange = this.calendarChange.bind(this);
    this.save = this.save.bind(this);
    this.showMediaPlan = this.showMediaPlan.bind(this);
    this.closeMediaPlanPanel = this.closeMediaPlanPanel.bind(this);
    this.onChangeMediaPlan = this.onChangeMediaPlan.bind(this);
    this.pickerSpocChange = this.pickerSpocChange.bind(this);
    this.headOfActivityChange = this.headOfActivityChange.bind(this);
    this.closePanel = this.closePanel.bind(this);
    this.associatedBriefChange = this.associatedBriefChange.bind(this);
    this.briefOnChange = this.briefOnChange.bind(this);
    this.fileChange = this.fileChange.bind(this);
    this.rangeCalendarChange = this.rangeCalendarChange.bind(this);
    this.removeFile = this.removeFile.bind(this);
    this.closeMessageBarError = this.closeMessageBarError.bind(this);
  }

  async componentDidMount(): Promise<void> {
    const briefClient = new BriefClient();
    const briefCollection = await briefClient.getBriefsApprovedAsync();
    await this.loadCalendarsAsync();

    if (briefCollection) {
      this.setState({ briefs: briefCollection, briefsLoaded: true });
    } else {
      this.setState({ briefs: [], briefsLoaded: true });
    }
  }

  async componentWillUnmount(): Promise<void> {
    document.getElementById("newActivityButttonForBrief")?.focus();
  }

  private readonly spocPicker = React.createRef<IBasePicker<ITag>>();

  //Activity type collection used by drop downlist
  private readonly activityTypeOptions: IDropdownOption[] = [
    { key: "macro", text: "Macro", itemType: DropdownMenuItemType.Normal },
    { key: "micro", text: "Micro", itemType: DropdownMenuItemType.Normal },
  ];

  //Activity type collection used by drop downlist
  private get mediaPlanOptions(): IDropdownOption[] {
    return [
      {
        key: 0,
        text: this._translation.no,
        itemType: DropdownMenuItemType.Normal,
      },
      {
        key: 1,
        text: this._translation.yes,
        itemType: DropdownMenuItemType.Normal,
      },
    ];
  }

  private get getIMacro(): IMacro | undefined {
    let macro: IMacro | undefined;

    const {
      name,
      calendar,
      description,
      dateFrom,
      dateTo,
      headOfActivity,
      spocId,
      briefId,
      briefTitle,
      file,
      associatedBrief,
      showCalendarError,
      showTitleError
    } = this.state;

    if (name && calendar && dateFrom && dateTo) {
      let lBriefId = this.props.brief ? this.props.brief.id : briefId;
      let lBriefTitle = this.props.brief ? this.props.brief.title : briefTitle;
      let lFile = file;
      //if the brief is not checked then neither of the two properties should be stored
      if (!associatedBrief && lBriefId && lBriefTitle && file) {
        lBriefId = undefined;
        lBriefTitle = undefined;
        lFile = undefined;
      }

      macro = {
        name: name,
        calendar: calendar,
        isMacro: true,
        description: description.toString("html"),
        dateFrom: dateFrom,
        dateTo: dateTo,
        headOfActivityUPN: headOfActivity,
        spocUPN: spocId,
        // briefId: lBriefId,
        linkedItemBrief:
          lBriefId && lBriefTitle
            ? {
              id: lBriefId,
              title: lBriefTitle,
            }
            : undefined,
        file: lFile,
        permissions: Utility.getDefaultActivityPermissions(),
      };
    }

    return macro;
  }

  private async loadCalendarsAsync() {
    try {
      const actClient = this.props.commonProps.clientCreator.createActivityClient();
      var calendarItems = await actClient.getCalendars();
      var calendarsPermissions =
        (await actClient.getCalendarsPermissions()) || [];
      var canCreateCalendarIds = calendarsPermissions
        .filter((p) => p.canCreate)
        .map((p) => p.calendarId);

      if (!!calendarItems && calendarItems.length > 0) {
        //filter by permissions
        calendarItems = calendarItems.filter((c) =>
          canCreateCalendarIds.some((p) => p === c.key)
        );

        this.calendarItems = calendarItems.map(
          (o: ICalendar): IDropdownOption => ({
            key: o.key,
            text: o.value,
            itemType: DropdownMenuItemType.Normal,
          })
        );
        const cal = this.state.calendar
          ? this.state.calendar
          : (this.calendarItems[0].key as number);
        this.setState({
          calendarOptions: this.calendarItems,
          calendarsLoaded: true,
          calendar: cal,
        });
      } else {
        this.setState({
          showError: true,
          messageError: this._translation.errorInLoadCalendar,
        });
      }
    } catch (error) {
      console.error(error);
      this.setState({
        showError: true,
        messageError: this._translation.errorInLoadCalendar,
      });
    }
  }

  private get mediaPlantSelectedOption() {
    return this.state.mediaPlanCheckboxStatus ? 1 : 0;
  }

  private headOfActivityChange(selectedTags: ITag[], id?: string): void {
    this.setState({ headOfActivity: id, selectedHeadOfActivity: selectedTags });
  }

  private pickerSpocChange(selected: ITag[], spocId?: string): void {
    this.setState({ spocId: spocId, selectedSpoc: selected });
  }

  private readonly BriefDTOToIDropdownOption = (
    item: BriefDTO
  ): IDropdownOption =>
  ({
    key: item.id,
    text: item.name,
    itemType: DropdownMenuItemType.Normal,
  } as IDropdownOption);

  private get canSave(): boolean {
    const { name, dateFrom, dateTo, calendar } = this.state;

    return name && calendar && dateFrom && dateTo ? true : false;
  }

  private get Briefs(): IDropdownOption[] {
    if (this.state.briefsLoaded) {
      let values = this.state.briefs.map(this.BriefDTOToIDropdownOption);
      const emptyItems = {
        key: -1,
        text: "",
        itemType: DropdownMenuItemType.Normal,
      } as IDropdownOption;
      //add an empty item
      values.unshift(emptyItems);
      return values;
    } else {
      return [];
    }
  }

  private mediaPlanOnChange(
    event: FormEvent<HTMLDivElement>,
    option?: IDropdownOption | undefined,
    index?: number | undefined
  ): void | undefined {
    if (option) {
      this.setState({
        mediaPlanCheckboxStatus: option.key === 0 ? false : true,
      });
    }
  }

  calendarChange(
    ev: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption
  ) {
    this.setState({
      calendar: !!option?.key ? +option.key : 0,
      selectedSpoc: [],
      selectedHeadOfActivity: []
    });
  }

  private closePanel(): void {
    this.props.hideModalCallback();
  }

  private titleChange(
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined
  ) {
    this.setState({ name: newValue });
    if (!newValue) {
      this.setState({
        showTitleError: true
      });
    }
    else {
      this.setState({
        showTitleError: false
      });
    }
  }

  private descriptionChange(
    //event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue: EditorValue
  ) {
    this.setState({ description: newValue });
  }

  private activityTypeOnChange(
    event: FormEvent<HTMLDivElement>,
    option?: IDropdownOption | undefined,
    index?: number | undefined
  ): void | undefined {
    if (option) {
      const actType: string = option.key.toString();
      this.setState({ activityType: actType }, () => { });
    }
  }

  private briefOnChange(
    event: FormEvent<HTMLDivElement>,
    option?: IDropdownOption | undefined,
    index?: number | undefined
  ): void | undefined {
    if (option) {
      const brief = option.key as number;
      const value = brief !== -1 ? brief : undefined;
      this.setState({ briefId: value, briefTitle: option.text });
    }
  }

  private get genericErrorMessage(): string {
    return this._translation.genericErrorInSave;
  }

  private async saveMacroAsync() {
    try {

      if (!this.state.name) {
        this.setState({
          showTitleError: true
        });
      }

      if (!this.state.dateFrom || !this.state.dateTo) {
        this.setState({
          showCalendarError: true
        });
      }

      const data = this.getIMacro;

      if (data) {
        const retValue = await this._macroClient.saveMacroAsync(data);

        if (retValue) {
          this.props.onSave(data); //notify to parent component
          this.setState({ messageError: undefined, showError: false });
        } else {
          this.setState({
            messageError: this.genericErrorMessage,
            showError: true,
          });
        }
      }
    } catch (error) {
      console.error(error);
      this.setState({
        messageError: this.genericErrorMessage,
        showError: true,
      });
    } finally {
      this.setState({ isSaving: false });
    }
  }

  private save(
    event: React.MouseEvent<
      | HTMLAnchorElement
      | HTMLButtonElement
      | HTMLDivElement
      | import("@fluentui/react").BaseButton
      | import("@fluentui/react").Button
      | HTMLSpanElement,
      MouseEvent
    >
  ): void | undefined {
    event.preventDefault();

    this.setState({ isSaving: true }, () => {
      this.saveMacroAsync();
    });
  }

  private showMediaPlan(
    event: React.MouseEvent<
      | HTMLAnchorElement
      | HTMLButtonElement
      | HTMLDivElement
      | import("@fluentui/react").BaseButton
      | import("@fluentui/react").Button
      | HTMLSpanElement,
      MouseEvent
    >
  ): void | undefined {
    event.preventDefault();
    this.setState({ showMediaPlan: true, showMacro: false });
  }

  private renderBrief(): JSX.Element {
    const file = this.state.file;
    return (
      <React.Fragment>
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
            {((!this.state.briefId && !this.state.file) ||
              this.state.briefId) && (
                <>
                  <CustomLabel
                    commonProps={this.props.commonProps}
                    controlId="brief"
                    label={this._translation.selectExistingBrief}
                    tooltiptext="Select existing brief"
                    required={false}
                    disableTooltip={false}
                  ></CustomLabel>
                  <Dropdown
                    id="brief"
                    options={
                      this.Briefs || [{ key: 0, text: this._translation.loading }]
                    }
                    placeholder={
                      !this.state.briefsLoaded
                        ? this._translation.loadingBrief
                        : this._translation.selectBrief
                    }
                    onChange={this.briefOnChange}
                    selectedKey={this.state.briefId}
                    styles={Constants.dropdownStyle}
                  />
                </>
              )}
          </div>
        </div>
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
            {((!this.state.briefId && !this.state.file) || this.state.file) && (
              <>
                <Stack2
                  horizontal
                  verticalAlign="center"
                  tokens={stackTokens}
                  styles={stackStyles}
                >
                  <Label styles={Constants.labelStyle}>
                    {this._translation.or}
                  </Label>
                  <input
                    id="fileBrief"
                    type="file"
                    title={this._translation.chooseFile}
                    onChange={this.fileChange}
                    accept={this._acceptedExtensions}
                  />
                  <Label htmlFor="fileBrief" styles={Constants.labelFileStyle}>
                    {this._translation.uploadFile}
                  </Label>
                  {file && (
                    <>
                      <Label styles={Constants.labelStyle}>File:</Label>
                      <Text nowrap styles={Constants.fileNameStyles}>
                        {file.name}
                        <ActionButton
                          alt="Remove File"
                          iconProps={Constants.iDelete}
                          onClick={this.removeFile}
                        ></ActionButton>
                      </Text>
                    </>
                  )}
                </Stack2>
              </>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }

  fileChange(event: React.ChangeEvent<HTMLInputElement>): void | undefined {
    if (event.target.files && event.target.files.length > 0) {
      const sFile: File = event.target.files[0];
      const fileName = sFile.name;
      const fileExtension = fileName.substring(
        fileName.lastIndexOf(".") + 1,
        fileName.length
      );

      if (
        this._allExtensions.some(
          (t) => t.toLowerCase() === fileExtension.toLowerCase()
        )
      ) {
        this.setState({ file: sFile });
      } else {
        this.setState({ messageError: "Invalid file type", showError: true });
      }
    }
  }

  private closeMediaPlanPanel() {
    this.setState({
      showMediaPlan: false,
      showMacro: true,
    });
  }

  private onChangeMediaPlan(value: IMediaPlan | undefined) {
    this.setState({
      mediaPlan: value,
    });
    this.closeMediaPlanPanel();
  }

  private closeMessageBarError() {
    this.setState({ showError: false });
  }

  private rangeCalendarChange(newValue?: IDateRangeValue | undefined) {
    if (newValue) {
      this.setState({
        dateFrom: newValue.fromDate,
        dateTo: newValue.toDate,
        rangeDateValue: newValue,
        showCalendarError: false
      });

    } else {
      this.setState({
        dateFrom: undefined,
        dateTo: undefined,
        rangeDateValue: undefined,
        showCalendarError: true,
      });
    }
  }

  private removeFile() {
    this.setState({ file: undefined });
  }

  private associatedBriefChange(
    ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
    checked?: boolean | undefined
  ) {
    const checkValue: boolean = checked ? true : false;
    this.setState({ associatedBrief: checkValue });
  }

  renderMediaPlan() {
    const macro = this.getIMacro;
    if (macro) {
      return (
        <Modal
          isOpen={this.state.showMediaPlan}
          isModeless={false}
          closeButtonAriaLabel={this._translation.close}
          dragOptions={dragOptions}
          containerClassName={Constants.activityModalPanelStyle.container}
        >
          {
            <div className="panelHeader" aria-label="activities panel">
              <span>{this._translation.addMediaPlanTitle}</span>
              <div>
                <IconButton
                  alt="Title"
                  style={{ float: "right" }}
                  iconProps={Constants.iCancel}
                  aria-label="Close"
                  ariaLabel={this._translation.close}
                  onClick={this.closeMediaPlanPanel}
                />
              </div>
            </div>
          }
          <div>
            <MediaPlan
              commonProps={this.props.commonProps}
              macro={macro}
              className={Constants.activityModalPanelStyle.body}
              onCancel={this.closeMediaPlanPanel}
              onSave={this.props.onSave}
              onChange={this.onChangeMediaPlan}
            ></MediaPlan>
          </div>
        </Modal>
      );
    }
  }

  render() {
    const isBriefVisibile = this.state.associatedBrief;
    const showMediaPlan = this.state.mediaPlanCheckboxStatus;
    const showMacro = this.state.showMacro;
    const showError = this.state.showError;

    let calendarError = undefined;
    let titleError = undefined;

    if (this.state.showCalendarError) {
      calendarError = <div id="calendarErrorMessage" role="alert"><p className="calendarErrorAlert">Calendar date are required</p></div>;
    }
    if (this.state.showTitleError) {
      titleError = <div id="titleErrorMessage" role="alert"><p className="errorAlert">Event Title is required</p></div>;
    }

    const jsxDescription = (
      // <TextField
      //   id="activityDescription"
      //   multiline={true}
      //   styles={Constants.textFieldAreaStyle}
      //   placeholder={this.state.description}
      //   value={this.state.description}
      //   onChange={this.descriptionChange}
      //   onRenderLabel={onRenderCustomLabel(
      //     this.props.commonProps,
      //     "activityDescription",
      //     this._translation.description,
      //     "Activity description tool tip",
      //     false,
      //     true
      //   )}
      // ></TextField>
      <div role="ActivityDescription" id="activityDescription">
        <RichTextEditor
          // id="activityDescription"
          // multiline={true}
          // styles={Constants.textFieldAreaStyle}
          placeholder={this._translation.description}
          value={this.state.description}
          onChange={this.descriptionChange}
          toolbarConfig={Constants.toolbarRichTextEditor}
          className={"RichTextboxCustom"}
        // onRenderLabel={onRenderCustomLabel(
        //   this.props.commonProps,
        //   "activityDescription",
        //   this._translation.description,
        //   "Activity description tool tip",
        //   false,
        //   true
        // )}
        ></RichTextEditor>
      </div>
    );

    const jsxActivityCalendar = (
      <Dropdown
        id="activityCalendar"
        options={this.state.calendarOptions}
        placeholder={
          !this.state.calendarsLoaded
            ? this._translation.loadingCalendar
            : this._translation.selectCalendar
        }
        onChange={this.calendarChange}
        selectedKey={this.state.calendar}
        styles={Constants.dropdownStyle}
        onRenderLabel={onRenderCustomLabel(
          this.props.commonProps,
          "activityCalendar",
          this._translation.activityCalendar,
          this._translation.activityCalendarTooltip,
          true,
          false
        )}
      />
    );

    // const jsxMediaPlan = (
    //   <Dropdown
    //     id="mediaPlan"
    //     options={this.mediaPlanOptions}
    //     selectedKey={this.mediaPlantSelectedOption}
    //     onChange={this.mediaPlanOnChange}
    //     styles={Constants.dropdownStyle}
    //     onRenderLabel={onRenderCustomLabel(
    //       this.props.commonProps,
    //       "mediaPlan",
    //       this._translation.mediaPlan,
    //       "Media plan tool tip"
    //     )}
    //   />
    // );

    const jsxTitle = (
      <TextField
        id="activityTitle"
        styles={Constants.textFieldStyle}
        maxLength={254}
        placeholder={this._translation.typeHere}
        value={this.state.name}
        onChange={this.titleChange}
        onRenderLabel={onRenderCustomLabel(
          this.props.commonProps,
          "activityTitle",
          this._translation.activityTitle,
          "Activity title tool tip",
          true,
          true
        )}
      ></TextField>
    );

    const jsxAssociatedBriefCheck = (
      <Checkbox
        id="associatedBrief"
        label="Associate brief"
        onChange={this.associatedBriefChange}
        styles={Constants.checkBoxStyle}
        checked={this.state.associatedBrief}
        onRenderLabel={onRenderCustomLabel(
          this.props.commonProps,
          "associatedBrief",
          this._translation.associateBrief,
          "Activity description tool tip",
          false,
          true
        )}
      />
    );

    const jsxAssociatedBrief = (
      <>
        <CustomLabel
          commonProps={this.props.commonProps}
          controlId={"associatedBrief"}
          label={this._translation.associatedBrief}
          tooltiptext={"Associated brief tool tip"}
          required={false}
          disableTooltip={true}
        ></CustomLabel>
        <Label id="associatedBrief" styles={Constants.textFieldStyle}>
          {this.props.brief?.title}
        </Label>
      </>
    );

    const jsxHeadOfActivity = (
      <HeadOfActivity
        commonProps={this.props.commonProps}
        styles={Constants.pickerStyle}
        onChange={this.headOfActivityChange}
        values={this.state.selectedHeadOfActivity}
        calendar={this.state.calendar}
      ></HeadOfActivity>
    );

    const jsxSpoc = (
      <Spoc
        commonProps={this.props.commonProps}
        styles={Constants.pickerStyle}
        onChange={this.pickerSpocChange}
        values={this.state.selectedSpoc}
        calendar={this.state.calendar}
      ></Spoc>
    );
    const jsxError = (
      <div className="ms-Grid-row ">
        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 messageError">
          <MessageBar
            messageBarType={MessageBarType.error}
            isMultiline={false}
            dismissButtonAriaLabel={this._translation.close}
            onDismiss={this.closeMessageBarError}
          >
            {this.state.messageError}
          </MessageBar>
        </div>
      </div>
    );
    
    return (
      <React.Fragment>
        {showMacro && (
          <>
            <Modal titleAriaId="load" isOpen={this.state.isSaving}>
              <Spinner
                size={SpinnerSize.large}
                label={this._translation.savingData}
                style={{ minHeight: 176 }}
              />
            </Modal>
            <div role="ActivityConfiguration"
              className={this.props.className + " panel-add-activity"}
              style={panelStyle}
            >
              <div className="ms-Grid" dir="ltr">
                <div
                  className={
                    (this.state.calendarOptions.length > 1
                      ? ""
                      : "display-none") + " ms-Grid-row"
                  }
                >
                  <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                    {jsxActivityCalendar}
                  </div>
                </div>
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                    <CustomLabel
                      commonProps={this.props.commonProps}
                      controlId="rangeCalendar"
                      label={this._translation.timePeriod}
                      tooltiptext="Time period"
                      required={true}
                      disableTooltip={false}
                    ></CustomLabel>
                    <RangeCalendar
                      commonProps={this.props.commonProps}
                      showTime={false}
                      disableTime={true}
                      onChange={this.rangeCalendarChange}
                      value={this.state.rangeDateValue}
                    ></RangeCalendar>
                  </div>
                </div>
                {calendarError}
                {/* <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                    {jsxMediaPlan}
                  </div>
                </div> */}
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    {jsxTitle}
                  </div>
                  {titleError}
                </div>
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    <CustomLabel
                      commonProps={this.props.commonProps}
                      controlId="activityDescription"
                      label={this._translation.description}
                      tooltiptext="Description"
                      required={false}
                      disableTooltip={false}
                    ></CustomLabel>
                    {jsxDescription}
                  </div>
                </div>

                {this.props.brief && jsxAssociatedBrief}
                {false && !this.props.brief && (
                  <>
                    <div className="ms-Grid-row briefPadding">
                      <div className="ms-Grid-col">
                        {jsxAssociatedBriefCheck}
                      </div>
                    </div>
                    {(isBriefVisibile || this.props.brief) &&
                      this.renderBrief()}
                  </>
                )}

                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    <CustomLabel
                      commonProps={this.props.commonProps}
                      controlId="headOfActivity"
                      label={this._translation.headOfActivity}
                      tooltiptext="Head of activity"
                      required={false}
                      disableTooltip={false}
                    ></CustomLabel>
                    {jsxHeadOfActivity}
                  </div>
                </div>

                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    <CustomLabel
                      commonProps={this.props.commonProps}
                      controlId="chooseSpoc"
                      label={this._translation.chooseSpoc}
                      tooltiptext="Choose Spoc"
                      required={false}
                      disableTooltip={false}
                    ></CustomLabel>
                    {jsxSpoc}
                  </div>
                </div>
                <div className="ms-Grid-row">
                  <div></div>
                </div>
                {showError && jsxError}
                <div className="ms-Grid-row commandFooter">
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    <DefaultButton
                      alt="Close"
                      onClick={this.closePanel}
                      styles={Constants.iStandardButton}
                    >
                      {this._translation.cancel}
                    </DefaultButton>
                    {!showMediaPlan && (
                      <PrimaryButton
                        alt="Save"
                        onClick={this.save}
                        // disabled={!this.canSave}
                        styles={Constants.iPrincipalButton}
                      >
                        {this._translation.addToCalendar}
                      </PrimaryButton>
                    )}
                    {showMediaPlan && (
                      <PrimaryButton
                        alt="Show Media Plan"
                        onClick={this.showMediaPlan}
                        disabled={!this.canSave}
                        styles={Constants.iPrincipalButton}
                      >
                        {this._translation.addMediaPlan}
                      </PrimaryButton>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {this.renderMediaPlan()}
      </React.Fragment>
    );
  }
}
