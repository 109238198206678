import React, { useState } from "react";
// import './MonthCalendar.css';
import styles from "./MonthCalendar.module.scss";
import * as dates from '../BigCalendar/utils/dates'
import {WeekDaysMemo} from './WeekDays';
import { notify } from '../BigCalendar/utils/helpers';
import { DateHelper } from "../../../../Models/DateHelper";

export interface IMonthHeaderProps {
  t: any;
  year: number;
  month: number;  
}
export interface IMonthHeaderState { 
}

export class MonthHeader extends React.Component<IMonthHeaderProps, IMonthHeaderState> {
   
    constructor(props: IMonthHeaderProps) {
        super(props);        
    }       
    
    handleHeaderClick (date: Date, view: any, e: any)  {
        e.preventDefault()
        notify(this.props.t.onDrillDown, [date, view])
    }

    private getMonthName(month: number): string {        
        return DateHelper.monthName[month - 1];        
    }

    renderMonthHeader(year: number, month: number)
    {                
        const monthName: string = this.getMonthName(month);
        const isCurrentMonth:boolean = dates.isCurrentMonth(year, month);
        const monthNameClasses:string = styles["month-calendar-monthname"];
        const date: Date = new Date(year, month-1);
        const drilldownView = 'month';
        const monthElement = <a href="#" onClick={e => this.handleHeaderClick(date, drilldownView, e)}>
            <span className={isCurrentMonth ? styles["month-calendar-monthname-current"] : ""}>{monthName} {year}</span>
                </a>;
        return <React.Fragment>
                <div className={monthNameClasses}>
                    {monthElement}
                </div>
                <WeekDaysMemo></WeekDaysMemo>
            </React.Fragment>
    }

    render() {
        return (
            <div className={styles["month-calendar-header"]}>
                {this.renderMonthHeader(this.props.year, this.props.month)}
            </div>
        );
    }  
    
};

