export enum ToastNotificationType {
  SUCCESS = "SUCCESS",
  WARNING = "WARNING",
  ERROR = "ERROR",
  INFO = "INFO",
}

export enum ToastNotificationPosition {
  TOPRIGHT = "top-right",
  TOPCENTER = "top-center",
  TOPLEFT = "top-left",
  BOTTOMRIGHT = "bottom-right",
  BOTTOMCENTER = "bottom-center",
  BOTTOMLEFT = "bottom-left",
}

export interface IToastNote {
  // [x: string]: any;
  id: number;
  type: ToastNotificationType;
  title: string;
  description: string;
  stayOpen?: boolean;
  start: Date;
}