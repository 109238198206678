import { TFunction } from "i18next";
import { CommonTranslation } from "./Common.Translation";

export class BriefTranslation extends CommonTranslation {
  constructor(translation: TFunction) {
    super(translation, "brief");
  }

  //"Delegate approver"

  public get delegateApprover(): string {
    return super.getTextByMessageKey("delegateApprover");
  }

  public get delegateInsertNotification(): string {
    return super.getTextByMessageKey("delegateInsertNotification");
  }

  public get delegateForApprovation(): string {
    return super.getTextByMessageKey("delegateForApprovation");
  }

  public get delegateForInsert(): string {
    return super.getTextByMessageKey("delegateForInsert");
  }

  public approvalNotification(referent: string): string {
    return super.getTextByMessageKey("approvalNotification", {
      referent: referent,
    });
  }

  public get delegate(): string {
    return super.getTextByMessageKey("delegate");
  }

  public get addToCalendar(): string {
    return super.getTextByMessageKey("addToCalendar");
  }

  public get newBriefTitle(): string {
    return super.getTextByMessageKey("newBriefTitle");
  }

  public get newBriefSubTitle(): string {
    return super.getTextByMessageKey("newBriefSubTitle");
  }

  public get suggestedStakeholder(): string {
    return super.getTextByMessageKey("suggestedStakeholder");
  }

  public get suggestedTarget(): string {
    return super.getTextByMessageKey("suggestedTarget");
  }

  public get noTargetFound(): string {
    return super.getTextByMessageKey("noTargetFound");
  }

  public get suggestedReferent(): string {
    return super.getTextByMessageKey("suggestedReferent");
  }

  public get noReferentFound(): string {
    return super.getTextByMessageKey("noReferentFound");
  }

  public get saveDraftMessage(): string {
    return super.getTextByMessageKey("saveDraftMessage");
  }

  public get errorSaveBrief(): string {
    return super.getTextByMessageKey("errorSaveBrief");
  }  

  public get errorBriefNotFound(): string {
    return super.getTextByMessageKey("errorBriefNotFound");
  }

  public get errorRetrieveData(): string {
    return super.getTextByMessageKey("errorRetrieveData");
  }

  public get sendBrief(): string {
    return super.getTextByMessageKey("sendBrief");
  }

  public get briefAfterSendText(): string {
    return super.getTextByMessageKey("briefAfterSendText");
  }

  public get send(): string {
    return super.getTextByMessageKey("send");
  }

  public get previewAndSend(): string {
    return super.getTextByMessageKey("previewAndSend");
  }

  public get edit(): string {
    return super.getTextByMessageKey("edit");
  }

  public get contactApplicantUnit(): string {
    return super.getTextByMessageKey("contactApplicantUnit");
  }

  public get saveDraft(): string {
    return super.getTextByMessageKey("saveDraft");
  }

  public get noStakeholderFound(): string {
    return super.getTextByMessageKey("noStakeholderFound");
  }

  public get noBriefFound(): string {
    return super.getTextByMessageKey("noBriefFound");
  }

  public get downloadAttachment(): string {
    return super.getTextByMessageKey("downloadAttachment");
  }

  public get loadingBriefError(): string {
    return super.getTextByMessageKey("loadingBriefError");
  }

  public get status(): string {
    return super.getTextByMessageKey("status");
  }

  public get title(): string {
    return super.getTextByMessageKey("title");
  }

  public get applicationUnit(): string {
    return super.getTextByMessageKey("applicationUnit");
  }

  public get dateColumn(): string {
    return super.getTextByMessageKey("dateColumn");
  }

  public get dateOnColumn(): string {
    return super.getTextByMessageKey("dateOnColumn");
  }

  public get referents(): string {
    return super.getTextByMessageKey("referents");
  }

  public get referent(): string {
    return super.getTextByMessageKey("referent");
  }

  public get timing(): string {
    return super.getTextByMessageKey("timing");
  }

  public get expectedDeliveryDate(): string {
    return super.getTextByMessageKey("expectedDeliveryDate");
  }

  public get skipApprovation(): string {
    return super.getTextByMessageKey("skipApprovation");
  }

  public get associateBehalf(): string {
    return super.getTextByMessageKey("associateDelegate");
  }

  public get description(): string {
    return super.getTextByMessageKey("description");
  }

  public get goalsAndKeyMessage(): string {
    return super.getTextByMessageKey("goalsAndKeyMessage");
  }

  public get targets(): string {
    return super.getTextByMessageKey("targets");
  }

  public get highlights(): string {
    return super.getTextByMessageKey("highlights");
  }

  public get links(): string {
    return super.getTextByMessageKey("links");
  }

  public get embargoed(): string {
    return super.getTextByMessageKey("embargoed");
  }

  public get relevantStakeholder(): string {
    return super.getTextByMessageKey("relevantStakeholder");
  }

  public get requireMediaCoverage(): string {
    return super.getTextByMessageKey("requireMediaCoverage");
  }

  public get uploadDocumentation(): string {
    return super.getTextByMessageKey("uploadDocumentation");
  }

  public get dragAndDropHere(): string {
    return super.getTextByMessageKey("dragAndDropHere");
  }

  public get orUseUploadButton(): string {
    return super.getTextByMessageKey("orUseUploadButton");
  }

  public get upoloadButton(): string {
    return super.getTextByMessageKey("upoloadButton");
  }

  public get maxFileSize(): string {
    return super.getTextByMessageKey("maxFileSize");
  }

  public get mailFROM(): string {
    return super.getTextByMessageKey("mailFROM");
  }

  public get mailTO(): string {
    return super.getTextByMessageKey("mailTO");
  }

  public get mailCC(): string {
    return super.getTextByMessageKey("mailCC");
  }

  public get subject(): string {
    return super.getTextByMessageKey("subject");
  }

  public get message(): string {
    return super.getTextByMessageKey("message");
  }

  public get genericErrorInSave(): string {
    return super.getTextByMessageKey("genericErrorOnSave");
  }

  public get contactActionInfo(): string {
    return super.getTextByMessageKey("contactActionInfo");
  }

  public get statusConflic(): string {
    return super.getTextByMessageKey("statusConflict");
  }

  public get delegateForInsertSuccess(): string {
    return super.getTextByMessageKey("delegateForInsertSuccess");
  }

  public get getBriefsError(): string {
    return super.getTextByMessageKey("getBriefsError");
  }

  public get sendApprovalRequestActionDashboard(): string {
    return super.getTextByMessageKey("sendApprovalRequestActionDashboard");
  }
  public get requestInfoActionDashboard(): string {
    return super.getTextByMessageKey("requestInfoActionDashboard");
  }
  public get resendApprovalRequestActionDashboard(): string {
    return super.getTextByMessageKey("resendApprovalRequestActionDashboard");
  }
  public get acceptedActionDashboard(): string {
    return super.getTextByMessageKey("acceptedActionDashboard");
  }
  public get rejectedActionDashboard(): string {
    return super.getTextByMessageKey("rejectedActionDashboard");
  }
  public get reEditedActionDashboard(): string {
    return super.getTextByMessageKey("reEditedActionDashboard");
  }
  public get deleteBriefSuccess(): string {
    return super.getTextByMessageKey("deleteBriefSuccess");
  }
  public get deleteBriefError(): string {
    return super.getTextByMessageKey("deleteBriefError");
  }
}
