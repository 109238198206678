import { TFunction } from "i18next";
import { CommonTranslation } from "../../../../Translations/Common.Translation";

export class PlannerNavigationTranslation extends CommonTranslation
{    
    constructor(translation: TFunction)
    {
        super(translation, "planner");
    }
       
}