import { IResponsibility } from './../Models/IResponsibility';
import { IDateRangeValue } from "../Components/Shared/RangeCalendar/IDateRangeValue";
import { ActivityPermissions, IActivitiesCount, IActivity, IActivityAllAttributes, IListActivities, IListActivitiesFilter, IOrderBy } from "../Models/Activity";
import { CalendarResult } from "../Models/Calendar";
import { ICalendar } from "../Models/ICalendar";
import { ICategory } from "../Models/ICategory";
import { ISensibility } from "../Models/ISensibility";
import { ICalendarPermission } from "../Models/Planner";
import { TimelineResult } from "../Models/Timeline";
import { User } from "../Models/User";

export interface IActivityClient {
    // getTimelineByFilters(filter: string, statusFilter: string[],fromDate: Date, toDate: Date): Promise<Timeline[]>;
    getTimelineByFilters(filterOptions: IFilterPlannerActivity): Promise<TimelineResult | undefined>;
    getMacroAsync(date: Date | undefined, calendar: number | undefined): Promise<IActivity[] | undefined>
    getCalendars(): Promise<ICalendar[] | undefined>
    getCalendarsPermissions(): Promise<ICalendarPermission[] | undefined>
    getCategories(): Promise<ICategory[] | undefined>
    getResponsibilities(): Promise<IResponsibility[] | undefined>
    getSensibilities(): Promise<ISensibility[] | undefined>
    getCalendarActivities(filterOptions: IFilterPlannerActivity): Promise<CalendarResult | undefined>;
    getMacroActivity(id: number): Promise<IActivityAllAttributes | undefined>;
    getMicroActivity(id: number): Promise<IActivityAllAttributes | undefined>;
    getActivities(filter: IListActivitiesFilter, orderBy: (IOrderBy | undefined)[]): Promise<IListActivities | undefined>;
    getActivitiesCount(): Promise<IActivitiesCount[] | undefined>
    getActivityDetails(id?: number, isMacro?: boolean): Promise<IActivityAllAttributes | undefined>;
    getActivityPermissions(id?: number, isMacro?: boolean): Promise<ActivityPermissions | undefined>;
    updateActivityName(id: number, name: string | null, isMacro?: boolean): Promise<boolean | undefined>;
    updateActivityParent(id: number, idMacro: number): Promise<boolean | undefined>;
    updateActivityDescription(id: number, description: string | null, isMacro?: boolean): Promise<boolean | undefined>;
    updateActivityStatus(id: number, status: string): Promise<boolean | undefined>;
    updateActivityCategories(id: number, categories: number[]): Promise<number>;
    updateActivityCategory(id: number, value: string): Promise<number>;
    updateActivityResponsibilities(id: number, responsibilities: number[]): Promise<number>;
    updateActivityResponsibility(id: number, value: string): Promise<number>;
    updateActivitySensibility(id: number, value: string): Promise<number>;
    // updateActivityDueTo(id: number, dateTo: Date): Promise<boolean | undefined>;    
    updateActivityDueTo(id: number, dateTo: IDateRangeValue): Promise<boolean | undefined>;
    updateActivityTiming(id: number, dateRange: IDateRangeValue): Promise<boolean | undefined>;
    updateActivityInternalResources(id: number, resources: User[]): Promise<number>;
    updateActivityAlarmResources(id: number, resources: User[]): Promise<number>;
    updateActivityExternalResources(id: number, resources: User[]): Promise<number>;
    updateSpoc(id: number, spocUPN: string | null, isMacro: boolean): Promise<number>;
    updateReminder(id: number, reminder: number): Promise<number>;
    updateHeadOfActivity(id: number, headOfActivityUPN: string | null, isMacro: boolean): Promise<boolean | undefined>;
    deleteActivity(idActivity: string, isMacro: boolean): Promise<boolean | undefined>;
}

export interface IFilterPlannerActivity {
    readonly activityType: ActivityTypeFilter | string;
    readonly activityStatus: string[];
    readonly activityCalendar: string[];
    readonly activitySensibility: string[];
    readonly activityCategory: string[];
    readonly activityResponsibility: string[];
    readonly fromDate: Date;
    readonly toDate: Date;
}

export enum ActivityTypeFilter {
    all = "All",
    macro = "Macro",
    micro = "Micro"
}