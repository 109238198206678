import React, { useState } from "react";
import { apiConfig } from "./msal/MsalConfig";
import { Subject } from "rxjs";
import { withAuth } from "./msal/MsalAuthProvider";
import { Header } from "./Components/Header/Header";
import { Footer } from "./Components/Footer/Footer";
import { Dashboard } from "./Components/Body/Dashboard/Dashboard";
import { MyBrief } from "./Components/Body/MyBrief/MyBrief";
import { Activities } from "./Components/Body/Activities/Activities";
import { Planner, PlannerView } from "./Components/Body/Planner/Planner";
import { Report } from "./Components/Body/Report/Report";
import { Constants } from "./Utility/Constants";
import { Security } from "./Utility/Security";
import { UserClient } from "./Clients/UserClient";
import { useTranslation, UseTranslationOptions } from "react-i18next";
import { initializeIcons } from "office-ui-fabric-react/lib/Icons";
import { ToastComponent } from "./Components/Shared/ToastComponent/ToastComponent";
import "./App.css";
import "./Style/global.scss";
import {
  AdditionalRoutingInfo,
  ICommonProperties,
  IRoute,
  Page,
} from "./Models/IBaseProperties";
import { HttpClientsCreator } from "./Clients/ClientCreator";
import { ActivityHelper } from "./Models/ActivityStatus";
import { DateHelper } from "./Models/DateHelper";
import { ToastNotificationType } from "./Models/ToastNote";
import { TFunction } from "i18next";
import "url-search-params-polyfill";
import {
  Dialog,
  DialogFooter,
  DialogType,
  IModalProps,
} from "@fluentui/react";
import { NotificationClient } from "./Clients/NotificationClient";
import {
  INotification,
} from "./Models/Notification";

initializeIcons();

function RootBrowserCompatibilityApp(props: { page: any }) {
  var isIE11 =
    !!(window as any).MSInputMethodContext && !!(document as any).documentMode;
  if (isIE11) {
    var confirmDialogProps: IModalProps = { isBlocking: true };
    return (
      <div className="App">
        <Dialog
          hidden={false}
          onDismiss={() => {
            window.location.href = "";
          }}
          modalProps={confirmDialogProps}
          dialogContentProps={{
            type: DialogType.normal,
            title: "BROWSER NOT SUPPORTED",
            subText:
              "The website you are opening is not supported on Internet Explorer",
          }}
        >
          <DialogFooter>
            {/* <DefaultButton
              onClick={() => {
                window.location.href = "";
              }}
              text="Chiudi"
            /> */}
          </DialogFooter>
        </Dialog>
      </div>
    );
  } else {
    return RootApp(props);
  }
}

function onNotification(notification: INotification): void { }

function RootApp(props: { page: any }) {
  const options: UseTranslationOptions = {
    useSuspense: false,
  };
  const { t, i18n } = useTranslation("common", options);
  const _ = buildActivityHelper();

  const value1 = process.env.authenticationAddress;

  const languageStoredInLocalStorage = localStorage.getItem("language");

  const urlParams = new URLSearchParams(window.location.search);
  const pageFromUrl = urlParams.get("page");
  const pageInLocalStorage = localStorage.getItem("page");
  const pageInSessionStorage = sessionStorage.getItem("page");
  const userInLocalStorage = localStorage.getItem("user");
  const [language, setLanguage] = useState(
    languageStoredInLocalStorage ? languageStoredInLocalStorage : "en"
  );

  buildDateHelper();

  const [signalRConnected, setSignalRConnected] = useState(false);
  const [routingBuilded, setRoutingSentinel] = useState(false);
  const [routingObject, setRouting] = useState<Subject<IRoute> | undefined>(
    undefined
  );

  const [page, setPage] = useState(
    pageFromUrl
      ? pageFromUrl
      : pageInSessionStorage
        ? pageInSessionStorage
        : pageInLocalStorage
          ? pageInLocalStorage
          : "Dashboard"
  );

  // const [page, setPage] = useState(
  //   pageInLocalStorage ? pageInLocalStorage : "Dashboard"
  // );
  const [user, setUser] = useState(
    userInLocalStorage ? userInLocalStorage : "-"
  );

  const [signalRNotification, setSignalRNotification] = useState<NotificationClient | undefined>();
  const [additionalInfo, setAdditionalInfo] = useState<
    AdditionalRoutingInfo | undefined
  >(undefined);

  const toastRef: React.RefObject<ToastComponent> = React.createRef();
  const [toastRefState, setToastComponent] = useState(toastRef.current);
  const userClient: UserClient = new UserClient();

  const routing = new Subject<IRoute>();

  const notificationSubject = new Subject<INotification>();

  routing.subscribe((route: IRoute) => {
    const newPage = pageEnumToString(route.page);
    setAdditionalInfo(route.additionalInfo);
    setNewBrief(false);
    if (newPage) {
      setPage(newPage);
    }
  });

  // setRouting(routing);
  // let notification;
  if (!signalRConnected) {
    setSignalRConnected(true);
    const notification = new NotificationClient(
      `${apiConfig.signalResource}/notifications`
    );

    setSignalRNotification(notification);
  }

  if (signalRNotification) {
    signalRNotification.onNewNotification = (value: INotification) => {
      notificationSubject.next(value);
      // subscriber.next(value);
    };
  }

  const commonProps: ICommonProperties = {
    translation: t,
    toastComponent: toastRefState,
    clientCreator: new HttpClientsCreator(),
    onNotification: notificationSubject,
    onRoute: routing,
    additionalInfo: additionalInfo,
  };

  const [userInfoStored, setUserInfo] = useState(false);
  const [userInfoStoredPendingRequest, setPendingRequest] = useState(false);
  const [sharepointUrl, setSharepointUrl] = useState("");

  //used setUserInfo parameter to manage async storeCurrentUserInfo
  if (!userInfoStored || Security.getCurrentUserInfo() === undefined) {
    if (!userInfoStoredPendingRequest) {
      userClient.getSharepointLoginPageUrl().then((url) => {
        if (url) {
          setSharepointUrl(url);
        }
        // setPendingRequest(false);
      });
      setPendingRequest(true);
    }
    storeCurrentUserInfo(setUserInfo, setPendingRequest, toastRefState, t);
  }

  const isGM = isGMUser();
  const [newBriefOpen, setNewBrief] = useState(false);
  const [newActivityOpen, setNewActivity] = useState(false);

  return (
    <div className="App">
      <div hidden={true}>
        /* {sharepointUrl && <iframe className="frameSharepoint" src={sharepointUrl}></iframe>} */
      </div>
      <div className="ms-Grid" dir="ltr">
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
            <Header
              commonProps={commonProps}
              onClickNewBrief={() => {
                setNewBrief(true);
                setPage(Constants.PageDashboard);
              }}
              page={page == null ? "" : page}
              handleSetPage={(page) => {
                if (window.location.search) {
                  window.sessionStorage.setItem("page", page);
                  window.location.href = "";
                } else {
                  window.sessionStorage.removeItem("page");
                  setPage(page);
                }
                // TODO: da decommentare
                // storeVariableInLocalStorage("page", page);
              }}
              handleSetUser={(user) => {
                setUser(user);
              }}
            />
          </div>{" "}
        </div>
        <div className="ms-Grid-row content">
          <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
            {userInfoStored && (
              <div id="body" className="bodyApp">
                {page === Constants.PageDashboard && (
                  <Dashboard
                    commonProps={commonProps}
                    showNewBrief={newBriefOpen}
                    onDismissBrief={() => setNewBrief(false)}
                    onNavigate={(page: string) => {
                      setPage(page);
                    }}
                  />
                )}
                {page === Constants.PageMyBrief && (
                  <MyBrief commonProps={commonProps} />
                )}
                {page === Constants.PageActivities && (
                  <Activities commonProps={commonProps} />
                )}
                {isGM && page === Constants.PagePlanner && (
                  <Planner commonProps={commonProps} views={PlannerView.All} />
                )}
                {isGM && page === Constants.PageReport && (
                  <Report commonProps={commonProps} />
                )}
              </div>
            )}
          </div>
        </div>
        <div className="ms-Grid-row footer-row">
          <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
            <Footer
              commonProps={commonProps}
              language={language}
              handleSetLanguage={(language) => {
                setLanguage(language);
                i18n.changeLanguage(language);
                storeVariableInLocalStorage("language", language);
              }}
            />
          </div>
        </div>
        <ToastComponent
          commonProps={commonProps}
          ref={setToastComponent}
        ></ToastComponent>
      </div>
    </div>
  );

  function pageEnumToString(page: Page): string | undefined {
    let sPage: string | undefined;
    switch (page) {
      case Page.Dashboard:
        sPage = Constants.PageDashboard;
        break;
      case Page.Activities:
        sPage = Constants.PageActivities;
        break;
      case Page.MyBrief:
        sPage = Constants.PageMyBrief;
        break;
      case Page.Planner:
        sPage = Constants.PagePlanner;
        break;
      case Page.Report:
        sPage = Constants.PageReport;
        break;
      default:
        sPage = undefined;
        break;
    }

    return sPage;
  }

  function buildActivityHelper() {
    return new ActivityHelper(t);
  }

  function buildDateHelper() {
    return new DateHelper(language);
  }

  function isGMUser() {
    return (
      userInfoStored &&
      (Security.isGlobalMediaAdvanced ||
        Security.isGlobalMediaSpecialist ||
        Security.isAuditor ||
        Security.isAdmin)
    );
  }
}

async function storeCurrentUserInfo(
  setUserInfo: (value: React.SetStateAction<boolean>) => void,
  setPendingRequest: (value: React.SetStateAction<boolean>) => void,
  toast: any,
  t: TFunction
) {
  const userClient: UserClient = new UserClient();
  userClient.getCurrentUserInfoAsync().then((userInfo) => {
    if (userInfo) {
      new Security(userInfo);
      setUserInfo(true);
      setPendingRequest(false);
    } else {
      toast?.showMessage(
        "Error",
        t("users.userInfoError"),
        ToastNotificationType.ERROR
      );
      setUserInfo(false);
      setPendingRequest(false);
    }
  });
}

function storeVariableInLocalStorage(variableName: string, page: string) {
  localStorage.setItem(variableName, page);
}

export const App = withAuth(RootBrowserCompatibilityApp);
