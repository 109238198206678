import * as React from "react";
import "./Profile.css";
import { Security } from "../../../Utility/Security";
import {
  Callout,
  DirectionalHint,
  ICalloutContentStyles,
  IconButton,
  Image,
  ImageFit,
  Panel,
  PanelType,
  PersonaSize,
} from "@fluentui/react";
import { IBaseProperties } from "../../../Models/IBaseProperties";
import { UserComponent } from "../../UserComponent/UserComponent";
import { PersonaComponent } from "../../Shared/PersonaComponent/PersonaComponent";
import { PersonaStyle } from "../../../Models/User";

export interface IProfileProps extends IBaseProperties {
  user: any;
}
export interface IProfileState {
  showUserInfo: boolean;
}

const demoImg = require("./demo-profile.png");

export class Profile extends React.Component<IProfileProps, IProfileState> {
  private readonly panelStyle = { main: { backgroundColor: "#f7f8fb" } };
  private readonly _userInfoRef = React.createRef<HTMLDivElement>();
  private readonly calloutStyle: ICalloutContentStyles = {
    container: {},
    root: {},
    beak: {},
    beakCurtain: {},
    calloutMain: { borderRadius: "14px" },
  };

  constructor(props: IProfileProps) {
    super(props);
    this.state = {
      showUserInfo: false,
    };
  }

  render() {
    const { showUserInfo } = this.state;
    var user = Security.getCurrentUser();
    return (
      <React.Fragment>
        <div
          id="notifyContainer"
          ref={this._userInfoRef}
          className="notify marginTop10"
        >
          {/* <Image
                        className="marginTop10"
                        src={demoImg}
                        imageFit={ImageFit.centerContain}
                        alt='Example of the image fit value "contain" on an image wider than the frame.'
                        width={35}
                        height={35}
                        onClick={() => this.setState({showUserInfo: !this.state.showUserInfo}) }
                    /> */}
          {/* <span className="userInfo">GM</span> */}
          <div
            className="userInfo"
            aria-label="View Profile Actions"
            tabIndex={0}
            onClick={() =>
              this.setState({ showUserInfo: !this.state.showUserInfo })
            }
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                this.setState({ showUserInfo: !this.state.showUserInfo });
              }
            }}
          >
            {/* {Security.getCurrentUserInfo()?.initials} */}
            {user && (
              <PersonaComponent
                commonProps={this.props.commonProps}
                personaSize={PersonaSize.size32}
                personaStyle={PersonaStyle.NORMAL}
                maxLength={1}
                persona={[user]}
              ></PersonaComponent>
            )}
          </div>
        </div>
        {showUserInfo && (
          <Callout
            target={this._userInfoRef}
            role="dialog"
            directionalHint={DirectionalHint.bottomAutoEdge}
            styles={this.calloutStyle}
            onDismiss={() => this.setState({ showUserInfo: false })}
          >
            <UserComponent commonProps={this.props.commonProps}></UserComponent>
          </Callout>
        )}
      </React.Fragment>
    );
  }
}
