import * as React from "react";
import styles from "./BriefFileList.module.scss";
import {    
  IColumn,  
  DetailsListLayoutMode,
  SelectionMode,
  FontIcon,  
  Text,
  IDetailsRowProps,
  IRenderFunction,
  DetailsRow,
  ShimmeredDetailsList,  
} from "@fluentui/react";
import {
  IBaseProperties,  
} from "../../../../Models/IBaseProperties";
import { Utility } from "../../../../Utility/Utility";
import { IFile } from "../../../../Models/Brief";

interface IFileWithFooter
{    
    file?: IFile;
    footer?: IFooter;
}

interface IFooter
{
  num: number;
  totalSize: number;
}

export interface IBriefFileListProps extends IBaseProperties {  
  items?: IFile[];  
}
export interface IBriefFileListState {
}

export class BriefFileList extends React.Component<IBriefFileListProps, IBriefFileListState> {
  private tryOpenFile(file: IFile | undefined): void {
    if (file)
    {
      this.openFile(file.openPath, file.name)
    }
  }
  
  private openFile(filePath: string, fileName: string) {
    this.createTemporaryHREF(filePath, "_blank");
  }

  private downloadFile(fileDownload: string, fileName: string) {
    this.createTemporaryHREF(fileDownload, "_self");
  }

  private createTemporaryHREF(href: string, target: string) {
    const link = document.createElement("a");
    link.target = target;
    link.href = href;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }   
  
  private readonly _columns: IColumn[] = [      
    {
      key: 'fileName',
      name: 'File name',
      // classNames: classNames.fileIconCell,
      // iconClassName: classNames.fileIconHeaderIcon,            
      fieldName: 'name',
      minWidth: 400,      
      data: 'string',
       onRender: (item: IFileWithFooter) => {
        let renderFileName = <></>;
         if (item.file)
         {
            renderFileName = <div className={styles["file-name"]} onClick={()=> this.tryOpenFile(item.file)} >
              <FontIcon                
                iconName={Utility.GetIconType(
                  item.file.name ? item.file.name : ""
                )}
                className="iconType"
              ></FontIcon>
              <span>{item.file.name}</span>
            </div>;
         }
         else if (item.footer)
         {
          renderFileName = <div>
            <Text className={styles["footer-file-label"]}>Total</Text>
            <Text className={styles["footer-file-value"]}> {item.footer.num} </Text>
            <Text className={styles["footer-file-label"]}>files</Text>
        </div>;
         }
         return(         
              renderFileName            
         )
       },
    },    
    {
      key: 'fileSize',
      name: 'File size',
      fieldName: 'fileSizeRaw',
      minWidth: 100,      
      isResizable: true,
      isCollapsible: true,
      data: 'string',     
      onRender: (item: IFileWithFooter) => {

        let renderSize = <></>;

        if (item.file)
        {
          renderSize = <span>{Utility.GetFileSize(item.file.size)}</span>;
        }
        else if (item.footer)
        {
          renderSize = <Text className={styles["footer-file-value"]}>{Utility.GetFileSize(item.footer.totalSize)}</Text>;
        }

        return renderSize;
      },
    },
  ];
    
  constructor(props: IBriefFileListProps) {
    
    super(props);            

    this.initializeState(props);

    this.bindEvents();
  }

  private initializeState(props: IBriefFileListProps) {
    this.state = {      
    };
  }

  private bindEvents() {    
    
  }

  private _onRenderRow (props?: IDetailsRowProps, defaultRender?: IRenderFunction<IDetailsRowProps> | undefined) : JSX.Element
  {
    if (props) {

      const item = props.item as IFileWithFooter;

      let className = styles["file-item"];

      if (item.footer)
      {
        className = styles["footer-file"];
      }

      return (
        <DetailsRow {...props} className={className} />
      );
    }
    else
    {
      return <></>;
    }
  }

  private getItems(): IFileWithFooter[]
  {
    if (this.props.items)
    {
      const values = this.props.items.map(t=> ({file: t}) as IFileWithFooter);

      values.push(this.getFooter(values));
      
      return values;
    }
    else
    {
      return [];
    }
  }

  private getFooter(items: IFileWithFooter[]): IFileWithFooter
  {    
    const totalSize = items.length === 0 ? 0 : items.map(t=> t.file ? t.file.size : 0).reduce((a, b) => a + b);
    const item : IFileWithFooter = {footer: {num: items.length, totalSize: totalSize }};
    
    return item;
  }
  
  render() {
    const enableShimmer = this.props.items ? false : true;
    return (
      <React.Fragment>
          <div>            
            <ShimmeredDetailsList
                items={this.getItems()}
                enableShimmer={enableShimmer}
                compact={true}
                selectionMode={SelectionMode.none}
                columns={this._columns}         
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={false}                  
                onRenderRow={this._onRenderRow}                
              />
          </div>
      </React.Fragment>
    );
  }
}
