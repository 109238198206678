import * as React from "react";
import "./Header.css";
import { PivotItem, Image, ImageFit, DefaultButton } from "@fluentui/react";
import { Navigation } from "./Navigation/Navigation";
import { HamburgerMenu } from "./Navigation/HamburgerMenu";
import { HeaderAction } from "./HeaderAction/HeaderAction";
import { Notification } from "./Notification/Notification";
import { Profile } from "./Profile/Profile";
import { Bug } from "./Bug/Bug";
import { Constants } from "../../Utility/Constants";

import { msalAuth } from "../../msal/MsalAuthProvider";
import { getUserDetails } from "../../graph/GraphService";
import { IBaseProperties, IRoute, Page } from "../../Models/IBaseProperties";
import { INotification } from "../../Models/Notification";
import { IMacro, IMicro } from "../../Models/Activity";
import { Macro } from "../Body/Activities/Macro";
import { Micro } from "../Body/Activities/Micro";
import { PlannerTranslation } from "../Body/Planner/Planner.Translation";
import { NewActivityComponent } from "../Shared/NewActivityComponent/NewActivityComponent";

export interface IHeaderProps extends IBaseProperties {
  page: string;
  handleSetPage: (key: any) => void;
  handleSetUser: (key: any) => void;
  onClickNewBrief(): void;
}
export interface IHeaderState {
  user: any;
  isLoading: boolean;
  error: any;
  showAddbtn: boolean;
  newActivityType: string;
  newActivityVisible: boolean;
  changeSelNavCallback: (key: string) => void;
}

const logo = "/enel-logo-white.png";

export class Header extends React.Component<IHeaderProps, IHeaderState> {
  private readonly _plannerTranslation: PlannerTranslation;

  constructor(props: IHeaderProps) {
    super(props);
    this.state = {
      user: {},
      isLoading: false,
      error: null,
      showAddbtn: false,
      newActivityType: "",
      newActivityVisible: false,
      changeSelNavCallback: this.changeSelNav,
    };

    this._plannerTranslation = new PlannerTranslation(
      this.props.commonProps.translation
    );
  }

  private navigateToDashboard(): void {
    this.props.handleSetPage("Dashboard");
  }

  render() {
    return (
      <React.Fragment>
        <div className="ms-Grid header">
          <div className="ms-Grid-row">
            {/* <div className="ms-Grid-col">
              <div className="">
                <HamburgerMenu
                  commonProps={this.props.commonProps}
                  page={this.props.page}
                  changeSelNavCallback={this.state.changeSelNavCallback.bind(this)}
                />
              </div>
            </div> */}
            <div className="ms-Grid-col ms-sm6 ms-md2 ms-lg2">
              <div style={{ display: "flex", width: "200px" }}>
                <HamburgerMenu
                  commonProps={this.props.commonProps}
                  page={this.props.page}
                  changeSelNavCallback={this.state.changeSelNavCallback.bind(
                    this
                  )}
                />

                <Image
                  src={logo}
                  imageFit={ImageFit.centerContain}
                  alt="Enel Spa"
                  title="Enel - Media Planning"
                  width={112}
                  height={41}
                  className="enelLogo"
                  tabIndex={0}
                  onClick={() => this.navigateToDashboard()}
                />
              </div>
            </div>
            <div
              className="ms-Grid-col ms-sm6 ms-md10 ms-lg10"
              style={{
                display: "inline-flex",
                justifyContent: "flex-end",
              }}
            >
              {/* <div style={{ float: "right", marginRight: 24 }}>
                <Bug commonProps={this.props.commonProps} />
              </div> */}
              {/* <div style={{ float: "right", marginRight: 32 }}> */}

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Navigation
                  commonProps={this.props.commonProps}
                  page={this.props.page}
                  changeSelNavCallback={this.state.changeSelNavCallback.bind(
                    this
                  )}
                />
              </div>
              <div className={"hidden-mobile"} style={{ marginRight: "1.5rem", height: "3rem", }}>
                {this.props.page !== Constants.PageDashboard && (
                  <NewActivityComponent
                    commonProps={this.props.commonProps}
                    position={"HEADER"}
                  ></NewActivityComponent>
                )}
                {/* <HeaderAction
                  commonProps={this.props.commonProps}
                  visible={this.props.page !== Constants.PageDashboard}
                  onClickNewBrief={() => {}}
                /> */}
              </div>
              <div style={{ marginRight: 24 }}>
                <Notification commonProps={this.props.commonProps} />
              </div>
              <div style={{ marginRight: 22 }}>
                <Profile
                  commonProps={this.props.commonProps}
                  user={this.state.user}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  async componentDidMount() {
    try {
      const accessTokenRequest = {
        scopes: ["user.read"],
      };
      var accessToken = null;
      try {
        accessToken = await msalAuth.acquireTokenSilent(accessTokenRequest);
      } catch (error) {
        console.log("AquireTokenSilent failure");
        accessToken = await msalAuth.acquireTokenPopup(accessTokenRequest);
      }

      if (accessToken) {
        var user = await getUserDetails(accessToken);
        this.setLoggedUser(user);
        this.setState({
          user: user,
          isLoading: false,
          error: null,
        });
      } else {
        this.setState({
          user: null,
          isLoading: false,
          error: "No access token...",
        });
      }
    } catch (err) {
      var error = {};
      if (typeof err === "string") {
        var errParts = err.split("|");
        error =
          errParts.length > 1
            ? { message: errParts[1], debug: errParts[0] }
            : { message: err };
      } else {
        error = {
          message: err.message,
          debug: JSON.stringify(err),
        };
      }

      this.setState({
        user: {},
        isLoading: false,
        error: error,
      });
    }
  }

  private changeSelNav(key: string) {
    // var index = key.props.itemKey;
    //alert("Change Sel " + index);
    this.props.handleSetPage(key);
  }

  private setLoggedUser(user: any) {
    this.props.handleSetUser(user.givenName);
  }
}
