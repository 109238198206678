import React from "react";
import {
  IBasePicker,
  IBasePickerStyles,
  IBasePickerSuggestionsProps,
  ISuggestionModel,
  ITag,
  TagPicker,
  ValidationState,
} from "@fluentui/react";
import { IBaseProperties } from "../../../Models/IBaseProperties";
import { CommonTranslation } from "../../../Translations/Common.Translation";

export interface ICustomPickerProps extends IBaseProperties {
  styles?: Partial<IBasePickerStyles>;
  values: ITag[];
  onChange: (selectedTags: ITag[]) => void;
  minDigitBeforeSearch?: number;  
  onResolveSuggestion (filter: string, selectedItems?: ITag[] | undefined): ITag[] | PromiseLike<ITag[]>;
  itemLimit?: number;
  pickerSuggestionsProps: IBasePickerSuggestionsProps;
  disabled?: boolean;
}
export interface ICustomPickerState {  
  selectedCustomPicker: ITag[];
  disabled: boolean;
}

export class CustomPicker extends React.Component<ICustomPickerProps, ICustomPickerState> {
  private readonly _translation: CommonTranslation;
  private readonly _pickerRef = React.createRef<IBasePicker<ITag>>();
    
  private readonly minDigitBeforeSearch: number;
  private readonly DEFAULTMINDIGIT = 3;
  private lastRequestCompleted: boolean = true;
  private pendingRequest: boolean = false;        

  constructor(props: ICustomPickerProps) {
    super(props);
    this._translation = new CommonTranslation(props.commonProps.translation, "common");
    this.minDigitBeforeSearch = this.props.minDigitBeforeSearch ? this.props.minDigitBeforeSearch : this.DEFAULTMINDIGIT;

    this.state = {
      selectedCustomPicker: props.values,
      disabled: props.disabled ? props.disabled : false,
    };

    this.bindEvents();
  }

  componentDidUpdate(prevProps: ICustomPickerProps)
   {
    if (prevProps.values !== this.props.values) 
    {

      this.setState ({          
          selectedCustomPicker: this.props.values,        
        });
      } 
      
  }

  private createNewItem (input: string, validationState: ValidationState) : ITag | ISuggestionModel<ITag> 
  {
      return {name: input, key: input};
  }

  private currentListContainsTag = (tag: ITag, tagList?: ITag[]) => {
    if (!tagList || !tagList.length || tagList.length === 0) {
      return false;
    }
    return tagList.some((compareTag) => compareTag.key === tag.key);
  };

  private readonly checkSelectedItem = (
    selectedItem?: ITag | undefined
  ): ITag | PromiseLike<ITag> | null => {
    if (
      selectedItem &&
      this._pickerRef.current &&
      this.currentListContainsTag(
        selectedItem as ITag,
        this._pickerRef.current.items
      )
    ) {
      return null;
    }

    return selectedItem as ITag;
  };
  
  private bindEvents() {
    this.onChange = this.onChange.bind(this);
  }

  private onChange(items?: ITag[] | undefined): void {
    const newValues = items ? items : [];
    this.setState({ selectedCustomPicker: newValues });
    this.props.onChange(newValues);

    // if (items && (items as ITag[]).length == 1) {

    //   this.setState({ selectedCustomPicker: items });
    //   this.props.onChange(items);
    // } else {
    //   this.setState({ selectedCustomPicker: [] });
    //   this.props.onChange([]);
    // }
  }
  
  render() {
    const jsxCustomPicker = (
      <TagPicker
        ref={this._pickerRef}        
        onItemSelected={this.checkSelectedItem}
        pickerSuggestionsProps={this.props.pickerSuggestionsProps}
        removeButtonAriaLabel="Remove"
        onResolveSuggestions={this.props.onResolveSuggestion}
        resolveDelay={300}
        itemLimit={this.props.itemLimit}
        onChange={this.onChange}
        selectedItems={this.state.selectedCustomPicker}
        styles={this.props.styles}
        createGenericItem={this.createNewItem}
        disabled={this.props.disabled}
      ></TagPicker>
    );

    return jsxCustomPicker;
  }
}
