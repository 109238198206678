import { IActivityAllAttributes } from "./Activity";
import filetypes from "./DeliverableFileTypes.json";

import { IColumn } from "@fluentui/react";
import { User } from "./User";

export interface IDeliverableFilter {
  fileName: string;
  fileType: string[];
  activityBrief: string;
  lastModifyStartDate?: Date;
  lastModifyEndDate?: Date;
  startIndex: number;
  orderBy?: IColumn[];
}

export interface IListDeliverables {
  deliverables: IDeliverable[];
  count: number;
  permission: IDocumentPermission;
}

export interface ExtensionsDTO {
  id: number;
  fileExtension: string;
}

export interface ITemplates {
  fileName: string;
  fileExtension: string;
  fileType: DeliverableType;
}

export interface IDocumentPermission {
  visibility: boolean;
  canAdd: boolean;
  canDelete: boolean;
  canDownloadDeliverables: boolean;
}


export interface IDeliverable {
  // [x: string]: any;
  id: number;
  fileType?: DeliverableType;
  fileExtension?: string;
  // idActivity?: number;
  idBrief?: number;
  // activityBrief?: string;
  fileName?: string;
  // filePath?: string;
  // fileDownload?: string;
  // fileSize?: number;
  // editedBy?: User;
  created?: Date;
  lastUpdate?: Date;
  // size?: string;
  showContextMenu?: boolean;
  entityId?: number;
  entityName?: string;
  entityType?: string;
  // embedUrl?: string;
}

export interface IDeliverableDetails {
  id: number;
  entityId?: number;
  entityName?: string;
  entityType?: string;
  fileType?: DeliverableType;
  fileExtension?: string;
  // idActivity?: number;
  idBrief?: number;
  // activityBrief?: string;
  fileName?: string;
  filePath?: string;
  fileDownload?: string;
  fileSize?: number;
  editedBy?: User;
  created?: Date;
  lastUpdate?: Date;
  size?: string;
  embedUrl?: string;
}

export interface IDeliverableVersion {
  id: number;
  documentVersion: string; 
  fileSize: number;
  editedBy: User;
  lastModify: Date;
  isCurrent: boolean;
}

export const DeliverableFileTypes: IDeliverableFileType[] = filetypes;

export interface IDeliverableFileType {
  readonly key: string;
  readonly name: string;
  readonly associatedExtensions: string[];
  readonly iconName: string;
}

export enum DeliverableType {
  EXCEL = "excel",
  WORD = "word",
  ONENOTE = "onenote",
  POWERPOINT = "powerpoint",
  PNG = "png",
  JPG = "jpg",
  GIF = "gif",
  TEXT = "text",
  PDF = "pdf",
}

export const FileExtensionsDeliverableType = new Map<string, DeliverableType>([
  ["xlsx", DeliverableType.EXCEL],
  ["ppt", DeliverableType.POWERPOINT],
  ["docx", DeliverableType.WORD],
  ["one", DeliverableType.ONENOTE],
  ["pdf", DeliverableType.PDF],
  ["jpg", DeliverableType.JPG],
  ["png", DeliverableType.GIF],
  ["gif", DeliverableType.PNG],
]);


export const DeliverableTypeLabels = new Map<string, string>([
  [DeliverableType.EXCEL, "Excel workbook"],
  [DeliverableType.POWERPOINT, "PowerPoint presentation"],
  [DeliverableType.WORD, "Word document"],
  [DeliverableType.ONENOTE, "OneNote notebook"],
  [DeliverableType.PNG, "PNG image"],
  [DeliverableType.JPG, "JPG image"],
  [DeliverableType.GIF, "GIF image"],
  [DeliverableType.TEXT, "Text document"],
  [DeliverableType.PDF, "PDF document"],
]);

export const DeliverableTypeIcons = new Map<string, string>([
  [DeliverableType.EXCEL, "ExcelDocument"],
  [DeliverableType.POWERPOINT, "PowerPointDocument"],
  [DeliverableType.WORD, "WordDocument"],
  [DeliverableType.ONENOTE, "OneNoteLogo"],
  [DeliverableType.PNG, "FileImage"],
  [DeliverableType.JPG, "FileImage"],
  [DeliverableType.GIF, "FileImage"],
  [DeliverableType.TEXT, "TextDocument"],
  [DeliverableType.PDF, "PDF"],
]);

export const FileExtensionsIcons = new Map<string, string>([
  ["xlsx", "ExcelDocument"],
  ["ppt", "PowerPointDocument"],
  ["docx", "WordDocument"],
  ["one", "OneNoteLogo"],
  ["pdf", "pdf"],
  ["jpg", "Photo2"],
]);
