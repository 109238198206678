import * as React from "react";

import "bootstrap/dist/css/bootstrap.css";
import "@fluentui/react/dist/css/fabric.min.css";
import { initializeIcons } from "@uifabric/icons";
import { BriefTranslation } from "../../../Translations/Brief.Translation";
import styles from "./PanelBriefHeaderComponent.module.scss";

import {
  CommandBarButton,
  ContextualMenu,
  DirectionalHint,
  IColumn,
  IContextualMenuItem,
  IContextualMenuProps,
} from "@fluentui/react";

import { IBaseProperties } from "../../../Models/IBaseProperties";
import { IBriefDetail } from "../../../Models/Brief";
import { IBriefClient } from "../../../Clients/IBriefClient";

export interface IPanelBriefHeaderComponentProps extends IBaseProperties {
  item?: IBriefDetail;
  idBrief?: number;
  titleBrief?: string;
  subTitle?: string;
  text?: string;
  showContextMenu: boolean;
  showStatus: boolean;
  showDeleteButton: boolean;
  deleteBrief?: (itemId: number) => void;
  downloadAsPdf?: (id: number) => void;
}

export interface IPanelBriefHeaderComponentState {
  briefContextualMenuProps?: IContextualMenuProps;
  statusSelected?: string;
}

export class PanelBriefHeaderComponent extends React.Component<
  IPanelBriefHeaderComponentProps,
  IPanelBriefHeaderComponentState
> {
  _taskColumns: IColumn[] = [];
  private readonly _translation: BriefTranslation;
  private readonly _briefClient: IBriefClient;

  constructor(props: IPanelBriefHeaderComponentProps) {
    super(props);

    initializeIcons();
    this._translation = new BriefTranslation(
      this.props.commonProps.translation
    );
    this._briefClient = this.props.commonProps.clientCreator.createBriefClient();

    this.state = {
      statusSelected: this.props.item?.status,
    };
  }

  public componentDidMount() {}

  public render() {
    return (
      <div className={"row " + styles.PanelBriefHeaderComponent}>
        <div className={styles.TitleDetails}>
          {this.props.subTitle && (
            <div className={styles.SubTitleDetails}>{this.props.subTitle}</div>
          )}
          {this.props.item ? this.props.item?.title : this.props.titleBrief ? this.props.titleBrief : this.props.text}
        </div>
        {this.props.showStatus && this.props.item?.status && (
          <div className={styles.statusChangeDetails}>
            {/* <div className={styles.statuslabelDetails}>STATUS</div> */}
            <div
              className={
                this.props.subTitle
                  ? styles.statusMarginWithSubtitle
                  : styles.statusMargin
              }
            >
              <span
                status-type={this.props.item.status.toLowerCase()}
                className={styles["status"]}
              >
                {this.props.item.status}
              </span>
            </div>
          </div>
        )}

        {this.props.showContextMenu && (
          <div className={styles.contextMenuHeaderDetails}>
            <CommandBarButton
              role="menuitem"
              aria-label="More items"
              className={styles.MenuProps}
              // styles={onRenderOverflowButtonStyles}
              menuIconProps={{ iconName: "MoreVertical" }}
              menuProps={this.state.briefContextualMenuProps}
              onClick={(ev: React.MouseEvent<HTMLElement>) => {
                if (this.props.item?.id) {
                  this.setState({
                    briefContextualMenuProps: this._getBriefContextualMenuProps(
                      ev,
                      this.props.item.id
                    ),
                  });
                } else if (this.props.idBrief) {
                  this.setState({
                    briefContextualMenuProps: this._getBriefContextualMenuProps(
                      ev,
                      this.props.idBrief
                    ),
                  });
                }
              }}
            />
            {this.state.briefContextualMenuProps && (
              <ContextualMenu {...this.state.briefContextualMenuProps} />
            )}
          </div>
        )}
        <hr />
      </div>
    );
  }

  private _onBriefContextualMenuDismissed = (ev: any, dp: any): void => {
    this.setState({
      briefContextualMenuProps: undefined,
    });
  };

  private _getBriefContextualMenuProps(
    ev: React.MouseEvent<HTMLElement>,
    itemId: number
  ): IContextualMenuProps {
    var items: IContextualMenuItem[] = [
      {
        key: "delete",
        text: this._translation.delete,
        disabled: !this.props.showDeleteButton,
        onClick: () => {
          if (this.props.deleteBrief && this.props.item)
            return this.props.deleteBrief(this.props.item?.id);
            else if (this.props.deleteBrief && this.props.idBrief)
            return this.props.deleteBrief(this.props.idBrief);
        },
      },
      {
        key: "download",
        text: this._translation.download,
        onClick: () => {
          if (this.props.downloadAsPdf && this.props.item)
            this.props.downloadAsPdf(this.props.item.id);
            else if (this.props.downloadAsPdf && this.props.idBrief)
            return this.props.downloadAsPdf(this.props.idBrief);
        },
      },
    ];

    return {
      items: items,
      target: ev.currentTarget as HTMLElement,
      directionalHint: DirectionalHint.bottomLeftEdge,
      gapSpace: 10,
      isBeakVisible: false,
      onDismiss: this._onBriefContextualMenuDismissed,
    };
  }
}
