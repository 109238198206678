import React from 'react'
import PropTypes, { any } from 'prop-types'
import createClasses from '../../utils/classes'
import { Utility } from "../../../../../../Utility/Utility";
import { ActivityTooltip } from "../../../../Planner/ActivityTooltip";
import { TooltipHost, TooltipDelay, DirectionalHint } from '@fluentui/react';

const buildDataAttributes = (attributes = {}) => {
  const value = {}
  Object.keys(attributes).forEach(name => {
    value[`data-${name}`] = attributes[name]
  })
  return value
}

const Basic = ({ title, start, end, style, classes, dataSet, tooltip, tracks,tracks2, element, commonProps, width }) => {

  const activity = element;

  if (tracks2)
  {
    activity.microsState = tracks2;
  }
  let maxMicroItems = Number.MAX_SAFE_INTEGER;
  let maxElementsInMacroBox = 0;  
  let plusAdded = false;

  if (!isNaN(width) && Array.isArray(tracks2) && tracks2.length !== 0)
  {
    const leftFixedMargin = 10; //first circle start from 10 pixel
    const circleSize = 16; //each circle is 8 px wide and has an 8 px margin to the right
    const iWidth = parseInt(width) - leftFixedMargin; 
    const microNumber = tracks2.length;            
    maxElementsInMacroBox = Math.floor(iWidth / circleSize);
    // let elementInMacroBox = Math.ceil(iWidth / (microNumber * circleSize));
    
    

    if (maxElementsInMacroBox > 0 && maxElementsInMacroBox > microNumber) //if true, the macro box can contains all circles 
    {
      maxMicroItems = microNumber;
    }
    else if (maxElementsInMacroBox === 1 && microNumber > 1)
    {
      maxMicroItems = 0;
    }
    else
    {
      maxMicroItems = maxElementsInMacroBox -1; //trim circle layout to maxElementsInMacroBox
    }

  }

  return (
    <TooltipHost
      calloutProps={{ gapSpace: 0 }}
      tooltipProps={{
        onRenderContent: () => renderActivityToolTip(commonProps, activity)
      }}
      delay={TooltipDelay.zero}
      id={element.id}
      directionalHint={DirectionalHint.bottomCenter} >
      {/* <div className={createClasses('rt-element', classes)} style={style} {...buildDataAttributes(dataSet)}> */}
      <div className={createClasses('rt-element', classes)} {...buildDataAttributes(dataSet)}>
        
        <div className={element.isMacro ? "rt-element__content rt-element-macro" : "rt-element__content"} aria-hidden="true">
            {
              element.isMacro && tracks2 !== undefined && tracks2.length !== 0 &&  
              <div className="rt-element__states" >
                {tracks2.map((track, index) => {
                  if (index < maxMicroItems)
                  {
                    return <div key={index} className={["rt-track-key__state", Utility.GetActivityClassNameFromStatus(track.state, false)].join(" ")} ></div>
                  }
                  else
                  {
                    if (maxElementsInMacroBox > 0 && !plusAdded)
                    {
                      plusAdded = true;                      
                      return <div key={index} className="rt-track-key__state_ellipsis">...</div>
                    }
                    else
                    {
                      return <></>;
                    }
                  }
                })
                }
              </div>
            }
        </div>
      </div>
    </TooltipHost>
  ); 
}

function renderActivityToolTip(commonProps, event) {
  return (
    <ActivityTooltip commonProps={commonProps} activity = {event}></ActivityTooltip>
  );   
}
Basic.propTypes = {
  title: PropTypes.string.isRequired,
  start: PropTypes.instanceOf(Date).isRequired,
  end: PropTypes.instanceOf(Date).isRequired,
  style: PropTypes.shape({}),
  classes: PropTypes.arrayOf(PropTypes.string.isRequired),
  dataSet: PropTypes.shape({}),
  tooltip: PropTypes.string,
  tracks: any,
  element: any,
  commonProps: PropTypes.object.isRequired,
  width: PropTypes.number.isRequired,
}

export default Basic
