import * as React from "react";
import styles from "./AccordionComponent.module.scss";
import "./AccordionComponent.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { IBaseProperties } from "../../../Models/IBaseProperties";
import { FAQ } from "../../../Models/FAQ";

export interface IAccordionComponentProps extends IBaseProperties {
  items: Array<any>;
  columnTitle: string;
  columnText: string;
  columnChoice: string;
  allowZeroExpanded: boolean;
  allowMultipleExpanded: boolean;
}

export interface IAccordionComponentState {
  items: Array<any>;
}

export default class AccordionComponent extends React.Component<
  IAccordionComponentProps,
  IAccordionComponentState
> {
  constructor(props: IAccordionComponentProps) {
    super(props);
    this.state = {
      items: new Array<any>(),
    };
  }

  public componentDidMount(): void {
    this.setState({
      items: this.props.items
    });
  }

  public render(): React.ReactElement<IAccordionComponentProps> {
    return (
      <div className={styles.AccordionComponent}>
        <div>
          <Accordion
            allowZeroExpanded={this.props.allowZeroExpanded}
            allowMultipleExpanded={this.props.allowMultipleExpanded}
          >
            {this.state.items.map((item: FAQ) => {
              return (
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton title={item.Question}>
                      {item.Question}
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: item.Answer,
                      }}
                    />
                  </AccordionItemPanel>
                </AccordionItem>
              );
            })}
          </Accordion>
        </div>
      </div>
    );
  }
}
