import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Header from './Header'
import Body from './Body'
import NowMarker from './Marker/Now'
import PointerMarker from './Marker/Pointer'
import getMouseX from '../../utils/getMouseX'
import getGrid from '../../utils/getGrid'

class Timeline extends Component {
  constructor(props) {
    super(props)

    this.state = {
      pointerDate: null,
      pointerVisible: false,
      pointerHighlighted: false,
    }
  }

  handleMouseMove = e => {
    const { time } = this.props
    this.setState({ pointerDate: time.fromX(getMouseX(e)) })
  }

  handleMouseLeave = () => {
    this.setState({ pointerHighlighted: false })
  }

  handleMouseEnter = () => {
    this.setState({ pointerVisible: true, pointerHighlighted: true })
  }

  fillDaysForYear(start)
  {
    const months = 12;
    let month = 0;
    let monthDays = [];    
    while(month < months){
      
      const firstMonthDay = this.addMonths(start, month);
      monthDays.push(firstMonthDay);
      month++;
    }

    return monthDays;
  }

  addMonths(date, months) {
    let newDate = new Date(date);
    const d = newDate.getDate();
    newDate.setMonth(newDate.getMonth() + +months);
    if (newDate.getDate() != d) {
      newDate.setDate(0);
    }
    return newDate;
  }

  adjustDateRange(start, end, items)
  {
    if (items && Array.isArray(items))
    {
      items.forEach(item=> 
        {
          this.updateElements(item, start, end);

          this.updateTracks(item, start, end)
        }
      );
    }
  }

  updateTracks(item, start, end) {
    if (item.tracks && Array.isArray(item.tracks)) {
      item.tracks.forEach(element => {
        if (element.start < start) {
          element.start = start
        }

        if (element.end > end) {
          element.end = end
        }

        this.updateElements(item, start, end)
      })
    }
  }

  updateElements(item, start, end) {
    if (item.elements && Array.isArray(item.elements)) {
      item.elements.forEach(element => {
        if (element.start < start) {
          element.start = start
        }

        if (element.end > end) {
          element.end = end
        }

        this.updateTracks(element, start, end);
      })
    }
  }

  render() {
    const { now, time, timebar, tracks, tracks2, sticky, clickElement, onClickDate, selectedDay } = this.props

    const { pointerDate, pointerVisible, pointerHighlighted } = this.state

    const grid = getGrid(timebar)

    const start = new Date (time.start);
    const end = new Date (time.end);
    
    let days = []; 

    if (time.view && time.view.toLowerCase()==='year')
    {
      days = this.fillDaysForYear(start);
    }
    else
    {
      days = this.fillDaysByRange(start, end)
    }

    //Update start and end to all items in order to prevent the timeline from cutting the entire display line
    this.adjustDateRange(time.start, time.end, tracks);

    return (
      <div className="rt-timeline" style={{ width: time.timelineWidthStyle }}>
        {now && <NowMarker now={now} visible time={time} />}
        {/* {pointerDate && (
          <PointerMarker date={pointerDate} time={time} visible={pointerVisible} highlighted={pointerHighlighted} />
        )} */}
        {days.map((day, index) => {
          return <PointerMarker key={index} date={day} time={time} visible={pointerVisible} highlighted={pointerHighlighted} />
        })}
        <Header
          time={time}
          timebar={timebar}
          onMove={this.handleMouseMove}
          onEnter={this.handleMouseEnter}
          onLeave={this.handleMouseLeave}
          onClickDate={onClickDate}
          selectedDay={selectedDay}
          width={time.timelineWidthStyle}
          sticky={sticky}
        />
        <Body time={time} grid={grid} tracks={tracks} tracks2={tracks2} clickElement={clickElement} />        
      </div>
    )
  }

  fillDaysByRange(start, end) {
    const  days = [];
    start = new Date(start.setDate(start.getDate() - 1))
    while (start <= end) {
      const newDate = start.setDate(start.getDate() + 1)
      start = new Date(newDate)
      days.push(start)
    }
    return days
  }
}

Timeline.propTypes = {
  now: PropTypes.instanceOf(Date),
  time: PropTypes.shape({
    fromX: PropTypes.func.isRequired,
    toStyleLeftAndWidth: PropTypes.func,
    timelineWidthStyle: PropTypes.string,
  }).isRequired,
  timebar: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string,
    }).isRequired
  ).isRequired,
  tracks: PropTypes.arrayOf(PropTypes.shape({})),
  sticky: PropTypes.shape({}),
  clickElement: PropTypes.func,
  onClickDate:  PropTypes.func.isRequired,
  selectedDay: PropTypes.instanceOf(Date),
}

export default Timeline
