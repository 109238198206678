import * as React from "react";
import styles from "./FAQComponent.module.scss";
import { Dropdown, FontIcon, IDropdownOption } from "office-ui-fabric-react";
import { IBaseProperties } from "../../../../../../Models/IBaseProperties";
import {
  ALL,
  RoleCounter,
  SupportLanguage,
  SupportPagesEnum,
} from "../../../../../../Models/SupportPage";
import { LoaderComponent } from "../../../../../Shared/LoaderComponent/LoaderComponent";
import { SupportTranslation } from "../../../../../../Translations/Support.Translation";
import { ISupportClient } from "../../../../../../Clients/ISupportClient";
import AccordionComponent from "../../../../../Shared/AccordionComponent/AccordionComponent";
import { FAQ } from "../../../../../../Models/FAQ";

export interface FAQComponentProps extends IBaseProperties {
  languages: SupportLanguage[];
  languageSelected: SupportLanguage;
}
export interface FAQComponentState {
  showMobileStates: boolean;
  rolesCounters: RoleCounter[];
  isLoading: boolean;
  changeFilterLoading: boolean;
  roleSelected: string;
  roles: string[];
  languageSelected: SupportLanguage;
  languages: SupportLanguage[];
  items: {
    role: string;
    items: FAQ[];
  }[];
}

export class FAQComponent extends React.Component<
  FAQComponentProps,
  FAQComponentState
> {
  private readonly _translation: SupportTranslation;
  private readonly _supportClient: ISupportClient;

  constructor(props: FAQComponentProps) {
    super(props);

    this._translation = new SupportTranslation(
      this.props.commonProps.translation
    );
    this._supportClient = this.props.commonProps.clientCreator.createSupportClient();

    this._changeRole = this._changeRole.bind(this);
    this._getFAQItems = this._getFAQItems.bind(this);
    this._getFAQItemsByRole = this._getFAQItemsByRole.bind(this);

    this.state = {
      showMobileStates: false,
      rolesCounters: [],
      isLoading: true,
      changeFilterLoading: false,
      languageSelected: this.props.languageSelected,
      languages: this.props.languages,
      roleSelected: ALL,
      roles: [],
      items: [],
    };
  }

  public componentDidMount() {
    this._supportClient
      .getRoles(SupportPagesEnum.Faq, this.state.languageSelected)
      .then((roles) => {
        this._supportClient
          .getCounters(
            SupportPagesEnum.Faq,
            this.state.languages,
            this.state.roleSelected
          )
          .then(async (counters) => {
            this._getFAQItems(roles, this.state.languageSelected).then(
              (allItems) => {
                if (counters?.roleCounter) {
                  this.setState({
                    rolesCounters: counters?.roleCounter,
                    roles: roles,
                    isLoading: false,
                    items: allItems,
                  });
                }
              }
            );
          });
      });
  }

  private async _changeRole(type: string) {
    this.setState({
      isLoading: false,
      roleSelected: type,
    });
  }

  private async _getFAQItems(
    roles: string[],
    language: SupportLanguage
  ): Promise<{ role: string; items: FAQ[] }[]> {
    var allItems = [];
    for (var i = 0; i < roles.length; i++) {
      var role = roles[i];
      var items = await this._getFAQItemsByRole(role, language);
      allItems.push({
        role: role,
        items: items,
      });
    }

    return allItems;
  }

  private async _getFAQItemsByRole(
    role: string,
    language: SupportLanguage
  ): Promise<FAQ[]> {
    var result: FAQ[] = [];
    try {
      if (typeof role !== undefined) {
        var items = await this._supportClient.getItems(
          SupportPagesEnum.Faq,
          language,
          role
        );
        result = items as FAQ[];
      }
    } catch (e) {
      console.log("Failed to get list items!");
      console.log(e);
    }
    return result;
  }

  public render() {
    return (
      // <div></div>

      <div className={"faqs " + styles.faqs}>
        <LoaderComponent
          commonProps={this.props.commonProps}
          isLoading={this.state.isLoading}
        />
        {!this.state.isLoading && (
          <div className={styles.content}>
            <div className={styles.backgroundDiv}>
              <div>
                <div className={styles.lowpanel}>
                  <div
                    className={
                      "ms-Grid-col ms-xl8 ms-lg12 ms-md12 ms-sm12 " +
                      styles.hiddenMobile
                    }
                  >
                    <ul
                      className={"nav nav-tabs " + styles.navTabs}
                      role="tablist"
                    >
                      <li className={styles.tab_pnl_home}>
                        <a
                          className={
                            styles.navLink +
                            " " +
                            (this.state.roleSelected == ALL
                              ? styles.active
                              : styles.notActive)
                          }
                          data-toggle="tab"
                          role="tab"
                          aria-controls="tab1"
                          aria-selected="true"
                          onClick={() => {
                            this._changeRole(ALL);
                          }}
                        >
                          <div>All ({this.state.rolesCounters[0].count})</div>
                        </a>
                      </li>
                      {this.state.rolesCounters
                        .filter((x) => x.role != ALL)
                        .map((counter) => {
                          return (
                            <li className={styles.tab_pnl_home}>
                              <a
                                className={
                                  styles.navLink +
                                  " " +
                                  (this.state.roleSelected == counter.role
                                    ? styles.active
                                    : styles.notActive)
                                }
                                data-toggle="tab"
                                role="tab"
                                aria-controls="tab1"
                                aria-selected="true"
                                onClick={() => {
                                  this._changeRole(counter.role);
                                }}
                              >
                                <div>
                                  {counter.role + " (" + counter.count + ")"}
                                </div>
                              </a>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                  <div className={"ms-Grid-col ms-xl4 ms-lg12 ms-md12 ms-sm12"}>
                    <div
                      id="filterLanguage"
                      className={styles.divFilterLanguage}
                    >
                      <Dropdown
                        placeholder="Filter language"
                        defaultSelectedKey={this.state.languageSelected}
                        onChange={(
                          event: React.FormEvent<HTMLDivElement>,
                          item: IDropdownOption | undefined
                        ) => {
                          this.setState(
                            {
                              changeFilterLoading: true,
                            },
                            () => {
                              this._getFAQItems(
                                this.state.roles,
                                item?.key.toString() as unknown as SupportLanguage
                              ).then((items) => {
                                this.setState({
                                  languageSelected: item?.key.toString() as unknown as SupportLanguage,
                                  items: items,
                                  changeFilterLoading: false,
                                });
                              });
                            }
                          );
                        }}
                        options={this.state.languages.map((x) => {
                          return {
                            key: x,
                            text: this._supportClient.getLanguageLabel(x),
                          };
                        })}
                        className={styles.filterLanguage}
                        onRenderPlaceholder={() => {
                          return (
                            <div>
                              <FontIcon
                                iconName={"Globe"}
                                className={styles.IconGlobe}
                              />
                              <div className={styles.divTextSelected}>
                                Filter language
                              </div>
                            </div>
                          );
                        }}
                        // onRenderTitle={(options: IDropdownOption[]) => {
                        //   if (options) {
                        //     const option = options[0];

                        //     return (
                        //       <div>
                        //         <FontIcon
                        //           iconName={"Globe"}
                        //           className={styles.IconGlobe}
                        //         />
                        //         <div className={styles.divTextSelected}>
                        //           {options.map((x) => {
                        //             return (
                        //               <div className={styles.ItemSelected}>
                        //                 <div
                        //                   className={styles.TextItemSelected}
                        //                 >
                        //                   {x.text}
                        //                 </div>
                        //               </div>
                        //             );
                        //           })}
                        //         </div>
                        //       </div>
                        //     );
                        //   } else {
                        //     return <div></div>
                        //   }
                        // }}
                        onRenderCaretDown={() => {
                          return (
                            <div>
                              <FontIcon
                                iconName={"ChevronDownMed"}
                                className={styles.IconGlobe}
                              />
                            </div>
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="ms-Grid-col ms-xl12 ms-lg12 ms-md12 ms-sm12">
                    <div className={styles.margin_top_mobile}>
                      <div
                        className={styles.panel_state + " " + styles.onlyMobile}
                      >
                        <div className={styles.statePanel}>
                          <span className={styles.activeState}>
                            {this.state.roleSelected}
                          </span>
                          <span
                            className={
                              styles.selState + " ms-Icon ms-Icon--Equalizer"
                            }
                            onClick={() => {
                              this.setState({
                                showMobileStates: !this.state.showMobileStates,
                              });
                            }}
                          ></span>
                        </div>
                        <div>
                          {this.state.showMobileStates && (
                            <ul
                              className={
                                "nav nav-tabs hidden-sm hidden-md hidden-lg " +
                                styles.navTabs
                              }
                              role="tablist"
                            >
                              <li
                                className={
                                  styles.tab_pnl_home +
                                  " ms-Grid-col ms-xl6 ms-lg6 ms-md6 ms-sm12"
                                }
                              >
                                <a
                                  className={
                                    "nav-link " +
                                    " " +
                                    (this.state.roleSelected == ALL
                                      ? styles.active
                                      : styles.notActive)
                                  }
                                  data-toggle="tab"
                                  role="tab"
                                  aria-controls="tab1"
                                  aria-selected="true"
                                  onClick={() => {
                                    this._changeRole(ALL);
                                  }}
                                >
                                  <div>All</div>
                                </a>
                              </li>
                              {this.state.rolesCounters.map((counter) => {
                                return (
                                  <li
                                    className={
                                      styles.tab_pnl_home +
                                      " ms-Grid-col ms-xl6 ms-lg6 ms-md6 ms-sm12"
                                    }
                                  >
                                    <a
                                      className={
                                        styles.navLink +
                                        " " +
                                        (this.state.roleSelected == counter.role
                                          ? styles.active
                                          : styles.notActive)
                                      }
                                      data-toggle="tab"
                                      role="tab"
                                      aria-controls="tab1"
                                      aria-selected="true"
                                      onClick={() => {
                                        this._changeRole(counter.role);
                                      }}
                                    >
                                      <div>
                                        {counter.role +
                                          " (" +
                                          counter.count +
                                          ")"}
                                      </div>
                                    </a>
                                  </li>
                                );
                              })}
                            </ul>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className={
                      styles.tab_content +
                      " ms-Grid-col ms-xl12 ms-lg12 ms-md12 ms-sm12"
                    }
                  >
                    {this.state.changeFilterLoading && (
                      <div
                        id="tab1"
                        role="tabpanel"
                        aria-labelledby="tab1-tab"
                        className={styles.tabSection}
                      >
                        <div
                          className={
                            "ms-Grid-col ms-xl12 ms-lg12 ms-md12 ms-sm12"
                          }
                        >
                          <LoaderComponent
                            commonProps={this.props.commonProps}
                            isLoading={this.state.changeFilterLoading}
                          ></LoaderComponent>
                        </div>
                      </div>
                    )}
                    {!this.state.changeFilterLoading && (
                      <div
                        id="tab1"
                        role="tabpanel"
                        aria-labelledby="tab1-tab"
                        className={styles.tabSection}
                      >
                        {this.state.items.map((list) => {
                          if (
                            this.state.roleSelected == ALL ||
                            this.state.roleSelected == list.role
                          ) {
                            return (
                              <div className={styles.divSection}>
                                <div className={styles.HeaderSection}>
                                  {list.role.toLowerCase()}
                                </div>
                                <div className={styles.AccordionSection}>
                                  <AccordionComponent
                                    commonProps={this.props.commonProps}
                                    items={list.items}
                                    columnTitle={"Question"}
                                    columnText={"Answer"}
                                    columnChoice={"Role"}
                                    allowZeroExpanded={true}
                                    allowMultipleExpanded={true}
                                  ></AccordionComponent>
                                </div>
                              </div>
                            );
                          } else {
                            return <div></div>;
                          }
                        })}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
