import * as React from "react";

import { IBaseProperties } from "../../../../Models/IBaseProperties";
import { DashboardTranslation } from "../../../../Translations/Dashboard.Translation";

import styles from "./WelcomeMessageComponent.module.scss";

export interface IWelcomeMessageComponentProps extends IBaseProperties {
  userLogged: string;
}
export interface IWelcomeMessageComponentState {}

export class WelcomeMessageComponent extends React.Component<
  IWelcomeMessageComponentProps,
  IWelcomeMessageComponentState
> {
  private readonly _translation: DashboardTranslation;

  constructor(props: IWelcomeMessageComponentProps) {
    super(props);

    this._translation = new DashboardTranslation(
      this.props.commonProps.translation
    );

    this.state = {};
  }

  render() {
    return (
      <div role="welcomeMessage" className={styles.welcome}>
        <h2>{this._translation.welcomeMessage(this.props.userLogged)}</h2>
        <h2>{this._translation.welcomeMessage2("Enel Media Planning")}</h2>
      </div>
    );
  }
}
