import * as React from "react";
import { ActionButton, PersonaSize, Spinner, Text } from "@fluentui/react";
import styles from "./HistoryCardComponent.module.scss";
import {
  IBaseProperties,
  IRoute,
  Page,
} from "../../../../Models/IBaseProperties";
import { PersonaComponent } from "../../../Shared/PersonaComponent/PersonaComponent";
import { User } from "../../../../Models/User";
import moment from "moment";
import {
  INotificationHistory,
  NotificationSubType,
  NotificationType,
  NotificationUtilities,
} from "../../../../Models/Notification";
import { INotificationHistoryClient } from "../../../../Clients/INotificationHistoryClient";
import { LoaderComponent } from "../../../Shared/LoaderComponent/LoaderComponent";
import { CommonTranslation } from "../../../../Translations/Common.Translation";
import { NotificationTranslation } from "../../../../Translations/Notification.Translation";

export interface IHistoryCardProps extends IBaseProperties { }
export interface IHistoryCardState {
  notifications: INotificationHistory[];
  isLoading: boolean;
  page: number;
}

export class HistoryCardComponent extends React.Component<
  IHistoryCardProps,
  IHistoryCardState
> {
  private readonly _users: User[] = [
    { id: "1", initials: "FC", userPrincipalName: "", displayName: "Fabio C." },
  ];
  private readonly _client: INotificationHistoryClient;
  private readonly _translation: NotificationTranslation;

  constructor(props: IHistoryCardProps) {
    super(props);

    this._client = props.commonProps.clientCreator.createNotificationHistoryClient();
    this._translation = new NotificationTranslation(
      props.commonProps.translation
    );

    this.state = {
      notifications: [],
      isLoading: true,
      page: 0,
    };

    this.showNextPage = this.showNextPage.bind(this);
  }

  private showNextPage() {
    const { page } = this.state;
    this.setState(
      {
        page: page + 1,
        isLoading: true,
      },
      async () => {
        await this.loadHistoryNotificationsAsync();
      }
    );
  }

  async componentDidMount() {
    await this.loadHistoryNotificationsAsync();
  }

  private async loadHistoryNotificationsAsync() {
    try {
      const { page, notifications } = this.state;
      let newNotifications = await this._client.getNotifications(page);

      if (newNotifications) {
        if (notifications && notifications.length > 0) {
          newNotifications = notifications.concat(newNotifications);
        }

        this.setState({
          notifications: newNotifications,
        });
      } else if (page !== 0) {
        this.setState({
          page: page - 1,
        });
      }
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  render() {
    const { notifications } = this.state;
    const vItems = notifications.map((t) => {
      return this.renderRow(t);
    });
    return (
      <div>
        {this.state.isLoading && (
          <Spinner
            label={this._translation.loadingMessageByKey("notificationHistory")}
          />
        )}
        {vItems}
        {!this.state.isLoading && (
          <div>
            <ActionButton allowDisabledFocus onClick={this.showNextPage}>
              Show more
            </ActionButton>
          </div>
        )}
      </div>
    );
  }

  private notificationClick(notification: INotificationHistory): void {
    const page = this.notificationToPage(notification);
    const route: IRoute = {
      page: page,
      additionalInfo: {
        keysValues: {
          id: notification.id.toString(),
          type: this.toType(notification),
          subtype: undefined,
          title: notification.title,
          showMyActivities: undefined
        },
      },
    };
    this.props.commonProps.onRoute?.next(route);
  }

  private toType(notification: INotificationHistory): string | undefined {
    let retValue: string | undefined = undefined;
    switch (notification.subType) {
      case NotificationSubType.macroactivityheadassignment:
      case NotificationSubType.macroactivityheadremoval:
      case NotificationSubType.macroactivityspocassignment:
      case NotificationSubType.macroactivityspocremoval:
        retValue = "1";
        break;
      case NotificationSubType.microactivityresourceassignment:
      case NotificationSubType.microactivityresourceremoval:
      case NotificationSubType.microactivityspocremoval:
      case NotificationSubType.microactivitystatuschange:
      case NotificationSubType.microactivitytaskcompletion:
      case NotificationSubType.microactivitytaskcreation:
      case NotificationSubType.microactivitytaskreopening:
        retValue = "2";
        break;
    }

    return retValue;
  }

  private notificationToPage(notification: INotificationHistory): Page {
    let page: Page = Page.None;

    switch (notification.type) {
      case NotificationType.Brief:
        page = Page.MyBrief;
        break;
      case NotificationType.Micro:
      case NotificationType.Macro:
        page = Page.Activities;
        break;

    }

    return page;
  }

  private renderRow(notification: INotificationHistory) {
    const mmDate = moment(notification.date);
    const onlyDate = mmDate.format("DD/MM/YYYY");
    const onlyTime = mmDate.format("HH:mm");
    const user = notification.author;
    const title = notification.title;
    const notificationType = NotificationUtilities.getNotificationStringType(this._translation, notification.subType);
    // const notification: INotificationHistory = { id: 105, title: 'title', type: NotificationType.Brief, subType: 1, author: { id: "0", userPrincipalName: 'aaa', initials: 'GS', displayName: 'Administrator' }, date: new Date, identifier: '123' };
    return (
      <div
        key={notification.notificationId}
        className={styles.activityCard}
        onClick={() => this.notificationClick(notification)}
      >
        <div className={styles.card_body}>
          <div className={styles.card_body_header}>
            <div className={styles.card_time}>
              <Text>{onlyTime}</Text>
            </div>
            <div className={styles.card_date}>
              <Text>{onlyDate}</Text>
            </div>
          </div>
          <div className={styles.card_body_content}>
            <div className={styles.card_user}>
              <div className={styles.card_user_persona}>
                <PersonaComponent
                  commonProps={this.props.commonProps}
                  persona={[user]}
                  personaSize={PersonaSize.size24}
                  maxLength={1}
                ></PersonaComponent>
              </div>
              <div>{user.displayName}</div>
            </div>
            <div className={styles.card_title}>{title}</div>
            <div className={styles.card_typestatus}>{notificationType}</div>
            <div className={styles.card_body_footer}></div>
          </div>
        </div>
      </div>
    );
  }
}
