import * as React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "@fluentui/react/dist/css/fabric.min.css";
import styles from "./RoutingComponent.module.scss";
import { IBaseProperties, IRoute, Page } from "../../../Models/IBaseProperties";
import { CommonTranslation } from "../../../Translations/Common.Translation";
import { LoaderComponent } from "../LoaderComponent/LoaderComponent";

export interface IRoutingComponentProps extends IBaseProperties { }

export interface IRoutingComponentState {
  loading: boolean;
}

export class RoutingComponent extends React.Component<
  IRoutingComponentProps,
  IRoutingComponentState
> {

  private readonly _translation: CommonTranslation;

  constructor(props: IRoutingComponentProps) {
    super(props);

    this._translation = new CommonTranslation(
      this.props.commonProps.translation,
      "common"
    );

    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    var urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("activity") != null) {
      var qs_activity = urlParams.get("activity");
      var qs_status = urlParams.get("status");
      var qs_activityType = urlParams.get("type");

      window.history.pushState({}, document.title, "/");
      const route: IRoute = {
        page: Page.Activities,
        additionalInfo: {
          keysValues: {
            id: qs_activity ? qs_activity : undefined,
            type: qs_activityType ? qs_activityType : undefined,
            subtype: undefined,
            title: undefined,
            showMyActivities: undefined
          },
          optionalValues: {
            status: qs_status ? qs_status : undefined,
          },
        },
      };
      this.props.commonProps.onRoute?.next(route);
    }
  }

  public render() {
    return (
      <div className={styles.RoutingComponent}>
        <LoaderComponent
          commonProps={this.props.commonProps}
          isLoading={this.state.loading}
          label={this._translation.waiting}
        />
      </div>
    );
  }
}
