import { TFunction } from "i18next";
import { CommonTranslation } from "./Common.Translation";

export class NotesTranslation extends CommonTranslation {
  constructor(translation: TFunction) {
    super(translation, "notes");
  }

  public get note(): string {
    return super.getTextByMessageKey("notes");
  }

  public get getNotesError(): string {
    return super.getTextByMessageKey("getNotesError");
  }

  public get getNoteError(): string {
    return super.getTextByMessageKey("getNoteError");
  }

  public get insertNoteError(): string {
    return super.getTextByMessageKey("addNoteError");
  }

  public get updateNoteError(): string {
    return super.getTextByMessageKey("updateNoteError");
  }

  public get deleteNoteError(): string {
    return super.getTextByMessageKey("removeNoteError");
  }
}
