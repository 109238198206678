import * as React from "react";
import { apiConfig } from "../../msal/MsalConfig";
import {
  ActionButton,
  Link,
  PersonaSize,
  Spinner,
  Text,
  elementContains,
} from "@fluentui/react";
import styles from "./UserComponent.module.scss";
import { Roles, Security } from "../../Utility/Security";
import { IBaseProperties, IRoute, Page } from "../../Models/IBaseProperties";
import { PersonaComponent } from "../Shared/PersonaComponent/PersonaComponent";
import { User } from "../../Models/User";
import { INotificationHistoryClient } from "../../Clients/INotificationHistoryClient";
import { CommonTranslation } from "../../Translations/Common.Translation";
import { IUserClient } from "../../Clients/IUserClient";
import { UsersTranslation } from "../../Translations/Users.Translation";
import { ToastNotificationType } from "../../Models/ToastNote";
import { IActivityClient } from "../../Clients/IActivityClient";
import { ICalendarPermission } from "../../Models/Planner";
import { BorderAllRounded } from "@material-ui/icons";

export interface IUserComponentProps extends IBaseProperties { }
export interface IUserComponentState {
  calendarPermission: ICalendarPermission[];
}

export class UserComponent extends React.Component<
  IUserComponentProps,
  IUserComponentState
> {
  private readonly _client: INotificationHistoryClient;
  private readonly _translation: UsersTranslation;
  private readonly _userClient: IUserClient;
  private readonly _activityClient: IActivityClient;

  constructor(props: IUserComponentProps) {
    super(props);

    this.state = {
      calendarPermission: []
    };

    this._client = props.commonProps.clientCreator.createNotificationHistoryClient();
    this._activityClient = props.commonProps.clientCreator.createActivityClient();
    this._userClient = props.commonProps.clientCreator.createUserClient();
    this._translation = new UsersTranslation(props.commonProps.translation);

    this.loadCalendars().then(permissions => {
      const filteredCalendars = permissions
        .filter(p => p.canCreate || p.canUpdate) // Filter calendars which user can operate
        .filter((p, index) => permissions.findIndex(pp => pp.calendarId === p.calendarId) === index); // Get distinct calendars
      this.setState({
        calendarPermission: filteredCalendars
      });
    });
  }

  private toExtendRole(group: string): string {
    let role = "";
    switch (group.toLowerCase()) {
      case Roles.Auditor.toLowerCase():
        role = "Auditor";
        break;
      case Roles.GMA.toLowerCase():
        role = "Advanced";
        break;
      case Roles.GMS.toLowerCase():
        role = "Specialist";
        break;
      case Roles.Guest.toLowerCase():
        role = "Guest";
        break;
      case Roles.Admin.toLowerCase():
        role = "Administrator";
        break;
    }

    return role;
  }

  private async loadCalendars() {
    var calendarsPermissions =
      (await this._activityClient.getCalendarsPermissions()) || [];
    var canCreateCalendarIds = calendarsPermissions
      .filter((p) => p.canCreate);

    return calendarsPermissions;
  }


  render() {
    const userInfo = Security.getCurrentUserInfo();

    const faq = apiConfig.externalLinks.faq;
    const support = apiConfig.externalLinks.support;
    const whoweare = apiConfig.externalLinks.whoweare;
    const contact = apiConfig.externalLinks.contact;

    const displayName = userInfo ? userInfo.displayName : "-";
    const roleName = userInfo ? this.toExtendRole(userInfo.group) : "-";
    const showGuestLinks = userInfo && userInfo.group.toLowerCase() === "guest";
    const user: User | undefined = userInfo
      ? {
        userPrincipalName: userInfo?.username,
        id: userInfo.id,
        displayName: userInfo.displayName,
        initials: userInfo.initials,
      }
      : undefined;

    const jsxContact = (
      <div className={styles["user-contacts"]}>
        <hr></hr>
        {/* <Link href={contact} target="_blank">
      Contacts
    </Link> */}
        <div
          onClick={() => {
            const route: IRoute = {
              page: Page.Contacts,
              additionalInfo: {
                keysValues: {
                  id: undefined,
                  type: undefined,
                  subtype: undefined,
                  title: undefined,
                  showMyActivities: undefined,
                },
              },
            };
            this.props.commonProps.onRoute?.next(route);
          }}
        >
          Contacts
        </div>
      </div>
    );
    const jsxFaq = (
      <div className={styles["user-faq"]}>
        <hr></hr>
        <div
          onClick={() => {
            const route: IRoute = {
              page: Page.FAQ,
              additionalInfo: {
                keysValues: {
                  id: undefined,
                  type: undefined,
                  subtype: undefined,
                  title: undefined,
                  showMyActivities: undefined,
                },
              },
            };
            this.props.commonProps.onRoute?.next(route);
          }}
        >
          F.A.Q.
        </div>
      </div>
    );

    const calendarJSX = (calendar: ICalendarPermission, index: number, role: string, isLast: boolean) => {
      if (isLast)
        return <div key={index}>{role} {calendar.calendarInternalName}</div>;
      else
        return <div key={index}>{role} {calendar.calendarInternalName},</div>;
    }


    return (
      <div className={styles["user-container"]} style={{ textAlign: "center" }}>
        <div className={styles["user-displayname"]}>
          {/* <div className={styles["user-persona"]}>            
          </div> */}
          <div>{displayName}</div>
        </div>
        <div className={styles["user-calendar"]}>
          {                      
            roleName == "Administrator" ? <div> Administrator - Media Planning </div>: this.state.calendarPermission.map((element, index) => (calendarJSX(element, index, roleName, this.state.calendarPermission.length - 1 == index)))          
          }
        </div>
        <div className={styles["user-whoweare"]}>
        </div>
        <div className={styles["user-logout"]}>
          <div
            tabIndex={0}
            onClick={() => {
              var result = this._userClient.logoutCurrentUser();
              if (!result) {
                this.props.commonProps.toastComponent?.showMessage(
                  this._translation.error,
                  this._translation.logoutUserError,
                  ToastNotificationType.ERROR
                );
              }
            }}
          >
            Logout
          </div>
        </div>
      </div>
    );
  }
}
