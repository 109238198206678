import { MacroDTO } from "../Models/DTO/MacroDTO";
import { APIUtility } from "../Utility/APIUtility";
import "../Utility/DateExtensions";
import { ActivityPermissions, IActivity, IMacro } from "../Models/Activity";
import { IMacroClient } from "./IMacroClient";
import { NoContent } from "../Models/NoContent";
import { HttpError } from "../Models/HttpResponse";
import { ErrorClient } from "./ErrorClient";
import { Utility } from "../Utility/Utility";

const HTTPStatusOk = 200;

export class MacroClient implements IMacroClient {
    private readonly _http: APIUtility = new APIUtility();

    //#region Public methods    
    public async getMacroAsync(): Promise<IActivity[] | undefined> {
        let retValue: IActivity[] | undefined = undefined;
        try {
            const actDTO = await this._http.getMacrosAsync(undefined, undefined);
            retValue = this.toIActivities(actDTO);
        }
        catch (err) {
            console.error(err);
        }
        finally {
            return retValue;
        }
    }

    public async saveMacroAsync(macro: IMacro): Promise<boolean> {
        let result: number = 0;
        try {
            const sMacro = macro;

            const form = this.buildFormData(sMacro);

            result = await this._http.saveMacroForm(form);

        }
        catch (err) {
            this.storeError(err);
        }
        finally {
            return this.validatePostResult(result);
        }

    }

    private storeError(err: HttpError): void {
        console.error(err);
        ErrorClient.storeHttpError(err);
    }

    private validatePostResult(result: number): boolean {
        return result === HTTPStatusOk ? true : false;
    }

    //#endregion    

    private buildFormData(sMacro: IMacro): FormData {
        const form = new FormData();

        //cannot be used because form property name are different
        // const keyValuePair: Array<Object> = Object.entries(sMacro).map(
        //     (key) => {
        //     return { text: key, value: key }
        //     }
        // );  

        form.append("Name", sMacro.name);
        form.append("CalendarId", sMacro.calendar.toString());
        form.append("DateTo", sMacro.dateTo.toISOString());
        form.append("DateFrom", sMacro.dateFrom.toISOString());
        form.append("Description", this.getStringFormValue(sMacro.description ? sMacro.description : ""));

        if (sMacro.headOfActivityUPN) {
            form.append("HeadOfActivityUPN", sMacro.headOfActivityUPN);
        }

        if (sMacro.spocUPN) {
            form.append("SpocUPN", sMacro.spocUPN);
        }

        if (sMacro.linkedItemBrief) {
            form.append("BriefId", sMacro.linkedItemBrief.id.toString());
        }

        if (sMacro.file) {
            form.append("BriefDocument", sMacro.file, sMacro.file.name);
        }

        if (sMacro.mediaPlan) {
            form.append("MediaPlanDateFrom", sMacro.mediaPlan.dateFrom.utc().toISOString());
            form.append("MediaPlanDateTo", sMacro.mediaPlan.dateTo.utc().toISOString());
            form.append("MediaPlanName", sMacro.mediaPlan.name);

            if (sMacro.mediaPlan.description) {
                form.append("MediaPlanDescription", sMacro.mediaPlan.description);
            }

            if (sMacro.mediaPlan.spocUPN) {
                form.append("MediaPlanSpocUPN", sMacro.mediaPlan.spocUPN);
            }

            if (sMacro.mediaPlan.resourcesUPNs) {
                sMacro.mediaPlan.resourcesUPNs.forEach(t =>
                    form.append("MediaPlanResourcesUPNs", t)
                );
            }

            if (sMacro.mediaPlan.externalResourcesUPNs) {
                sMacro.mediaPlan.externalResourcesUPNs.forEach(t =>
                    form.append("MediaPlanExternalResourcesUPNs", t)
                );
            }

        }

        return form;
    }

    private getStringFormValue(value: string | undefined): string {
        return value ? value : '';
    }

    private getNumericFormValue(value: number | undefined): string {
        return value ? value.toString() : '';
    }

    // public async getMacroAsync(): Promise<MacroDTO[]>
    // {
    //     return await this.getAPIMacroAsync();
    //     // return await this.getMokedMacros();
    // }


    private toIActivities(dto: MacroDTO[]): IActivity[] {
        return dto.map(t => this.toIActivity(t));
    }

    private toIActivity(dto: MacroDTO): IActivity {
        const act: IActivity =
        {
            id: dto.id,
            isMacro: true,
            name: dto.name,
            calendar: dto.calendarId,
            dateFrom: new Date(),
            dateTo: new Date(),
            permissions: Utility.getDefaultActivityPermissions()
        };

        return act;
    }
}