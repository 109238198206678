import moment from "moment";
import 'moment/locale/it';

export class DateHelper
{
    private readonly _locale: string;    
    private readonly _localMoment = ()=> moment().locale(this._locale);
    private static _monthName: string[];
    private static _monthShortName: string[];
    private static _weekDayShortName: string[];


    constructor(locale: string)
    {
        this._locale = locale;
        moment.locale(locale);

        DateHelper._monthName = this.getMonthNames();
        DateHelper._monthShortName = this.getMonthShortNames();
        DateHelper._weekDayShortName = this.getWeekDayShortNames();
    }

    private getMonthNames(): string[]
    {
      return this._localMoment().localeData().months();      
    }

    private getWeekDayShortNames(): string[]
    {        
        return this._localMoment().localeData().weekdaysShort();      
    }

    private getMonthShortNames(): string[]
    {        
        return this._localMoment().localeData().monthsShort();      
    }

    public static toFormatDate(date: Date, format: string): string
    {
        return moment(date).format(format);
    }

    

    public static get monthName(): string[]
    {
        return DateHelper._monthName;
    }

    public static get monthShortName(): string[]
    {
        return DateHelper._monthShortName;
    }

    public static get weekDayShortName(): string[]
    {
        return DateHelper._weekDayShortName;
    }
}