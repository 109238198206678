import * as React from "react";

import "bootstrap/dist/css/bootstrap.css";

import "@fluentui/react/dist/css/fabric.min.css";

import { initializeIcons } from "@uifabric/icons";

import { ActivityTranslation } from "../../../Translations/Activity.Translation";

import styles from "./DescriptionActivityViewComponent.module.scss";

import { FontIcon, IColumn } from "@fluentui/react";

import { PanelActivityHeaderComponent } from "../PanelActivityHeaderComponent/PanelActivityHeaderComponent";
import { IActivityAllAttributes, IMicro } from "../../../Models/Activity";

import { IBaseProperties } from "../../../Models/IBaseProperties";
import { IActivityClient } from "../../../Clients/IActivityClient";
import { ToastNotificationType } from "../../../Models/ToastNote";
import { SpinnerComponent } from "../SpinnerComponent/SpinnerComponent";
import RichTextEditor, { EditorValue } from "react-rte";
import { Constants } from "../../../Utility/Constants";

export interface IMicroActivityViewComponentProps extends IBaseProperties {
  item?: IMicro;
  itemId?: number;
  edit: boolean;
  updateDescription?: (text: string) => Promise<boolean>;
}

export interface IMicroActivityViewComponentState {
  item?: IMicro;
  isLoading: boolean;

  showDescription: boolean;
  editDescription: boolean;
  descriptionTemp: EditorValue;
}

export class DescriptionActivityViewComponent extends React.Component<
  IMicroActivityViewComponentProps,
  IMicroActivityViewComponentState
> {
  _taskColumns: IColumn[] = [];
  private readonly _translation: ActivityTranslation;
  private readonly _activityClient: IActivityClient;

  constructor(props: IMicroActivityViewComponentProps) {
    super(props);

    initializeIcons();
    this._translation = new ActivityTranslation(
      this.props.commonProps.translation
    );
    this._activityClient = this.props.commonProps.clientCreator.createActivityClient();

    this.renderActivityModalHeader = this.renderActivityModalHeader.bind(this);

    this.state = {
      // item: this.props.item,
      isLoading: false,
      showDescription: true,
      editDescription: false,
      descriptionTemp: this.props.item?.description
        ? RichTextEditor.createValueFromString(
            this.props.item?.description,
            "html"
          )
        : RichTextEditor.createEmptyValue(),
    };
  }

  componentDidMount() {
    if (this.props.item) {
      this.setState({
        item: Object.assign({}, this.props.item),
      });
    } else if (this.props.itemId) {
      this.setState({ isLoading: true });
      this._activityClient
        .getActivityDetails(this.props.itemId, false)
        .then((item) => {
          if (item) {
            this.setState({
              isLoading: false, // item: response,
              item: item,
            });
          } else {
            this.props.commonProps.toastComponent?.showMessage(
              this._translation.error,
              this._translation.getActivityError,
              ToastNotificationType.ERROR
            );
            this.setState({
              isLoading: false, // item: response,
            });
          }
        });
    }
  }

  private renderActivityModalHeader() {
    return (
      <PanelActivityHeaderComponent
        commonProps={this.props.commonProps}
        item={
          {
            ...this.state.item,
            isMacro: false,
          } as IActivityAllAttributes
        }
        isMacro={false}
        showContextMenu={false}
        showStatus={false}
        showType={true}
        showManage={false}
      ></PanelActivityHeaderComponent>
    );
  }

  private _decodeHTMLEntities(text: string) {
    var span = document.createElement("span");
    span.innerHTML = text;
    return span.textContent || span.innerText;
  }

  public render() {
    return this.state.isLoading ? (
      <SpinnerComponent
        commonProps={this.props.commonProps}
        relative={false}
      ></SpinnerComponent>
    ) : (
      <div
        role="microDescriptionConfiguration"
        className={styles.DescriptionActivityViewComponent}
      >
        <div className={styles.activity_details_parent_body + " row"}>
          <div className={"ms-Grid-col ms-sm12"}>
            <hr />
          </div>
          <div className={"ms-Grid-col ms-sm12"}>
            <div
              className={
                styles.label_ActivityAttribute + " ms-Grid-col ms-sm12"
              }
            >
              {this._translation.description}
            </div>
            {!this.state.showDescription && (
              <div className="ms-Grid-col ms-md12 ">
                <SpinnerComponent
                  commonProps={this.props.commonProps}
                  relative={false}
                ></SpinnerComponent>
              </div>
            )}
            {this.props.edit && this.state.showDescription && (
              <div
                className={
                  styles.value_ActivityAttribute +
                  " ms-Grid-col ms-md12 " +
                  styles.descriptionText
                }
              >
                <div className={styles.descriptionEditor}>
                  {!this.state.editDescription && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: !this.state.editDescription
                          ? this.state.descriptionTemp &&
                            this._decodeHTMLEntities(
                              this.state.descriptionTemp?.toString("html")
                            )
                            ? this.state.descriptionTemp?.toString("html") //.replaceAll("\n", "<br />")
                            : "---"
                          : "",
                      }}
                      className={
                        styles.labelDescription + " " + styles.editableField
                      }
                      tabIndex={0}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          if (this.props.item?.permissions.canUpdate)
                            this.setState({ editDescription: true });
                        }
                      }}
                      onClick={() => {
                        if (this.props.item?.permissions.canUpdate)
                          this.setState({ editDescription: true });
                      }}
                    >
                      {/* {!this.state.editDescription && .replace("\n", "<br />")} */}
                    </div>
                  )}
                  {this.state.editDescription && (
                    <RichTextEditor
                      // multiline autoAdjustHeight
                      className={styles.textDescription}
                      onChange={(newValue) => {
                        this.setState({
                          descriptionTemp: newValue
                            ? newValue
                            : RichTextEditor.createEmptyValue(),
                        });
                      }}
                      value={this.state.descriptionTemp}
                      toolbarConfig={Constants.toolbarRichTextEditor}
                    ></RichTextEditor>
                  )}
                  <div className={styles.descriptionEdit}>
                    {!this.state.editDescription &&
                      this.props.item?.permissions.canUpdate && (
                        <div>
                          <FontIcon
                            iconName={"Edit"}
                            aria-label="Edit Description"
                            tabIndex={0}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                this.setState({ editDescription: true });
                              }
                            }}
                            onClick={() => {
                              this.setState({ editDescription: true });
                            }}
                          ></FontIcon>
                        </div>
                      )}
                  </div>
                </div>
                <div className={styles.descriptionActions}>
                  {this.state.editDescription &&
                    this.props.item?.permissions.canUpdate && (
                      <FontIcon
                        aria-label={this._translation.save}
                        iconName={"Accept"}
                        tabIndex={0}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            if (this.props.updateDescription) {
                              this.setState({ showDescription: false });
                              this.props
                                .updateDescription(
                                  this.state.descriptionTemp.toString("html")
                                )
                                .then((result) => {
                                  if (result) {
                                    this.setState({
                                      editDescription: false,
                                      showDescription: true,
                                    });
                                  }
                                });
                            }
                          }
                        }}
                        onClick={() => {
                          if (this.props.updateDescription) {
                            this.setState({ showDescription: false });
                            this.props
                              .updateDescription(
                                this.state.descriptionTemp.toString("html")
                              )
                              .then((result) => {
                                if (result) {
                                  this.setState({
                                    editDescription: false,
                                    showDescription: true,
                                  });
                                }
                              });
                          }
                        }}
                      ></FontIcon>
                    )}
                  {this.state.editDescription &&
                    this.props.item?.permissions.canUpdate && (
                      <FontIcon
                        aria-label={this._translation.cancel}
                        iconName={"Cancel"}
                        tabIndex={0}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.setState({ editDescription: false });
                          }
                        }}
                        onClick={() => {
                          this.setState({ editDescription: false });
                        }}
                      ></FontIcon>
                    )}
                </div>
              </div>
            )}
            {!this.props.edit && this.state.showDescription && (
              <div
                className={
                  styles.value_ActivityAttribute + " ms-Grid-col ms-sm12"
                }
                dangerouslySetInnerHTML={{
                  __html:
                    this.props.item?.description &&
                    this._decodeHTMLEntities(this.props.item?.description)
                      ? this.props.item?.description //.replaceAll("\n", "<br />")
                      : "---",
                }}
              >
                {/* {this.props.item?.description} */}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
