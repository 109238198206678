import { HttpError } from "../Models/HttpResponse";

export class ErrorClient
{
    public static storeHttpError(err: HttpError): void
    {
        console.debug(err);

        if (localStorage) {              

            const errors = this.getErrors();

            errors.push(err);

            localStorage.setItem('errors', JSON.stringify(errors));
        } 
        else
        {
            console.error("Cannot access the local storage resource")
        }
    }

    private static getErrors(): HttpError[] {
    
        let errors;
    
        if (!localStorage['errors']) {
          errors = [];
        }
    
        else {
          errors = JSON.parse(localStorage['errors']);
        }
    
        if (!(errors instanceof Array))
        {
          errors = [];
        }
    
        return errors;
      }
}