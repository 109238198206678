import * as React from "react";
import $ from "jquery";
import "bootstrap/dist/css/bootstrap.css";
import "@fluentui/react/dist/css/fabric.min.css";
import { initializeIcons } from "@uifabric/icons";

// import "./DeliverablesOnNewMicro.scss";
import styles from "./DeliverablesOnNewMicro.module.scss";
import { IColumn, IContextualMenuProps, IModalProps, FontIcon, IDetailsListProps, DetailsRow, IDialogContentProps, DialogType, DetailsList, SelectionMode, ShimmeredDetailsList, Dialog, DialogFooter, PrimaryButton, DefaultButton, ContextualMenu, IContextualMenuItem, DirectionalHint } from "@fluentui/react";
import { Modal, ResponsiveEmbed } from "react-bootstrap";
import { IconButton } from "rsuite";
import { IDeliverableClient } from "../../../../Clients/IDeliverableClient";
import { ActivityPermissions } from "../../../../Models/Activity";
import { ITemplates, DeliverableType } from "../../../../Models/Deliverable";
import { IBaseProperties } from "../../../../Models/IBaseProperties";
import { ToastNotificationType } from "../../../../Models/ToastNote";
import { ActivityTranslation } from "../../../../Translations/Activity.Translation";
import { DeliverablesTranslation } from "../../../../Translations/Deliverables.Translation";
import { Utility } from "../../../../Utility/Utility";
import { PanelActivityHeaderComponent } from "../../../Shared/PanelActivityHeaderComponent/PanelActivityHeaderComponent";
import { SpinnerComponent } from "../../../Shared/SpinnerComponent/SpinnerComponent";

export interface IDeliverablesOnNewMicroProps extends IBaseProperties {
  onDeliverablesListChange: (files: File[]) => any;
  showMessage: (
    title: string,
    description: string,
    type: ToastNotificationType
  ) => void;
}

export interface IDeliverablesOnNewMicroState {
  isLoading: boolean;
  permissions: ActivityPermissions;
  derivableColumns: IColumn[];
  deliverableItems?: File[];
  addDerivableContextualMenuProps?: IContextualMenuProps;
  derivableContextualMenuProps?: IContextualMenuProps;
  itemsSelected: File[];

  allowedExtensions?: string[];
  templates?: ITemplates[];

  uploadDisabled: boolean;

  newItemFilename?: string;
  newTemplateName?: string;
  errorFileInsert: boolean;

  deliverableItemSelected?: File;

  fileSelected: any;
}

export class DeliverablesOnNewMicro extends React.Component<
  IDeliverablesOnNewMicroProps,
  IDeliverablesOnNewMicroState
> {
  _derivableColumns: IColumn[];
  private readonly _translation: DeliverablesTranslation;
  private readonly _activityTranslation: ActivityTranslation;
  private readonly _deliverableClient: IDeliverableClient;
  private confirmDeleteDialogProps: IModalProps = { isBlocking: true };

  constructor(props: IDeliverablesOnNewMicroProps) {
    super(props);

    initializeIcons();
    this._translation = new DeliverablesTranslation(
      this.props.commonProps.translation
    );
    this._activityTranslation = new ActivityTranslation(
      this.props.commonProps.translation
    );
    this._deliverableClient = this.props.commonProps.clientCreator.createDeliverableClient();

    this._getLabelSelection = this._getLabelSelection.bind(this);
    this._getLabelSizeSelection = this._getLabelSizeSelection.bind(this);

    this._fileChange = this._fileChange.bind(this);
    this._renderActivityModalHeader = this._renderActivityModalHeader.bind(
      this
    );
    this._deleteDeliverable = this._deleteDeliverable.bind(this);

    this._derivableColumns = [
      {
        key: "delete",
        ariaLabel: "Delete",
        name: (<div></div>) as any,
        fieldName: "delete",
        minWidth: 20,
        maxWidth: 20,
        isResizable: false,
        // onColumnClick: this._onDerivableColumnClick,
        onRender: (item: File) => {
          return (
            <div onClick={(ev) => {
              this._deleteDeliverable(item);
            }}
            >
              <FontIcon
                iconName="Delete"
                className="iconType"
              ></FontIcon>
            </div>
          );
        },
        // columnActionsMode: ColumnActionsMode.,
      },
      {
        key: "file",
        ariaLabel: "Filter or Sort " + this._translation.file,
        name: (<div>{this._translation.file}</div>) as any,
        fieldName: "file",
        minWidth: 100,
        isResizable: true,
        onColumnClick: this._onDerivableColumnClick,
        onRender: (item: File) => {
          return (
            <div
              className={styles.deliverablesDetailsCell}
            >
              <FontIcon
                iconName={Utility.GetIconType(
                  item.name ? item.name : ""
                )}
                className="iconType"
              ></FontIcon>
              <div className={styles.text}>{item.name}</div>
            </div>
          );
        },
        // columnActionsMode: ColumnActionsMode.,
      },
      {
        key: "size",
        ariaLabel: "Filter or Sort " + this._translation.size,
        name: (<div>{this._translation.size}</div>) as any,
        fieldName: "size",
        minWidth: 80,
        isResizable: true,
        onColumnClick: this._onDerivableColumnClick,
        onRender: (item: File) => {
          return (
            <div className={styles.deliverablesDetailsCell}>{item.size}</div>
          );
        },
        // columnActionsMode: ColumnActionsMode.,
      },
    ];

    this.state = {
      derivableColumns: this._derivableColumns,
      deliverableItems: [],
      itemsSelected: [],
      isLoading: false,
      allowedExtensions: undefined,
      permissions: Utility.getDefaultActivityPermissions(),
      fileSelected: undefined,
      errorFileInsert: false,
      uploadDisabled: false,
      templates: [],
    };
  }

  componentDidMount() {
    this._deliverableClient.getListTemplates().then((templates) => {
      if (templates == undefined) {
        this.props.showMessage(
          this._translation.error,
          this._translation.getTemplatesError,
          ToastNotificationType.ERROR
        );
      }
    });
  }

  private _renderActivityModalHeader() {
    return (
      <PanelActivityHeaderComponent
        commonProps={this.props.commonProps}
        // item={this.props.item}
        text={this._translation.editBy}
        isMacro={false}
        showContextMenu={false}
        showStatus={false}
        showType={false}
        showManage={false}
      ></PanelActivityHeaderComponent>
    );
  }

  private _fileChange(
    event: React.ChangeEvent<HTMLInputElement>
  ): void | undefined {
    if (event.target.files && event.target.files.length > 0) {
      const deliverables = !!this.state.deliverableItems ? [...this.state.deliverableItems] : [];
      for (var i = 0; i < event.target.files.length; i++) {
        const sFile = event.target.files[i];
        if (!deliverables.some(d => d.name === sFile.name)) {
          deliverables.push(sFile);
        } else {
          this.props.showMessage(
            this._translation.error,
            this._activityTranslation.fileAlreadyExists,
            ToastNotificationType.ERROR
          );
          return;
        }
      }
      this.setState({
        deliverableItems: deliverables
      });
      this.props.onDeliverablesListChange(deliverables);
    }
  }

  private _onRenderRow: IDetailsListProps["onRenderRow"] = (props) => {
    var result = null;

    if (props) {
      result = (
        <div
          className={styles.DetailsRowNoMicro}
        >
          <DetailsRow
            {...props}
            key={"Deliverables" + props.item?.id}
            className={styles.DeliverablesRow + " ms-MainRow"}
          />
        </div>
      );
    }

    return result;
  };

  private _onDerivableColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn
  ): void => {
    const { derivableColumns, deliverableItems } = this.state;
    const newColumns: IColumn[] = derivableColumns.slice();
    const currColumn: IColumn = newColumns.filter(
      (currCol) => column.key === currCol.key
    )[0];

    if (deliverableItems) {
      newColumns.forEach((newCol: IColumn) => {
        if (newCol === currColumn) {
          currColumn.isSortedDescending = !currColumn.isSortedDescending;
          currColumn.isSorted = true;
        } else {
          newCol.isSorted = false;
          newCol.isSortedDescending = true;
        }
      });
      const newItems = Utility.CopyAndSort(
        deliverableItems,
        currColumn.fieldName!,
        currColumn.isSortedDescending
      );
      this.setState({
        derivableColumns: newColumns,
        deliverableItems: newItems,
      });
    }
  };

  private _getLabelSelection(): string {
    var labelItemsSelected = this._translation.fileSelected(
      this.state.itemsSelected.length
    );
    return labelItemsSelected;
  }

  private _getLabelSizeSelection(): string {
    var labelItemsSelected = "";
    var totSize: number = 0;

    this.state.itemsSelected.forEach((element: File) => {
      if (element && element.size != undefined) {
        var size: number = element.size;
        totSize += size;
      }
    });

    labelItemsSelected = Utility.GetFileSize(totSize);

    return labelItemsSelected;
  }

  private async _deleteDeliverable(file: File): Promise<boolean> {
    const items = !!this.state.deliverableItems ? [...this.state.deliverableItems] : [];
    items.splice(items.findIndex(d => d.name === file.name), 1);
    this.setState({ deliverableItems: items });
    this.props.onDeliverablesListChange(items);
    return true;
  }

  public render() {
    const {
      addDerivableContextualMenuProps,
      derivableContextualMenuProps,
    } = this.state;

    var detailsList = this.state.deliverableItems ? (
      <DetailsList
        items={this.state.deliverableItems}
        columns={this.state.derivableColumns}
        groupProps={{
          showEmptyGroups: true,
        }}
        onRenderRow={this._onRenderRow}
        selectionMode={SelectionMode.none}
      // selection={this._selection}
      // selectionPreservedOnEmptyClick={true}
      />
    ) : (
      <ShimmeredDetailsList
        setKey="items"
        items={[this.state.deliverableItems]}
        columns={this.state.derivableColumns}
        selectionMode={SelectionMode.none}
        enableShimmer={false}
      // listProps={shimmeredDetailsListProps}
      />
    );

    const inputUploadFolderAtt = { directory: "", webkitdirectory: "", mozdirectory: "" };

    return (
      <div className={styles.DeliverableComponent}>
        {/* <LoaderComponent
          commonProps={this.props.commonProps}
          isLoading={this.state.isLoading}
        /> */}
        {this.state.isLoading && (
          <SpinnerComponent
            commonProps={this.props.commonProps}
            relative={true}
          ></SpinnerComponent>
        )}

        <iframe
          id="deliverable"
          style={{ display: "none" }}
          title={this._translation.deliverable}
        />
        <input
          id="fileBrief"
          type="file"
          title="Choose a file"
          multiple
          onChange={this._fileChange}
          hidden={true}
          accept={
            this.state.allowedExtensions
              ? this.state.allowedExtensions.join(", ")
              : ""
          }
        />
        <input
          id="folderBrief"
          type="file"
          title="Choose a folder"
          onChange={this._fileChange}
          hidden={true}
          {...inputUploadFolderAtt}
          accept={
            this.state.allowedExtensions
              ? this.state.allowedExtensions.join(", ")
              : ""
          }
        />
        <div id="printDiv"></div>

        {!this.state.isLoading && [
          <div className={styles.headerDeliverables} key="deliverables">
            <span>{this._translation.deliverables}</span>
            <strong
              onClick={(ev) => {
                this.setState({
                  addDerivableContextualMenuProps: this._getAddDerivableContextualMenuProps(
                    ev
                  ),
                });
              }}
            >
              +
            </strong>

            {addDerivableContextualMenuProps && (
              <ContextualMenu {...addDerivableContextualMenuProps} />
            )}
          </div>,
          <div className={styles.divDeliverablesList + " ms-Grid-col ms-sm12"} key="list">
            {/* <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}> */}
            {detailsList}
            {derivableContextualMenuProps && (
              <ContextualMenu {...derivableContextualMenuProps} />
            )}
            {/* </ScrollablePane> */}
          </div>,
        ]}
      </div>
    );
  }

  private _onAddDerivableContextualMenuDismissed = (ev: any, dp: any): void => {
    this.setState({
      addDerivableContextualMenuProps: undefined,
    });
  };

  private _getAddDerivableContextualMenuProps(
    ev: React.MouseEvent<HTMLElement>
  ): IContextualMenuProps {
    var items: IContextualMenuItem[] = [
      {
        key: "UploadFile",
        name: this._translation.uploadFile,
        canCheck: false,
        onClick: () => {
          $("#fileBrief").trigger("click");
        },
      },
      {
        key: "UploadFolder",
        name: this._translation.uploadFolder,
        canCheck: false,
        onClick: () => {
          $("#folderBrief").trigger("click");
        },
      },
    ];

    return {
      items: items,
      target: ev.currentTarget as HTMLElement,
      directionalHint: DirectionalHint.bottomLeftEdge,
      gapSpace: 10,
      isBeakVisible: false,
      onDismiss: this._onAddDerivableContextualMenuDismissed,
    };
  }
}
