import React from 'react'
import PropTypes from 'prop-types'

import Tracks from './Tracks'
import Grid from './Grid'

const Body = ({ time, grid, tracks, tracks2, clickElement }) => (
  
  <div className="rt-timeline__body">
    {grid && <Grid time={time} grid={grid} />}
    
    <Tracks time={time} tracks={tracks} tracks2={tracks2}  clickElement={clickElement} />
    
  </div>
)

Body.propTypes = {
  time: PropTypes.shape({}).isRequired,
  grid: PropTypes.arrayOf(PropTypes.shape({})),
  tracks: PropTypes.arrayOf(PropTypes.shape({})),
  tracks2: PropTypes.arrayOf(PropTypes.shape({})),
  clickElement: PropTypes.func,
}

export default Body
