import * as React from "react";
import { BriefTranslation } from '../../../../Translations/Brief.Translation';
import { Persona,  PersonaSize, Spinner, Text } from '@fluentui/react';

import styles from "./BriefHistory.module.scss";
import {
  IBaseProperties,  
} from "../../../../Models/IBaseProperties";
import { IBriefHistoryItem } from "../../../../Models/Brief";
import moment from "moment";

import msgin from "../../../../Style/Images/ICO-MsgIN.svg"; 
import { PersonaStyle } from "../../../../Models/User";
import { IBriefClient } from "../../../../Clients/IBriefClient";
import { Observer, Subscription } from "rxjs";
import { INotification, NotificationSubType } from "../../../../Models/Notification";
import { filter } from "rxjs/operators";

export interface IBriefHistoryProps extends IBaseProperties {  
  id: number;
  currentUpn: string;
}

export interface IBriefHistoryState {
    items: IBriefHistoryItem[];
    isLoading: boolean;
}

export class BriefHistory extends React.Component<IBriefHistoryProps, IBriefHistoryState> {
  private readonly _briefClient: IBriefClient;
  private readonly _translation: BriefTranslation;
  private _notificationSubscription?: Subscription;

  constructor(props: IBriefHistoryProps) {

    super(props);

    this._briefClient = props.commonProps.clientCreator.createBriefClient();
    this._translation = new BriefTranslation(props.commonProps.translation);    

    this.initializeState(props);

    this.bindEvents();
  }

  private initializeState(props: IBriefHistoryProps) {
    this.state = {    
        items: [],
        isLoading: true,
    };
  }

  async componentDidMount() {

    try
    {

        this.buildNotificationObserver();            
        const {id} = this.props;
    
        await this.loadBriefHistoryStatusAsync(id);
    }
    finally
    {
        this.setState(
        {                
            isLoading: false,
        }
        );
    }
  }  

    private async loadBriefHistoryStatusAsync(id: number) {
        const result = await this._briefClient.getBriefStatuses(id);
        if (result) {
            this.setState(
                {
                    items: result,
                }
            );
        }
    }

  public componentWillUnmount() {
    
    if (this._notificationSubscription) {
      this._notificationSubscription.unsubscribe();
    }
  }

  componentDidUpdate(prevProps: IBriefHistoryProps) {   
    if (prevProps.commonProps.onNotification !== this.props.commonProps.onNotification) {
      this.buildNotificationObserver();
    }
  }

  private buildNotificationObserver()
  {
    const observer: Observer<INotification> = {
      next: async (value:INotification)  =>  {                
            await this.loadBriefHistoryStatusAsync(value.id);          
          },
      error: err => console.error('Observer got an error: ' + err),
      complete: () => console.log('Observer got a complete notification'),
    };

    const filterObs = this.props.commonProps.onNotification?.pipe(filter(item => item.subType === NotificationSubType.internalbriefchanged ));
    this._notificationSubscription = filterObs?.subscribe(observer); 
  }

  private bindEvents() {    
    
  }
 
  private infoTypeTostring(key: number): string
  {
      let retValue: string;

      switch (key)
      {
        case 1:
            retValue = "Sending Approval Request";
            break;
        case 2:
            retValue = "Request Info";
            break;
        case 3:
            retValue = "Re-Sending Approval Request";
            break;
        case 4:
            retValue = "Accepted";
            break;
        case 5:
            retValue = "Rejected";
            break;
        case 6:
            retValue = "Rework";
            break;            
        default:
            retValue = "-";
            break;

      }

      return retValue;
  }
  

  private renderItem(item: IBriefHistoryItem): JSX.Element {

    const {user, actionDate: date, type} = item;
    
    const mmDate = moment(date);
    const onlyDate = mmDate.format("DD/MM/YYYY");
    const onlyTime = mmDate.format("HH:mm");    
    const sType = this.infoTypeTostring(type);
    const classDirection = user ? this.getClassDirection(user.userPrincipalName) : '';
    const sUserDisplayName = user ? user.displayName : '';
    const sUserInitials = user ? user.initials : '';
    
    return (
        <div>
            <div className={styles.row}>
                <div className={[styles.content50, styles.left, styles.contentText].join(" ")}><Text>{onlyTime}</Text></div>
                <div className={[styles.content50, styles.right, styles.contentText].join(" ")}><Text>{onlyDate}</Text></div>
            </div>            
            <div className={styles.row}>
                <div className={[styles.content100, styles.left].join(" ")}>                    
                    <Persona                         
                        size={PersonaSize.size24}                        
                        imageInitials={sUserInitials}
                        text={sUserDisplayName}
                        className={PersonaStyle.NORMAL}
                     />                                         
                </div>                
            </div>            
            <div className={styles.row}>
                <div className={[styles.content100, styles.left, styles.contentText].join(" ")}><Text>{sType}</Text></div>                
            </div>
            <div className={styles.row}>
                <div className={[styles.content100, styles.right].join(" ")}>
                    <img src={msgin} className={classDirection}></img>
                </div>                
            </div>
            
            <hr></hr>
        </div>
    );
  }
    private getClassDirection(userPrincipalName: string): string {
        const input = styles.rotate180;
        const output = "";
        const isEq = this.props.currentUpn.toLowerCase()===userPrincipalName.toLowerCase();

        return isEq ? input : output;
    }
    
  render() {
    const {items} = this.state;

    const rows = items.map(
        (item)=> 
        {
            return (
                <div key={item.actionDate.toUTCString()}>{this.renderItem(item)}</div>
            );
        }
    );
    
    return (
        <div className={styles.container}>
            {
            this.state.isLoading &&
                <Spinner label={this._translation.loadingMessageByKey("briefHistory")} />
            }  
            {
               rows
            }            
        </div>
    );
    }
}
