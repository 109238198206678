import React from "react";
import PropTypes, { any } from "prop-types";
import { Utility } from "../../../../../../../Utility/Utility";
import { Constants } from "../../../../../../../Utility/Constants";
import { IconButton, FontIcon } from "@fluentui/react";
import { mergeStyles } from "office-ui-fabric-react/lib/Styling";

import TrackKeys from ".";

const TrackKey = ({
  track,
  toggleOpen,
  clickTrackButton,
  index,
  onSelectActivity,
}) => {
  const { title, tracks, isOpen, hasButton, sideComponent } = track;
  const isExpandable = isOpen !== undefined;

  const formatDate = (date) => {
    let dd = date.getDate();
    let mm = date.getMonth() + 1;
    const yyyy = date.getFullYear();
    if (dd < 10) {
      dd = `0${dd}`;
    }

    if (mm < 10) {
      mm = `0${mm}`;
    }
    return `${dd}/${mm}/${yyyy}`;
  };

  const buildSideComponent = () => {
    if (sideComponent) {
      return React.cloneElement(sideComponent);
    }
    if (hasButton && clickTrackButton) {
      const handleClick = () => clickTrackButton(track);
      // eslint-disable-next-line jsx-a11y/control-has-associated-label
      return (
        <button
          className="rt-track-key__side-button"
          onClick={handleClick}
          type="button"          
        />
      );
    }

    return null;
  };
  let classEntryBackground =
    track.trackLevel == 3
      ? "rt-track-key__entrytask"
      : index % 2 == 0
      ? ""
      : "rt-track-key__entryalt";
  return (
    <li className="rt-track-key">
      <div className={["rt-track-key__entry", classEntryBackground].join(" ")}>
        {isExpandable && tracks.length != 0 && (
          <IconButton
            iconProps={
              isOpen
                ? Constants.iChevronDownSmall
                : Constants.iChevronRightSmall
            }
            title=""
            ariaLabel=""
            styles={Constants.iconChevronStyle}
            onClick={() => toggleOpen(track)}
          />
        )}

        {track.trackLevel != 3 && (
          <>
            <div className="rt-track-key__entry1">
              <div
                tabIndex={0}
                className="rt-track-key__title"
                title={title}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    onSelectActivity(track);
                  }
                }}
                onClick={() => onSelectActivity(track)}
              >
                {title}
              </div>
              <div className="rt-track-key__row2">
                {!track.isMacro && (
                  <div
                    className={[                      
                      "rt-track-key__state",
                      Utility.GetActivityClassNameFromStatus(
                        track.state,
                        false
                      ),
                    ].join(" ")}
                  ></div>
                )}
                <div  className={[                      
                      Utility.GetActivityClassNameForStatus(
                        track.state,
                        false
                      ),
                    ].join(" ")} style={{ lineHeight: "0.93rem", fontWeight:"normal", marginRight:"0.5rem", fontSize:"small" }}>
                  {track.elements && track.elements.length > 0 ? track.elements[0].state : ""}
                </div>
                <div className="rt-track-key__calendartitle">
                  {track.elements && track.elements.length > 0
                    ? track.elements[0].calendar
                    : ""}
                </div>
                <div className="rt-track-key__date">
                  {track.elements &&
                    track.elements.length > 0 &&
                    track.elements[0].startActivity &&
                    formatDate(track.elements[0].startActivity)}{" "}
                  -{" "}
                  {track.elements &&
                    track.elements.length > 0 &&
                    track.elements[0].endActivity &&
                    formatDate(track.elements[0].endActivity)}
                </div>
              </div>
            </div>
            {track.isMacro && (
              <div className="rt-track-key__entry2">
                <div className="rt-track-key__macro">MACRO</div>
                <div className="rt-track-key__nmacro">{track.Nmicro}</div>
              </div>
            )}
            {!track.isMacro && track.trackLevel != 3 && (
              <div className="rt-track-key__entry2">
                <div className="rt-track-key__micro">MICRO</div>
              </div>
            )}
          </>
        )}
        {track.trackLevel == 3 && (
          <div className="rt-track-key__task">
            <div>{title}</div>
            {track.completed && (
              <FontIcon
                iconName="CheckMark"
                className="rt-track-key__task-completed"
              />
            )}
          </div>
        )}
        {buildSideComponent()}
      </div>
      {isOpen && tracks && tracks.length > 0 && (
        <TrackKeys
          tracks={tracks}
          toggleOpen={toggleOpen}
          onSelectActivity={onSelectActivity}
        />
      )}
    </li>
  );
};

TrackKey.propTypes = {
  track: PropTypes.shape({
    title: PropTypes.string.isRequired,
    tracks: PropTypes.arrayOf(PropTypes.shape({})),
    isOpen: PropTypes.bool,
    hasButton: PropTypes.bool,
    sideComponent: PropTypes.element,
    index: PropTypes.number,
  }),
  toggleOpen: PropTypes.func,
  clickTrackButton: PropTypes.func,
};

TrackKey.defaultProps = {
  clickTrackButton: undefined,
};

export default TrackKey;
