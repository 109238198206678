
import { NoteDTO } from "./../Models/DTO/NoteDTO";
import { INoteClient } from "./INoteClient";
import { INote } from "./../Models/Note";
import { APIUtility } from "../Utility/APIUtility";
import { NoteDTO_insert, NoteDTO_update } from "../Models/DTO/NoteDTO";

import "../Utility/DateExtensions";
// import RichTextEditor from "react-rte";

export class NoteClient implements INoteClient {
  public async getNotes(
    id: number,
    isMacro: boolean
  ): Promise<INote[] | undefined> {
    let retValue: INote[] | undefined = undefined;
    try {
      let apiUtility = new APIUtility();

      var elements: NoteDTO[] = isMacro
        ? await apiUtility.getMacroActivityNotes(id)
        : await apiUtility.getMicroActivityNotes(id);

      retValue = elements
        .map((note) => {
          return {
            id: note.id,
            text: note.text, //RichTextEditor.createValueFromString(note.text, "html"),
            editedBy: note.editedBy,
            lastUpdate: note.lastUpdate,
            edit: false,
          };
        })
        .sort((a, b) => {
          return a.lastUpdate > b.lastUpdate
            ? -1
            : a.lastUpdate < b.lastUpdate
            ? 1
            : 0;
        });
    } catch (err) {
      console.error(err);
    } finally {
      return retValue;
    }
  }

  public async insertNote(
    idActivity: string,
    isMacro: boolean,
    note: INote
  ): Promise<boolean | undefined> {
    let retValue: boolean | undefined = undefined;
    try {
      let noteDTO: NoteDTO_insert = {
        text: note.text, //.toString("html"),
        upn: note.editedBy.userPrincipalName,
      };

      let apiUtility = new APIUtility();
      // return await apiUtility.addNote(idActivity, noteDTO);
      await apiUtility.addNote(idActivity, isMacro, noteDTO.text);
      retValue = true;
    } catch (err) {
      console.error(err);
    } finally {
      return retValue;
    }
  }

  public async updateNote(
    idActivity: string,
    idNote: string,
    isMacro: boolean,
    text: string
  ): Promise<boolean | undefined> {
    let retValue: boolean | undefined = undefined;
    try {
      let noteDTO: NoteDTO_update = {
        text: text,
      };

      let apiUtility = new APIUtility();
      await apiUtility.updateNote(idActivity, idNote, isMacro, noteDTO.text);
      retValue = true;
    } catch (err) {
      console.error(err);
    } finally {
      return retValue;
    }
  }

  public async deleteNote(
    idActivity: string,
    idNote: string,
    isMacro: boolean
  ): Promise<boolean | undefined> {
    let retValue: boolean | undefined = undefined;
    try {
      let apiUtility = new APIUtility();
      await apiUtility.deleteNote(idActivity, idNote, isMacro);
      retValue = true;
    } catch (err) {
      console.error(err);
    } finally {
      return retValue;
    }
  }
}
