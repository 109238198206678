import React from 'react'
import PropTypes from 'prop-types'

import Cell from './Cell'

const Row = ({ time, cells, style, onClickDate, selectedDay}) => (  
  <div className="rt-timebar__row" style={style} >
    {
      //todogiuseppe    
    cells.map(cell => (
      // (cell.start>= time.start && cell.end<=time.end) &&       
      <Cell key={cell.id} selectedDay={selectedDay} onClickDate={onClickDate} time={time} style={style} {...cell}  />      
    ))}
  </div>
)

Row.propTypes = {
  time: PropTypes.shape({}).isRequired,
  cells: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  style: PropTypes.shape({}),
  onClickDate:  PropTypes.func.isRequired,
  selectedDay: PropTypes.instanceOf(Date),
}

export default Row
