import * as React from "react";

import "bootstrap/dist/css/bootstrap.css";

import "@fluentui/react/dist/css/fabric.min.css";

import { initializeIcons } from "@uifabric/icons";

import { Utility } from "../../../Utility/Utility";
import { CustomPageTranslation } from "../../../Translations/CustomPage.Translation";

// import "./ActivitySidebarComponent.scss";
import styles from "./CustomPageComponent.module.scss";

import { FontIcon, IColumn, Panel, PanelType } from "@fluentui/react";

import { User } from "../../../Models/User";
import {
  IToastNote,
  ToastNotificationPosition,
  ToastNotificationType,
} from "../../../Models/ToastNote";
import { IBaseProperties } from "../../../Models/IBaseProperties";
import { Constants } from "../../../Utility/Constants";
import RichTextEditor, { EditorValue } from "react-rte";
import { CustomPage } from "../../../Models/CustomPage";
import { ICustomPageClient } from "../../../Clients/ICustomPageClient";
import {getTextAlignBlockMetadata, getTextAlignClassName, getTextAlignStyles} from './blockStyleFunctions';

export interface ICustomPageComponentProps extends IBaseProperties {
  title: string;
}

export interface ICustomPageComponentState {
  isLoading: boolean;
  showEditPage: boolean;
  customPage?: CustomPage;
  editContent: boolean;
  contentTemp: EditorValue;
  format: string;
  readOnly: boolean;
}

export class CustomPageComponent extends React.Component<
  ICustomPageComponentProps,
  ICustomPageComponentState
> {
  _taskColumns: IColumn[] = [];
  private readonly _translation: CustomPageTranslation;
  private readonly _customPageClient: ICustomPageClient;

  constructor(props: ICustomPageComponentProps) {
    super(props);

    initializeIcons();
    this._translation = new CustomPageTranslation(
      this.props.commonProps.translation
    );
    this._customPageClient = this.props.commonProps.clientCreator.createCustomPageClient();

    this._reloadPage = this._reloadPage.bind(this);
    this._editCustomPage = this._editCustomPage.bind(this);

    var assignedResourcesItems: User[] = [];
    var externalResourcesItems: User[] = [];

    this.state = {
      isLoading: true,
      showEditPage: false,
      customPage: undefined,
      editContent: false,
      contentTemp: EditorValue.createEmpty(),
      format: "html",
      readOnly: false,
    };
  }

  componentDidMount() {
    this._reloadPage();
  }

  private _reloadPage() {
    this._customPageClient.getCustomPage(this.props.title).then((x) => {
      if (!x) {
        this.props.commonProps.toastComponent?.showMessage(
          this._translation.error,
          this._translation.pageNotFound,
          ToastNotificationType.ERROR
        );
      } else {
        this.setState({ customPage: x });
      }
    });
  }

  private _editCustomPage(ev: any): void {
    ev.preventDefault();
    this.setState({
      showEditPage: true,
      contentTemp: this.state.customPage?.page.content
        ? this.state.contentTemp.setContentFromString(
            this.state.customPage?.page.content,
            "html",
            {customBlockFn: getTextAlignBlockMetadata}
          )
        : EditorValue.createEmpty(),
      editContent: this.state.customPage?.permissions?.canEdit ? true : false,
    });
  }

  private async _updateCustomPage(content: string) {
    this.setState({ isLoading: true });
    if (this.state.customPage) {
      this.setState({
        isLoading: true,
        showEditPage: false,
        // showDetails: this.state.isMacro ? false : true,
      });
      var result = await this._customPageClient.updateCustomPage(
        this.props.title,
        content
      );
      if (!result) {
        this.props.commonProps.toastComponent?.showMessage(
          this._translation.error,
          this._translation.errorUpdatingContent,
          ToastNotificationType.ERROR
        );
      } else {
        this.props.commonProps.toastComponent?.showMessage(
          this._translation.ok,
          this._translation.successUpdatingContent,
          ToastNotificationType.SUCCESS,
          false,
          ToastNotificationPosition.TOPCENTER
        );
      }

      this.setState(
        {
          /*item: undefined,*/ contentTemp: RichTextEditor.createEmptyValue(),
        },
        this._reloadPage
      );
    }
    return true;
  }

  private _decodeHTMLEntities(text: string) {
    var span = document.createElement("span");
    span.innerHTML = text;
    return span.textContent || span.innerText;
  }

  public render() {
    return (
      <div className={styles.CustomPageComponent}>
        <div
          className={
            this.state.customPage?.permissions?.canEdit
              ? styles.editablePage
              : ""
          }
        >
          <div
            className={styles.ActivityDescription}
            dangerouslySetInnerHTML={{
              __html:
                this.state.customPage?.page.content &&
                this._decodeHTMLEntities(this.state.customPage?.page.content)
                  ? this.state.customPage?.page.content
                  : "---",
            }}
          ></div>
          {this.state.customPage?.permissions?.canEdit && (
            <FontIcon
              iconName="Settings"
              title={this._translation.editPage}
              className={"iconGet " + styles.iconSettings}
              onClick={
                this.state.customPage?.permissions?.canEdit
                  ? this._editCustomPage
                  : () => {}
              }
            ></FontIcon>
          )}
        </div>

        <Panel
          // data={null}
          headerText={this.props.title}
          isOpen={this.state.showEditPage ? true : false}
          onDismiss={() => {
            this.setState({ showEditPage: false });
          }}
          isHiddenOnDismiss={false}
          isLightDismiss={true}
          customWidth={window.innerWidth > 767 ? "55vw" : "100vw"}
          className={
            styles.nestedPanelActivityDetails +
            " nestedPanel " +
            styles.ActivityDetailsComponent
          }
          type={PanelType.custom}
          // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
          closeButtonAriaLabel={this._translation.close}
        >
          <hr className={styles.activity_details_separator} />
          <div className={styles.activity_details_body + " row"}>
            <div className={"ms-Grid-col ms-md12 " + styles.descriptionText}>
              <div className={styles.descriptionEditor}>
                {!this.state.editContent && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: !this.state.editContent
                        ? this.state.contentTemp?.toString("html", {blockStyleFn: getTextAlignStyles}) //.replaceAll("\n", "<br />")
                        : "",
                    }}
                    onClick={() => {
                      // if (this.state.customPage?.permissions?.canEdit)
                      //   this.setState({ editContent: true });
                    }}
                    className={
                      styles.labelDescription + " " // + styles.editableField
                    }
                  >
                    {/* {!this.state.editDescription && .replace("\n", "<br />")} */}
                  </div>
                )}
                {this.state.editContent && (
                  <div className="">
                    <label className={styles.radio_item}>
                      <input
                        type="radio"
                        name="format"
                        value="html"
                        checked={this.state.format === "html"}
                        onChange={() => {
                          this.setState({ format: "html" });
                        }}
                      />
                      <span>HTML</span>
                    </label>
                    <label className={styles.radio_item}>
                      <input
                        type="radio"
                        name="format"
                        value="source"
                        checked={this.state.format === "source"}
                        onChange={() => {
                          this.setState({ format: "source" });
                        }}
                      />
                      <span>SOURCE</span>
                    </label>
                    <label className={styles.radio_item}>
                      <input
                        type="checkbox"
                        onChange={() => {
                          this.setState({ readOnly: !this.state.readOnly });
                        }}
                        checked={this.state.readOnly}
                      />
                      <span>Editor is read-only</span>
                    </label>
                  </div>
                )}
                {this.state.editContent && this.state.format == "source" && (
                  <div className="">
                    <textarea
                      className={styles.source}
                      placeholder="Editor Source"
                      value={this.state.contentTemp.toString("html", {blockStyleFn: getTextAlignStyles})}
                      readOnly={this.state.readOnly}
                      onChange={(ev) => {
                        this.setState({
                          contentTemp: this.state.contentTemp.setContentFromString(
                            ev.currentTarget.value,
                            "html",
                            {customBlockFn: getTextAlignBlockMetadata}
                          ),
                        });
                      }}
                    />
                  </div>
                )}
                {this.state.editContent && this.state.format == "html" && (
                  <RichTextEditor
                    // multiline
                    // autoAdjustHeight
                    // styles={Constants.textFieldAreaStyle}
                    blockStyleFn={getTextAlignClassName}
                    className={styles.textDescription}
                    onChange={(newValue) => {
                      this.setState({
                        contentTemp: newValue
                          ? newValue
                          : RichTextEditor.createEmptyValue(), //event.currentTarget.value,
                      });
                    }}
                    value={this.state.contentTemp}
                    readOnly={this.state.readOnly}
                    toolbarConfig={Constants.toolbarRichTextEditorCustomPage}
                  ></RichTextEditor>
                )}
                <div className={styles.descriptionEdit}>
                  {!this.state.editContent &&
                    this.state.customPage?.permissions?.canEdit && (
                      <div>
                        <FontIcon
                          iconName={"Edit"}
                          onClick={() => {
                            this.setState({ editContent: true });
                          }}
                        ></FontIcon>
                      </div>
                    )}
                </div>
              </div>
              <div className={styles.descriptionActions}>
                {this.state.editContent &&
                  this.state.customPage?.permissions?.canEdit && (
                    <FontIcon
                      iconName={"Accept"}
                      onClick={() => {
                        this._updateCustomPage(
                          this.state.contentTemp.toString("html", {blockStyleFn: getTextAlignStyles})
                        );
                      }}
                    ></FontIcon>
                  )}
                {this.state.editContent &&
                  this.state.customPage?.permissions?.canEdit && (
                    <FontIcon
                      iconName={"Cancel"}
                      onClick={() => {
                        this.setState({ editContent: false, showEditPage: false });
                      }}
                    ></FontIcon>
                  )}
              </div>
            </div>
          </div>
        </Panel>
      </div>
    );
  }
}
