import { IBriefsCount, BriefStatusEnum } from "./../Models/Brief";
import { BriefApproved, BriefApprovedDTO, BriefDTO } from "../Models/DTO/BriefDTO";
import { BriefListDTO, BriefListItemDTO } from "../Models/DTO/BriefListDTO";
import { APIUtility } from "../Utility/APIUtility";
import BriefMock from "../Mocks/DTO/BriefDTOMock.json";
import { IBriefClient } from "./IBriefClient";
import {
  Applicant,
  IBrief,
  IBriefDetail,
  IBriefFilter,
  IBriefHistoryItem,
  IBriefPermission,
  IFile,
  IListBrief,
  IListBriefDetails,
} from "../Models/Brief";
import { HttpError } from "../Models/HttpResponse";
import { ErrorClient } from "./ErrorClient";
import { IOrderBy } from "../Models/Activity";
import { BriefDetailDTO, BriefFileDTO } from "../Models/DTO/BriefDetailDTO";
import moment from "moment";
import { BriefHistoryStatusDTO } from "../Models/DTO/BriefHistoryStatusDTO";
import { ITag } from "@fluentui/react";
const HTTPStatusOk = 200;

export class BriefClient implements IBriefClient {
  private readonly _http: APIUtility = new APIUtility();

  private get briefListMock(): IListBrief {
    const rows = this.briefListDetailMock;
    const permission = this.briefPermissionMock;
    const value: IListBrief = {
      briefs: rows,
      count: rows.length,
      permission: permission,
    };
    return value;
  }

  private get briefPermissionMock(): IBriefPermission {
    const value: IBriefPermission = {
      visibility: true,
      canAdd: true,
      canDelete: true,
      canDownloadBriefs: true,
    };
    return value;
  }

  private get briefListDetailMock(): IListBriefDetails[] {
    const values: IListBriefDetails[] = [
      {
        id: 97,
        title: "Bilancio navigabile",
        status: "Draft",
        referent: "mocked user",
        applicantUnit: "applicant user",
        date: new Date(),
        attachmentsCount: 1
      },
      {
        id: 100,
        title: "mock 2",
        status: "Pending",
        referent: "mocked user",
        applicantUnit: "applicant user",
        date: new Date(),
        attachmentsCount: 0
      },
      {
        id: 87,
        title: "mock 3",
        status: "Accepted",
        referent: "mocked user",
        applicantUnit: "applicant user",
        date: new Date(),
        attachmentsCount: 2
      },
      {
        id: 73,
        title: "mock 3",
        status: "Rejected",
        referent: "mocked user",
        applicantUnit: "applicant user",
        date: new Date(),
        attachmentsCount: 3
      },
    ];

    return values;
  }

  public async getTargetsByFilterAsync(
    value: string
  ): Promise<string[] | undefined> {
    let retValue: string[] | undefined = undefined;
    try {
      const apiUtility = new APIUtility();
      retValue = await apiUtility.getTargets(value);
    } catch (err) {
      this.storeError(err);
    } finally {
      return retValue;
    }
  }

  public async getApplicant(): Promise<Applicant | undefined> {
    let retValue: Applicant | undefined = undefined;
    try {
      const apiUtility = new APIUtility();
      const dto = await apiUtility.getApplicant();

      retValue = dto; //actually are equal
    } catch (err) {
      this.storeError(err);
    } finally {
      return retValue;
    }
  }

  public async getStakeholderByFilterAsync(
    value: string
  ): Promise<string[] | undefined> {
    let retValue: string[] | undefined = undefined;
    try {
      const apiUtility = new APIUtility();
      retValue = await apiUtility.getStackeholder(value);
    } catch (err) {
      this.storeError(err);
    } finally {
      return retValue;
    }
  }

  public async getBriefs(
    filter: IBriefFilter,
    orderBy: (IOrderBy | undefined)[]
  ): Promise<IListBrief | undefined> {
    //return this.briefListMock;
    const DEFAULT_LIMIT = 50;
    let retValue: IListBrief | undefined = undefined;
    try {
      //retValue = this.getListDeliverablesMock();

      const apiUtility = new APIUtility();
      const dto: BriefListDTO = await apiUtility.getBriefList(
        filter.maxLength ? filter.maxLength : DEFAULT_LIMIT,
        filter.startIndex,
        filter.title,
        filter.status,
        filter.referent,
        filter.applicationUnit,
        filter.fromDate?.utc().toISOString(),
        filter.toDate?.utc().toISOString(),
        filter.id,
        orderBy
      );

      retValue = this.briefListDTOToIListBrief(dto);
    } catch (err) {
      this.storeError(err);
    } finally {
      return retValue;
    }
  }

  public async getBriefsCount(): Promise<IBriefsCount[] | undefined> {
    let retValue: IBriefsCount[] | undefined = undefined;
    try {
      let apiUtility = new APIUtility();
      var counters = await apiUtility.getBriefsCount();
      retValue = [
        {
          state: BriefStatusEnum.Draft,
          count: counters.draft,
        },
        {
          state: BriefStatusEnum.Pending,
          count: counters.pending,
        },
        {
          state: BriefStatusEnum.Rework,
          count: counters.rework,
        },
        {
          state: BriefStatusEnum.Accepted,
          count: counters.accepted,
        },
        {
          state: BriefStatusEnum.Rejected,
          count: counters.rejected,
        },
      ];
    } catch (err) {
      console.error(err);
    } finally {
      return retValue;
    }
  }

  private briefListDTOToIListBrief(dto: BriefListDTO): IListBrief {
    const items: IListBriefDetails[] = dto.briefs.map((t) =>
      this.briefListItemDTOToIListBriefDetails(t)
    );

    const retValue: IListBrief = {
      briefs: items,
      count: dto.count,
      permission: {},
    };
    return retValue;
  }
  private briefListItemDTOToIListBriefDetails(
    dto: BriefListItemDTO
  ): IListBriefDetails {
    const localDate = dto.date ? this.utcToLocalDate(dto.date) : undefined;

    const value: IListBriefDetails = {
      id: dto.id,
      title: dto.title,
      status: dto.status,
      date: localDate,
      applicantUnit: dto.applicantUnit,
      referent: dto.referent ? dto.referent.displayName : "N.D.",
      createdOn: dto.createdOn,
      attachmentsCount: dto.attachmentsCount,
      lastUpdate: dto.lastUpdate,
      lastAction: dto.lastAction
        ? this.toIBriefStatus(dto.lastAction)
        : undefined,

    };
    return value;
  }

  public async deleteBrief(id: number): Promise<boolean> {
    let retValue: boolean = false;
    const validHttpSates = [200, 202];

    try {
      const httpStatus = await this._http.deleteBrief(id);

      retValue = validHttpSates.includes(httpStatus);
    } catch (err) {
      this.storeError(err);
    } finally {
      return retValue;
    }
  }

  public async delegate(
    id: number,
    upn: string,
    type: string,
    mailSubject: string,
    mailBody: string
  ): Promise<boolean> {
    let retValue: boolean = false;
    const validHttpSates = [200, 202];

    try {
      const httpStatus = await this._http.delegateBrief(
        id,
        upn,
        type,
        mailSubject,
        mailBody
      );

      retValue = validHttpSates.includes(httpStatus);
    } catch (err) {
      this.storeError(err);
    } finally {
      return retValue;
    }
  }

  public async delegateApprove(
    id: number,
    upn: string,
    mailSubject: string,
    mailBody: string
  ): Promise<boolean> {
    return this.delegate(id, upn, "approval", mailSubject, mailBody);
  }

  public async delegateInsert(
    id: number,
    upn: string,
    mailSubject: string,
    mailBody: string
  ): Promise<boolean> {
    return this.delegate(id, upn, "createActivity", mailSubject, mailBody);
  }

  public async downloadBriefAsPdfAsync(
    id: number,
    language: "english" | "italian" | "text"
  ): Promise<Blob | undefined> {

    let retValue: any | undefined = undefined;
    try {
      let apiUtility = new APIUtility();
      let file = await apiUtility.downloadBriefAsPdf(
        id,
        language
      );
      retValue = file;
    } catch (err) {
      console.error(err);
    } finally {
      return retValue;
    }
  }

  public async downloadBriefAllAttachmentAsync(
    id: number,
    language: "english" | "italian" | "text"
  ): Promise<Blob | undefined> {

    let retValue: any | undefined = undefined;
    try {
      let apiUtility = new APIUtility();
      let file = await apiUtility.downloadBriefAllAttachments(
        id,
        language
      );
      retValue = file;
    } catch (err) {
      console.error(err);
    } finally {
      return retValue;
    }
  }

  public async downloadAttachmentsAsync(
    id: number
  ): Promise<Blob | undefined> {

    let retValue: any | undefined = undefined;
    try {
      let apiUtility = new APIUtility();
      let file = await apiUtility.downloadBriefAttachments(
        id
      );
      retValue = file;
    } catch (err) {
      console.error(err);
    } finally {
      return retValue;
    }
  }

  public async changeStatus(id: number, status: string): Promise<boolean> {
    let retValue: boolean = false;
    const validHttpSates = [200, 202];

    try {
      const httpStatus = await this._http.changeBriefStatus(id, status);

      retValue = validHttpSates.includes(httpStatus);
    } catch (err) {
      this.storeError(err);
    } finally {
      return retValue;
    }
  }

  public async getFilesByBriefId(id: number): Promise<IFile[] | undefined> {
    let files: IFile[] | undefined = undefined;

    try {
      const briefDetailDTO = await this._http.getFilesByBriefIdAsync(id);

      files = this.toIFiles(briefDetailDTO);
    } catch (err) {
      this.storeError(err);
    } finally {
      return files;
    }
  }

  private delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  public async getBriefById(id: number): Promise<IBriefDetail | undefined | number> {
    let briefDetail: IBriefDetail | undefined | number = undefined;

    try {
      const briefDetailDTO = await this._http.getBriefByIdAsync(id);

      briefDetail = this.toIBriefDetail(briefDetailDTO);
    } catch (err) {
      if (err.httpStatus === 204) {
        briefDetail = 204;
      }
      this.storeError(err);
    } finally {
      return briefDetail;
    }
  }

  public async getBriefStatuses(
    id: number
  ): Promise<IBriefHistoryItem[] | undefined> {
    let briefStatuses: IBriefHistoryItem[] | undefined = undefined;

    try {
      const briefDetailDTO = await this._http.getBriefActionsAsync(id);

      briefStatuses = this.toIBriefStatuses(briefDetailDTO);
    } catch (err) {
      this.storeError(err);
    } finally {
      return briefStatuses;
    }
  }

  private toIBriefStatuses(dto: BriefHistoryStatusDTO[]): IBriefHistoryItem[] {
    return dto.map((t) => this.toIBriefStatus(t));
  }

  private toIBriefStatus(dto: BriefHistoryStatusDTO): IBriefHistoryItem {
    const value: IBriefHistoryItem = {
      id: dto.id,
      actionDate: this.utcToLocalDate(dto.actionDate),
      user: dto.user,
      type: dto.type,
    };

    return value;
  }

  private toIBriefDetail(dto: BriefDetailDTO): IBriefDetail {
    const briefDTO = dto.brief;
    const briefDetail: IBriefDetail = {
      id: briefDTO.id,
      title: briefDTO.title,
      status: briefDTO.status,
      description: briefDTO.description,
      timingFrom: briefDTO.timingFrom
        ? this.utcToLocalDate(briefDTO.timingFrom)
        : undefined,
      timingTo: briefDTO.timingTo
        ? this.utcToLocalDate(briefDTO.timingTo)
        : undefined,
      applicantUnit: briefDTO.applicantUnit,
      createdOn: this.utcToLocalDate(briefDTO.createdOn),
      lastUpdate: this.utcToLocalDate(briefDTO.lastUpdate),
      goalsAndKeyMessages: briefDTO.goalsAndKeyMessages,
      highlightsAndCriticalIssues: briefDTO.highlightsAndCriticalIssues,
      targets:
        briefDTO.targets && Array.isArray(briefDTO.targets)
          ? briefDTO.targets
          : [],
      referent: briefDTO.referent,
      relevantStakeholders: briefDTO.relevantStakeholders
        ? briefDTO.relevantStakeholders
        : [],
      embargoed: briefDTO.embargoed,
      mediaCoverage: briefDTO.mediaCoverage,
      expectedDelivery: briefDTO.expectedDelivery
        ? this.utcToLocalDate(briefDTO.expectedDelivery)
        : undefined,
      files: this.toIFiles(briefDTO.documents),
      links: briefDTO.links,
      otherReferents: briefDTO.otherReferents,
      skipApprovation: briefDTO.skipApprovation,
      onBehalf: briefDTO.onBehalf,
      permissions: dto.permissions,
      macroActivityCount: briefDTO.macroActivityCount,
    };
    return briefDetail;
  }

  private toIFiles(files: BriefFileDTO[] | undefined): IFile[] | undefined {
    if (files) {
      return files.map((t) => this.toIFile(t));
    } else {
      return undefined;
    }
  }

  private toIFile(file: BriefFileDTO): IFile {
    const result: IFile = {
      id: file.id,
      name: file.fileName,
      size: file.fileSize,
      openPath: file.filePath,
      downloadPath: file.downloadPath,
    };
    return result;
  }

  // private toIBriefDetailMocked(dto: BriefDetailDTO): IBriefDetail
  // {

  //     const briefDetail: IBriefDetail = dto;

  //     briefDetail.timingFrom = this.utcToLocalDate(briefDetail.timingFrom);
  //     briefDetail.timingTo = this.utcToLocalDate(briefDetail.timingTo);
  //     briefDetail.applicantUnit = "User BU Mock";
  //     briefDetail.goalsAndKeyMessages = "Goal Mock";
  //     briefDetail.highlightsAndCriticalIssues = "Critical issue Mock";
  //     briefDetail.targets = ["target mock 1", "target mock 2"];

  //     dto.referent = {userPrincipalName: "AlexW@M365x178943.OnMicrosoft.com", displayName: "Alex Wilber", id: "1", initials: "AW" } ;
  //     dto.relevantStakeholders = ["Stakeholder1", "Stakeholder2", "Stakeholder3" ];
  //     briefDetail.embargoed = true;
  //     briefDetail.mediaCoverage = true;
  //     if (briefDetail.expectedDelivery)
  //     {
  //         briefDetail.expectedDelivery = this.utcToLocalDate(briefDetail.expectedDelivery);
  //     }
  //     briefDetail.expectedDelivery = new Date();

  //     briefDetail.files = [{name: "Document1.docx",size: 1024, openPath: '', downloadPath: '', id: 1 }] ;
  //     briefDetail.links = ["http://www.google.it", "https://xd.adobe.com/view/7a02e7d3-cbb0-4306-a11b-cf66b877d09b-2f51/screen/aca87019-132a-45fb-85c4-04bd469e7434"];

  //     return briefDetail;

  // }

  private utcToLocalDate(input: Date): Date {
    return moment
      .utc(input)
      .local()
      .toDate();
  }

  public async saveBrief(
    brief: IBrief,
    abort?: AbortController
  ): Promise<IBriefDetail | undefined | boolean> {
    let result: IBriefDetail | undefined | boolean = undefined;
    try {
      const formData = this.buildFormData(brief);

      const postResult = await ((!brief.id || brief.id === -1)
        ? this._http.addNewBrief(formData)
        : this._http.updateBrief(brief.id, formData));

      if (postResult.status === 200 || postResult.status === 201) {
        if (!brief.id || brief.id === -1) {
          const dto = ((await postResult.json()) as unknown) as BriefDetailDTO;
          result = this.toIBriefDetail(dto);
        } else {
          result = true;
        }
      }
    } catch (err) {
      this.storeError(err);
    } finally {
      return result;
    }
  }

  private validatePostResult(result: number): boolean {
    return result === HTTPStatusOk ? true : false;
  }

  private storeError(err: HttpError): void {
    console.error(err);
    ErrorClient.storeHttpError(err);
  }

  private buildFormData(brief: IBrief): FormData {
    const form = new FormData();

    form.append("Title", brief.title);
    form.append("ApplicantUnit", brief.applicantUnit);
    if (brief.referent) {
      form.append("Referent", brief.referent);
    }

    this.appendItemArray(form, brief.otherReferents, "OtherReferents");

    if (brief.timingFrom && brief.timingTo) {
      form.append("TimingFrom", brief.timingFrom.toISOString());
      form.append("TimingTo", brief.timingTo.toISOString());
    }

    if (brief.expectedDelivery) {
      form.append("ExpectedDelivery", brief.expectedDelivery.toISOString());
    }

    form.append("Description", this.getStringFormValue(brief.description));
    if (brief.goalAndKeyMessage) {
      form.append(
        "GoalsAndKeyMessages",
        this.getStringFormValue(brief.goalAndKeyMessage)
      );
    }

    this.appendItemArray(form, brief.targets, "Targets");

    if (brief.highlightsAndCriticalIssues) {
      form.append(
        "HighlightsAndCriticalIssues",
        this.getStringFormValue(brief.highlightsAndCriticalIssues)
      );
    }

    form.append("SetPending", this.booleanToString(brief.send));
    form.append("Embargoed", this.booleanToString(brief.embargoed));
    form.append("SkipApprovation", this.booleanToString(brief.skipApprovation));
    form.append("OnBehalf", this.booleanToString(brief.onBehalf));

    this.appendItemArray(
      form,
      brief.relevantStakeholders,
      "RelevantStakeholders"
    );

    form.append("MediaCoverage", this.booleanToString(brief.mediaCoverage));

    if (brief.documentations) {
      brief.documentations.forEach((t) =>
        form.append("Documentation", t, t.name)
      );
    }

    this.appendItemArray(
      form,
      brief.filesToRemove ? brief.filesToRemove.map((t) => t.toString()) : [],
      "DocumentationToRemove"
    );

    this.appendItemArray(form, brief.links, "Links");

    return form;
  }

  private appendItemArray(form: FormData, items: string[], name: string): void {
    if (items) {
      items.forEach((t) => form.append(name, t));
    }
  }

  private getStringFormValue(value: string | undefined): string {
    return value ? value : "";
  }

  private booleanToString(value: boolean): string {
    return value ? "true" : "false";
  }

  public async getBriefsApprovedAsync(): Promise<BriefApproved[] | undefined> {

    let retValue: BriefApproved[] | undefined = undefined;

    try {
      const apiUtility = new APIUtility();
      const dto = await apiUtility.getBriefsApprovedAsync();
      retValue = dto.map(t => this.briefApprovedDTOToModel(t));
    } catch (err) {
      this.storeError(err);
    } finally {
      return retValue;
    }

  }

  private briefApprovedDTOToModel(
    dto: BriefApprovedDTO
  ): BriefApproved {
    const timingFrom = dto.timingFrom ? this.utcToLocalDate(dto.timingFrom) : new Date();
    const timingTo = dto.timingTo ? this.utcToLocalDate(dto.timingTo) : new Date();

    const value: BriefApproved = {
      id: dto.id,
      name: dto.name,
      timingFrom: timingFrom,
      timingTo: timingTo
    };
    return value;
  }

  private async getMokedBriefs(): Promise<BriefDTO[]> {
    return BriefMock;
  }

  private async getAPIBriefAsync(): Promise<BriefApprovedDTO[]> {
    const http: APIUtility = new APIUtility();
    return await http.getBriefsApprovedAsync();
  }

  public async sendMailAsync(
    idBrief: string,
    recipientsTo: ITag[],
    recipientsCC: ITag[],
    subject: string,
    text: string,
    newStatus?: string
  ): Promise<number> {
    let result: number = 500;
    try {
      result = await this._http.sendMail(idBrief, "english", {
        recipientsTo: recipientsTo.map((x) => {
          return x.key.toString();
        }),
        recipientsCC: recipientsCC.map((x) => {
          return x.key.toString();
        }),
        subject: subject,
        body: text,
        type: newStatus,
      });
    } catch (err) {
      this.storeError(err);
    } finally {
      return result;
    }
  }
}
