import * as React from "react";
import RichTextEditor, { EditorValue } from "react-rte";
import { BriefTranslation } from "../../../../Translations/Brief.Translation";
import { Constants } from "../../../../Utility/Constants";
import styles from "./NewBrief.module.scss";
import {
  Checkbox,
  ITag,
  TextField,
  PrimaryButton,
  IBasePickerSuggestionsProps,
  BaseButton,
  Button,
  Toggle,
  Label,
  Dialog,
  DialogFooter,
  DefaultButton,
  DialogType,
} from "@fluentui/react";
import { FormEvent } from "react";
import { IBriefClient } from "../../../../Clients/IBriefClient";
import { RangeCalendar } from "../../../Shared/RangeCalendar/RangeCalendar";
import { CustomCalendar } from "../../../Shared/CustomCalendar/CustomCalendar";
import { MultipleFileUpload } from "../../../Shared/MultipleFileUpload/MultipleFileUpload";
import { IDateRangeValue } from "../../../Shared/RangeCalendar/IDateRangeValue";
import { CustomPicker } from "../../../Shared/CustomPicker/CustomPicker";
import { CustomLabel } from "../../../Shared/CustomLabel";
import {
  IBaseProperties,
  ICommonProperties,
} from "../../../../Models/IBaseProperties";
import { Links } from "../../../Shared/Links/Links";
import { ILink } from "../../../Shared/LinkList/LinkList";
import {
  IBrief,
  IBriefDetail,
  IBriefDetailPermission,
  IBriefPermission,
  IFile,
} from "../../../../Models/Brief";
import { IUserClient } from "../../../../Clients/IUserClient";
import { User, UserInfo } from "../../../../Models/User";
import { ToastNotificationType } from "../../../../Models/ToastNote";
import { LoaderComponent } from "../../../Shared/LoaderComponent/LoaderComponent";
import { IReadOnlyFileDocument } from "../../../Shared/FileList/FileList";
import { AlternateEmailTwoTone, LeakAddTwoTone } from "@material-ui/icons";
import { Security } from "../../../../Utility/Security";
import {
  INotification,
  NotificationSubType,
  NotificationType,
} from "../../../../Models/Notification";

const dialogContentProps = {
  type: DialogType.normal,
  title: "Send brief",
  closeButtonAriaLabel: "Close",
  subText:
    "Thank you, Your Brief has been sent correctly. You will shortly receive a notification in case of Approval or Reject.?",
};

const EmptyRichTextValue = RichTextEditor.createEmptyValue();

const onRenderCustomLabel = (
  commonProps: ICommonProperties,
  controlid: string,
  label: string,
  tooltip: string,
  required?: boolean,
  disableTooltip?: boolean
) => (p: any) => {
  return (
    <CustomLabel
      commonProps={commonProps}
      controlId={controlid}
      label={label}
      tooltiptext={tooltip}
      required={required}
      disableTooltip={disableTooltip}
    ></CustomLabel>
  );
};

export interface INewBriefProps extends IBaseProperties {
  idBrief?: number;
  onSave(brief: IBriefDetail, send: boolean): void;
  enableScroll: boolean;
  onDismiss(): void;
  onCancel(): void;
}
export interface INewBriefState {
  isSaving: boolean;
  isLoading: boolean;
  title?: string;
  applicantUnit: string;
  otherReferents?: string[];
  otherReferentsTag: ITag[];
  referent?: string;
  onBehalfReferent?: string;
  referentTag: ITag[];
  referentApplicantUnit?: string;
  timingStart?: Date;
  timingEnd?: Date;
  expectedDeliveryDate?: Date;
  description: EditorValue;
  skipApprovation?: boolean;
  onBehalf?: boolean;
  goalAndKeyMessage: EditorValue;
  targets?: string[];
  targetsTag: ITag[];
  highlightsAndIssue: EditorValue;
  embargoed?: boolean;
  relevantStakeholders?: string[];
  relevantStakeholdersTag: ITag[];
  requireMediaCoverage?: boolean;
  documentations: File[];
  links: string[];
  files: IFile[];
  id?: number;
  filesToRemoves: IFile[];
  status: string;
  permissions?: IBriefDetailPermission;
  showSendDialog: boolean;
}

export class NewBrief extends React.Component<INewBriefProps, INewBriefState> {
  private readonly _translation: BriefTranslation;
  private readonly _briefClient: IBriefClient;
  private readonly _userClient: IUserClient;
  private readonly _minDigits = 3;

  private readonly _stringsToITags = (items: string[]): ITag[] =>
    items.map(this._stringToITag);

  private readonly getLinks = (): ILink[] => {
    return this.state.links ? this.state.links.map(this._toILink) : [];
  };

  private readonly _toILink = (item: string): ILink => ({ url: item } as ILink);

  private readonly _stringToITag = (item: string): ITag =>
    ({ key: item, name: item } as ITag);

  private readonly _userInfoToITag = (item: UserInfo): ITag =>
    ({ key: item.username, name: item.displayName } as ITag);

  private readonly _userInfoObjectToITag = (item: UserInfo): ITag =>
    ({ key: JSON.stringify(item), name: item.displayName } as ITag);

  private readonly _usersToITag = (items: User[]): ITag[] =>
    items.map(this._userToITag);

  private readonly _userToITag = (item: User): ITag =>
  ({
    key: JSON.stringify({ userName: item.userPrincipalName }),
    name: item.displayName,
  } as ITag);

  private pickerSuggestionsProps: IBasePickerSuggestionsProps = {
    suggestionsHeaderText: "Suggested SPOC",
    noResultsFoundText: "No SPOC found",
  };

  private get _pickerSuggestionsReferents(): IBasePickerSuggestionsProps {
    return {
      suggestionsHeaderText: this._translation.suggestedReferent,
      noResultsFoundText: this._translation.noReferentFound,
    };
  }

  private get _pickerSuggestionsTargets(): IBasePickerSuggestionsProps {
    return {
      suggestionsHeaderText: this._translation.suggestedTarget,
      noResultsFoundText: this._translation.noTargetFound,
    };
  }

  private readonly MAX_FILE_SIZE = 10 * 1024;

  private get _pickerSuggestionsStakeholder(): IBasePickerSuggestionsProps {
    return {
      suggestionsHeaderText: this._translation.suggestedStakeholder,
      noResultsFoundText: this._translation.noStakeholderFound,
    };
  }

  private readonly onResolveSuggestionRelevantStakeholders = (
    filter: string,
    selectedItems?: ITag[] | undefined
  ): ITag[] | PromiseLike<ITag[]> => {
    if (filter) {
      return this.getStakeholder(filter);
    } else {
      return [];
    }
  };

  private readonly onResolveSuggestionOtherReferent = (
    filter: string,
    selectedItems?: ITag[] | undefined
  ): ITag[] | PromiseLike<ITag[]> => {
    if (filter) {
      if (filter.length >= this._minDigits) {
        return this.getBriefOtherReferents(filter, selectedItems);
      } else {
        return [];
      }
    } else {
      return [];
    }
  };

  private readonly onResolveSuggestionTarget = (
    filter: string,
    selectedItems?: ITag[] | undefined
  ): ITag[] | PromiseLike<ITag[]> => {
    if (filter) {
      if (filter.length >= this._minDigits) {
        return this.getTargets(filter);
      } else {
        return [this.toNewITag(filter)];
      }
    } else {
      return [];
    }
  };

  private readonly toNewITag = (value: string): ITag => {
    const newValue: ITag = { key: value, name: value };
    return newValue;
  };

  private readonly canSaveBrief = (): boolean => {
    const {
      title,
      applicantUnit: applicationUnit,
      timingStart,
      timingEnd,
      description,
    } = this.state;

    if (
      title &&
      applicationUnit &&
      timingStart &&
      timingEnd &&
      !this.editorValueIsEmpty(description)
    ) {
      return true;
    } else {
      return false;
    }
  };

  private editorValueIsEmpty(value: EditorValue): boolean {
    return !value
      .getEditorState()
      .getCurrentContent()
      .hasText();
  }

  private readonly canSaveDraftBrief = (): boolean => {
    const { title } = this.state;

    if (title) {
      return true;
    } else {
      return false;
    }
  };

  private readonly _isGlobalMedia: boolean;

  constructor(props: INewBriefProps) {
    super(props);

    this._briefClient = props.commonProps.clientCreator.createBriefClient();
    this._userClient = props.commonProps.clientCreator.createUserClient();
    this._translation = new BriefTranslation(props.commonProps.translation);

    this._isGlobalMedia = Security.isGlobalMedia;

    this.initializeState(props);

    this.bindEvents();
  }

  componentWillUnmount() {
    this.props.onDismiss();
  }

  async componentDidMount() {
    const { idBrief } = this.props;

    if (idBrief) {
      this.setState(
        {
          isLoading: true,
        },
        async () => this.loadBriefAsync(idBrief)
      );
    }
  }

  private async loadBriefAsync(id: number) {
    try {
      const result = await this._briefClient.getBriefById(id);
      if (result && result.hasOwnProperty("id")) {
        await this.loadBriefObjectAsync((result as IBriefDetail));
      }
    } catch {
      this.props.commonProps.toastComponent?.showMessage(
        this._translation.error,
        this._translation.errorRetrieveData,
        ToastNotificationType.ERROR
      );
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  private toUserInfo(user: User): UserInfo {
    const uInfo: UserInfo = {
      id: user.id,
      initials: user.initials,
      displayName: user.displayName,
      username: user.userPrincipalName,
      group: "",
    };

    return uInfo;
  }

  private async loadBriefObjectAsync(brief: IBriefDetail | undefined) {
    try {
      if (brief) {
        this.setState(
          {
            id: brief.id,
            documentations: [], //todo to be check
            title: brief.title,
            timingStart:
              brief.timingFrom === null ? undefined : brief.timingFrom,
            timingEnd: brief.timingTo === null ? undefined : brief.timingTo,
            applicantUnit: brief.applicantUnit,
            expectedDeliveryDate: brief.expectedDelivery,
            description: this.stringToEditorValue(brief.description),
            goalAndKeyMessage: this.stringToEditorValue(
              brief.goalsAndKeyMessages
            ),
            highlightsAndIssue: this.stringToEditorValue(
              brief.highlightsAndCriticalIssues
            ),
            embargoed: brief.embargoed,
            requireMediaCoverage: brief.mediaCoverage,
            //otherReferentsTag: this._usersToITag(brief.otherReferents),
            otherReferentsTag: brief.otherReferents
              .map(this.toUserInfo)
              .map(this._userInfoObjectToITag),
            otherReferents: brief.otherReferents
              ? brief.otherReferents.map((t) => t.userPrincipalName)
              : [],
            targets: brief.targets,
            targetsTag: this._stringsToITags(brief.targets),
            relevantStakeholders: brief.relevantStakeholders,
            relevantStakeholdersTag: this._stringsToITags(
              brief.relevantStakeholders
            ),
            files: brief.files ? brief.files : [],
            links: brief.links,
            referent: brief.referent ? brief.referent.userPrincipalName : "",
            referentTag: brief.referent
              ? [this._userToITag(brief.referent)]
              : [],
            status: brief.status,
            onBehalf: brief.onBehalf,
            onBehalfReferent:
              brief.onBehalf && brief.onBehalf === true && brief.referent
                ? brief.referent.userPrincipalName
                : undefined,
            permissions: brief.permissions,
          },
          async () => {
            if (!brief.files) {
              await this.loadFilesAsync(brief.id);
            }
          }
        );
      } else {
        this.props.commonProps.toastComponent?.showMessage(
          this._translation.error,
          this._translation.errorRetrieveData,
          ToastNotificationType.ERROR
        );
      }
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  private stringToEditorValue(text?: string): EditorValue {
    return text
      ? RichTextEditor.createValueFromString(text, "html")
      : EmptyRichTextValue;
  }

  private async loadFilesAsync(id: number) {
    try {
      const files = await this._briefClient.getFilesByBriefId(id);
      this.setState({
        files: files ? files : [],
      });
    } catch {
      this.setState({
        files: [],
      });
    }
  }

  private stringArrayToItag(items: string[]): ITag[] {
    return items.map(this.stringToITag);
  }

  private stringToITag(item: string): ITag {
    const value: ITag = { key: item, name: item };
    return value;
  }

  private get currentUserTags(): ITag[] {
    const currentUserTag = this.currentUserToITag();
    const referentTag: ITag[] = currentUserTag ? [currentUserTag] : [];
    return referentTag;
  }

  private initializeState(props: INewBriefProps) {
    this.state = {
      isSaving: false,
      isLoading: false,
      applicantUnit: this.currentApplicantUnit ? this.currentApplicantUnit : "",
      documentations: [],
      links: [],
      otherReferentsTag: [],
      relevantStakeholdersTag: [],
      targetsTag: [],
      files: [],
      filesToRemoves: [],
      referentTag: this.currentUserTags,
      status: "Draft",
      permissions: undefined,
      showSendDialog: false,
      description: EmptyRichTextValue,
      highlightsAndIssue: EmptyRichTextValue,
      goalAndKeyMessage: EmptyRichTextValue,
    };
  }

  private bindEvents() {
    this.onChangeTitle = this.onChangeTitle.bind(this);
    this.onChangeFiles = this.onChangeFiles.bind(this);
    this.onChangeLink = this.onChangeLink.bind(this);
    this.onRemoveLinks = this.onRemoveLinks.bind(this);
    this.onChangeTitle = this.onChangeTitle.bind(this);
    this.onChangeTiming = this.onChangeTiming.bind(this);
    this.onChangeDeliveryDate = this.onChangeDeliveryDate.bind(this);
    this.onChangeSkipApprovation = this.onChangeSkipApprovation.bind(this);
    this.onChangeBehalf = this.onChangeBehalf.bind(this);
    this.onChangeDescription = this.onChangeDescription.bind(this);
    this.onChangeGoalAndKeyMessage = this.onChangeGoalAndKeyMessage.bind(this);
    this.onChangeHighlightsAndIssue = this.onChangeHighlightsAndIssue.bind(
      this
    );
    this.onChangeEmbargoed = this.onChangeEmbargoed.bind(this);
    this.onChangeRequireMediaCoverage = this.onChangeRequireMediaCoverage.bind(
      this
    );
    this.onChangeOtherReferents = this.onChangeOtherReferents.bind(this);
    this.onChangeReferent = this.onChangeReferent.bind(this);
    this.onChangeRelevantStakeholders = this.onChangeRelevantStakeholders.bind(
      this
    );
    this.onChangeTargets = this.onChangeTargets.bind(this);
    this.onClickSaveDraftBrief = this.onClickSaveDraftBrief.bind(this);
    this.onClickSaveAndSendBrief = this.onClickSaveAndSendBrief.bind(this);
    this.onClickCancel = this.onClickCancel.bind(this);
    this.onRemoveFiles = this.onRemoveFiles.bind(this);
  }

  private onChangeSkipApprovation(
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    checked?: boolean | undefined
  ): void {
    this.setState({
      skipApprovation: checked,
    });
  }

  private onChangeBehalf(
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    checked?: boolean | undefined
  ): void {
    const applicantUnit =
      checked && checked === true ? "" : this.currentApplicantUnit;
    const referentTag = checked && checked === true ? [] : this.currentUserTags;

    this.setState({
      onBehalf: checked,
      applicantUnit: applicantUnit ? applicantUnit : "",
      referentTag: referentTag,
      onBehalfReferent: undefined,
    });
  }

  private onChangeDeliveryDate(newValue?: Date | undefined): void {
    this.setState({
      expectedDeliveryDate: newValue,
    });
  }

  private onClickSaveDraftBrief(
    event: React.MouseEvent<
      | HTMLAnchorElement
      | HTMLButtonElement
      | HTMLDivElement
      | BaseButton
      | Button
      | HTMLSpanElement,
      MouseEvent
    >
  ): void | undefined {
    event.preventDefault();

    this.setState({ isSaving: true }, () => {
      this.saveBriefAsync(false);
    });
  }

  private onClickCancel(
    event: React.MouseEvent<
      | HTMLAnchorElement
      | HTMLButtonElement
      | HTMLDivElement
      | BaseButton
      | Button
      | HTMLSpanElement,
      MouseEvent
    >
  ): void | undefined {
    this.props.onCancel();
  }

  private onClickSaveAndSendBrief(
    event: React.MouseEvent<
      | HTMLAnchorElement
      | HTMLButtonElement
      | HTMLDivElement
      | BaseButton
      | Button
      | HTMLSpanElement,
      MouseEvent
    >
  ): void | undefined {
    event.preventDefault();

    this.setState({ isSaving: true }, () => {
      this.saveBriefAsync(true);
    });
  }

  private async saveBriefAsync(send: boolean) {
    try {
      const data: IBrief | undefined = this.toIBrief();

      if (data) {
        const retValue = await this._briefClient.saveBrief(data);

        if (retValue) {
          this.props.commonProps.toastComponent?.showMessage(
            this._translation.success,
            this._translation.saveDraftMessage,
            ToastNotificationType.SUCCESS
          );

          // if (!(retValue as boolean))
          // {
          //   const files = (retValue as IBriefDetail).files;
          //   data.files = files === undefined ? [] : files ;
          // }
          this.sendChangeNotification(data);

          if (!data.id || data.id === -1) {
            if (!send) {
              await this.loadBriefObjectAsync(retValue as IBriefDetail);
              //this.props.onSave(retValue as IBriefDetail, false); //notify to parent component
            } else {
              this.props.onSave(retValue as IBriefDetail, true); //notify to parent component
            }
          } else if ((retValue as boolean) === true) {
            if (!send) {
              await this.loadBriefObjectAsync(
                this.toIBriefDetail(
                  data,
                  this.state.referentTag,
                  this.state.otherReferentsTag
                )
              );
            } else {
              let result = await this._briefClient.getBriefById(data.id);

              if (result && result.hasOwnProperty("id")) {
                result = result as IBriefDetail;
                const files = await this._briefClient.getFilesByBriefId(data.id);
                if (files) {
                  result.files = files;
                }
                this.props.onSave(
                  result
                  // this.toIBriefDetail(
                  //   data,
                  //   this.state.referentTag,
                  //   this.state.otherReferentsTag

                  //   ),
                  , send
                ); //notify to parent component
              }
            }
          } else {
            this.props.commonProps.toastComponent?.showMessage(
              this._translation.error,
              this._translation.errorSaveBrief,
              ToastNotificationType.ERROR
            );
          }
          //  this.props.onSave(this.briefMock);
        } else {
          this.props.commonProps.toastComponent?.showMessage(
            this._translation.error,
            this._translation.errorSaveBrief,
            ToastNotificationType.ERROR
          );
        }
      }
    } catch (error) {
      console.error(error);
      this.props.commonProps.toastComponent?.showMessage(
        this._translation.error,
        this._translation.errorSaveBrief,
        ToastNotificationType.ERROR
      );
    } finally {
      this.setState({ isSaving: false });
    }
  }

  private sendChangeNotification(data: IBrief) {
    const changeNotification: INotification = {
      id: data && data.id ? data.id : -1,
      name: data.title,
      type: NotificationType.Brief,
      subType: NotificationSubType.internalbriefchanged,
      author: undefined,
      date: new Date(),
      identifier: "",
      additionalInfo: {
        referent:
          this.state.referentTag && this.state.referentTag.length > 0
            ? this.tagToUserSimple(this.state.referentTag[0]).displayName
            : undefined,
        status: this.getStatus(),
        applicantUnit: data.applicantUnit,
        newdate: this.state.timingEnd,
      },
    };

    this.props.commonProps.onNotification?.next(changeNotification);
  }

  private toIBriefDetail(
    brief: IBrief,
    referentTag: ITag[],
    otherReferents: ITag[]
  ): IBriefDetail {
    const { id, title, applicantUnit, skipApprovation, onBehalf } = brief;
    const value: IBriefDetail = {
      id: id ? id : -1,
      title: title,
      onBehalf: onBehalf,
      applicantUnit: applicantUnit,
      referent:
        referentTag && referentTag.length > 0
          ? this.tagToUser(referentTag[0])
          : undefined,
      otherReferents: otherReferents.map((t) => this.tagToUser(t)),
      createdOn: new Date(),
      lastUpdate: new Date(),
      timingFrom: brief.timingFrom,
      timingTo: brief.timingTo,
      description: brief.description,
      goalsAndKeyMessages: brief.goalAndKeyMessage,
      highlightsAndCriticalIssues: brief.highlightsAndCriticalIssues,
      targets: brief.targets,
      skipApprovation: brief.skipApprovation,
      relevantStakeholders: brief.relevantStakeholders,
      embargoed: brief.embargoed,
      mediaCoverage: brief.mediaCoverage,
      links: brief.links,
      status: this.getStatus(),
      expectedDelivery: brief.expectedDelivery,
      permissions: {
        canApprove: false,
        canEdit: true,
        canDelete: true,
        canSendForApproval: true,
        canCreateActivity: false,
        canDelegateCreateActivity: false,
        canDelegateApproval: false,
      },
      macroActivityCount: 0
    };

    return value;
  }

  private getStatus(): string {
    const { status } = this.state;
    return status.toLowerCase() === "pending" ? "Rework" : status;
  }

  private tagToUserSimple(userTag: ITag): User {
    const user: User = {
      userPrincipalName: userTag.key as string,
      displayName: userTag.name,
      id: "0",
      initials: "",
    };
    return user;
  }

  private tagToUser(userTag: ITag): User {
    const userInfo = this.getUserInfo(userTag.key as string);
    const user: User = {
      userPrincipalName: userInfo.username,
      displayName: userTag.name,
      id: userInfo.id,
      initials: userInfo.initials,
    };
    return user;
  }

  private async getReferents(filter: string): Promise<ITag[]> {
    const result = await this._userClient.getReferentByFilterAsync(filter);

    if (result) {
      return result.map(this._userInfoObjectToITag);
    } else {
      this.props.commonProps.toastComponent?.showMessage(
        this._translation.error,
        this._translation.errorRetrieveData,
        ToastNotificationType.ERROR
      );
      return [];
    }
  }

  private async getBriefOtherReferents(
    filter: string,
    selectedItems?: ITag[] | undefined
  ): Promise<ITag[]> {
    let result = await this._userClient.getBriefReferentByFilterAsync(filter);

    if (result) {
      if (selectedItems && selectedItems.length > 0) {
        result = result.filter(
          (t) => !selectedItems.some((t2) => (t2.key as string) === t.username)
        );
      }

      return result.map(this._userInfoObjectToITag);
    } else {
      this.props.commonProps.toastComponent?.showMessage(
        this._translation.error,
        this._translation.errorRetrieveData,
        ToastNotificationType.ERROR
      );
      return [];
    }
  }

  private async getTargets(filter: string): Promise<ITag[]> {
    const result = await this._briefClient.getTargetsByFilterAsync(filter);

    if (result) {
      if (result.length > 0) {
        return result.map((t) => {
          const v: ITag = { key: t, name: t };
          return v;
        });
      } else {
        return [this.toNewITag(filter)];
      }
    } else {
      this.props.commonProps.toastComponent?.showMessage(
        this._translation.error,
        this._translation.errorRetrieveData,
        ToastNotificationType.ERROR
      );
      return [this.toNewITag(filter)];
    }
  }

  private async getStakeholder(filter: string): Promise<ITag[]> {
    const result = await this._briefClient.getStakeholderByFilterAsync(filter);

    if (result) {
      if (result.length > 0) {
        return result.map((t) => {
          const v: ITag = { key: t, name: t };
          return v;
        });
      } else {
        return [this.toNewITag(filter)];
      }
    } else {
      this.props.commonProps.toastComponent?.showMessage(
        this._translation.error,
        this._translation.errorRetrieveData,
        ToastNotificationType.ERROR
      );
      return [this.toNewITag(filter)];
    }
  }

  private onChangeOtherReferents(selectedTags: ITag[]): void {
    this.setState({
      otherReferentsTag: selectedTags,
      otherReferents: selectedTags.map(
        (t) => this.getUserInfo(t.key as string).username
      ),
    });
  }

  private getUserInfo(jsonValue: string): UserInfo {
    return JSON.parse(jsonValue);
  }

  private onChangeReferent(selectedTags: ITag[]): void {
    this.setState({
      referentTag: selectedTags,
      referent:
        selectedTags && selectedTags.length > 0
          ? this.getUserInfo(selectedTags[0].key as string).username
          : undefined,
      onBehalfReferent:
        selectedTags && selectedTags.length > 0
          ? this.getUserInfo(selectedTags[0].key as string).username
          : undefined,
      applicantUnit:
        selectedTags && selectedTags.length > 0
          ? this.getUserInfo(selectedTags[0].key as string).businessUnit
            ? (this.getUserInfo(selectedTags[0].key as string)
              .businessUnit as string)
            : ""
          : "",
      referentApplicantUnit:
        selectedTags && selectedTags.length > 0
          ? this.getUserInfo(selectedTags[0].key as string).businessUnit
          : undefined,
    });
  }

  private onChangeTargets(selectedTags: ITag[]): void {
    this.setState({
      targetsTag: selectedTags,
      targets: selectedTags.map((t) => t.key as string),
    });
  }

  private onChangeRelevantStakeholders(selectedTags: ITag[]): void {
    this.setState({
      relevantStakeholdersTag: selectedTags,
      relevantStakeholders: selectedTags.map((t) => t.key as string),
    });
  }

  private onChangeRequireMediaCoverage(
    ev?: FormEvent<HTMLElement | HTMLInputElement> | undefined,
    checked?: boolean | undefined
  ): void {
    this.setState({
      requireMediaCoverage: checked,
    });
  }

  private onChangeEmbargoed(
    ev?: FormEvent<HTMLElement | HTMLInputElement> | undefined,
    checked?: boolean | undefined
  ): void {
    this.setState({
      embargoed: checked,
    });
  }

  private onChangeHighlightsAndIssue(value: EditorValue): void {
    this.setState({
      highlightsAndIssue: value,
    });
  }

  private onChangeGoalAndKeyMessage(value: EditorValue): void {
    this.setState({
      goalAndKeyMessage: value,
    });
  }

  private onChangeTiming(newValue?: IDateRangeValue | undefined): void {
    if (newValue) {
      this.setState({
        timingStart: newValue.fromDate,
        timingEnd: newValue.toDate,
      });
    } else {
      this.setState({
        timingStart: undefined,
        timingEnd: undefined,
      });
    }
  }
  private onChangeTitle(
    event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined
  ): void {
    this.setState({
      title: newValue,
    });
  }

  private onChangeDescription(value: EditorValue): void {
    this.setState({
      description: value,
    });
  }

  private onChangeFiles(files: File[]): void {
    this.setState({
      documentations: files,
    });
  }

  private onRemoveFiles(files: IReadOnlyFileDocument[]): void {
    const { filesToRemoves, files: fs } = this.state;

    const idsToRemove = files.map((t) => t.id);

    const newFiles = fs.filter((t) => !idsToRemove.includes(t.id));

    const toRemove = filesToRemoves.concat(this.toIFiles(files));

    this.setState({
      filesToRemoves: toRemove,
      files: newFiles,
    });
  }

  private toIFiles(items: IReadOnlyFileDocument[]): IFile[] {
    return items.map(this.toIFile);
  }

  private toIFile(item: IReadOnlyFileDocument): IFile {
    const value: IFile = {
      name: item.name,
      size: item.fileSizeRaw,
      id: item.id,
      openPath: "",
      downloadPath: "",
    };
    return value;
  }

  private onChangeLink(newLink: ILink): void {
    let { links } = this.state;

    if (!links.some((t) => newLink.url.toLowerCase() === t.toLowerCase())) {
      links = links.concat(newLink.url);

      this.setState({
        links: links,
      });
    }
  }

  private onRemoveLinks(linksToRemove: ILink[]): void {
    let { links } = this.state;
    const lowerLinks = linksToRemove.map((t1) => t1.url.toLowerCase());

    links = links.filter((t) => !lowerLinks.includes(t.toLowerCase()));

    this.setState({
      links: links,
    });
  }

  private get timing(): IDateRangeValue | undefined {
    const { timingStart, timingEnd } = this.state;
    if (timingStart && timingEnd) {
      const value: IDateRangeValue = {
        fromDate: timingStart,
        toDate: timingEnd,
      };
      return value;
    } else {
      return undefined;
    }
  }

  private toIBrief(): IBrief | undefined {
    const {
      title,
      applicantUnit: applicationUnit,
      otherReferents: referents,
      timingStart,
      timingEnd,
      expectedDeliveryDate,
      description,
      goalAndKeyMessage,
      targets,
      highlightsAndIssue,
      embargoed,
      onBehalf,
      relevantStakeholders,
      requireMediaCoverage,
      documentations,
      links,
      skipApprovation,
      filesToRemoves,
    } = this.state;

    if (title) {
      // && applicationUnit && timingStart && timingEnd && description)
      const fileWillBeRemoved = filesToRemoves
        ? filesToRemoves.map((t) => t.id)
        : [];

      const cReferent = this.getReferent();

      const brief: IBrief = {
        id: this.props.idBrief
          ? this.props.idBrief
          : this.state.id
            ? this.state.id
            : -1,
        title: title,
        applicantUnit: applicationUnit,
        otherReferents: referents ? referents : [],
        referent: cReferent,
        timingFrom: timingStart,
        timingTo: timingEnd,
        expectedDelivery: expectedDeliveryDate,
        skipApprovation: skipApprovation ? skipApprovation : false,
        description: this.editorValueIsEmpty(description)
          ? undefined
          : description.toString("html"),
        goalAndKeyMessage: this.editorValueIsEmpty(goalAndKeyMessage)
          ? undefined
          : goalAndKeyMessage.toString("html"),
        targets: targets ? targets : [],
        highlightsAndCriticalIssues: this.editorValueIsEmpty(highlightsAndIssue)
          ? undefined
          : highlightsAndIssue.toString("html"),
        embargoed: embargoed ? embargoed : false,
        relevantStakeholders: relevantStakeholders ? relevantStakeholders : [],
        mediaCoverage: requireMediaCoverage ? requireMediaCoverage : false,
        documentations: documentations ? documentations : [],
        links: links ? links : [],
        files: [],
        filesToRemove: fileWillBeRemoved,
        onBehalf: onBehalf ? onBehalf : false,
        send: false,
      };

      return brief;
    } else {
      return undefined;
    }
  }

  private get currentApplicantUnit(): string | undefined {
    const userInfo = Security.getCurrentUserInfo();
    return userInfo ? userInfo.businessUnit : undefined;
  }

  private getReferent(): string | undefined {
    const userInfo = Security.getCurrentUserInfo();
    const { referent } = this.state;
    const retReferent =
      this.state.onBehalf && this.state.onBehalf === true
        ? this.state.onBehalfReferent
        : referent
          ? referent
          : userInfo
            ? userInfo.username
            : undefined;
    return retReferent;
  }

  private currentUserToITag(): ITag | undefined {
    const userInfo = Security.getCurrentUserInfo();
    if (userInfo) {
      const value: ITag = {
        key: userInfo.username,
        name: userInfo.displayName,
      };
      return value;
    } else {
      return undefined;
    }
  }

  // private get briefMock(): IBriefDetail
  // {
  //   const brief: IBriefDetail = {
  //     id: 40,
  //     title: "Bilancio navigabile",
  //     applicantUnit: "Name of unit",
  //     referent: {id: "0", userPrincipalName: "f.bonifacio@microsoft.com", displayName: "Giuseppe Solino", initials: "GHS" },
  //     timingFrom: new Date(2020, 2, 1),
  //     timingTo: new Date(2020, 2, 16),
  //     expectedDelivery: new Date(2020, 2, 19),
  //     description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  //     goalsAndKeyMessages: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
  //     targets: ["tag1", "tag2"],
  //     highlightsAndCriticalIssues: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo.",
  //     embargoed: true,
  //     relevantStakeholders: [{id: "0", userPrincipalName: "f.bonifacio@microsoft.com", displayName: "Giuseppe Solino", initials: "GHS" }],
  //     mediaCoverage: true,
  //     documentations: [],
  //     links: ["https://www.google.it/", "https://xd.adobe.com/view/7a02e7d3-cbb0-4306-a11b-cf66b877d09b-2f51/screen/5bd0a3a2-cc08-40c1-b6d2-5cbeba7454b2/specs/"],
  //     files: [
  //       {id: 0, name: "matrix_high_level_principal.kwt", size: 1024, openPath : "", downloadPath: ""},
  //       {id: 1, name: "image1.jpg", size: 4096, openPath : "", downloadPath: ""}
  //     ],
  //     status: "Draft",
  //     createdOn: new Date(),
  //     lastUpdate: new Date(),
  //   }

  //   return brief;

  // }

  private get selectedFiles(): IReadOnlyFileDocument[] {
    if (this.state.files) {
      const items = this.state.files.map(this.toIFileDocument);

      return items;
    } else {
      return [];
    }
  }

  private toIFileDocument(item: IFile): IReadOnlyFileDocument {
    const value: IReadOnlyFileDocument = {
      name: item.name,
      fileSizeRaw: item.size,
      id: item.id,
    };
    return value;
  }

  render() {
    const { permissions, showSendDialog } = this.state;
    const canEdit =
      (permissions ? permissions.canEdit : true) && this.canSaveBrief();
    const canSave =
      this.state.status.toLowerCase() === "draft" || this.state.status === ""
        ? this.canSaveBrief()
        : false;
    const canSaveDraft =
      this.state.status.toLowerCase() === "draft" || this.state.status === ""
        ? this.canSaveDraftBrief()
        : false;
    // const scrollClass = this.props.enableScroll ? " " + styles["scroll-y"] : "";
    const scrollClass = "";

    const jsxSendConfirmation = (
      <>
        <Dialog
          hidden={!showSendDialog}
          onDismiss={() => {
            this.setState({ showSendDialog: false });
          }}
          dialogContentProps={dialogContentProps}
        // modalProps={modalProps}
        >
          <DialogFooter>
            <PrimaryButton
              text="Back to home"
              onClick={() => {
                this.setState({ showSendDialog: false });
              }}
            />
            <DefaultButton
              text="My Brief"
              onClick={() => {
                this.setState({ showSendDialog: false });
              }}
            />
          </DialogFooter>
        </Dialog>
      </>
    );

    const jsxTitle = (
      <TextField
        id="title"
        styles={Constants.textFieldLargeStyle}
        maxLength={254}
        placeholder={this._translation.typeHere}
        value={this.state.title}
        onChange={this.onChangeTitle}
        onRenderLabel={onRenderCustomLabel(
          this.props.commonProps,
          "title",
          this._translation.title,
          "Title tooltip",
          true,
          true
        )}
      ></TextField>
    );

    const jsxApplicationUnit = (
      <TextField
        id="applicationUnit"
        styles={Constants.textFieldStyle}
        onRenderLabel={onRenderCustomLabel(
          this.props.commonProps,
          "applicationUnit",
          this._translation.applicationUnit,
          "Applicant unit tooltip",
          true,
          true
        )}
        value={this.state.applicantUnit}
        readOnly={true}
        disabled={true}
      ></TextField>
    );

    const jsxOtherReferents = (
      <>
        <CustomLabel
          commonProps={this.props.commonProps}
          controlId="referents"
          label={this._translation.referents}
          tooltiptext="Tooltip referents"
          required={false}
          disableTooltip={true}
        ></CustomLabel>
        <CustomPicker
          commonProps={this.props.commonProps}
          values={this.state.otherReferentsTag}
          styles={Constants.pickerStyle}
          onChange={this.onChangeOtherReferents}
          onResolveSuggestion={this.onResolveSuggestionOtherReferent}
          pickerSuggestionsProps={this._pickerSuggestionsReferents}
        ></CustomPicker>
      </>
    );

    const disabledReferent = this.state.onBehalf
      ? this.state.onBehalf === true
        ? false
        : true
      : true;

    const jsxReferent = (
      <>
        <CustomLabel
          commonProps={this.props.commonProps}
          controlId="referent"
          label={this._translation.referent}
          tooltiptext="Tooltip referent"
          required={true}
          disableTooltip={true}
        ></CustomLabel>
        <CustomPicker
          commonProps={this.props.commonProps}
          values={this.state.referentTag}
          styles={Constants.pickerStyle}
          onChange={this.onChangeReferent}
          onResolveSuggestion={this.onResolveSuggestionOtherReferent}
          pickerSuggestionsProps={this._pickerSuggestionsReferents}
          itemLimit={1}
          disabled={disabledReferent}
        ></CustomPicker>
      </>
    );

    const jsxTiming = (
      <>
        <CustomLabel
          commonProps={this.props.commonProps}
          controlId="timing"
          label={this._translation.timing}
          tooltiptext="Timing"
          required={true}
          disableTooltip={true}
        ></CustomLabel>
        <RangeCalendar
          commonProps={this.props.commonProps}
          showTime={false}
          disableTime={false}
          onChange={this.onChangeTiming}
          value={this.timing}
        ></RangeCalendar>
      </>
    );

    const jsxExpectedDeliveryDate = (
      <>
        <CustomLabel
          commonProps={this.props.commonProps}
          controlId="deliveryDate"
          label={this._translation.expectedDeliveryDate}
          tooltiptext="Expected delivery date"
          required={false}
          disableTooltip={true}
        ></CustomLabel>
        <CustomCalendar
          commonProps={this.props.commonProps}
          onChange={this.onChangeDeliveryDate}
          value={this.state.expectedDeliveryDate}
        ></CustomCalendar>
      </>
    );

    const jsxSkipApprovation = (
      <Toggle
        id="skipApprovation"
        onChange={this.onChangeSkipApprovation}
        onText={this._translation.skipApprovation}
        offText={this._translation.skipApprovation}
        styles={Constants.iToggle}
        checked={this.state.skipApprovation}
      ></Toggle>
    );

    const jsxBehalf = (
      <>
        <Toggle
          checked={this.state.onBehalf}
          id="enableBehalf"
          onChange={this.onChangeBehalf}
          onText={this._translation.associateBehalf}
          offText={this._translation.associateBehalf}
          styles={Constants.iToggle}
        ></Toggle>
      </>
    );

    const jsxDescription = (
      // <TextField
      //   id="description"
      //   multiline={true}
      //   styles={Constants.textFieldAreaStyle}
      //   placeholder={this._translation.typeHere}
      //   value={this.state.description}
      //   onChange={this.onChangeDescription}
      //   onRenderLabel={onRenderCustomLabel(
      //     this.props.commonProps,
      //     "description",
      //     this._translation.description,
      //     "Description tooltip",
      //     true,
      //     true
      //   )}
      // ></TextField>
      <>
        <CustomLabel
          commonProps={this.props.commonProps}
          controlId="description"
          label={this._translation.description}
          tooltiptext="Description tooltip"
          required={true}
          disableTooltip={true}
        ></CustomLabel>
        <RichTextEditor
          value={this.state.description}
          onChange={this.onChangeDescription}
          placeholder={this._translation.description}
          toolbarConfig={Constants.toolbarRichTextEditor}
          className={styles.RichTextboxCustom}
        ></RichTextEditor>
      </>
    );

    const jsxGoal = (
      // <TextField
      //   id="goals"
      //   multiline={true}
      //   styles={Constants.textFieldAreaStyle}
      //   placeholder={this._translation.typeHere}
      //   value={this.state.goalAndKeyMessage}
      //   onChange={this.onChangeGoalAndKeyMessage}
      //   onRenderLabel={onRenderCustomLabel(
      //     this.props.commonProps,
      //     "goals",
      //     this._translation.goalsAndKeyMessage,
      //     "Goals and keymessage tooltip",
      //     false,
      //     true
      //   )}
      // ></TextField>

      <>
        <CustomLabel
          commonProps={this.props.commonProps}
          controlId="goalAndKeyMessage"
          label={this._translation.goalsAndKeyMessage}
          tooltiptext="Goals and keymessage tooltip"
          required={false}
          disableTooltip={true}
        ></CustomLabel>
        <RichTextEditor
          value={this.state.goalAndKeyMessage}
          onChange={this.onChangeGoalAndKeyMessage}
          placeholder={this._translation.goalsAndKeyMessage}
          toolbarConfig={Constants.toolbarRichTextEditor}
          className={styles.RichTextboxCustom}
        ></RichTextEditor>
      </>
    );

    const jsxTargets = (
      <>
        <CustomLabel
          commonProps={this.props.commonProps}
          controlId="targets"
          label={this._translation.targets}
          tooltiptext="Tooltip targets"
          required={false}
          disableTooltip={true}
        ></CustomLabel>
        <CustomPicker
          commonProps={this.props.commonProps}
          values={this.state.targetsTag}
          styles={Constants.pickerStyle}
          onChange={this.onChangeTargets}
          onResolveSuggestion={this.onResolveSuggestionTarget}
          pickerSuggestionsProps={this._pickerSuggestionsTargets}
        ></CustomPicker>
      </>
    );

    const jsxHighlights = (
      // <TextField
      //   id="highlights"
      //   multiline={true}
      //   styles={Constants.textFieldAreaStyle}
      //   placeholder={this._translation.highlights}
      //   value={this.state.highlightsAndIssue}
      //   onChange={this.onChangeHighlightsAndIssue}
      //   onRenderLabel={onRenderCustomLabel(
      //     this.props.commonProps,
      //     "highlights",
      //     this._translation.highlights,
      //     "Highlights tooltip",
      //     false,
      //     true
      //   )}
      // ></TextField>
      <>
        <CustomLabel
          commonProps={this.props.commonProps}
          controlId="highlights"
          label={this._translation.highlights}
          tooltiptext="Highlights tooltip"
          required={false}
          disableTooltip={true}
        ></CustomLabel>
        <RichTextEditor
          value={this.state.highlightsAndIssue}
          onChange={this.onChangeHighlightsAndIssue}
          placeholder={this._translation.highlights}
          toolbarConfig={Constants.toolbarRichTextEditor}
          className={styles.RichTextboxCustom}
        ></RichTextEditor>
      </>
    );

    const jsxEmbargoed = (
      <Checkbox
        id="embargoed"
        styles={Constants.textFieldAreaStyle}
        placeholder={this._translation.embargoed}
        checked={this.state.embargoed}
        onChange={this.onChangeEmbargoed}
        onRenderLabel={onRenderCustomLabel(
          this.props.commonProps,
          "embargoed",
          this._translation.embargoed,
          "Embargoed tooltip",
          false,
          true
        )}
      ></Checkbox>
    );

    const jsxStakeholders = (
      <>
        <CustomLabel
          commonProps={this.props.commonProps}
          controlId="stakeholder"
          label={this._translation.relevantStakeholder}
          tooltiptext="Tooltip stakeholder"
          required={false}
          disableTooltip={true}
        ></CustomLabel>
        <CustomPicker
          commonProps={this.props.commonProps}
          values={this.state.relevantStakeholdersTag}
          styles={Constants.pickerStyle}
          onChange={this.onChangeRelevantStakeholders}
          onResolveSuggestion={this.onResolveSuggestionRelevantStakeholders}
          pickerSuggestionsProps={this._pickerSuggestionsStakeholder}
        ></CustomPicker>
      </>
    );

    const jsxMediaCoverage = (
      <Checkbox
        id="mediaCoverage"
        styles={Constants.textFieldAreaStyle}
        placeholder={this._translation.requireMediaCoverage}
        checked={this.state.requireMediaCoverage}
        onChange={this.onChangeRequireMediaCoverage}
        onRenderLabel={onRenderCustomLabel(
          this.props.commonProps,
          "mediaCoverage",
          this._translation.requireMediaCoverage,
          "Require Media Coverage tooltip",
          false,
          true
        )}
      ></Checkbox>
    );

    const jsxMultipleFileUpload = (
      <MultipleFileUpload
        commonProps={this.props.commonProps}
        onChange={this.onChangeFiles}
        onRemove={this.onRemoveFiles}
        maxFileSizeKB={this.MAX_FILE_SIZE}
        items={this.selectedFiles}
      ></MultipleFileUpload>
    );

    const jsxUsefulLinks = (
      <Links
        commonProps={this.props.commonProps}
        onAdd={this.onChangeLink}
        onRemove={this.onRemoveLinks}
        items={this.getLinks()}
      ></Links>
    );

    const jsxButtons = (
      <div className={styles["newbrief-container-item-right"]}>
        <div
          className={
            styles["newbrief-button-top"] +
            " " +
            styles["newbrief-button-bottom"]
          }
        >
          <PrimaryButton
            text={this._translation.previewAndSend}
            styles={Constants.iStandardButtonLarge}
            onClick={this.onClickSaveAndSendBrief}
            disabled={!canSave && !canEdit}
          ></PrimaryButton>
        </div>
        <div className={styles["newbrief-button-bottom"]}>
          <PrimaryButton
            text={this._translation.saveDraft}
            styles={Constants.iStandardButtonLarge}
            onClick={this.onClickSaveDraftBrief}
            disabled={!canSaveDraft && !canEdit}
          ></PrimaryButton>
        </div>
        <div>
          <PrimaryButton
            text={this._translation.cancel}
            styles={Constants.iStandardButtonLarge}
            onClick={this.onClickCancel}
          ></PrimaryButton>
        </div>
      </div>
    );
    return (
      <React.Fragment>
        <LoaderComponent
          commonProps={this.props.commonProps}
          isLoading={this.state.isSaving}
          label={this._translation.waiting}
        />
        <div className={styles["newbrief-container"]}>
          <div className={styles["newbrief-container-item-left"] + scrollClass}>
            <div className="ms-Grid" dir="ltr">
              <div className={"ms-Grid-row " + styles["row-space"]}>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                  {jsxTitle}
                </div>
              </div>
              <div className={styles["newbrief-skip-container"]}>
                {this._isGlobalMedia && (
                  <div className={"ms-Grid-row " + styles["row-space"]}>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                      {jsxBehalf}
                    </div>
                  </div>
                )}
              </div>
              <div className={"ms-Grid-row " + styles["row-space"]}>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                  {jsxReferent}
                </div>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                  {jsxApplicationUnit}
                </div>
              </div>
              <div className={"ms-Grid-row " + styles["row-space"]}>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                  {jsxOtherReferents}
                </div>
              </div>
              <div className={"ms-Grid-row " + styles["row-space"]}>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                  {jsxTiming}
                </div>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                  {jsxExpectedDeliveryDate}
                </div>
              </div>
              <div className={styles["newbrief-skip-container"]}>
                {this._isGlobalMedia && (
                  <div className={"ms-Grid-row " + styles["row-space"]}>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                      {jsxSkipApprovation}
                    </div>
                  </div>
                )}
                <hr></hr>
              </div>
              <div className={"ms-Grid-row " + styles["row-space"]}>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                  {jsxDescription}
                </div>
              </div>
              <div className={"ms-Grid-row " + styles["row-space"]}>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                  {jsxGoal}
                </div>
              </div>
              <div className={"ms-Grid-row " + styles["row-space"]}>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                  {jsxTargets}
                </div>
              </div>
              <div className={"ms-Grid-row " + styles["row-space"]}>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                  {jsxHighlights}
                </div>
              </div>
              <div className={"ms-Grid-row " + styles["row-space"]}>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                  {jsxEmbargoed}
                </div>
              </div>
              <div className={"ms-Grid-row " + styles["row-space"]}>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                  {jsxStakeholders}
                </div>
              </div>
              <div className={"ms-Grid-row " + styles["row-space"]}>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                  {jsxMediaCoverage}
                </div>
              </div>
              <div className={"ms-Grid-row " + styles["row-space"]}>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                  {jsxMultipleFileUpload}
                </div>
              </div>
              <div className={"ms-Grid-row " + styles["row-space"]}>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                  {jsxUsefulLinks}
                </div>
              </div>
            </div>
          </div>
          {jsxButtons}
        </div>
      </React.Fragment>
    );
  }
}
