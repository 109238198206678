import { number } from "prop-types";
export const ALL: string = "All";
export const footerHrefQuestions: string = "GLOBALMEDIAGOVERNANCE@ENEL.COM";
export const footerHrefTechSupport: string = "SUPPORTMEDIAPLANNING@ENEL.COM";
export const webFunctionUrl: string = "";
export const functionName: string = "";
export const clientId: string = "";

export interface SupportPage {
  name: string;
  title: string;
  text: string;
  supportVisible: boolean;
}

export enum SupportPagesEnum {
  Home = 0,
  UserGuide = 1,
  VideoTutorials = 2,
  Faq = 3,
}

export enum SupportLanguage {
  Italian = 0,
  English = 1,
  Spanish = 2,
}

export interface FooterLink {
  header: string;
  text: string;
  href: string;
  hrefText: string;
}

export interface Footer {
  links: FooterLink[];
  webFunctionUrl: string;
  clientId: string;
  functionName: string;
  userId: string;
}

export interface CardComponent {
  visible: boolean;
  languages: SupportLanguage[];
}

export interface RoleCounter {
  role: string;
  count: number;
}

export interface LanguageCounter {
  language: string;
  count: number;
}

export interface SupportCounter {
  roleCounter: RoleCounter[];
  languageCounter: LanguageCounter[];
}


