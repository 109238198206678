import React from 'react'
import PropTypes from 'prop-types'

import Track from './Track'

const Tracks = ({ time, tracks, tracks2, clickElement }) => (
  <div className="rt-tracks">
    {tracks.map(({ id, elements, isOpen, tracks: children, tracks2: children2 }) => (      
      <Track key={id} time={time} elements={elements} isOpen={isOpen} tracks={children} tracks2={children2} clickElement={clickElement} />      
    ))}
  </div>
)

Tracks.propTypes = {
  time: PropTypes.shape({}).isRequired,
  tracks: PropTypes.arrayOf(PropTypes.shape({})),
  tracks2: PropTypes.arrayOf(PropTypes.shape({})),
  clickElement: PropTypes.func,
}

export default Tracks
